export default [
  {
    title: 'user-address',
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: 'select-insurances',
    },
  },
  {
    title: 'select-insurances',
    step: 1,
    subStep: 2,
    nextRoutes: {
      2: 'car-already-registered',
    },
  },
  {
    title: 'car-already-registered',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'car-information',
    },
  },
  {
    title: 'car-information',
    step: 3,
    subStep: 1,
    nextRoutes: {
      // 1: 'car-information-extra',
      1: 'replacement-vehicle',
    },
  },
  // {
  //   title: 'car-information-extra',
  //   step: 3,
  //   subStep: 3,
  //   nextRoutes: {
  //     1: 'replacement-vehicle',
  //   },
  // },
  {
    title: 'replacement-vehicle',
    step: 3,
    subStep: 4,
    nextRoutes: {
      1: 'vehicle-purpose',
    },
  },
  {
    title: 'vehicle-purpose',
    step: 3,
    subStep: 5,
    nextRoutes: {
      1: 'average-km-travelled',
    },
  },
  {
    title: 'average-km-travelled',
    step: 3,
    subStep: 6,
    nextRoutes: {
      1: 'drivers-information',
    },
  },
  {
    title: 'drivers-information',
    step: 3,
    subStep: 7,
    nextRoutes: {
      1: 'additional-drivers',
      // 1: "drivers-information-extra"
    },
  },
  {
    title: 'drivers-information-extra',
    step: 3,
    subStep: 8,
    nextRoutes: {
      1: 'drivers-policy-info',
    },
  },
  {
    title: 'additional-drivers',
    step: 3,
    subStep: 8,
    nextRoutes: {
      1: 'vehicle-insurance-policy',
    },
  },
  {
    title: 'vehicle-insurance-policy',
    step: 3,
    subStep: 9,
    nextRoutes: {
      1: 'vehicle-insurance-policy-information',
      2: 'vehicle-specifics',
    },
  },
  {
    title: 'vehicle-insurance-policy-information',
    step: 3,
    subStep: 10,
    nextRoutes: {
      1: 'vehicle-specifics',
    },
  },
  {
    title: 'vehicle-specifics',
    step: 3,
    subStep: 11,
    nextRoutes: {
      1: 'insurance-options-car',
    },
  },
  {
    title: 'insurance-options-car',
    step: 4,
    subStep: 0,
    nextRoutes: {
      1: 'car-premium-summary',
      2: 'price-comparison',
    },
  },
  {
    title: 'price-comparison',
    step: 4,
    subStep: 0,
    nextRoutes: {
      1: 'car-premium-summary',
    },
  },
  {
    title: 'car-premium-summary',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'car-already-registered',
      2: 'overview',
    },
  },
  {
    title: 'insure-extras',
    step: 1,
    subStep: 3,
    nextRoutes: {
      1: 'acceptance-conditions',
    },
  },
  {
    title: 'acceptance-conditions',
    step: 1,
    subStep: 4,
    nextRoutes: {},
  },
  {
    title: 'search-vehicle',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'car-information',
    },
  },
  {
    title: 'car-information',
    step: 2,
    subStep: 1,
    nextRoutes: {
      // 1: 'car-information-extra',
      1: 'replacement-vehicle',
    },
  },
  // {
  //   title: 'car-information-extra',
  //   step: 2,
  //   subStep: 2,
  //   nextRoutes: {
  //     1: 'overview',
  //   },
  // },
  {
    title: 'safety-systems',
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: 'price-calculator',
    },
  },
  {
    title: 'price-calculator',
    step: 3,
    subStep: 0,
    nextRoutes: {
      1: 'overview',
    },
  },
  {
    title: 'overview',
    step: 4,
    subStep: 0,
    nextRoutes: {
      1: 'thankyou',
    },
  },
  {
    title: 'thankyou',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];
