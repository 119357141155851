import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ActionButton,
  SearchSelectInput,
  TextInput,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';
import { getNestedObject } from '../helpers/objectService';
import { insurance_companies } from '../constants/FlowSearchSelectData';
import { format } from 'date-fns';

const FlowHealthExistingMedicalInsuranceInformationMobile = () => {
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addAnswer } = useContext(ChatContext);
  const [defaultValues, setDefaultValues] = useState({});
  const [insuranceCompany, setInsuranceCompany] = useState();
  const [insurancePrice, setInsurancePrice] = useState();
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      insurances: data.insurances,
      insurance_price: getNestedObject(data, [
        'healths',
        [currentHealth],
        'insurance_price',
      ]),
      insurance_company_name: getNestedObject(data, [
        'healths',
        [currentHealth],
        'insurance_company_name',
      ]),
      start_of_contract: getNestedObject(data, [
        'healths',
        [currentHealth],
        'start_of_contract',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues?.insurance_company_name) {
      setInsuranceCompany({
        label: defaultValues?.insurance_company_name,
        value: defaultValues?.insurance_company_name,
      });
    }
    if (defaultValues?.insurance_price) {
      setInsurancePrice({
        name: 'insurance_price',
        value: defaultValues?.insurance_price,
      });
    }
    if (defaultValues?.start_of_contract) {
      setStartDate({
        name: 'start_of_contract',
        value: defaultValues?.start_of_contract,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const submitHandler = () => {
    if (
      insuranceCompany == null ||
      startDate == null ||
      insurancePrice == null
    ) {
      if (
        (insuranceCompany?.value !== 'None' &&
          insuranceCompany?.value !== 'GAN DIRECT INSURANCE LTD' &&
          insuranceCompany?.value !== 'OVERSEAS') ||
        insuranceCompany == null
      ) {
        return;
      }
    }

    const payload = {
      insurance_price: insurancePrice?.value,
      insurance_company_name: insuranceCompany?.value,
      start_of_contract: startDate?.value,
    };
    patchStorage({
      payload: payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    if (insuranceCompany?.value != null && startDate?.value != null) {
      const answer = {
        message: `I have an insurance with ${
          insuranceCompany.value
        } that was started on ${format(
          new Date(startDate.value),
          'dd/MM/yyyy'
        )}`,
        slug: 'existing_health_insurance_information',
      };
      addAnswer(answer);
    }
    renderNextRoute(1, { health: currentHealth });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <SearchSelectInput
              name="insurance_company_name"
              options={insurance_companies}
              onSelected={(val) => setInsuranceCompany(val)}
            >
              <Label>{i18n.t('Insurance company')}</Label>
            </SearchSelectInput>
            {insuranceCompany == null ||
              (insuranceCompany?.value !== 'None' &&
                insuranceCompany?.value !== 'GAN DIRECT INSURANCE LTD' &&
                insuranceCompany?.value !== 'OVERSEAS' && (
                  <>
                    <DateInput
                      iso
                      name="start_of_contract"
                      value={startDate?.value}
                      onChange={(val) => {
                        setStartDate(val);
                      }}
                    >
                      <Label>{i18n.t('End date')}</Label>
                    </DateInput>
                    <StyledTextInput
                      name="insurance_price"
                      type="number"
                      value={insurancePrice?.value}
                      onChange={(val) => {
                        setInsurancePrice(val);
                      }}
                    >
                      <p>{i18n.t('Price')}</p>
                      <p className="valueType">&euro;</p>
                    </StyledTextInput>
                  </>
                ))}
          </div>
          <ButtonContainer>
            <ActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Submit')}
            </ActionButton>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Label = styled.p`
  color: #8990a3;
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.5rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  form {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    > div > div {
      height: 9rem;
    }
    .Select__control {
      border-color: #e4e4e4 !important;
    }
    .Select__indicator-separator {
      background-color: #e4e4e4 !important;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
`;

export default FlowHealthExistingMedicalInsuranceInformationMobile;
