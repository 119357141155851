import 'isomorphic-fetch';
import { BASE_URL } from '../env.config';

import { setLoggedIn } from './authService';

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
};

export async function refresh() {
  const path = 'v1/api/aut/v1/api/auth/refresh';
  const payload = {};

  if (sessionStorage.getItem('access_token')) {
    payload.refresh_token = sessionStorage.getItem('refresh_token');
  }

  const [resp, status] = await request(path, 'POST', payload);
  if (status === 200)
    sessionStorage.setItem('access_token', resp?.access_token);
  return [resp, status];
}

export async function request(path, method, payload = null, origin) {
  let [resp, status] = await _request(path, method, payload, origin);
  if (status >= 401) {
    if (resp.msg === 'INVALID_TOKEN' || resp.msg === 'NOT_AUTHORIZED') {
      return await logout();
    } else if (resp.msg === 'AUTHENTICATION_EXPIRED') {
      await refresh();
      [resp, status] = await _request(path, method, payload, origin);
    }
  }
  return [resp, status];
}

export async function logout() {
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  setLoggedIn(false);
  window.location.href = '/';
}

async function _request(path, method, payload, origin) {
  let tempOrigin = BASE_URL;
  if (origin) tempOrigin = origin;

  const url = tempOrigin + path;
  let req;
  if (sessionStorage.getItem('access_token')) {
    headers.Authorization = `Bearer ${sessionStorage.getItem('access_token')}`;
  }
  if (payload == null) {
    req = await fetch(`${url}`, {
      method: method,
      headers: headers,
      credentials: 'include',
    });
  } else {
    req = await fetch(`${url}`, {
      method: method,
      headers: headers,
      body: payload instanceof FormData ? payload : JSON.stringify(payload),
      credentials: 'include',
    });
  }
  console.log(`[API RESPONSE] ${method} ${path} status: ${req.status}`);
  const contentType = req.headers.get('content-type');
  if (
    contentType?.indexOf('application/json') !== -1 ||
    contentType?.indexOf('application/vnd.api+json') !== -1
  ) {
    const resp = await req.json();
    return [resp, req.status];
  } else {
    const resp = URL.createObjectURL(await req.blob());
    return [resp, req.status];
  }
}
