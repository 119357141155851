import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import { useHistory } from 'react-router-dom';
import {
  AccordionBox,
  ActionButton,
  IconActionChevronLeft,
  LoadingSpinner,
  SmallLoader,
  IconCertificateFilled,
  PieProgressIndicator,
} from 'wg-fe-ui';
import CoverDetail from '../components/CoverDetailHealth';
import { createHealthPolicyGan } from '../helpers/apiRouterService';
import { usePremiumSummaryStore } from '../contexts/PremiumSummaryContext.js';

import HelEcoGanIcon from '../assets/icons/HelEcoGanIcon';
import HelPremiumGanIcon from '../assets/icons/HelPremiumGanIcon';
import HelValueGanIcon from '../assets/icons/HelValueGanIcon';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getCoverDetailsHealth } from '../helpers/CoverDetailsService.js';
import { handlePolicyStartDate } from '../helpers/dateService.js';

const getBoolean = (value) => {
  if (value === 'yes') return 1;
  return 0;
};

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowHealthPremiumSummary = () => {
  const [{ state, addPackage, editPackage }] = usePremiumSummaryStore();
  const history = useHistory();
  const { t } = useTranslation();
  const { renderNextRoute } = useRouting();
  const [isAdding, setIsAdding] = useState(false);
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const { healths } = storage?.data;
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [areDiscountsOpen, setAreDiscountsOpen] = useState(false);

  const getPayload = (_data, selected, currentHealth) => {
    if (!selected || !currentHealth) return;
    if (state?.packages?.health?.[currentHealth]?.[selected])
      return state?.packages?.health?.[currentHealth]?.[selected];
    const payload = {
      SavePolicy: _data?.SavePolicy || 'False',
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      MedicalStatus: 1,
      DoYouSmoke: getBoolean(_data?.healths[currentHealth]?.DoYouSmoke),
      ExistingInsuranceCompany: {
        InsuranceCompany:
          _data?.healths?.[currentHealth]?.insurance_company_name,
      },
      ExpiringDateOfYourCurrentPolicy: _data?.healths?.[currentHealth]
        ?.insurance_company_name
        ? _data?.healths?.[currentHealth]?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.healths?.[currentHealth]
        ?.insurance_company_name
        ? _data?.healths?.[currentHealth]?.insurance_price || null
        : null,
      NumberOfCigarettesPerDay:
        _data?.healths[currentHealth]?.NumberOfCigarettesPerDay,
      AnyChronicOrLongTermMedicalCondition: getBoolean(
        _data?.healths[currentHealth]?.AnyChronicOrLongTermMedicalCondition
      ),
      PolicyStartDate: handlePolicyStartDate(
        _data.healths[currentHealth]?.policy_details?.starting_date
      ),
      PolicyEndDate: new Date(
        _data.healths[currentHealth]?.policy_details?.ending_date
      ).toISOString(),
      policyDuration:
        _data.healths[currentHealth]?.policy_details?.insurance_duration,
      WeightInKg: _data?.healths[currentHealth]?.WeightInKg,
      HeightInCM: _data?.healths[currentHealth]?.HeightInCM,
      CurrentlyDisabledPregnantOrUnableToPerformNormalActivities: getBoolean(
        _data?.healths[currentHealth]
          ?.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
      ),
      EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth: getBoolean(
        _data?.healths[currentHealth]
          ?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
      ),
      AnyDiseasesOfTheCirculatorySystemHeart: getBoolean(
        _data?.healths[currentHealth]?.AnyDiseasesOfTheCirculatorySystemHeart
      ),
      SpecifyTheDisease: _data?.healths[currentHealth]?.SpecifyTheDisease,
      DateOfRecentBloodPressureReading:
        _data?.healths[currentHealth]?.DateOfRecentBloodPressureReading,
      BloodPresureResult: _data?.healths[currentHealth]?.BloodPresureResult,
      MedicationTaken: _data?.healths[currentHealth]?.MedicationTaken,
      NutritionalAndMetabolicDiseases: getBoolean(
        _data?.healths[currentHealth]?.NutritionalAndMetabolicDiseases
      ),
      DiabetesDisease: _data?.healths[currentHealth]?.DiabetesDisease,
      DiabetesDiagnosed: _data?.healths[currentHealth]?.DiabetesDiagnosed,
      ControlledByDiet: getBoolean(
        _data?.healths[currentHealth]?.ControlledByDiet
      ),
      DiabetesMedicationTaken:
        _data?.healths[currentHealth]?.DiabetesMedicationTaken,
      RecentHba1cTest: _data?.healths[currentHealth]?.RecentHba1cTest,
      ResultHba1c: _data?.healths[currentHealth]?.ResultHba1c,
      HowManyMonthsOfAYearDoYouLiveAbroad:
        _data?.healths[currentHealth]?.HowManyMonthsOfAYearDoYouLiveAbroad,
      AreBothOYourParentsSmokers: getBoolean(
        _data?.healths[currentHealth]?.AreBothOYourParentsSmokers
      ),
      NoOfCigarettesParentsSmoke:
        _data?.healths[currentHealth]?.NoOfCigarettesParentsSmoke,
      ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus: getBoolean(
        getBoolean(
          _data?.healths[currentHealth]
            ?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
        )
      ),

      YoungestParentAge: _data?.healths[currentHealth]?.YoungestParentAge,
      Doctor: _data?.healths[currentHealth]?.Doctor,
      LastDateSeenByTheDoctor:
        _data?.healths[currentHealth]?.LastDateSeenByTheDoctor,
      ReasonOfLastVisit: _data?.healths[currentHealth]?.ReasonOfLastVisit,
      CanYouProvideMedicalCertificate: getBoolean(
        _data?.healths[currentHealth]?.CanYouProvideMedicalCertificate
      ),
      CurrentMediclaPolicyFromDate:
        _data?.healths[currentHealth]?.CurrentMediclaPolicyFromDate,
      CurrentMediclaPolicyToDate:
        _data?.healths[currentHealth]?.CurrentMediclaPolicyToDate,
      InsuranceScheduleFromPreviousInsuranceCompany: null,
      MedicalInPatient: getBoolean(
        _data?.healths[currentHealth]?.MedicalInPatient
      ),
      InPatientAnnualLimit: _data?.healths[currentHealth]?.InPatientAnnualLimit,
      InPatientDeductible: _data?.healths[currentHealth]?.InPatientDeductible,
      InPatientCoInsurance: _data?.healths[currentHealth]?.InPatientCoInsurance,
      NewBornBaby: getBoolean(_data?.healths[currentHealth]?.NewBornBaby),
      MedicalOutPatient: 0,
      GeographicalArea: _data?.healths[currentHealth]?.GeographicalArea,
      ReimbursementMethod:
        _data?.healths[currentHealth]?.ReimbursementMethod || 1,
      MedicalCallCenteService: getBoolean(
        _data?.healths[currentHealth]?.MedicalCallCenteService
      ),
      TravelTransportationAndOutOfGeorgaphycalArea: 0,
      DeathBenefit: getBoolean(_data?.healths[currentHealth]?.DeathBenefit),
      DeathAmountInsured: _data?.healths[currentHealth]?.DeathAmountInsured,
      MortgageFinancialInstitution:
        _data?.healths[currentHealth]?.MortgageFinancialInstitution,
      DreadDisease: _data?.healths[currentHealth]?.DreadDisease,
      DreadSumInsured: _data?.healths[currentHealth]?.DreadSumInsured,
      PermanentTotalOrPartialDisability: getBoolean(
        _data?.healths[currentHealth]?.PermanentTotalOrPartialDisability
      ),
      AutomaticRenewal:
        _data?.healths[currentHealth]?.AutomaticRenewal || false,
      DisabilityAmount: _data?.healths[currentHealth]?.DisabilityAmount,
      MedicalDiseases:
        _data?.healths[currentHealth]?.MedicalDiseases?.map((item) => ({
          Oid: item.value,
        })) || undefined,
    };
    const clone = {};
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'undefined') clone[key] = null;
      else clone[key] = payload[key];
    });

    switch (selected) {
      case 'Economy':
        clone.MedicalInPatient = 1;
        clone.InPatientAnnualLimit = 1;
        clone.GeographicalArea = 1;
        clone.InPatientDeductible = 3;
        clone.ReimbursementMethod = 1;
        clone.InPatientCoInsurance = 1;
        clone.NewBornBaby = 0;
        clone.MedicalOutPatient = 0;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
        clone.DeathBenefit = 0;
        clone.DreadDisease = 0;
        clone.PermanentTotalOrPartialDisability = 0;
        clone.MedicalCallCenteService = 0;
        break;
      case 'Value':
        clone.NewBornBaby = 1;
        clone.MedicalInPatient = 1;
        clone.GeographicalArea = 3;
        clone.InPatientAnnualLimit = 2;
        clone.InPatientDeductible = 5;
        clone.ReimbursementMethod = 3;
        clone.InPatientCoInsurance = 1;
        clone.MedicalOutPatient = 0;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
        clone.DeathBenefit = 0;
        clone.DreadDisease = 0;
        clone.PermanentTotalOrPartialDisability = 0;
        clone.MedicalCallCenteService = 1;
        break;
      case 'Premium':
        clone.MedicalOutPatient = 1;
        clone.MedicalInPatient = 1;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 1;
        clone.DeathBenefit = 1;
        clone.DeathAmountInsured = 1;
        clone.GeographicalArea = 4;
        clone.DreadDisease = 1;
        clone.DreadSumInsured = 5;
        clone.InPatientCoInsurance = 1;
        clone.InPatientAnnualLimit = 3;
        clone.InPatientDeductible = 8;
        clone.NewBornBaby = 1;
        clone.MedicalCallCenteService = 1;
        clone.ReimbursementMethod = 4;
        break;
      default:
        break;
    }
    addPackage({
      type: 'health',
      current: currentHealth,
      selected,
      data: clone,
    });
    return clone;
  };

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createHealthPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected, currentHealth, state)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['policyData', selected, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    refetch();
  }, [selected, state?.packages?.health?.[currentHealth]]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (healths?.[currentHealth]?.insurance_pack) {
      setSelected(healths?.[currentHealth].insurance_pack);
    }
  }, []);

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData[selected]]);

  function iconType(icon) {
    switch (icon) {
      case 'Economy': //semi-detached
        return <HelEcoGanIcon />;
      case 'Value':
        return <HelValueGanIcon />;
      case 'Premium':
        return <HelPremiumGanIcon />;
      default:
        return <HelEcoGanIcon />;
    }
  }

  const getPackageColor = (insuranceType) => {
    switch (insuranceType) {
      case 'Economy': //semi-detached
        return '#00A3E4';
      case 'Value':
        return '#024284';
      case 'Premium':
        return '#C29B67';
      default:
        return;
    }
  };

  const packages = [
    {
      value: 'Economy',
    },
    {
      value: 'Value',
    },
    {
      value: 'Premium',
    },
  ];

  const premium_summary_items = [
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  function receiveChanges(val) {
    const _coverData = {
      ...state?.packages?.health?.[currentHealth]?.[selected],
    };
    if (val?.toggle === false) {
      if (val?.type === 'BOOL') {
        _coverData[val?.key] = 0;
      } else if (val?.toggleOffValue !== undefined) {
        _coverData[val?.key] = val?.toggleOffValue;
      } else delete _coverData[val?.key];
    } else {
      if (val?.amount) {
        if (val?.type === 'ENUM') {
          if (val?.targetKey) {
            _coverData[val?.targetKey] =
              val?.amount?.value || val?.amount || '';
            _coverData[val?.key] = 1;
          } else {
            _coverData[val?.key] = val?.amount?.value || val?.amount || '';
          }
        } else _coverData[val?.key] = val?.amount || '';
      } else if (val?.toggleOnValue) {
        if (val?.targetKey) {
          _coverData[val?.targetKey] = val?.toggleOnValue || '';
          _coverData[val?.key] = 1;
        } else {
          _coverData[val?.key] = val?.toggleOnValue || '';
        }
      } else if (val?.toggleOnAmount) {
        _coverData[val?.key] = val?.toggleOnAmount || '';
      } else {
        if (val?.type === 'ENUM') {
          _coverData[val?.key] = val?.amount?.value || val?.amount || '';
        } else _coverData[val?.key] = val?.amount || '';
      }
    }
    if (val?.amount !== 'Included') {
      editPackage({
        type: 'health',
        current: currentHealth,
        selected,
        data: _coverData,
      });
    }
  }

  async function handleNextRoute() {
    setIsAdding(true);
    const payload = getPayload(storage?.data, selected, currentHealth, state);
    payload.SavePolicy = 'true';
    payload.MedicalStatus = 0;
    const resp = await handleApiCall(payload);
    setIsAdding(false);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        health_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['healths', currentHealth],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Health > +currentHealth + 1) {
        let nextHealth = 1 + +currentHealth;
        renderNextRoute(1, { health: nextHealth });
      } else {
        renderNextRoute(2);
      }
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      MultipolicyDiscount: 10,
      FiveYearsNoClaimDiscount: 5,
      TenYearsNoClaimDiscount: 10,
      FleetDiscount: 5,
      MyRideDiscount: 50,
      NewGraduateDiscount: 5,
      BrandNewCarDiscount: 10,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  return (
    <Container>
      <TopContainer>
        <BackButton type="button" name="back" onClick={goBack}>
          <IconActionChevronLeft />
          {t('Back')}
        </BackButton>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected === insurance.value}
                borderColor={getPackageColor(insurance.value)}
                onClick={() => setSelected(insurance.value)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
      </TopContainer>
      <BottomContainer>
        <LeftContainer>
          <CoverDetailsTable>
            <Headers>
              <p>{t('Cover details')}</p>
              <p>{t('Cover limit')}</p>
              <p>{t('Premium')}</p>
            </Headers>
            {isLoading && !policyData[selected] ? (
              <LoadingBox>
                <LoadingSpinner className="spinner" />
              </LoadingBox>
            ) : selected ? (
              <Scroll>
                <Content>
                  {data?.Error && (
                    <ErrorBoxLine>
                      <strong>{t('Error! ')}</strong>
                      {data?.Description}
                    </ErrorBoxLine>
                  )}
                  {getCoverDetailsHealth(
                    selected,
                    state?.packages?.health?.[currentHealth]?.[selected]
                  )?.map((details) => {
                    return (
                      <CoverDetail
                        details={details}
                        key={`${selected}_${details?.key}`}
                        premium={
                          policyData?.[selected]?.Data?.[details?.responseKey]
                        }
                        data={
                          state?.packages?.health?.[currentHealth]?.[selected]
                        }
                        sendChanges={receiveChanges}
                      />
                    );
                  })}
                </Content>
              </Scroll>
            ) : null}
          </CoverDetailsTable>
        </LeftContainer>
        <RightContainer>
          {!areDiscountsOpen && (
            <div>
              <Title>{t('Premium summary')}</Title>
              <PremiumItems>
                {premium_summary_items.map((item) => {
                  return (
                    <div>
                      <span>{item.value}</span>
                      <p>{item.amount}</p>
                    </div>
                  );
                })}
              </PremiumItems>
            </div>
          )}

          <StyledAccordionBox
            isOpen={areDiscountsOpen}
            onOpen={(open) => setAreDiscountsOpen(open)}
          >
            <AccordionBox.Header>
              <AccordionHeader>
                <p>{t('Enabled discounts')}</p>
                <DiscountCount>
                  <PieProgressIndicator
                    percentage={
                      discounts.length === 0
                        ? 0
                        : (discounts?.filter(
                            (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                          )?.length /
                            discounts?.length) *
                          100
                    }
                  />
                  <p>
                    {
                      discounts?.filter(
                        (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                      )?.length
                    }
                    /{discounts?.length}
                  </p>
                </DiscountCount>
              </AccordionHeader>
            </AccordionBox.Header>
            <AccordionBox.Content>
              <AccordionInfo>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] !== '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{t(discount[0])}</p>
                      <p className="discount-price">{discount[1]} &euro;</p>
                    </div>
                  ))}
                <Header>Possible discounts</Header>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] === '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{t(discount[0])}</p>
                      <p className="discount-price">
                        -{getDiscountPrice(discount[0])} &euro;
                      </p>
                    </div>
                  ))}
              </AccordionInfo>
            </AccordionBox.Content>
          </StyledAccordionBox>
          {isFetching && policyData[selected] && (
            <UpdatingContainer>
              <p>Updating price</p> <SmallLoader color="" />
            </UpdatingContainer>
          )}
          <ButtonContainer>
            <Total>
              <p>{t('Your total premium')}</p>
              <span>&euro; {policyData[selected]?.Data?.TotalwithFees}</span>
            </Total>
            <ActionButton
              disabled={data?.Error || isLoading || isAdding}
              onClick={() => handleNextRoute()}
            >
              {t('Add to basket')}{' '}
              {isAdding && (
                <SmallLoader style={{ marginLeft: '20px' }} color="" />
              )}
            </ActionButton>
          </ButtonContainer>
        </RightContainer>
      </BottomContainer>
    </Container>
  );
};

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
`;

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  & > p {
    margin-right: 0.8rem;
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin-bottom: 1.5rem;
  margin-top: 2.4rem;
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const AccordionInfo = styled(Scroll)`
  margin-top: -5.5rem;
  overflow-y: auto;
  max-height: 33vh;
  width: 100%;
  padding-right: 1.6rem;
  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }

    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1.8rem;
  border-top: 0.1rem solid #e4e4e4;

  & button {
    font-weight: bold;
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  min-width: 8rem;
  height: 4rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6.4rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 8rem;
    height: 2rem;
    justify-content: center;
    > div {
      margin-right: -3rem;
    }
  }
  p {
    font-weight: 550;
  }
`;

const StyledAccordionBox = styled(AccordionBox)`
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;
  margin-top: ${({ isOpen }) => (!isOpen ? 'auto' : '0')};
`;

const Total = styled.div`
  align-self: center;
  margin-bottom: 1rem;
  width: 100%;
  margin-top: 1rem;
  > p {
    font-size: 1.4rem;
    color: #8990a3;
    margin-bottom: 0.5rem;
  }
  > span {
    font-weight: 550;
    font-size: 2.1rem;
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    padding: 0.4rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
`;

const Headers = styled.div`
  border-bottom: 1px solid #e5e5e5;
  color: #8990a3;
  display: grid;
  font-weight: 550;
  grid-template-columns: 55% 25% 20%;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  p:first-child {
    padding-left: 6rem;
  }
`;

const Content = styled.div`
  padding-right: 1.5rem;

  > div {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    padding: 0.8rem 0;
  }
`;

const CoverDetailsTable = styled.div`
  height: 100%;
  margin: 0;
`;

const LeftContainer = styled.div`
  height: 100%;
  margin: 0;
  overflow: hidden;
`;

const RightContainer = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
  padding: 1.5rem;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected }) => (selected ? `0.2rem solid` : '1px solid')};
  border-color: ${({ selected, borderColor }) =>
    selected ? borderColor : '#e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 0.5rem;
  color: ${({ selected, borderColor }) => (selected ? borderColor : '#cfcece')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  > p {
    font-size: 1.75rem;
    text-align: center;
    width: 100%;
  }
  span > svg {
    height: 4rem;
    width: 3rem;
  }
  @media (max-width: 1000px) {
    padding: 0 1rem;
    p {
      font-size: 1.5rem;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 15% 85%;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    grid-template-rows: 18% 82%;
  }
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ErrorBoxLine = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 5px;

  strong {
    font-weight: bold;
  }
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 2rem;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-rows: 45% 55%;
    grid-template-columns: unset;
  }
`;

export default FlowHealthPremiumSummary;
