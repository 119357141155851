import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import FlowAddressContainer from '../components/FlowAddressContainer';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import { ActionButton } from 'wg-fe-ui';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { retrieveAddressInfo } from '../helpers/apiRouterService';
import FlowChatBox from '../components/FlowChatBox';

const FlowActualRiskAddressMobile = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
    buildingName: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Please fill in the address that you would like to insure'
      ),
      slug: 'risk-address-actual',
    };
    addQuestion(question);
    defaultValuesHandler();
    setInitAddress();
  }, []);

  const setInitAddress = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    const { risk_addresses } = data;

    if (
      risk_addresses != null &&
      risk_addresses[currentProperty]?.address != null
    ) {
      const {
        street,
        city,
        zipcode,
        boxnr,
        housenr,
        buildingName,
      } = risk_addresses[currentProperty].address;
      const tempData = {
        streetName: street,
        municipalityName: city,
        postalCode: zipcode,
        boxNumber: boxnr,
        streetNumber: housenr,
        buildingName: buildingName,
      };
      Object.keys(tempData).forEach((key) =>
        tempData[key] === undefined ? delete tempData[key] : {}
      );
      setDefaultValues(tempData);
    }
  };

  function receiveNewAddress(address) {
    setDefaultValues(address);
    defaultValuesHandler();
  }

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (address) => {
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
    } = address;

    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: 'CY',
      boxnr: boxNumber,
      city: municipalityName,
    });

    if (status / 100 === 2) {
      const element = {
        message: {
          address: resp.address,
          blankImage: resp.aerial_images_blank_satellite,
          image: resp.aerial_images_marked_satellite,
        },
        slug: 'address_response',
      };
      addAnswer(element);

      patchStorage({
        payload: resp,
        path: ['risk_addresses', [currentProperty]],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute(1, { property: currentProperty });
    }
    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <FlowAddressContainer
          title={i18n.t(`Fill in the address you would like to insure`)}
          errors={errors}
          values={values}
          defaultValues={defaultValues}
          sendAddress={receiveNewAddress}
          handleChange={handleChange}
        />
        <form onSubmit={handleForm}>
          <ButtonContainer>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {values.streetNumber != null
                  ? i18n.t('Submit')
                  : i18n.t('Search')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowActualRiskAddressMobile;
