import React from 'react';

const HelEcoGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M39.8,78.37A38.57,38.57,0,1,1,78.37,39.8,38.61,38.61,0,0,1,39.8,78.37Z"
            style={{
              fill: 'none',
              stroke: '#dd88b0',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <polygon
            points="56.27 36.17 44.38 36.17 44.38 24.28 35.86 24.28 35.86 36.17 23.98 36.17 23.98 44.69 35.86 44.69 35.86 56.57 44.38 56.57 44.38 44.69 56.27 44.69 56.27 36.17"
            style={{
              fill: 'none',
              stroke: '#dd88b0',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default HelEcoGanIcon;
