import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurrencyInput from './CurrencyInput';
import DateInput from '../components/DateInput';
import styled from 'styled-components';
import i18n from '../i18n';
import { Label, SearchSelectInput, IconActionPlus } from 'wg-fe-ui';
import { claim_types } from '../constants/FlowSearchSelectData';
import _ from 'lodash';

const AccidentInput = ({ sendAccidentArr }) => {
  const { insuranceType } = useParams();
  const [claimDate, setClaimDate] = useState();
  const [claimAmount, setClaimAmount] = useState();
  const [accidentArr, setAccidentArr] = useState([]);
  const [claimType, setClaimType] = useState();
  function handleAddClaim() {
    if (!claimAmount?.value && !claimDate?.value && !claimType?.value) return;
    accidentArr.push({
      claim_amount: claimAmount.value,
      claim_date: claimDate.value,
      claim_type: claimType.value,
    });
    let arr = _.uniq(accidentArr);
    setAccidentArr(arr);
    setClaimAmount('');
    setClaimDate('');
  }

  useEffect(() => {
    sendAccidentArr(accidentArr);
  }, [accidentArr]);

  return (
    <>
      <ClaimQuestions insurance={insuranceType}>
        <div>
          <StyledLabel>{i18n.t('Date')}</StyledLabel>
          <DateInput
            name="accident_date"
            onChange={(val) => setClaimDate(val)}
          />
        </div>
        <div>
          <StyledLabel>{i18n.t('Claim type')}</StyledLabel>
          <SelectInput
            name="claim_type"
            onChange={(val) => {
              setClaimType(val);
            }}
            options={claim_types}
            placeholder={i18n.t('Choose your option')}
          />
        </div>
        <InputContainer>
          <CurrencyInput
            onChange={(val) => setClaimAmount(val)}
            name="claim_amount"
          >
            {i18n.t('Claim amount')}
          </CurrencyInput>
          <EuroSign>&euro;</EuroSign>
        </InputContainer>
      </ClaimQuestions>
      <AddAccident onClick={() => handleAddClaim()}>
        <IconActionPlus />
        {i18n.t('Add Accident')}
      </AddAccident>
    </>
  );
};

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  & div {
    margin-bottom: 0;
  }
`;

const ClaimQuestions = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 36px;
  > div {
    grid-column: ${({ insurance }) =>
      insurance === 'allMobile' ? 'span 6 / auto' : 'span 3 / auto'};
    height: 9rem;
  }
`;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-size: 1.5rem;
`;

const AddAccident = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.brand.primary};
  border-radius: 5px;
  font-weight: 550;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border: none;
  margin-bottom: 3rem;

  > svg {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: 1rem;
`;

const EuroSign = styled.span`
  border-left: 1px solid #e4e4e4;
  color: #8990a3;
  padding: 1.1rem;
  position: absolute;
  right: 0;
  top: 2.5rem;
`;

export default AccidentInput;
