import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import HomePremGanIcon from '../assets/icons/HomePremGanIcon';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';
import {
  ActionButton,
  IconStatusCheck,
  IconActionDropDown,
  SmallLoader,
} from 'wg-fe-ui';
import { useParams } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { createPropertyPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';

const getPropertyType = (type) => {
  switch (type) {
    case 'open':
      return 35;
    case 'halfopen':
      return 30;
    case 'Detached':
      return 29;
    case 'Appartment':
      return 25;
    default:
      return 30;
  }
};

const getConstructionMaterialString = (package_info) => {
  return `${package_info?.column_material
    ?.map((value) => `Columns / ${value?.value}`)
    .join(';')};${package_info?.floor
    ?.map((value) => `Floor / ${value?.value}`)
    .join(';')};${package_info?.roof
    ?.map((value) => `Roof / ${value?.value}`)
    .join(';')};${package_info?.walls
    ?.map((value) => `Walls / ${value?.value}`)
    .join(';')}`;
};

const getPayload = (_data, selected, currentProperty) => {
  const isPremium = selected === 'Premium';
  const payload = {
    Customer: {
      Oid: _data?.ganCustomer?.Oid,
    },
    PropertyStatus: 1, // What do all the enums entail?
    AvailiableProductsForCustomer: _data?.risk_addresses[currentProperty]
      ?.is_landlord
      ? 'FTP_and_PL_and_EL'
      : 'HHC_and_EL',
    SelectedProduct:
      selected === 'Value'
        ? _data?.risk_addresses[currentProperty]?.is_landlord
          ? 1
          : 3
        : 0,
    DoYouWishToHaveCargoInsurance: 0,
    DoesYourBusinessEmployeeAnyAdditionalPeople: 0,
    DoPeopleOtherThanEmployeesEnterYourPremisses: 0,
    DoesYourBusinessProvideProfessionalAdviseOrConstultationToCustomers: 0,
    DoesYourBusinessSupplieProductsOrComponentsToCustomers: 0,
    CouldTheftOrDishonestyByEmployeesBeAProblem: 0,
    PremisesHasMachineryStockOrOtherContents: 0,
    WillYourBusinessBeAffectedByAnyInterruptionLikeFireFloodDeniedAccess: 0,
    BusinessIsInvolvedInTransportinGoodsOrProducts: 0,
    BusinessOperateMotorVechiclesOrUsesStaffOwnVechicles: 0,
    BusinessProvidesHealthBenefitsToStaff: 0,
    ExistingInsuranceCompany: {
      InsuranceCompany:
        _data?.risk_addresses[currentProperty]?.current_insurance_info
          ?.insurance_company_name || 'None',
    },
    ExpiringDateOfYourCurrentPolicy:
      _data?.risk_addresses[currentProperty]?.current_insurance_info
        ?.end_of_current_contract || null,
    ExistingInsuranceCompanyPrice:
      _data?.risk_addresses[currentProperty]?.current_insurance_info
        ?.insurance_price || null,
    BuildingAddress: _data?.risk_addresses[currentProperty]?.Oid,
    FinancialInstitution:
      _data.risk_addresses[currentProperty]?.FinancialInstitution || null,
    JointOwnership: 0,
    PropertyType: getPropertyType(
      _data.risk_addresses[currentProperty]?.parcel?.main_building?.house_type
    ), // What are the oids of these building types?
    ManufactureYear:
      _data?.risk_addresses[currentProperty]?.parcel?.main_building
        ?.construction_year,
    DateOfLastRenovation:
      _data?.risk_addresses[currentProperty]?.parcel?.main_building
        ?.renovation_year ||
      new Date(
        _data?.risk_addresses[
          currentProperty
        ]?.parcel?.main_building?.construction_year
      ).toISOString(),
    ConsecutiveDaysUnoccupiedFor:
      _data?.ConsecutiveDaysUnoccupiedFor?.value || 1,
    ConstructionMaterial: getConstructionMaterialString(
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.construction_details
    ),
    MethodsOfHeating:
      _data.risk_addresses[
        currentProperty
      ].parcel?.main_building?.construction_details?.heating_method
        ?.map((value) => value?.value)
        ?.join(';') || 'None',
    WaterPipes:
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.WaterPipes || 1,
    UseOfProperty: 1,
    DoesYourPropertyHaveAntisesmicSurvey:
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.DoesYourPropertyHaveAntisesmicSurvey,
    TypeOfPremises: null, // What are the kids of PremisesType table?
    DoYouHaveAnyLargeQuantitiesOfWaterWithinPremises:
      'No Large Water Quantities', // What are the allowed enums WaterQuantitiesTypes table?
    SafetyFeatures: '',
    IsThePropertyNeighbouringToAnOpenAreaWithBushesOrShrubsOrTreesWithinThirtyMetersFromProperty: 0,
    IsThereAManMadeOrNaturalFirePortectionZone: 0,
    IsThePropertyAtARemoteDestination: 0,
    PolicyDurationMonths:
      _data.risk_addresses[currentProperty]?.policy_details?.insurance_duration,
    StartDate: new Date(
      _data.risk_addresses[currentProperty]?.policy_details?.starting_date
    ).toISOString(),
    EndDate: new Date(
      _data.risk_addresses[currentProperty]?.policy_details?.ending_date
    ).toISOString(),
    BuildingSumInsured:
      _data.risk_addresses[currentProperty]?.parcel?.main_building?.surface *
      1200,
    AccidentalDamageToBuilding: _data?.AccidentalDamageToBuilding || 0,
    ContentsSumInsured:
      _data.ContentsSumInsured ||
      _data.risk_addresses[currentProperty]?.parcel?.content_sum
        ?.insurance_price ||
      0,
    AccidentalDamageToContents: _data?.AccidentalDamageToContents || 0,
    TotalValueOfHighRiskItems: 0,

    SpecifiedItemsTotalValue: _data?.SpecifiedItemsTotalValue || 0,
    DomesticFreezeContents: _data?.DomesticFreezeContents || 0, // What does this entail?
    TracingTheLeakCoverAmount:
      _data?.TracingTheLeakCoverAmount?.value ||
      '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a',
    ValuableAndPersonalEffects: _data?.ValuableAndPersonalEffects || 0,
    PersonalMoneyAndCreditCards: _data?.PersonalMoneyAndCreditCards || 0,
    UsePedalCycles: !!_data?.UsePedalCycles ? 1 : 0,
    PetInsurance:
      _data.risk_addresses[currentProperty]?.extra_items_to_insure?.includes(
        'Pets'
      ) && isPremium
        ? 1
        : 0,
    PetsInProperty:
      _data.risk_addresses[currentProperty]?.PetsInProperty || null,
    PropertyInventory:
      _data.risk_addresses[currentProperty]?.PropertyInventory?.length > 0
        ? _data.risk_addresses[currentProperty]?.PropertyInventory
        : null,
    ClassicVehicleInsurance: isPremium ? 1 : 0,
    ClassicVehicleMarketValue: null,
    AccidentsToDomesticStaff: isPremium ? 1 : 0,
    VesselLiability: 0,
    SkiersLiability: 0,
    PassengerLiability: 0,
    HuntersLiability: 0,
    MedicalForDomesticStaff: 0,
    VesselInProperty: null,

    PedalCycles: _data.risk_addresses[currentProperty]?.Bikes
      ? _data.risk_addresses[currentProperty]?.Bikes
      : null,
    DomesticStaff: null,
    ContentsSumInsuredExcludingSAndC: null, // Some more explanation?
    TheftForContents: 0,
    StockSumInsured: null,
    TheftForStock: 0,
    OwnComputerEquipmentSumInsured: null,
    TotalElectronicEquipmentSumInsured: null,
    MoneyCoverSumInsured: null,
    BusinessInterruptionSumInsured: null,
    BusinessInterruptionForRegisteredCoreEmployeesSalaries: null,
    NetAnnualSalaryForAllCoreRegisteredEmployees: null,
    LimitOfIdemnityRequiredForAnyOneClaimSumInsured: null,
    NoOfAllEmployees: null,
    AnnualWagesAndSalariesForAllEmployees: null,
    SumInsuredByAnyOneConsignment: null,
    PublicLiability: 0,
    DoYouHaveAWrittenQualityProceduresManual: 0,
    ProductLiability: 0,
    EmployersLiablity: _data.EmployersLiablity || 0,
    EmployersSocialSecurityNo: null,
    EmployersLiabilityList: null,
    PersonalAccident: 0,
    NoOfPersonsForPersonalAccidentCover: null,
    NoOfUnitsForEachEmployee: null, // What do these units entail?
    PersonalAccidentPersons: null,
    GlassSumInsured: null,
    SignSumInsured: null,
    PanesOfGlassWhereLengthAndWidthExceed5mOrTotalAreaExceeds10cm: null, // TotalAreaExceeds10cm, shouldn’t this be 10m?
    HomeBusinessSecure: { Oid: isPremium ? 2 : 5 },
    TotalCoverAreaOfGlassInSquareCm: null,
    SpecifiedItemsSumInsured: null,
    UnspecifiedItemsSumInsured: null,
    ProfessionalIdemnity: 0, // What does this entail?
    NoOfEmployeesThatYouWishToInsureForProfessionalIdemity: null,
    CountryYourBusinessIsDomicited: null, // Where can I get _data from Countries  Table?
    TotalTurnoverOfTheLastYearIncludingFeeIncome: null,
    HaveYouBeenChargedWithACriminalOffence: 0,
    TotalResponse: _data?.TotalResponse?.value || 4, // What does this entail + where can I get data from PropertyTotalResponse Table
    EarthquakeExcess: 1148,
  };
  return payload;
};

const FlowHomeInsuranceOptionsMobile = () => {
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [open, setOpen] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createPropertyPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentProperty)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, failureCount } = useQuery(
    ['propertyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  useEffect(() => {
    const packageIndex = packages.findIndex(
      (pack) =>
        pack.value ===
        storage?.data?.risk_addresses[currentProperty]?.insurance_pack
    );
    if (packageIndex > -1) setSelected(packages[packageIndex]);
  }, []);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  const ValueDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
  ];

  const PremiumDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
    { value: 'Accidental damage to building' },
    { value: 'Accidental damage to contents' },
    { value: 'Valuable & personal effects' },
    { value: 'Specified items' },
    { value: 'Contents of frozen food cabinet' },
    { value: 'Personal money & credit cards' },
    { value: ' Tracing the leak' },
    { value: 'Pedal cycles' },
    { value: 'Pet insurance' },
    { value: 'Classic vehicle cover' },
    { value: 'Speed boats third perty liability' },
    { value: 'Jet-skiers liability' },
    { value: 'Passengers liability' },
    { value: 'Hunters liability' },
    { value: 'Medical to domestic staff' },
    { value: 'Accidents to domestic staff' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
  ];

  const landlordDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
  ];

  const packages = [
    ...(storage.data?.risk_addresses?.[currentProperty]?.is_landlord
      ? [
          {
            value: 'Value',
            description:
              'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
            benefits: ValueDetails,
          },
          {
            value: 'Premium',
            description:
              'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
            benefits: PremiumDetails,
          },
        ]
      : [
          {
            value: 'Landlord',
            description:
              'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
            benefits: landlordDetails,
          },
        ]),
  ];

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    if (data.risk_addresses[currentProperty].insurance_pack) {
      setSelected(data.risk_addresses[currentProperty].home_insurance_pack);
    }
  }, []);

  function iconType(icon) {
    switch (icon) {
      case 'Value':
        return <HomeCatGanIcon />;
      case 'Premium':
        return <HomePremGanIcon />;
      default:
        return <HomeCatGanIcon />;
    }
  }

  const submitHandler = () => {
    patchStorage({
      payload: { home_insurance_pack: selected },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <LeftContainer>
        <TitleForm>{i18n.t('Choose your package')}</TitleForm>
        <TotalPremium>
          <p>{i18n.t('Your total premium')}</p>
          {isLoading ? (
            <UpdatingContainer>
              <SmallLoader color="" />
            </UpdatingContainer>
          ) : (
            policyData[selected?.value]?.Data?.TotalwithFees && (
              <span>
                <strong>
                  {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
                </strong>{' '}
              </span>
            )
          )}
        </TotalPremium>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected?.value === insurance.value}
                onClick={() => setSelected(insurance)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
      </LeftContainer>
      <RightContainer>
        {selected != null && (
          <MenuWrapper open={open} onClick={() => setOpen(!open)}>
            <span>
              <span>{i18n.t('Specifics')}</span>
              <p open={open}>
                {open ? i18n.t('See less') : i18n.t('See more')}
                <IconActionDropDown open={open} />
              </p>
            </span>
            <EconomicPackageDetailsContainer open={open}>
              {selected?.benefits?.map((detail) => {
                return (
                  <DetailContainer>
                    <IconStatusCheck />
                    <p>{detail.value}</p>
                  </DetailContainer>
                );
              })}
              <div>{selected?.description}</div>
            </EconomicPackageDetailsContainer>
          </MenuWrapper>
        )}
        <ButtonContainer>
          <ActionButton
            onClick={() => submitHandler()}
            type="submit"
            value="Submit"
            disabled={data?.Error || !selected || isLoading}
            data-test-id="property_information_submit"
          >
            {i18n.t('Specify')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const MenuWrapper = styled.div`
  box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  overflow: auto;

  font-family: ${({ theme }) => theme.font};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  top: ${({ open }) => (open ? '25rem' : 'calc(100% - 11rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 7rem;
  z-index: 1;
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.7rem 2rem 0 2rem;
    > span {
      color: ${({ theme }) => theme.brand.primary};
      font-weight: 900;
      font-size: 2rem;
    }
    p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
      > svg {
        margin-left: 1rem;
        transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
        transition: transform 700ms cubic-bezier(1, 0, 0, 1);
        > path {
          stroke: ${({ theme }) => theme.brand.primary};
        }
      }
    }
  }
`;

const TotalPremium = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1.6rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
  }
  span {
    color: #0e0e0e;
    display: flex;
    font-size: 2.1rem;
    font-weight: 550;
    strong {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  height: 100%;
  align-items: flex-end;
  width: 100%;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: ${({ open }) => (!open ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: scroll;
  div:last-child {
    font-size: 1.25rem;
    color: rgba(48, 55, 61, 0.66);
    padding: 2rem;
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  p {
    align-self: center;
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.brand.primary}` : '2px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-evenly;
  width: 100%;

  > p {
    font-size: 1.2rem;
    text-align: center;
  }
  span > svg {
    height: 4rem;
    width: 4rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  justify-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: scroll;
  flex-direction: column;
  padding: 2rem;
`;

const RightContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin: 1rem 0;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 35% 65%;
  width: 100%;
`;

const UpdatingContainer = styled.div`
  height: 2rem;
`;

const ErrorBox = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-items: center;
`;

export default FlowHomeInsuranceOptionsMobile;
