import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeExistingPropertyInsuranceQuestionMobile = () => {
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [, setError] = useState();
  const [
    currentHealthInsuranceState,
    setCurrentHealthInsuranceState,
  ] = useState();
  const [selectedLabel, setSelectedLabel] = useState();

  const answers = [
    { label: i18n.t('Yes, and I know the insurer'), value: 0 },
    { label: i18n.t('Yes, but I dont know the insurer'), value: 1 },
    { label: i18n.t('No'), value: 2 },
  ];

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Do you currently have a <span>health insurance policy</span>?'
      ),
      slug: 'existing_health_insurance',
    };
    addQuestion(question);
    // loadDefaultValues();
  }, []);

  // const loadDefaultValues = async () => {
  //   let defaultValuesTemp;

  //   defaultValuesTemp = {
  //     currently_have_property_insurance: getNestedObject(data, [
  //       'healths',
  //       [currentHealth],
  //       'currently_have_property_insurance',
  //     ]),
  //     insurances: data.insurances,
  //   };
  //   setDefaultValues(defaultValuesTemp);
  //   if (defaultValuesTemp?.currently_have_property_insurance != null) {
  //     setCurrentHealthInsuranceState(
  //       defaultValuesTemp?.currently_have_property_insurance
  //     );
  //   }
  // };

  const handlehealthInsurance = (e, answer) => {
    e.preventDefault();
    setCurrentHealthInsuranceState(answer.value);
    setSelectedLabel(answer.label);
  };

  useEffect(() => {
    if (currentHealthInsuranceState == null) {
      setError('Please answer the question above');
      return;
    } else {
      setError();
    }

    patchStorage({
      payload: {
        currently_have_health_insurance: currentHealthInsuranceState,
      },
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: i18n.t(selectedLabel),
      slug: 'existing_health_insurance',
    };

    //TODO FIX NO FLOW

    addAnswer(answer);
    if (currentHealthInsuranceState === 0) {
      renderNextRoute(1, { health: currentHealth });
    } else if (currentHealthInsuranceState === 1) {
      renderNextRoute(2, { health: currentHealth });
    } else {
      patchStorage({
        payload: {
          insurance_company_name: 'None',
        },
        path: ['healths', [currentHealth]],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute(3, { health: currentHealth });
    }
  }, [currentHealthInsuranceState]);

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form>
          {answers.map((answer) => {
            return (
              <StyledActionButton
                type="submit"
                checked={selectedLabel === answer.label}
                onClick={(e) => {
                  handlehealthInsurance(e, answer);
                }}
              >
                {answer.label}
              </StyledActionButton>
            );
          })}
        </form>
      </RightContainer>
    </Container>
  );
};

const StyledActionButton = styled.button`
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.primary : 'white'};
  border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ checked, theme }) => (checked ? '#FFFFFF' : theme.brand.primary)};
  cursor: pointer;
  font-weight: 550;
  height: 3.5rem;
  width: 100%;
  &:focus {
    outline: 0;
  }

  margin-bottom: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  form {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowHomeExistingPropertyInsuranceQuestionMobile;
