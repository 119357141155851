import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowAverageDistanceTravelledMobile = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [rangeInput, setRangeInput] = useState(0);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'How <span>many km/year</span> will the vehicle travel approximately?'
      ),
      slug: 'average_km_yearly',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const loadDefaultValues = async () => {
    let defaultValuesTemp;
    defaultValuesTemp = {
      average_km_yearly: getNestedObject(data, [
        'cars',
        [currentVehicle],
        'average_km_yearly',
      ]),
    };
    if (defaultValuesTemp?.average_km_yearly != null) {
      setRangeInput(defaultValuesTemp?.average_km_yearly);
    }
  };

  const submitHandler = () => {
    patchStorage({
      payload: { average_km_yearly: rangeInput },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: i18n.t(`Around`, { km: rangeInput }),
      slug: 'average_km_yearly',
    };
    addAnswer(answer);
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  function handleRangeInput(e) {
    setRangeInput(e.target.value);
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <InputContainer>
            <RangeContainer>
              <p className="min">{i18n.t('5,000 km')}</p>
              <p className="max">{i18n.t('25,000 km')}</p>
            </RangeContainer>
            <MaxConsecutiveDays>
              <input
                type="range"
                min="5000"
                max="25000"
                value={rangeInput}
                onChange={(e) => handleRangeInput(e)}
                step="100"
              />
              <p>
                <span>{rangeInput} </span>
                km
              </p>
            </MaxConsecutiveDays>
          </InputContainer>
          <ButtonContainer>
            <ActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Submit')}
            </ActionButton>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const InputContainer = styled.div`
  margin-bottom: 6rem;
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  form {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowAverageDistanceTravelledMobile;
