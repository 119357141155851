import React, { createContext, useReducer } from 'react';

const initialState = {
  chatMessages: [],
};

export const ChatReducer = (state, action) => {
  const tempState = JSON.parse(JSON.stringify(state));
  if (action.type === 'CLEAR_CHAT') {
    return {
      chatMessages: [],
    };
  }
  const index = tempState?.chatMessages?.findIndex(
    (item) => item?.question_slug === action?.payload.question_slug
  );
  if (index > -1) {
    if (action.type !== 'ADD_ANSWER') {
      tempState.chatMessages = tempState.chatMessages.splice(0, index);
    }
  }

  switch (action.type) {
    case 'ADD_QUESTION':
      return {
        ...tempState,
        chatMessages: [...tempState.chatMessages, action.payload],
      };
    case 'ADD_ANSWER':
      return {
        ...tempState,
        chatMessages: [...tempState.chatMessages, action.payload],
      };
    default:
      return tempState;
  }
};

export const ChatContext = createContext(initialState);

export const ChatContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChatReducer, initialState);
  function addQuestion({ message, slug }) {
    if (state.chatMessages) {
      state.chatMessages.filter((item) => item.question_slug === slug);
    }
    dispatch({
      type: 'ADD_QUESTION',
      payload: {
        message: message,
        question_slug: slug,
        isQuestion: true,
      },
    });
  }

  function clearChat() {
    dispatch({
      type: 'CLEAR_CHAT',
    });
  }

  function addAnswer({ message, slug }) {
    if (state.chatMessages) {
      state.chatMessages.map((item) => item.slug === slug);
    }
    dispatch({
      type: 'ADD_ANSWER',
      payload: {
        message,
        slug,
        isQuestion: false,
      },
    });
  }

  return (
    <ChatContext.Provider
      value={{
        chatMessages: state.chatMessages,
        addQuestion,
        addAnswer,
        clearChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
