/* stylelint-disable value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Drawer from 'react-drag-drawer';
import { string } from 'prop-types';

const InfoPopUp = ({ info, title, buttonText, className }) => {
  const [togglen, setToggle] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setToggle(false);
      }
    });
  }, []);

  const toggleDrawer = () => {
    setToggle(!togglen);
  };

  return togglen ? (
    <>
      <InfoButton className={className}>{buttonText}</InfoButton>
      <Drawer open={true} onRequestClose={() => toggleDrawer()}>
        <InfoContainer>
          <CloseButton onClick={() => toggleDrawer()}>x</CloseButton>
          <InfoBlock>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: info }} />
          </InfoBlock>
        </InfoContainer>
      </Drawer>
    </>
  ) : (
    <InfoButton
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        toggleDrawer();
      }}
    >
      {buttonText}
    </InfoButton>
  );
};

const InfoContainer = styled.div`
  color: white;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 66rem;
  overflow: auto;
  z-index: 9999;
`;

const InfoBlock = styled.div`
  padding: 5rem;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font};

  & h2 {
    font-size: 1.6rem;
    color: black;
    margin-bottom: 2rem;
    font-weight: 900;

    ::first-letter {
      text-transform: capitalize;
    }
  }

  & p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.typo.subTitle};
  }

  & h3 {
    font-weight: bold;
  }

  & span {
    font-weight: bold;
  }
`;

const InfoButton = styled.a`
  color: ${({ theme }) => theme.brand.primary};
  font-weight: 900;
  transition: background-color 0.1s ease;
  border: none;
  width: auto;
  height: auto;
  font-weight: bold;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: black;
  position: absolute;
  right: 0;
  padding: 1.5rem;
  font-family: ${({ theme }) => theme.font};
  font-weight: 900;

  &:hover {
    color: ${({ theme }) => theme.brand.primary};
  }
`;

InfoPopUp.defaultProps = {
  info: '',
  title: '',
  buttonText: '?',
};

InfoPopUp.propTypes = {
  info: string,
  title: string,
  buttonText: string,
  className: string,
};

export default InfoPopUp;
