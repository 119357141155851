import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PieProgressIndicator } from 'wg-fe-ui';

interface Props {
  discounts: any;
}
const FlowOverviewDiscountsSectionHeader: React.FC<Props> = ({ discounts }) => {
  const { t } = useTranslation();

  return (
    <AccordionHeader>
      <p>{t('Enabled discounts')}</p>
      <DiscountCount>
        <PieProgressIndicator
          percentage={
            discounts.length === 0
              ? 0
              : (discounts?.filter(
                  (d: any) => d[1] !== '0,00' && d[0] !== 'Discount'
                )?.length /
                  discounts?.length) *
                100
          }
        />
        <p>
          {
            discounts?.filter(
              (d: any) => d[1] !== '0,00' && d[0] !== 'Discount'
            )?.length
          }
          /{discounts?.length}
        </p>
      </DiscountCount>
    </AccordionHeader>
  );
};

export default FlowOverviewDiscountsSectionHeader;

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6.4rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 8rem;
    height: 2rem;
    justify-content: center;
    > div {
      margin-right: -3rem;
    }
  }
  p {
    font-weight: 550;
  }
`;
