import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeExistingPropertyInsuranceQuestion = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const [error, setError] = useState();
  const [, setDefaultValues] = useState({});
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const history = useHistory();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [
    currentPropertyInsuranceState,
    setCurrentPropertyInsuranceState,
  ] = useState();
  const [
    currentPropertyInsuranceKnown,
    setCurrentPropertyInsuranceKnown,
  ] = useState();
  const [selectedLabel, setSelectedLabel] = useState();

  const answers = [
    { label: i18n.t('Yes, and I know the insurer'), value: true },
    { label: i18n.t('Yes, but I dont know the insurer'), value: true },
    { label: i18n.t('No'), value: false },
  ];

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Do you currently have a <span>property insurance policy</span>?'
      ),
      slug: 'existing_property_insurance',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    let defaultValuesTemp;

    defaultValuesTemp = {
      currently_have_property_insurance: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'currently_have_property_insurance',
      ]),
      insurances: data.insurances,
    };
    setDefaultValues(defaultValuesTemp);
    if (defaultValuesTemp?.currently_have_property_insurance != null) {
      setCurrentPropertyInsuranceState(
        defaultValuesTemp?.currently_have_property_insurance
      );
    }
  };

  const handlePropertyInsurance = (e, answer) => {
    e.preventDefault();
    setCurrentPropertyInsuranceState(answer.value);
    setSelectedLabel(answer.label);
    if (
      answer.label === 'Yes, but I dont know the insurer' ||
      answer.label === 'No'
    ) {
      setCurrentPropertyInsuranceKnown(false);
    } else {
      setCurrentPropertyInsuranceKnown(true);
    }
  };

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const submitHandler = () => {
    if (currentPropertyInsuranceState == null) {
      setError('Please answer the question above');
      return;
    } else {
      setError();
    }
    setLoading(true);

    if (
      !risk_addresses[currentProperty].parcel.main_building
        .currently_have_property_insurance === currentPropertyInsuranceState ||
      risk_addresses[currentProperty].parcel.main_building
        .currently_have_property_insurance == null
    ) {
      patchStorage({
        payload: {
          currently_have_property_insurance: currentPropertyInsuranceState,
        },
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
    }

    const answer = {
      message: i18n.t(selectedLabel),
      slug: 'existing_property_insurance',
    };

    //TODO FIX NO FLOW

    addAnswer(answer);
    if (
      currentPropertyInsuranceState === true &&
      currentPropertyInsuranceKnown === true
    ) {
      renderNextRoute(1, { property: currentProperty });
    } else if (currentPropertyInsuranceKnown === false) {
      patchStorage({
        payload: {
          insurance_company_name: 'None',
        },
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute(2, { property: currentProperty });
    }
    setLoading(false);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <TitleForm>
              {i18n.t('Do you currently have a property insurance policy?')}
            </TitleForm>
            <div>
              {answers.map((answer) => {
                return (
                  <StyledActionButton
                    checked={selectedLabel === answer.label}
                    onClick={(e) => {
                      handlePropertyInsurance(e, answer);
                    }}
                  >
                    {answer.label}
                  </StyledActionButton>
                );
              })}
            </div>
          </div>
          <Error>{error}</Error>
          <ButtonContainer>
            <div>
              <BackButton type="button" name="back" onClick={goBack}>
                <IconActionChevronLeft />
                {i18n.t('Back')}
              </BackButton>
              {loading ? (
                <LoadingActionButton
                  type="submit"
                  value="Confirm"
                  data-test-id="risk_address_flow_loading"
                >
                  {i18n.t('Loading')}
                </LoadingActionButton>
              ) : (
                <ActionButton
                  type="submit"
                  value="Confirm"
                  data-test-id="risk_address_flow_submit"
                >
                  {i18n.t('Next')}
                </ActionButton>
              )}
            </div>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  text-align: center;
  font-size: 1.4rem;
  color: #f74040;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledActionButton = styled.button`
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.primary : 'white'};
  border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ checked, theme }) => (checked ? '#FFFFFF' : theme.brand.primary)};
  cursor: pointer;
  font-weight: 550;
  height: 5.3rem;
  margin-bottom: 1rem;
  width: 100%;
  &:focus {
    outline: 0;
  }
`;

const ButtonContainer = styled.div`
  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeExistingPropertyInsuranceQuestion;
