export const diseases = [
  { value: '1', label: { en: '001 Cholera', gr: 'Χολέρα' } },
  {
    value: '2',
    label: {
      en: '002 Typhoid and paratyphoid fevers',
      gr: 'Τυφοειδής και παρατυφοειδής πυρετοί',
    },
  },
  {
    value: '3',
    label: {
      en: '003 Other salmonella infections',
      gr: 'Άλλες μολύνσεις σαλμονέλας',
    },
  },
  {
    value: '4',
    label: { en: '004 Shigellosis', gr: 'Σιγκέλλωση (μικροβιακή δυσεντερία)' },
  },
  {
    value: '5',
    label: {
      en: '005 Other food poisoning (bacterial)',
      gr: 'Άλλες τροφικές δηλητηριάσεις (βακτηριακές)',
    },
  },
  { value: '6', label: { en: '006 Amoebiasis', gr: 'Αμοιβάδωση' } },
  {
    value: '7',
    label: {
      en: '007 Other protozoal intestinal diseases',
      gr: 'Άλλες πρωτοζωικές μολυσματικές ασθένειες',
    },
  },
  {
    value: '8',
    label: {
      en: '008 Intestinal infections due to other organisms',
      gr: 'Μολυσματικές μολύνσεις που οφείλονται σε άλλους οργανισμούς',
    },
  },
  {
    value: '9',
    label: {
      en: '009 Ill- defined intestinal infections',
      gr: 'Άρρωστες - ορισμένες μούσματικές μολύνσεις',
    },
  },
  {
    value: '10',
    label: {
      en: '010 Primary tuberculous infection',
      gr: 'Βασική φυματώδης μόλυνση',
    },
  },
  {
    value: '11',
    label: { en: '011 Pulmonary tuberculosis', gr: 'Πνευμονική φυματίωση' },
  },
  {
    value: '12',
    label: {
      en: '012 Other respiratory tuberculosis',
      gr: 'Άλλη αναπνευστική φυματίωση',
    },
  },
  {
    value: '13',
    label: {
      en: '013 Tuberculosis of meninges and central nervous system',
      gr: 'Φυματίωση στα μηνίγγια και στο κεντρικό νευρικό σύστημα',
    },
  },
  {
    value: '14',
    label: {
      en: '014 Tuberculosis of intestines peritoneum and mesenteric glands',
      gr:
        'Φυματίωση στα έντερα στο περιτόναιο και στους αδένες περιτοναϊκής μεμβράνης',
    },
  },
  {
    value: '15',
    label: {
      en: '015 Tuberculosis of bones and joints',
      gr: 'Φυματίωση στα κόκαλα και στις αρθρώσεις',
    },
  },
  {
    value: '16',
    label: {
      en: '016 Tuberculosis of genitourinary system',
      gr: 'Φυματίωση στο γεννητικό-ουροποιητικό σύστημα',
    },
  },
  {
    value: '17',
    label: {
      en: '017 Tuberculosis of other organs',
      gr: 'Φυματίωση σε άλλα όργανα',
    },
  },
  {
    value: '18',
    label: { en: '018 Miliary tuberculosis', gr: 'Κεγχροειδής φυματίωση' },
  },
  { value: '19', label: { en: '020 Plaque', gr: 'Μικροβιακή πλάκα' } },
  { value: '20', label: { en: '021 Tularaemia', gr: 'Τουλαρήμια' } },
  { value: '21', label: { en: '022 Anthrax', gr: 'Άνθραξ' } },
  { value: '22', label: { en: '023 Brucellosis', gr: 'Βρουκέλλωση' } },
  { value: '23', label: { en: '024 Glanders', gr: 'Μάλη' } },
  {
    value: '24',
    label: { en: '025 Melioidosis', gr: 'Μαλοειδές ή ψευδομάλη' },
  },
  {
    value: '25',
    label: { en: '026 Rat-bite fever', gr: 'Πυρετός από δάγκωμα τρωκτικού' },
  },
  {
    value: '26',
    label: {
      en: '027 Other zoonotic bacterial diseases',
      gr: 'Άλλες μεταδοτικές από ζώα ασθένειες',
    },
  },
  { value: '27', label: { en: '030 Leprosy', gr: 'Λέπρα' } },
  {
    value: '28',
    label: {
      en: '031 Disease due to other mycobacteria',
      gr: 'Ασθένεια που οφείλεται σε άλλα μυκητοβακτήρια',
    },
  },
  { value: '29', label: { en: '032 Diphtheria', gr: 'Διφθερίτιδα' } },
  { value: '30', label: { en: '033 Whooping cough', gr: 'Κοκίτης' } },
  {
    value: '31',
    label: {
      en: '034 Streptococcal sore throat and scariatina',
      gr: 'Στρεπτοκοκκική φαρυγγίτιδα και οστρακιά',
    },
  },
  { value: '32', label: { en: '035 Erysipelas', gr: 'Ερυσίπελας' } },
  {
    value: '33',
    label: { en: '036 Meningococcal infection', gr: 'Μηνιγγοκοκκική μόλυνση' },
  },
  { value: '34', label: { en: '037 Tetanus', gr: 'Τέτανος' } },
  { value: '35', label: { en: '038 Septicaemia', gr: 'Σηψαιμία' } },
  {
    value: '36',
    label: {
      en: '039 Actinomycotic infections',
      gr: 'Ακτινομυκητιακές μολύνσεις',
    },
  },
  {
    value: '37',
    label: {
      en: '040 Other bacterial diseases',
      gr: 'Άλλες βακτηριακές ασθένειες',
    },
  },
  {
    value: '38',
    label: {
      en:
        '041 Bacterial infection in conditions classified elsewhere and of unspecified site',
      gr:
        'Βακτηριακή μόλυνση σε καταστάσεις που ταξινομούνται αλλού και σε απροσδιόριστη θέση',
    },
  },
  {
    value: '39',
    label: {
      en: '045 Acute poliomyelitis',
      gr: 'Πολιομυελίτιδα οξείας μορφής',
    },
  },
  {
    value: '40',
    label: {
      en: '046 Slow virus infection of central nervous system',
      gr: 'Αργή μόλυνση του κεντρικού νευρικού συστήματος από ιό',
    },
  },
  {
    value: '41',
    label: {
      en: '047 Meningitis due to enterovirus',
      gr: 'Μηνιγγίτιδα οφειλόμενη σε εντερο-ιό',
    },
  },
  {
    value: '42',
    label: {
      en: '048 Other enterovirus diseases of central nervous system',
      gr: 'Άλλες ασθένειες του κεντρικού νευρικού συστήματος από εντερο-ιό',
    },
  },
  {
    value: '43',
    label: {
      en:
        '049 Other non-arthropod-borne viral diseases of central nervous system',
      gr:
        'Άλλες ιογενείς ασθένειες του κεντρικού νευρικού συστήματος που ο ιός να μην μεταδόθηκε από αρθρόποδα',
    },
  },
  { value: '44', label: { en: '050 Smallpox', gr: 'Ευλογιά' } },
  {
    value: '45',
    label: {
      en: '051 Cowpox and paravaccinia',
      gr: 'Αγελαδίτιδα και παραγελαδίτιδα',
    },
  },
  { value: '46', label: { en: '052 Chickenpox', gr: 'Ανεμοβλογιά' } },
  { value: '47', label: { en: '053 Herpes zoster', gr: 'Έρπις ζωστήρ' } },
  { value: '48', label: { en: '054 Herpes simplex', gr: 'Έρπις απλός' } },
  { value: '49', label: { en: '055 Measles', gr: 'Ιλαρά' } },
  { value: '50', label: { en: '056 Rubella', gr: 'Γερμανική ιλαρά' } },
  {
    value: '51',
    label: { en: '057 Other viral exanthemata', gr: 'Άλλα ιογενή εξανθήματα' },
  },
  { value: '52', label: { en: '060 Yellow fever', gr: 'Κίτρινος πυρετός' } },
  { value: '53', label: { en: '061 Dengue', gr: 'Δάγκειος πυρετός' } },
  {
    value: '54',
    label: {
      en: '062 Mosquito-borne viral encephalitis',
      gr: 'Ιογενής εγκεφαλίτιδα προερχόμενη από κουνούπι',
    },
  },
  {
    value: '55',
    label: {
      en: '063 Tick-borne viral encephalitis',
      gr: 'Ιογενής εγκεφαλίτιδα προερχόμενη από τσιμπούρι',
    },
  },
  {
    value: '56',
    label: {
      en:
        '064 Viral encephalitis transmitted by other and unspecified arthropods',
      gr:
        'Ιογενής εγκεφαλίτιδα που μεταδόθηκε από άλλα απροσδιόριστα αρθρόποδα',
    },
  },
  {
    value: '57',
    label: {
      en: '064a Other viral encephalitis',
      gr: 'Άλλη ιογενής εγκεφαλίτιδα',
    },
  },
  {
    value: '58',
    label: {
      en: '065 Arthropod-borne haemorrhagic fever',
      gr: 'Αιμορραγικός πυρετός',
    },
  },
  {
    value: '59',
    label: {
      en: '066 Other arthropod-borne viral diseases',
      gr: 'Άλλες ιογενείς ασθένειες προερχόμενες από αρθρόποδα',
    },
  },
  {
    value: '60',
    label: { en: '070 Viral hepatitis', gr: 'Ιογενής ηπατίτιδα' },
  },
  { value: '61', label: { en: '071 Rabies', gr: 'Λύσσα' } },
  { value: '62', label: { en: '072 Mumps', gr: 'Μαγουλάδες' } },
  { value: '63', label: { en: '073 Ornithosis', gr: 'Ψιττακίωση' } },
  {
    value: '64',
    label: {
      en: '074 Specific disease due to Coxsackie virus',
      gr: 'Συγκεκριμένη ασθένεια οφειλόμενη στον ιό Coxsackie',
    },
  },
  {
    value: '65',
    label: {
      en: '075 Infectious mononucleosis',
      gr: 'Μολυσματική μονοπυρήνωση',
    },
  },
  { value: '66', label: { en: '076 Trachoma', gr: 'Τράχωμα' } },
  {
    value: '67',
    label: {
      en: '076a Late effects of diseases due to viruses and Chlamydiae',
      gr:
        'Καθυστερημένα επακόλουθα ασθενειών προερχόμενων από ιούς και Χλαμύδια',
    },
  },
  {
    value: '68',
    label: {
      en: '077 Other diseases of conjunctiva due to viruses and Chlamydiae',
      gr:
        'Άλλες ασθένειες της μεμβράνης βλεφάρων οφειλόμενες σε ιούς και Χλαμύδια',
    },
  },
  {
    value: '69',
    label: {
      en: '078 Other diseases due to viruses and Chlamydiae',
      gr: 'Άλλες ασθένειες οφειλόμενες σε ιούς και Χλαμύδια',
    },
  },
  {
    value: '70',
    label: {
      en:
        '079 Viral infection in conditions classified elsewhere and of unspecified site',
      gr:
        'Ιογενής μόλυνση σε καταστάσεις που ταξινομούνται αλλού και σε ακαθόριστη θέση',
    },
  },
  {
    value: '71',
    label: {
      en: '080 Louse-borne [epidemic] typhus',
      gr: 'Τύφος [επιδημικός] προερχόμενος από ψείρες',
    },
  },
  { value: '72', label: { en: '081 Other typhus', gr: 'Άλλος τύφος' } },
  {
    value: '73',
    label: {
      en: '082 Tick-borne rickettsioses',
      gr: 'Ρικετσιώσεις προερχόμενες από τσιμπούρια',
    },
  },
  {
    value: '74',
    label: { en: '083 Other rickettsioses', gr: 'Άλλες ρικετσιώσεις' },
  },
  { value: '75', label: { en: '084 Malaria', gr: 'Μαλάρια' } },
  { value: '76', label: { en: '085 Leishmaniasis', gr: 'Λεϊσμανίαση' } },
  { value: '77', label: { en: '086 Trypanosomiasis', gr: 'Τρυπανοσωμίαση' } },
  {
    value: '78',
    label: { en: '087 Relapsing fever', gr: 'Επανερχόμενος πυρετός' },
  },
  {
    value: '79',
    label: {
      en: '088 Other arthropod-borne diseases',
      gr: 'Άλλες ασθένειες προερχόμενες από αρθρόποδα',
    },
  },
  {
    value: '80',
    label: { en: '090 Congenital syphilis', gr: 'Σύφιλη εκ γενετής' },
  },
  {
    value: '81',
    label: {
      en: '091 Early syphilis symptomatic',
      gr: 'Πρώιμη σύφιλη συμπτωματική',
    },
  },
  {
    value: '82',
    label: { en: '092 Early syphilis latent', gr: 'Πρώιμη σύφιλη λανθάνουσα' },
  },
  {
    value: '83',
    label: { en: '093 Cardiovascular syphilis', gr: 'Καρδιαγγειακή σύφιλη' },
  },
  { value: '84', label: { en: '094 Neurosyphilis', gr: 'Νευροσύφιλη' } },
  {
    value: '85',
    label: {
      en: '095 Other forms of late syphilis with symptoms',
      gr: 'Άλλοι τύποι ώριμης σύφιλης με συμπτώματα',
    },
  },
  {
    value: '86',
    label: { en: '096 Late syphilis latent', gr: 'Ώριμη σύφιλη λανθάνουσα' },
  },
  {
    value: '87',
    label: {
      en: '097 Other and unspecified syphilis',
      gr: 'Άλλη και ακαθόριστη σύφιλη',
    },
  },
  {
    value: '88',
    label: { en: '098 Gonococcal infections', gr: 'Γονοκοκκικές μολύνσεις' },
  },
  {
    value: '89',
    label: {
      en: '099 Other venereal diseases',
      gr: 'Άλλα αφροδισιακά νοσήματα',
    },
  },
  { value: '90', label: { en: '100 Leptospirosis', gr: 'Λεπτοσπείρωση' } },
  {
    value: '91',
    label: { en: "101 Vincent's angina", gr: 'Στηθάγχη Vincent' },
  },
  { value: '92', label: { en: '102 Yaws', gr: 'Τροπική ασθένεια' } },
  { value: '93', label: { en: '103 Pinta', gr: 'Είδος δερματικής ασθένειας' } },
  {
    value: '94',
    label: {
      en: '104 Other spirochaetal infection',
      gr: 'Άλλη σπειροχαιτική μόλυνση',
    },
  },
  { value: '95', label: { en: '110 Dermatophytosis', gr: 'Δερματοφυτία' } },
  {
    value: '96',
    label: {
      en: '111 Dermatomycosis other and unspecified',
      gr: 'Δερματομυκητίαση άλλη και ακαθόριστη',
    },
  },
  { value: '97', label: { en: '112 Candidiasis', gr: 'Στοματίτιδα' } },
  { value: '98', label: { en: '113 Actinomycose', gr: 'Ακτινομύκητας' } },
  {
    value: '99',
    label: { en: '114 Coccidioidomycosis', gr: 'Κοκκιδιοειδομυκητίαση' },
  },
  { value: '100', label: { en: '115 Histoplasmosis', gr: 'Ιστοπλάσμωση' } },
  {
    value: '101',
    label: {
      en: '116 Blastomycotic infection',
      gr: 'Μολυνση από Βλαστομύκητα',
    },
  },
  { value: '102', label: { en: '117 Other mycoses', gr: 'Άλλη μυκητίαση' } },
  {
    value: '103',
    label: { en: '118 Opportunistic mycoses', gr: 'Ευκαιριακή μυκητίαση' },
  },
  {
    value: '104',
    label: {
      en: '120 Schistosomiasis [bilharziasis]',
      gr: 'Σχιστοσωμίαση [μπιλχαρζίαση]',
    },
  },
  {
    value: '105',
    label: {
      en: '121 Other trematode infections',
      gr: 'Άλλες μολύνσεις του τρημοτόδιου',
    },
  },
  { value: '106', label: { en: '122 Echinococcosis', gr: 'Εχινοκόκκοση' } },
  {
    value: '107',
    label: { en: '123 Other cestode infection', gr: 'Άλλη κεστώδης μόλυνση' },
  },
  { value: '108', label: { en: '124 Trichinosis', gr: 'Τριχίνωση' } },
  {
    value: '109',
    label: {
      en: '125 Filarial infection and dracontiasis',
      gr: 'Μόλυνση από τον σκώληκα φιλαρια και δρακοντίαση',
    },
  },
  {
    value: '110',
    label: {
      en: '126 Ancylostomiasis and necatoriasis',
      gr: 'Αγκυλοστομίαση και νεκατορίαση',
    },
  },
  {
    value: '111',
    label: {
      en: '127 Other intestinal helminthiases',
      gr: 'Άλλες εντερικές ελμινθιάσεις',
    },
  },
  {
    value: '112',
    label: {
      en: '128 Other and unspecified helminthiases',
      gr: 'Άλλες και ακαθόριστες ελμινθιάσεις',
    },
  },
  {
    value: '113',
    label: {
      en: '129 Intestinal parasitism unspecified',
      gr: 'Εντερικοί παρασιτισμοί ακαθόριστοι',
    },
  },
  { value: '114', label: { en: '130 Toxoplasmosis', gr: 'Τοξοπλάσμωση' } },
  {
    value: '115',
    label: { en: '131 Trichomoniasis', gr: 'Μόλυνση από Τριχομονάδες' },
  },
  {
    value: '116',
    label: {
      en: '132 Pediculosis and phthirus infestation',
      gr: 'Φθειρίαση και παρασιτική φθειρίαση',
    },
  },
  { value: '117', label: { en: '133 Acariasis', gr: 'Ακαρίαση' } },
  {
    value: '118',
    label: { en: '134 Other infestation', gr: 'Άλλοι παρασιτισμοί' },
  },
  { value: '119', label: { en: '135 Sarcoidosis', gr: 'Σαρκοείδωση' } },
  {
    value: '120',
    label: {
      en: '136 Other and unspecified infectious and parasitic diseases',
      gr: 'Άλλες και αταξινόμητες μολυσματικές και παρασιτικές ασθένειες',
    },
  },
  {
    value: '121',
    label: {
      en: '137 Late effects of tuberculosis',
      gr: 'Ώριμα επακόλουθα φυματίωσης',
    },
  },
  {
    value: '122',
    label: {
      en: '138 Late effects of acute poliomyelitis',
      gr: 'Ώριμα επακόλουθα οξείας πολιομυελίτιδας',
    },
  },
  {
    value: '123',
    label: {
      en: '139 Late effects of other infectious and parasitic diseases',
      gr: 'Ώριμα επακόλουθα άλλων μολυσματικών και παρασιτικών ασθενειών',
    },
  },
  {
    value: '124',
    label: {
      en: '140 Malignant neoplasm of lip',
      gr: 'Κακοήθης νεοπλασία χείλους',
    },
  },
  {
    value: '125',
    label: {
      en: '141 Malignant neoplasm of tongue',
      gr: 'Κακοήθης νεοπλασία γλώσσας',
    },
  },
  {
    value: '126',
    label: {
      en: '142 Malignant neoplasm of major salivary glands',
      gr: 'Κακοήθης νεοπλασία σιελογόνων αδένων',
    },
  },
  {
    value: '127',
    label: {
      en: '143 Malignant neoplasm of gum',
      gr: 'Κακοήθης νεοπλασία ούλων',
    },
  },
  {
    value: '128',
    label: {
      en: '144 Malignant neoplasm of floor of mouth',
      gr: 'Κακοήθης νεοπλασία του πατώματος του στόματος',
    },
  },
  {
    value: '129',
    label: {
      en: '145 Malignant neoplasm of other and unspecified parts of mouth',
      gr: 'Κακοήθης νεοπλασία άλλων ακατονόμαστων μερών του στόματος',
    },
  },
  {
    value: '130',
    label: {
      en: '146 Malignant neoplasm of oropharynx',
      gr: 'Κακοήθης νεοπλασία του στοματοφάρυγγα',
    },
  },
  {
    value: '131',
    label: {
      en: '147 Malignant neoplasm of nasopharynx',
      gr: 'Κακοήθης νεοπλασία του ρινοφάρυγγα',
    },
  },
  {
    value: '132',
    label: {
      en: '148 Malignant neoplasm of hypopharynx',
      gr: 'Κακοήθης νεοπλασία του υποφάρυγγα',
    },
  },
  {
    value: '133',
    label: {
      en:
        '149 Malignant neoplasm of other and ill-defined sites within the lip oral cavity and pharynx',
      gr:
        'Κακοήθης νεοπλασία άλλων άρρωστων πλευρών των χηλέων της κοιλότητας του στόματος και του φάρυγγα',
    },
  },
  {
    value: '134',
    label: {
      en: '150 Malignant neoplasm of oesophagus',
      gr: 'Κακοήθης νεοπλασία του οισοφάγου',
    },
  },
  {
    value: '135',
    label: {
      en: '151 Malignant neoplasm of stomach',
      gr: 'Κακοήθης νεοπλασία του στομάχου',
    },
  },
  {
    value: '136',
    label: {
      en: '152 Malignant neoplasm of small intestine including duodenum',
      gr:
        'Κακοήθης νεοπλασία του μικρού εντέρου περιλαμβανομένου και του δωδεκαδάκτυλου',
    },
  },
  {
    value: '137',
    label: {
      en: '153 Malignant neoplasm of colon',
      gr: 'Κακοήθης νεοπλασία του μεγάλου εντέρου',
    },
  },
  {
    value: '138',
    label: {
      en: '154 Malignant neoplasm of rectum rectosigmoid junction and anus',
      gr:
        'Κακοήθης νεοπλασία του ορθού εντέρου της ορθοσιγμοειδούς ένωσης και του πρωκτού',
    },
  },
  {
    value: '139',
    label: {
      en: '155 Malignant neoplasm of liver and intrahepatic bile ducts',
      gr: 'Κακοήθης νεοπλασία του ήπατος και του ενδοηπατικού χοληδόχου σωλήνα',
    },
  },
  {
    value: '140',
    label: {
      en: '156 Malignant neoplasm of gallbladder and extra hepatic bile ducts',
      gr:
        'Κακοήθης νεοπλασία χοληδόχου κύστεος και του εξωηπατικού χοληδόχου σωλήνα',
    },
  },
  {
    value: '141',
    label: {
      en: '157 Malignant neoplasm of pancreas',
      gr: 'Κακοήθης νεοπλασία του παγκρέατος',
    },
  },
  {
    value: '142',
    label: {
      en: '158 Malignant neoplasm of retro peritoneum and peritoneum',
      gr: 'Κακοήθης νεοπλασία στο περιτόναιο και πίσω από αυτό',
    },
  },
  {
    value: '143',
    label: {
      en:
        '159 Malignant neoplasm of other and ill-defined sites within the digestive organs and peritoneum',
      gr:
        'Κακοήθης νεοπλασία σε άλλες άρρωστες πλευρές εντός των πεπτικών οργάνων και του περιτόναιου',
    },
  },
  {
    value: '144',
    label: {
      en:
        '160 Malignant neoplasm nasal cavities middle ear and accessory sinuses',
      gr:
        'Κακοήθης νεοπλασία ρινικών κοιλοτήτων μεσαίο αυτί και βοηθητικά ιγμόρεια',
    },
  },
  {
    value: '145',
    label: {
      en: '161 Malignant neoplasm of larynx',
      gr: 'Κακοήθης νεοπλασία του λάρυγκα',
    },
  },
  {
    value: '146',
    label: {
      en: '162 Malignant neoplasm of trachea bronchus and lung',
      gr: 'Κακοήθης νεοπλασία της τραχείας των βρόγχων και των πνευμόνων',
    },
  },
  {
    value: '147',
    label: {
      en: '163 Malignant neoplasm of pleura',
      gr: 'Κακοήθης νεοπλασία της υπεζωκότας',
    },
  },
  {
    value: '148',
    label: {
      en: '164 Malignant neoplasm of thymus heart and mediastinum',
      gr: 'Κακοήθης νεοπλασία του θύμου αδένα της καρδίας και του μεσοθωράκιου',
    },
  },
  {
    value: '149',
    label: {
      en:
        '165 Malignant neoplasm of other and ill-defined sites within the respiratory system and intrathoracic organs',
      gr:
        'Κακοήθης νεοπλασία άλλων άρρωστων πλευρών εντός του αναπνευστικού συστήματος και ενδοθωρακικών οργάνων',
    },
  },
  {
    value: '150',
    label: {
      en: '170 Malignant neoplasm of bone and articular cartilage',
      gr: 'Κακοήθης νεοπλασία κοκάλου και αρθρικού χόνδρου',
    },
  },
  {
    value: '151',
    label: {
      en: '171 Malignant neoplasm of connective and other soft tissue',
      gr: 'Κακοήθης νεοπλασία συνδετικού και άλλου μαλακού ιστού',
    },
  },
  {
    value: '152',
    label: {
      en: '172 Malignant melanoma of skin',
      gr: 'Κακόηθες μελάνωμα του δέρματος',
    },
  },
  {
    value: '153',
    label: {
      en: '173 Other malignant neoplasm of skin',
      gr: 'Άλλη κακοήθης νεοπλασία του δέρματος',
    },
  },
  {
    value: '154',
    label: {
      en: '174 Malignant neoplasm of female breast',
      gr: 'Κακοήθης νεοπλασία του θηλυκού στήθους',
    },
  },
  {
    value: '155',
    label: {
      en: '175 Malignant neoplasm of male breast',
      gr: 'Κακοήθης νεοπλασία του αρσενικού στήθους',
    },
  },
  {
    value: '156',
    label: {
      en: '179 Malignant neoplasm of uterus part unspecified',
      gr: 'Κακοήθης νεοπλασία μήτρας τμήμα απροσδιόριστο',
    },
  },
  {
    value: '157',
    label: {
      en: '180 Malignant neoplasm of cervix uteri',
      gr: 'Κακοήθης νεοπλασία του τραχήλου της μήτρας',
    },
  },
  {
    value: '158',
    label: {
      en: '181 Malignant neoplasm of placenta',
      gr: 'Κακοήθης νεοπλασία του πλακούντα',
    },
  },
  {
    value: '159',
    label: {
      en: '182 Malignant neoplasm of body of uterus',
      gr: 'Κακοήθης νεοπλασία της κοιλότητας της μήτρας',
    },
  },
  {
    value: '160',
    label: {
      en: '183 Malignant neoplasm of ovary and other uterine adnexa',
      gr: 'Κακοήθης νεοπλασία της ωοθήκης και άλλων μητρικών εξαρτημάτων',
    },
  },
  {
    value: '161',
    label: {
      en:
        '184 Malignant neoplasm of other and unspecified female genital organs',
      gr: 'Κακοήθης νεοπλασία άλλων ακαθόριστων θηλυκών γεννητικών οργάνων',
    },
  },
  {
    value: '162',
    label: {
      en: '185 Malignant neoplasm of prostate',
      gr: 'Κακοήθης νεοπλασία προστάτη',
    },
  },
  {
    value: '163',
    label: {
      en: '186 Malignant neoplasm of testis',
      gr: 'Κακοήθης νεοπλασία όρχεως',
    },
  },
  {
    value: '164',
    label: {
      en: '187 Malignant neoplasm of penis and other male genital organs',
      gr: 'Κακοήθης νεοπλασία πέους και άλλων ανδρικών γεννητικών οργάνων',
    },
  },
  {
    value: '165',
    label: {
      en: '188 Malignant neoplasm of bladder',
      gr: 'Κακοήθης νεοπλασία ουροδόχου κύστης',
    },
  },
  {
    value: '166',
    label: {
      en:
        '189 Malignant neoplasm of kidney and other and unspecified urinary organs',
      gr:
        'Κακοήθης νεοπλασία νεφρού και άλλων ακαθόριστων οργάνων του ουροποιητικού συστήματος',
    },
  },
  {
    value: '167',
    label: {
      en: '190 Malignant neoplasm of eye',
      gr: 'Κακοήθης νεοπλασία ματιού',
    },
  },
  {
    value: '168',
    label: {
      en: '191 Malignant neoplasm of brain',
      gr: 'Κακοήθης νεοπλασία εγκεφάλου',
    },
  },
  {
    value: '169',
    label: {
      en:
        '192 Malignant neoplasm of other and unspecified parts of nervous system',
      gr:
        'Κακοήθης νεοπλασία άλλων απροσδιόριστων μερών του νευρικού συστήματος',
    },
  },
  {
    value: '170',
    label: {
      en: '193 Malignant neoplasm of thyroid gland',
      gr: 'Κακοήθης νεοπλασία θυρεοειδούς αδένα',
    },
  },
  {
    value: '171',
    label: {
      en:
        '194 Malignant neoplasm of other endocrine glands and related structures',
      gr:
        'Κακοήθης νεοπλασία άλλων ενδοκρινών αδένων και συγγενικών κατασκευών',
    },
  },
  {
    value: '172',
    label: {
      en: '195 Malignant neoplasm of other and ill-defined sites',
      gr: 'Κακοήθης νεοπλασία άλλων και άρρωστων μερών',
    },
  },
  {
    value: '173',
    label: {
      en: '196 Secondary and unspecified malignant neoplasm of lymph nodes',
      gr: 'Δευτερεύουσα και ακαθόριστη κακοήθης νεοπλασία λεμφαδένων',
    },
  },
  {
    value: '174',
    label: {
      en:
        '197 Secondary malignant neoplasm of respiratory and digestive systems',
      gr:
        'Δευτερεύουσα κακοήθης νεοπλασία του αναπνευστικού και πεπτικού συστήματος',
    },
  },
  {
    value: '175',
    label: {
      en: '198 Secondary malignant neoplasm of other specified sites',
      gr: 'Δευτερεύουσα κακοήθης νεοπλασία άλλων καθορισμένων μερών',
    },
  },
  {
    value: '176',
    label: {
      en: '199 Malignant neoplasm without specification of site',
      gr: 'Κακοήθης νεοπλασία χωρίς καθορισμό του μέρους',
    },
  },
  {
    value: '177',
    label: {
      en: '200 Lymphosarcoma and reticulosarcoma',
      gr: 'Λεμφοσάρκωμα και δυχτιοσάρκωμα',
    },
  },
  {
    value: '178',
    label: { en: "201 Hodgkin's disease", gr: 'Ασθένεια Hodgkin' },
  },
  {
    value: '179',
    label: {
      en: '202 Other malignant neoplasm of lymphoid and histiocytic tissue',
      gr: 'Άλλη κακοήθης νεοπλασία λεμφοειδούς και ιστοκυτταρικού ιστού',
    },
  },
  {
    value: '180',
    label: {
      en: '203 Multiple myeloma and immunoproliferative neoplasms',
      gr: 'Πολλαπλό μυέλωμα και ανοσοϋπερπλαστικές νεοπλασίες',
    },
  },
  {
    value: '181',
    label: { en: '204 Lymphoid leukaemia', gr: 'Λεμφική λευχαιμία' },
  },
  {
    value: '182',
    label: { en: '205 Myeloid leukaemia', gr: 'Μυελοειδής λευχαιμία' },
  },
  {
    value: '183',
    label: { en: '206 Monocytic leukaemia', gr: 'Μονοκυτταρική λευχαιμία' },
  },
  {
    value: '184',
    label: {
      en: '207 Other specified leukaemia',
      gr: 'Άλλη καθορισμένη λευχαιμία',
    },
  },
  {
    value: '185',
    label: {
      en: '208 Leukaemia of unspecified cell type',
      gr: 'Λευχαιμία ακαθόριστου κυτταρικού τύπου',
    },
  },
  {
    value: '909',
    label: {
      en: '210 Benign neoplasm of lip oral cavity and pharynx (after surgery)',
      gr:
        'Καλοήθης νεοπλασία του χείλους στοματικής κοιλότητας και φάρυγγα (μετεγχειρητικά)',
    },
  },
  {
    value: '186',
    label: {
      en: '210 Benign neoplasm of lip oral cavity and pharynx (before surgery)',
      gr:
        'Καλοήθης νεοπλασία του χείλους στοματικής κοιλότητας και φάρυγγα (προεγχειρητικά)',
    },
  },
  {
    value: '910',
    label: {
      en:
        '211 Benign neoplasm of other parts of digestive system (after surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων μερών του πεπτικού συστήματος (μετεγχειρητικά)',
    },
  },
  {
    value: '187',
    label: {
      en:
        '211 Benign neoplasm of other parts of digestive system (before surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων μερών του πεπτικού συστήματος (προεγχειρητικά)',
    },
  },
  {
    value: '911',
    label: {
      en:
        '212 Benign neoplasm of respiratory and intrathoracic organs (after surgery)',
      gr:
        'Καλοήθης νεοπλασία των αναπνευστικών και ενδοθωρακικών οργάνων  (μετεγχειρητικά)',
    },
  },
  {
    value: '188',
    label: {
      en:
        '212 Benign neoplasm of respiratory and intrathoracic organs (before surgery)',
      gr:
        'Καλοήθης νεοπλασία των αναπνευστικών και ενδοθωρακικών οργάνων  (προεγχειρητικά)',
    },
  },
  {
    value: '912',
    label: {
      en: '213 Benign neoplasm of bone and articular cartilage (after surgery)',
      gr: 'Καλοήθης νεοπλασία οστικού και αρθρικού χόνδρου (μετεγχειρητικά)',
    },
  },
  {
    value: '189',
    label: {
      en:
        '213 Benign neoplasm of bone and articular cartilage (before surgery)',
      gr: 'Καλοήθης νεοπλασία οστικού και αρθρικού χόνδρου (προεγχειρητικά)',
    },
  },
  {
    value: '913',
    label: { en: '214 Lipoma (after surgery)', gr: 'Λίπωμα (μετεγχειρητικά)' },
  },
  {
    value: '190',
    label: { en: '214 Lipoma (before surgery)', gr: 'Λίπωμα (προεγχειρητικά)' },
  },
  {
    value: '914',
    label: {
      en:
        '215 Other benign neoplasm of connective and other soft tissue (after surgery)',
      gr:
        'Άλλη καλοήθης νεοπλασία συνδετικού και άλλου μαλακού ιστού (μετεγχειρητικά)',
    },
  },
  {
    value: '191',
    label: {
      en:
        '215 Other benign neoplasm of connective and other soft tissue (before surgery)',
      gr:
        'Άλλη καλοήθης νεοπλασία συνδετικού και άλλου μαλακού ιστού (προεγχειρητικά)',
    },
  },
  {
    value: '915',
    label: {
      en: '216 Benign neoplasm of skin (after surgery)',
      gr: 'Καλοήθης νεοπλασία δέρματος (μετεγχειρητικά)',
    },
  },
  {
    value: '192',
    label: {
      en: '216 Benign neoplasm of skin (before surgery)',
      gr: 'Καλοήθης νεοπλασία δέρματος (προεγχειρητικά)',
    },
  },
  {
    value: '916',
    label: {
      en: '217 Benign neoplasm of breast (after surgery)',
      gr: 'Καλοήθης νεοπλασία στήθους (μετεγχειρητικά)',
    },
  },
  {
    value: '193',
    label: {
      en: '217 Benign neoplasm of breast (before surgery)',
      gr: 'Καλοήθης νεοπλασία στήθους (προεγχειρητικά)',
    },
  },
  {
    value: '917',
    label: {
      en: '218 Uterine leiomyoma (after surgery)',
      gr: 'Μητρικό λειομύωμα (μετεγχειρητικά)',
    },
  },
  {
    value: '194',
    label: {
      en: '218 Uterine leiomyoma (before surgery)',
      gr: 'Μητρικό λειομύωμα (προεγχειρητικά)',
    },
  },
  {
    value: '918',
    label: {
      en: '219 Other Benign neoplasm of uterus (after surgery)',
      gr: 'Άλλη καλοήθης νεοπλασία της μήτρας (μετεγχειρητικά)',
    },
  },
  {
    value: '195',
    label: {
      en: '219 Other Benign neoplasm of uterus (before surgery)',
      gr: 'Άλλη καλοήθης νεοπλασία της μήτρας (προεγχειρητικά)',
    },
  },
  {
    value: '919',
    label: {
      en: '220 Benign neoplasm of ovary (after surgery)',
      gr: 'Καλοήθης νεοπλασία ωοθήκης (μετεγχειρητικά)',
    },
  },
  {
    value: '196',
    label: {
      en: '220 Benign neoplasm of ovary (before surgery)',
      gr: 'Καλοήθης νεοπλασία ωοθήκης (προεγχειρητικά)',
    },
  },
  {
    value: '920',
    label: {
      en: '221 Benign neoplasm of other female genital organs (after surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων γυναικείων γεννητικών οργάνων (μετεγχειρητικά)',
    },
  },
  {
    value: '197',
    label: {
      en: '221 Benign neoplasm of other female genital organs (before surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων γυναικείων γεννητικών οργάνων (προεγχειρητικά)',
    },
  },
  {
    value: '921',
    label: {
      en: '222 Benign neoplasm of male genital organs (after surgery)',
      gr: 'Καλοήθης νεοπλασία ανδρικών γεννητικών οργάνων (μετεγχειρητικά)',
    },
  },
  {
    value: '198',
    label: {
      en: '222 Benign neoplasm of male genital organs (before surgery)',
      gr: 'Καλοήθης νεοπλασία ανδρικών γεννητικών οργάνων (προεγχειρητικά)',
    },
  },
  {
    value: '922',
    label: {
      en:
        '223 Benign neoplasm of kidney and other urinary organs (after surgery)',
      gr:
        'Καλοήθης νεοπλασία νεφρού και άλλων οθροποιητικών οργάνων (μετεγχειρητικά)',
    },
  },
  {
    value: '199',
    label: {
      en:
        '223 Benign neoplasm of kidney and other urinary organs (before surgery)',
      gr:
        'Καλοήθης νεοπλασία νεφρού και άλλων οθροποιητικών οργάνων (προεγχειρητικά)',
    },
  },
  {
    value: '923',
    label: {
      en: '224 Benign neoplasm of eye (after surgery)',
      gr: 'Καλοήθης νεοπλασία ματιού (μετεγχειρητικά)',
    },
  },
  {
    value: '200',
    label: {
      en: '224 Benign neoplasm of eye (before surgery)',
      gr: 'Καλοήθης νεοπλασία ματιού (προεγχειρητικά)',
    },
  },
  {
    value: '924',
    label: {
      en:
        '225 Benign neoplasm of brain and other parts of nervous system (after surgery)',
      gr:
        'Καλοήθης νεοπλασία εγκεφάλου και άλλων μερών του νευρικού συστήματος (μετεγχειρητικά)',
    },
  },
  {
    value: '201',
    label: {
      en:
        '225 Benign neoplasm of brain and other parts of nervous system (before surgery)',
      gr:
        'Καλοήθης νεοπλασία εγκεφάλου και άλλων μερών του νευρικού συστήματος (προεγχειρητικά)',
    },
  },
  {
    value: '202',
    label: {
      en: '226 Benign neoplasm of thyroid gland',
      gr: 'Καλοήθης νεοπλασία θυρεοειδούς αδένα (προεγχειρητικά)',
    },
  },
  {
    value: '925',
    label: {
      en: '226 Benign neoplasm of thyroid gland (after surgery)',
      gr: 'Καλοήθης νεοπλασία θυρεοειδούς αδένα (μετεγχειρητικά)',
    },
  },
  {
    value: '926',
    label: {
      en:
        '227 Benign neoplasm of other endocrine glands and related structures (after surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων ενδοκρινών αδένων και συγγενικών κατασκευών (μετεγχειρητικά)',
    },
  },
  {
    value: '203',
    label: {
      en:
        '227 Benign neoplasm of other endocrine glands and related structures (before surgery)',
      gr:
        'Καλοήθης νεοπλασία άλλων ενδοκρινών αδένων και συγγενικών κατασκευών (προεγχειρητικά)',
    },
  },
  {
    value: '927',
    label: {
      en: '228 Haemangioma and lymphangioma any site (after surgery)',
      gr: 'Αιμαγγείωμα και λεμφαγγείωμα οποιουδήποτε μέρους (μετεγχειρητικά)',
    },
  },
  {
    value: '204',
    label: {
      en: '228 Haemangioma and lymphangioma any site (before surgery)',
      gr: 'Αιμαγγείωμα και λεμφαγγείωμα οποιουδήποτε μέρους (προεγχειρητικά)',
    },
  },
  {
    value: '928',
    label: {
      en: '229 Benign neoplasm of other and unspecified sites (after surgery)',
      gr: 'Καλοήθης νεοπλασία άλλων και ακαθόριστων μερών (μετεγχειρητικά)',
    },
  },
  {
    value: '205',
    label: {
      en: '229 Benign neoplasm of other and unspecified sites (before surgery)',
      gr: 'Καλοήθης νεοπλασία άλλων και ακαθόριστων μερών (προεγχειρητικά)',
    },
  },
  {
    value: '206',
    label: {
      en: '230 Carcinoma in situ of digestive organs',
      gr: 'Καρκίνωμα στην αρχική φάση στα πεπτικά όργανα',
    },
  },
  {
    value: '207',
    label: {
      en: '231 Carcinoma in situ of respiratory system',
      gr: 'Καρκίνωμα στην αρχική φάση στα αναπνευστικά όργανα',
    },
  },
  {
    value: '208',
    label: {
      en: '232 Carcinoma in situ of skin',
      gr: 'Καρκίνωμα στην αρχική φάση στο δέρμα',
    },
  },
  {
    value: '209',
    label: {
      en: '233 Carcinoma in situ of breast and genitourinary system',
      gr:
        'Καρκίνωμα στην αρχική φάση στο στήθος και στο γεννητικουροποιητικό σύστημα',
    },
  },
  {
    value: '210',
    label: {
      en: '234 Carcinoma in situ of other and unspecified sites',
      gr: 'Καρκίνωμα στην αρχική φάση σε άλλα ακαθόριστα μέρη',
    },
  },
  {
    value: '211',
    label: {
      en:
        '235 Neoplasm of uncertain behaviour of digestive and respiratory systems',
      gr:
        'Νεοπλασία ασταθούς συμπεριφοράς στο πεπτικό και αναπνευστικό σύστημα',
    },
  },
  {
    value: '212',
    label: {
      en: '236 Neoplasm of uncertain behaviour of genitourinary organs',
      gr: 'Νεοπλασία ασταθούς συμπεριφοράς στα γεννητικουροποιητικά όργανα',
    },
  },
  {
    value: '213',
    label: {
      en:
        '237 Neoplasm of uncertain behaviour of endocrine glands and nervous system',
      gr:
        'Νεοπλασία ασταθούς συμπεριφοράς ενδοκρινών αδένων και νευρικού συστήματος',
    },
  },
  {
    value: '214',
    label: {
      en:
        '238 Neoplasm of uncertain behaviour of other unspecified sites and tissues',
      gr: 'Νεοπλασία ασταθούς συμπεριφοράς άλλων ακαθόριστων μερών και ιστών',
    },
  },
  {
    value: '215',
    label: {
      en: '239 Neoplasm of unspecified nature',
      gr: 'Νεοπλασίες ακαθόριστης φύσης',
    },
  },
  {
    value: '216',
    label: {
      en: '240 Simple and unspecified goitre',
      gr: 'Απλό και ακαθόριστο πρήξιμο του θυρεοειδούς',
    },
  },
  {
    value: '217',
    label: {
      en: '241 Non-toxic nodular goitre',
      gr: 'Μη τοξικό οζώδες πρήξιμο',
    },
  },
  {
    value: '218',
    label: {
      en: '242 Thyrotoxicosis with or without goitre',
      gr: 'Υπερθυρεοειδισμός με ή χωρίς πρήξιμο',
    },
  },
  {
    value: '219',
    label: {
      en: '243 Congenital hypothyroidism',
      gr: 'Εκ γενετής υποθυρεοειδισμός',
    },
  },
  {
    value: '220',
    label: {
      en: '244 Acquired hypothyroidism',
      gr: 'Επίκτητος υποθυρεοειδισμός',
    },
  },
  { value: '221', label: { en: '244a Myxodem', gr: 'Μυξοίδημα' } },
  { value: '222', label: { en: '245 Thyroiditis', gr: 'Θυρεοειδίτιδα' } },
  {
    value: '223',
    label: {
      en: '246 Other disorders of thyroid',
      gr: 'Άλλες διαταραχές του θυρεοειδούς',
    },
  },
  {
    value: '224',
    label: { en: '250 Diabetes mellitus', gr: 'Σαγχαρώδης διαβήτης' },
  },
  {
    value: '225',
    label: {
      en: '251 Other disorders of pancreatic internal secretion',
      gr: 'Άλλες διαταραχές παγκρεατικής εσωτερικής έκκρισης',
    },
  },
  {
    value: '226',
    label: {
      en: '252 Disorders of parathyroid gland',
      gr: 'Διαταραχές του παραθυρεοειδούς αδένα',
    },
  },
  {
    value: '227',
    label: {
      en: '253 Disorders of the pituitary gland and its hypothalamic control',
      gr: 'Διαταραχές της υπόφυσης και του υποθαλάμιου ελέγχου',
    },
  },
  {
    value: '228',
    label: {
      en: '254 Diseases of thymus gland',
      gr: 'Ασθένειες του θύμου αδένα',
    },
  },
  {
    value: '229',
    label: {
      en: '255 Disorders of adrenal glands',
      gr: 'Διαταραχές του επινεφριδίου αδένα',
    },
  },
  {
    value: '230',
    label: { en: '256 Ovarian dysfunction', gr: 'Δυσλειτουργία των ωοθηκών' },
  },
  {
    value: '231',
    label: { en: '257 Testicular dysfunction', gr: 'Δυσλειτουργία των όρχεων' },
  },
  {
    value: '232',
    label: {
      en: '258 Polyglandular dysfunction and related disorders',
      gr: 'Πολυαδενική δυσλειτουργία και συγγενικών διαταραχών',
    },
  },
  {
    value: '233',
    label: {
      en: '259 Other endocrine disorders',
      gr: 'Άλλες ενδοκρινικές διαταραχές',
    },
  },
  {
    value: '234',
    label: { en: '260 Kwashiorkor', gr: 'Σύνδρομο Kwashiorkor' },
  },
  {
    value: '235',
    label: {
      en: '261 Nutritional marasmus',
      gr: 'Διατροφικός μαρασμός (παθολογικός υποσιτισμός)',
    },
  },
  {
    value: '236',
    label: {
      en: '262 Other severe protein-calorie malnutrition',
      gr: 'Άλλος υποσιτισμός σε διάφορες πρωτεΐνες-θερμίδες',
    },
  },
  {
    value: '237',
    label: {
      en: '263 Other and unspecified protein-calorie malnutrition',
      gr: 'Άλλος ακαθόριστος υποσιτισμός σε πρωτεΐνες-θερμίδες',
    },
  },
  {
    value: '238',
    label: { en: '264 Vitamin A deficiency', gr: 'Έλλειψη βιταμίνης Α' },
  },
  {
    value: '239',
    label: {
      en: '265 Thiamine and niacin deficiency states',
      gr: 'Καταστάσεις έλλειψης θειαμίνης και νιασίνης',
    },
  },
  {
    value: '240',
    label: {
      en: '266 Deficiency of B-complex components',
      gr: 'Έλλειψη σε συστατικά της ομάδας βιταμινών Β',
    },
  },
  {
    value: '241',
    label: { en: '267 Ascorbic acid deficiency', gr: 'Έλλειψη βιταμίνης Γ' },
  },
  {
    value: '242',
    label: { en: '268 Vitamin D deficiency', gr: 'Έλλειψη βιταμίνης Δ' },
  },
  {
    value: '243',
    label: {
      en: '269 Other nutritional deficiency',
      gr: 'Άλλη διατροφική ανεπάρκεια',
    },
  },
  {
    value: '244',
    label: {
      en: '270 Disorders of amino-acid transport and metabolism',
      gr: 'Διαταραχές στην διακίνηση αμινοξέων και στον μεταβολισμό',
    },
  },
  {
    value: '245',
    label: {
      en: '271 Disorders of carbohydrate transport and metabolism',
      gr: 'Διαταραχές στην διακίνηση υδρογονανθράκων και στον μεταβολισμό',
    },
  },
  {
    value: '246',
    label: {
      en: '272 Disorders of lipoid metabolism',
      gr: 'Διαταραχές στον λιποειδικό μεταβολισμό',
    },
  },
  {
    value: '247',
    label: {
      en: '273 Disorders of plasma protein metabolism',
      gr: 'Διαταραχές στον μεταβολισμό πρωτεϊνών στο πλάσμα',
    },
  },
  { value: '248', label: { en: '274 Gout', gr: 'Αρθρίτιδα ποδάγρα' } },
  {
    value: '249',
    label: {
      en: '275 Disorders of mineral metabolism',
      gr: 'Διαταραχές στον μεταβολισμό μετάλλων',
    },
  },
  {
    value: '250',
    label: {
      en: '276 Disorders of fluid electrolyte and acid-base balance',
      gr: 'Διαταραχές στην ισορροπία υγρών ηλεκτρολυτών και οξέων',
    },
  },
  {
    value: '251',
    label: {
      en: '277 Other and unspecified disorders of metabolism',
      gr: 'Άλλες και μη καθορισμένες διαταραχές του μεταβολισμού',
    },
  },
  {
    value: '252',
    label: {
      en: '278 Obesity and other hyper alimentation',
      gr: 'Παχυσαρκία και άλλος υπερσιτισμός',
    },
  },
  {
    value: '253',
    label: {
      en: '279 Disorders involving the immune mechanism',
      gr: 'Διαταραχές που έχουν σχέση με ανοσοποιητικό μηχανισμό',
    },
  },
  {
    value: '254',
    label: {
      en: '280 Iron deficiency anaemias',
      gr: 'Αναιμίες έλλειψης σιδήρου',
    },
  },
  {
    value: '255',
    label: {
      en: '281 Other deficiency anaemias',
      gr: 'Άλλες αναιμίες ανεπάρκειας',
    },
  },
  {
    value: '256',
    label: {
      en: '282 Hereditary haemolytic anaemias',
      gr: 'Κληρονομικές αιμολυτικές αναιμίες',
    },
  },
  {
    value: '257',
    label: {
      en: '283 Acquired haemolytic anaemias',
      gr: 'Επίκτητες αιμολυτικές αναιμίες',
    },
  },
  {
    value: '258',
    label: { en: '284 Aplastic anaemia', gr: 'Απλαστική αναιμία' },
  },
  {
    value: '259',
    label: {
      en: '285 Other and unspecified anaemias',
      gr: 'Άλλες και ακαθόριστες αναιμίες',
    },
  },
  {
    value: '260',
    label: { en: '286 Coagulation defects', gr: 'Επακόλουθα θρόμβωσης' },
  },
  {
    value: '261',
    label: {
      en: '287 Purpura and other haemorrhagic conditions',
      gr: 'Κοκκινίλες και άλλες αιμορραγικές καταστάσεις',
    },
  },
  {
    value: '262',
    label: {
      en: '288 Diseases of white blood cells',
      gr: 'Ασθένειες των λευκών αιμοσφαιρίων',
    },
  },
  {
    value: '263',
    label: {
      en: '289 Other diseases of blood and blood-forming organs',
      gr: 'Άλλες ασθένειες του αίματος και των οργάνων κυκλοφορίας αίματος',
    },
  },
  {
    value: '264',
    label: {
      en: '290 Senile and presenile organic psychotic conditions',
      gr: 'Γεροντικές και προγεροντικές οργανικές ψυχωτικές καταστάσεις',
    },
  },
  {
    value: '265',
    label: { en: '291 Alcoholic psychoses', gr: 'Ψυχώσεις αλκοολισμού' },
  },
  {
    value: '266',
    label: { en: '292 Drug psychoses', gr: 'Ψυχώσεις λήψης ναρκωτικών' },
  },
  {
    value: '267',
    label: {
      en: '293 Transient organic psychotic conditions',
      gr: 'Μεταβατικές οργανικές ψυχωτικές καταστάσεις',
    },
  },
  {
    value: '268',
    label: {
      en: '294 Other organic psychotic conditions (chronic)',
      gr: 'Άλλες οργανικές ψυχωτικές καταστάσεις (χρόνιες)',
    },
  },
  {
    value: '269',
    label: { en: '295 Schizophrenic psychoses', gr: 'Σχιζοφρενικές ψυχώσεις' },
  },
  {
    value: '270',
    label: { en: '296 Affective psychoses', gr: 'Συναισθηματικές ψυχώσεις' },
  },
  {
    value: '271',
    label: { en: '297 Paranoid states', gr: 'Παρανοϊκές καταστάσεις' },
  },
  {
    value: '272',
    label: {
      en: '298 Other nonoρganic psychoses',
      gr: 'Άλλες μη οργανικές ψυχώσεις',
    },
  },
  {
    value: '273',
    label: {
      en: '299 Psychoses with origin specified to childhood',
      gr: 'Ψυχώσεις που η αρχή τους τοποθετείται στην παιδική ηλικία',
    },
  },
  {
    value: '274',
    label: { en: '300 Neurotic disorders', gr: 'Νευρωτικές διαταραχές' },
  },
  {
    value: '275',
    label: { en: '301 Personality disorders', gr: 'Διαταραχές προσωπικότητας' },
  },
  {
    value: '276',
    label: {
      en: '302 Sexual deviations and disorders',
      gr: 'Σεξουαλικά προβλήματα και διαταραχές',
    },
  },
  {
    value: '277',
    label: {
      en: '303 Alcohol dependence syndrome',
      gr: 'Σύνδρομο εξάρτησης από το αλκοόλ',
    },
  },
  {
    value: '278',
    label: { en: '304 Drug dependence', gr: 'Εξάρτηση από ναρκωτικά' },
  },
  {
    value: '279',
    label: {
      en: '305 Nondependent abuse of drugs',
      gr: 'Μη εξαρτώμενη κατάχρηση ναρκωτικών',
    },
  },
  {
    value: '280',
    label: {
      en: '306 Physiological malfunction arising from mental factors',
      gr: 'Φυσιολογική βλάβη προερχόμενη από ψυχολογικούς παράγοντες',
    },
  },
  {
    value: '281',
    label: {
      en: '307 Special symptoms or syndromes not elsewhere classified',
      gr: 'Ειδικά συμπτώματα ή σύνδρομα που δεν ταξινομούνται αλλού',
    },
  },
  {
    value: '282',
    label: {
      en: '308 Acute reaction to stress',
      gr: 'Οξεία αντίδραση στο άγχος',
    },
  },
  {
    value: '283',
    label: { en: '309 Adjustment reaction', gr: 'Αντίδραση προσαρμογής' },
  },
  {
    value: '284',
    label: {
      en:
        '310 Specific nonpsychotic mental disorders following organic brain damage',
      gr:
        'Ειδικές ψυχολογικές διαταραχές ως επακόλουθο οργανικής ζημιάς του εγκεφάλου',
    },
  },
  {
    value: '285',
    label: {
      en: '311 Depressive disorder not elsewhere classified',
      gr: 'Διαταραχές κατάθλιψης που δεν ταξινομούνται αλλού',
    },
  },
  {
    value: '286',
    label: {
      en: '312 Disturbance of conduct not elsewhere classified',
      gr: 'Ταραχή στην συμπεριφορά που δεν ταξινομείται αλλού',
    },
  },
  {
    value: '287',
    label: {
      en: '313 Disturbance of emotions specific to childhood and adolescence',
      gr: 'Ταραχή στα συναισθήματα ειδικά στην παιδική ηλικία και στην εφηβεία',
    },
  },
  {
    value: '288',
    label: {
      en: '314 Hyperkinetic syndrome of childhood',
      gr: 'Σύνδρομο της υπερκινητικότητας στην παιδική ηλικία',
    },
  },
  {
    value: '289',
    label: {
      en: '315 Specific delays in development',
      gr: 'Συγκεκριμένες καθυστερήσεις στην ανάπτυξη',
    },
  },
  {
    value: '290',
    label: {
      en: '316 Psychic factors associated with diseases classified elsewhere',
      gr:
        'Ψυχικοί παράγοντες που σχετίζονται με ασθένειες που ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '291',
    label: {
      en: '317 Mild mental retardation',
      gr: 'Ήπια ψυχολογική καθυστέρηση',
    },
  },
  {
    value: '292',
    label: {
      en: '318 Other specified mental retardation',
      gr: 'Άλλη συγκεκριμένη ψυχολογική καθυστέρηση',
    },
  },
  {
    value: '293',
    label: {
      en: '319 Unspecified mental retardation',
      gr: 'Μη προσδιορισμένη ψυχολογική καθυστέρηση',
    },
  },
  {
    value: '294',
    label: {
      en: '330 Cerebral degenerations usually manifest in childhood',
      gr: 'Εγκεφαλικός εκφυλισμός που συνήθως εκδηλώνεται στην παιδική ηλικία',
    },
  },
  {
    value: '295',
    label: {
      en: '331 Other cerebral degenerations',
      gr: 'Άλλοι εγκεφαλικοί εκφυλισμοί',
    },
  },
  {
    value: '296',
    label: { en: "332 Parkinson's disease", gr: 'Ασθένεια Parkinson' },
  },
  {
    value: '297',
    label: {
      en: '333 Other extra pyramidal disease and abnormal movement disorders',
      gr: 'Άλλες υπερπυραμιδικές ασθένειες και διαταραχές αφύσικων κινήσεων',
    },
  },
  {
    value: '298',
    label: {
      en: '334 Spinocerebellar disease',
      gr: 'Νωτιοπαρεγκεφαλιδική ασθένεια',
    },
  },
  {
    value: '299',
    label: {
      en: '335 Anierior horn cell disease',
      gr: 'Κυτταρική ασθένεια Anierior horn',
    },
  },
  {
    value: '300',
    label: {
      en: '336 Other diseases of spinal cord',
      gr: 'Άλλες ασθένειες νωτιαίου μυελού',
    },
  },
  {
    value: '301',
    label: {
      en: '337 Disorders of the autonomic nervous system',
      gr: 'Διαταραχές της αυτονομίας του νευρικού συστήματος',
    },
  },
  {
    value: '302',
    label: { en: '340 Multiple sclerosis', gr: 'Πολλαπλή αρτηριοσκλήρωση' },
  },
  {
    value: '303',
    label: {
      en: '341 Other demyelinating diseases of central nervous system',
      gr: 'Άλλες ασθένειες απομυελίνωσης του κεντρικού νευρικού συστήματος',
    },
  },
  { value: '304', label: { en: '342 Hemiplegia', gr: 'Ημιπληγία' } },
  {
    value: '305',
    label: {
      en: '343 Infantile cerebral palsy',
      gr: 'Παιδική εγκεφαλική παράλυση',
    },
  },
  {
    value: '306',
    label: {
      en: '344 Other paralytic syndromes',
      gr: 'Άλλα παραλυτικά σύνδρομα',
    },
  },
  { value: '307', label: { en: '345 Epilepsy', gr: 'Επιληψία' } },
  { value: '308', label: { en: '346 Migraine', gr: 'Ημικρανία' } },
  {
    value: '309',
    label: {
      en: '347 Cataplexy and narcolepsy',
      gr: 'Καταπληξία και ναρκοληψία',
    },
  },
  {
    value: '310',
    label: {
      en: '348 Other conditions of brain',
      gr: 'Άλλες καταστάσεις του εγκεφάλου',
    },
  },
  {
    value: '311',
    label: {
      en: '349 Other and unspecified disorders of the nervous system',
      gr: 'Άλλες ακαθόριστες διαταραχές του νευρικού συστήματος',
    },
  },
  {
    value: '312',
    label: {
      en: '350 Trigeminal nerve disorders',
      gr: 'Διαταραχές των νεύρων της κεφαλής (τρίδυμο)',
    },
  },
  {
    value: '313',
    label: {
      en: '351 Facial nerve disorders',
      gr: 'Διαταραχές των νεύρων του προσώπου',
    },
  },
  {
    value: '314',
    label: {
      en: '352 Disorders of other cranial nerves',
      gr: 'Διαταραχές άλλων κρανιακών νεύρων',
    },
  },
  {
    value: '315',
    label: {
      en: '353 Nerve root and plexus disorders',
      gr: 'Διαταραχές στη βάση και στη δυχτίωση των νεύρων',
    },
  },
  {
    value: '316',
    label: {
      en: '354 Mononeuritis of upper limb and mononeuritis multiplex',
      gr: 'Μονονευρίτις άνω άκρου και πολλαπλός μονονευρίτις',
    },
  },
  {
    value: '317',
    label: {
      en: '355 Mononeuritis of lower limb',
      gr: 'Μονονευρίτις κάτω άκρου',
    },
  },
  {
    value: '318',
    label: {
      en: '356 Hereditary and idiopathic peripheral neuropathy',
      gr: 'Κληρονομική και ιδιοπαθητική περιφερειακή νευροπάθεια',
    },
  },
  {
    value: '319',
    label: {
      en: '357 Inflammatory and toxic neuropathy',
      gr: 'Φλεγμονώδης και τοξική νευροπάθεια',
    },
  },
  {
    value: '320',
    label: { en: '358 Myoneural disorders', gr: 'Μυονευρικές διαταραχές' },
  },
  {
    value: '321',
    label: {
      en: '359 Muscular dystrophies and other myopathies',
      gr: 'Μυϊκές δυστροφίες και άλλες μυοπάθειες',
    },
  },
  {
    value: '322',
    label: {
      en: '360 Disorders of the globe',
      gr: 'Διαταραχές στη σφαίρα του ματιού',
    },
  },
  {
    value: '323',
    label: {
      en: '361 Retinal detachments and defects',
      gr: 'Αποκολλήσεις αμφιβληστροειδούς και ανωμαλίες',
    },
  },
  {
    value: '324',
    label: {
      en: '362 Other retinal disorders',
      gr: 'Άλλες διαταραχές του αμφιβληστροειδούς',
    },
  },
  {
    value: '325',
    label: {
      en:
        '363 Chorioretinal inflammations and scars and other disorders of choroid',
      gr:
        'Χοριοαμφιβληστροειδικές φλεγμονές ουλές και άλλες διαταραχές του χοριοειδούς χιτώνα',
    },
  },
  {
    value: '326',
    label: {
      en: '364 Disorders of iris and ciliary body',
      gr: 'Διαταραχές στη ίριδα και στον ταρσό του βλεφάρου',
    },
  },
  { value: '327', label: { en: '365 Glaucoma', gr: 'Γλαύκωμα' } },
  { value: '328', label: { en: '366 Cataract', gr: 'Καταρράκτης' } },
  {
    value: '329',
    label: {
      en: '367 Disorders of refraction and accommodation',
      gr: 'Διαταραχές στη διάθλαση και στην προσαρμογή',
    },
  },
  {
    value: '330',
    label: { en: '368 Visual disturbances', gr: 'Προβλήματα στην όραση' },
  },
  {
    value: '331',
    label: {
      en: '369 Blindness and low vision',
      gr: 'Τυφλώτητα και αδύνατη όραση',
    },
  },
  { value: '332', label: { en: '370 Keratitis', gr: 'Κερατίτιδα' } },
  {
    value: '333',
    label: {
      en: '371 Corneal opacity and other disorders of cornea',
      gr: 'Θαμπάδα του κερατοειδούς χιτώνα και άλλες διαταραχές',
    },
  },
  {
    value: '334',
    label: {
      en: '372 Disorders of conjunctiva',
      gr: 'Διαταραχές της μεμβράνης των βλεφάρων',
    },
  },
  {
    value: '335',
    label: { en: '373 Inflammation of eyelids', gr: 'Φλεγμονές στα βλέφαρα' },
  },
  {
    value: '336',
    label: {
      en: '374 Other disorders of eyelids',
      gr: 'Άλλες διαταραχές των βλεφάρων',
    },
  },
  {
    value: '337',
    label: {
      en: '375 Disorders of lacrimal system',
      gr: 'Διαταραχές στο δακρυγόνο σύστημα',
    },
  },
  {
    value: '338',
    label: {
      en: '376 Disorders of the orbit',
      gr: 'Διαταραχές στον οφθαλμικό κόγχο',
    },
  },
  {
    value: '339',
    label: {
      en: '377 Disorders of optic nerve and visual pathways',
      gr: 'Διαταραχές οπτικού νεύρου και της διαδρομής της όρασης',
    },
  },
  {
    value: '340',
    label: {
      en: '378 Strabismus and other disorders of binocular eye movements',
      gr: 'Στραβισμός και άλλες διαταραχές στην κίνηση και των δύο οφθαλμών',
    },
  },
  {
    value: '341',
    label: {
      en: '379 Other disorders of eye',
      gr: 'Άλλες διαταραχές στα μάτια',
    },
  },
  {
    value: '342',
    label: {
      en: '380 Disorders of external ear',
      gr: 'Διαταραχές στο εξωτερικό του αυτιού',
    },
  },
  {
    value: '343',
    label: {
      en: '381 Nonsuppurative otitis media and Eustachian tube disorders',
      gr: 'Μη πυώδης μέση ωτίτιδα και διαταραχές της ευσταχιανής σάλπιγκας',
    },
  },
  {
    value: '344',
    label: {
      en: '382 Suppurative and unspecified otitis media',
      gr: 'Πυώδης και απροσδιόριστη μέση ωτίτιδα',
    },
  },
  {
    value: '345',
    label: {
      en: '383 Mastoiditis and related conditions',
      gr: 'Μαστοειδίτις και συγγενικές καταστάσεις',
    },
  },
  {
    value: '346',
    label: {
      en: '384 Other disorders of tympanic membrane',
      gr: 'Άλλες διαταραχές της τυμπανικής μεμβράνης',
    },
  },
  {
    value: '347',
    label: {
      en: '385 Other disorders of middle ear and mastoid',
      gr: 'Άλλες διαταραχές του μέσου αυτιού και του μαστοειδούς οστού',
    },
  },
  {
    value: '348',
    label: {
      en: '386 Vertiginous syndromes and other disorders of vestibular system',
      gr:
        'Σύνδρομα ιλίγγου και άλλες διαταραχές του εσωτερικού συστήματος του αφτιού',
    },
  },
  { value: '349', label: { en: '387 Otosclerosis', gr: 'Ωτοσκλήρυνση' } },
  {
    value: '350',
    label: {
      en: '388 Other disorders of ear',
      gr: 'Άλλες διαταραχές του αυτιού',
    },
  },
  { value: '351', label: { en: '389 Deafness', gr: 'Κώφωση' } },
  {
    value: '352',
    label: {
      en: '390 Rheumatic fever without mention of heart involvement',
      gr: 'Ρευματικός πυρετός χωρίς συμμετοχή καρδιακού προβλήματος',
    },
  },
  {
    value: '353',
    label: {
      en: '391 Rheumatic fever with heart involvement',
      gr: 'Ρευματικός πυρετός με συμμετοχή καρδιακού προβλήματος',
    },
  },
  {
    value: '354',
    label: { en: '392 Rheumatic cholera', gr: 'Ρευματική χολέρα' },
  },
  {
    value: '355',
    label: {
      en: '393 Chronic rheumatic pericarditis',
      gr: 'Χρόνια ρευματική περικαρδίτιδα',
    },
  },
  {
    value: '356',
    label: {
      en: '394 Diseases of mitral valve',
      gr: 'Ασθένειες της μιτροειδούς βαλβίδας',
    },
  },
  {
    value: '357',
    label: {
      en: '395 Diseases of aortic valve',
      gr: 'Ασθένειες της βαλβίδας της αορτής',
    },
  },
  {
    value: '358',
    label: {
      en: '396 Diseases of mitral and aortic valves',
      gr: 'Ασθένειες της μιτροειδούς και της αορτικής βαλβίδας',
    },
  },
  {
    value: '359',
    label: {
      en: '397 Diseases of other endocardial structures',
      gr: 'Ασθένειες άλλων ενδοκαρδιακών εξαρτημάτων',
    },
  },
  {
    value: '360',
    label: {
      en: '398 Other rheumatic heart disease',
      gr: 'Άλλη ρευματική καρδιακή πάθηση',
    },
  },
  {
    value: '361',
    label: { en: '401 Essential hypertension', gr: 'Ουσιώδης υπέρταση' },
  },
  {
    value: '362',
    label: {
      en: '402 Hypertensive heart disease',
      gr: 'Υπερτασική καρδιακή πάθηση',
    },
  },
  {
    value: '363',
    label: {
      en: '403 Hypertensive renal disease',
      gr: 'Υπερτασική νεφρική πάθηση',
    },
  },
  {
    value: '364',
    label: {
      en: '404 Hypertensive heart and renal disease',
      gr: 'Υπερτασική καρδιακή και νεφρική πάθηση',
    },
  },
  {
    value: '365',
    label: { en: '405 Secondary hypertension', gr: 'Δευτερεύουσα υπέρταση' },
  },
  {
    value: '366',
    label: {
      en: '410 Acute myocardial infarction',
      gr: 'Οξύ μυοκαρδιακό Έμφραγμα',
    },
  },
  {
    value: '367',
    label: {
      en: '411 Other acute and sub acute form of ischaemic heart disease',
      gr: 'Άλλος οξύς και ημιοξύς τύπος ισχαιμικής καρδιακής ασθένειας',
    },
  },
  {
    value: '368',
    label: {
      en: '412 Old myocardial infarction',
      gr: 'Παλαιό μυοκαρδιακό έμφραγμα',
    },
  },
  { value: '369', label: { en: '413 Angina pectoris', gr: 'Στηθάγχη' } },
  {
    value: '370',
    label: {
      en: '414 Other forms of chronic ischaemic heart disease',
      gr: 'Άλλοι τύποι χρόνιων ισχαιμικών καρδιακών ασθενειών',
    },
  },
  {
    value: '371',
    label: {
      en: '415 Acute pulmonary heart disease',
      gr: 'Οξεία πνευμονική καρδιακή πάθηση',
    },
  },
  {
    value: '372',
    label: {
      en: '416 Chronic pulmonary heart disease',
      gr: 'Χρόνια πνευμονική καρδιακή πάθηση',
    },
  },
  {
    value: '373',
    label: {
      en: '417 Other diseases of pulmonary circulation',
      gr: 'Άλλες παθήσεις της πνευμονικής κτκλοφορίας',
    },
  },
  {
    value: '374',
    label: { en: '420 Acute pericarditis', gr: 'Οξεία περικαρδίτιδα' },
  },
  {
    value: '375',
    label: {
      en: '421 Acute and sub acute endocarditis',
      gr: 'Οξεία και ημιοξεία ενδοκαρδίτιδα',
    },
  },
  {
    value: '376',
    label: { en: '422 Acute myocarditis', gr: 'Οξεία μυοκαρδίτιδα' },
  },
  {
    value: '377',
    label: {
      en: '423 Other diseases of pericardium',
      gr: 'Άλλες ασθένειες του περικαρδίου',
    },
  },
  {
    value: '378',
    label: {
      en: '424 Other diseases of endocardium',
      gr: 'Άλλες ασθένειες του ενδοκαρδίου',
    },
  },
  { value: '379', label: { en: '425 Cardiomyopathy', gr: 'Καρδιομυοπάθεια' } },
  {
    value: '380',
    label: {
      en: '426 Conduction disorders',
      gr: 'Διαταραχές στο σύστημα μεταφοράς νευρικών σημάτων',
    },
  },
  {
    value: '381',
    label: { en: '427 Cardiac dysrhythmias', gr: 'Καρδιακές δυσρυθμίες' },
  },
  {
    value: '382',
    label: { en: '428 Heart failure', gr: 'Καρδιακή ανεπάρκεια' },
  },
  {
    value: '383',
    label: {
      en: '429 Ill-defined descriptions and complications of heart disease',
      gr: 'Καρδιακές ασθένειες καθορισθείσες από περιγραφές και επιπλοκές',
    },
  },
  {
    value: '384',
    label: {
      en: '430 Subarachnoid haemorrhage',
      gr: 'Υπαραχνοειδής αιμορραγία',
    },
  },
  {
    value: '385',
    label: {
      en: '431 Intracerebral haemorrhage',
      gr: 'Ενδοεγκεφαλική αιμορραγία',
    },
  },
  {
    value: '386',
    label: {
      en: '432 Other and unspecified intracranial haemorrhage',
      gr: 'Άλλη μη καθορισμένη ενδοκρανιακή αιμορραγία',
    },
  },
  {
    value: '387',
    label: {
      en: '433 Occlusion and stenosis of precerebral arteries',
      gr: 'Απόφραξη και στένωση των προεγκεφαλικών αρτηριών',
    },
  },
  {
    value: '388',
    label: {
      en: '434 Occlusion of cerebral arteries',
      gr: 'Απόφραξη των προεγκεφαλικών αρτηριών',
    },
  },
  {
    value: '389',
    label: {
      en: '435 Transcend cerebral ischaemia',
      gr: 'Προχωρημένη εγκεφαλική ισχαιμία',
    },
  },
  {
    value: '390',
    label: {
      en: '436 Acute but ill-defined cerebrovascular disease',
      gr: 'Οξεία καθορισμένη εγκεφαλοαγγειακή ασθένεια',
    },
  },
  {
    value: '391',
    label: {
      en: '437 Other and ill-defined cerebrovascular disease',
      gr: 'Άλλη καθορισμένη εγκεφαλοαγγειακή ασθένεια',
    },
  },
  {
    value: '392',
    label: {
      en: '438 Late effects of cerebrovascular disease',
      gr: 'Καθυστερημένες επιδράσεις εγκεφαλοαγγειακής ασθένειας',
    },
  },
  { value: '393', label: { en: '440 Atherosclerosis', gr: 'Αθηροσκλήρωση' } },
  {
    value: '394',
    label: { en: '441 Aortic aneurysm', gr: 'Αορτικό ανεύρυσμα' },
  },
  { value: '395', label: { en: '442 Other aneurysm', gr: 'Άλλο ανεύρυσμα' } },
  {
    value: '396',
    label: {
      en: '443 Other peripheral vascular disease',
      gr: 'Άλλη περιφερειακή αγγειακή ασθένεια',
    },
  },
  {
    value: '397',
    label: {
      en: '444 Arterial embolism and thrombosis',
      gr: 'Αρτηριακός εμβολισμός και θρόμβωση',
    },
  },
  {
    value: '398',
    label: {
      en: '446 Polyarteritis nodosa and allied conditions',
      gr: 'Οζώδης πολυαρτηρίτης  και συγγενικές καταστάσεις',
    },
  },
  {
    value: '399',
    label: {
      en: '447 Other disorders of arteries and arterioles',
      gr: 'Άλλες διαταραχές στις αρτηρίες και τα αρτηριόλια',
    },
  },
  {
    value: '400',
    label: {
      en: '448 Diseases of capillaries',
      gr: 'Ασθένειες τριχοειδών αρτηριών',
    },
  },
  {
    value: '401',
    label: {
      en: '450 Pulmonary embolism and lung infarction',
      gr: 'Πνευμονικός εμβολισμός και λοίμωξη πνευμόνων',
    },
  },
  {
    value: '402',
    label: {
      en: '451 Phlebitis and thrombophlebitis',
      gr: 'Φλεβίτιδα και θρομβοφλεβίτιδα',
    },
  },
  {
    value: '403',
    label: { en: '452 Portal vein thrombosis', gr: 'Θρόμβωση πυλαίας φλέβας' },
  },
  {
    value: '404',
    label: {
      en: '453 Other venous embolism and thrombosis',
      gr: 'Άλλος φλεβικός εμβολισμός και θρόμβωση',
    },
  },
  {
    value: '405',
    label: {
      en: '454 Varicose veins of lower extremities',
      gr: 'Κιρσοί χαμηλών άκρων',
    },
  },
  { value: '406', label: { en: '455 Haemorrhoids', gr: 'Αιμορροΐδες' } },
  {
    value: '407',
    label: {
      en: '456 Varicose veins of other sites',
      gr: 'Κιρσοί άλλων σημείων',
    },
  },
  {
    value: '408',
    label: {
      en: '457 Noninfective disorders of lymphatic channels',
      gr: 'Μη πρεσβεβλημένες διαταραχές των λεμφαγγείων',
    },
  },
  { value: '409', label: { en: '458 Hypotension', gr: 'Υπόταση' } },
  {
    value: '410',
    label: {
      en: '459 Other disorders of circulatory system',
      gr: 'Άλλες διαταραχές του κυκλοφοριακού συστήματος',
    },
  },
  {
    value: '411',
    label: {
      en: '460 Acute nasopharyngitis [common cold]',
      gr: 'Οξεία ρινοφαρυγγίτιδα',
    },
  },
  {
    value: '412',
    label: { en: '461 Acute sinusitis', gr: 'Οξεία ιγμορίτιδα' },
  },
  {
    value: '413',
    label: { en: '462 Acute pharyngitis', gr: 'Οξεία φαρυγγίτιδα' },
  },
  {
    value: '414',
    label: { en: '463 Acute tonsillitis', gr: 'Οξεία αμυγδαλίτιδα' },
  },
  {
    value: '415',
    label: {
      en: '464 Acute laryngitis and tracheitis',
      gr: 'Οξεία λαρυγγίτιδα και τραχειίτιδα',
    },
  },
  {
    value: '416',
    label: {
      en:
        '465 Acute upper respiratory infections of multiple or unspecified sites',
      gr:
        'Οξείες άνω αναπνευστικές λοιμώξεις των πολλαπλών ή ακαθόριστων μερών',
    },
  },
  {
    value: '417',
    label: {
      en: '466 Acute bronchitis and bronchiolitis',
      gr: 'Οξεία βρογχίτιδα και βρογχιολίτιδα',
    },
  },
  {
    value: '418',
    label: {
      en: '470 Deflected nasal septum',
      gr: 'Στράβωμα ρινικού διαφράγματος',
    },
  },
  { value: '419', label: { en: '471 Nasal polyps', gr: 'Ρινικοί πολύποδες' } },
  {
    value: '420',
    label: {
      en: '472 Chronic pharyngitis and nasopharyngitis',
      gr: 'Χρόνια φαρυγγίτιδα και ρινοφαρυγγίτιδα',
    },
  },
  {
    value: '421',
    label: { en: '473 Chronic sinusitis', gr: 'Χρόνια ιγμορίτιδα' },
  },
  {
    value: '422',
    label: {
      en: '474 Chronic disease of tonsils and adenoids',
      gr: 'Χρόνια ασθένεια αμυγδαλίτιδας και αδενοειδίτιδας',
    },
  },
  {
    value: '423',
    label: { en: '475 Peritonsilar absess', gr: 'Περιαμυγλαλιτικό απόστημα' },
  },
  {
    value: '424',
    label: {
      en: '476 Chronic laryngitis and laryngotracheitis',
      gr: 'Χρόνια λαρυγγίτιδα και λαρυγγοτραχειίτιδα',
    },
  },
  {
    value: '425',
    label: { en: '477 Allergic rhinitis', gr: 'Αλλεργική ρινίτιδα' },
  },
  {
    value: '426',
    label: {
      en: '478 Other diseases of upper respiratory tract',
      gr: 'Άλλες ασθένειες του άνω αναπνευστικού διαδρόμου',
    },
  },
  {
    value: '427',
    label: { en: '480 Viral pneumonia', gr: 'Ιογενής πνευμονία' },
  },
  {
    value: '428',
    label: { en: '481 Pneumococcal pneumonia', gr: 'Πνευμοκοκκική πνευμονία' },
  },
  {
    value: '429',
    label: {
      en: '482 Other bacterial pneumonia',
      gr: 'Άλλη βακτηριακή πνευμονία',
    },
  },
  {
    value: '430',
    label: {
      en: '483 Pneumonia due to other specified organism',
      gr: 'Πνευμονία οφειλόμενη σε άλλους καθορισμένους οργανισμούς',
    },
  },
  {
    value: '431',
    label: {
      en: '484 Pneumonia in infectious diseases classified elsewhere',
      gr: 'Πνευμονία σε μολυσματικές ασθένειες καθορισμένες αλλού',
    },
  },
  {
    value: '432',
    label: {
      en: '485 Bronchopneumonia organism unspecified',
      gr: 'Βρογχοπνευμονία ακαθόριστοι οργανισμοί',
    },
  },
  {
    value: '433',
    label: {
      en: '486 Pneumonia organism unspecified',
      gr: 'Πνευμονία ακαθόριστοι οργανισμοί',
    },
  },
  { value: '434', label: { en: '487 Influenza', gr: 'Γρίπη' } },
  {
    value: '435',
    label: {
      en: '490 Bronchitis not specified as acute or chronic',
      gr: 'Βρογχίτιδα μη καθορισμένη ως οξεία ή χρόνια',
    },
  },
  {
    value: '436',
    label: { en: '491 Chronic bronchitis', gr: 'Χρόνια βρογχίτιδα' },
  },
  { value: '437', label: { en: '492 Emphysema', gr: 'Εμφύσημα' } },
  { value: '438', label: { en: '493 Asthma', gr: 'Άσθμα' } },
  { value: '439', label: { en: '494 Bronchiectasis', gr: 'Βρογχιεκτασία' } },
  {
    value: '440',
    label: {
      en: '495 Extrinsic allergic alveolitis',
      gr: 'Εξωτερική αλλεργική κυψελιδίτιδα',
    },
  },
  {
    value: '441',
    label: {
      en: '496 Chronic airways obstruction not elsewhere classified',
      gr: 'Χρόνια απόφραξη του αεραγωγού που δεν ταξινομήθηκε αλλού',
    },
  },
  {
    value: '442',
    label: {
      en: "500 Coal workers' pneumoconiosis",
      gr: 'Πνευμοκονίαση ανθρακωρύχων',
    },
  },
  { value: '443', label: { en: '501 Asbestosis', gr: 'Αμιαντίαση' } },
  {
    value: '444',
    label: {
      en: '502 Pneumoconiosis due to other silica or silicates',
      gr: 'Πνευμοκονίαση οφειλόμενη σε άλλους χαλαζίες ή άλατα',
    },
  },
  {
    value: '445',
    label: {
      en: '503 Pneumoconiosis due to other inorganic dust',
      gr: 'Πνευμοκονίαση οφειλόμενη σε άλλες ανόργανες σκόνες',
    },
  },
  {
    value: '446',
    label: {
      en: '504 Pneumopathy due to inhalation of other dust',
      gr: 'Πνευμονοπάθεια οφειλόμενη σε εισπνοή άλλης σκόνης',
    },
  },
  {
    value: '447',
    label: {
      en: '505 Pneumoconiosis unspecified',
      gr: 'Πνευμοκονίαση ακαθόριστη',
    },
  },
  {
    value: '448',
    label: {
      en: '506 Respiratory conditions due to chemical fumes and vapours',
      gr:
        'Αναπνευστικά προβλήματα οφειλόμενα σε χημικές αναθυμιάσεις και εξατμίσεις',
    },
  },
  {
    value: '449',
    label: {
      en: '507 Pneumonitis due to solids and liquids',
      gr: 'Πνευμονίτιδα οφειλόμενη σε στερεά και υγρά',
    },
  },
  {
    value: '450',
    label: {
      en:
        '508 Respiratory conditions due to other and unspecified external agents',
      gr:
        'Αναπνευστικά προβλήματα από άλλους ακαθόριστους εξωτερικούς παράγοντες',
    },
  },
  { value: '451', label: { en: '510 Empyema', gr: 'Εμπύημα' } },
  { value: '452', label: { en: '511 Pleurisy', gr: 'Πλευρίτιδα' } },
  { value: '453', label: { en: '512 Pneumothorax', gr: 'Πνευμοθώρακας' } },
  {
    value: '454',
    label: {
      en: '513 Abscess of lung and mediastinum',
      gr: 'Απόστημα στους πνεύμονες και στο διάφραγμα',
    },
  },
  {
    value: '455',
    label: {
      en: '514 Pulmonary congestion and hypostasis',
      gr: 'Πνευμονική συμφόρηση και υπόσταση',
    },
  },
  {
    value: '456',
    label: {
      en: '515 Post inflammatory pulmonary fibrosis',
      gr: 'Μεταφλεγμονική πνευμονική ινωμάτωση',
    },
  },
  {
    value: '457',
    label: {
      en: '516 Other alveolar and parietoalveolar pneumopathy',
      gr: 'Άλλες κυψελικές και παρακυψελικές πνευμονοπάθειες',
    },
  },
  {
    value: '458',
    label: {
      en: '517 Lung involvement in conditions classified elsewhere',
      gr: 'Ανάμειξη των πνευμόνων σε προβλήματα που ταξινομούνται αλλού',
    },
  },
  {
    value: '459',
    label: {
      en: '518 Other diseases of lung',
      gr: 'Άλλες ασθένειες των πνευμόνων',
    },
  },
  {
    value: '460',
    label: {
      en: '519 Other diseases of respiratory system',
      gr: 'Άλλες ασθένειες του αναπνευστικού συστήματος',
    },
  },
  {
    value: '461',
    label: {
      en: '520 Disorders of tooth development and eruption',
      gr: 'Διαταραχές στην ανάπτυξη των δοντιών και εξανθήματα',
    },
  },
  {
    value: '462',
    label: {
      en: '521 Diseases of hard tissues of teeth',
      gr: 'Ασθένειες σκληρών ιστών των δοντιών',
    },
  },
  {
    value: '463',
    label: {
      en: '522 Diseases of pulp and periapical tissues',
      gr: 'Ασθένειες σε μαλακούς και επικαλυπτικούς ιστούς',
    },
  },
  {
    value: '464',
    label: {
      en: '523 Gingival and periodontal diseases',
      gr: 'Περιοδοντικές και των ούλων ασθένειες',
    },
  },
  {
    value: '465',
    label: {
      en: '524 Dentofacial anomalies including malocclusion',
      gr: 'Οδοντοπροσωπικές ανωμαλίες περιλαμβανομένης κακής σύγκλεισης',
    },
  },
  {
    value: '466',
    label: {
      en:
        '525 Other diseases and conditions of the teeth and supporting structures',
      gr:
        'Άλλες ασθένειες και καταστάσεις των δοντιών και υποστηρικτικών εξαρτημάτων',
    },
  },
  {
    value: '467',
    label: { en: '526 Diseases of the jaws', gr: 'Ασθένειες των σιαγόνων' },
  },
  {
    value: '468',
    label: {
      en: '527 Diseases of the salivary glands',
      gr: 'Ασθένειες των σιελογόνων αδένων',
    },
  },
  {
    value: '469',
    label: {
      en:
        '528 Diseases of the oral soft tissues excluding lesions specific for gingiva and tongue',
      gr:
        'Ασθένειες των στοματικών μαλακών ιστών εξαιρουμένων συγκεκριμένων βλαβών των ούλων και της γλώσσας',
    },
  },
  {
    value: '470',
    label: {
      en: '529 Diseases and other conditions of the tongue',
      gr: 'Ασθένειες και άλλες καταστάσεις της γλώσσας',
    },
  },
  {
    value: '471',
    label: { en: '530 Diseases of oesophagus', gr: 'Ασθένειες στον οισοφάγο' },
  },
  { value: '472', label: { en: '531 Gastric ulcer', gr: 'Έλκος στομάχου' } },
  {
    value: '473',
    label: { en: '532 Duodenal ulcer', gr: 'Έλκος δωδεκαδάκτυλου' },
  },
  {
    value: '474',
    label: {
      en: '533 Peptic ulcer site unspecified',
      gr: 'Πεπτικό έλκος απροσδιόριστο σημείο',
    },
  },
  {
    value: '475',
    label: { en: '534 Gastrojejunal ulcer', gr: 'Γαστρονηστιδικό έλκος' },
  },
  {
    value: '476',
    label: {
      en: '535 Gastritis and duodenitis',
      gr: 'Γαστρίτιδα και δωδεκαδακτηλίτιδα',
    },
  },
  {
    value: '477',
    label: {
      en: '536 Disorders of function of stomach',
      gr: 'Διαταραχές στη λειτουργία του στομάχου',
    },
  },
  {
    value: '478',
    label: {
      en: '537 Other disorders of stomach and duodenum',
      gr: 'Άλλες διαταραχές του στομάχου και του δωδεκαδάκτηλου',
    },
  },
  {
    value: '479',
    label: { en: '540 Acute appendicitis', gr: 'Οξεία σκωληκοειδίτιδα' },
  },
  {
    value: '480',
    label: {
      en: '541 Appendicitis unqualified',
      gr: 'Σκωληκοειδίτιδα αναξιολόγητη',
    },
  },
  {
    value: '481',
    label: { en: '542 Other appendicitis', gr: 'Άλλη σκωληκοειδίτιδα' },
  },
  {
    value: '482',
    label: {
      en: '543 Other disease of appendix',
      gr: 'Άλλη ασθένεια της σκωληκοειδούς απόφυσης',
    },
  },
  { value: '483', label: { en: '550 Inguinal hernia', gr: 'Βουβωνοκήλη' } },
  {
    value: '484',
    label: {
      en: '551 Other hernia of abdominal cavity with gangrene',
      gr: 'Άλλη κήλη της γαστρικής κοιλότητας με γάγγραινα',
    },
  },
  {
    value: '485',
    label: {
      en:
        '552 Other hernia of abdominal cavity with obstruction without mention of gangrene',
      gr: 'Άλλη κήλη της γαστρικής κοιλότητας με απόφραξη χωρίς γάγγραινα',
    },
  },
  {
    value: '486',
    label: {
      en:
        '553 Other hernia of abdominal cavity without mention of obstruction or gangrene',
      gr: 'Άλλη κήλη της γαστρικής κοιλότητας χωρίς απόφραξη ή γάγγραινα',
    },
  },
  {
    value: '487',
    label: { en: '555 Regional enteritis', gr: 'Τοπική εντερίτιδα' },
  },
  {
    value: '488',
    label: {
      en: '556 Idiopathic proctocolitis',
      gr: 'Ιδιοπαθής πρωκτοκολίτιδα',
    },
  },
  {
    value: '489',
    label: {
      en: '557 Vascular insufficiency of intestine',
      gr: 'Αγγειακή ανεπάρκεια των εντέρων',
    },
  },
  {
    value: '490',
    label: {
      en: '558 Other noninfective gastroenteritis',
      gr: 'Άλλη μη προσβεβλημένη γαστρεντερίτιδα',
    },
  },
  {
    value: '491',
    label: {
      en: '560 Intestinal obstruction without mention of hernia',
      gr: 'Εντερική απόφραξη χωρίς κήλη',
    },
  },
  {
    value: '492',
    label: { en: '562 Diverticula of intestine', gr: 'Εκκολπώματα στα έντερα' },
  },
  {
    value: '493',
    label: {
      en: '564 Functional digestive disorders not elsewhere classified',
      gr:
        'Διαταραχές στη λειτουργία του πεπτικού συστήματος που δεν ταξινομούνται αλλού',
    },
  },
  {
    value: '494',
    label: {
      en: '565 Anal fissure and fistula',
      gr: 'Κωλικό ράγισμα και συρίγγιο',
    },
  },
  {
    value: '495',
    label: {
      en: '566 Abscess of anal and rectal regions',
      gr: 'Απόστημα στην κολική και ορθική περιοχή',
    },
  },
  { value: '496', label: { en: '567 Peritonitis', gr: 'Περιτονίτιδα' } },
  {
    value: '497',
    label: {
      en: '568 Other disorders of peritoneum',
      gr: 'Άλλες διαταραχές του περιτοναίου',
    },
  },
  {
    value: '498',
    label: {
      en: '569 Other disorders of intestine',
      gr: 'άλλες διαταραχές των εντέρων',
    },
  },
  {
    value: '499',
    label: {
      en: '570 Acute and sub acute necrosis of liver',
      gr: 'Οξεία και ημιοξεία νέκρωση του συκωτιού',
    },
  },
  {
    value: '500',
    label: {
      en: '571 Chronic liver disease and cirrhosis',
      gr: 'Χρόνια ασθένεια του συκωτιού και κίρρωση',
    },
  },
  {
    value: '501',
    label: {
      en: '572 Liver abscess and sequelae of chronic liver disease',
      gr: 'Απόστημα στο συκώτι και επακόλουθα χρόνιας ασθένειας στο συκώτι',
    },
  },
  {
    value: '502',
    label: {
      en: '573 Other disorders of liver',
      gr: 'Άλλες διαταραχές στο συκώτι',
    },
  },
  { value: '503', label: { en: '574 Cholelithiasis', gr: 'Χολολιθίαση' } },
  {
    value: '504',
    label: {
      en: '575 Other disorders of gallbladder',
      gr: 'Άλλες διαταραχές στη χοληδόχο κύστη',
    },
  },
  {
    value: '505',
    label: {
      en: '576 Other disorders of biliary tract',
      gr: 'Άλλες διαταραχές στο χοληδόχο σύστημα',
    },
  },
  {
    value: '506',
    label: { en: '577 Diseases of pancreas', gr: 'Ασθένειες στο πάγκρεας' },
  },
  {
    value: '507',
    label: {
      en: '578 Gastrointestinal haemorrhage',
      gr: 'Γαστρεντερική αιμορραγία',
    },
  },
  {
    value: '508',
    label: { en: '579 Intestinal malabsorption', gr: 'Εντερική δυσαπορρόφηση' },
  },
  {
    value: '509',
    label: {
      en: '580 Acute glomerulonephritis',
      gr: 'Οξεία σπειραματονεφρίτιδα',
    },
  },
  {
    value: '510',
    label: { en: '581 Nephrotic syndrome', gr: 'Νεφριτικό σύνδρομο' },
  },
  {
    value: '511',
    label: {
      en: '582 Chronic glomerulonephritis',
      gr: 'Χρόνια σπειρονεφρίτιδα',
    },
  },
  {
    value: '512',
    label: {
      en: '583 Nephritis and nephropathy not specified as acute or chronic',
      gr: 'Νεφρίτιδα και νεφροπάθεια μη οξεία ή χρόνια',
    },
  },
  {
    value: '513',
    label: { en: '584 Acute renal failure', gr: 'Οξεία νεφρική ανεπάρκεια' },
  },
  {
    value: '514',
    label: { en: '585 Chronic renal failure', gr: 'Χρόνια νεφρική ανεπάρκεια' },
  },
  {
    value: '515',
    label: {
      en: '586 Renal failure unspecified',
      gr: 'Νεφρική ανεπάρκεια απροσδιόριστη',
    },
  },
  {
    value: '516',
    label: {
      en: '587 Renal sclerosis unspecified',
      gr: 'Νεφρική σκλήρωση απροσδιόριστη',
    },
  },
  {
    value: '517',
    label: {
      en: '588 Disorders resulting from impaired renal function',
      gr: 'Διαταραχές προερχόμενες από εξασθενημένη νεφρική λειτουργία',
    },
  },
  {
    value: '518',
    label: {
      en: '589 Small kidney of unknown cause',
      gr: 'Μικρός νεφρός από άγνωστη αιτία',
    },
  },
  {
    value: '519',
    label: { en: '590 Infections of kidney', gr: 'Μολύνσεις νεφρού' },
  },
  { value: '520', label: { en: '591 Hydronephrosis', gr: 'Υδρονέφρωση' } },
  {
    value: '521',
    label: {
      en: '592 Calculus of kidney and ureter',
      gr: 'Πέτρες στα νεφρά και στον ουρητήρα',
    },
  },
  {
    value: '522',
    label: {
      en: '593 Other disorders of kidney and ureter',
      gr: 'Άλλες διαταραχές στα νεφρά και στον ουρητήρα',
    },
  },
  {
    value: '523',
    label: {
      en: '594 Calculus of lower urinary tract',
      gr: 'Πέτρες στο κάτω ουροδόχο σύστημα',
    },
  },
  { value: '524', label: { en: '595 Cystitis', gr: 'Κυστίτιδα' } },
  {
    value: '525',
    label: {
      en: '596 Other disorders of bladder',
      gr: 'Άλλες διαταραχές στην ουροδόχο κύστη',
    },
  },
  {
    value: '526',
    label: {
      en: '597 Urethritis not sexually transmitted and urethral syndrome',
      gr: 'Ουρηθρίτιδα που δεν μεταδόθηκε σεξουαλικά και ουρηθρικό σύνδρομο',
    },
  },
  {
    value: '527',
    label: { en: '598 Urethral stricture', gr: 'Στένωση ουρήθρας' },
  },
  {
    value: '528',
    label: {
      en: '599 Other disorders of urethra and urinary tract',
      gr: 'Άλλες διαταραχές στην ουρήθρα και στο ουροδόχο σύστημα',
    },
  },
  {
    value: '529',
    label: { en: '600 Hyperplasia of prostate', gr: 'Υπερπλασία του προστάτη' },
  },
  {
    value: '530',
    label: {
      en: '601 Inflammatory diseases of prostate',
      gr: 'Μολυσματικές ασθένειες του προστάτη',
    },
  },
  {
    value: '531',
    label: {
      en: '602 Other disorders of prostate',
      gr: 'Άλλες διαταραχές του προστάτη',
    },
  },
  { value: '532', label: { en: '603 Hydrocele', gr: 'Υδροκήλη' } },
  {
    value: '533',
    label: {
      en: '604 Orchitis and epididymitis',
      gr: 'Ορχίτιδα και επιδιδυμίτιδα',
    },
  },
  {
    value: '534',
    label: {
      en: '605 Redundant prepuce and phimosis',
      gr: 'Μεγάλη πόσθη και φίμωση',
    },
  },
  {
    value: '535',
    label: { en: '606 Infertility male', gr: 'Στειρότητα άρρενος' },
  },
  {
    value: '536',
    label: { en: '607 Disorders of penis', gr: 'Διαταραχές του πέους' },
  },
  {
    value: '537',
    label: {
      en: '608 Other disorders of male genital organs',
      gr: 'Άλλες διαταραχές των ανδρικών γεννητικών οργάνων',
    },
  },
  {
    value: '538',
    label: {
      en: '610 Benign mammary dysplasias',
      gr: 'Καλοήθης μαστική δυσπλασία',
    },
  },
  {
    value: '539',
    label: {
      en: '611 Other disorders of breast',
      gr: 'Άλλες διαταραχές στους μαστούς',
    },
  },
  {
    value: '540',
    label: {
      en:
        '614 Inflammatory disease of ovary fallopian tube pelvic cellurar tissue and peritoneum',
      gr:
        'Μολυσματική ασθένεια στις ωοθήκες στις ωαγωγούς σάλπιγγες κυτταρικός ιστός στη λεκάνη και περιτόναιο',
    },
  },
  {
    value: '541',
    label: {
      en: '615 Inflammatory diseases of uterus except cervix',
      gr: 'Μολυσματικές ασθένειες στην μήτρα εξαιρουμένου του αυχένα',
    },
  },
  {
    value: '542',
    label: {
      en: '616 Inflammatory disease of cervix vagina and vulva',
      gr: 'Μολυσματική ασθένεια στον αυχένα στον κόλπο και στο αιδοίο',
    },
  },
  { value: '543', label: { en: '617 Endometriosis', gr: 'Ενδομητρίωση' } },
  {
    value: '544',
    label: { en: '618 Genital prolapse', gr: 'Γενετική πρόπτωση' },
  },
  {
    value: '545',
    label: {
      en: '619 Fistulae involving female genital tract',
      gr: 'Συρίγγιο σχετιζόμενο με το γυναικείο γενετικό σύστημα',
    },
  },
  {
    value: '546',
    label: {
      en:
        '620 Noninflammatory disorders of ovary fallopian tube and broad ligament',
      gr:
        'Μη μολυσματικές διαταραχές στις ωοθήκες σάλπιγγες και στον πλατύ σύνδεσμο',
    },
  },
  {
    value: '547',
    label: {
      en: '621 Disorders of uterus not elsewhere classified',
      gr: 'Διαταραχές στη μήτρα που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '548',
    label: {
      en: '622 Noninflammatory disorders of cervix',
      gr: 'Μη μολυσματικές διαταραχές στον αυχένα',
    },
  },
  {
    value: '549',
    label: {
      en: '623 Noninflammatory disorders of vagina',
      gr: 'Μη μολυσματικές διαταραχές στον κόλπο',
    },
  },
  {
    value: '550',
    label: {
      en: '624 Noninflammatory disorders of vulva and perineum',
      gr: 'Μη μολυσματικές διαταραχές στο αιδοίο και περίνεο',
    },
  },
  {
    value: '551',
    label: {
      en: '625 Pain and other symnptoms associated with female genital organs',
      gr:
        'Πόνοι και άλλα συμπτώματα σχετιζόμενα με τα γυναικεία γεννητικά όργανα',
    },
  },
  {
    value: '552',
    label: {
      en:
        '626 Disorders of menstruation and other abnormal bleading from female genital tract',
      gr:
        'Διαταραχές στην εμμηνόρροια και άλλες αφύσικες αιμορραγίες από το γυναικείο γενετικό σύστημα',
    },
  },
  {
    value: '553',
    label: {
      en: '627 Menopausal and postmenopausal disorders',
      gr: 'Εμμηνόπαυση και διαταραχές μετά την εμμηνόπαυση',
    },
  },
  {
    value: '554',
    label: { en: '628 Infertility female', gr: 'Γυναικεία στειρότητα' },
  },
  {
    value: '555',
    label: {
      en: '629 Other disorders of female genital organs',
      gr: 'Άλλες διαταραχές των γυναικείων γεννητικών οργάνων',
    },
  },
  {
    value: '556',
    label: { en: '630 Hydatidiform mole', gr: 'Υδατιδόμορφη μύλη' },
  },
  {
    value: '557',
    label: {
      en: '631 Other adnormal product of conception',
      gr: 'Άλλα αφύσικα παράγωγα της κύησης',
    },
  },
  {
    value: '558',
    label: { en: '632 Missed abortion', gr: 'Παλίνδρομη έκτρωση' },
  },
  {
    value: '559',
    label: { en: '633 Ectopic pregnancy', gr: 'Εξωμήτρια κύηση' },
  },
  {
    value: '560',
    label: { en: '634 Spontaneous abortion', gr: 'Αυτόματη έκτρωση' },
  },
  {
    value: '561',
    label: {
      en: '635 Legally induced abortion',
      gr: 'Νομικά προκαλούμενη έκτρωση',
    },
  },
  {
    value: '562',
    label: {
      en: '636 Illegally induced abortion',
      gr: 'Παράνομα προκαλούμενη έκτρωση',
    },
  },
  {
    value: '563',
    label: { en: '637 Unspecified abortion', gr: 'Μη ξεκαθαρισμένη έκτρωση' },
  },
  {
    value: '564',
    label: {
      en: '638 Failed attempted abortion',
      gr: 'Αποτυχημένη απόπειρα έκτρωσης',
    },
  },
  {
    value: '565',
    label: {
      en:
        '639 Complications following abortion and ectopic and mular pregnancies',
      gr: 'Επιπλοκές μετά από έκτρωση και εξωμήτρια ή στο διάκενο εγκυμοσύνη',
    },
  },
  {
    value: '566',
    label: {
      en: '640 Haemorrhage in early pregnancy',
      gr: 'Αιμορραγία στην αρχή της εγκυμοσύνης',
    },
  },
  {
    value: '567',
    label: {
      en: '641 Antepartum haemorrhage abruptio placentae and placenta praevia',
      gr: 'Η προ του τοκετού αιμορραγία ρήξη πλακούντα και πρόδρομος πλακούντα',
    },
  },
  {
    value: '568',
    label: {
      en:
        '642 Hypertension complicating pregnancy childbirth and the puerperium',
      gr: 'Υπέρταση που περιπλέκει την εγκυμοσύνη γέννα και λοχεία',
    },
  },
  {
    value: '569',
    label: {
      en: '643 Excessive vomiting in pregnancy',
      gr: 'Υπερβολικός εμετός κατά την εγκυμοσύνη',
    },
  },
  {
    value: '570',
    label: {
      en: '644 Early or threatened labour',
      gr: 'Πρόωρος ή ενδεχόμενος πρόωρος τοκετός',
    },
  },
  {
    value: '571',
    label: { en: '645 Prolonged pregnancy', gr: 'Παρατεταμένη εγκυμοσύνη' },
  },
  {
    value: '572',
    label: {
      en: '646 Other complications of pregnancy not elsewhere classified',
      gr: 'Άλλες επιπλοκές στην εγκυμοσύνη που δεν ταξινομούνται αλλού',
    },
  },
  {
    value: '573',
    label: {
      en:
        '647 Infective and parasitic conditions in the mother classifiable elsewhere but complicating pregnancy childbirth and the puerperium',
      gr:
        'Μολυσματικές και παρασιτικές καταστάσεις που έχουν ταξινομηθεί αλλού αλλά περιπλέκουν την εγκυμοσύνη την γέννα και τη λοχεία',
    },
  },
  {
    value: '574',
    label: {
      en:
        '648 Other current conditions in the mother classifiable elsewhere but complicating pregnancy childbirth and the puerperium',
      gr:
        'Άλλες τρέχουσες καταστάσεις ταξινομημένες αλλού αλλά που περιπλέκουν την εγκυμοσύνη την γέννα και τη λοχεία',
    },
  },
  {
    value: '575',
    label: {
      en: '650 Delivery in a completely normal case',
      gr: 'Τοκετός σε πλήρως φυσιολογικές συνθήκες',
    },
  },
  {
    value: '576',
    label: { en: '651 Multiple gestation', gr: 'Πολλαπλή κυοφορία' },
  },
  {
    value: '577',
    label: {
      en: '652 Malposition and malpresentation of fetus',
      gr: 'Λανθασμένη θέση και λανθασμένη παρουσίαση του εμβρύου',
    },
  },
  { value: '578', label: { en: '653 Disproportion', gr: 'Δυσαναλογία' } },
  {
    value: '579',
    label: {
      en: '654 Abnormality of organs and soft tissues of pelvis',
      gr: 'Δυσμορφία οργάνων και μαλακοί ιστοί στη λεκάνη',
    },
  },
  {
    value: '580',
    label: {
      en:
        '655 Known or suspected fetal abnormality affecting management of mother',
      gr:
        'Γνωστή ή ύποπτη εμβρυϊκή ανωμαλία που επηρεάζει την λειτουργικότητα της μητέρας',
    },
  },
  {
    value: '581',
    label: {
      en:
        '656 Other fetal and placental problems affecting management of mother',
      gr:
        'Άλλα προβλήματα του εμβρύου και του πλακούντα που επηρεάζουν την λειτουργικότητα της μητέρας',
    },
  },
  { value: '582', label: { en: '657 Polyhydramnios', gr: 'Πολυυδράμνιο' } },
  {
    value: '583',
    label: {
      en: '658 Other problems associated with amniotic cavity and membranes',
      gr: 'Άλλα προβλήματα σχετιζόμενα με την αμνιακή κοιλότητα και μεμβράνες',
    },
  },
  {
    value: '584',
    label: {
      en:
        '659 Other indications for care or intervention related to labour and delivery and not elsewhere classified',
      gr:
        'Άλλες ενδείξεις για ειδική φροντίδα ή επέμβαση σχετιζόμενη με τον τοκετό και που δεν ταξινομήθηκε αλλού',
    },
  },
  {
    value: '585',
    label: { en: '660 Obstructed labour', gr: 'Παρακωλυμένος τοκετός' },
  },
  {
    value: '586',
    label: {
      en: '661 Abnormality of forces of labour',
      gr: 'Ανωμαλία στις δυνάμεις του τοκετού',
    },
  },
  { value: '587', label: { en: '662 Long labour', gr: 'Τοκετός διαρκείας' } },
  {
    value: '588',
    label: {
      en: '663 Umbilical cord complications',
      gr: 'Επιπλοκές στον ομφάλιο λώρο',
    },
  },
  {
    value: '589',
    label: {
      en: '664 Trauma to perineum and vulva during delivery',
      gr: 'Τραύμα στο περίνεο και στο αιδοίο κατά την γέννα',
    },
  },
  {
    value: '590',
    label: { en: '665 Other obstetrical trauma', gr: 'Άλλο μαιευτικό τραύμα' },
  },
  {
    value: '591',
    label: { en: '666 Postpartum haemorrhage', gr: 'Μεταγεννητική αιμορραγία' },
  },
  {
    value: '592',
    label: {
      en:
        '667 Complications of the administration of anaesthetic or other sedation in labour and delivery',
      gr:
        'Επιπλοκές στην διαδικασία αναισθησίας ή άλλης νάρκωσης κατά τον τοκετό',
    },
  },
  {
    value: '593',
    label: {
      en: '667 Retained placenta or membranes without haemorrhage',
      gr: 'Συγκράτηση πλακούντα ή μεμβρανών χωρίς αιμορραγία',
    },
  },
  {
    value: '594',
    label: {
      en:
        '669 Other complications of labour and delivery not elsewhere classified',
      gr:
        'Άλλες επιπλοκές του τοκετού και της γέννας που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '595',
    label: {
      en: '670 Major puerperal infection',
      gr: 'Κύρια επιλόχεια μόλυνση',
    },
  },
  {
    value: '596',
    label: {
      en: '671 Venous complications in pregnancy and the puerperium',
      gr: 'Φλεβικές επιπλοκές στην εγκυμοσύνη και στην λοχεία',
    },
  },
  {
    value: '597',
    label: {
      en: '672 Pyrexia of unknown origin during the puerperium',
      gr: 'Πυρετός προερχόμενος από άγνωστη αιτία κατά την λοχεία',
    },
  },
  {
    value: '598',
    label: {
      en: '673 Obstetrical pulmonary embolism',
      gr: 'Μαιευτική πνευμονική εμβολή',
    },
  },
  {
    value: '599',
    label: {
      en:
        '674 Other and unspecified complications of the puerperium not elsewhere classified',
      gr:
        'Άλλες και ακαθόριστες επιπλοκές της λοχείας που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '600',
    label: {
      en: '675 Infections of the breast and nipple associated with childbirth',
      gr: 'Μολύνσεις των μαστών και θηλών σχετιζόμενες με την γέννα',
    },
  },
  {
    value: '601',
    label: {
      en:
        '676 Other disorders of the breast associated with childbirth and disorders of lactation',
      gr:
        'Άλλες διαταραχές των μαστών σχετιζόμενες με την γέννα και την γαλακτογονία',
    },
  },
  {
    value: '602',
    label: {
      en: '680 Carbuncle and furuncle',
      gr: 'Ψευδάνθρακας και δοθιήνας',
    },
  },
  {
    value: '603',
    label: {
      en: '681 Cellulitis and abscess of finger and toe',
      gr: 'Κυτταρίτιδα και απόστημα δακτύλου χεριού ή ποδιού',
    },
  },
  {
    value: '604',
    label: {
      en: '682 Other cellulitis and abscess',
      gr: 'Άλλη κυτταρίτιδα ή απόστημα',
    },
  },
  {
    value: '605',
    label: { en: '683 Acute lymphadenitis', gr: 'Οξεία λεμφαδενίτιδα' },
  },
  { value: '606', label: { en: '684 Impetigo', gr: 'Έκζεμα προσώπου' } },
  {
    value: '607',
    label: { en: '685 Pilonidal cyst', gr: 'Κύστη που περιέχει τρίχες' },
  },
  {
    value: '608',
    label: {
      en: '686 Other local infections of skin and subcutaneous tissue',
      gr: 'Άλλες τοπικές μολύνσεις του δέρματος και υποδερμικού ιστού',
    },
  },
  {
    value: '609',
    label: {
      en: '690 Erythematosquamous dermatosis',
      gr: 'Ερυθρολεπιδωτή δερματοπάθεια',
    },
  },
  {
    value: '610',
    label: {
      en: '691 Atopic dermatitis and related conditions',
      gr: 'Ατοπική δερματίτιδα και σχετιζόμενες καταστάσεις',
    },
  },
  {
    value: '611',
    label: {
      en: '692 Contact dermatitis and other eczema',
      gr: 'Δερματίτιδα εξ επαφής και άλλα εκζέματα',
    },
  },
  {
    value: '612',
    label: {
      en: '693 Dermatitis due to taken internally substances',
      gr: 'Δερματίτιδα οφειλόμενη σε παροχή ουσιών εσωτερικά',
    },
  },
  {
    value: '613',
    label: { en: '694 Bullous dermatoses', gr: 'Φουσκαλιασμένη δερματοπάθεια' },
  },
  {
    value: '614',
    label: {
      en: '695 Erythematous conditions',
      gr: 'Καταστάσεις ερυθρότητας του δέρματος',
    },
  },
  {
    value: '615',
    label: {
      en: '696 Psoriasis and similar disorders',
      gr: 'Ψωρίαση και παρόμοιες διαταραχές',
    },
  },
  { value: '616', label: { en: '697 Lichen', gr: 'Λειχήνα' } },
  {
    value: '617',
    label: {
      en: '698 Pruritus and related conditions',
      gr: 'Φαγούρα και συγγενικές καταστάσεις',
    },
  },
  {
    value: '618',
    label: {
      en: '700 Corns and callosities',
      gr: 'Κάλοι και σκληρές επιφάνειες',
    },
  },
  {
    value: '619',
    label: {
      en: '701 Other hypertrophic and atrophic conditions of skin',
      gr: 'Άλλες υπερτροφικές και ατροφικές καταστάσεις του δέρματος',
    },
  },
  {
    value: '620',
    label: { en: '702 Other dermatoses', gr: 'Άλλες δερματοπάθειες' },
  },
  {
    value: '621',
    label: { en: '703 Diseases of nail', gr: 'Ασθένειες των νυχιών' },
  },
  {
    value: '622',
    label: {
      en: '704 Diseases of hair and hair follicles',
      gr: 'Ασθένειες των τριχών και των αδένων των τριχών',
    },
  },
  {
    value: '623',
    label: {
      en: '705 Disorders of sweat glands',
      gr: 'Διαταραχές των ιδρωτοποιών αδένων',
    },
  },
  {
    value: '624',
    label: {
      en: '706 Diseases of sebaceous glands',
      gr: 'Ασθένειες των σμηγματικών αδένων',
    },
  },
  {
    value: '625',
    label: { en: '707 Chronic ulcer of skin', gr: 'Χρόνιο έλκος του δέρματος' },
  },
  {
    value: '626',
    label: { en: '708 Urticaria', gr: 'Ουρτικάρια (αλλεργική ασθένεια)' },
  },
  {
    value: '627',
    label: {
      en: '709 Other disorders of skin and subcutaneous tissue',
      gr: 'Άλλες παθήσεις δερματικών και υποδερμικών ιστών',
    },
  },
  {
    value: '628',
    label: {
      en: '710 Diffuse diseases of connective tissue',
      gr: 'Διάχυτες ασθένειες των συνδετικών ιστών',
    },
  },
  {
    value: '629',
    label: {
      en: '711 Arthropathy associated with infections',
      gr: 'Αρθροπάθεια σχετιζόμενη με λοιμώξεις',
    },
  },
  {
    value: '630',
    label: { en: '712 Crystal arthropathies', gr: 'Κρυστάλλινες αρθροπάθειες' },
  },
  {
    value: '631',
    label: {
      en:
        '713 Arthropathy associated with other disorders classified elsewhere',
      gr: 'Αρθροπάθεια σχετιζόμενη με άλλες διαταραχές που ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '632',
    label: {
      en: '714 Rheumatoid arthritis and other inflammatory polyarthropathies',
      gr: 'Ρευματοειδής αρθρίτιδα και άλλες μολυσματικές πολυαρθροπάθειες',
    },
  },
  {
    value: '633',
    label: {
      en: '715 Osteoarthrosis and allied disorders',
      gr: 'Οστεοάρθρωση και συγγενικές διαταραχές',
    },
  },
  {
    value: '634',
    label: {
      en: '716 Other and unspecified arthropathies',
      gr: 'Άλλες και απροσδιόριστες αρθροπάθειες',
    },
  },
  {
    value: '635',
    label: {
      en: '717 Internal derangement of knee',
      gr: 'Εσωτερική διατάραξη του γονάτου',
    },
  },
  {
    value: '636',
    label: {
      en: '718 Other derangement of joint',
      gr: 'Άλλη διατάραξη άρθρωσης',
    },
  },
  {
    value: '637',
    label: {
      en: '719 Other and unspecified disorder of joint',
      gr: 'Άλλη και απροσδιόριστη διαταραχή άρθρωσης',
    },
  },
  {
    value: '638',
    label: {
      en: '720 Ankylosing spondylitis and other inflammatory spondylopathies',
      gr: 'Αγκυλωτική σπονδυλίτιδα και άλλες μολυσματικές σπονδυλοπάθειες',
    },
  },
  {
    value: '639',
    label: {
      en: '721 Spondylosis and allied disorders',
      gr: 'Σπονδύλωση και συγγενικές διαταραχές',
    },
  },
  {
    value: '640',
    label: {
      en: '722 Intervertebral disc disorders',
      gr: 'Διαταραχές του μεσοσπονδύλιου δίσκου',
    },
  },
  {
    value: '641',
    label: {
      en: '723 Other dosorders of cervical region',
      gr: 'Άλλες διαταραχές της αυχενικής περιοχής',
    },
  },
  {
    value: '642',
    label: {
      en: '724 Other and unspecified disorders of back',
      gr: 'Άλλες και απροσδιόριστες διαταραχές της πλάτης',
    },
  },
  {
    value: '643',
    label: { en: '725 Polymyalgia rheumatica', gr: 'Ρευματική πολυμυαλγία' },
  },
  {
    value: '644',
    label: {
      en: '726 Peripheral enthesopathies and allied syndromes',
      gr:
        'Περιφερειακή διαταραχή της μυϊκής προσφύσεως των οστών και συγγενικά σύνδρομα',
    },
  },
  {
    value: '645',
    label: {
      en: '727 Other disorders of synovium tendon and bursa',
      gr:
        'Άλλες διαταραχές των αρθρικών υμένων. τενόντων και σάκων μεταξύ τενόντων και οστών',
    },
  },
  {
    value: '646',
    label: {
      en: '728 Disorders of muscle ligament and fascia',
      gr: 'Διαταραχές μυώνων συνδέσμων και περιτοναίων',
    },
  },
  {
    value: '647',
    label: {
      en: '729 Other disorders of soft tissues',
      gr: 'Άλλες διαταραχές των μαλακών ιστών',
    },
  },
  {
    value: '648',
    label: {
      en: '730 Osteomyelitis periostitis and other infections involving bone',
      gr:
        'Οστεομυελίτιδα περιοστίτιδα και άλλες μολύνσεις σχετιζόμενες με κόκαλο',
    },
  },
  {
    value: '649',
    label: {
      en:
        '731 Osteitis deformals and osteopathies associated with other disorders classified elsewhere',
      gr:
        'Οστίτιδα δυσμορφίες και οστεοπάθειες σχετιζόμενες με άλλες διαταραχές που ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '650',
    label: { en: '732 Osteochondropathies', gr: 'Οστεοχονδροπάθειες' },
  },
  {
    value: '651',
    label: {
      en: '733 Other disorders of bone and cartilage',
      gr: 'Άλλες διαταραχές οστών και χόνδρων',
    },
  },
  { value: '652', label: { en: '734 Flat foot', gr: 'Πλατυποδία' } },
  {
    value: '653',
    label: {
      en: '735 Acquired deformities of toe',
      gr: 'Επίκτητες παραμορφώσεις των δακτύλων του ποδιού',
    },
  },
  {
    value: '654',
    label: {
      en: '736 Other acquired deformities of limbs',
      gr: 'Άλλες επίκτητες παραμορφώσεις των άκρων',
    },
  },
  {
    value: '655',
    label: {
      en: '737 Curvature of spine',
      gr: 'Καμπύλωση της σπονδυλικής στήλης',
    },
  },
  {
    value: '656',
    label: {
      en: '738 Other acquired deformity',
      gr: 'Άλλη επίκτητη παραμόρφωση',
    },
  },
  {
    value: '657',
    label: {
      en: '739 Nonallopathic lesions not elsewhere classified',
      gr: 'Μη αλλοπαθητικές βλάβες που δεν ταξινομούνται αλλού',
    },
  },
  {
    value: '658',
    label: {
      en: '740 Anencephalus and similar anomalies',
      gr: 'Ανεγκέφαλος και παρόμοιες ανωμαλίες',
    },
  },
  { value: '659', label: { en: '740 Hydrocephalus', gr: 'Υδροκέφαλος' } },
  {
    value: '660',
    label: { en: '741 Spinal bifida', gr: 'Δισχιδής σπονδυλική στήλη' },
  },
  {
    value: '661',
    label: {
      en: '742 Other congenital anomalies of nervous system',
      gr: 'Άλλες εκ γενετής ανωμαλίες του νευρικού συστήματος',
    },
  },
  {
    value: '662',
    label: {
      en: '743 Congenital anomalies of eye',
      gr: 'Εκ γενετής ανωμαλίες στα μάτια',
    },
  },
  {
    value: '663',
    label: {
      en: '744 Congenital anomalies of ear face and neck',
      gr: 'Εκ γενετής ανωμαλίες στα αυτιά πρόσωπο και λαιμό',
    },
  },
  {
    value: '664',
    label: {
      en:
        '745 Bulbus cordis anomalies and anomalies of  cardiac septal closure',
      gr: 'Ανωμαλίες καρδιακού βολβού και καρδιακής διαφραγματικής βαλβίδας',
    },
  },
  {
    value: '665',
    label: {
      en: '746 Other congenital anomalies of heart',
      gr: 'Άλλες εκ γενετής ανωμαλίες της καρδιάς',
    },
  },
  {
    value: '666',
    label: {
      en: '747 Other congenital anomalies of circulatory system',
      gr: 'Άλλες εκ γενετής ανωμαλίες του κυκλοφοριακού συστήματος',
    },
  },
  {
    value: '667',
    label: {
      en: '748 Congenital anomalies of respiratory system',
      gr: 'Εκ γενετής ανωμαλίες του αναπνευστικού συστήματος',
    },
  },
  {
    value: '668',
    label: {
      en: '749 cleft palate and cleft lip',
      gr: 'Σχισμή στον ουρανίσκο και σχισμή στα χείλη',
    },
  },
  {
    value: '669',
    label: {
      en: '750 Other congenital anomalies of upper alimentary tract',
      gr: 'Άλλες εκ γενετής ανωμαλίες του άνω θρεπτικού συστήματος οργάνων',
    },
  },
  {
    value: '670',
    label: {
      en: '751 Other congenital anomalies of digestive system',
      gr: 'Άλλες εκ γενετής ανωμαλίες του πεπτικού συστήματος',
    },
  },
  {
    value: '671',
    label: {
      en: '752 Congenital anomalies of genital organs',
      gr: 'Εκ γενετής ανωμαλίες των γεννητικών οργάνων',
    },
  },
  {
    value: '672',
    label: {
      en: '753 Congenital anomalies of urinary system',
      gr: 'Εκ γενετής ανωμαλίες του ουροποιητικού συστήματος',
    },
  },
  {
    value: '673',
    label: {
      en: '754 Certain congenital musculoskeletal deformities',
      gr: 'Κάποιες εκ γενετής μυοσκελετικές δυσμορφίες',
    },
  },
  {
    value: '674',
    label: {
      en: '755 Other congenital anomalies of limbs',
      gr: 'Άλλες εκ γενετής ανωμαλίες των άκρων',
    },
  },
  {
    value: '675',
    label: {
      en: '756 Other congenital musculoskeletal anomalies',
      gr: 'Άλλες εκ γενετής μυοσκελετικές ανωμαλίες',
    },
  },
  {
    value: '676',
    label: {
      en: '757 Congenital anomalies of the integument',
      gr: 'Εκ γενετής ανωμαλίες του δέρματος',
    },
  },
  {
    value: '677',
    label: { en: '758 Chromosomal anomalies', gr: 'Χρωμοσωματικές ανωμαλίες' },
  },
  {
    value: '678',
    label: {
      en: '759 Other and unspecified congenital anomalies',
      gr: 'Άλλες και απροσδιόριστες εκ γενετής ανωμαλίες',
    },
  },
  {
    value: '679',
    label: {
      en:
        '760 Fetus or newborn affected by maternal conditions which may be unrelated to present pregnancy',
      gr:
        'Έμβρυο ή νεογέννητο επηρεασμένο από μητρικές καταστάσεις που μπορεί να μην σχετίζονται με την παρούσα εγκυμοσύνη',
    },
  },
  {
    value: '680',
    label: {
      en:
        '761 Fetus or newborn affected by maternal complications of pregnancy',
      gr:
        'Έμβρυο ή νεογέννητο επηρεασμένο από μητρικές επιπλοκές της εγκυμοσύνης',
    },
  },
  {
    value: '681',
    label: {
      en:
        '762 Fetus or newborn affected by complications of placenta cord and membranes',
      gr:
        'Έμβρυο ή νεογέννητο επηρεασμένο από επιπλοκές του πλακούντα λώρου και μεμβρανών',
    },
  },
  {
    value: '682',
    label: {
      en:
        '763 Fetus or newborn affected by other complications of labour and delivery',
      gr:
        'Έμβρυο ή νεογέννητο επηρεασμένο από άλλες επιπλοκές του τοκετού και της γέννας',
    },
  },
  {
    value: '683',
    label: {
      en: '764 Slow fetal growth and fetal malnutrition',
      gr: 'Αργή εμβρυακή ανάπτυξη και εμβρυακός υποσιτισμός',
    },
  },
  {
    value: '684',
    label: {
      en:
        '765 Disorders relating to short gestation and unspecified low birth-weight',
      gr:
        'Διαταραχές σχετιζόμενες με ελλιπή κυοφορία και απροσδιόριστο χαμηλό βάρος του βρέφους',
    },
  },
  {
    value: '685',
    label: {
      en: '766 Disorders relating to long gestation and high birth weight',
      gr:
        'Διαταραχές σχετιζόμενες με μακρά κυοφορία και ψηλό βάρος του βρέφους',
    },
  },
  { value: '686', label: { en: '767 Birth trauma', gr: 'Τραύμα γέννας' } },
  {
    value: '687',
    label: {
      en: '768 Intrauterine hypoxia and birth asphyxia',
      gr: 'Εσωουρηθρική υποξία και ασφυξία της γέννας',
    },
  },
  {
    value: '688',
    label: {
      en: '769 Respiratory distress syndrome',
      gr: 'Σύνδρομο αναπνευστικής καταπόνησης',
    },
  },
  {
    value: '689',
    label: {
      en: '770 Other respiratory conditions of fetus and newborn',
      gr: 'Άλλες αναπνευστικές καταστάσεις του εμβρύου και του νεογέννητου',
    },
  },
  {
    value: '690',
    label: {
      en: '771 Infections specific to the perinatal period',
      gr: 'Λοιμώξεις σχετικές με την περιγεννητική περίοδο',
    },
  },
  {
    value: '691',
    label: {
      en: '772 Fetal and neonatal haemorrhage',
      gr: 'Εμβρυϊκή και νεογνική αιμορραγία',
    },
  },
  {
    value: '692',
    label: {
      en: '773 Haemolytic disease of fetus or newborn due to isoimmunization',
      gr: 'Εμβρυακή ερυθροβλάστωση οφειλόμενη σε ισοανοσοποίηση',
    },
  },
  {
    value: '693',
    label: {
      en: '774 Other perinatal jaundice',
      gr: 'Άλλος περιγεννητικός ίκτερος',
    },
  },
  {
    value: '694',
    label: {
      en:
        '775 Endocrine and metabolic disturbances specific to the fetus and newborn',
      gr:
        'Ενδοκρινείς και μεταβολικές διαταραχές σχετικές με έμβρυο και νεογέννητο',
    },
  },
  {
    value: '695',
    label: {
      en: '776 Haematological disorders of fetus and newborn',
      gr: 'Αιματολογικές διαταραχές του εμβρύου και του νεογέννητου',
    },
  },
  {
    value: '696',
    label: {
      en: '777 Perinatal disorders of digestive system',
      gr: 'Περιγεννητικές διαταραχές του πεπτικού συστήματος',
    },
  },
  {
    value: '697',
    label: {
      en:
        '778 Conditions involving the integuement and temperature regulation of fetus and newborn',
      gr:
        'Καταστάσεις σχετικές με τη ρύθμιση του δέρματος και της θερμοκρασίας του εμβρύου και του νεογέννητου',
    },
  },
  {
    value: '698',
    label: {
      en:
        '779 Other and ill-defined conditions originating in the perinatal period',
      gr:
        'Άλλες και ασθενείς καταστάσεις προσδιορισμένες στην περιγεννητική περίοδο',
    },
  },
  {
    value: '699',
    label: { en: '780 General symptoms', gr: 'Γενικά συμπτώματα' },
  },
  {
    value: '700',
    label: {
      en: '781 Symptoms involving nervous and musculoskeletal systems',
      gr: 'Συμπτώματα σχετικά με το νευρικό και μυοσκελετικό συστήματα',
    },
  },
  {
    value: '701',
    label: {
      en: '782 Symptoms involving skin and other integumentary tissue',
      gr: 'Συμπτώματα σχετικά με την επιδερμίδα και άλλους δερματικούς ιστούς',
    },
  },
  {
    value: '702',
    label: {
      en: '783 Symptoms concerning nutrition metabolism and development',
      gr:
        'Συμπτώματα που αφορούν την διατροφή τον μεταβολισμό και την ανάπτυξη',
    },
  },
  {
    value: '703',
    label: {
      en: '784 Symptoms involving head and neck',
      gr: 'Συμπτώματα σχετικά με το κεφάλι και το λαιμό',
    },
  },
  {
    value: '704',
    label: {
      en: '785 Symptoms involving cardiovascular system',
      gr: 'Συμπτώματα σχετικά με το καρδιαγγειακό σύστημα',
    },
  },
  {
    value: '705',
    label: {
      en: '786 Symptoms involving respiratory system and other chest symptoms',
      gr:
        'Συμπτώματα σχετικά με το αναπνευστικό σύστημα και άλλα συμπτώματα στο στήθος',
    },
  },
  {
    value: '706',
    label: {
      en: '787 Symptoms involving digestive system',
      gr: 'Συμπτώματα σχετικά με το πεπτικό σύστημα',
    },
  },
  {
    value: '707',
    label: {
      en: '788 Symptoms involving urinary system',
      gr: 'Συμπτώματα σχετικά με το ουροποιητικό σύστημα',
    },
  },
  {
    value: '708',
    label: {
      en: '789 Other symptoms involving abdominal and pelvis',
      gr: 'Άλλα συμπτώματα σχετικά με την γαστρική περιοχή και τη λεκάνη',
    },
  },
  {
    value: '709',
    label: {
      en: '790 Nonspecific findings on examination of blood',
      gr: 'Μη συγκεκριμένα ευρήματα κατά την εξέταση του αίματος',
    },
  },
  {
    value: '710',
    label: {
      en: '791 Nonspecific findings on examination of urine',
      gr: 'Μη συγκεκριμένα ευρήματα κατά την εξέταση των ούρων',
    },
  },
  {
    value: '711',
    label: {
      en: '792 Nonspecific abnormal findings in other body substances',
      gr: 'Μη συγκεκριμένα ασυνήθιστα ευρήματα σε άλλες ουσίες του σώματος',
    },
  },
  {
    value: '712',
    label: {
      en:
        '793 Nonspecific abnormal findings on radiological and other examinations of body structure',
      gr:
        'Μη συγκεκριμένα ασυνήθιστα ευρήματα σε ραδιολογικές και άλλες εξετάσεις του σώματος',
    },
  },
  {
    value: '713',
    label: {
      en: '794 Nonspecific abnormal results of function studies',
      gr: 'Μη συγκεκριμένα ασυνήθιστα ευρήματα σε λειτουργικές μελέτες',
    },
  },
  {
    value: '714',
    label: {
      en: '795 Nonspecific abnormal historical and immunological findings',
      gr: 'Μη συγκεκριμένα ασυνήθιστα ιστορικά και ανοσολογικά ευρήματα',
    },
  },
  {
    value: '715',
    label: {
      en: '796 Other nonspecific abnormal findings',
      gr: 'Άλλα μη συγκεκριμένα ασυνήθιστα ευρήματα',
    },
  },
  {
    value: '716',
    label: {
      en: '797 Senility without mention of psychosis',
      gr: 'Άνοια χωρίς να γίνεται μνεία για ψύχωση',
    },
  },
  {
    value: '717',
    label: {
      en: '798 Sudden death cause unknown',
      gr: 'Ξαφνικός θάνατος άγνωστης αιτίας',
    },
  },
  {
    value: '718',
    label: {
      en: '799 Other ill-defined and unknown causes of morbidity and mortality',
      gr: 'Άλλες ασθενείς και άγνωστες αιτίες νοσηρότητας και θνησιμότητας',
    },
  },
  {
    value: '719',
    label: {
      en: '800 Fracture of vault of skull',
      gr: 'Κάταγμα του θόλου του κρανίου',
    },
  },
  {
    value: '720',
    label: {
      en: '801 Fracture of base of skull',
      gr: 'Κάταγμα της βάσης του κρανίου',
    },
  },
  {
    value: '721',
    label: {
      en: '802 Fracture of face bones',
      gr: 'Κάταγμα των οστών του προσώπου',
    },
  },
  {
    value: '722',
    label: {
      en: '803 Other and unqualified skull fractures',
      gr: 'Άλλα και ακαθόριστα κατάγματα του κρανίου',
    },
  },
  {
    value: '723',
    label: {
      en: '804 Multiple fractures involving skull or face with other bones',
      gr: 'Πολλαπλά κατάγματα σχετικά με το κρανίο ή το πρόσωπο και άλλα οστά',
    },
  },
  {
    value: '724',
    label: {
      en:
        '805 Fracture of vertebra column without mention of spinal cord lesion',
      gr: 'Κάταγμα σπονδυλικής στήλης χωρίς μνεία για βλάβη στον νωτιαίο μυελό',
    },
  },
  {
    value: '725',
    label: {
      en: '806 Fracture of vertebral column with spinal cord lesion',
      gr: 'Κάταγμα σπονδυλικής στήλης με βλάβη στον νωτιαίο μυελό',
    },
  },
  {
    value: '726',
    label: {
      en: '807 Fracture of rib(s) sternum larynx and trachea',
      gr: 'Κάταγμα στις πλευρές το στέρνο τον λάρυγγα και την τραχεία',
    },
  },
  {
    value: '727',
    label: { en: '808 Fracture of pelvis', gr: 'Κάταγμα λεκάνης' },
  },
  {
    value: '728',
    label: {
      en: '809 Ill-defined fractures of trunk',
      gr: 'Κατάγματα του κορμού',
    },
  },
  {
    value: '729',
    label: {
      en: '810 Fracture of clavicle',
      gr: 'Κάταγμα του οστού της κλείδας',
    },
  },
  {
    value: '730',
    label: { en: '811 Fracture of scapula', gr: 'Κάταγμα της ωμοπλάτης' },
  },
  {
    value: '731',
    label: { en: '812 Fracture of humerus', gr: 'Κάταγμα του βραχιόνιου' },
  },
  {
    value: '732',
    label: {
      en: '813 Fracture of radius and ulna',
      gr: 'Κάταγμα της κερκίδας και της ωλένης',
    },
  },
  {
    value: '733',
    label: {
      en: '814 Fracture of carpal bone(s)',
      gr: 'Κάταγμα καρπικών οστών',
    },
  },
  {
    value: '734',
    label: {
      en: '815 Fracture of metacarpal bone (s)',
      gr: 'Κάταγμα μετακαρπικών οστών',
    },
  },
  {
    value: '735',
    label: {
      en: '816 Fracture of one or more phalanges of hand',
      gr: 'Κάταγμα ενός ή περισσοτέρων φαλαγγών του χεριού',
    },
  },
  {
    value: '736',
    label: {
      en: '817 Multiple fractures of hand bones',
      gr: 'Πολλαπλά κατάγματα των οστών του χεριού',
    },
  },
  {
    value: '737',
    label: {
      en: '818 Ill-defined fractures of upper limb',
      gr: 'Κατάγματα των άνω άκρων',
    },
  },
  {
    value: '738',
    label: {
      en:
        '819 Multiple fractures involving both upper limbs and upper limb with rib(s) and sternum',
      gr:
        'Πολλαπλά κατάγματα σχετικά με τα δύο άνω άκρα και άνω άκρο με πλευρές και στέρνο',
    },
  },
  {
    value: '739',
    label: {
      en: '820 Fracture of neck of femur',
      gr: 'Κάταγμα του λαιμού του μηριαίου οστού',
    },
  },
  {
    value: '740',
    label: {
      en: '821 Fracture of other and unspecified parts of femur',
      gr: 'Κάταγμα άλλων και ακαθόριστων μερών του μηριαίου οστού',
    },
  },
  {
    value: '741',
    label: { en: '822 Fracture of patella', gr: 'Κάταγμα επιγονατίδας' },
  },
  {
    value: '742',
    label: {
      en: '823 Fracture of tibia and fibula',
      gr: 'Κάταγμα του οστού και της περόνης της κνήμης',
    },
  },
  {
    value: '743',
    label: { en: '824 Fracture of ankle', gr: 'Κάταγμα του αστραγάλου' },
  },
  {
    value: '744',
    label: {
      en: '825 Fracture of one or more tarsal and metatarsal bones',
      gr: 'Κάταγμα ενός ή περισσοτέρων ταρσικών και μεταταρσικών οστών',
    },
  },
  {
    value: '745',
    label: {
      en: '826 Fracture of one or more phalanges of foot',
      gr: 'Κάταγμα ενός ή περισσοτέρων φαλαγγών του ποδιού',
    },
  },
  {
    value: '746',
    label: {
      en: '827 Other multiple and ill-defined fractures of lower limb',
      gr: 'Άλλα πολλαπλά κατάγματα κάτω άκρου',
    },
  },
  {
    value: '747',
    label: {
      en:
        '828 Multiple fractures involving both lower limbs lower with upper limb and lower limb(s) with rib(s) and sternum',
      gr:
        'Πολλαπλά κατάγματα σχετικά με τα δύο κάτω άκρα κάτω και άνω άκρο και κάτω άκρα με άνω άκρα και στέρνο',
    },
  },
  {
    value: '748',
    label: {
      en: '829 Fracture of unspecified bones',
      gr: 'Κάταγμα απροσδιόριστων οστών',
    },
  },
  {
    value: '749',
    label: { en: '830 Dislocation of jaw', gr: 'Εξάρθρωση σιαγώνας' },
  },
  {
    value: '750',
    label: { en: '831 Dislocation of shoulder', gr: 'Εξάρθρωση ώμου' },
  },
  {
    value: '751',
    label: { en: '832 Dislocation of elbow', gr: 'Εξάρθρωση αγκώνας' },
  },
  {
    value: '752',
    label: { en: '833 Dislocation of wrist', gr: 'Εξάρθρωση καρπού' },
  },
  {
    value: '753',
    label: { en: '834 Dislocation of finger', gr: 'Εξάρθρωση δακτύλου' },
  },
  {
    value: '754',
    label: { en: '835 Dislocation of hip', gr: 'Εξάρθρωση γοφού' },
  },
  {
    value: '755',
    label: { en: '836 Dislocation of knee', gr: 'Εξάρθρωση γονάτου' },
  },
  {
    value: '756',
    label: { en: '837 Dislocation of ankle', gr: 'Εξάρθρωση αστραγάλου' },
  },
  {
    value: '757',
    label: { en: '838 Dislocation of foot', gr: 'Εξάρθρωση ποδιού' },
  },
  {
    value: '758',
    label: {
      en: '839 Other multiple and ill-defined dislocations',
      gr: 'Άλλες πολλαπλές εξαρθρώσεις',
    },
  },
  {
    value: '759',
    label: {
      en: '840 Sprains and strains of shoulder and upper arm',
      gr: 'Διαστρέμματα και στραμπουλίσματα του ώμου και του άνω βραχίονα',
    },
  },
  {
    value: '760',
    label: {
      en: '841 Sprains and strains of elbow and forearm',
      gr: 'Διαστρέμματα και στραμπουλίσματα του αγκώνα και αντιβραχίονα',
    },
  },
  {
    value: '761',
    label: {
      en: '842 Sprains and strains of wrist and hand',
      gr: 'Διαστρέμματα και στραμπουλίσματα του καρπού και του χεριού',
    },
  },
  {
    value: '762',
    label: {
      en: '843 Sprains and strains of hip and thigh',
      gr: 'Διαστρέμματα και στραμπουλίσματα του γοφού και του μηρού',
    },
  },
  {
    value: '763',
    label: {
      en: '844 Sprains and strains of knee and leg',
      gr: 'Διαστρέμματα και στραμπουλίσματα του γονάτου και του ποδιού',
    },
  },
  {
    value: '764',
    label: {
      en: '845 Sprains and strains of ankle and foot',
      gr: 'Διαστρέμματα και στραμπουλίσματα του αστραγάλου και του ποδιού',
    },
  },
  {
    value: '765',
    label: {
      en: '846 Sprains and strains of sacroiliac region',
      gr: 'Διαστρέμματα και στραμπουλίσματα στην περιοχή του ιερού οστού',
    },
  },
  {
    value: '766',
    label: {
      en: '847 Sprains and strains of other and unspecified parts of back',
      gr:
        'Διαστρέμματα και στραμπουλίσματα άλλων και απροσδιόριστων μερών της πλάτης',
    },
  },
  {
    value: '767',
    label: {
      en: '848 Other and ill-defined sprains and strains',
      gr: 'Άλλα διαστρέμματα και στραμπουλίσματα',
    },
  },
  { value: '768', label: { en: '850 Concussion', gr: 'Διάσειση εγκεφάλου' } },
  {
    value: '769',
    label: {
      en: '851 Cerebral laceration and contusion',
      gr: 'Εγκεφαλική πληγή και μωλωπισμός',
    },
  },
  {
    value: '770',
    label: {
      en:
        '852 Subarachnoid subdural and extradural haemorrhage following injury',
      gr:
        'Υπαραχνοειδής υποσκληρίδια και υπερσκληρίδια αιμορραγία μετά από τραυματισμό',
    },
  },
  {
    value: '771',
    label: {
      en: '853 Other and unspecified intracranial haemorrhage following injury',
      gr:
        'Άλλες και ακαθόριστες ενδοκρανιακές αιμορραγίες μετά από τραυματισμό',
    },
  },
  {
    value: '772',
    label: {
      en: '854 Intracranial injury of other and unspecified nature',
      gr: 'Ενδοκρανιακός τραυματισμός άλλης και ακαθόριστης φύσης',
    },
  },
  {
    value: '773',
    label: {
      en: '860 Traumatic pneumothorax and haemothorax',
      gr: 'Τραυματικός πνευμοθώρακας και αιμοθώρακας',
    },
  },
  {
    value: '774',
    label: {
      en: '861 Injury to heart and lung',
      gr: 'Τραυματισμός στην καρδιά και τον πνεύμονα',
    },
  },
  {
    value: '775',
    label: {
      en: '862 Injury to other and unspecified intrathoracic organs',
      gr: 'Τραυματισμός σε άλλα ακαθόριστα ενδοθωρακικά όργανα',
    },
  },
  {
    value: '776',
    label: {
      en: '863 Injury to gastrointestinal tract',
      gr: 'Τραυματισμός στην γαστρεντερική περιοχή',
    },
  },
  {
    value: '777',
    label: { en: '864 Injury to liver', gr: 'Τραυματισμός στο συκώτι' },
  },
  {
    value: '778',
    label: { en: '865 Injury to spleen', gr: 'Τραυματισμός στην σπλήνα' },
  },
  {
    value: '779',
    label: { en: '866 Injury to kidney', gr: 'Τραυματισμός στα νεφρά' },
  },
  {
    value: '780',
    label: {
      en: '867 Injury to pelvic organs',
      gr: 'Τραυματισμός σε όργανα της λεκάνης',
    },
  },
  {
    value: '781',
    label: {
      en: '868 Injury to other intraabdominal organs',
      gr: 'Τραυματισμός άλλων ενδογαστρικών οργάνων',
    },
  },
  {
    value: '782',
    label: {
      en: '869 Internal injury to unspecified or ill-defined organs',
      gr: 'Εσωτερικός τραυματισμός σε ακαθόριστα όργανα',
    },
  },
  {
    value: '783',
    label: {
      en: '870 Open wound of ocular adnexa',
      gr: 'Ανοιχτή πληγή σε οφθαλμικά εξαρτήματα',
    },
  },
  {
    value: '784',
    label: {
      en: '871 Open wound of eyeball',
      gr: 'Ανοιχτή πληγή στο βολβό του ματιού',
    },
  },
  {
    value: '785',
    label: { en: '872 Open wound of ear', gr: 'Ανοιχτή πληγή στο αυτί' },
  },
  {
    value: '786',
    label: {
      en: '873 Other open wound of head',
      gr: 'Άλλη ανοιχτή πληγή στο κεφάλι',
    },
  },
  {
    value: '787',
    label: { en: '874 Open wound of neck', gr: 'Ανοιχτή πληγή στο λαιμό' },
  },
  {
    value: '788',
    label: {
      en: '875 Open wound of chest (wall)',
      gr: 'Ανοιχτή πληγή στο στήθος',
    },
  },
  {
    value: '789',
    label: { en: '876 Open wound of back', gr: 'Ανοιχτή πληγή στην πλάτη' },
  },
  {
    value: '790',
    label: { en: '877 Open wound of buttock', gr: 'Ανοιχτή πληγή στον γλουτό' },
  },
  {
    value: '791',
    label: {
      en:
        '878 Open wound of genital organs (external) including traumatic amputation',
      gr:
        'Ανοιχτή πληγή στα εξωτερικά γεννητικά όργανα περιλαμβανομένης τραυματικής αποκοπής',
    },
  },
  {
    value: '792',
    label: {
      en: '879 Open wound of other and unspecified sites except limbs',
      gr: 'Ανοιχτή πληγή σε άλλα ακαθόριστα μέρη εκτός άκρων',
    },
  },
  {
    value: '793',
    label: {
      en: '880 Open wound of shoulder and upper arm',
      gr: 'Ανοιχτή πληγή στον ώμο και άνω βραχίονα',
    },
  },
  {
    value: '794',
    label: {
      en: '881 Open wound of elbow forearm and wrist',
      gr: 'Ανοιχτή πληγή στον αγκώνα πήχη και καρπό',
    },
  },
  {
    value: '795',
    label: {
      en: '882 Open wound of hand except finger(s) alone',
      gr: 'Ανοιχτή πληγή στο χέρι εκτός δάκτυλα μόνο',
    },
  },
  {
    value: '796',
    label: {
      en: '883 Open wound of finger(s)',
      gr: 'Ανοιχτή πληγή στα δάκτυλα',
    },
  },
  {
    value: '797',
    label: {
      en: '884 Multiple and unspecified open wound of upper limb',
      gr: 'Πολλαπλές και ακαθόριστες ανοιχτές πληγές των άνω άκρων',
    },
  },
  {
    value: '798',
    label: {
      en: '885 Traumatic amputation of thumb (complete) (partial)',
      gr: 'Τραυματική αποκοπή αντίχειρα (πλήρης) (μερική)',
    },
  },
  {
    value: '799',
    label: {
      en: '886 Traumatic amputation of other finger(s) (complete) (partial)',
      gr: 'Τραυματική αποκοπή άλλων δακτύλων (πλήρης) (μερική)',
    },
  },
  {
    value: '800',
    label: {
      en: '887 Traumatic amputation of arm and hand (complete) (partial)',
      gr: 'Τραυματική αποκοπή βραχίονα και χεριού (πλήρης) (μερική)',
    },
  },
  {
    value: '801',
    label: {
      en: '890 Open wound of hip and thigh',
      gr: 'ανοιχτή πληγή γοφού και μηρού',
    },
  },
  {
    value: '802',
    label: {
      en: '891 Open wound of knee leg [except thigh] and ankle',
      gr: 'ανοιχτή πληγή γονάτου ποδιού [εκτός μηρού] και αστραγάλου',
    },
  },
  {
    value: '803',
    label: {
      en: '892 Open wound of foot except toe(s) alone',
      gr: 'ανοιχτή πληγή ποδιού εκτός δακτύλων μόνο',
    },
  },
  {
    value: '804',
    label: {
      en: '893 Open wound of toe(s)',
      gr: 'ανοιχτή πληγή δακτύλων ποδιού',
    },
  },
  {
    value: '805',
    label: {
      en: '894 Multiple and unspecified open wound of lower limb',
      gr: 'Πολλαπλή κα ακαθόριστη ανοιχτή πληγή κάτω άκρου',
    },
  },
  {
    value: '806',
    label: {
      en: '895 Traumatic amputation of toe(s) (complete) (partial)',
      gr: 'Τραυματική αποκοπή δακτύλων (πλήρης) (μερική)',
    },
  },
  {
    value: '807',
    label: {
      en: '896 Traumatic amputation of foot (complete) (partial)',
      gr: 'Τραυματική αποκοπή ποδιού (πλήρης) (μερική)',
    },
  },
  {
    value: '808',
    label: {
      en: '897 Traumatic amputation of leg(s) (complete) (partial)',
      gr: 'Τραυματική αποκοπή ποδιών (πλήρης) (μερική)',
    },
  },
  {
    value: '809',
    label: {
      en: '900 Injury to blood vessels of head and neck',
      gr: 'Τραυματισμοί σε αγγεία αίματος κεφαλής και λαιμού',
    },
  },
  {
    value: '810',
    label: {
      en: '901 Injury to blood vessels of thorax',
      gr: 'Τραυματισμοί σε αγγεία αίματος θώρακος',
    },
  },
  {
    value: '811',
    label: {
      en: '902 Injury to blood vessels of abdomen and pelvis',
      gr: 'Τραυματισμοί σε αγγεία αίματος γαστρικής περιοχής και λεκάνης',
    },
  },
  {
    value: '812',
    label: {
      en: '903 Injury to blood vessels of upper extremity',
      gr: 'Τραυματισμοί σε αγγεία αίματος άνω άκρων',
    },
  },
  {
    value: '813',
    label: {
      en:
        '904 Injury to blood vessels of lower extremity and unspecified sites',
      gr: 'Τραυματισμοί σε αγγεία αίματος κάτω άκρων και ακαθόριστων μερών',
    },
  },
  {
    value: '814',
    label: {
      en: '905 Late effects of musculoskeletal and connective tissue injuries',
      gr:
        'Καθυστερημένες επιδράσεις από τραυματισμούς στους μυοσκελετικούς και συνδετικούς ιστούς',
    },
  },
  {
    value: '815',
    label: {
      en: '906 Late effects of injuries to skin and subcutaneous tissues',
      gr:
        'Καθυστερημένες επιδράσεις από τραυματισμούς στο δέρμα και υποδερμικούς ιστούς',
    },
  },
  {
    value: '816',
    label: {
      en: '907 Late effects of injuries to the nervous system',
      gr: 'Καθυστερημένες επιδράσεις από τραυματισμούς στο νευρικό σύστημα',
    },
  },
  {
    value: '817',
    label: {
      en: '908 Late effects of other and unspecified injuries',
      gr: 'Καθυστερημένες επιδράσεις άλλων και ακαθόριστων τραυματισμών',
    },
  },
  {
    value: '818',
    label: {
      en: '909 Late effects of other and unspecified external causes',
      gr: 'Καθυστερημένες επιδράσεις άλλων και ακαθόριστων εξωτερικών αιτιών',
    },
  },
  {
    value: '819',
    label: {
      en: '910 Superficial injury of face neck and scalp except eye',
      gr: 'Επιφανειακός τραυματισμός στο πρόσωπο λαιμό και κρανίο εκτός ματιών',
    },
  },
  {
    value: '820',
    label: {
      en: '911 Superficial injury of trunk',
      gr: 'Επιφανειακός τραυματισμός στον κορμό',
    },
  },
  {
    value: '821',
    label: {
      en: '912 Superficial injury of shoulder and upper arm',
      gr: 'Επιφανειακός τραυματισμός στον ώμο και άνω βραχίονα',
    },
  },
  {
    value: '822',
    label: {
      en: '913 Superficial injury of elbow forearm and wrist',
      gr: 'Επιφανειακός τραυματισμός στον αγκώνα αντιβραχίωνα και καρπό',
    },
  },
  {
    value: '823',
    label: {
      en: '914 Superficial injury of hand(s) except finger(s) alone',
      gr: 'Επιφανειακός τραυματισμός στα χέρια εκτός δάκτυλα μόνο',
    },
  },
  {
    value: '824',
    label: {
      en: '915 Superficial injury of finger(s)',
      gr: 'Επιφανειακός τραυματισμός στα δάκτυλα',
    },
  },
  {
    value: '825',
    label: {
      en: '916 Superficial injury of hip thigh leg and ankle',
      gr: 'Επιφανειακός τραυματισμός στον γοφό μηρό πόδι και αστράγαλο',
    },
  },
  {
    value: '826',
    label: {
      en: '917 Superficial injury of foot and toe(s)',
      gr: 'Επιφανειακός τραυματισμός στα πόδια και δάκτυλα',
    },
  },
  {
    value: '827',
    label: {
      en: '918 Superficial injury of eye and adnexa',
      gr: 'Επιφανειακός τραυματισμός στα μάτια και εξαρτήματα τους',
    },
  },
  {
    value: '828',
    label: {
      en: '919 Superficial injury of other multiple and unspecified sites',
      gr: 'Επιφανειακός τραυματισμός σε άλλα πολλαπλά και ακαθόριστα μέρη',
    },
  },
  {
    value: '829',
    label: {
      en: '920 Contusion of face scalp and neck except eye(s)',
      gr: 'Μωλωπισμός προσώπου κρανίου και λαιμού εκτός ματιών',
    },
  },
  {
    value: '830',
    label: {
      en: '921 Contusion of eye and adnexa',
      gr: 'Μωλωπισμός ματιών και εξαρτημάτων τους',
    },
  },
  {
    value: '831',
    label: { en: '922 Contusion of trunk', gr: 'Μωλωπισμός κορμού' },
  },
  {
    value: '832',
    label: { en: '923 Contusion of upper limb', gr: 'Μωλωπισμός άνω άκρου' },
  },
  {
    value: '833',
    label: {
      en: '924 Contusion of lower limb and of other and unspecified sites',
      gr: 'Μωλωπισμός κάτω άκρου και άλλων και ακαθόριστων μερών',
    },
  },
  {
    value: '834',
    label: {
      en: '925 Crushing injury of face scalp and neck',
      gr: 'τραυματισμός από σύνθλιψη προσώπου κρανίου και λαιμού',
    },
  },
  {
    value: '835',
    label: {
      en: '926 Crushing injury of trunk',
      gr: 'τραυματισμός από σύνθλιψη του κορμού',
    },
  },
  {
    value: '836',
    label: {
      en: '927 Crushing injury of upper limb',
      gr: 'τραυματισμός από σύνθλιψη άνω άκρου',
    },
  },
  {
    value: '837',
    label: {
      en: '928 Crushing injury of lower limb',
      gr: 'τραυματισμός από σύνθλιψη κάτω άκρου',
    },
  },
  {
    value: '838',
    label: {
      en: '929 Crushing injury of multiple and unspecified sites',
      gr: 'τραυματισμός από σύνθλιψη πολλαπλών και ακαθόριστων μερών',
    },
  },
  {
    value: '839',
    label: {
      en: '930 Foreign body on external eye',
      gr: 'Ξένο σώμα στο εξωτερικό του ματιού',
    },
  },
  {
    value: '840',
    label: { en: '931 Foreign body in ear', gr: 'Ξένο σώμα στο αυτί' },
  },
  {
    value: '841',
    label: { en: '932 Foreign body in nose', gr: 'Ξένο σώμα στη μύτη' },
  },
  {
    value: '842',
    label: {
      en: '933 Foreign body in pharynx and larynx',
      gr: 'Ξένο σώμα στον φάρυγγα και λάρυγγα',
    },
  },
  {
    value: '843',
    label: {
      en: '934 Foreign body in trachea bronchus and lung',
      gr: 'Ξένο σώμα στην τραχεία τους βρόγχους και τον πνεύμονα',
    },
  },
  {
    value: '844',
    label: {
      en: '935 Foreign body in mouth oesophagus and stomach',
      gr: 'Ξένο σώμα στο στόμα οισοφάγο και στομάχι',
    },
  },
  {
    value: '845',
    label: {
      en: '936 Foreign body in intestine and colon',
      gr: 'Ξένο σώμα στα έντερα και στο κόλον',
    },
  },
  {
    value: '846',
    label: {
      en: '937 Foreign body in anus and rectum',
      gr: 'Ξένο σώμα στον πρωκτό και στον όρθο',
    },
  },
  {
    value: '847',
    label: {
      en: '938 Foreign body in digestive system unspecified',
      gr: 'Ξένο σώμα στο πεπτικό σύστημα ακαθόριστο',
    },
  },
  {
    value: '848',
    label: {
      en: '939 Foreign body in genitourinary tract',
      gr: 'Ξένο σώμα στην γεννητικό-ουροποιητική περιοχή',
    },
  },
  {
    value: '849',
    label: {
      en: '940 Burn confined to eye and adnexa',
      gr: 'Εγκαύματα στα μάτια και εξαρτήματα τους',
    },
  },
  {
    value: '850',
    label: {
      en: '941 Burn of face head and neck',
      gr: 'Εγκαύματα στο πρόσωπο κεφάλι και λαιμό',
    },
  },
  {
    value: '851',
    label: { en: '942 Burn of trunk', gr: 'Εγκαύματα στον κορμό' },
  },
  {
    value: '852',
    label: {
      en: '943 Burn of upper limb except wrist and hand',
      gr: 'Εγκαύματα σε άνω άκρο εκτός καρπού και χεριού',
    },
  },
  {
    value: '853',
    label: {
      en: '944 Burn of wrist(s) and hand(s)',
      gr: 'Εγκαύματα στον καρπό και στο χέρι',
    },
  },
  {
    value: '854',
    label: { en: '945 Burn of lower limb(s)', gr: 'Εγκαύματα στα κάτω άκρα' },
  },
  {
    value: '855',
    label: {
      en: '946 Burns of multiple specified sites',
      gr: 'Εγκαύματα πολλαπλών καθορισμένων μερών',
    },
  },
  {
    value: '856',
    label: {
      en: '947 Burn of internal organs',
      gr: 'Εγκαύματα στα εσωτερικά όργανα',
    },
  },
  {
    value: '857',
    label: {
      en: '948 Burns classified according to extent of body surface involved',
      gr:
        'Εγκαύματα ταξινομημένα με βάση την έκταση επιφάνειας του σώματος που συμμετάχει',
    },
  },
  {
    value: '858',
    label: { en: '949 Burn unspecified', gr: 'Εγκαύματα απροσδιόριστα' },
  },
  {
    value: '859',
    label: {
      en: '950 Injury to optic nerve and pathways',
      gr: 'Τραυματισμός στα οπτικά νεύρα και τις διόδους των',
    },
  },
  {
    value: '860',
    label: {
      en: '951 Injury to other cranial nerve(s)',
      gr: 'Τραυματισμός σε άλλα κρανιακά νεύρα',
    },
  },
  {
    value: '861',
    label: {
      en: '952 Spinal cord lesion without evidence of spinal bone injury',
      gr:
        'Βλαβη στον νωτιαίο μυελό χωρίς ενδείξεις τραυματισμού σε σπονδυλικό οστό',
    },
  },
  {
    value: '862',
    label: {
      en: '953 Injury to nerve roots and spinal plexus',
      gr: 'Τραυματισμός σε ρίζες νεύρων και δίχτυο σπονδυλικών νεύρων',
    },
  },
  {
    value: '863',
    label: {
      en:
        '954 Injury to other nerve(s) of trunk excluding shoulder and pelvic girdles',
      gr:
        'Τραυματισμός σε άλλα νεύρα του κορμού εκτός του ώμου και τις ζώνες της λεκάνης',
    },
  },
  {
    value: '864',
    label: {
      en: '955 Injury to peripheral nerve(s) of shoulder girdle and upper limb',
      gr:
        'Τραυματισμός στα περιφερειακά νεύρα της ζώνης του ώμου και άνω άκρων',
    },
  },
  {
    value: '865',
    label: {
      en: '956 Injury to peripheral nerve(s) of pelvis girdle and lower limb',
      gr:
        'Τραυματισμός στα περιφερειακά νεύρα της ζώνης της λεκάνης και κάτω άκρων',
    },
  },
  {
    value: '866',
    label: {
      en: '957 Injury to other and unspecified nerves',
      gr: 'Τραυματισμός σε άλλα και ακαθόριστα νεύρα',
    },
  },
  {
    value: '867',
    label: {
      en: '958 Certain early complications of trauma',
      gr: 'Κάποιες πρώιμες επιπλοκές ενός τραύματος',
    },
  },
  {
    value: '868',
    label: {
      en: '959 Injury other and unspecified',
      gr: 'Τραυματισμός άλλος και ακαθόριστος',
    },
  },
  {
    value: '869',
    label: {
      en: '960 Poisoning by antibiotics',
      gr: 'Δηλητηρίαση από αντιβιοτικά',
    },
  },
  {
    value: '870',
    label: {
      en: '961 Poisoning by other anti-infectives',
      gr: 'Δηλητηρίαση από άλλα αντιμολυσματικά',
    },
  },
  {
    value: '871',
    label: {
      en: '962 Poisoning by hormones and synthetic substitutes',
      gr: 'Δηλητηρίαση από ορμόνες και συνθετικά υποκατάστατα',
    },
  },
  {
    value: '872',
    label: {
      en: '963 Poisoning by primarily systemic agents',
      gr: 'Δηλητηρίαση κυρίως από μικροβιοκτόνους παράγοντες',
    },
  },
  {
    value: '873',
    label: {
      en: '964 Poisoning by agents primarily affecting blood constituents',
      gr:
        'Δηλητηρίαση από παράγοντες που κυρίως επηρεάζουν τα συστατικά του αίματος',
    },
  },
  {
    value: '874',
    label: {
      en: '965 Poisoning by analgesics antipyretics and antirheumatics',
      gr: 'Δηλητηρίαση από αναλγητικά αντιπυρετικά και αντιρρευματικά',
    },
  },
  {
    value: '875',
    label: {
      en: '966 Poisoning by anticonvulsants and anti-Parkinsonism drugs',
      gr: 'Δηλητηρίαση από φάρμακα αντιπαροξυστικά και αντί-Parkisonism',
    },
  },
  {
    value: '876',
    label: {
      en: '967 Poisoning by sedatives and hypnotics',
      gr: 'Δηλητηρίαση από ηρεμιστικά και υπνωτικά',
    },
  },
  {
    value: '877',
    label: {
      en: '968 Poisoning by other central nervous system depressants',
      gr: 'Δηλητηρίαση από άλλα κατευναστικά του κεντρικού νευρικού συστήματος',
    },
  },
  {
    value: '878',
    label: {
      en: '969 Poisoning by psychotropic agents',
      gr: 'Δηλητηρίαση από ψυχοτροπικούς παράγοντες',
    },
  },
  {
    value: '879',
    label: {
      en: '970 Poisoning by central nervous system stimulants',
      gr: 'Δηλητηρίαση από διεγερτικά του κεντρικού νευρικού συστήματος',
    },
  },
  {
    value: '880',
    label: {
      en:
        '971 Poisoning by drugs primarily affecting the autonomic nervous system',
      gr:
        'Δηλητηρίαση από φάρμακα που κυρίως επηρεάζουν την αυτονομία του νευρικού συστήματος',
    },
  },
  {
    value: '881',
    label: {
      en:
        '972 Poisoning by agents primarily affecting the cardiovascular system',
      gr:
        'Δηλητηρίαση από παράγοντες που κυρίως επηρεάζουν το καρδιαγγειακό σύστημα',
    },
  },
  {
    value: '882',
    label: {
      en:
        '973 Poisoning by agents primarily affecting the gastrointestinal system',
      gr:
        'Δηλητηρίαση από παράγοντες που κυρίως επηρεάζουν το γαστρεντερικό σύστημα',
    },
  },
  {
    value: '883',
    label: {
      en: '974 Poisoning by water mineral and uric acid metabolism drugs',
      gr:
        'Δηλητηρίαση από νερό ορυκτά και φάρμακα μεταβολισμού του ουρικού οξέως',
    },
  },
  {
    value: '884',
    label: {
      en:
        '975 Poisoning by agents primarily acting on the smooth and skeletal muscles and respiratory system',
      gr:
        'Δηλητηρίαση από παράγοντες που κυρίως δρουν στο στόμα και στους σκελετικούς μυώνες και στο αναπνευστικό σύστημα',
    },
  },
  {
    value: '885',
    label: {
      en:
        '976 Poisoning by agents primarily affecting skin and mucous membrane ophthalmologic otorhinolaryngological and dental drugs',
      gr:
        'Δηλητηρίαση από παράγοντες που κυρίως επηρεάζουν το δέρμα και βλεννώδεις μεμβράνες οφθαλμολογικά οτορινολαρυγγολογικά και οδοντικά φάρμακα',
    },
  },
  {
    value: '886',
    label: {
      en: '977 Poisoning by other and unspecified drugs and medicaments',
      gr: 'Δηλητηρίαση από άλλα και ακαθόριστα φάρμακα και φαρμακευτικά υλικά',
    },
  },
  {
    value: '887',
    label: {
      en: '978 Poisoning by bacterial vaccines',
      gr: 'Δηλητηρίαση από βακτηριακά εμβόλια',
    },
  },
  {
    value: '888',
    label: {
      en: '979 Poisoning by other vaccines and biological substances',
      gr: 'Δηλητηρίαση από άλλα εμβόλια και βιολογικές ουσίες',
    },
  },
  {
    value: '889',
    label: {
      en: '980 Toxic effect of alcohol',
      gr: 'Τοξικές επιδράσεις από αλκοόλ',
    },
  },
  {
    value: '890',
    label: {
      en: '981 Toxic effect of petroleum products',
      gr: 'Τοξικές επιδράσεις από πετρελαιοειδή προϊόντα',
    },
  },
  {
    value: '891',
    label: {
      en: '982 Toxic effect of solvents other than petroleum-based',
      gr:
        'Τοξικές επιδράσεις από διαλυτικές ουσίες άλλες από αυτές που βασίζονται στο πετρέλαιο',
    },
  },
  {
    value: '892',
    label: {
      en: '983 Toxic effect of corrosive aromatics acids and caustic alkalis',
      gr:
        'Τοξικές επιδράσεις από διαβρωτικά αρρωματικά οξέα και καυστικά αλκαλικά',
    },
  },
  {
    value: '893',
    label: {
      en: '984 Toxic effect of lead and its compounds (including fumes)',
      gr:
        'Τοξικές επιδράσεις από μόλυβδο και τα σχετιζόμενα στοιχεία (περιλαμβανομένων και αναθυμιάσεων)',
    },
  },
  {
    value: '894',
    label: {
      en: '985 Toxic effect of other metals',
      gr: 'Τοξικές επιδράσεις από αλλα μέταλλα',
    },
  },
  {
    value: '895',
    label: {
      en: '986 Toxic effect of carbon monoxide',
      gr: 'Τοξικές επιδράσεις από μονοξείδιο του άνθρακα',
    },
  },
  {
    value: '896',
    label: {
      en: '987 Toxic effect of other gases fumes or vapours',
      gr: 'Τοξικές επιδράσεις από αλλα αέρια αναθυμιάσεις ή ατμούς',
    },
  },
  {
    value: '897',
    label: {
      en: '988 Toxic effect of noxious substances eaten as food',
      gr: 'Τοξικές επιδράσεις από επιβλαβείς ουσίες που φαγώθηκαν ως τροφή',
    },
  },
  {
    value: '898',
    label: {
      en:
        '989 Toxic effect of other substances chiefly nonmedicinal as to source',
      gr:
        'Τοξικές επιδράσεις από άλλες ουσίες κυρίως μη φαρμακευτικές ως προς την προέλευση',
    },
  },
  {
    value: '899',
    label: {
      en: '990 Effects of radiation unspecified',
      gr: 'Επιδράσεις από ραδιενέργεια απροσδιόριστη',
    },
  },
  {
    value: '900',
    label: {
      en: '991 Effects of reduced temperature',
      gr: 'Επιδράσεις από μειωμένη θερμοκρασία',
    },
  },
  {
    value: '901',
    label: {
      en: '992 Effects of heat and light',
      gr: 'Επιδράσεις από ζέστη και φως',
    },
  },
  {
    value: '902',
    label: {
      en: '993 Effects of air pressure',
      gr: 'Επιδράσεις από πίεση του αέρα',
    },
  },
  {
    value: '903',
    label: {
      en: '994 Effects of other external causes',
      gr: 'Επιδράσεις από άλλες εξωτερικές αιτίες',
    },
  },
  {
    value: '904',
    label: {
      en: '995 Certain adverse effects not elsewhere classified',
      gr: 'Κάποιες εχθρικές επιδράσεις που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '905',
    label: {
      en: '996 Complications peculiar to certain specified procedures',
      gr: 'Επιπλοκές ασυνήθιστες σε κάποιες συγκεκριμένες διαδικασίες',
    },
  },
  {
    value: '906',
    label: {
      en:
        '997 Complications affecting specified body systems not elsewhere classified',
      gr:
        'Επιπλοκές που επηρεάζουν συγκεκριμένα συστήματα του σώματος. που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '907',
    label: {
      en: '998 Other complications of procedures not elsewhere classified',
      gr: 'Άλλες επιπλοκές διαδικασιών που δεν ταξινομήθηκαν αλλού',
    },
  },
  {
    value: '908',
    label: {
      en: '999 Complications of medical care not elsewhere classified',
      gr: 'Επιπλοκές ιατρικής φροντίδας που δεν ταξινομήθηκαν αλλού',
    },
  },
];
