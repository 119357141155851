import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { patchStorage } from '../helpers/storeService';
import useRouting from '../hooks/useRouting';
import { ActionButton } from 'wg-fe-ui';
import i18n from '../i18n';
import * as Yup from 'yup';
import LoadingActionButton from '../components/LoadingActionButton';
import FlowChatBox from '../components/FlowChatBox';
import { building_types } from '../constants/BuildingTypesData';
import useForm from '../hooks/useForm';
import SearchSelectInputV3 from '../components/SearchSelectInputV3';

const FlowHomeBuildingType = () => {
  const [loading, setLoading] = useState(false);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);

  const validationSchema = Yup.object().shape({
    house_type: Yup.string().required(),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Great location! What <span>type of building</span> is it?'
      ),
      slug: 'home-information',
    };
    addQuestion(question);
  }, []);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const submitHandler = () => {
    setLoading(true);

    const answer = {
      message: i18n.t(
        `I live in a <span>${
          building_types?.find((item) => item?.value === values.house_type)
            ?.label
        }</span>`
      ),
      slug: 'house-type',
    };
    addAnswer(answer);

    const payload = {
      house_type: values.house_type,
    };

    patchStorage({
      payload: payload,
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { property: currentProperty });
    setLoading(false);
  };

  return (
    <SplitContainer>
      <FlowChatBox />
      <RightSplit>
        <form onSubmit={handleForm}>
          <div>
            <SelectInput
              isClearable
              name="house_type"
              error={errors.house_type}
              value={handleSelectValue(building_types, 'house_type')}
              onSelected={({ value }) => {
                handleChange({
                  name: 'house_type',
                  value: value,
                });
              }}
              options={building_types}
              placeholder="Choose an option"
            >
              {i18n.t('Select the building type')}
            </SelectInput>
          </div>
          <ButtonContainer>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Submit')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const SelectInput = styled(SearchSelectInputV3)`
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;

  & button {
    height: 4rem;
    min-width: 12rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const RightSplit = styled.div`
  display: flex;
  padding-left: 4rem;
  position: relative;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    > div:first-child {
      height: 100%;
      overflow: scroll;
    }
  }
`;

const SplitContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowHomeBuildingType;
