/* eslint-disable react/jsx-key */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { getThemeObject } from '../helpers/themingService.js';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon.jsx';
import HelCatGanIcon from '../assets/icons/HelCatGanIcon';
import CarCatGanIcon from '../assets/icons/CarCatGanIcon.jsx';
import MobileFlowRoomInput from '../components/MobileFlowRoomInput';

const FlowSelectInsurancesMobile = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [error, setError] = useState();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [insurances, setInsurances] = useState();

  useEffect(() => {
    const question = {
      message: i18n.t('What would you like to insure?'),
      slug: 'select-insurances',
    };
    addQuestion(question);
    setInsuranceTypes(insuranceType);
  }, []);

  const setInsuranceTypes = (insuranceType) => {
    switch (insuranceType) {
      case 'carMobile':
        setInsurances({ Car: 0 });
        break;
      case 'homeMobile':
        setInsurances({ Home: 0 });
        break;
      case 'healthMobile':
        setInsurances({ Health: 0 });
        break;
      case 'allMobile':
        setInsurances({ Home: 0, Car: 0, Health: 0 });
        break;
      default:
        setInsurances(data.insurances);
        break;
    }
  };

  function handleChange(e, type) {
    if (e !== type[1] && e !== insurances[type[0]]) {
      setInsurances({
        ...insurances,
        [type[0]]: e,
      });
    }
  }

  function handleSubmit(insurances) {
    if (
      insurances?.Car === 0 &&
      insurances?.Home === 0 &&
      insurances?.Health === 0
    ) {
      return setError('Please select an insurance');
    }

    setError();

    patchStorage({
      payload: {
        insurances: insurances,
        ...(insurances?.Car > 0 ? { cars: new Array(insurances?.Car) } : {}),
        ...(insurances?.Home > 0
          ? { risk_addresses: new Array(insurances?.Home) }
          : {}),
        ...(insurances?.Health > 0
          ? { healths: new Array(insurances?.Health) }
          : {}),
      },
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: i18n.t(
        `I would like to insure ${
          insurances?.Car === 1
            ? '<span>a car</span>,'
            : insurances?.Car > 1
            ? '<span>' + insurances?.Car + ' cars</span>,'
            : ''
        }${
          insurances?.Home === 1 && insurances?.Car >= 1
            ? ' and <span>a property</span>'
            : insurances?.Home > 1 && insurances?.Car >= 1
            ? ' and <span>' + insurances?.Home + ' properties</span>'
            : insurances?.Home === 1
            ? '<span>a property</span>'
            : insurances?.Home > 1
            ? '<span>' + insurances?.Home + ' properties</span>'
            : ''
        }${
          insurances?.Health === 1 &&
          (insurances?.Home >= 1 || insurances?.Car >= 1)
            ? ' and <span>a health</span>'
            : insurances?.Health > 1 &&
              (insurances?.Home >= 1 || insurances?.Car >= 1)
            ? ' and <span>' + insurances?.Health + ' health</span>'
            : insurances?.Health === 1
            ? '<span>a health</span>'
            : insurances?.Health > 1
            ? '<span>' + insurances?.Health + ' health</span>'
            : ''
        }`
      ),
      slug: 'building_information',
    };

    addAnswer(answer);

    if (insurances.Home > 0) {
      renderNextRoute(1, { property: 0 });
    } else if (insurances.Car > 0) {
      renderNextRoute(2, { vehicle: 0 });
    } else if (insurances.Health > 0) {
      renderNextRoute(3, { health: 0 });
    }
  }

  function getInsuranceIcon(type) {
    if (type === 'Home') {
      return <HomeCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
    if (type === 'Car') {
      return <CarCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
    if (type === 'Health') {
      return <HelCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <div className="insurances">
          {insurances &&
            Object.entries(insurances).map((insuranceType, index) => {
              return (
                <StyledMobileFlowRoomInput
                  key={insuranceType[0]}
                  value={insuranceType[1]}
                  icon={getInsuranceIcon(insuranceType[0])}
                  initialValue={insuranceType[1]}
                  selected={insuranceType[1] > 0}
                  label={insuranceType[0]}
                  maxValue={10}
                  minValue={0}
                  onInputChange={(e) => handleChange(e, insuranceType)}
                />
              );
            })}
        </div>
        {error && <Error>{error}</Error>}
        <ButtonContainer>
          <ActionButton onClick={() => handleSubmit(insurances)}>
            {i18n.t('Submit')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  font-size: 1.2rem;
  color: #f74040;
  margin-bottom: 2rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 2rem;
`;

const StyledMobileFlowRoomInput = styled(MobileFlowRoomInput)`
  margin-bottom: 2rem;

  :last-of-type {
    margin-bottom: 0;
  }

  circle,
  line,
  path,
  polyline,
  rect {
    stroke: ${({ selected }) => (selected ? 'default' : '#CFCECE !important')};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: hidden;

  div.insurances {
    overflow-y: scroll;
    margin-bottom: 3rem;
  }
`;

export default FlowSelectInsurancesMobile;
