import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import LoadingDriveAssistIcons from './LoadingDriveAssistIcons';
import Checkmark from '../assets/images/checkmark.jsx';

const CheckBoxBorderedBlockWithIcon = ({
  question,
  colors,
  initial,
  onChange = () => {},
}) => {
  const [isSelected, setIsSelected] = useState(initial);

  useEffect(() => {
    onChange({ type: question.type, value: isSelected });
  }, [isSelected]);

  function handleCheckboxClick(e) {
    e.preventDefault();
    setIsSelected(!isSelected);
  }

  return (
    <StyledLabelCheckbox onClick={handleCheckboxClick}>
      <BoundingBox>
        <StyledLoadingDriveAssistIcons
          name={question.type}
          question={question}
          colors={colors}
        />
      </BoundingBox>
      <span
        dangerouslySetInnerHTML={{
          __html: i18n.t(question.value),
        }}
      />
      <StandardInputCheckbox
        onClick={(e) => handleCheckboxClick(e)}
        checked={isSelected}
        type="checkbox"
      />
      <CheckboxItem>
        {isSelected ? <Checkmark alt="checkmark" /> : ''}
      </CheckboxItem>
    </StyledLabelCheckbox>
  );
};

const BoundingBox = styled.div`
  width: 4rem;
  height: 3.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingDriveAssistIcons = styled(LoadingDriveAssistIcons)`
  fill: ${({ theme }) => theme.brand.primary};
  width: 100%;
  height: 100%;
`;

const StandardInputCheckbox = styled.input`
  border-radius: 0.3rem;
  opacity: 0;
  margin-left: 1.5rem;
  margin-right: -1.6rem;
  cursor: pointer;
`;

const StyledLabelCheckbox = styled.label`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;
  line-height: 1.5;
  background-color: ${(props) => (!props.disabled ? 'white' : 'none')};
  flex: 0 0 50%;
  color: #aeaeae;
  padding: 0.65rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  align-items: center;
  margin-bottom: 0.75rem;
  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: ${({ theme }) => theme.brand.primary};
    font-weight: bold;
  }
  & > span {
    font-size: 1.4rem;
    width: 90%;
    transition: color 0.1s ease;
    color: #aeaeae;

    & > span {
      font-weight: bold;
      color: ${(props) => (props.disabled ? '#aeaeae' : 'black')};
    }
  }
`;

const CheckboxItem = styled.div`
  width: 2rem;
  height: 2rem;
  margin-top: 0.3rem;
  background-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : 'none'};
  transition: all 0.1s ease;
  flex-grow: 0;
  flex-shrink: 0;
  border: 0.2rem solid #d3d4d8;
  border-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : '#D3D4D8'};
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export default CheckBoxBorderedBlockWithIcon;
