// Fold constants to see all constants

export const countries = [
  {
    value: 'f6e01ef8-de76-459c-b8bb-93e16e346575',
    label: 'Afghanistan',
  },
  {
    value: '5efca410-e844-4a15-8ed6-d7b4d8f08e5c',
    label: 'Albania',
  },
  {
    value: 'c193ca1d-3384-4f0c-8f6c-2cafc963ef56',
    label: 'Algeria',
  },
  {
    value: 'cda9b8cb-634c-40b7-9c79-56b3dca69ab4',
    label: 'American Samoa',
  },
  {
    value: 'ffa9328e-e4a4-47ed-a966-22bf51eac8c1',
    label: 'Andorra',
  },
  {
    value: '30a933fa-e5eb-4487-a4c0-ff3a95a0e722',
    label: 'Angola',
  },
  {
    value: 'e2ee214b-a77c-41a6-b255-6da3cd4c3ba0',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'd8da4e7b-8b7d-44fd-908b-ba08c36cf48a',
    label: 'Argentina',
  },
  {
    value: '7504a80d-4bc0-493e-86a6-232e486adcaf',
    label: 'Armenia',
  },
  {
    value: 'eb58718d-cf3c-40e5-99d8-41cce6f7e0ac',
    label: 'Australia',
  },
  {
    value: '6b235980-b47b-4286-8af8-69f747df6a8e',
    label: 'Austria',
  },
  {
    value: '295cafc9-d305-4fa9-8063-5cf8c0a662dd',
    label: 'Azerbaijan',
  },
  {
    value: '38db11c1-53e7-4353-af49-81be63056cee',
    label: 'Bahamas',
  },
  {
    value: 'e3d2efcd-2c94-4f55-883e-9dc31b8a35bf',
    label: 'Bahrain',
  },
  {
    value: '284c24fb-3b9a-46fe-b4f3-ded060510902',
    label: 'Bangladesh',
  },
  {
    value: '7791f6ab-d763-4306-bf40-e1016888c8ec',
    label: 'Barbados',
  },
  {
    value: '23346e66-0b1c-4cdd-9f2b-2b5c30d616ca',
    label: 'Belarus',
  },
  {
    value: '21008ee6-6a12-4ec9-80cb-a83ec495c23c',
    label: 'Belgium',
  },
  {
    value: 'ef3b76b8-0966-4d3f-a9e7-f29eb1688e0e',
    label: 'Belize',
  },
  {
    value: 'ebaea64f-50a2-44f7-aeb4-8a77e2fe2295',
    label: 'Benin',
  },
  {
    value: '1190ec36-1096-4b35-b69b-475602cdb61b',
    label: 'Bhutan',
  },
  {
    value: '7e99c7e4-efc1-40e3-a2b3-ec4504f2ba25',
    label: 'Bolivia',
  },
  {
    value: '8e29f956-0533-4596-9cbe-7de34f14afda',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 'b924fc92-d2aa-4e9e-b364-cf44aee3d07b',
    label: 'Botswana',
  },
  {
    value: 'aa038808-52b1-49f1-891b-03431b31445c',
    label: 'Brazil',
  },
  {
    value: '30e1188e-137f-455e-981d-38d1d8c9f6bf',
    label: 'British Indian Ocean Territory',
  },
  {
    value: '10743e1d-92c7-4eda-ad6a-fad39ad615f8',
    label: 'Brunei Darussalam',
  },
  {
    value: '0548cbbf-e7e5-40f2-8f58-a695bfe630e5',
    label: 'Bulgaria',
  },
  {
    value: 'ea0f5fb3-c1ec-41dc-9237-967a4ebe888f',
    label: 'Burundi',
  },
  {
    value: '316e72c8-6bbc-46e0-b9b6-add35ae3dfcd',
    label: 'Cambodia',
  },
  {
    value: '6819387e-91e1-453c-b294-5a356b0d83f9',
    label: 'Cameroon',
  },
  {
    value: '11ab451f-8ecc-4cf5-a2c2-d381ebfaaa52',
    label: 'Canada',
  },
  {
    value: 'd9a6e01c-5a88-4958-9c72-9a18697f283e',
    label: 'Cape Verde',
  },
  {
    value: '4db156a2-83e5-4a60-9fc0-a18abc767d02',
    label: 'Central African Republic',
  },
  {
    value: '5ed39cb5-a893-495f-8120-2587aedafa05',
    label: 'Chad',
  },
  {
    value: 'a35d726c-d261-4bb7-84a3-e3c7446b00d1',
    label: 'Chile',
  },
  {
    value: '776e24ff-3ca6-4be6-9735-0101c77fb910',
    label: 'China',
  },
  {
    value: '27c211df-a69a-4f6f-a811-59bbf139bcb8',
    label: 'Colombia',
  },
  {
    value: 'bd2b8622-23ec-424f-b6cb-b5d337e0c1e0',
    label: 'Comoros',
  },
  {
    value: 'e7dcb0a5-3086-4555-88a4-f1215a8bdd51',
    label: 'Congo',
  },
  {
    value: 'f95e3c32-8726-4ed5-a799-692b4f77fdd6',
    label: 'Congo. Democratic Republic of the',
  },
  {
    value: '3ae5a660-5ab7-4733-a25a-81d7229644c1',
    label: 'Cook Islands',
  },
  {
    value: '9ccaab8a-b6ee-460c-8f09-314f2dcbf74b',
    label: 'Costa Rica',
  },
  {
    value: '40fb272b-495b-43c7-a899-a7d2677560c2',
    label: 'Croatia',
  },
  {
    value: '9777ab37-dd11-463b-8e4c-772c137b50cd',
    label: 'Cuba',
  },
  {
    value: '6e0e5110-ebbf-4a71-abfc-491a2778e24f',
    label: 'Cyprus',
  },
  {
    value: 'c1790a78-6729-40aa-95f5-e632da65ba46',
    label: 'Czech Republic',
  },
  {
    value: '351c67ce-d2d0-4bd6-9c99-12fb2402ead3',
    label: 'Denmark',
  },
  {
    value: 'c525d122-6dd0-4b00-9248-833d111fc631',
    label: 'Djibouti',
  },
  {
    value: '49adde35-311d-43f5-a351-436dd6e0544e',
    label: 'Dominica',
  },
  {
    value: '2ec40e7b-8895-4cea-be43-fda31543af97',
    label: 'Dominican Republic',
  },
  {
    value: 'a6842804-a2d8-4a62-a3ff-ca19e21cc76b',
    label: 'Ecuador',
  },
  {
    value: 'aa99448a-1e30-4d37-a6fc-04f345b3e6aa',
    label: 'Egypt',
  },
  {
    value: 'e398effe-9cd4-4e15-b919-7faba9644f16',
    label: 'El Salvador',
  },
  {
    value: 'df3b09a0-10bc-42fd-a601-f22441c7ea2c',
    label: 'Equatorial Guinea',
  },
  {
    value: '7c998756-e1bf-45f2-ba45-775e4ee09141',
    label: 'Eritrea',
  },
  {
    value: 'b97847b0-91e6-4df2-a52c-aada0a118a6d',
    label: 'Estonia',
  },
  {
    value: '60760c0c-1152-457c-b314-99647e4143d2',
    label: 'Ethiopia',
  },
  {
    value: 'aeac12f2-6aec-419b-ac50-21c3d991f27c',
    label: 'Fiji',
  },
  {
    value: '50a14d6d-ed94-41ba-a454-7bc49b283e5b',
    label: 'Finland',
  },
  {
    value: '53cea682-4931-40e4-8e13-4c2e70e5c0ad',
    label: 'France',
  },
  {
    value: 'bee74310-a7de-452a-927b-bfbfc36a51b9',
    label: 'Gabon',
  },
  {
    value: 'aff3f746-d8a1-4b21-999c-6a0bf306cb8f',
    label: 'Gambia',
  },
  {
    value: 'd648df78-e626-4d20-9b18-dfd2d757809d',
    label: 'Georgia',
  },
  {
    value: '64906f01-299c-4e5f-b85f-df350a11bb2a',
    label: 'Germany',
  },
  {
    value: '7dcbd789-57be-4ab5-9e2d-8cb8e15b0607',
    label: 'Ghana',
  },
  {
    value: '82c4c44e-3024-4bb8-997b-75ee2369f2a2',
    label: 'Greece',
  },
  {
    value: '70267d82-6a8e-4929-9ab3-d076fbf76f0d',
    label: 'Greenland',
  },
  {
    value: '25560e3c-b970-4ba5-8880-76c49a779620',
    label: 'Grenada',
  },
  {
    value: '3ef8d0e3-d3b2-4301-b6bf-abb85f92565d',
    label: 'Guatemala',
  },
  {
    value: '118e9ad4-f580-4800-a38b-c94e7465061f',
    label: 'Guinea',
  },
  {
    value: 'e4c4d270-d53c-44cb-9bc4-a7fd79e4dca7',
    label: 'Guinea-Bissau',
  },
  {
    value: '989e06cf-fadb-4c2e-8862-8dca7fcea68b',
    label: 'Guyana',
  },
  {
    value: 'c01caf3d-7695-4dd6-83b7-68db48d6d2db',
    label: 'Haiti',
  },
  {
    value: '1a727657-0b53-4fca-85a7-1a9146a7cb65',
    label: 'Honduras',
  },
  {
    value: '3add8025-8bd7-4949-a674-869ce37bc2fe',
    label: 'Hong Kong',
  },
  {
    value: 'ebad016f-f1eb-40e9-9cd1-837a97ca1130',
    label: 'Hungary',
  },
  {
    value: '0c4f629c-f503-4cf3-8097-80f833d1adbf',
    label: 'Iceland',
  },
  {
    value: '264ea4ca-cc47-4e18-94f5-76d0c3632833',
    label: 'India',
  },
  {
    value: 'bab359c6-870e-4273-a04d-325131d27b8e',
    label: 'Indonesia',
  },
  {
    value: 'cc1d53d4-d089-48aa-842a-df93a089d5fb',
    label: 'Iran',
  },
  {
    value: '5e0ef70d-ea18-4202-a2bb-e4bc2413924a',
    label: 'Iraq',
  },
  {
    value: '58ff0c57-0030-4b13-940b-4e4ecbf1e22c',
    label: 'Ireland',
  },
  {
    value: 'e947d06b-93af-4f3a-ba75-3a8bad47b877',
    label: 'Israel',
  },
  {
    value: '5fb4bc19-c84d-445d-940c-85f39e02c38c',
    label: 'Italy',
  },
  {
    value: '68aa5cc0-0a96-4124-a00c-dee0fb2b5987',
    label: 'Jamaica',
  },
  {
    value: 'e6418c31-4d20-496a-a902-dcf54d94d5a9',
    label: 'Japan',
  },
  {
    value: '37616a10-8b3d-4420-8351-58c095a31949',
    label: 'Jordan',
  },
  {
    value: '4ff2e624-cd83-4155-8b0f-bfaaf6f64039',
    label: 'Kazakhstan',
  },
  {
    value: '61bcb2f2-b0c7-4dbc-9b70-9792d7a4a90a',
    label: 'Kenya',
  },
  {
    value: 'f8b69d14-ad80-42f0-9bb8-6a70de8ff090',
    label: 'Kiribati',
  },
  {
    value: '9a6d7842-7b5c-4eed-b0cd-64aab7a2efb6',
    label: 'Kuwait',
  },
  {
    value: '04d33a7b-5a83-4e43-ba40-89a509747cc8',
    label: 'Kyrgyzstan',
  },
  {
    value: '7ffe6430-8796-4c39-ab7c-d4a2222180a2',
    label: 'Laos',
  },
  {
    value: 'd91af10a-d728-4bf9-b822-7959ff08fee8',
    label: 'Latvia',
  },
  {
    value: '6695063a-3848-4153-b751-6e88b3ba0cf1',
    label: 'Lebanon',
  },
  {
    value: '2bd3ea10-0808-4613-996e-7bb079043178',
    label: 'Lesotho',
  },
  {
    value: '6c8c77a3-eacd-4fdd-bc67-971780d2fac9',
    label: 'Liberia',
  },
  {
    value: 'f7b811bf-4651-434a-865e-24c971d4906d',
    label: 'Libya',
  },
  {
    value: '92df8ab4-b9bc-4099-b42e-bc365e3bdfff',
    label: 'Liechtenstein',
  },
  {
    value: 'e8cde488-bd3b-4b39-be86-46e762a03ef9',
    label: 'Lithuania',
  },
  {
    value: '39fd21fc-4138-4878-a4dc-16de52abd863',
    label: 'Luxembourg',
  },
  {
    value: '51062479-da19-4adc-9adf-eae4cdcc6039',
    label: 'Macedonia',
  },
  {
    value: '11c20738-612d-4350-8f2f-7c2612f1296f',
    label: 'Madagascar',
  },
  {
    value: 'c03c719a-9e53-4824-bba4-4821a4367ec2',
    label: 'Malawi',
  },
  {
    value: '4b45b999-d97d-4846-a074-9c853f31b84d',
    label: 'Malaysia',
  },
  {
    value: '0c992aac-8c96-4a4c-a78d-f32b6e91d2e4',
    label: 'Maldives',
  },
  {
    value: 'e7ff4ce8-2dc7-4aa8-939b-03afaa1da46a',
    label: 'Mali',
  },
  {
    value: '2a1f85da-e6c4-4555-8424-0600e06b8b28',
    label: 'Malta',
  },
  {
    value: 'e25052bb-af5f-4101-8ce3-b8a5630126ff',
    label: 'Marshall Islands',
  },
  {
    value: '7052c8f7-95e3-41df-a23a-e4ed6f9c2eb3',
    label: 'Mauritania',
  },
  {
    value: 'c2f495ec-71cf-4ed0-823d-5cda6fae1aa8',
    label: 'Mauritius',
  },
  {
    value: 'e8e90547-f6c5-4490-9018-f56233635af5',
    label: 'Mexico',
  },
  {
    value: '0fd14fe3-57d3-45c1-bb8c-deaf5112b579',
    label: 'Micronesia',
  },
  {
    value: '12d38631-2cc5-4408-9a34-9c28d12b297d',
    label: 'Moldova',
  },
  {
    value: 'f1b91b94-5459-4699-8c92-bc68506c9e95',
    label: 'Monaco',
  },
  {
    value: '7bb69f05-2a92-4ffc-beaf-08b4a91f65d9',
    label: 'Mongolia',
  },
  {
    value: '1c1cd9e0-5a32-41dc-854a-66aec3a91562',
    label: 'Morocco',
  },
  {
    value: 'd90704bc-f2f4-4376-9fb6-ab72ceb52376',
    label: 'Mozambique',
  },
  {
    value: '1363d97a-644a-40c5-acfc-336010928e74',
    label: 'Myanmar',
  },
  {
    value: '191521b8-4d70-477b-b803-f54bcf587252',
    label: 'Namibia',
  },
  {
    value: '4c6ad816-c7a0-4324-a33d-f480f71415c7',
    label: 'Nauru',
  },
  {
    value: '5cf24bf1-09e5-4553-b6e9-3475b8bfd246',
    label: 'Nepal',
  },
  {
    value: '492c28bf-4beb-4574-a7e2-4be3ab7c6843',
    label: 'Netherlands',
  },
  {
    value: 'aa9da5e1-b4b8-4257-809a-05e4b0a740ac',
    label: 'Netherlands Antilles',
  },
  {
    value: '59fba32c-6b7f-4418-86b9-bee1583bfdcf',
    label: 'New Zealand',
  },
  {
    value: 'dda4056a-bd35-47ed-9883-a4c49d62f40d',
    label: 'Nicaragua',
  },
  {
    value: '23146db2-d8af-45f3-9cd0-6d6ddb3d7b9e',
    label: 'Niger',
  },
  {
    value: 'b0414636-05a5-42ca-a642-ed395e677a91',
    label: 'Nigeria',
  },
  {
    value: '0c0f298d-7407-4e2e-8253-99c446dbfc4a',
    label: 'North Korea',
  },
  {
    value: '8bd89e1a-de23-44a3-bbac-ac7407d5a303',
    label: 'Norway',
  },
  {
    value: 'f8af7fd0-88fe-40c1-a711-7c4f69446b85',
    label: 'Oman',
  },
  {
    value: 'f0812001-d796-4625-ad34-8a77972fbaf2',
    label: 'Pakistan',
  },
  {
    value: '7df40fd2-e4a9-43bc-9426-b78d8b0f309c',
    label: 'Palau',
  },
  {
    value: 'a03f2d74-e71e-45a8-b407-4ac4fbda8ee8',
    label: 'Panama',
  },
  {
    value: '0d2cf97c-2cf4-4d7e-b11e-2099ea2f5583',
    label: 'Papua New Guinea',
  },
  {
    value: '0b7d14bb-82c7-405f-b5f8-06d4826c2c86',
    label: 'Paraguay',
  },
  {
    value: '33b59dd7-a1e1-43cc-b421-3e419058f23a',
    label: 'Peru',
  },
  {
    value: '54751181-c312-45fa-9699-60e73c4c7e64',
    label: 'Philippines',
  },
  {
    value: 'e28a130f-bf16-4706-a24d-288218e8d68e',
    label: 'Poland',
  },
  {
    value: '9e8abbe3-5233-45e0-a5d6-d87ee197f613',
    label: 'Portugal',
  },
  {
    value: 'a5a87232-942b-44ba-a9e7-f24d3662323c',
    label: 'Qatar',
  },
  {
    value: 'cde9e67b-210c-4e20-8d19-45bf84f9bd55',
    label: 'Romania',
  },
  {
    value: '7d569661-99c1-407f-b4f7-88daa972f2ca',
    label: 'Russian Federation',
  },
  {
    value: '9d227720-6cc6-4505-9184-07263f82cac7',
    label: 'Rwanda',
  },
  {
    value: '99a6d1c6-8efc-47b2-a5fe-fd399d7a9c4a',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'be47e6c9-6abc-4cb3-a01c-7d852a03809f',
    label: 'Saint Lucia',
  },
  {
    value: 'b4c2e73c-a74d-45a8-bdca-604a1850e2e6',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: '3c71f851-a723-40b1-8bea-625b1a82dfa6',
    label: 'Samoa',
  },
  {
    value: '1d79d833-7ffe-49d5-9c42-9af591b1bf15',
    label: 'San Marino',
  },
  {
    value: 'c63c8123-e655-449a-a1ad-aba8c6f7fd7d',
    label: 'Sao Tome and Principe',
  },
  {
    value: '3bc19712-cc8c-47e7-9dd7-2c8fa0be27fa',
    label: 'Saudi Arabia',
  },
  {
    value: '92e4093f-094c-4fea-a24a-50b55ff67f15',
    label: 'Senegal',
  },
  {
    value: '3e44b626-fdb8-4cf4-b2c4-000a3304ca0c',
    label: 'Serbia and Montenegro',
  },
  {
    value: '9662e440-221f-4502-9792-8c2d95c272fd',
    label: 'Seychelles',
  },
  {
    value: '8be9f46f-8a4c-4b6a-ae81-8c50dc93bbfd',
    label: 'Sierra Leone',
  },
  {
    value: 'f5bf3d75-14b8-4676-9478-cf6d7d3aa60d',
    label: 'Singapore',
  },
  {
    value: 'a1127c08-9180-4a5e-a628-dce576e6b76e',
    label: 'Slovakia',
  },
  {
    value: '8cc2c6ed-80de-4f8a-ae8e-a6964a1a838e',
    label: 'Slovenia',
  },
  {
    value: '638140e6-5d9b-4aee-8244-42c364802884',
    label: 'Solomon Islands',
  },
  {
    value: 'd0d9343e-53b0-49ad-9a70-13861433ad3c',
    label: 'Somalia',
  },
  {
    value: '68128c34-fad2-4a47-acf3-18e4ca2f32a9',
    label: 'South Africa',
  },
  {
    value: 'b2cf7694-21ab-484b-a515-8d4fc629eed5',
    label: 'South Korea',
  },
  {
    value: '67f7caab-1570-4f2d-b4c1-1deb5139b377',
    label: 'Spain',
  },
  {
    value: 'edc61ae3-1309-48bc-baa9-007d77a36648',
    label: 'Sri Lanka',
  },
  {
    value: '44213a0f-99e9-460d-8c4c-c863b2b680e0',
    label: 'Sudan',
  },
  {
    value: '088d5a7b-220d-47b1-8d20-fb7af22e6e28',
    label: 'Suriname',
  },
  {
    value: '1fddd39f-1669-459f-b861-cfeb56f23dab',
    label: 'Swaziland',
  },
  {
    value: '2614f9fc-01e2-4e13-b1f0-9f07b4513e57',
    label: 'Sweden',
  },
  {
    value: 'e0c634a9-e903-42bf-908c-1c52b70a0454',
    label: 'Switzerland',
  },
  {
    value: '4daff98d-064d-4117-bbfe-5d6b644823b5',
    label: 'Syrian Arab Republic',
  },
  {
    value: '58aa5b66-1c38-4888-a018-b51d03be3ee8',
    label: 'Tajikistan',
  },
  {
    value: 'e096dfb7-f338-4c9e-94ab-ee2ad596b62c',
    label: 'Tanzania',
  },
  {
    value: '3f305992-171a-4de2-895c-d7f609e4594a',
    label: 'Thailand',
  },
  {
    value: 'ad4004fe-dd92-4982-84ba-d50eb9638dde',
    label: 'Togo',
  },
  {
    value: '3874411c-acaf-4714-8889-6ffc68a731f2',
    label: 'Tonga',
  },
  {
    value: 'e7cbee33-df83-4e49-a855-49d2d3fd5cfc',
    label: 'Trinidad and Tobago',
  },
  {
    value: '888c90f1-c67d-4eae-a89c-79fd9cc638f2',
    label: 'Tunisia',
  },
  {
    value: 'b38b48de-6e18-41f3-84d7-8f46cef1e02e',
    label: 'Turkey',
  },
  {
    value: '88a6901c-84b5-4c47-9d0e-c5c06785d835',
    label: 'Turkmenistan',
  },
  {
    value: 'b7ba1e65-8fe5-4385-83dc-4a4235feeda2',
    label: 'Tuvalu',
  },
  {
    value: '9ea8c0a2-a2ad-4006-bfb1-01c068fcb81c',
    label: 'Uganda',
  },
  {
    value: '8d7b31a6-649c-4264-904a-38bbcb8b90ff',
    label: 'Ukraine',
  },
  {
    value: '12bbd23b-9fb2-4263-a3e9-7f1cc317e23b',
    label: 'United Arab Emirates',
  },
  {
    value: '06d173c6-7faf-4072-a47a-aeece1f447cb',
    label: 'United Kingdom',
  },
  {
    value: '6739b852-1d62-4d40-b3be-653f004dfaa6',
    label: 'United States',
  },
  {
    value: 'a32c4c07-7059-4a0e-8316-a09cb6212f24',
    label: 'Uruguay',
  },
  {
    value: '673d6b30-7050-4c1f-91b9-34429757f002',
    label: 'Uzbekistan',
  },
  {
    value: '6f3ebd19-b4bb-434c-ad18-a2bf9271d7b5',
    label: 'Venezuela',
  },
  {
    value: '9023eb97-b266-4131-9070-6728edea7f9e',
    label: 'Vietnam',
  },
  {
    value: '3840ecc4-bd9f-4500-92de-7eb0e5eb2164',
    label: 'Virgin Islands. U.S.',
  },
  {
    value: 'ed960802-9433-449b-934a-6c2285fd31d5',
    label: 'Yemen',
  },
  {
    value: '70f0f81d-2069-405c-854e-4b50bcfaafb0',
    label: 'Zambia',
  },
  {
    value: 'c585c06a-9c0e-403a-9d30-29edc5dac577',
    label: 'Zimbabwe',
  },
];

export const occupations = [
  {
    value: 3118,
    label: 'NONE',
    labelGreek: 'ΚΑΝΕΝΑ',
  },
  {
    value: 3119,
    label: 'ADVERTISING AGENT',
    labelGreek: 'ΠΡΑΚΤΟΡΑΣ ΔΙΑΦΗΜΙΣΕΩΝ',
  },
  {
    value: 3120,
    label: 'DOOR TO DOOR - FRANCHISE',
    labelGreek: 'ΑΠΟ ΠΟΡΤΑ ΣΕ ΠΟΡΤΑ - FRANCHISE',
  },
  {
    value: 3121,
    label: 'ANIMATOR',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΚΙΝΟΥΜΕΝΩΝ ΣΧΕΔΙΩΝ',
  },
  {
    value: 3122,
    label: 'EXHIBITION DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΕΚΘΕΣΕΩΝ',
  },
  {
    value: 3123,
    label: 'GRAPHIC DESIGNER',
    labelGreek: 'ΓΡΑΦΙΣΤΑΣ',
  },
  {
    value: 3124,
    label: 'WEB SITE DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΙΣΤΟΣΕΛΙΔΩΝ',
  },
  {
    value: 3125,
    label: 'MARKET RESEARCHER',
    labelGreek: 'ΕΡΕΥΝΗΤΗΣ ΑΓΟΡΑΣ',
  },
  {
    value: 3126,
    label: 'MARKETING-DIRECT',
    labelGreek: 'ΑΜΕΣΟ ΜΑΡΚΕΤΙΝΚ',
  },
  {
    value: 3127,
    label: 'WEB MARKETING',
    labelGreek: 'ΔΙΑΔYΚΤΙΑΚΟ ΜΑΡΚΕΤΙΝΚ',
  },
  {
    value: 3128,
    label: 'MARKETING AGENCY',
    labelGreek: 'ΠΡΑΚΤΟΡΑΣ ΜΑΡΚΕΤΙΝΓΚ',
  },
  {
    value: 3129,
    label: 'COPYWRITER',
    labelGreek: 'ΚΕΙΜΕΝΟΓΡΑΦΟΣ',
  },
  {
    value: 3130,
    label: 'PUBLIC RELATIONS OFFICER',
    labelGreek: 'ΥΠΑΛΛΗΛΟΣ ΔΗΜΟΣΙΩΝ ΣΧΕΣΕΩΝ',
  },
  {
    value: 3131,
    label: 'PUBLICITY MANAGEMENT',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΗ ΔΗΜΟΣΙΟΤΗΤΑΣ',
  },
  {
    value: 3132,
    label: 'TELEMARKETER / TELESALES',
    labelGreek: 'ΤΗΛΕΠΩΛΗΣΕΙΣ',
  },
  {
    value: 3133,
    label: 'AGRICULTURE (FELLING  SAWING & CARTING OF TREES)',
    labelGreek: 'ΓΕΩΡΓΙΑ (ΚΛΑΔΕΜΑ   ΚΟΠΗ ΔΕΝΔΡΩΝ)',
  },
  {
    value: 3134,
    label: 'AGRICULTURAL MACHINERY PROPRIETORS',
    labelGreek: 'ΙΔΙΟΚΤΗΤΕΣ ΑΓΡΟΤΙΚΩΝ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 3135,
    label: 'AGRICULTURISTS',
    labelGreek: 'ΓΕΩΠΟΝΟΙ',
  },
  {
    value: 3136,
    label: 'AGRICULTURAL CONTRACTORS (EXCLUDING CROP SPRAYING)',
    labelGreek:
      ' : ΕΡΓΟΛΑΒΟΙ ΣΤΟΝ ΑΓΡΟΤΙΚΟ ΤΟΜΕΑ ( ΕΚΤΟΣ ΨΕΚΑΣΜΟ ΚΑΛΛΙΕΡΓΕΙΩΝ )',
  },
  {
    value: 3137,
    label: 'ANIMAL BREEDERS & TRAINERS',
    labelGreek: 'ΚΤΗΝΟΤΡΟΦΟΙ & ΕΚΠΑΙΔΕΥΤΕΣ ΖΩΩΝ',
  },
  {
    value: 3138,
    label: 'BREEDING FARMS - HORSES',
    labelGreek: 'ΦΑΡΜΕΣ ΕΚΤΡΟΦΗΣ - ΑΛΟΓΑ',
  },
  {
    value: 3139,
    label: 'BREEDING FARMS - SMALL ANIMALS',
    labelGreek: 'ΕΚΤΡΟΦΕΙΑ - ΜΙΚΡΩΝ ΖΩΩΝ',
  },
  {
    value: 3140,
    label: 'BREEDING FARMS - RATITE BIRDS',
    labelGreek: 'ΕΚΤΡΟΦΕΙΑ - ΠΟΥΛΙΑ ΣΤΡΟΥΘΙΟΝΙΔΩΝ',
  },
  {
    value: 3141,
    label: 'DOG BREEDER',
    labelGreek: 'ΕΚΤΡΟΦΕΙΑ ΣΚΥΛΩΝ',
  },
  {
    value: 3142,
    label: 'HORSE TRAINERS',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΕΣ ΑΛΟΓΩΝ',
  },
  {
    value: 3143,
    label: 'ANIMAL HOSPITAL',
    labelGreek: 'ΝΟΣΟΚΟΜΕΙΟ ΖΩΩΝ',
  },
  {
    value: 3144,
    label: 'ANIMAL SHELTER',
    labelGreek: 'ΚΑΤΑΦΥΓΙΟ ΖΩΩΝ',
  },
  {
    value: 3145,
    label: 'DOG WALKER',
    labelGreek: 'ΣΥΝΟΔΟΣ ΣΚΥΛΩΝ',
  },
  {
    value: 3146,
    label: 'DOG CATCHER',
    labelGreek: 'ΜΠΟΓΙΑΣ',
  },
  {
    value: 3147,
    label: 'PET GROOMING',
    labelGreek: 'ΠΕΡΙΠΟΙΗΣΗ ΚΑΤΟΙΚΙΔΙΟΥ',
  },
  {
    value: 3148,
    label: 'PET MINDER',
    labelGreek: 'ΦΡΟΝΤΙΣΤΗΣ ΚΑΤΟΙΚΙΔΙΩΝ',
  },
  {
    value: 3149,
    label: 'ZOOKEEPER',
    labelGreek: 'ΦΥΛΑΚΑΣ ΖΟΩΛΟΓΙΚΟΥ ΚΗΠΟΥ',
  },
  {
    value: 3150,
    label: 'BOTANIST OR HORTICULTURIST',
    labelGreek: 'ΒΟΤΑΝΟΛΟΓΟΣ ΚΗΠΟΥΡΟΣ',
  },
  {
    value: 3151,
    label: 'ECOLOGIST',
    labelGreek: 'ΟΙΚΟΛΟΓΟΣ',
  },
  {
    value: 3152,
    label: 'FARMERS',
    labelGreek: 'ΑΓΡΟΤΕΣ',
  },
  {
    value: 3153,
    label: 'FARMERS  / FARM MACHINERY USING',
    labelGreek: 'ΑΓΡΟΤΕΣ  / ΧΕΙΡΙΣΤΗΣ ΑΓΡΟΤΙΚΩΝ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 3154,
    label: 'FARMERS / FARM WITHOUT THE USE OF MACHINERY',
    labelGreek: 'ΑΓΡΟΤΕΣ / ΕΚΜΕΤΑΛΛΕΥΣΗ ΧΩΡΙΣ ΤΗ ΧΡΗΣΗ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 3155,
    label: 'SHEPHERD',
    labelGreek: 'ΒΟΣΚΟΣ',
  },
  {
    value: 3156,
    label: 'FARM MACHINERY AND POWER EQUIPMENT DEALERS',
    labelGreek: 'ΑΓΡΟΤΙΚΑ ΜΗΧΑΝΗΜΑΤΑ ΚΑΙ ΕΞΟΠΛΙΣΜΟΣ',
  },
  {
    value: 3157,
    label: 'FARMING CHRISTMAS TREES',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΧΡΙΣΤΟΥΓΕΝΝΙΑΤΙΚΩΝ ΔΕΝΤΡΩΝ',
  },
  {
    value: 3158,
    label: 'FARMING CUSTOM HARVESTERS',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΜΕ ΘΕΡΙΣΤΙΚΕΣ ΜΗΧΑΝΕΣ',
  },
  {
    value: 3159,
    label: 'FARMING DAIRY',
    labelGreek: 'ΓΑΛΑΚΤΟΚΟΜΙΑ',
  },
  {
    value: 3160,
    label: 'FURMING MUSHROOMS',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΜΑΝΙΤΑΡΙΩΝ',
  },
  {
    value: 3161,
    label: 'FARMING ORCHARDS',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΔΕΝΔΡΟΚΗΠΩΝ',
  },
  {
    value: 3162,
    label: 'FARMING RICE',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΡΥΖΙΟΥ',
  },
  {
    value: 3163,
    label: 'FARMING SOD',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΧΛΟΟΤΑΠΗΤΑ',
  },
  {
    value: 3164,
    label: 'FARMING TOBACCO',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΚΑΠΝΟΥ',
  },
  {
    value: 3165,
    label: 'FARMING VEGETABLES AND MELONS',
    labelGreek: 'ΚΑΛΛΙΕΡΓΕΙΑ ΟΠΩΡΟΚΗΠΕΥΤΙΚΩΝ ΚΑΙ ΠΕΠΟΝΙΩΝ',
  },
  {
    value: 3166,
    label: 'FISH FARMERS',
    labelGreek: 'ΙΧΘΥΟΚΑΛΛΙΕΡΓΗΤΕΣ',
  },
  {
    value: 3167,
    label: 'GRAIN INSPECTOR',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ ΣΙΤΗΡΩΝ',
  },
  {
    value: 3168,
    label: 'BEEKEEPERS',
    labelGreek: 'ΜΕΛΙΣΣΟΚΟΜΟΣ',
  },
  {
    value: 3169,
    label: 'POULTRY FARM',
    labelGreek: 'ΦΑΡΜΑ ΠΟΥΛΕΡΙΚΩΝ',
  },
  {
    value: 3170,
    label: 'WINE GROWERS',
    labelGreek: 'ΑΜΠΕΛΟΥΡΓΟΣ',
  },
  {
    value: 3171,
    label: 'FISHERMAN',
    labelGreek: 'ΨΑΡΑΣ',
  },
  {
    value: 3172,
    label: 'FISHERMEN - NETS',
    labelGreek: 'ΨΑΡΑΣ - ΔΙΧΤΥΑ',
  },
  {
    value: 3173,
    label: 'FISHERMEN - TRAWL',
    labelGreek: 'ΨΑΡΑΣ- ΜΗΧΑΝΟΤΡΑΤΑ',
  },
  {
    value: 3174,
    label: 'FLORICULTURE - GROWING',
    labelGreek: 'ΑΝΘΟΚΟΜΙΑ - ΚΑΛΛΙΕΡΓΕΙΑ',
  },
  {
    value: 3175,
    label: 'FLOWER ARRANGER',
    labelGreek: 'ΕΠΙΜΕΛΗΤΗΣ ΛΟΥΛΟΥΔΙΩΝ',
  },
  {
    value: 3176,
    label: 'PLANTATION /ESTATES',
    labelGreek: 'ΦΥΤΕΙΕΣ',
  },
  {
    value: 3177,
    label: 'FORESTER',
    labelGreek: 'ΔΑΣΟΚΟΜΕΙΑ',
  },
  {
    value: 3178,
    label: 'GAMEKEEPER',
    labelGreek: 'ΦΥΛΑΚΑΣ ΠΕΡΙΟΧΗΣ ΚΥΝΗΓΙΟΥ',
  },
  {
    value: 3179,
    label: 'GARDEN DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΚΗΠΩΝ',
  },
  {
    value: 3180,
    label: 'GARDEN MAINTENANCE EXCLUDING TREEFELLING',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗ ΚΗΠΟΥ ΕΚΤΟΣ ΚΟΠΗΣ ΔΕΝΤΡΩΝ',
  },
  {
    value: 3181,
    label: 'GARDEN MAINTENANCE INCLUDING TREEFELLING',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗ ΚΗΠΟΥ   ΜΕ ΚΟΠΗ ΔΕΝΤΡΩΝ',
  },
  {
    value: 3182,
    label: 'GARDENER',
    labelGreek: 'ΚΗΠΟΥΡΟΣ',
  },
  {
    value: 3183,
    label: 'LANDSCAPE GARDENER EXCLUDING TREEFELLING',
    labelGreek: 'ΚΗΠΟΥΡΟΣ ΤΟΠΙΟΥ ΕΚΤΟΣ ΚΟΠΗΣ ΔΕΝΤΡΩΝ',
  },
  {
    value: 3184,
    label: 'LANDSCAPE GARDNER INCLUDING TREEFELLING',
    labelGreek: 'ΚΗΠΟΥΡΟΣ ΤΟΠΙΟΥ ΜΕ ΚΟΠΗ ΔΕΝΤΡΩΝ',
  },
  {
    value: 3185,
    label: 'LAND DRAINAGE & IRRIGATION',
    labelGreek: 'ΑΠΟΣΤΡΑΓΓΙΣΗ ΕΔΑΦΟΥΣ & ΑΡΔΕΥΣΗ',
  },
  {
    value: 3186,
    label: 'MILLER',
    labelGreek: 'ΜΥΛΩΝΑΣ',
  },
  {
    value: 3187,
    label: 'MILLER  CORN  RICE AND MEAL WORKERS',
    labelGreek: 'ΜΥΛΩΝΑΣ  ΚΑΛΑΜΠΟΚΙΑ  ΡΥΖΙ  ΚΑΙ ΕΡΓΑΤΗΣ ΣΕ ΜΥΛΟ',
  },
  {
    value: 3188,
    label: 'OIL MILLS NOT MINERL OILS',
    labelGreek: 'ΕΛΑΙΟΤΡΙΒΕΙΑ (ΟΧΙ ΟΡΥΚΤΑ ΕΛΑΙΑ)',
  },
  {
    value: 3189,
    label: 'OIL MINERAL REFINERS LOADING AND DISCHARGING',
    labelGreek: 'ΟΡΥΚΤΕΛΑΙΟ ΔΙΥΛΙΣΤΗΡΙΩΝ ΦΟΡΤΩΣΗ ΚΑΙ ΕΚΦΟΡΤΩΣΗ',
  },
  {
    value: 3190,
    label: 'PEST AND VERMIN CONTROL',
    labelGreek: 'ΕΛΕΓΧΟΣ ΠΑΡΑΣΙΤΩΝ ΚΑΙ ΖΩΥΦΙΩΝ',
  },
  {
    value: 3191,
    label: 'PORTERS TO TRUCKS (EXCLUDING PORTS)',
    labelGreek: 'ΑΧΘΟΦΟΡΟΙ ΓΙΑ ΤΑ ΦΟΡΤΗΓΑ ΟΧΗΜΑΤΑ (ΕΚΤΟΣ ΑΠΟ ΛΙΜΑΝΙΑ )',
  },
  {
    value: 3192,
    label: 'LANDSCAPER',
    labelGreek: 'ΤΟΠΟΓΡΑΦΟΣ',
  },
  {
    value: 3193,
    label: 'ARCHITECT',
    labelGreek: 'ΑΡΧΙΤΕΚΤΟΝΑΣ',
  },
  {
    value: 3194,
    label: 'ARCHITECTS / DESIGNERS / DECORATORS',
    labelGreek: 'ΑΡΧΙΤΕΚΤΟΝΕΣ / ΣΧΕΔΙΑΣΤΕΣ / ΔΙΑΚΟΣΜΗΤΕΣ',
  },
  {
    value: 3195,
    label: 'CALIBRATION',
    labelGreek: 'ΒΑΘΜΟΝΟΜΗΣΗ',
  },
  {
    value: 3196,
    label: 'COBBLER',
    labelGreek: 'ΕΜΒΑΛΩΜΑΤΗΣ',
  },
  {
    value: 3197,
    label: 'BUILDER - DESIGN ONLY',
    labelGreek: 'ΟΙΚΟΔΟΜΟΣ - ΣΧΕΔΙΑΣΜΟΣ ΜΟΝΟ',
  },
  {
    value: 3198,
    label: 'DRILL INSTRUCTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΔΙΑΤΡΗΣΕΩΝ',
  },
  {
    value: 3199,
    label: 'MASON',
    labelGreek: 'ΚΤΙΣΤΗΣ',
  },
  {
    value: 3200,
    label: 'CONTRACTOR',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ',
  },
  {
    value: 3201,
    label: 'FENCING CONTRACTORS',
    labelGreek: 'ΠΕΡΙΦΡΑΞΕΙΣ',
  },
  {
    value: 3202,
    label: 'SEWER AND ROAD CONTRACTORS',
    labelGreek: 'ΑΠΟΧΕΤΕΥΤΙΚΟ ΔΙΚΤΥΟ ΚΑΙ ΟΙ ΕΡΓΟΛΑΒΟΙ ΕΡΓΩΝ ΟΔΟΠΟΙΪΑΣ',
  },
  {
    value: 3203,
    label: 'DESIGN LANDSCAPE',
    labelGreek: 'ΣΧΕΔΙΑΣΜΟΣ ΤΟΠΙΟΥ',
  },
  {
    value: 3204,
    label: 'DESIGNER KITCHEN & BATHROOM',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΚΟΥΖΙΝΑΣ & ΜΠΑΝΙΟΥ',
  },
  {
    value: 3205,
    label: 'DESIGNER INTERIOR',
    labelGreek: 'ΔΙΑΚΟΣΜΗΤΗΣ ΕΣΣΩΤΕΡΙΚΟΥ ΧΩΡΟΥ',
  },
  {
    value: 3206,
    label: 'DRAFTSMAN',
    labelGreek: 'ΣΥΝΤΑΚΤΗΣ ΓΝΩΜΟΔΟΤΗΣΗΣ',
  },
  {
    value: 3207,
    label: 'GRAPHIC DESIGNERS / DESIGNERS',
    labelGreek: 'ΓΡΑΦΙΣΤΕΣ / ΣΧΕΔΙΑΣΤΕΣ',
  },
  {
    value: 3208,
    label: 'INTERIOR DECORATOR',
    labelGreek: 'ΔΙΑΚΟΣΜΗΤΕΣ',
  },
  {
    value: 3209,
    label: 'ALARM SYSTEMS DEALERS AND INSTALLERS',
    labelGreek: 'ΣΥΣΤΗΜΑΤΑ ΣΥΝΑΓΕΡΜΟΥ  ΕΜΠΟΡΟΙ ΚΑΙ ΕΓΚΑΤΑΣΤΑΤΕΣ',
  },
  {
    value: 3210,
    label: 'ELECTRICAL TECHNICIAN',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΣ ΤΕΧΝΙΚΟΣ',
  },
  {
    value: 3211,
    label: 'ELECTRICIAN',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΣ',
  },
  {
    value: 3212,
    label: 'ACOUSTIC ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΑΚΟΥΣΤΙΚΗΣ',
  },
  {
    value: 3213,
    label: 'BROADCAST ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΑΝΑΜΕΤΑΔΩΣΗΣ',
  },
  {
    value: 3214,
    label: 'BUILDING SERVICES ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ (ΚΑΤΑΣΚΕΥΑΣΤΙΚΕΣ ΥΠΗΡΕΣΙΕΣ)',
  },
  {
    value: 3215,
    label: 'CIVIL & CONSULTING ENGINEERS',
    labelGreek: 'ΠΟΛΙΤΙΚΟΙ & ΣΥΜΒΟΥΛΟΙ ΜΗΧΑΝΙΚΟΙ',
  },
  {
    value: 3216,
    label: 'ELECTRICAL ENGINEER',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3217,
    label: 'ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3218,
    label: 'ENGINEERING CONSULTING',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ ΣΥΜΒΟΥΛΟΙ',
  },
  {
    value: 3219,
    label: 'ENGINEERS - BUILDING SERVICES',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΟΙΚΟΔΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 3220,
    label: 'ENGINEERS - HEATING VENTILATION & AIR-CONDITIONING DESIGN',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΘΕΡΜΑΝΣΗ  ΑΕΡΙΣΜΟΣ & ΚΛΙΜΑΤΙΣΜΟΥ',
  },
  {
    value: 3221,
    label: 'ENGINEERS - MANUFACTURING (PRECISION)',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΜΕΤΑΠΟΙΗΣΗ (ΑΚΡΙΒΕΙΑΣ)',
  },
  {
    value: 3222,
    label: 'ENGINEERS - MECHANICAL & ELECTRICAL BUILDING SERVICES',
    labelGreek:
      ' : ΜΗΧΑΝΙΚΟΙ - ΜΗΧΑΝΟΛΟΓΙΚΟΣ & ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ ΟΙΚΟΔΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 3223,
    label: 'ENGINEERS - MECHANICAL (PROCESS CONTROL & PRODUCTION LINE)',
    labelGreek:
      ' : ΜΗΧΑΝΙΚΟΙ - ΜΗΧΑΝΙΚΗΣ (ΔΙΑΔΙΚΑΣΙΑ ΕΛΕΓΧΟΥ & ΓΡΑΜΜΗ ΠΑΡΑΓΩΓΗΣ)',
  },
  {
    value: 3224,
    label:
      'ENGINEERS - PROJECT COORDINATION (EXCLUDING APPOINTMENT OF SUBCONTRACTORS)',
    labelGreek:
      ' : ΜΗΧΑΝΙΚΟΙ - ΣΥΝΤΟΝΙΣΜΟ ΤΟΥ ΕΡΓΟΥ (ΕΚΤΟΣ ΔΙΟΡΙΣΜΟΣ ΤΩΝ ΥΠΕΡΓΟΛΑΒΩΝ)',
  },
  {
    value: 3225,
    label: 'ENGINEERS - PROJECT MANAGEMENT',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΔΙΑΧΕΙΡΙΣΗ ΕΡΓΟΥ',
  },
  {
    value: 3226,
    label: 'ENGINEERS - REFRIGERATION',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΨΥΞΗ',
  },
  {
    value: 3227,
    label: 'ENGINEERS - STRUCTURAL GARDEN',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ - ΔΙΑΡΘΡΩΣΕΙΣ ΚΗΠΩΝ',
  },
  {
    value: 3228,
    label:
      'ENGINEERS (EXCLUDE. ERECTION  FITTING  OR REPAIR AWAY FROM SHOP OR YARD)',
    labelGreek:
      ' : ΜΗΧΑΝΙΚΟΙ (ΕΚΤΟΣ ΑΝΕΓΕΡΣΗ  ΤΟΠΟΘΕΤΗΣΗ  Η ΕΠΙΣΚΕΥΗ ΜΑΚΡΙΑ ΑΠΟ ΤΟ ΚΑΤΑΣΤΗΜΑ Η ΤΗΝ ΑΥΛΗ)',
  },
  {
    value: 3229,
    label: 'ENGINEERS OVER 20FT',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ (ΕΡΓΑΣΙΕΣ ΠΑΝΩ ΑΠΟ 20FT)',
  },
  {
    value: 3230,
    label: 'ENGINEERS UP TO 20FT HEIGHT FROM WATER LEVEL AT LOWTIDE',
    labelGreek:
      ' : ΜΗΧΑΝΙΚΟΙ (ΕΡΓΑΣΙΕΣ ΕΩΣ 20FT ΥΨΟΣ ΑΠΟ ΣΤΑΘΜΗΣ ΤΟΥ ΝΕΡΟΥ ΣΤΗΝ ΑΜΠΩΤΗ',
  },
  {
    value: 3231,
    label: 'HYDRAULIC ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΥΔΡΑΥΛΙΚΩΝ',
  },
  {
    value: 3232,
    label: 'INDUSTRIAL ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΒΙΟΜΗΧΑΝΙΑΣ',
  },
  {
    value: 3233,
    label: 'NUCLEAR ENGINEER',
    labelGreek: 'ΠΥΡΗΝΙΚΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3234,
    label: 'RECORDING ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΚΑΤΑΓΡΑΦΗΣ',
  },
  {
    value: 3235,
    label: 'SANITARY ENGINEERS',
    labelGreek: 'ΥΓΕΙΟΝΟΜΙΚΟΙ ΜΗΧΑΝΙΚΟΙ',
  },
  {
    value: 3236,
    label: 'STRUCTURAL ENGINEERS',
    labelGreek: 'ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3237,
    label: 'TELEPHONE ENGINEERS',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ ΤΗΛΕΦΩΝΩΝ',
  },
  {
    value: 3238,
    label: 'IRON WORKER',
    labelGreek: 'ΕΡΓΑΖΟΜΕΝΟΙ ΣΙΔΕΡΟΚΑΤΑΣΚΕΥΩΝ',
  },
  {
    value: 3239,
    label: 'IRONMASTER',
    labelGreek: 'ΣΙΔΕΡΑΣ',
  },
  {
    value: 3240,
    label: 'IRONMONGER',
    labelGreek: 'ΣΙΔΗΡΟΠΩΛΗΣ',
  },
  {
    value: 3241,
    label: 'ELEVATOR MECHANIC',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ ΑΣΑΝΣΕΡ',
  },
  {
    value: 3242,
    label: 'LIFT ATTENDANTS',
    labelGreek: 'ΣΥΝΤΗΡΗΤΕΣ ΑΣΑΝΣΕΡ',
  },
  {
    value: 3243,
    label: 'LIFT ENGINEER (EXCL CERTIFICATION)',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΑΝΥΨΩΣΕΩΝ (ΧΩΡΙΣ ΠΙΣΤΟΠΟΙΗΣΗ)',
  },
  {
    value: 3244,
    label: 'LIFT ENGINEER (INCL CERTIFICATION)',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΑΝΥΨΩΣΕΩΝ (ΣΥΜΠ ΠΙΣΤΟΠΟΙΗΣΗ)',
  },
  {
    value: 3245,
    label: 'LIGHTING TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ ΦΩΤΙΣΜΟΥ',
  },
  {
    value: 3246,
    label: 'PANEL BEATER',
    labelGreek: 'ΦΑΝΟΠΟΙΟΣ',
  },
  {
    value: 3247,
    label: 'TOWN PLANNER',
    labelGreek: 'ΠΟΛΕΟΔΟΜΟΣ',
  },
  {
    value: 3248,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΑΧΕΙΡΗΣΗ ΕΡΓΟΥ',
  },
  {
    value: 3249,
    label: 'TECHNOLOGIST',
    labelGreek: 'ΤΕΧΝΟΛΟΓΟΣ',
  },
  {
    value: 3250,
    label: 'BRITISH FORCES SERVANTS',
    labelGreek: 'ΥΠΑΛΛΗΛΟΙ ΒΡΕΤΑΝΙΚΕΣ ΔΥΝΑΜΕΙΣ',
  },
  {
    value: 3251,
    label: 'CAPTAIN',
    labelGreek: 'ΛΟΧΑΓΟΣ',
  },
  {
    value: 3252,
    label: 'COLONEL',
    labelGreek: 'ΣΥΝΤΑΓΜΑΤΑΡΧΗΣ',
  },
  {
    value: 3253,
    label: 'LT COLONEL',
    labelGreek: 'ΑΝΤΙΣΥΝΤΑΓΜΑΤΑΡΧΗΣ',
  },
  {
    value: 3254,
    label: 'CONSTABLE',
    labelGreek: 'ΑΣΤΥΦΥΛΑΚΑΣ',
  },
  {
    value: 3255,
    label: 'DETECTIVE',
    labelGreek: 'ΝΤΕΤΕΚΤΙΒ',
  },
  {
    value: 3256,
    label: 'FIRE BRIGADE',
    labelGreek: 'ΠΥΡΟΣΒΕΣΤΙΚΟ ΣΩΜΑ',
  },
  {
    value: 3257,
    label: 'FIREMAN/FIRE GUARD',
    labelGreek: 'ΠΥΡΟΣΒΕΣΤΕΣ/ ΠΥΡΑΣΦΑΛΕΙΑ',
  },
  {
    value: 3258,
    label: 'GENERAL',
    labelGreek: 'ΣΤΡΑΤΗΓΟΣ',
  },
  {
    value: 3259,
    label: 'INFANTRYMAN',
    labelGreek: 'ΦΑΝΤΑΡΟΣ',
  },
  {
    value: 3260,
    label: 'MAJOR',
    labelGreek: 'ΤΑΓΜΑΤΑΡΧΗΣ',
  },
  {
    value: 3261,
    label: 'MARINE',
    labelGreek: 'ΠΕΖΟΝΑΥΤΕΣ',
  },
  {
    value: 3262,
    label: 'INTELLIGENCE OFFICER',
    labelGreek: 'ΑΞΙΩΜΑΤΙΚΟΣ ΠΛΗΡΟΦΟΡΙΩΝ',
  },
  {
    value: 3263,
    label: 'CHIEF OF POLICE',
    labelGreek: 'ΑΡΧΗΓΟΣ ΤΗΣ ΑΣΤΥΝΟΜΙΑΣ',
  },
  {
    value: 3264,
    label: 'DEPUTY (LAW ENFORCEMENT)',
    labelGreek: 'ΑΝΑΠΛΗΡΩΤΗΣ (ΕΠΙΒΟΛΗ ΤΟΥ ΝΟΜΟΥ)',
  },
  {
    value: 3265,
    label: 'DEPUTY (PARLIAMENTARY)',
    labelGreek: 'ΑΝΑΠΛΗΡΩΤΗΣ (ΚΟΙΝΟΒΟΥΛΕΥΤΙΚΗ)',
  },
  {
    value: 3266,
    label: 'INSPECTOR',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ',
  },
  {
    value: 3267,
    label: 'INTERROGATOR',
    labelGreek: 'ΑΝΑΚΡΙΤΗΣ',
  },
  {
    value: 3268,
    label: 'POLICE INSPECTOR',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ ΑΣΤΥΝΟΜΙΑΣ',
  },
  {
    value: 3269,
    label: 'POLICE OFFICER',
    labelGreek: 'ΑΣΤΥΝΟΜΙΚΟΣ',
  },
  {
    value: 3270,
    label: 'SPECIAL AGENT',
    labelGreek: 'ΕΙΔΙΚΟΣ ΠΡΑΚΤΟΡΑΣ',
  },
  {
    value: 3271,
    label: 'TEST PILOT',
    labelGreek: 'ΠΙΛΟΤΟΣ ΔΟΚΙΜΩΝ',
  },
  {
    value: 3272,
    label: 'CABLE TELEVISION SYSTEM OPERATORS',
    labelGreek: 'ΚΑΛΩΔΙΑΚΩΝ ΥΠΗΡΕΣΙΩΝ ΤΗΛΕΟΠΤΙΚΟΥ ΣΥΣΤΗΜΑΤΟΣ',
  },
  {
    value: 3273,
    label: 'COMPUTER CONSUMABLES SALES',
    labelGreek: 'ΑΝΑΛΩΣΙΜΑ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΠΟΛΟΓΙΣΤΩΝ ΠΩΛΗΣΕΙΣ',
  },
  {
    value: 3274,
    label: 'COMPUTER DEALER',
    labelGreek: 'ΠΩΛΗΤΗΣ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3275,
    label: 'COMPUTER STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3276,
    label: 'CONSULTANT DATABASE MANAGEMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΑΧΕΙΡΙΣΗΣ ΒΑΣΕΩΝ ΔΕΔΟΜΕΝΩΝ',
  },
  {
    value: 3277,
    label: 'DATA PROCESSOR',
    labelGreek: 'ΕΠΕΞΕΡΓΑΣΤΗΣ ΔΕΔΟΜΕΝΩΝ',
  },
  {
    value: 3278,
    label: 'DATABASE ADMINISTRATOR (DBA)',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΤΗΣ ΒΑΣΗΣ ΔΕΔΟΜΕΝΩΝ (DBA)',
  },
  {
    value: 3279,
    label: 'DATABASE MIGRATION',
    labelGreek: 'ΜΕΤΑΦΟΡΑ ΒΑΣΗΣ ΔΕΔΟΜΕΝΩΝ',
  },
  {
    value: 3280,
    label: 'COMPACT DISC MANUFACTURING',
    labelGreek: 'ΠΑΡΑΓΩΓΗΣ CD',
  },
  {
    value: 3281,
    label: 'COMPUTER ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3282,
    label: 'COMPUTER HARDWARE AND SOFTWARE INSTALLATION',
    labelGreek: 'ΕΦΑΡΜΟΓΗ ΣΥΣΚΕΥΩΝ Η/Υ ΚΑΙ ΛΟΓΙΣΜΙΚΟΥ',
  },
  {
    value: 3283,
    label: 'COMPUTER MANUFACTURER / ASSEMBLY',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ Η/Υ / ΣΥΝΑΡΜΟΛΟΓΙΣΗ',
  },
  {
    value: 3284,
    label: 'COMPUTER REPAIRS',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3285,
    label: 'INTERNET SERVICE PROVIDER',
    labelGreek: 'ΥΠΗΡΕΣΙΑ ΠΑΡΟΧΗΣ INTERNET',
  },
  {
    value: 3286,
    label: 'SERVER HOSTING',
    labelGreek: 'ΔΙΑΧΕΙΡΗΣΗ ΔΙΑΚΟΜΙΣΤΗ',
  },
  {
    value: 3287,
    label: 'COMPUTER CABLING',
    labelGreek: 'ΚΑΛΩΔΙΩΣΗ Η/Υ',
  },
  {
    value: 3288,
    label: 'INFORMATION TECHNOLOGIST (IT)',
    labelGreek: 'ΤΕΧΝΟΛΟΓΟΣ ΠΛΗΡΟΦΟΡΙΩΝ (IT)',
  },
  {
    value: 3289,
    label: 'IT CONTRACTOR',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3290,
    label: 'IT HARDWARE DESIGN',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3291,
    label: 'IT HARDWARE LEASING',
    labelGreek: 'ΕΝΟΙΚΙΑΣΤΗΣ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3292,
    label: 'IT HARDWARE MAINTENANCE & REPAIR',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗ & ΕΠΙΣΚΕΥΗ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3293,
    label: 'IT HARDWARE MANUFACTURE &/OR ASSEMBLY',
    labelGreek: 'ΚΑΤΑΣΚΕΥΗ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ Η /ΚΑΙ ΣΥΝΑΡΜΟΛΟΓΗΣΗ',
  },
  {
    value: 3294,
    label: 'IT HARDWARE SALES & SUPPLY - NEW',
    labelGreek:
      ' : ΠΩΛΗΣΕΙΣ & ΠΑΡΟΧΗ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ & ΠΑΡΟΧΗΣ - ΚΑΝΟΥΡΓΙΑ',
  },
  {
    value: 3295,
    label: 'IT HARDWARE SALES & SUPPLY - SECOND HAND',
    labelGreek:
      ' : ΠΩΛΗΣΕΙΣ & ΠΑΡΟΧΗ ΣΥΣΚΕΥΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ & ΠΑΡΟΧΗΣ - ΜΕΤΑΧΕΙΡΙΣΜΕΝΑ',
  },
  {
    value: 3296,
    label: 'IT OUTSOURCING - CONSULTANCY',
    labelGreek: 'ΕΞΩΤΕΡΙΚΗ ΑΝΑΘΕΣΗ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΣΥΜΒΟΥΛΟΙ',
  },
  {
    value: 3297,
    label: 'IT OUTSOURCING - DATA BACKUP & STORAGE',
    labelGreek:
      ' : ΕΞΩΤΕΡΙΚΗ ΑΝΑΘΕΣΗ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΔΗΜΙΟΥΡΓΙΑ & ΑΠΟΘΗΚΕΥΣΗ ΑΝΤΙΓΡΑΦΩΝ ΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 3298,
    label: 'IT OUTSOURCING - DATABASE MANAGEMENT',
    labelGreek:
      ' : ΕΞΩΤΕΡΙΚΗ ΑΝΑΘΕΣΗ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΔΙΑΧΕΙΡΙΣΗΣ ΒΑΣΕΩΝ ΔΕΔΟΜΕΝΩΝ',
  },
  {
    value: 3299,
    label: 'IT OUTSOURCING - IT FACILITIES MANAGEMENT',
    labelGreek:
      ' : ΕΞΩΤΕΡΙΚΗ ΑΝΑΘΕΣΗ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΔΙΑΧΕΙΡΙΣΗ ΕΓΚΑΤΑΣΤΑΣΕΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3300,
    label: 'IT PROGRAMME MANAGEMENT',
    labelGreek: 'ΔΙΑΧΕΙΡΗΣΗ ΠΡΟΓΡΑΜΜΑΤΩΝ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3301,
    label: 'IT SECURITY - CONSULTANCY',
    labelGreek: 'ΑΣΦΑΛΕΙΑ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΣΥΜΒΟΥΛΟΙ',
  },
  {
    value: 3302,
    label: 'IT SECURITY - PENETRATION TESTING',
    labelGreek: 'ΑΣΦΑΛΕΙΑ ΠΛΗΡΟΦΟΡΙΚΗΣ - ΔΟΚΙΜΕΣ',
  },
  {
    value: 3303,
    label: 'IT TECHNICIAN',
    labelGreek: 'ΠΛΗΡΟΦΟΡΙΚΗ - ΤΕΧΝΙΚΟΣ',
  },
  {
    value: 3304,
    label: 'IT TRAINING NETWORK & SYSTEMS SUPPORT',
    labelGreek: 'ΔΙΚΤΥΟ ΕΚΠΑΙΔΕΥΣΗΣ ΠΛΗΡΟΦΟΡΙΚΗΣ & ΣΥΣΤΗΜΑΤΩΝ ΥΠΟΣΤΗΡΙΞΗΣ',
  },
  {
    value: 3305,
    label: 'COMPUTER PROGRAMMER',
    labelGreek: 'ΠΡΟΓΡΑΜΜΑΤΙΣΤΗΣ Η/Υ',
  },
  {
    value: 3306,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΑΧΕΙΡΗΣΤΗΣ ΕΡΓΟΥ',
  },
  {
    value: 3307,
    label: 'COMPUTER SOFTWARE CUSTOMISATION',
    labelGreek: 'ΠΡΟΣΑΡΜΟΓΗ ΛΟΓΙΣΜΙΚΟΥ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3308,
    label: 'COMPUTER SOFTWARE DEVELOPERS',
    labelGreek: 'ΠΡΟΓΡΑΜΜΑΤΙΣΤΕΣ ΛΟΓΙΣΜΙΚΟΥ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3309,
    label: 'COMPUTER SOFTWARE MAINTENANCE',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗ ΛΟΓΙΣΜΙΚΟΥ Η/Υ',
  },
  {
    value: 3310,
    label: 'COMPUTER SOFTWARE SALE (THIRD PARTY)',
    labelGreek: 'ΠΩΛΗΣΗ ΛΟΓΙΣΜΙΚΟΥ ΥΠΟΛΟΓΙΣΤΩΝ  (ΣΕ ΤΡΙΤΟΥΣ)',
  },
  {
    value: 3311,
    label: 'COMPUTER SOFTWARE TESTING',
    labelGreek: 'ΔΟΚΙΜΕΣ ΛΟΓΙΣΜΙΚΟΥ Η/Υ',
  },
  {
    value: 3312,
    label: 'SOFTWARE ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΛΟΓΙΣΜΙΚΟΥ',
  },
  {
    value: 3313,
    label: 'SOFTWARE PROJECT MANAGER',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΤΗΣ ΕΡΓΟΥ (ΛΟΓΙΣΜΙΚΟ)',
  },
  {
    value: 3314,
    label: 'WEB DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΙΣΤΟΣΕΛΙΔΩΝ',
  },
  {
    value: 3315,
    label: 'WEB DEVELOPER',
    labelGreek: 'ΠΡΟΓΡΑΜΜΑΤΙΣΤΗΣ ΙΣΤΟΣΕΛΙΔΩΝ',
  },
  {
    value: 3316,
    label: 'BRICKLAYER',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΗ ΤΟΥΒΛΩΝ',
  },
  {
    value: 3317,
    label: 'BUILDER',
    labelGreek: 'ΟΙΚΟΔΟΜΟΣ',
  },
  {
    value: 3318,
    label: 'BUILDER - COMMERCIAL PREMISES (ALT AND REPAIR)',
    labelGreek:
      ' : ΟΙΚΟΔΟΜΟΣ - ΕΜΠΟΡΙΚΩΝ ΚΑΤΑΣΤΗΜΑΤΩΝ (ΔΙΑΚΟΠΗ ΕΡΓΑΣΙΩΝ ΚΑΙ ΕΠΙΣΚΕΥΗ)',
  },
  {
    value: 3319,
    label: 'BUILDER - COMMERCIAL PREMISES (NEW)',
    labelGreek: 'ΟΙΚΟΔΟΜΟΣ - ΕΜΠΟΡΙΚΩΝ ΚΑΤΑΣΤΗΜΑΤΩΝ (ΝΕΟ)',
  },
  {
    value: 3320,
    label: 'BUILDER - PRIVATE DWELLINGS (NEW)',
    labelGreek: 'ΟΙΚΟΔΟΜΟΣ - ΙΔΙΩΤΙΚΕΣ ΚΑΤΟΙΚΙΕΣ (ΝΕΟ)',
  },
  {
    value: 3321,
    label: 'BUILDER - PRIVATE DWELLINGS (ALT AND REPAIR)',
    labelGreek: 'ΟΙΚΟΔΟΜΟΣ - ΙΔΙΩΤΙΚΕΣ ΚΑΤΟΙΚΙΕΣ (ΠΑΥΣΗΣ ΚΑΙ ΕΠΙΣΚΕΥΗΣ)',
  },
  {
    value: 3322,
    label:
      'BUILDERS (ALL OTHER WORK ON BUILDING EXCEEDING 6 STOREYS IN HEIGHT)',
    labelGreek:
      ' : ΟΙΚΟΔΟΜΟΙ (ΟΛΕΣ ΟΙ ΥΠΟΛΟΙΠΕΣ ΕΡΓΑΣΙΕΣ ΓΙΑ ΤΗΝ ΚΑΤΑΣΚΕΥΗ ΠΟΥ ΓΙΝΟΝΤΑΙ ΣΕ ΥΨΟΣ ΑΝΩ ΤΩΝ 6 ΟΡΟΦΩΝ)',
  },
  {
    value: 3323,
    label: 'BUILDERS AT A HEIGHT OF UP TO 3 STOREYS',
    labelGreek: 'ΟΙΚΟΔΟΜΟΙ ΣΕ ΥΨΟΣ ΜΕΧΡΙ 3 ΟΡΟΦΟΥΣ',
  },
  {
    value: 3324,
    label: 'BUILDERS AT A HEIGHT TO 11 STOREYS',
    labelGreek: 'ΟΙΚΟΔΟΜΟΙ ΣΕ ΥΨΟΣ ΙΣΟ ΜΕΧΡΙ 11 ΟΡΟΦΟΥΣ',
  },
  {
    value: 3325,
    label: 'BUILDERS MERCHANTS LOADING & DISCHARGING VESSELS',
    labelGreek: 'ΚΤΙΣΤΕΣ ΣΕ ΕΜΠΟΡΙΚΑ ΣΚΑΦΗ ΦΟΡΤΩΣΗΣ ΚΑΙ ΕΚΦΟΡΤΩΣΗΣ',
  },
  {
    value: 3326,
    label: 'BUILDING - AT A HEIGHT TO 11 STOREYS',
    labelGreek: 'ΟΙΚΟΔΟΜΙΚΑ - ΣΕ ΥΨΟΣ ΕΩΣ 11 ΟΡΟΦΟΥΣ',
  },
  {
    value: 3327,
    label: 'CARRIAGE BUILDERS',
    labelGreek: 'ΟΙΚΟΔΟΜΩΝ ΜΕΤΑΦΟΡΑΣ',
  },
  {
    value: 3328,
    label: 'COACH BUILDERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΑΜΑΞΩΜΑΤΩΝ',
  },
  {
    value: 3329,
    label: 'MASON',
    labelGreek: 'ΚΤΙΣΤΕΣ',
  },
  {
    value: 3330,
    label: 'PONTOON BUILDERS  & REPAIRERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ & ΕΠΙΣΚΕΥΑΣΤΕΣ ΣΧΕΔΊΩΝ',
  },
  {
    value: 3331,
    label: 'ATMOKATHARISTIRIA / WASHERS - ENGINE USERS',
    labelGreek: 'ΑΤΜΟΚΑΘΑΡΙΣΤΗΡΙΑ/ ΠΛΥΝΤΗΡΙΑ - ΧΡΗΣΤΕΣ ΤΗΣ ΜΗΧΑΝΗΣ',
  },
  {
    value: 3332,
    label: 'CAR WASHES',
    labelGreek: 'ΠΛΥΝΤΗΡΙΑ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 3333,
    label: 'DYERS  DRY CLEANERS  DRESSERS & MERCERISERS',
    labelGreek: 'ΒΑΦΕΙΣ  ΚΑΘΑΡΙΣΤΗΡΙΑ',
  },
  {
    value: 3334,
    label: 'WHEELIE BIN CLEANERS',
    labelGreek: 'ΠΛΑΝΩΔΙΟΙ ΚΑΘΑΡΙΣΤΕΣ',
  },
  {
    value: 3335,
    label: 'ALUMINIUM - CONSTRUCTION APPLICATIONS',
    labelGreek: 'ΑΛΟΥΜΙΝΙΑ - ΕΦΑΡΜΟΓΕΣ ΣΕ ΚΑΤΑΣΚΕΥΕΣ',
  },
  {
    value: 3336,
    label: 'CEILING CONTRACTORS',
    labelGreek: 'ΕΡΓΟΛΑΒΟΙ ΟΡΟΦΗΣ',
  },
  {
    value: 3337,
    label: 'CEMENT WORKS',
    labelGreek: 'ΤΣΙΜΕΝΤΟΒΙΟΜΗΧΑΝΙΕΣ',
  },
  {
    value: 3338,
    label: 'CERAMISTS',
    labelGreek: 'ΚΕΡΑΜΙΣΤΕΣ',
  },
  {
    value: 3339,
    label: 'CONCRETE CONTRACTOR',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ ΜΠΕΤΟΝ',
  },
  {
    value: 3340,
    label: 'CONSTRUCTION ENGINEER',
    labelGreek: 'ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3341,
    label: 'CONSTRUCTION MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΚΑΤΑΣΚΕΥΗΣ',
  },
  {
    value: 3342,
    label: 'CONSTRUCTION RISKS',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΗ ΚΙΝΔΥΝΩΝ ΚΑΤΑΣΚΕΥΗΣ',
  },
  {
    value: 3343,
    label: 'CONSTRUCTION WORKER',
    labelGreek: 'ΕΡΓΑΤΗΣ ΚΑΤΑΣΚΕΥΗΣ',
  },
  {
    value: 3344,
    label: 'DECORATIVE PLASTER WORK - CONSTRUCTION / APPLICATIONS',
    labelGreek: 'ΕΡΓΑΣΙΑ ΔΙΑΚΟΣΜΗΤΙΚΟΥ ΓΥΨΟΥ - ΚΑΤΑΣΚΕΥΗ / ΕΦΑΡΜΟΓΕΣ',
  },
  {
    value: 3345,
    label: 'DEMOLITION',
    labelGreek: 'ΚΑΤΕΔΑΦΙΣΕΙΣ',
  },
  {
    value: 3346,
    label: 'DEMOLITIONIST',
    labelGreek: 'ΚΑΤΕΔΑΦΙΣΤΗΣ',
  },
  {
    value: 3347,
    label: 'DOMESTIC SHED & CARPORT ERECTOR',
    labelGreek: 'ΟΙΚΙΑΚΗ ΣΚΙΑΣΗ & ΥΠΟΣΤΕΓΟ',
  },
  {
    value: 3348,
    label: 'DUCTWORK INSTALLERS',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΤΕΣ ΑΓΩΓΩΝ',
  },
  {
    value: 3349,
    label: 'FASCIA / GUTTERING INSTALLATION',
    labelGreek: 'ΠΕΡΙΤΟΝΙΑ / ΥΔΡΟΡΡΟΕΣ ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3350,
    label: 'FASCIA INSTALLATION',
    labelGreek: 'ΠΕΡΙΤΟΝΙΑ ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3351,
    label: 'FIREPLACE INSTALLATION',
    labelGreek: 'ΤΖΑΚΙ ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3352,
    label: 'FLOOR SURFACING',
    labelGreek: 'ΕΠΙΣΤΡΩΣΗ ΔΑΠΕΔΟΥ',
  },
  {
    value: 3353,
    label: 'FLOORING CONTRACTORS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΑΠΕΔΩΝ',
  },
  {
    value: 3354,
    label: 'GEOTRYPANISTES',
    labelGreek: 'ΓΕΩΤΡΥΠΑΝΙΣΤΕΣ',
  },
  {
    value: 3355,
    label: 'GUTTERING',
    labelGreek: 'ΥΔΡΟΡΡΟΕΣ',
  },
  {
    value: 3356,
    label: 'INSULATION',
    labelGreek: 'ΜΟΝΩΣΗ',
  },
  {
    value: 3357,
    label: 'INSULATION BUILDING MUSICAL INSTRUMENTS - REPAIR / CONSTRUCTION',
    labelGreek: 'ΜΟΝΩΣΕΙΣ ΚΤΙΡΙΩΝ ΓΙΑ ΜΟΥΣΙΚΑ ΟΡΓΑΝΑ - ΕΠΙΣΚΕΥΗ / ΚΑΤΑΣΚΕΥΗ',
  },
  {
    value: 3358,
    label: 'MOLDING WORKER (CONSTRUCTION)',
    labelGreek: 'ΚΑΛΟΥΠΙΑ',
  },
  {
    value: 3359,
    label: 'MACHINING & REPAIR CONSTRUCTION',
    labelGreek: 'ΜΕΤΑΛΛΟΤΕΧΝΙΕΣ  ΕΠΙΣΚΕΥΗ & ΚΑΤΑΣΚΕΥΗ',
  },
  {
    value: 3360,
    label: 'PARTITION CONTRACTOR',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ ΔΙΧΟΤΟΜΗΣΗΣ',
  },
  {
    value: 3361,
    label: 'PLASTERER',
    labelGreek: 'ΣΟΒΑΤΖΗΣ',
  },
  {
    value: 3362,
    label: 'SIGNS (CONSTRUCTION / APPLICATIONS)',
    labelGreek: 'ΠΙΝΑΚΙΔΕΣ (ΚΑΤΑΣΚΕΥΗ / ΕΦΑΡΜΟΓΕΣ)',
  },
  {
    value: 3363,
    label: 'STEEL CONSTRUCTION',
    labelGreek: 'ΜΕΤΑΛΛΙΚΕΣ ΚΑΤΑΣΚΕΥΕΣ',
  },
  {
    value: 3364,
    label: 'STEEL ERECTION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΗ ΜΕΤΑΛΛΙΚΩΝ ΚΑΤΑΣΚΕΥΩΝ',
  },
  {
    value: 3365,
    label: 'TILER',
    labelGreek: 'ΚΕΡΑΜΙΔΩΤΗΣ',
  },
  {
    value: 3366,
    label: 'TILLERS - FLOOR & WALL TILLERS - ROOFS',
    labelGreek: 'ΣΚΑΠΤΙΚΑ - ΔΑΠΕΔΟΥ /ΤΟΙΧΟΥ / ΣΤΕΓΩΝ',
  },
  {
    value: 3367,
    label: 'TIRES REPAIRS / VALKANAIZER',
    labelGreek: 'ΕΛΑΣΤΙΚΑ ΕΠΙΣΚΕΥΕΣ / VALKANAIZER',
  },
  {
    value: 3368,
    label: 'UNDERPINNING',
    labelGreek: 'ΘΕΜΕΛΙΑ',
  },
  {
    value: 3369,
    label: 'BUILDERS MERCHANT',
    labelGreek: 'ΕΜΠΟΡΙΑ ΔΟΜΙΚΩΝ ΥΛΙΚΩΝ',
  },
  {
    value: 3370,
    label: 'CONSULTING FIRE SAFETY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΥΡΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 3371,
    label: 'DRAIN CLEANERS',
    labelGreek: 'ΚΑΘΑΡΙΣΜΟΣ ΥΔΡΟΡΟΗΣ',
  },
  {
    value: 3372,
    label: 'DRILLING',
    labelGreek: 'ΓΕΩΤΡΗΣΕΙΣ',
  },
  {
    value: 3373,
    label: 'DRIVERS',
    labelGreek: 'ΟΔΗΓΟΙ',
  },
  {
    value: 3374,
    label: 'DRY LINING',
    labelGreek: 'ΕΠΕΝΔΥΣΕΙΣ ΤΟΙΧΩΝ',
  },
  {
    value: 3375,
    label: 'DRY STONE WALLING',
    labelGreek: 'ΞΕΡΟΛΙΘΙΑ ΠΡΟΣΟΨΕΙΣ',
  },
  {
    value: 3376,
    label: 'ELECTRICAL',
    labelGreek: 'ΗΛΕΚΤΡΙΚΑ',
  },
  {
    value: 3377,
    label: 'ELECTRICAL FACILITIES / BUILDINGS',
    labelGreek: 'ΗΛΕΚΤΡΙΚΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ/ ΚΤΙΡΙΑ',
  },
  {
    value: 3378,
    label: 'ELECTRICIANS - WORKSHOPS / HOUSEHOLD APPLIANCES',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΩΝ - ΕΡΓΑΣΤΗΡΙΑ / ΟΙΚΙΑΚΕΣ ΣΥΣΚΕΥΕΣ',
  },
  {
    value: 3379,
    label: 'ELECTRONIC - LABORATORY',
    labelGreek: 'ΗΛΕΚΤΡΟΝΙΚΑ - ΕΡΓΑΣΤΗΡΙΟ',
  },
  {
    value: 3380,
    label:
      'HYDRAULIC MACHINERY (INCL. RECTING FITTING OR REPAIR WAY FROM SHOP OR YARD)',
    labelGreek:
      ' : ΥΔΡΑΥΛΙΚΕΣ ΜΗΧΑΝΕΣ (ΣΥΜΠ. ΤΟΠΟΘΕΤΗΣΗ Η ΕΠΙΣΚΕΥΗ ΜΑΚΡΙΑ ΑΠΟ ΤΟ ΚΑΤΑΣΤΗΜΑ Η ΤΗΝ ΑΥΛΗ)',
  },
  {
    value: 3381,
    label: 'ILEKTROTECHNITES',
    labelGreek: 'ΗΛΕΚΤΡΟΤΕΧΝΗΤΕΣ',
  },
  {
    value: 3382,
    label: 'LIGHTNING - INSTALLATION',
    labelGreek: 'ΚΕΡΑΥΝΟΙ (ΑΛΕΞΙΚΕΡΑΥΝΑ) - ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3383,
    label: 'MACHINES (ANTIPR. & SUPPLIERS) - ELECTRICAL / FACILITIES',
    labelGreek:
      ' : ΜΗΧΑΝΕΣ (ANTIΠΡ & ΠΡΟΜΗΘΕΥΤΕΣ.) - ΗΛΕΚΤΡΙΚΑ / ΕΓΚΑΤΑΣΤΑΣΕΙΣ',
  },
  {
    value: 3384,
    label: 'HANDYMAN',
    labelGreek: 'ΠΟΛΥΤΕΧΝΙΤΗΣ',
  },
  {
    value: 3385,
    label: 'BALCONY VEHICLE APPLICATIONS / REPAIRS',
    labelGreek: 'ΕΦΑΡΜΟΓΕΣ ΟΧΗΜΑΤΟΣ ΜΠΑΛΚΟΝΙΟΥ / ΕΠΙΣΚΕΥΕΣ',
  },
  {
    value: 3386,
    label: 'BATH RE-SURFACER',
    labelGreek: 'ΕΠΙΣΤΡΩΣΕΙΣ ΕΠΙΦΑΝΕΙΩΝ ΜΠΑΝΙΟΥ',
  },
  {
    value: 3387,
    label: 'BEDROOM AND BATHROOM FITTER',
    labelGreek: 'ΣΥΝΑΡΜΟΛΟΓΗΣΕΙΣ ΥΠΝΟΔΩΜΑΤΙΟΥ ΚΑΙ ΜΠΑΝΙΟΥ',
  },
  {
    value: 3388,
    label: 'DECORATOR & PAINTER',
    labelGreek: 'ΔΙΑΚΟΣΜΗΤΗΣ & ΖΩΓΡΑΦΟΣ',
  },
  {
    value: 3389,
    label: 'FITTED FURNITURE INSTALLERS',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΕΝΤΟΙΧΙΣΜΕΝΩΝ ΕΠΙΠΛΩΝ',
  },
  {
    value: 3390,
    label: 'GARAGE AND SHED ERECTORS',
    labelGreek: 'ΑΝΕΓΕΡΣΗ ΓΚΑΡΑΖ ΚΑΙ ΜΕΣΩΝ ΣΚΙΑΣΗΣ',
  },
  {
    value: 3391,
    label: 'GARAGE DOOR AND GATE FITTERS',
    labelGreek: 'ΕΦΑΡΜΟΣΤΕΣ ΓΚΑΡΑΖΟΠΟΡΤΩΝ ΚΑΙ ΠΥΛΩΝ',
  },
  {
    value: 3392,
    label: 'GAS FITTERS',
    labelGreek: 'ΕΦΑΡΜΟΓΕΣ ΕΓΚΑΤΑΣΤΑΣΕΩΝ ΑΕΡΙΟΥ',
  },
  {
    value: 3393,
    label: 'LABOURER',
    labelGreek: 'ΕΡΓΑΤΗΣ',
  },
  {
    value: 3394,
    label: 'LABOURERS - CANNERY',
    labelGreek: 'ΕΡΓΑΤΕΣ - ΚΟΝΣΕΡΒΟΠΟΙΙΑ',
  },
  {
    value: 3395,
    label: 'LABOURERS - KAMINIA',
    labelGreek: 'ΕΡΓΑΤΕΣ - ΚΑΜΙΝΙΑ',
  },
  {
    value: 3396,
    label: 'LABOURERS - MEDALLION',
    labelGreek: 'ΕΡΓΑΤΕΣ - ΜΕΝΤΑΓΙΟΝ',
  },
  {
    value: 3397,
    label: 'LABOURERS - QUARRIES',
    labelGreek: 'ΕΡΓΑΤΕΣ - ΛΑΤΟΜΕΙΑ',
  },
  {
    value: 3398,
    label: 'LIFT INSTALLATION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΗ ΑΝΥΨΩΤΙΚΩΝ (ΑΝΕΛΚΥΣΤΗΡΩΝ)',
  },
  {
    value: 3399,
    label: 'LIFT MAINTENANCE',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗ ΑΝΕΛΚΥΣΤΗΡΩΝ',
  },
  {
    value: 3400,
    label: 'LIFT SUPPLIERS AND REPAIRERS',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ ΚΑΙ ΕΠΙΣΚΕΥΑΣΤΕΣ ΑΝΕΛΚΥΣΤΗΡΩΝ',
  },
  {
    value: 3401,
    label: 'ASBESTOS - MINING',
    labelGreek: 'ΑΜΙΑΝΤΟΣ - ΜΕΤΑΛΛΕΙΑ',
  },
  {
    value: 3402,
    label: 'ASBESTOS - USE / INSTALLATION / REMOVAL',
    labelGreek: 'ΑΜΙΑΝΤΟΣ - ΧΡΗΣΗ / ΤΟΠΟΘΕΤΗΣΗ / ΑΦΑΙΡΕΣΗ',
  },
  {
    value: 3403,
    label: 'MINER',
    labelGreek: 'ΜΕΤΑΛΛΕΙΑ',
  },
  {
    value: 3404,
    label: 'MINING MACHINERY (ERECTING  FITTING OR REPAIR AWAY FROM SHOP',
    labelGreek:
      ' : ΜΗΧΑΝΗΜΑΤΑ ΟΡΥΧΕΙΩΝ (ΑΝΕΓΕΡΣΗ  ΤΟΠΟΘΕΤΗΣΗ Η ΕΠΙΣΚΕΥΗ ΜΑΚΡΙΑ ΑΠΟ ΚΑΤΑΣΤΗΜΑ)',
  },
  {
    value: 3405,
    label: 'BEVELLERS',
    labelGreek: 'ΛΥΓΙΣΜΑ ΜΕΤΑΛΛΩΝ',
  },
  {
    value: 3406,
    label: 'BLACKSMITH',
    labelGreek: 'ΣΙΔΗΡΟΥΡΓΕΙΟ',
  },
  {
    value: 3407,
    label: 'CALCIFICATION',
    labelGreek: 'ΑΠΟΛΙΘΩΣΗ',
  },
  {
    value: 3408,
    label: 'CAR PARKS',
    labelGreek: 'ΧΩΡΟΙ ΣΤΑΘΜΕΥΣΗΣ',
  },
  {
    value: 3409,
    label: 'CAVITY INSULLATORS',
    labelGreek: 'ΜΟΝΟΤΕΣ ΚΟΥΦΩΜΑΤΩΝ',
  },
  {
    value: 3410,
    label: 'COAL MERCHANTS & DEALERS LOADING & DISCHARGING VESSELS',
    labelGreek: 'ΕΜΠΟΡΟΙ ΑΝΘΡΑΚΑ  ΦΟΡΤΩΣΗ ΚΑΙ ΕΚΦΟΡΤΩΣΗ ΣΚΑΦΩΝ',
  },
  {
    value: 3411,
    label: 'DOCK SERVICES-LOADING & DISCHARGING VESSELS',
    labelGreek: 'ΥΠΗΡΕΣΙΕΣ ΑΠΟΒΑΘΡΑΣ ΦΟΡΤΩΣΗ ΚΑΙ ΕΚΦΟΡΤΩΣΗ ΣΚΑΦΩΝ',
  },
  {
    value: 3412,
    label: 'DYE GRINDING & MIXING WORK',
    labelGreek: 'ΒΑΦΗ ΛΕΙΑΝΣΗΣ & ΕΡΓΑΣΙΕΣ ΑΝΑΜΕΙΞΗΣ',
  },
  {
    value: 3413,
    label: 'FLAX SCUTCH MILLS (MACHINERY IS USED  FOR SCOTCHING)',
    labelGreek: 'ΜΥΛΟΣ ΠΟΤΟΠΟΙΙΑΣ',
  },
  {
    value: 3414,
    label:
      'GASHOLDERS (EXCLUDE. ERECTION  FITTING  OR REPAIR AWAY FROM SHOP OR YARD',
    labelGreek:
      ' : ΦΙΑΛΕΣ ΓΚΑΖΙΟΥ (ΕΞΑΙΡΟΥΝΤΑΙ ΑΝΕΓΕΡΣΗ   ΤΟΠΟΘΕΤΗΣΗ   Η ΕΠΙΣΚΕΥΗ ΜΑΚΡΙΑ ΑΠΟ ΤΟ ΚΑΤΑΣΤΗΜΑ Η ΤΗΝ ΑΥΛΗ',
  },
  {
    value: 3415,
    label: 'FRAMEMAKERS',
    labelGreek: 'KOΡNIZOΠOIOI',
  },
  {
    value: 3416,
    label: 'PATH & PATIO LAYING',
    labelGreek: 'ΕΠΙΣΤΡΩΣΕΙΣ ΜΟΝΟΠΑΤΙΩΝ ΚΑΙ ΕΣΣΩΤΕΡΙΚΩΝ ΑΥΛΩΝ',
  },
  {
    value: 3417,
    label: 'PATIO & DRIVE LAYING',
    labelGreek: 'ΕΠΙΣΤΡΩΣΕΙΣ ΕΣΩΤΕΡΙΚΩΝ ΑΥΛΩΝ ΚΑΙ ΟΔΙΚΩΝ ΟΔΩΝ',
  },
  {
    value: 3418,
    label: 'PERFORATED',
    labelGreek: 'ΔΙΑΤΡΗΤΑ',
  },
  {
    value: 3419,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΗ ΕΡΓΟΥ',
  },
  {
    value: 3420,
    label: 'QUILTS INDUSTRY / CRAFTS',
    labelGreek: 'ΠΑΠΛΩΜΑΤΑ ΒΙΟΜΗΧΑΝΙΑ / ΒΙΟΤΕΧΝΙΑ',
  },
  {
    value: 3421,
    label: 'REARING GROCER',
    labelGreek: 'ΜΠΑΚΑΛΗΣ',
  },
  {
    value: 3422,
    label: 'SCAFFOLDERS',
    labelGreek: 'ΞΥΛΟΥΡΓΟΙ',
  },
  {
    value: 3423,
    label: 'SHED & CARPORT ERECTOR',
    labelGreek: 'ΑΝΟΡΘΩΣΗΣ ΥΠΟΣΤΕΓΩΝ ΣΚΙΑΣ ΚΑΙ ΑΥΤΟΚΙΝΤΗΤΩΝ',
  },
  {
    value: 3424,
    label: 'SHOP FITTERS',
    labelGreek: 'ΣΧΕΔΙΑΣΗ/ΕΦΑΡΜΟΓΗ ΧΩΡΩΝ ΚΑΤΑΣΤΗΜΑΤΩΝ',
  },
  {
    value: 3425,
    label: 'SKIP HIRE',
    labelGreek: 'ΕΝΟΙΚΙΑΣΗ ΚΑΛΑΘΩΝ SKIP',
  },
  {
    value: 3426,
    label: 'HOUSEPAINTER',
    labelGreek: 'ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΗΣ ΟΙΚΙΩΝ',
  },
  {
    value: 3427,
    label: 'PAINTER',
    labelGreek: 'ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΗΣ',
  },
  {
    value: 3428,
    label: 'PAINTER AND DECORATOR',
    labelGreek: 'ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΗΣ ΚΑΙ ΔΙΑΚΟΣΜΗΤΗΣ',
  },
  {
    value: 3429,
    label: 'PAINTERS FURNITURE',
    labelGreek: 'ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΕΣ ΕΠΙΠΛΩΝ',
  },
  {
    value: 3430,
    label: 'PAINTERS HOUSE (GARAGE)',
    labelGreek: 'ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΕΣ ΣΠΙΤΙΟΥ ( ΓΚΑΡΑΖ )',
  },
  {
    value: 3431,
    label: 'BRICK & TILE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΤΟΥΒΛΩΝ ΚΑΙ ΠΛΑΚΑΚΙΩΝ',
  },
  {
    value: 3432,
    label:
      'CABINETMAKERS / CARPENTERS / PELICANS - WITH XYLOSCHISTIKA MACHINES',
    labelGreek:
      ' : ΕΠΙΠΛΟΠΟΙΟΙ / ΞΥΛΟΥΡΓΟΙ / ΠΕΛΕΚΑΝΟΙ - ΜΕ ΜΗΧΑΝΗΜΑΤΑ ΞΥΛΟΣΧΙΣΤΙΚΑ',
  },
  {
    value: 3433,
    label:
      'CABINETMAKERS / CARPENTERS / PELICANS - WITHOUT XYLOSCHISTIKA MACHINES',
    labelGreek:
      ' : ΕΠΙΠΛΟΠΟΙΟΙ / ΞΥΛΟΥΡΓΟΙ / ΠΕΛΕΚΑΝΟΙ - ΧΩΡΙΣ ΜΗΧΑΝΗΜΑΤΑ ΞΥΛΟΣΧΙΣΤΙΚΑ',
  },
  {
    value: 3434,
    label: 'JOINER',
    labelGreek: 'ΞΥΛΟΥΡΓΟΣ',
  },
  {
    value: 3435,
    label: 'KITCHEN  BEDROOM AND BATHROOM FITTER',
    labelGreek: 'ΣΥΝΑΡΜΟΛΟΓΗΤΗΣ ΚΟΥΖΙΝΑΣ   ΥΠΝΟΔΩΜΑΤΙΟΥ ΚΑΙ ΜΠΑΝΙΟΥ',
  },
  {
    value: 3436,
    label: 'PELICANS STANDS PIZZA SELLER',
    labelGreek: 'ΠΛΑΝΩΔΙΟΣ ΠΩΛΗΤΗΣ ΠΙΤΣΑΣ',
  },
  {
    value: 3437,
    label: 'WOODCUTTERS',
    labelGreek: 'ΞΥΛΟΚΟΠΟΣ',
  },
  {
    value: 3438,
    label: 'WOODWORKING MACHINIST',
    labelGreek: 'ΜΗΧΑΝΟΥΡΓΟΣ ΞΥΛΟΥΡΓΙΚΩΝ',
  },
  {
    value: 3439,
    label: 'XYLOSCHISTES',
    labelGreek: 'ΞΥΛΟΣΧΙΣΤΕΣ',
  },
  {
    value: 3440,
    label: 'PETROL STATIONS - FREE LAUNDRY & CAR SERVICE',
    labelGreek: 'ΒΕΝΖΙΝΑΔΙΚΑ - ΔΩΡΕΑΝ ΠΛΥΝΤΗΡΙΟ & ΕΛΕΓΧΟΣ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 3441,
    label: 'PETROL STATIONS - IN WASHING & CAR SERVICE',
    labelGreek: 'ΒΕΝΖΙΝΑΔΙΚΑ -  ΠΛΥΝΤΗΡΙΟ ΑΥΤΟΚΙΝΗΤΩΝ & ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 3442,
    label: 'AIR CONDITIONING TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ ΚΛΙΜΑΤΙΣΜΟΥ',
  },
  {
    value: 3443,
    label: 'AUTOMATIC SPRINKLER SYSTEM CONTRACTORS',
    labelGreek: 'ΕΡΓΟΛΑΒΟΙ ΑΥΤΟΜΑΤΩΝ ΣΥΣΤΗΜΑΤΩΝ SPRINKLER',
  },
  {
    value: 3444,
    label: 'CENTRAL HEATING TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ ΚΕΝΤΡΙΚΗΣ ΘΕΡΜΑΝΣΗΣ',
  },
  {
    value: 3445,
    label: 'HEATING',
    labelGreek: 'ΘΕΡΜΑΝΣΗ',
  },
  {
    value: 3446,
    label: 'HEATING AND VENTILATION CONTRACTOR - COMMERCIAL',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ ΘΕΡΜΑΝΣΗΣ ΚΑΙ ΑΕΡΙΣΜΟΥ - ΕΜΠΟΡΙΚΟ',
  },
  {
    value: 3447,
    label: 'HEATING AND VENTILATION CONTRACTOR - DOMESTIC',
    labelGreek: 'ΕΡΓΟΛΑΒΟΣ ΘΕΡΜΑΝΣΗΣ ΚΑΙ ΑΕΡΙΣΜΟΥ  - ΟΙΚΙΑΚΑ',
  },
  {
    value: 3448,
    label: 'PLUMBING',
    labelGreek: 'ΥΔΡΑΥΛΙΚΑ',
  },
  {
    value: 3449,
    label: 'PLUMBING & HEATING CONTRACTOR - COMMERCIAL',
    labelGreek: 'ΥΔΡΑΥΛΙΚΑ & ΘΕΡΜΑΝΣΗ ΕΡΓΟΛΑΒΟΣ - ΕΜΠΟΡΙΚΑ',
  },
  {
    value: 3450,
    label: 'PLUMBING & HEATING CONTRACTOR - DOMESTIC',
    labelGreek: 'ΥΔΡΑΥΛΙΚΑ & ΘΕΡΜΑΝΣΗ ΕΡΓΟΛΑΒΟΣ - ΟΙΚΙΑΚΑ',
  },
  {
    value: 3451,
    label: 'PLUMBING  HEATING AND VENTILATION CONTRACTOR',
    labelGreek: 'ΥΔΡΑΥΛΙΚΑ  ΘΕΡΜΑΝΣΗΣ ΚΑΙ ΕΞΑΕΡΙΣΜΟΥ ΕΡΓΟΛΑΒΟΣ',
  },
  {
    value: 3452,
    label: 'VENTILATION & PLUMBING CONTRACTOR - COMMERCIAL',
    labelGreek: 'ΕΞΑΕΡΙΣΜΟΣ & ΥΔΡΑΥΛΙΚΑ ΕΡΓΟΛΑΒΟΣ - ΕΜΠΟΡΙΚΗ',
  },
  {
    value: 3453,
    label: 'WATER SUPPLY UNDERTAKING',
    labelGreek: 'ΕΠΙΧΕΙΡΗΣΕΙΣ ΥΔΡΕΥΣΗΣ',
  },
  {
    value: 3454,
    label: 'ROAD CONTRACTORS',
    labelGreek: 'ΕΡΓΟΛΑΒΟΙ ΟΔΟΠΟΙΙΑΣ',
  },
  {
    value: 3455,
    label: 'ROAD MAKING',
    labelGreek: 'ΟΔΟΠΟΙΟΙ',
  },
  {
    value: 3456,
    label: 'ROAD PAVING',
    labelGreek: 'ΟΔΟΣΤΡΩΜΑ',
  },
  {
    value: 3457,
    label: 'ROOFERS EXCLUDING THE APPLICATION OF HEAT',
    labelGreek: 'ΚΕΡΑΜΙΔΑΔΕΣ ΕΚΤΟΣ ΤΗΝ ΕΦΑΡΜΟΓΗ ΘΕΡΜΟΤΗΤΑΣ',
  },
  {
    value: 3458,
    label: 'ROOFERS INCLUDING THE APPLICATION OF HEAT',
    labelGreek: 'ΚΕΡΑΜΙΔΑΔΕΣ ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΘΕΡΜΟΤΗΤΑΣ',
  },
  {
    value: 3459,
    label: 'ROOFERS UP TO 2 FLOORS',
    labelGreek: 'ΚΕΡΑΜΙΔΑΔΕΣ ΕΩΣ 2 ΟΡΟΦΟΥΣ',
  },
  {
    value: 3460,
    label: 'SHIP BUILDERS PAINTERS AND REPAIRERS',
    labelGreek: 'ΝΑΥΠΗΓΟΙ  ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΕΣ ΚΑΙ ΕΠΙΣΚΕΥΑΣΤΕΣ',
  },
  {
    value: 3461,
    label: 'YACHT  BUILDER  PAINTER  REPAIRERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΣΚΑΦΟΥΣ   ΕΛΑΙΟΧΡΩΜΑΤΙΣΤΗΣ  ΕΠΙΣΚΕΥΑΣΤΗΣ',
  },
  {
    value: 3462,
    label: 'SWIMMING POOLS',
    labelGreek: 'ΠΙΣΙΝΕΣ',
  },
  {
    value: 3463,
    label: 'AERIAL ERECTOR',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΗ ΚΕΡΑΙΩΝ',
  },
  {
    value: 3464,
    label: 'BOILER MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΛΕΒΗΤΑ',
  },
  {
    value: 3465,
    label: 'CABLE LAYING',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΗ ΚΑΛΩΔΙΩΝ',
  },
  {
    value: 3466,
    label: 'COMMUNICATIONS TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ ΕΠΙΚΟΙΝΩΝΙΩΝ',
  },
  {
    value: 3467,
    label: 'CONSERVATORY INSTALLERS',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΤΕΣ ΩΔΕΙΟΥ',
  },
  {
    value: 3468,
    label: 'LITHOGRAPHERS',
    labelGreek: 'ΛΙΘΟΓΡΑΦΟΙ',
  },
  {
    value: 3469,
    label: 'MARINERS',
    labelGreek: 'ΝΑΥΤΙΚΟΙ',
  },
  {
    value: 3470,
    label: 'MARMAROPOIOI-MOSAIC',
    labelGreek: 'ΜΑΡΜΑΡΟΠΟΙΟΙ ΜΩΣΑΙΚΑ',
  },
  {
    value: 3471,
    label: 'METAL POLISHERS & PLATTERS',
    labelGreek: 'ΓΥΑΛΙΣΜΑ ΜΕΤΑΛΛΩΝ & ΠΙΑΤΕΛΛΩΝ',
  },
  {
    value: 3472,
    label: 'OF GLASS CUTTERS',
    labelGreek: 'ΚΟΠΗ ΓΥΑΛΙΟΥ',
  },
  {
    value: 3473,
    label: 'SATELLITE SYSTEMS / ANTENNAS - INSTALLATION',
    labelGreek: 'ΔΟΡΥΦΟΡΙΚΑ ΣΥΣΤΗΜΑΤΑ / ΚΕΡΑΙΕΣ - ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3474,
    label: 'SOLAR PANEL INSTALLATION',
    labelGreek: 'ΗΛΙΑΚΑ ΠΑΝΕΛ ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3475,
    label: 'SOUND ENGINEERS',
    labelGreek: 'ΗΧΟΛΗΠΤΕΣ',
  },
  {
    value: 3476,
    label: 'STONEMASON',
    labelGreek: 'ΛΙΘΟΞΟΟΣ',
  },
  {
    value: 3477,
    label: 'TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ',
  },
  {
    value: 3478,
    label: 'TECHNICIANS/TELECOMMUNICATIONS',
    labelGreek: 'ΤΕΧΝΙΚΟΙ / ΤΗΛΕΠΙΚΟΙΝΩΝΙΕΣ',
  },
  {
    value: 3479,
    label: 'TV ANTENNAS - INSTALLATION',
    labelGreek: 'ΚΕΡΑΙΕΣ ΤΗΛΕΟΡΑΣΗΣ - ΕΓΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 3480,
    label: 'VENTILATION',
    labelGreek: 'ΕΞΑΕΡΙΣΜΟΣ',
  },
  {
    value: 3481,
    label: 'WELDERS',
    labelGreek: 'ΣΥΓΚΟΛΛΗΤΕΣ',
  },
  {
    value: 3482,
    label: 'X-RAY TECHNICIAN',
    labelGreek: 'ΤΕΧΝΙΚΟΣ X-RAY ',
  },
  {
    value: 3483,
    label: 'FOREMEN / CARETAKERS',
    labelGreek: 'ΕΡΓΟΔΗΓΟΥΣ / ΕΠΙΣΤΑΤΕΣ',
  },
  {
    value: 3484,
    label: 'GROUNDWORK',
    labelGreek: 'ΔΗΜΙΟΥΡΓΙΑ ΘΕΜΕΛΙΩΝ',
  },
  {
    value: 3485,
    label: 'UNSKILLED WORKERS - MACHINE OPERATORS',
    labelGreek: 'ΑΝΕΙΔΙΚΕΥΤΟΙ ΕΡΓΑΤΕΣ - ΧΕΙΡΙΣΤΕΣ ΜΗΧΑΝΩΝ',
  },
  {
    value: 3486,
    label: 'UNSKILLED WORKERS - WITHOUT THE USE OF MACHINERY',
    labelGreek: 'ΑΝΕΙΔΙΚΕΥΤΟΙ ΕΡΓΑΤΕΣ - ΧΩΡΙΣ ΤΗ ΧΡΗΣΗ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 3487,
    label: 'VOTHROKATHARISTES',
    labelGreek: 'ΒΟΘΡΟΚΑΘΑΡΙΣΤΕΣ',
  },
  {
    value: 3488,
    label: 'WAGON BUILDERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΑΜΑΞΩΝ',
  },
  {
    value: 3489,
    label: 'AGENTS',
    labelGreek: 'ΠΡΑΚΤΟΡΕΣ',
  },
  {
    value: 3490,
    label: 'EMPLOYMENT AGENCY',
    labelGreek: 'ΠΡΑΚΤΟΡΕΙΟ ΕΞΕΥΡΕΣΕΩΣ ΕΡΓΑΣΕΙΑΣ',
  },
  {
    value: 3491,
    label: 'MARINE/SHIPPING AGENT - OFFICE WORK',
    labelGreek: 'ΝΑΥΤΙΛΙΑΚΟΣ ΠΡΑΚΤΟΡΑΣ/ ΕΡΓΑΣΙΑ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3492,
    label: 'RECRUITMENT AGENCY',
    labelGreek: 'ΠΡΑΚΤΟΡΑΣ ΕΞΕΥΡΕΣΗΣ ΕΡΓΑΣΙΑΣ',
  },
  {
    value: 3493,
    label: 'ANALYST',
    labelGreek: 'ΑΝΑΛΥΤΗΣ',
  },
  {
    value: 3494,
    label: 'STATISTICIAN',
    labelGreek: 'ΣΤΑΤΙΣΤΙΚΟΛΟΓΟΣ',
  },
  {
    value: 3495,
    label: 'OFFICE ASSISTANT',
    labelGreek: 'ΒΟΗΘΟΣ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3496,
    label: 'PERSONAL ASSISTANCE',
    labelGreek: 'ΠΡΟΣΩΠΙΚΟΣ ΒΟΗΘΟΣ',
  },
  {
    value: 3497,
    label: 'AUCTIONEERS',
    labelGreek: 'ΔΗΜΟΠΡΑΤΗΣ',
  },
  {
    value: 3498,
    label: 'ADMINISTRATOR',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΤΗΣ',
  },
  {
    value: 3499,
    label: 'CLERICAL/ADMIN STAFF',
    labelGreek: 'ΓΡΑΦΕΙΑΚΟ / ΓΡΑΜΜΑΤΕΙΑΚΟ ΠΡΟΣΩΠΙΚΟ',
  },
  {
    value: 3500,
    label: 'CLERK',
    labelGreek: 'ΓΡΑΦΕΑΣ',
  },
  {
    value: 3501,
    label: 'GRAPHICS / OFFICE STAFF',
    labelGreek: 'ΓΡΑΦΙΚΑ / ΠΡΟΣΩΠΙΚΟ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3502,
    label: 'BUSINESS CONSULTANT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΠΙΧΕΙΡΗΣΕΩΝ',
  },
  {
    value: 3503,
    label: 'CONSULTANT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3504,
    label: 'CONSULTANT ACOUSTIC',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΚΟΥΣΤΙΚΗΣ',
  },
  {
    value: 3505,
    label: 'CONSULTANT ADVERTISING & MARKETING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΑΡΚΕΤΙΝΓΚ & ΔΙΑΦΗΜΙΣΗ',
  },
  {
    value: 3506,
    label: 'CONSULTANT AGRICULTURE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΓΕΩΡΓΙΑΣ',
  },
  {
    value: 3507,
    label: 'CONSULTANT AIR CONDITIONING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΚΛΙΜΑΤΙΣΜΟΥ',
  },
  {
    value: 3508,
    label: 'CONSULTANT AUTOMATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΥΤΟΜΑΤΙΣΜΩΝ',
  },
  {
    value: 3509,
    label: 'CONSULTANT AVIATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΕΡΟΠΟΡΙΑΣ',
  },
  {
    value: 3510,
    label: 'CONSULTANT BRAND & DESIGN',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΣΧΕΔΙΑΣΜΟΥ ΣΗΜΑΤΟΣ (BRAND)',
  },
  {
    value: 3511,
    label: 'CONSULTANT BREWERS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΟΤΟΠΟΙΙΑΣ',
  },
  {
    value: 3512,
    label: 'CONSULTANT BUILDING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΟΙΚΟΔΟΜΩΝ',
  },
  {
    value: 3513,
    label: 'CONSULTANT BUSINESS & MANAGEMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΟΙΚΗΣΗΣ ΕΠΙΧΕΙΡΗΣΕΩΝ',
  },
  {
    value: 3514,
    label: 'CONSULTANT COMPUTER HARDWARE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ  ΥΛΙΚΟΥ ΠΛΗΡΟΦΟΡΙΚΗΣ',
  },
  {
    value: 3515,
    label: 'CONSULTANT COMPUTER SOFTWARE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΛΟΓΙΣΜΙΚΟΥ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3516,
    label: 'CONSULTANT EDUCATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΚΠΑΙΔΕΥΣΗΣ',
  },
  {
    value: 3517,
    label: 'CONSULTANT ELECTRONICS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΗΛΕΚΤΡΟΝΙΚΩΝ',
  },
  {
    value: 3518,
    label: 'CONSULTANT EMPLOYMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΠΑΣΧΟΛΗΣΗΣ',
  },
  {
    value: 3519,
    label: 'CONSULTANT ENERGY (INCL CARBON CREDIT TRADING)',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΝΕΡΓΕΙΑΣ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΟΥ ΕΜΠΟΡΙΟΥ ΑΝΘΡΑΚΑ)',
  },
  {
    value: 3520,
    label: 'CONSULTANT ENGINEER',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3521,
    label: 'CONSULTANT ENVIRONMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΕΡΙΒΑΛΛΟΝΤΟΣ',
  },
  {
    value: 3522,
    label: 'CONSULTANT FINANCIAL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΟΙΚΟΝΟΜΙΚΩΝ',
  },
  {
    value: 3523,
    label: 'CONSULTANT FIRE SAFETY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΥΡΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 3524,
    label: 'CONSULTANT FISHERIES',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΛΙΕΙΑΣ',
  },
  {
    value: 3525,
    label: 'CONSULTANT FOOD SAFETY & HYGIENE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΣΦΑΛΕΙΑΣ ΤΡΟΦΙΜΩΝ & ΥΓΙΕΙΝΗ',
  },
  {
    value: 3526,
    label: 'CONSULTANT FORESTRY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΑΣΩΝ',
  },
  {
    value: 3527,
    label: 'CONSULTANT HAIR AND BEAUTY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΑΛΛΙΑ ΚΑΙ ΤΗΝ ΟΜΟΡΦΙΑ',
  },
  {
    value: 3528,
    label: 'CONSULTANT HEALTH AND SAFETY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΥΓΕΙΑΣ ΚΑΙ ΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 3529,
    label: 'CONSULTANT HEATING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΘΕΡΜΑΝΣΗΣ',
  },
  {
    value: 3530,
    label: 'CONSULTANT HORTICULTURE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΦΥΤΟΚΟΜΙΑΣ',
  },
  {
    value: 3531,
    label: 'CONSULTANT HUMAN RESOURCES',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΝΘΡΩΠΙΝΟΥ ΔΥΝΑΜΙΚΟΥ',
  },
  {
    value: 3532,
    label: 'CONSULTANT IMAGE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΙΚΟΝΑΣ',
  },
  {
    value: 3533,
    label: 'CONSULTANT IMMIGRATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΕΤΑΝΑΣΤΕΥΣΗΣ',
  },
  {
    value: 3534,
    label: 'CONSULTANT IMPORT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΙΣΑΓΩΓΗΣ',
  },
  {
    value: 3535,
    label: 'CONSULTANT INDUSTRIAL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΒΙΟΜΗΧΑΝΙΩΝ',
  },
  {
    value: 3536,
    label: 'CONSULTANT INSURANCE',
    labelGreek: 'ΑΣΦΑΛΙΣΤΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3537,
    label: 'CONSULTANT INTERNET',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΑΔΙΚΤΥΟΥ',
  },
  {
    value: 3538,
    label: 'CONSULTANT INVESTMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΠΕΝΔΥΣΕΩΝ',
  },
  {
    value: 3539,
    label: 'CONSULTANT LEGAL',
    labelGreek: 'ΝΟΜΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3540,
    label: 'CONSULTANT LIFE AND PENSIONS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΖΩΗΣ ΚΑΙ ΣΥΝΤΑΞΕΩΝ',
  },
  {
    value: 3541,
    label: 'CONSULTANT LIFESTYLE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΤΡΟΠΟΥ ΖΩΗΣ',
  },
  {
    value: 3542,
    label: 'CONSULTANT LIGHTING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΦΩΤΙΣΜΟΥ',
  },
  {
    value: 3543,
    label: 'CONSULTANT MAGNET',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΡΟΣΕΛΚΥΣΗ ΠΕΛΑΤΩΝ',
  },
  {
    value: 3544,
    label: 'CONSULTANT MANAGEMENT & BUSINESS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΟΙΚΗΣΗΣ & ΕΠΙΧΕΙΡΗΣΕΙΣ',
  },
  {
    value: 3545,
    label: 'CONSULTANT MARINE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΝΑΥΤΙΛΙΑΚΑ',
  },
  {
    value: 3546,
    label: 'CONSULTANT MARKET RESEARCH',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΡΕΥΝΑΣ ΑΓΟΡΑΣ',
  },
  {
    value: 3547,
    label: 'CONSULTANT MARKETING AND ADVERTISING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΑΡΚΕΤΙΝΓΚ ΚΑΙ ΔΙΑΦΗΜΙΣΗΣ',
  },
  {
    value: 3548,
    label: 'CONSULTANT NOISE AND VIBRATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΘΟΡΥΒΟΥ ΚΑΙ ΤΟΥΣ ΚΡΑΔΑΣΜΩΝ',
  },
  {
    value: 3549,
    label: 'CONSULTANT OCCUPATIONAL HEALTH',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΥΓΙΕΙΝΗΣ',
  },
  {
    value: 3550,
    label: 'CONSULTANT PERSONNEL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΙ ΠΡΟΣΩΠΙΚΟΥ',
  },
  {
    value: 3551,
    label: 'CONSULTANT PHARMACEUTICAL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΦΑΡΜΑΚΕΥΤΙΚΩΝ',
  },
  {
    value: 3552,
    label: 'CONSULTANT PIPE LINE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΣΥΣΤΗΜΑΤΩΝ ΑΓΩΓΩΝ',
  },
  {
    value: 3553,
    label: 'CONSULTANT PLASTICS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΛΑΣΤΙΚΩΝ',
  },
  {
    value: 3554,
    label: 'CONSULTANT POLITICAL',
    labelGreek: 'ΠΟΛΙΤΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3555,
    label: 'CONSULTANT PRINTING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΚΤΥΠΩΣΕΩΝ',
  },
  {
    value: 3556,
    label: 'CONSULTANT PROCUREMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΗΜΟΣΙΩΝ ΣΥΜΒΑΣΕΩΝ',
  },
  {
    value: 3557,
    label: 'CONSULTANT PUBLIC RELATIONS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΗΜΟΣΙΩΝ ΣΧΕΣΕΩΝ',
  },
  {
    value: 3558,
    label: 'CONSULTANT PUBLICITY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΗΜΟΣΙΟΤΗΤΑΣ',
  },
  {
    value: 3559,
    label: 'CONSULTANT REFUSE TREATMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΠΕΞΕΡΓΑΣΙΑΣ ΑΠΟΡΡΙΜΜΑΤΩΝ',
  },
  {
    value: 3560,
    label: 'CONSULTANT REMOTE CONTROL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΤΗΛΕΧΕΙΡΙΣΜΟΥ',
  },
  {
    value: 3561,
    label: 'CONSULTANT RESEARCH',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΡΕΥΝΩΝ',
  },
  {
    value: 3562,
    label: 'CONSULTANT RISK MANAGEMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΑΧΕΙΡΙΣΗΣ ΚΙΝΔΥΝΩΝ',
  },
  {
    value: 3563,
    label: 'CONSULTANT SCIENCE & RESEARCH',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΠΙΣΤΗΜΩΝ ΚΑΙ ΕΡΕΥΝΩΝ',
  },
  {
    value: 3564,
    label: 'CONSULTANT SECURITY',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 3565,
    label: 'CONSULTANT TAX',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΦΟΡΟΛΟΓΙΑΣ',
  },
  {
    value: 3566,
    label: 'CONSULTANT TEXTILES',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΚΛΩΣΤΟΫΦΑΝΤΟΥΡΓΙΑΣ',
  },
  {
    value: 3567,
    label: 'CONSULTANT THERMAL & INSULATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΘΕΡΜΑΝΣΗΣ & ΜΟΝΩΣΗΣ',
  },
  {
    value: 3568,
    label: 'CONSULTANT TRAFFIC',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΚΥΚΛΟΦΟΡΙΑΣ',
  },
  {
    value: 3569,
    label: 'CONSULTANT TRAINING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΚΠΑΙΔΕΥΣΗΣ',
  },
  {
    value: 3570,
    label: 'CONSULTANT TRANSPORT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΜΕΤΑΦΟΡΩΝ',
  },
  {
    value: 3571,
    label: 'CONSULTANT TRAVEL',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΤΑΞΙΔΙΟΥ',
  },
  {
    value: 3572,
    label: 'CONSULTANT WASTE (EXCL BROKING)',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΠΟΒΛΗΤΩΝ (ΕΚΤΟΣ ΑΠΟ ΜΕΣΙΤΕΣ)',
  },
  {
    value: 3573,
    label: 'CONSULTANT WASTE (INCL BROKING)',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΠΟΒΛΗΤΩΝ (ΣΥΜΠ ΜΕΣΙΤΕΣ)',
  },
  {
    value: 3574,
    label: 'CONSULTANT WINDOW',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΑΡΑΘΥΡΩΝ',
  },
  {
    value: 3575,
    label: 'MANAGEMENT CONSULTANT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΔΙΟΙΚΗΣΗΣ',
  },
  {
    value: 3576,
    label: 'CONSULTANT DRAINAGE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΑΠΟΧΕΤΕΥΣΕΩΝ',
  },
  {
    value: 3577,
    label: 'CONSULTANT EXPORT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΞΑΓΩΓΩΝ',
  },
  {
    value: 3578,
    label: 'CONSULTANT FIRE PROOFING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΥΡΑΝΤΟΧΗΣ',
  },
  {
    value: 3579,
    label: 'CONSULTANT HEATING COSTS',
    labelGreek: 'ΣΥΜΒΟΥΛΩΝ ΚΟΣΤΟΥΣ  ΘΕΡΜΑΝΣΗΣ',
  },
  {
    value: 3580,
    label: 'CONSULTANT PLANNING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΥ',
  },
  {
    value: 3581,
    label: 'CONSULTANT REGENERATION',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΠΑΝΑΛΕΙΤΟΥΡΓΕΙΑΣ',
  },
  {
    value: 3582,
    label: 'CONSULTANT SEWAGE',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΛΥΜΑΤΩΝ',
  },
  {
    value: 3583,
    label: 'CONSULTANT TELECOMMUNICATIONS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΩΝ',
  },
  {
    value: 3584,
    label: 'CONSULTANT DESIGN',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΣΧΕΔΙΑΣΜΟΥ',
  },
  {
    value: 3585,
    label: 'CONSULTANT HOTEL AND CATERING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΞΕΝΟΔΟΧΕΙΩΝ ΚΑΙ ΕΣΤΙΑΣΗΣ',
  },
  {
    value: 3586,
    label: 'SURGEON (CONSULTANT)',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ - ΧΕΙΡΟΥΡΓΟΣ',
  },
  {
    value: 3587,
    label: 'CUSTOMER SERVICE ADVISORS',
    labelGreek: 'ΣΥΜΒΟΥΛΟΙ ΕΞΥΠΗΡΕΤΗΣΗΣ ΠΕΛΑΤΩΝ',
  },
  {
    value: 3588,
    label: 'CUSTOMER SERVICE SUPERVISOR',
    labelGreek: 'ΠΡΟΙΣΤΑΜΕΝΟΣ ΕΞΥΠΗΡΕΤΗΣΗΣ ΠΕΛΑΤΩΝ',
  },
  {
    value: 3589,
    label: 'BATHROOM AND KITCHEN DESIGN',
    labelGreek: 'ΜΠΑΝΙΟ ΚΑΙ ΚΟΥΖΙΝΑ ΣΧΕΔΙΑΣΜΟΣ',
  },
  {
    value: 3590,
    label: 'INTERIOR DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΜΟΣ ΚΑΙ ΔΙΑΚΟΣΜΗΣΗ ΕΣΩΤΕΡΙΚΩΝ ΧΩΡΩΝ',
  },
  {
    value: 3591,
    label: 'CONTROLLER',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ',
  },
  {
    value: 3592,
    label: 'INTERNAL CONTROLLER',
    labelGreek: 'ΕΣΩΤΕΡΙΚΟΣ ΕΛΕΓΚΤΗΣ',
  },
  {
    value: 3593,
    label: 'FUNERAL DIRECTOR',
    labelGreek: 'ΟΡΓΑΝΩΤΗΣ ΚΗΔΕΙΩΝ',
  },
  {
    value: 3594,
    label: 'BRANCH MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΥΠΟΚΑΤΑΣΤΗΜΑΤΟΣ',
  },
  {
    value: 3595,
    label: 'CEO (CHIEF EXECUTIVE OFFICER)',
    labelGreek: 'ΔΙΕΥΘΥΝΩΝ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3596,
    label: 'CONTRACT MANAGER',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΤΗΣ ΣΥΜΒΑΣΕΩΝ',
  },
  {
    value: 3597,
    label: 'ENTREPRENEUR',
    labelGreek: 'ΕΠΙΧΕΙΡΗΜΑΤΙΑΣ',
  },
  {
    value: 3598,
    label: 'EXECUTIVE',
    labelGreek: 'ΕΚΤΕΛΕΣΤΙΚΟΣ',
  },
  {
    value: 3599,
    label: 'FLOOR MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΟΡΟΦΟΥ',
  },
  {
    value: 3600,
    label: 'HOUSING AUTHORITY OR REAL ESTATE MANAGER',
    labelGreek: 'ΑΡΧΗ ΣΤΕΓΑΣΗΣ Ή ΔΙΕΥΘΥΝΤΗΣ ΚΤΗΜΑΤΟΜΕΣΙΤΙΚΩΝ',
  },
  {
    value: 3601,
    label: 'INTERIM MANAGEMENT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΕΝΔΙΑΜΕΣΗΣ ΚΑΤΑΣΤΑΣΗΣ',
  },
  {
    value: 3602,
    label: 'MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ',
  },
  {
    value: 3603,
    label: 'MANAGER (OFFSHORE)',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ (OFFSHORE)',
  },
  {
    value: 3604,
    label: 'MANAGER (UNDERWRITER)',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ (ΑΝΑΛΗΨΗΣ ΚΙΝΔΥΝΩΝ)',
  },
  {
    value: 3605,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΕΡΓΟΥ',
  },
  {
    value: 3606,
    label: 'CORPORATE EXECUTIVE OFFICER',
    labelGreek: 'ΕΤΑΙΡΙΚΟΣ ΔΙΕΥΘΥΝΩΝ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3607,
    label: 'ELECTRONIC - OFFICE WORK',
    labelGreek: 'ΗΛΕΚΤΡΟΝΙΚΑ - ΕΡΓΑΣΙΑ  ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3608,
    label: 'ENVIRONMENTAL HEALTH OFFICERS',
    labelGreek: 'ΑΞΙΩΜΑΤΟΥΧΟΙ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗΣ ΥΓΕΙΑΣ',
  },
  {
    value: 3609,
    label: 'MAIL ASSISTANT',
    labelGreek: 'ΒΟΗΘΟΣ ΤΑΧΥΔΡΟΜΕΙΟΥ',
  },
  {
    value: 3610,
    label: 'COMPUTER OPERATORS',
    labelGreek: 'ΧΕΙΡΙΣΤΕΣ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΠΟΛΟΓΙΣΤΩΝ',
  },
  {
    value: 3611,
    label: 'OPERATOR',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ',
  },
  {
    value: 3612,
    label: 'SWITCHBOARD OPERATOR',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ ΤΗΛΕΦΩΝΙΚΟΥ ΠΙΝΑΚΑ',
  },
  {
    value: 3613,
    label: 'TELEPHONE OPERATOR',
    labelGreek: 'ΤΗΛΕΦΩΝΗΤΗΣ',
  },
  {
    value: 3614,
    label: 'TOUR QUIDE OPERATOR',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ ΞΕΝΑΓΗΣΗΣ',
  },
  {
    value: 3615,
    label: 'PRIVATE INVESTIGATOR',
    labelGreek: 'ΙΔΙΩΤΙΚΟΣ ΕΡΕΥΝΗΤΗΣ',
  },
  {
    value: 3616,
    label: 'SECRETARY',
    labelGreek: 'ΓΡΑΜΜΑΤΕΑΣ',
  },
  {
    value: 3617,
    label: 'SECRETARY/CLERICAL STAFF',
    labelGreek: 'ΓΡΑΜΜΑΤΕΑΣ / ΓΡΑΦΕΙΑΚΟ ΠΡΟΣΩΠΙΚΟ',
  },
  {
    value: 3618,
    label: 'INTERPRETER',
    labelGreek: 'ΔΙΕΡΜΗΝΕΑΣ',
  },
  {
    value: 3619,
    label: 'TRANSLATOR',
    labelGreek: 'ΜΕΤΑΦΡΑΣΤΗΣ',
  },
  {
    value: 3620,
    label: 'EDUCATIONAL REFORMER',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΜΕΤΑΡΡΥΘΜΙΣΤΗΣ',
  },
  {
    value: 3621,
    label: 'EDUCATOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ',
  },
  {
    value: 3622,
    label: 'EDUCATOR OF THE HANDICAPPED',
    labelGreek: 'ΕΚΠΑΙΔΕΥΣΗ ΑΤΟΜΩΝ ΜΕ ΑΝΑΠΗΡΙΑ',
  },
  {
    value: 3623,
    label: 'HEADMASTER (ALSO HEADMISTRESS)',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ / ΔΙΕΥΘΥΝΤΡΙΑ',
  },
  {
    value: 3624,
    label: 'HISTORIAN',
    labelGreek: 'ΙΣΤΟΡΙΟΓΡΑΦΟΣ',
  },
  {
    value: 3625,
    label: 'INSTRUCTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ',
  },
  {
    value: 3626,
    label: 'LECTURER',
    labelGreek: 'ΛΕΚΤΟΡΑΣ',
  },
  {
    value: 3627,
    label: 'LITERARY SCHOLAR',
    labelGreek: 'ΜΕΛΕΤΗΤΗΣ ΛΟΓΟΤΕΧΝΕΙΑΣ',
  },
  {
    value: 3628,
    label: 'DRIVING INSTRUCTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΟΔΗΓΗΣΗΣ',
  },
  {
    value: 3629,
    label: 'PHYSICAL EDUCATOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΦΥΣΙΚΗΣ ΑΓΩΓΗΣ',
  },
  {
    value: 3630,
    label: 'PRINCIPAL',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΕΚΠΑΙΔΕΥΤΙΚΟΥ ΚΕΝΤΡΟΥ',
  },
  {
    value: 3631,
    label: 'PROFESSOR',
    labelGreek: 'ΚΑΘΗΓΗΤΗΣ',
  },
  {
    value: 3632,
    label: 'RECTOR',
    labelGreek: 'ΠΡΥΤΑΝΗΣ',
  },
  {
    value: 3633,
    label: 'RELIGIOUS EDUCATOR',
    labelGreek: 'ΚΑΘΗΓΗΤΗΣ ΘΡΗΣΚΕΥΤΙΚΩΝ',
  },
  {
    value: 3634,
    label: 'SCHOLAR',
    labelGreek: 'ΛΟΓΙΟΣ',
  },
  {
    value: 3635,
    label: 'SCHOOL PRINCIPAL',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΣΧΟΛΕΙΟΥ',
  },
  {
    value: 3636,
    label: 'SCHOOL SUPERINTENDENT',
    labelGreek: 'ΠΑΙΔΟΝΟΜΟΣ',
  },
  {
    value: 3637,
    label: 'SPEECH',
    labelGreek: 'ΟΜΙΛΙΤΗΣ',
  },
  {
    value: 3638,
    label: 'STUDENT',
    labelGreek: 'ΦΟΙΤΗΤΗΣ/ΜΑΘΗΤΗΣ',
  },
  {
    value: 3639,
    label: 'SUNDAY SCHOOL TEACHER',
    labelGreek: 'ΔΑΣΚΑΛΟΣ ΚΑΤΗΧΗΤΙΚΟΥ ΣΧΟΛΕΙΟΥ',
  },
  {
    value: 3640,
    label: 'TEACHERS',
    labelGreek: 'ΔΑΣΚΑΛΟΣ',
  },
  {
    value: 3641,
    label: 'SCHOOLS/COLLEGES VETERINARY',
    labelGreek: 'ΣΧΟΛΕΙΑ / ΣΧΟΛΕΣ ΚΤΗΝΙΑΤΡΙΚΗΣ',
  },
  {
    value: 3642,
    label: 'PHILOLOGIST',
    labelGreek: 'ΦΙΛΟΛΟΓΟΣ',
  },
  {
    value: 3643,
    label: 'TEACHERS - BALLET / DANCE / RIDING / OTHER SPORTS',
    labelGreek: 'ΔΑΣΚΑΛΟΙ - ΜΠΑΛΕΤΟΥ / ΧΟΡΟΥ / ΠΟΔΗΛΑΣΙΑΣ / ΑΛΛΩΝ ΑΘΛΗΜΑΤΩΝ',
  },
  {
    value: 3644,
    label: 'TEACHERS - EXCEPT BALLET / DANCE / RIDING / OTHER SPORTS',
    labelGreek:
      ' : ΔΑΣΚΑΛΟΙ - ΕΚΤΟΣ ΜΠΑΛΕΤΟ / ΧΟΡΟΣ / ΠΟΔΗΛΑΣΙΑ / ΑΛΛΑ ΑΘΛΗΜΑΤΑ',
  },
  {
    value: 3645,
    label: 'TUTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ',
  },
  {
    value: 3646,
    label: 'MATHEMATICIAN',
    labelGreek: 'ΜΑΘΗΜΑΤΙΚΟΣ',
  },
  {
    value: 3647,
    label: 'PHILOSOPHER',
    labelGreek: 'ΦΙΛΟΣΟΦΟΣ',
  },
  {
    value: 3648,
    label: 'BISHOP',
    labelGreek: 'ΕΠΙΣΚΟΠΟΣ',
  },
  {
    value: 3649,
    label: 'CHURCH USHER',
    labelGreek: 'ΒΟΗΘΟΣ ΣΕ ΕΚΚΛΗΣΙΑ',
  },
  {
    value: 3650,
    label: 'CHURCH WORKER',
    labelGreek: 'ΕΡΓΑΤΗΣ ΣΕ ΕΚΚΛΗΣΙΑ',
  },
  {
    value: 3651,
    label: 'CLERGY',
    labelGreek: 'ΚΛΗΡΟΣ',
  },
  {
    value: 3652,
    label: 'DEACON',
    labelGreek: 'ΔΙΑΚΟΝΟΣ',
  },
  {
    value: 3653,
    label: 'EVANGELIST',
    labelGreek: 'ΕΥΑΓΓΕΛΙΣΤΗΣ',
  },
  {
    value: 3654,
    label: 'MINISTER',
    labelGreek: 'ΥΠΟΥΡΓΟΣ',
  },
  {
    value: 3655,
    label: 'YMCA OR YWCA WORKER',
    labelGreek: 'ΨΑΛΤΗΣ',
  },
  {
    value: 3656,
    label: 'MISSIONARY SOCIETY LEADER',
    labelGreek: 'ΑΡΧΗΓΟΣ ΙΕΡΑΠΟΣΤΟΛΙΚΟΥ ΣΥΝΔΕΣΜΟΥ',
  },
  {
    value: 3657,
    label: 'MONK',
    labelGreek: 'ΜΟΝΑΧΟΣ',
  },
  { value: 3658, label: 'NUN', labelGreek: 'ΜΟΝΑΧΗ' },
  {
    value: 3659,
    label: 'PREACHER',
    labelGreek: 'ΙΕΡΟΚΗΡΥΚΑΣ',
  },
  {
    value: 3660,
    label: 'PRIEST',
    labelGreek: 'ΙΕΡΕΑΣ',
  },
  {
    value: 3661,
    label: 'RABBI',
    labelGreek: 'ΡΑΒΒΙΝΟΣ',
  },
  {
    value: 3662,
    label: 'RELIGION FOUNDER OR LEADER',
    labelGreek: 'ΙΔΡΥΤΗΣ/ΑΡΧΗΓΟΣ ΘΡΗΣΚΕΙΑΣ',
  },
  {
    value: 3663,
    label: 'THEOLOGIAN',
    labelGreek: 'ΘΕΟΛΟΓΟΣ',
  },
  {
    value: 3664,
    label: 'VICAR',
    labelGreek: 'ΕΦΗΜΕΡΙΟΣ',
  },
  {
    value: 3665,
    label: 'ARCHAEOLOGISTS / ASSISTANTS ARCHAEOLOGISTS',
    labelGreek: 'ΑΡΧΑΙΟΛΟΓΟΣ / ΒΟΗΘΟΣ ΑΡΧΑΙΟΛΟΓΟΥ',
  },
  {
    value: 3666,
    label: 'ANTHROPOLOGIST OR FOLKLORIST',
    labelGreek: 'ΑΝΘΡΩΠΟΛΟΓΟΣ Ή ΛΑΟΓΡΑΦΟΣ',
  },
  {
    value: 3667,
    label: 'GEOGRAPHER',
    labelGreek: 'ΓΕΩΓΡΑΦΟΣ',
  },
  {
    value: 3668,
    label: 'GEOLOGIST',
    labelGreek: 'ΓΕΩΛΟΓΟΣ',
  },
  {
    value: 3669,
    label: 'GEOPHYSICIST',
    labelGreek: 'ΓΕΩΦΥΣΙΚΟΣ',
  },
  {
    value: 3670,
    label: 'PHYSICIST',
    labelGreek: 'ΦΥΣΙΚΟΣ',
  },
  {
    value: 3671,
    label: 'SOCIOLOGIST',
    labelGreek: 'ΚΟΙΝΩΝΙΟΛΟΓΟΣ',
  },
  {
    value: 3672,
    label: 'MATERIALS SCIENTIST',
    labelGreek: 'ΕΠΙΣΤΗΜΟΝΑΣ ΤΩΝ ΥΛΙΚΩΝ',
  },
  {
    value: 3673,
    label: 'HISTORIOGRAPHER',
    labelGreek: 'ΙΣΤΟΡΙΟΓΡΑΦΟΣ',
  },
  {
    value: 3674,
    label: 'THATCHER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΠΛΙΝΘΟΣΤΕΓΩΝ',
  },
  {
    value: 3675,
    label: 'HERBALIST',
    labelGreek: 'ΒΟΤΑΝΟΛΟΓΟΣ',
  },
  {
    value: 3676,
    label: 'PHILANTHROPIST',
    labelGreek: 'ΦΙΛΑΝΘΡΩΠΟΣ',
  },
  {
    value: 3677,
    label: 'PALEONTOLOGIST',
    labelGreek: 'ΠΑΛΑΙΟΝΤΟΛΟΓΟΣ',
  },
  {
    value: 3678,
    label: 'METEOROLOGIST',
    labelGreek: 'ΜΕΤΕΩΡΟΛΟΓΟΣ',
  },
  {
    value: 3679,
    label: 'FORENSIC SCIENTIST',
    labelGreek: 'ΕΓΚΛΗΜΑΤΟΛΟΓΙΚΟΣ ΕΠΙΣΤΗΜΟΝΑΣ',
  },
  {
    value: 3680,
    label: 'ASTROLOGER',
    labelGreek: 'ΑΣΤΡΟΛΟΓΟΣ',
  },
  {
    value: 3681,
    label: 'ASTRONOMER',
    labelGreek: 'ΑΣΤΡΟΝΟΜΟΣ',
  },
  {
    value: 3682,
    label: 'POLITICAL SCIENTIST',
    labelGreek: 'ΠΟΛΙΤΙΚΟΣ ΕΠΙΣΤΗΜΟΝΑΣ',
  },
  {
    value: 3683,
    label: 'SOCIOLOGISTS',
    labelGreek: 'ΚΟΙΝΩΝΙΟΛΟΓΟΙ',
  },
  {
    value: 3684,
    label: 'ART DEALER',
    labelGreek: 'ΕΜΠΟΡΟΣ ΕΡΓΩΝ ΤΕΧΝΗΣ',
  },
  {
    value: 3685,
    label: 'ARTISTIC PAINTERS',
    labelGreek: 'ΚΑΛΛΙΤΕΧΝΙΚΟΙ ΖΩΓΡΑΦΟΙ',
  },
  {
    value: 3686,
    label: 'AUCTIONEER',
    labelGreek: 'ΔΗΜΟΠΡΑΤΗΣ',
  },
  {
    value: 3687,
    label: 'BODY GUARD',
    labelGreek: 'ΣΩΜΑΤΟΦΥΛΑΚΕΣ',
  },
  {
    value: 3688,
    label: 'POTTERS - POTTERY WORKSHOPS',
    labelGreek: 'ΑΓΓΕΙΟΠΛΑΣΤΕΣ - ΕΡΓΑΣΤΗΡΙΑ ΚΕΡΑΜΙΚΗΣ',
  },
  {
    value: 3689,
    label: 'CAKE MAKER AND DECORATOR',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΗΣ ΚΕΙΚ ΚΑΙ ΔΙΑΚΟΣΜΗΤΗΣ',
  },
  {
    value: 3690,
    label: 'CALLIGRAPHIST',
    labelGreek: 'ΚΑΛΛΙΓΡΑΦΟΣ',
  },
  {
    value: 3691,
    label: 'CARTOONIST',
    labelGreek: 'ΣΚΙΤΣΟΓΡΑΦΟΣ',
  },
  {
    value: 3692,
    label: 'DRESS MAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΕΝΔΥΜΑΤΩΝ',
  },
  {
    value: 3693,
    label: 'FASHION DESIGN',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΜΟΔΑΣ',
  },
  {
    value: 3694,
    label: 'JEWELLERS DESIGN',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΚΟΣΜΗΜΑΤΩΝ',
  },
  {
    value: 3695,
    label: 'KNITTED GARMENT MAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΠΛΕΚΤΩΝ ΕΝΔΥΜΑΤΩΝ',
  },
  {
    value: 3696,
    label: 'ILLUSTRATOR',
    labelGreek: 'ΣΚΙΤΣΟΓΡΑΦΟΣ',
  },
  {
    value: 3697,
    label: 'ACTOR OR ACTRESS',
    labelGreek: 'ΗΘΟΠΟΙΟΣ',
  },
  {
    value: 3698,
    label: 'ART COLLECTOR OR PATRON',
    labelGreek: 'ΣΥΛΛΕΚΤΗΣ ΕΡΓΩΝ ΤΕΧΝΗΣ Ή ΠΑΤΡΩΝ',
  },
  {
    value: 3699,
    label: 'ART CRITIC OR HISTORIAN',
    labelGreek: 'ΚΡΙΤΙΚΟΣ ΤΕΧΝΗΣ Ή ΙΣΤΟΡΙΚΟΣ',
  },
  {
    value: 3700,
    label: 'ARTIST',
    labelGreek: 'ΚΑΛΛΙΤΕΧΝΗΣ',
  },
  {
    value: 3701,
    label: 'CHOREOGRAPHER',
    labelGreek: 'ΧΟΡΟΓΡΑΦΟΣ',
  },
  {
    value: 3702,
    label: 'COMPOSER',
    labelGreek: 'ΣΥΝΘΕΤΗΣ',
  },
  {
    value: 3703,
    label: 'CONDUCTOR (MUSIC)',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ (ΜΟΥΣΙΚΗ)',
  },
  {
    value: 3704,
    label: 'MUSICIAN',
    labelGreek: 'ΜΟΥΣΙΚΟΣ',
  },
  {
    value: 3705,
    label: 'PHOTOGRAPHER',
    labelGreek: 'ΦΩΤΟΓΡΑΦΟΣ',
  },
  {
    value: 3706,
    label: 'PIANIST',
    labelGreek: 'ΠΙΑΝΙΣΤΑΣ',
  },
  {
    value: 3707,
    label: 'PIANO TUNER',
    labelGreek: 'ΡΥΘΜΙΣΤΗΣ ΠΙΑΝΟΥ',
  },
  {
    value: 3708,
    label: 'THEATRE MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΘΕΑΤΡΟΥ',
  },
  {
    value: 3709,
    label: 'SCULPTORS OR POTTERS',
    labelGreek: 'ΤΕΧΝΙΤΕΣ / ΑΓΓΕΙΟΠΛΑΣΤΕΣ',
  },
  {
    value: 3710,
    label: 'POET',
    labelGreek: 'ΠΟΙΗΤΗΣ',
  },
  {
    value: 3711,
    label: 'DANCE INSTRUCTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΧΟΡΟΥ',
  },
  {
    value: 3712,
    label: 'DANCER',
    labelGreek: 'ΧΟΡΕΥΤΡΙΑ',
  },
  {
    value: 3713,
    label: 'STAGE DESIGNER',
    labelGreek: 'ΣΚΗΝΟΓΡΑΦΟΣ',
  },
  {
    value: 3714,
    label: 'FILMMAKERS',
    labelGreek: 'ΔΗΜΙΟΥΡΓΟΙ ΤΑΙΝΙΩΝ',
  },
  {
    value: 3715,
    label: 'FILM DIRECTOR',
    labelGreek: 'ΣΚΗΝΟΘΕΤΗΣ',
  },
  {
    value: 3716,
    label: 'FILM PRODUCER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΤΑΙΝΙΩΝ',
  },
  {
    value: 3717,
    label: 'DIRECTOR',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ',
  },
  {
    value: 3718,
    label: 'THEATRE DIRECTOR',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΘΕΑΤΡΟΥ',
  },
  {
    value: 3719,
    label: 'BOUNCY CASTLE HIRER',
    labelGreek: 'ΕΝΟΚΙΑΣΤΗΣ ΦΟΥΣΚΩΤΩΝ ΚΑΣΤΡΩΝ',
  },
  {
    value: 3720,
    label: 'CHILDREN ENTERTAINER',
    labelGreek: 'ΔΙΑΣΚΕΔΑΣΤΗΣ ΠΑΙΔΙΩΝ',
  },
  {
    value: 3721,
    label: 'CLUBS  SOCIAL RESIDENTIAL',
    labelGreek: 'ΚΕΝΤΡΑ ΔΙΑΣΚΕΔΑΣΗΣ  ΚΟΙΝΩΝΙΚΟ  ΟΙΚΙΑΚΟ',
  },
  {
    value: 3722,
    label: 'CONFERENCE & EXHIBITION ORGANISER',
    labelGreek: 'ΔΙΟΡΓΑΝΩΤΗΣ ΣΥΝΕΔΡΙΩΝ ΚΑΙ ΕΚΘΕΣΕΩΝ',
  },
  {
    value: 3723,
    label: 'DISC JOCKEY',
    labelGreek: 'ΝΤΙ ΤΖΕΙ',
  },
  {
    value: 3724,
    label: 'EMPLOYEES GO-CART RACING',
    labelGreek: 'ΕΡΓΑΖΟΜΕΝΟΙ GO-CART RACING',
  },
  {
    value: 3725,
    label: 'ENTERTAINER',
    labelGreek: 'ΔΙΑΣΚΕΔΑΣΤΗΣ',
  },
  {
    value: 3726,
    label: 'FORTUNE TELLER',
    labelGreek: 'ΜΑΝΤΗΣ',
  },
  {
    value: 3727,
    label: 'PALMIST',
    labelGreek: 'ΧΕΙΡΟΜΑΝΤΗΣ',
  },
  {
    value: 3728,
    label: 'RESTAURATEUR',
    labelGreek: 'ΕΣΤΙΑΤΟΡΑΣ',
  },
  {
    value: 3729,
    label: 'EVENT ORGANISER',
    labelGreek: 'ΔΙΟΡΓΑΝΩΤΗΣ ΕΚΔΗΛΩΣΗΣ',
  },
  {
    value: 3730,
    label: 'MARQUEE HIRER',
    labelGreek: 'ΕΝΟΙΚΙΑΣΤΗΣ ΤΕΝΤΩΝ ΔΕΞΙΩΣΕΩΝ',
  },
  {
    value: 3731,
    label: 'PARTY PLANNER',
    labelGreek: 'ΟΡΓΑΝΩΤΗΣ ΠΑΡΤΙ',
  },
  {
    value: 3732,
    label: 'EVENT PLANNERS',
    labelGreek: 'ΟΡΓΑΝΩΤΗΣ ΕΚΔΗΛΩΣΗΣ',
  },
  {
    value: 3733,
    label: 'MAGICIAN',
    labelGreek: 'ΜΑΓΟΣ',
  },
  {
    value: 3734,
    label: 'WEATHER PROPHET',
    labelGreek: 'ΜΑΝΤΗΣ ΚΑΙΡΟΥ',
  },
  {
    value: 3735,
    label: 'LIBRARIAN',
    labelGreek: 'ΒΙΒΛΙΟΘΗΚΑΡΙΟΣ',
  },
  {
    value: 3736,
    label: 'MODEL AGENCIES',
    labelGreek: 'ΠΡΑΚΤΟΡΕΙΑ ΜΟΝΤΕΛΩΝ',
  },
  {
    value: 3737,
    label: 'MODELS',
    labelGreek: 'ΜΟΝΤΕΛΑ',
  },
  {
    value: 3738,
    label: 'MUSIC TEACHER',
    labelGreek: 'ΚΑΘΗΓΗΤΗΣ ΜΟΥΣΙΚΗΣ',
  },
  {
    value: 3739,
    label: 'MUSICIANS / SINGERS',
    labelGreek: 'ΜΟΥΣΙΚΟΙ /ΤΡΑΓΟΥΔΙΣΤΕΣ',
  },
  {
    value: 3740,
    label: 'PIANO TUNER',
    labelGreek: 'ΚΟΥΡΔΙΣΤΗΣ ΠΙΑΝΟΥ',
  },
  {
    value: 3741,
    label: 'CHOIR MASTER OR SINGER',
    labelGreek: 'ΑΡΧΗΓΟΣ ΧΟΡΩΔΙΑΣ Ή ΤΡΑΓΟΥΔΙΣΤΗΣ',
  },
  {
    value: 3742,
    label: 'PHOTOGRAPHER & DEALERS',
    labelGreek: 'ΦΩΤΟΓΡΑΦΟΣ & ΕΜΠΟΡΟΙ',
  },
  {
    value: 3743,
    label: 'PHOTOGRAPHER EXCLUDING UNDERWATER AND AERIAL',
    labelGreek: 'ΦΩΤΟΓΡΑΦΟΣ ΕΚΤΟΣ ΥΠΟΒΡΥΧΙΑ ΚΑΙ ΕΝΑΕΡΙΑ',
  },
  {
    value: 3744,
    label: 'PHOTOGRAPHER INCLUDING UNDERWATER AND AERIAL',
    labelGreek: 'ΦΩΤΟΓΡΑΦΟΣ ΣΥΜΠ. ΚΑΙ ΥΠΟΘΑΛΑΣΣΙΟΥ ΚΑΙ ΕΝΑΕΡΙΑΣ',
  },
  {
    value: 3745,
    label: 'ACTOR (EXCLUDING STUNT WORK)',
    labelGreek: 'ΗΘΟΠΟΙΟΣ (ΕΚΤΟΣ ΚΟΛΠΩΝ)',
  },
  {
    value: 3746,
    label: 'CINEMAS STAFF',
    labelGreek: 'ΠΡΟΣΩΠΙΚΟ ΚΙΝΗΜΑΤΟΓΡΑΦΟΥ',
  },
  {
    value: 3747,
    label: 'SCREEN / SCRIPT WRITER',
    labelGreek: 'ΣΕΝΑΡΙΟΓΡΑΦΟΣ',
  },
  {
    value: 3748,
    label: 'THEATRE AGENT',
    labelGreek: 'ΠΡΑΚΤΟΡΑΣ ΘΕΑΤΡΟΥ',
  },
  {
    value: 3749,
    label: 'THEATRES AND MUSIC HALLS',
    labelGreek: 'ΘΕΑΤΡΑ ΚΑΙ ΚΕΝΤΡΑ ΔΙΑΣΚΕΔΑΣΕΩΣ',
  },
  {
    value: 3750,
    label: 'GUIDE',
    labelGreek: 'ΟΔΗΓΟΣ',
  },
  {
    value: 3751,
    label: 'TOUR GUIDE',
    labelGreek: 'ΞΕΝΑΓΗΣΗ',
  },
  {
    value: 3752,
    label: 'TOUR OPERATOR',
    labelGreek: 'ΓΡΑΦΕΙΟ ΞΕΝΑΓΗΣΕΩΝ',
  },
  {
    value: 3753,
    label: 'TRAVEL AGENCY',
    labelGreek: 'ΤΑΞΙΔΙΩΤΙΚΟΣ ΠΡΑΚΤΟΡΑΣ',
  },
  {
    value: 3754,
    label: 'DECORATOR AND PAINTER',
    labelGreek: 'ΔΙΑΚΟΣΜΗΤΗΣ ΚΑΙ ΖΩΓΡΑΦΟΣ',
  },
  {
    value: 3755,
    label: 'ENGRAVER',
    labelGreek: 'ΧΑΡΑΚΤΗΣ',
  },
  {
    value: 3756,
    label: 'PICTURE FRAMER',
    labelGreek: 'ΚΟΡΝΙΖΟΠΟΙΟΣ',
  },
  {
    value: 3757,
    label: 'CARPENTER',
    labelGreek: 'ΞΥΛΟΥΡΓΟΣ',
  },
  {
    value: 3758,
    label: 'CARPET',
    labelGreek: 'ΧΑΛΙΑ',
  },
  {
    value: 3759,
    label: 'CARPET FITTER',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΕΙΣ ΧΑΛΙΩΝ',
  },
  {
    value: 3760,
    label: 'CURTAIN CARPET AND UPHOLSTERY CLEANER',
    labelGreek: 'ΚΑΘΑΡΙΣΜΟΣ ΚΟΥΡΤΙΝΩΝ ΧΑΛΙΩΝ & ΤΑΠΕΤΣΑΡΙΑΣ',
  },
  {
    value: 3761,
    label: 'WINDOW CLEANER',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΣ ΤΖΑΜΙΩΝ',
  },
  {
    value: 3762,
    label: 'CLEANERS - CARPET',
    labelGreek: 'ΚΑΘΑΡΙΣΤΕΣ - ΧΑΛΙΑ',
  },
  {
    value: 3763,
    label: 'CLEANERS - GLASS',
    labelGreek: 'ΚΑΘΑΡΙΣΤΕΣ - ΓΥΑΛΙΩΝ',
  },
  {
    value: 3764,
    label: 'CLEANERS - OFFICE / SHOP / RECREATION CENTERS',
    labelGreek: 'ΚΑΘΑΡΙΣΤΕΣ -  ΓΡΑΦΕΙΩΝ / ΚΑΤΑΣΤΗΜΑΤΩΝ/  ΚΕΝΤΡΩΝ ΑΝΑΨΥΧΗΣ',
  },
  {
    value: 3765,
    label: 'PEST AND VERMIN CONTROL SERVICES',
    labelGreek: 'ΥΠΗΡΕΣΙΕΣ ΕΛΕΓΧΟΥ ΠΑΡΑΣΙΤΩΝ ΚΑΙ ΖΩΥΦΙΩΝ',
  },
  {
    value: 3766,
    label: 'UPHOLSTERY & CURTAIN CLEANER',
    labelGreek: 'ΚΑΘΑΡΙΣΤΕΣ ΤΑΠΕΤΣΑΡΙΩΝ & ΚΟΥΡΤΙΝΩΝ',
  },
  {
    value: 3767,
    label: 'WHEELIE BIN CLEANER',
    labelGreek: 'ΠΛΑΝΩΔΙΟΣ ΚΑΘΑΡΙΣΤΗΣ',
  },
  {
    value: 3768,
    label: 'EXHIBITION STAND ERECTOR FASCIA / GUTTERING INSTALLATION',
    labelGreek: 'ΑΝΟΡΘΩΣΗΣ ΕΚΘΕΣΙΑΚΟΝ ΠΕΡΙΠΤΕΡΩΝ  / ΕΓΚΑΤΑΣΤΑΣΗ ΣΩΛΗΝΩΝ',
  },
  {
    value: 3769,
    label: 'GLAZIERS',
    labelGreek: 'ΥΑΛΟΤΕΧΝΙΤΩΝ',
  },
  {
    value: 3770,
    label: 'LOCKSMITH',
    labelGreek: 'ΚΛΕΙΔΑΡΑΣ',
  },
  {
    value: 3771,
    label: 'DRIVER',
    labelGreek: 'ΟΔΗΓΟΣ',
  },
  {
    value: 3772,
    label: 'ICE CREAM VAN',
    labelGreek: 'ΟΧΗΜΑ ΠΑΓΩΤΩΝ',
  },
  {
    value: 3773,
    label: 'AERIAL AND SATELLITE DISH ERECTORS - RESIDENTIAL ONLY',
    labelGreek:
      ' : ΑΝΟΡΘΩΣΕΙΣ ΤΗΛΕΟΠΤΙΚΩΝ ΚΑΙ ΔΟΡΥΦΟΡΙΚΩΝ ΠΙΑΤΩΝ - ΜΟΝΟ ΣΕ ΚΑΤΟΙΚΙΑ',
  },
  {
    value: 3774,
    label: 'AERIAL ERECTOR',
    labelGreek: 'ΑΝΟΡΘΩΤΕΣ ΚΕΡΑΙΩΝ',
  },
  {
    value: 3775,
    label: 'ALARM INSTALLERS',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΤΕΣ ΣΥΝΑΓΕΡΜΟΥ',
  },
  {
    value: 3776,
    label: 'APPLIANCE REPAIRERS - DOMESTIC',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΟΙΚΙΑΚΩΝ ΣΥΣΚΕΥΩΝ',
  },
  {
    value: 3777,
    label: 'ELECTRICIANS - MOTOR',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΙ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 3778,
    label: 'CABLE LAYING',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΗ ΚΑΛΩΔΙΩΝ',
  },
  {
    value: 3779,
    label: 'CCTV INSTALLATION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΗ ΚΛΕΙΣΤΩΝ ΚΥΚΛΩΜΑΤΩΝ ΤΗΛΕΟΡΑΣΗΣ',
  },
  {
    value: 3780,
    label: 'ELECTRICIAN - PORTABLE APPLIANCE TESTING',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΣ -ΕΛΕΓΧΟΣ ΦΟΡΗΤΩΝ ΣΥΣΚΕΥΩΝ',
  },
  {
    value: 3781,
    label: 'ELECTRICIANS - COMMERCIAL',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΩΝ - ΕΜΠΟΡΙΟΥ',
  },
  {
    value: 3782,
    label: 'ELECTRICIANS - DOMESTIC',
    labelGreek: 'ΗΛΕΚΤΡΟΛΟΓΟΣ ΟΙΚΙΩΝ',
  },
  {
    value: 3783,
    label: 'SATELLITE DISH INSTALLER',
    labelGreek: 'ΤΟΠΟΘΕΤΗΣΕΙΣ ΔΟΡΥΦΟΡΙΚΩΝ ΑΝΤΕΝΝΩΝ',
  },
  {
    value: 3784,
    label: 'TELEPHONE POINT/EXTENSION INSTALLATION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΚΑΙ ΕΠΙΜΗΚΥΝΣΕΙΣ ΤΗΛΕΦΩΝΙΚΩΝ ΚΟΥΤΙΩΝ',
  },
  {
    value: 3785,
    label: 'TELEVISION REPAIRS',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΤΗΛΕΟΡΑΣΗ',
  },
  {
    value: 3786,
    label: 'TILLERS - FLOOR & WALL & ROOFS',
    labelGreek: 'ΣΚΑΠΤΙΚΑ - ΔΑΠΕΔΟΥ & ΤΟΙΧΟΥ & ΣΤΕΓΕΣ',
  },
  {
    value: 3787,
    label: 'TV AND VIDEO INSTALLATION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΗ ΤΗΛΕΟΡΑΣΗΣ ΚΑΙ ΕΙΚΟΝΑΣ',
  },
  {
    value: 3788,
    label: 'ENGINEER - ACOUSTIC ENGINEERS - REFRIGERATION',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ - ACOUSTIC ΜΗΧΑΝΙΚΟΙ - ΨΥΞΗ',
  },
  {
    value: 3789,
    label: 'LIFT INSTALLATION',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΗ ΑΝΕΛΚΥΣΤΗΡΩΝ',
  },
  {
    value: 3790,
    label: 'REFRIGERATION ENGINEERS',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ ΨΥΞΗΣ',
  },
  {
    value: 3791,
    label: 'CATERER - EXCLUDING MOBILE VANS',
    labelGreek: 'ΕΤΟΙΜΑ ΦΑΓΗΤΑ - ΠΛΗΝ ΤΩΝ ΚΙΝΗΤΩΝ ΦΟΡΤΗΓΩΝ',
  },
  {
    value: 3792,
    label: 'CATERER - INCLUDING MOBILE VANS',
    labelGreek: 'ΕΤΟΙΜΑ ΦΑΓΗΤΑ - ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΤΩΝ ΚΙΝΗΤΩΝ ΦΟΡΤΗΓΩΝ',
  },
  {
    value: 3793,
    label: 'PRODUCE DEALERS',
    labelGreek: 'ΕΜΠΟΡΟΙ ΠΡΟΪΟΝΤΩΝ',
  },
  {
    value: 3794,
    label: 'OFFICE EQUIPMENT REPAIRER',
    labelGreek: 'ΓΡΑΦΕΙΟ ΕΠΙΣΚΕΥΑΣΤΩΝ ΕΞΟΠΛΙΣΜΟΥ',
  },
  {
    value: 3795,
    label: 'SILVERSMITH',
    labelGreek: 'ΑΡΓΥΡΟΧΟΟΣ',
  },
  {
    value: 3796,
    label: 'OFFICE EQUIPMENT REPAIR',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΤΟΥ ΕΞΟΠΛΙΣΜΟΥ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3797,
    label: 'SHOE REPAIRER',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΥΠΟΔΗΜΑΤΩΝ',
  },
  {
    value: 3798,
    label: 'UPHOLSTERY & FURNITURE REPAIR',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΕΠΙΠΛΩΝ & ΤΑΠΕΤΣΑΡΙΩΝ',
  },
  {
    value: 3799,
    label: 'HOME APPLIANCES - REPAIRS',
    labelGreek: 'ΟΙΚΙΑΚΕΣ ΣΥΣΚΕΥΕΣ - ΕΠΙΣΚΕΥΕΣ',
  },
  {
    value: 3800,
    label: 'PLUMBING',
    labelGreek: 'ΥΔΡΑΥΛΙΚΑ',
  },
  {
    value: 3801,
    label: 'VENTILATION & PLUMBING CONTRACTOR - DOMESTIC',
    labelGreek: 'ΕΞΑΕΡΙΣΜΟΣ & ΥΔΡΑΥΛΙΚΑ ΕΡΓΟΛΑΒΟΣ - ΟΙΚΙΑΚΑ',
  },
  {
    value: 3802,
    label: 'BATHROOM',
    labelGreek: 'ΜΠΑΝΙΟ',
  },
  {
    value: 3803,
    label: 'BEDROOM AND KITCHEN FITTER',
    labelGreek: 'ΣΥΝΑΝΡΜΟΛΟΓΗΣΕΙΣ ΥΠΝΟΔΩΜΑΤΙΟΥ ΚΑΙ ΚΟΥΖΙΝΑΣ',
  },
  {
    value: 3804,
    label: 'BLIND MAKERS AND FITTERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ  ΚΑΙ ΕΦΑΡΜΟΣΤΕΣ BLIND',
  },
  {
    value: 3805,
    label: 'CARETAKER',
    labelGreek: 'ΕΠΙΣΤΑΤΗΣ',
  },
  {
    value: 3806,
    label: 'CAVITY INSULATION',
    labelGreek: 'ΜΟΝΩΣΗ ΚΟΥΦΩΜΑΤΩΝ',
  },
  {
    value: 3807,
    label: 'CHIMNEY SWEEP',
    labelGreek: 'ΚΑΠΝΟΔΟΧΟΚΑΘΑΡΙΣΤΗΣ',
  },
  {
    value: 3808,
    label: 'CLADDING CLEANER - DOMESTIC CLEANER - DRAINS CLEANER - OFFICE',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΣ ΕΠΙΚΑΛΥΨΕΩΝ  - ΟΙΚΙΑΣ - ΑΠΟΧΕΤΕΥΣΗΣ- ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 3809,
    label: 'COOKING AND IRONING SERVICES - DOMESTIC ONLY',
    labelGreek: 'ΜΑΓΕΙΡΙΚΗΣ ΚΑΙ ΣΙΔΕΡΩΜΑΤΟΣ - ΟΙΚΙΑΚΑ ΜΟΝΟ',
  },
  {
    value: 3810,
    label: 'DOMESTIC APPLIANCE REPAIRERS',
    labelGreek: 'ΕΠΙΣΚΕΥΑΣΤΕΣ ΟΙΚΙΑΚΩΝ ΣΥΣΚΕΥΩΝ',
  },
  {
    value: 3811,
    label: 'DOMESTIC CLEANER',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΣ ΟΙΚΙΑΣ',
  },
  {
    value: 3812,
    label: 'FLOORING CONTRACTORS - COMMERCIAL',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΑΠΕΔΩΝ - ΕΜΠΟΡΙΟ',
  },
  {
    value: 3813,
    label: 'FLOORING CONTRACTORS - DOMESTIC',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΑΠΕΔΩΝ - ΟΙΚΙΕΣ',
  },
  {
    value: 3814,
    label: 'FURNISHER',
    labelGreek: 'ΑΝΑΚΑΙΝΙΣΕΙΣ',
  },
  {
    value: 3815,
    label: 'FURNITURE REMOVAL',
    labelGreek: 'ΑΠΟΜΑΚΡΥΝΣΗ ΕΠΙΠΛΩΝ',
  },
  {
    value: 3816,
    label: 'GARDEN MAINT INC TREE FELLING',
    labelGreek: 'ΠΕΡΙΠΟΙΗΣΗ ΚΗΠΟΥ ΜΕ ΥΛΟΤΟΜΗΣΗ',
  },
  {
    value: 3817,
    label: 'HYGIENE AND CLEANSING SERVICES',
    labelGreek: 'ΥΓΙΕΙΝΗ ΚΑΙ ΚΑΘΑΡΙΣΜΟΣ ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 3818,
    label: 'JOINERS KITCHEN',
    labelGreek: 'ΞΥΛΟΥΡΓΟΙ ΚΟΥΖΙΝΑ',
  },
  {
    value: 3819,
    label: 'LANDSCAPE GUARD EXCLUDING TREE FELLING',
    labelGreek: 'ΣΥΝΤΗΡΗΤΗΣ ΤΟΠΙΟΥ ΕΚΤΟΣ ΥΛΟΤΟΜΗΣΗ',
  },
  {
    value: 3820,
    label: 'LANDSCAPE GUARD INCLUDING TREE FELLING',
    labelGreek: 'ΣΥΝΤΗΡΗΤΗΣ ΤΟΠΙΟΥ ΜΕ ΥΛΟΤΟΜΗΣΗ',
  },
  {
    value: 3821,
    label: 'SCAFFOLDING',
    labelGreek: 'ΙΚΡΙΩΜΑΤΩΝ',
  },
  {
    value: 3822,
    label: 'SECURITY GUARD',
    labelGreek: 'ΦΥΛΑΚΑΣ',
  },
  {
    value: 3823,
    label: 'SHUTTERING',
    labelGreek: 'ΞΥΛΟΤΥΠΟΙ',
  },
  {
    value: 3824,
    label: 'SCRAP  METAL & SECOND HAND MACH. DEALERS',
    labelGreek:
      ' : ΕΜΠΟΡΟΙ ΘΡΑΥΣΜΑΤΩΝ  ΜΕΤΑΛΛΩΝ ΚΑΙ ΤΟ ΜΕΤΑΧΕΙΡΙΣΜΕΝΩΝ ΜΗΧΑΝΗΜΑΤΩΝ.',
  },
  {
    value: 3825,
    label: 'SHOE POLISHER',
    labelGreek: 'ΓΥΑΛΙΣΤΗΣ ΠΑΠΟΥΤΣΙΩΝ',
  },
  {
    value: 3826,
    label: 'SILVERERS',
    labelGreek: 'ΑΡΓΥΡΟΧΟΟΙ',
  },
  {
    value: 3827,
    label: 'SILVERSMITH / GOLDSMITH',
    labelGreek: 'ΑΡΓΥΡΟΧΡΥΣΟΧΟΪΑΣ /ΧΡΥΣΟΧΟΟΙΑ',
  },
  {
    value: 3828,
    label: 'SLAUGHTER MEN',
    labelGreek: 'ΣΦΑΓΕΑΣ',
  },
  {
    value: 3829,
    label: 'TAILOR',
    labelGreek: 'ΡΑΦΤΗΣ',
  },
  {
    value: 3830,
    label: 'UPHOLSTERER',
    labelGreek: 'ΤΑΠΕΤΣΑΡΗΣ',
  },
  {
    value: 3831,
    label: 'TIMBER MERCHANTS',
    labelGreek: 'ΞΥΛΕΜΠΟΡΟΙ',
  },
  {
    value: 3832,
    label: 'DAIRY ASSISTANT',
    labelGreek: 'ΒΟΗΘΟΣ ΓΑΛΑΚΤΟΚΟΜΙΚΑ',
  },
  {
    value: 3833,
    label: 'FIRE EXTINGUISHER SALES AND SERVICE',
    labelGreek: 'ΠΩΛΗΣΗ ΚΑΙ ΕΞΥΠΗΡΕΤΗΣΗ ΠΥΡΟΣΒΕΣΤΗΡΩΝ',
  },
  {
    value: 3834,
    label: 'FURNITURE POLISHER',
    labelGreek: 'ΓΥΑΛΙΣΜΑ ΕΠΙΠΛΩΝ',
  },
  {
    value: 3835,
    label: 'GILDERS',
    labelGreek: 'ΕΠΙΧΡΥΣΩΤΕΣ',
  },
  {
    value: 3836,
    label: 'GLAZIERS',
    labelGreek: 'ΥΑΛΟΤΕΧΝΙΤΕΣ',
  },
  {
    value: 3837,
    label: 'GLAZIERS INCLUDING DOUBLE GLAZING',
    labelGreek: 'ΥΑΛΟΤΕΧΝΙΤΕΣ ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΔΙΠΛΩΝ ΥΑΛΟΠΙΝΑΚΩΝ',
  },
  {
    value: 3838,
    label: 'KATHREPTOPOLEIA (SYMPER.EGKATASTASEON)',
    labelGreek: 'ΚΑΘΡΕΠΤΟΠΩΛΕΙΑ(ΜΑΖΙ ΜΕ ΕΓΚΑΤΑΣΤΑΣΕΩΝ)',
  },
  {
    value: 3839,
    label: 'FRENCH POLISHER',
    labelGreek: 'ΓΑΛΛΙΚΗ ΛΕΙΑΝΣΗ',
  },
  {
    value: 3840,
    label: 'MODEL MAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΜΑΚΕΤΩΝ',
  },
  {
    value: 3841,
    label: 'STRAIGHTENER CAR / FANOPOIOI / TINSMITH',
    labelGreek: 'ΙΣΩΤΕΣ/ΦΑΝΟΠΟΙΟΙ / ΦΑΝΑΡΤΖΗΣ',
  },
  {
    value: 3842,
    label: 'TYRE REPAIRERS',
    labelGreek: 'ΕΠΙΣΚΕΥΑΣΤΕΣ ΕΛΑΣΤΙΚΩΝ',
  },
  {
    value: 3843,
    label: 'SIGN ERECTORS',
    labelGreek: 'ΑΝΟΡΘΩΤΕΣ ΤΑΜΠΕΛΛΩΝ',
  },
  {
    value: 3844,
    label: 'SWIMMING POOL CLEANERS',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΣ ΠΙΣΙΝΑΣ',
  },
  {
    value: 3845,
    label: 'SWIMMING POOL INSTALLERS',
    labelGreek: 'ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΠΙΣΙΝΩΝ',
  },
  {
    value: 3846,
    label: 'CYCLE AND MOTORCYCLE REPAIRERS',
    labelGreek: 'ΕΠΙΣΚΕΥΑΣΤΕΣ ΠΟΔΗΛΑΤΩΝ Η ΜΟΤΟΣΙΚΛΕΤΩΝ',
  },
  {
    value: 3847,
    label: 'ENGINEERS / MECHANICS CAR',
    labelGreek: 'ΜΗΧΑΝΙΚΟΙ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 3848,
    label: 'DOOR TO DOOR - FRANCHISE',
    labelGreek: 'ΑΠΟ ΠΟΡΤΑ ΣΕ ΠΟΡΤΑ - FRANCHISE',
  },
  {
    value: 3849,
    label: 'MARKET TRADER',
    labelGreek: 'ΕΜΠΟΡΟΣ ΑΓΟΡΑΣ',
  },
  {
    value: 3850,
    label: 'MILKMAN',
    labelGreek: 'ΓΑΛΑΤΑΣ',
  },
  {
    value: 3851,
    label: 'MOTOR GARAGES AND SHOWROOMS',
    labelGreek: 'ΓΚΑΡΑΖ ΚΑΙ ΕΚΘΕΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 3852,
    label: 'OIL AND PETROLEUM IMPORTERS INCLUDING DISTRIBUTION',
    labelGreek: 'ΕΙΣΑΓΩΓΕΣ ΠΕΤΡΕΛΑΙΟΥ ΚΑΙ ΕΛΑΙΩΝ  ΠΟΥ ΠΕΡΙΛΑΜΒΑΝΕΙ ΔΙΑΝΟΜΗ',
  },
  {
    value: 3853,
    label: 'PRINTING',
    labelGreek: 'ΕΚΤΥΠΩΣΕΙΣ',
  },
  {
    value: 3854,
    label: 'FUNERAL CONTRACTORS',
    labelGreek: 'ΕΡΓΟΛΑΒΟΙ ΤΕΛΕΤΩΝ',
  },
  {
    value: 3855,
    label: 'GRAVE DIGGER',
    labelGreek: 'ΝΕΚΡΟΘΑΦΤΗΣ',
  },
  {
    value: 3856,
    label: 'CIVIL SERVANT',
    labelGreek: 'ΔΗΜΟΣΙΟΣ ΥΠΑΛΛΗΛΟΣ',
  },
  {
    value: 3857,
    label: 'DEMOGRAPHER',
    labelGreek: 'ΔΗΜΟΓΡΑΦΟΣ',
  },
  {
    value: 3858,
    label: 'LIFEGUARDS',
    labelGreek: 'ΝΑΥΑΓΟΣΩΣΤΕΣ',
  },
  {
    value: 3859,
    label: 'STREET SWEEPERS',
    labelGreek: 'ΟΔΟΚΑΘΑΡΙΣΤΕΣ',
  },
  {
    value: 3860,
    label: 'COAST GUARD',
    labelGreek: 'ΑΚΤΟΦΥΛΑΚΗ',
  },
  {
    value: 3861,
    label: 'DIPLOMAT',
    labelGreek: 'ΔΙΠΛΩΜΑΤΕΣ',
  },
  {
    value: 3862,
    label: 'GARBAGE COLLECTORS',
    labelGreek: 'ΡΑΚΟΣΥΛΛΕΚΤΕΣ',
  },
  {
    value: 3863,
    label: 'GOVERNMENT AGENT',
    labelGreek: 'ΚΥΒΕΡΝΗΤΙΚΟΣ ΕΚΠΡΟΣΩΠΟΣ',
  },
  {
    value: 3864,
    label: 'GOVERNMENT ADMINISTRATION STAFF',
    labelGreek: 'ΔΙΟΙΚΗΣΗ ΚΥΒΕΡΝΗΤΙΚΟΥ ΠΡΟΣΩΠΙΚΟΥ',
  },
  {
    value: 3865,
    label: 'GOVERNMENT LABOUR WORKER',
    labelGreek: 'ΚΥΒΕΡΝΗΤΙΚΟΙ ΕΡΓΑΤΕΣ',
  },
  {
    value: 3866,
    label: 'GOVERNOR',
    labelGreek: 'ΚΥΒΕΡΝΗΤΗΣ',
  },
  {
    value: 3867,
    label: 'LIGHTHOUSE-KEEPER',
    labelGreek: 'ΦΑΡΟΦΥΛΑΚΑΣ',
  },
  {
    value: 3868,
    label: 'POSTMAN/WOMAN',
    labelGreek: 'ΤΑΧΥΔΡΟΜΟΣ',
  },
  {
    value: 3869,
    label: 'PUBLIC HEALTH WORKER',
    labelGreek: 'ΥΠΑΛΛΗΛΟΣ ΔΗΜΟΣΙΑΣ ΥΓΕΙΑΣ',
  },
  {
    value: 3870,
    label: 'TAX COLLECTOR',
    labelGreek: 'ΦΟΡΟΕΙΣΠΡΑΚΤΟΡΑΣ',
  },
  {
    value: 3871,
    label: 'HEALTH REFORM ADVOCATE',
    labelGreek: 'ΣΥΝΗΓΟΡΟΣ ΜΕΤΑΡΡΥΘΜΙΣΗΣ ΥΓΕΙΑΣ',
  },
  {
    value: 3872,
    label: 'INSPECTOR',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ',
  },
  {
    value: 3873,
    label: 'NEGOTIATOR',
    labelGreek: 'ΔΙΑΠΡΑΓΜΑΤΕΥΤΗΣ',
  },
  {
    value: 3874,
    label: 'CUSTOMS OFFICER',
    labelGreek: 'ΤΕΛΩΝΙΑΚΟΣ ΥΠΑΛΗΛΟΣ',
  },
  {
    value: 3875,
    label: 'CLUBS AND ORGANIZATIONS NON PROFIT',
    labelGreek: 'ΛΕΣΧΕΣ ΚΑΙ ΟΡΓΑΝΩΣΕΙΣ ΜΗ ΚΕΡΔΟΣΚΟΠΙΚΕΣ',
  },
  {
    value: 3876,
    label: 'TOWN PLANNER',
    labelGreek: 'ΠΟΛΕΟΔΟΜΟΣ',
  },
  {
    value: 3877,
    label: 'URBAN PLANNER',
    labelGreek: 'ΠΟΛΕΟΔΟΜΟΣ (ΑΣΤΙΚΟΣ)',
  },
  {
    value: 3878,
    label: 'PUBLIC SPEAKER',
    labelGreek: 'ΔΗΜΟΣΙΟΣ ΟΜΙΛΗΤΗΣ',
  },
  {
    value: 3879,
    label: 'HOUSING REFORMER',
    labelGreek: 'ΑΝΑΚΑΙΝΙΣΕΙΣ ΟΙΚΙΑΣ',
  },
  {
    value: 3880,
    label: 'LABOUR REFORMER',
    labelGreek: 'ΔΙΑΙΤΗΤΗΣ ΕΡΓΑΣΙΑΣ',
  },
  {
    value: 3881,
    label: 'SCOUT',
    labelGreek: 'ΣΚΑΟΥΤΕΡ',
  },
  {
    value: 3882,
    label: 'SOCIAL WORKER',
    labelGreek: 'ΚΟΙΝΩΝΙΚΟΣ ΛΕΙΤΟΥΡΓΟΣ',
  },
  {
    value: 3883,
    label: 'SOCIALIST OR RADICAL',
    labelGreek: 'ΣΟΣΙΑΛΙΣΤΗΣ Ή ΡΙΖΟΣΠΑΣΤΗΣ',
  },
  {
    value: 3884,
    label: 'WEATHERMAN',
    labelGreek: 'ΜΕΤΕΩΡΟΛΟΓΟΣ',
  },
  {
    value: 3885,
    label: 'WELFARE WORK LEADER',
    labelGreek: 'ΑΡΧΗΓΟΣ ΠΡΟΝΟΙΑΣ ΕΡΓΑΣΙΑΣ',
  },
  {
    value: 3886,
    label: 'ANIMAL TRAINER',
    labelGreek: 'ΘΗΡΙΟΔΑΜΑΣΤΗΣ',
  },
  {
    value: 3887,
    label: 'HORSE TRAINER',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΑΛΟΓΩΝ',
  },
  {
    value: 3888,
    label: 'ADOPTION AGENCIES',
    labelGreek: 'ΓΡΑΦΕΙΑ ΥΙΟΘΕΤΗΣΗΣ',
  },
  {
    value: 3889,
    label: 'CHILD MINDER',
    labelGreek: 'ΦΡΟΝΤΙΔΑ ΠΑΙΔΙΟΥ',
  },
  {
    value: 3890,
    label: 'MIDWIFE',
    labelGreek: 'ΜΑΙΑ',
  },
  {
    value: 3891,
    label: 'NANNY',
    labelGreek: 'ΝΤΑΝΤΑ',
  },
  {
    value: 3892,
    label: 'OBSTETRICIAN',
    labelGreek: 'ΜΑΙΕΥΤΗΡΑΣ',
  },
  {
    value: 3893,
    label: 'BEAUTICIAN',
    labelGreek: 'ΑΙΣΘΗΤΙΚΟΣ',
  },
  {
    value: 3894,
    label: 'BODYBUILDER',
    labelGreek: 'BODYBUILDER',
  },
  {
    value: 3895,
    label: 'CHIROPODIST',
    labelGreek: 'ΠΟΔΙΑΤΡΟΣ',
  },
  {
    value: 3896,
    label: 'COSMETOLOGIST',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΟΜΟΡΦΙΑΣ',
  },
  {
    value: 3897,
    label: 'ESTHETICIAN',
    labelGreek: 'ΑΙΣΘΗΤΙΚΟΣ',
  },
  {
    value: 3898,
    label: 'FASHION DESIGNER',
    labelGreek: 'ΣΧΕΔΙΑΣΤΗΣ ΜΟΔΑΣ',
  },
  {
    value: 3899,
    label: 'HAIRDRESSER',
    labelGreek: 'ΚΟΜΜΩΤΡΙΑ',
  },
  {
    value: 3900,
    label: 'HEALTH CLUB',
    labelGreek: 'ΚΕΝΤΡΑ ΥΓΕΙΑΣ',
  },
  {
    value: 3901,
    label: 'HYGIENE SERVICES',
    labelGreek: 'ΥΓΕΙΟΝΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 3902,
    label: 'MAKE UP ARTIST',
    labelGreek: 'ΜΑΚΙΓΙΕΡ',
  },
  {
    value: 3903,
    label: 'MANICURIST AND PEDICURIST',
    labelGreek: 'ΜΑΝΙΚΙΟΥΡΙΣΤ ΚΑΙ ΠΕΝΤΙΚΙΟΥΡ',
  },
  {
    value: 3904,
    label: 'MODEL',
    labelGreek: 'ΜΟΝΤΕΛΟ',
  },
  {
    value: 3905,
    label: 'NUTRITIONIST',
    labelGreek: 'ΔΙΑΤΡΟΦΟΛΟΓΟΣ',
  },
  {
    value: 3906,
    label: 'BARBER',
    labelGreek: 'ΚΟΜΜΩΤΗΣ',
  },
  {
    value: 3907,
    label: 'CHEMICAL ENGINEER',
    labelGreek: 'ΧΗΜΙΚΟΣ ΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3908,
    label: 'CHEMICAL TECHNOLOGIST',
    labelGreek: 'ΧΗΜΙΚΟΣ ΤΕΧΝΟΛΟΓΟΣ',
  },
  {
    value: 3909,
    label: 'CHEMIST',
    labelGreek: 'ΧΗΜΙΚΟΣ',
  },
  {
    value: 3910,
    label: 'COUNSELLOR',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 3911,
    label: 'FENG SHUI CONSULTANT',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΦΕΝΓΚ ΣΟΥΙ',
  },
  {
    value: 3912,
    label: 'DENTIST AND DENTAL MECHANICS',
    labelGreek: 'ΟΔΟΝΤΙΑΤΡΟΣ ΚΑΙ ΟΔΟΝΤΟΤΕΧΝΙΤΗΣ',
  },
  {
    value: 3913,
    label: 'DENTAL',
    labelGreek: 'ΟΔΟΝΤΙΑΤΡΙΚΗ',
  },
  {
    value: 3914,
    label: 'DENTIST',
    labelGreek: 'ΟΔΟΝΤΙΑΤΡΟΣ',
  },
  {
    value: 3915,
    label: 'DENTIST ASSISTANT',
    labelGreek: 'ΒΟΗΘΟΣ ΟΔΟΝΤΙΑΤΡΟΥ',
  },
  {
    value: 3916,
    label: 'ODONTOLOGIST',
    labelGreek: 'ΟΔΟΝΤΟΛΟΓΟΣ',
  },
  {
    value: 3917,
    label: 'ORTHODONTIST',
    labelGreek: 'ΟΡΘΟΔΟΝΤΙΚΟΣ',
  },
  {
    value: 3918,
    label: 'ANESTHESIOLOGIST',
    labelGreek: 'ΑΝΑΙΣΘΗΣΙΟΛΟΓΟΣ',
  },
  {
    value: 3919,
    label: 'DOCTORS (EXCEPT VETERINARY SURGEONS)',
    labelGreek: 'ΓΙΑΤΡΟΙ (ΕΚΤΟΣ ΚΤΗΝΙΑΤΡΙΚΩΝ ΧΕΙΡΟΥΡΓΩΝ)',
  },
  {
    value: 3920,
    label: 'MEDICAL',
    labelGreek: 'ΙΑΤΡΙΚΗ',
  },
  {
    value: 3921,
    label: 'VETERINARIANS',
    labelGreek: 'ΚΤΗΝΙΑΤΡΟΙ',
  },
  {
    value: 3922,
    label: 'ANESTHETISTS',
    labelGreek: 'ΑΝΑΙΣΘΗΣΙΟΛΟΓΟΙ',
  },
  {
    value: 3923,
    label: 'BIOCHEMICAL',
    labelGreek: 'ΒΙΟΧΗΜΙΚΑ',
  },
  {
    value: 3924,
    label: 'GYNAECOLOGISTS',
    labelGreek: 'ΓΥΝΑΙΚΟΛΟΓΟΙ',
  },
  {
    value: 3925,
    label: 'MICROBIOLOGISTS',
    labelGreek: 'ΑΓΙΟΓΡΑΦΟΙ',
  },
  {
    value: 3926,
    label: 'ZOOLOGIST',
    labelGreek: 'ΖΩΟΛΟΓΟΣ',
  },
  {
    value: 3927,
    label: 'AUDIOLOGIST',
    labelGreek: 'ΩΤΟΡΙΝΟΛΑΡΥΓΓΟΛΟΓΟΣ',
  },
  {
    value: 3928,
    label: 'BACTERIOLOGIST',
    labelGreek: 'ΜΙΚΡΟΒΙΟΛΟΓΟΣ',
  },
  {
    value: 3929,
    label: 'CARDIOLOGIST',
    labelGreek: 'ΚΑΡΔΙΟΛΟΓΟΣ',
  },
  {
    value: 3930,
    label: 'DERMATOLOGIST',
    labelGreek: 'ΔΕΡΜΑΤΟΛΟΓΟΣ',
  },
  {
    value: 3931,
    label: 'DIETICIAN',
    labelGreek: 'ΔΙΑΙΤΟΛΟΓΟΣ',
  },
  {
    value: 3932,
    label: 'DOCTOR (MEDICAL)',
    labelGreek: 'ΙΑΤΡΟΣ (ΙΑΤΡΙΚΗ)',
  },
  {
    value: 3933,
    label: 'DOCTOR (PHYSICIAN)',
    labelGreek: 'ΙΑΤΡΟΣ ( ΠΑΘΟΛΟΓΟΣ )',
  },
  {
    value: 3934,
    label: 'DOCTOR ASSISTANTS',
    labelGreek: 'ΒΟΗΘΟΣ ΙΑΤΡΟΥ',
  },
  {
    value: 3935,
    label: 'DOCTOR(MD)',
    labelGreek: 'ΙΑΤΡΟΣ ( MD )',
  },
  {
    value: 3936,
    label: 'DOCTOR(PHD)',
    labelGreek: 'ΙΑΤΡΟΣ ( PHD)',
  },
  {
    value: 3937,
    label: 'EMBRYOLOGIST',
    labelGreek: 'ΕΜΒΡΥΟΛΟΓΟΣ',
  },
  {
    value: 3938,
    label: 'GYNECOLOGIST',
    labelGreek: 'ΓΥΝΑΙΚΟΛΟΓΟΣ',
  },
  {
    value: 3939,
    label: 'HOSPITALS/NURSES',
    labelGreek: 'ΝΟΣΟΚΟΜΕΙΑ / ΝΟΣΗΛΕΥΤΕΣ',
  },
  {
    value: 3940,
    label: 'INTERNIST',
    labelGreek: 'ΠΑΘΟΛΟΓΟΣ',
  },
  {
    value: 3942,
    label: 'MEDICAL SALESMAN REPRESENTATIVE',
    labelGreek: 'ΙΑΤΡΙΚΟΣ ΕΠΙΣΚΕΠΤΗΣ',
  },
  {
    value: 3943,
    label: 'NURSE',
    labelGreek: 'ΝΟΣΗΛΕΥΤΡΙΑ',
  },
  {
    value: 3944,
    label: 'ONCOLOGIST',
    labelGreek: 'ΟΓΚΟΛΟΓΟΣ',
  },
  {
    value: 3945,
    label: 'OPHTHALMOLOGIST',
    labelGreek: 'ΟΦΘΑΛΜΙΑΤΡΟΣ',
  },
  {
    value: 3946,
    label: 'OPTICIAN (ALSO OPTOMETRIST)',
    labelGreek: 'ΟΠΤΙΚΟΣ (ΕΠΙΣΗΣ ΟΠΤΟΜΕΤΡΗΤΗΣ )',
  },
  {
    value: 3947,
    label: 'OPTOMETRIST',
    labelGreek: 'ΟΦΘΑΛΜΟΜΕΤΡΗΣ',
  },
  {
    value: 3948,
    label: 'ORTHOPAEDIST',
    labelGreek: 'ΟΡΘΟΠΕΔΙΚΟΣ',
  },
  {
    value: 3949,
    label: 'OSTEOPATH',
    labelGreek: 'ΧΕΙΡΟΠΡΑΚΤΩΡ',
  },
  {
    value: 3950,
    label: 'OTORHINOLARYNGOLOGIST',
    labelGreek: 'ΩΤΟΡΙΝΟΛΑΡΥΓΓΟΛΟΓΟΣ',
  },
  {
    value: 3951,
    label: 'PAEDIATRICIAN',
    labelGreek: 'ΠΑΙΔΙΑΤΡΟΣ',
  },
  {
    value: 3952,
    label: 'PARAMEDIC',
    labelGreek: 'ΔΙΑΣΩΣΤΗΣ',
  },
  {
    value: 3953,
    label: 'PATHOLOGIST',
    labelGreek: 'ΠΑΘΟΛΟΓΟΣ',
  },
  {
    value: 3954,
    label: 'PEDIATRICIAN',
    labelGreek: 'ΠΑΙΔΙΑΤΡΟΣ',
  },
  {
    value: 3955,
    label: 'PHARMACIST',
    labelGreek: 'ΦΑΡΜΑΚΟΠΟΙΟΣ',
  },
  {
    value: 3956,
    label: 'PHYSICIAN ASSISTANT',
    labelGreek: 'ΒΟΗΘΟΣ ΦΥΣΙΟΘΕΡΑΠΕΥΤΗ',
  },
  {
    value: 3957,
    label: 'PHYSIOGNOMIST',
    labelGreek: 'ΦΥΣΙΟΓΝΩΜΙΣΤΗΣ',
  },
  {
    value: 3958,
    label: 'PLASTIC SURGEON',
    labelGreek: 'ΠΛΑΣΤΙΚΟΣ ΧΕΙΡΟΥΡΓΟΣ',
  },
  {
    value: 3959,
    label: 'PLAYGROUPS / NURSERIES',
    labelGreek: 'ΒΡΕΦΟΚΟΜΙΚΟΙ ΣΤΑΘΜΟΙ',
  },
  {
    value: 3960,
    label: 'PROCTOLOGIST',
    labelGreek: 'ΠΡΟΚΤΩΛΟΓΟΣ',
  },
  {
    value: 3961,
    label: 'PSYCHIATRIST',
    labelGreek: 'ΨΥΧΙΑΤΡΟΣ',
  },
  {
    value: 3962,
    label: 'PSYCHOANALYST',
    labelGreek: 'ΨΥΧΑΝΑΛΥΤΗΣ',
  },
  {
    value: 3963,
    label: 'PSYCHODRAMATIST',
    labelGreek: 'ΨΥΧΟΔΡΑΜΑΤΙΣΤΗΣ',
  },
  {
    value: 3964,
    label: 'PSYCHOLOGIST',
    labelGreek: 'ΨΥΧΟΛΟΓΟΣ',
  },
  {
    value: 3965,
    label: 'PSYCHOTHERAPIST',
    labelGreek: 'ΨΥΧΟΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 3966,
    label: 'RADIOLOGIST',
    labelGreek: 'ΑΚΤΙΝΟΛΟΓΟΣ',
  },
  {
    value: 3967,
    label: 'REFLEXOLOGIST',
    labelGreek: 'ΡΕΦΛΕΞΟΛΟΓΟΣ',
  },
  {
    value: 3968,
    label: 'SEXOLOGIST',
    labelGreek: 'ΣΕΞΟΛΟΓΟΣ',
  },
  {
    value: 3969,
    label: 'SPEECH THERAPIST',
    labelGreek: 'ΛΟΓΟΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 3970,
    label: 'SURGEON',
    labelGreek: 'ΧΕΙΡΟΥΡΓΟΣ',
  },
  {
    value: 3971,
    label: 'UROLOGIST',
    labelGreek: 'ΟΥΡΟΛΟΓΟΣ',
  },
  {
    value: 3972,
    label: 'VETERINARIAN',
    labelGreek: 'ΚΤΗΝΙΑΤΡΟΣ',
  },
  {
    value: 3973,
    label: 'VETERINARY SURGEONS AND ATTENDANTS',
    labelGreek: 'ΧΕΙΡΟΥΡΓΟΣ ΚΤΗΝΙΑΤΡΟΣ (ΚΑΙ ΣΥΝΟΔΟΣ)',
  },
  {
    value: 3974,
    label: 'WET NURSE',
    labelGreek: 'ΤΡΟΦΟΣ',
  },
  {
    value: 3975,
    label: 'BIOCHEMIST',
    labelGreek: 'ΒΙΟΧΗΜΙΚΟΣ',
  },
  {
    value: 3976,
    label: 'BIOENGINEER',
    labelGreek: 'BIOΜΗΧΑΝΙΚΟΣ',
  },
  {
    value: 3977,
    label: 'BIOLOGIST',
    labelGreek: 'ΒΙΟΛΟΓΟΣ',
  },
  {
    value: 3978,
    label: 'BIOMEDICAL SCIENTIST',
    labelGreek: 'ΒΙΟΪΑΤΡΙΚΟΣ ΕΠΙΣΤΗΜΟΝΑΣ',
  },
  {
    value: 3979,
    label: 'ENTOMOLOGIST',
    labelGreek: 'ΕΝΤΟΜΟΛΟΓΟΣ',
  },
  {
    value: 3980,
    label: 'ENVIRONMENTAL SCIENTIST',
    labelGreek: 'ΠΕΡΙΒΑΛΛΟΝΤΙΚΟΣ ΕΠΙΣΤΗΜΟΝΑΣ',
  },
  {
    value: 3981,
    label: 'INVENTOR',
    labelGreek: 'ΕΦΕΥΡΕΤΗΣ',
  },
  {
    value: 3982,
    label: 'MICROBIOLOGIST',
    labelGreek: 'ΜΙΚΡΟΒΙΟΛΟΓΟΣ',
  },
  {
    value: 3983,
    label: 'OCCULT SCIENTIST',
    labelGreek: 'ΑΠΟΚΡΥΦΟΣ ΕΠΙΣΤΗΜΟΝΑΣ',
  },
  {
    value: 3984,
    label: 'ORNITHOLOGIST',
    labelGreek: 'ΟΡΝΙΘΟΛΟΓΟΣ',
  },
  {
    value: 3985,
    label: 'PHYSICIAN',
    labelGreek: 'ΦΥΣΙΚΟΣ',
  },
  {
    value: 3986,
    label: 'PHYSICIST',
    labelGreek: 'ΦΥΣΙΚΟΣ',
  },
  {
    value: 3987,
    label: 'PHYSIOLOGIST',
    labelGreek: 'ΦΥΣΙΟΛΟΓΟΣ',
  },
  {
    value: 3988,
    label: 'RESEARCHER',
    labelGreek: 'ΕΡΕΥΝΗΤΗΣ',
  },
  {
    value: 3989,
    label: 'ARCHER',
    labelGreek: 'ΤΟΞΟΤΗΣ',
  },
  {
    value: 3990,
    label: 'ATHLETE',
    labelGreek: 'ΑΘΛΗΤΗΣ',
  },
  {
    value: 3991,
    label: 'BODYGUARD',
    labelGreek: 'ΣΩΜΑΤΟΦΥΛΑΚΑΣ',
  },
  {
    value: 3992,
    label: 'FITNESS INSTRUCTOR',
    labelGreek: 'ΓΥΜΝΑΣΤΗΣ',
  },
  {
    value: 3993,
    label: 'FOOTBALLER',
    labelGreek: 'ΠΟΔΟΣΦΑΙΡΙΣΤΗΣ',
  },
  {
    value: 3994,
    label: 'DIVERS',
    labelGreek: 'ΔΥΤΕΣ',
  },
  {
    value: 3995,
    label: 'COACH PROPRIETORS',
    labelGreek: 'ΠΡΟΠΟΝΗΤΕΣ',
  },
  {
    value: 3996,
    label: 'GOLF EMPLOYEES',
    labelGreek: 'ΕΡΓΑΖΟΜΕΝΟΙ ΓΚΟΛΦ',
  },
  {
    value: 3997,
    label: 'GYMNASIUM',
    labelGreek: 'ΓΥΜΝΑΣΤΗΡΙΟ',
  },
  {
    value: 3998,
    label: 'RIDERS',
    labelGreek: 'ΑΝΑΒΑΤΕΣ',
  },
  {
    value: 3999,
    label: 'SPORTS',
    labelGreek: 'ΑΘΛΗΤΙΣΜΟΣ',
  },
  {
    value: 4000,
    label: 'TRAINERS',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΕΣ',
  },
  {
    value: 4001,
    label: 'HUNTER',
    labelGreek: 'ΚΥΝΗΓΟΣ',
  },
  {
    value: 4002,
    label: 'MARTIAL ARTIST',
    labelGreek: 'ΠΟΛΕΜΙΚΕΣ ΤΕΧΝΕΣ',
  },
  {
    value: 4003,
    label: 'PERSONAL / SPORTS TRAINER',
    labelGreek: 'ΠΡΟΣΩΠΙΚΟΣ ΓΥΜΝΑΣΤΗΣ',
  },
  {
    value: 4004,
    label: 'REFEREE',
    labelGreek: 'ΔΙΑΙΤΗΤΗΣ',
  },
  {
    value: 4005,
    label: 'SWIMMER',
    labelGreek: 'ΚΟΛΥΜΒΗΤΗΣ',
  },
  {
    value: 4006,
    label: 'TENNIS PLAYER',
    labelGreek: 'ΤΕΝΙΣΤΑΣ',
  },
  {
    value: 4007,
    label: 'TAXIDERMIST',
    labelGreek: 'ΤΑΡΙΧΕΥΤΗΣ',
  },
  {
    value: 4008,
    label: 'MEDICAL TECHNOLOGIST',
    labelGreek: 'ΙΑΤΡΙΚΟΣ ΤΕΧΝΙΚΟΣ',
  },
  {
    value: 4009,
    label: 'ACUPUNCTURE',
    labelGreek: 'ΒΕΛΟΝΙΣΜΟΣ',
  },
  {
    value: 4010,
    label: 'AROMATHERAPY',
    labelGreek: 'ΑΡΩΜΑΤΟΘΕΡΑΠΕΙΑ',
  },
  {
    value: 4011,
    label: 'CARER',
    labelGreek: 'ΦΡΟΝΤΙΣΤΗΣ',
  },
  {
    value: 4012,
    label: 'CHIROPRACTOR',
    labelGreek: 'ΧΕΙΡΟΠΡΑΚΤΟΡΑΣ',
  },
  {
    value: 4013,
    label: 'ERGONOMIST',
    labelGreek: 'ΕΡΓΟΝΟΜΟΣ',
  },
  {
    value: 4014,
    label: 'HOMEO PATH LOCUM - GENERAL PRACTIONER',
    labelGreek: 'ΓΕΝΙΚΗΣ ΙΑΤΡΙΚΗΣ',
  },
  {
    value: 4015,
    label: 'HYPNOTIST',
    labelGreek: 'ΥΠΝΩΤΙΣΤΗΣ',
  },
  {
    value: 4016,
    label: 'ILLUSIONIST',
    labelGreek: 'ΑΥΘΥΠΟΒΟΛΕΑΣ',
  },
  {
    value: 4017,
    label: 'MASSAGE',
    labelGreek: 'ΜΑΣΑΖ',
  },
  {
    value: 4018,
    label: 'MASSAGE THERAPIST',
    labelGreek: 'ΘΕΡΑΠΕΥΤΗΣ ΜΑΣΑΖ',
  },
  {
    value: 4019,
    label: 'OCCUPATIONAL THERAPIST',
    labelGreek: 'ΕΡΓΟΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 4020,
    label: 'PHYSICAL THERAPIST',
    labelGreek: 'ΦΥΣΙΟΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 4021,
    label: 'PHYSIOTHERAPIST',
    labelGreek: 'ΦΥΣΙΚΟΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 4022,
    label: 'THERAPIST',
    labelGreek: 'ΘΕΡΑΠΕΥΤΗΣ',
  },
  {
    value: 4023,
    label: 'ACCOUNTANTS / CONTROLLERS',
    labelGreek: 'ΛΟΓΙΣΤΕΣ / ΕΛΕΓΚΤΕΣ',
  },
  {
    value: 4024,
    label: 'ADVOCATE',
    labelGreek: 'ΕΙΣΑΓΓΕΛΕΑΣ',
  },
  {
    value: 4025,
    label: 'AUDITOR',
    labelGreek: 'ΕΛΕΓΚΤΗΣ',
  },
  {
    value: 4026,
    label: 'BOOK KEEPER',
    labelGreek: 'ΛΟΓΙΣΤΗΣ',
  },
  {
    value: 4027,
    label: 'CHARTERED VALUER',
    labelGreek: 'ΟΡΚΩΤΟΣ ΕΚΤΙΜΗΤΗΣ',
  },
  {
    value: 4028,
    label: 'ACTUARY',
    labelGreek: 'ΑΝΑΛΟΓΙΣΤΗΣ',
  },
  {
    value: 4029,
    label: 'BANK EMPLOYEES',
    labelGreek: 'ΤΡΑΠΕΖΙΚΟΙ ΥΠΑΛΛΗΛΟΙ',
  },
  {
    value: 4030,
    label: 'BANKER',
    labelGreek: 'ΤΡΑΠΕΖΙΤΗΣ',
  },
  {
    value: 4031,
    label: 'CLAIMS OFFICER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΤΜΗΜΑΤΟΣ ΑΠΑΤΗΣΕΩΝ',
  },
  {
    value: 4032,
    label: 'CONSULTANT LEGAL',
    labelGreek: 'ΝΟΜΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 4033,
    label: 'PRIVATE DETECTIVE',
    labelGreek: 'ΙΔΙΩΤΙΚΟΣ ΝΤΕΤΕΚΤΙΒ',
  },
  {
    value: 4034,
    label: 'BROKER',
    labelGreek: 'ΜΕΣΙΤΗΣ',
  },
  {
    value: 4035,
    label: 'BUSINESS ANALYST',
    labelGreek: 'ΑΝΑΛΥΤΗΣ ΕΠΙΧΕΙΡΗΣΕΩΝ',
  },
  {
    value: 4036,
    label: 'ECONOMIST',
    labelGreek: 'ΟΙΚΟΝΟΜΟΛΟΓΟΣ',
  },
  {
    value: 4037,
    label: 'FINANCIAL ADVISER',
    labelGreek: 'ΧΡΗΜΑΤΟΟΙΚΟΝΟΜΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 4038,
    label: 'FINANCIAL ANALYST',
    labelGreek: 'ΟΙΚΟΝΟΜΙΚΟΣ ΑΝΑΛΥΤΗΣ',
  },
  {
    value: 4039,
    label: 'FINANCIER',
    labelGreek: 'ΧΡΗΜΑΤΟΔΟΤΗΣ',
  },
  {
    value: 4040,
    label: 'MORTGAGE ADVISER',
    labelGreek: 'ΚΤΗΜΑΤΙΚΗ ΣΥΜΒΟΥΛΟΣ',
  },
  {
    value: 4041,
    label: 'STOCKBROKER',
    labelGreek: 'ΧΡΗΜΑΤΙΣΤΗΣ',
  },
  {
    value: 4042,
    label: 'INSURANCE',
    labelGreek: 'ΑΣΦΑΛΙΣΗ',
  },
  {
    value: 4043,
    label: 'INSURANCE AGENT',
    labelGreek: 'ΑΣΦΑΛΙΣΤΙΚΟΣ ΠΡΑΚΤΟΡΑΣ',
  },
  {
    value: 4044,
    label: 'INSURANCE ASSESSOR',
    labelGreek: 'ΑΣΦΑΛΙΣΗ ΑΞΙΟΛΟΓΗΤΗ',
  },
  {
    value: 4045,
    label: 'INSURANCE CLAIMS MANAGEMENT',
    labelGreek: 'ΔΙΑΧΕΙΡΙΣΗ ΑΣΦΑΛΙΣΤΙΚΩΝ ΑΠΑΙΤΗΣΕΩΝ',
  },
  {
    value: 4046,
    label: 'INSURANCE COMPANIES - GRAPHICS / PRODUCERS',
    labelGreek: 'ΑΣΦΑΛΙΣΤΙΚΕΣ ΕΤΑΙΡΕΙΕΣ - ΓΡΑΦΙΚΑ / ΠΑΡΑΓΩΓΩΝ',
  },
  {
    value: 4047,
    label: 'INSURER',
    labelGreek: 'ΑΣΦΑΛΙΣΤΗΣ',
  },
  {
    value: 4048,
    label: 'INVESTMENT ANALYST',
    labelGreek: 'ΑΝΑΛΥΤΗΣ ΕΠΕΝΔΥΣΕΩΝ',
  },
  {
    value: 4049,
    label: 'INVESTMENT BANKER',
    labelGreek: 'ΤΡΑΠΕΖΙΤΗΣ ΕΠΕΝΔΥΣΕΩΝ',
  },
  {
    value: 4050,
    label: 'INVESTMENT BROKERS AND CONSULTANTS',
    labelGreek: 'ΜΕΣΙΤΕΣ ΕΠΕΝΔΥΣΕΩΝ ΚΑΙ ΣΥΜΒΟΥΛΟΙ',
  },
  {
    value: 4051,
    label: 'ARBITRATOR',
    labelGreek: 'ΔΙΑΙΤΗΤΗΣ',
  },
  {
    value: 4052,
    label: 'ATTORNEY AT LAW',
    labelGreek: 'ΔΙΚΗΓΟΡΟΣ',
  },
  {
    value: 4053,
    label: 'BAILIFF',
    labelGreek: 'ΔΙΚΑΣΤΙΚΟΣ ΚΛΗΤΗΡΑΣ',
  },
  {
    value: 4054,
    label: 'BARRISTER',
    labelGreek: 'ΔΙΚΗΓΟΡΟΣ',
  },
  {
    value: 4055,
    label: 'CLERKS',
    labelGreek: 'ΓΡΑΜΜΑΤΕΙΑΣ',
  },
  {
    value: 4056,
    label: 'COURT REGISTRAR',
    labelGreek: 'ΓΡΑΜΜΑΤΕΑΣ ΔΙΚΑΣΤΗΡΙΟΥ',
  },
  {
    value: 4057,
    label: 'JUDGE',
    labelGreek: 'ΔΙΚΑΣΤΗΣ',
  },
  {
    value: 4058,
    label: 'LAWYERS',
    labelGreek: 'ΔΙΚΗΓΟΡΟΙ',
  },
  {
    value: 4059,
    label: 'SOLICITOR',
    labelGreek: 'ΔΙΚΗΓΟΡΟΣ',
  },
  {
    value: 4060,
    label: 'ADJUSTER',
    labelGreek: 'ΡΥΘΜΙΣΤΗΣ',
  },
  {
    value: 4061,
    label: 'LOSS ADJUSTER',
    labelGreek: 'ΡΥΘΜΙΣΤΗΣ ΖΗΜΙΩΝ',
  },
  {
    value: 4062,
    label: 'LOSS ASSESSOR',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ ΖΗΜΙΩΝ',
  },
  {
    value: 4063,
    label: 'FINANCIAL MANAGER',
    labelGreek: 'ΟΙΚΟΝΟΜΙΚΟΣ ΔΙΕΥΘΥΝΤΗΣ',
  },
  {
    value: 4064,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΕΡΓΟΥ',
  },
  {
    value: 4065,
    label: 'QUALITY CONTROLLER',
    labelGreek: 'ΕΛΕΓΚΤΗΣ ΤΗΣ ΠΟΙΟΤΗΤΑΣ',
  },
  {
    value: 4066,
    label: 'ASSESSOR',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ',
  },
  {
    value: 4067,
    label: 'ESTATE AGENT',
    labelGreek: 'ΓΡΑΦΕΙΟ ΑΚΙΝΗΤΩΝ (ΠΡΑΚΤΟΡΑΣ)',
  },
  {
    value: 4068,
    label: 'REAL ESTATE DEVELOPER',
    labelGreek: 'DEVELOPER ΑΚΙΝΗΤΩΝ (ΓΡΑΦΕΙΟ)',
  },
  {
    value: 4069,
    label: 'SUPERINTENDENT OF BUILDINGS AND GROUNDS',
    labelGreek: 'ΕΠΙΣΤΑΤΗΣ ΚΤΙΡΙΩΝ ΚΑΙ ΕΔΑΦΩΝ',
  },
  {
    value: 4070,
    label: 'STATISTICIAN',
    labelGreek: 'ΣΤΑΤΙΣΤΙΚΟΛΟΓΟΣ',
  },
  {
    value: 4071,
    label: 'ANALYSTS / SURVEYOR',
    labelGreek: 'ΑΝΑΛΥΤΗΣ / ΕΠΙΜΕΤΡΗΤΗΣ',
  },
  {
    value: 4072,
    label: 'SURVEYOR',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ',
  },
  {
    value: 4073,
    label: 'SURVEYOR (INSURANCE)',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ ΑΣΦΑΛΙΣΗΣ',
  },
  {
    value: 4074,
    label: 'SURVEYOR (QUANTITY)',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ ΠΟΣΟΤΗΤΩΝ',
  },
  {
    value: 4075,
    label: 'SURVEYOR (RATING)',
    labelGreek: 'ΕΚΤΙΜΗΤΗΣ (ΒΑΘΜΟΝΟΜΗΣΗ)',
  },
  {
    value: 4076,
    label: 'UNDERWRITING AGENT',
    labelGreek: 'ΠΡΑΚΤΟΡΑΣ ΑΝΑΛΗΨΗΣ ΚΙΝΔΥΝΩΝ',
  },
  {
    value: 4077,
    label: 'ATHLETIC GOODS MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΑΘΛΗΤΙΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4078,
    label: 'BEDDING  MANUFACTURERS & MATTRESS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΚΡΕΒΑΤΙΑ  & ΣΤΡΩΜΑ',
  },
  {
    value: 4079,
    label: 'BRUSH MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΒΟΥΡΤΣΩΝ',
  },
  {
    value: 4080,
    label: 'CHEMIST AND DRUGGIST MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΧΗΜΙΚΩΝ ΚΑΙ ΦΑΡΜΑΚΩΝ',
  },
  {
    value: 4081,
    label: 'CLOTH MANUFACTURER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΥΦΑΣΜΑΤΩΝ',
  },
  {
    value: 4082,
    label: 'DRESSMAKING (WHOLESALE)',
    labelGreek: 'ΕΝΔΥΣΗ (ΧΟΝΔΡΙΚΗ)',
  },
  {
    value: 4083,
    label: 'DRUG MANUFACTURER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΦΑΡΜΑΚΩΝ',
  },
  {
    value: 4084,
    label: 'FEATHER MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΦΤΕΡΩΝ',
  },
  {
    value: 4085,
    label: 'JEWELLERS/GOLDSMITHS (MANUFACTURERS)',
    labelGreek: 'ΧΡΥΣΟΧΟΟΟΙ / ΠΟΛΥΤΙΜΑ (ΚΑΤΑΣΚΕΥΑΣΤΕΣ)',
  },
  {
    value: 4086,
    label: 'KNITTERS',
    labelGreek: 'ΠΛΕΚΤΟΒΙΟΜΗΧΑΝΙΕΣ',
  },
  {
    value: 4087,
    label: 'MATTRESS MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΙ ΣΤΡΩΜΑ',
  },
  {
    value: 4088,
    label: 'PERFUME MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΑΡΩΜΑΤΩΝ',
  },
  {
    value: 4089,
    label: 'SOAP MAKERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΣΑΠΟΥΝΙΩΝ',
  },
  {
    value: 4090,
    label: 'TENT MAKERS',
    labelGreek: 'ΣΚΗΝΟΠΟΙΟΙ',
  },
  {
    value: 4091,
    label: 'WATCHMAKER',
    labelGreek: 'ΩΡΟΛΟΓΟΠΟΙΟΣ',
  },
  {
    value: 4092,
    label: 'BASKET MAKERS',
    labelGreek: 'ΚΑΛΑΘΟΠΟΙΟΙ',
  },
  {
    value: 4093,
    label: 'BOX MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΚΟΥΤΙΩΝ',
  },
  {
    value: 4094,
    label: 'BOX MANUFACTURING CORRUGATED',
    labelGreek: 'ΚΥΤΙΟΠΟΙΙΑ ΚΥΜΑΤΟΕΙΔΕΣ',
  },
  {
    value: 4095,
    label: 'BOX MANUFACTURING PAPERBOARD',
    labelGreek: 'ΚΥΤΙΟΠΟΙΙΑ ΧΑΡΤΟΝΙ',
  },
  {
    value: 4096,
    label: 'CASE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΥΠΟΘΕΣΕΩΝ',
  },
  {
    value: 4097,
    label: 'PACKING CASE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΗΣ ΣΥΣΚΕΥΑΣΙΩΝ',
  },
  {
    value: 4098,
    label: 'CAN MANUFACTURING',
    labelGreek: 'ΒΙΟΜΗΧΑΝΙΑ ΑΛΟΥΜΙΝΕΝΙΩΝ ΚΟΥΤΙΩΝ',
  },
  {
    value: 4099,
    label: 'CANNING - FISH AND SEAFOOD',
    labelGreek: 'ΚΟΝΣΕΡΒΟΠΟΙΗΣΗ - ΨΑΡΙΑ ΚΑΙ ΨΑΡΙΚΑ',
  },
  {
    value: 4100,
    label: 'CANNING - FRUITS AND VEGETABLES',
    labelGreek: 'ΚΟΝΣΕΡΒΟΠΟΙΗΣΗ - ΦΡΟΥΤΑ ΚΑΙ ΛΑΧΑΝΙΚΑ',
  },
  {
    value: 4101,
    label: 'CARPENTER',
    labelGreek: 'ΞΥΛΟΥΡΓΕΙΟ',
  },
  {
    value: 4102,
    label: 'CARPET FITTER',
    labelGreek: 'ΕΦΑΡΜΟΓΕΣ ΧΑΛΙΩΝ',
  },
  {
    value: 4103,
    label: 'CARPET REPAIRER',
    labelGreek: 'ΕΠΙΣΚΕΥΑΣΤΗΣ ΧΑΛΙΩΝ',
  },
  {
    value: 4104,
    label: 'CARPET UPHOLSTERER AND CURTAIN CLEANER',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΣ ΧΑΛΙΩΝ ΚΑΙ ΚΟΥΡΤΙΝΩΝ',
  },
  {
    value: 4105,
    label: 'BOTTLE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΜΠΟΥΚΑΛΙΩΝ',
  },
  {
    value: 4106,
    label: 'BOTTLING - SOFT DRINKS',
    labelGreek: 'ΕΜΦΙΑΛΩΣΕΙΣ ΑΝΑΨΥΚΤΙΚΩΝ',
  },
  {
    value: 4107,
    label: 'BREWERIES',
    labelGreek: 'ΖΥΘΟΠΟΙΙΑ',
  },
  {
    value: 4108,
    label: 'BREWERS',
    labelGreek: 'ΠΟΤΟΠΟΙΙΑ',
  },
  {
    value: 4109,
    label: 'COFFEE ROASTERS AND MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΗ ΚΑΙ ΚΑΒΟΥΡΔΙΣΜΑ ΚΑΦΕ',
  },
  {
    value: 4110,
    label: 'WINE MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΙ ΚΡΑΣΙΟΥ',
  },
  {
    value: 4111,
    label: 'WINEMAKER',
    labelGreek: 'ΟΙΝΟΠΟΙΟΣ',
  },
  {
    value: 4112,
    label: 'DRUM MANUFACTURERS (METAL)',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΜΕΤΑΛΛΩΝ',
  },
  {
    value: 4113,
    label: 'MUSICAL INSTRUMENT  DEALERS & MAKERS',
    labelGreek: 'ΕΜΠΟΡΟΙ & ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΜΟΥΣΙΚΩΝ ΟΡΓΑΝΩΝ',
  },
  {
    value: 4114,
    label: 'CHEESE MAKER',
    labelGreek: 'ΠΑΡΑΣΚΕΥΗ ΤΥΡΙΟΥ',
  },
  {
    value: 4115,
    label: 'BAKERIES',
    labelGreek: 'ΑΡΤΟΠΟΙΕΙΑ',
  },
  {
    value: 4116,
    label: 'BISCUIT MANUFACTURER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΜΠΙΣΚΟΤΩΝ',
  },
  {
    value: 4117,
    label: 'CATTLE FOOD AND FISH MEAL MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΓΕΥΜΑΤΩΝ ΓΙΑ ΒΟΟΕΙΔΗ ΚΑΙ ΨΑΡΙΑ',
  },
  {
    value: 4118,
    label: 'CHOCOLATE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΟΚΟΛΑΤΑΣ',
  },
  {
    value: 4119,
    label: 'CONFECTIONARY MANUFACTURING',
    labelGreek: 'ΠΑΡΑΣΚΕΥΗ ΕΙΔΩΝ ΖΑΧΑΡΟΠΛΑΣΤΙΚΗΣ',
  },
  {
    value: 4120,
    label: 'MACARONI FACTORIES',
    labelGreek: 'ΕΡΓΟΣΤΑΣΙΑ ΜΑΚΑΡΟΝΙΩΝ',
  },
  {
    value: 4121,
    label: 'PASTA MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΖΥΜΑΡΙΚΩΝ',
  },
  {
    value: 4122,
    label: 'SAUCE MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΣΑΛΤΣΑΣ',
  },
  {
    value: 4123,
    label: 'SAUSAGE MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΑΛΛΑΝΤΙΚΩΝ',
  },
  {
    value: 4124,
    label: 'VINEGAR MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΞΥΔΙΟΥ',
  },
  {
    value: 4125,
    label: 'YEAST MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΜΑΓΙΑΣ',
  },
  {
    value: 4126,
    label: 'BAROMETER MAKERS',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΒΑΡΟΜΕΤΡΩΝ',
  },
  {
    value: 4127,
    label: 'CANDLE MANUFACTURING',
    labelGreek: 'ΠΑΡΑΣΚΕΥΑΣΤΕΣ ΚΕΡΙΩΝ',
  },
  {
    value: 4128,
    label: 'COOPERS MAKERS',
    labelGreek: 'ΒΑΡΕΛΟΠΟΙΟΣ',
  },
  {
    value: 4129,
    label: 'EMBROIDERER',
    labelGreek: 'ΚΕΝΤΗΤΗΣ',
  },
  {
    value: 4130,
    label: 'EXPLOSIVES MANUFACTURERS  DISTRIBUTERS  SUPPLIERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΚΡΗΚΤΙΚΩΝ   ΔΙΑΝΟΜΕΙΣ  ΠΡΟΜΗΘΕΥΤΕΣ',
  },
  {
    value: 4131,
    label: 'FOUNDRIES - WHERE NO CASTING EXCEEDS 28LBS IN WEIGHT',
    labelGreek: 'ΧΥΤΗΡΙΑ - ΟΠΟΥ ΔΕΝ ΥΠΕΡΒΑΙΝΕΙ ΤΟ ΧΥΣΙΜΟ ΤΙΣ 28LBS ΣΕ ΒΑΡΟΣ',
  },
  {
    value: 4132,
    label: 'GAS MANUFACTURERS & SUPPLIERS  GAS HOLDER PAINTING & REPAIRING',
    labelGreek:
      ' : ΓΚΑΖΙ- ΚΑΤΑΣΚΕΥΑΣΤΕΣ & ΠΡΟΜΗΘΕΥΤΕΣ ΒΑΨΙΜΟ ΚΥΛΙΝΔΡΩΝ ΑΕΡΙΟΥ & ΕΠΙΣΚΕΥΕΣ',
  },
  {
    value: 4133,
    label: 'GELATINE  GLUE  SIZE & ISINGLASS MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΖΕΛΑΤΙΝΗ  ΚΟΛΛΑ & ΨΑΡΟΚΟΛΑ',
  },
  {
    value: 4134,
    label: 'GREASE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΓΡΑΣΟΥ',
  },
  {
    value: 4135,
    label: 'ICE CREAM MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΑΓΩΤΟΥ',
  },
  {
    value: 4136,
    label: 'ICE DEALERS AND MANUFACTURERS',
    labelGreek: 'ΕΜΠΟΡΟΙ ΚΑΙ ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΑΓΟΥ',
  },
  {
    value: 4137,
    label: 'KEY MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΚΛΕΙΔΙΩΝ',
  },
  {
    value: 4138,
    label: 'LAMP MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΛΑΜΠΩΝ',
  },
  {
    value: 4139,
    label: 'MANUFACTURER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ',
  },
  {
    value: 4140,
    label: 'MATCH MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΠΙΡΤΩΝ',
  },
  {
    value: 4141,
    label: 'NET MAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΙΚΤΥΩΝ',
  },
  {
    value: 4142,
    label: 'OIL MILLS (NOT MINERAL OILS)',
    labelGreek: 'ΕΛΑΙΟΤΡΙΒΕΙΑ (ΟΧΙ ΠΕΤΡΕΛΑΙΟΕΙΔΗ)',
  },
  {
    value: 4143,
    label: 'OILCAKE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΙΤΑΣ ΛΑΔΙΟΥ',
  },
  {
    value: 4144,
    label: 'PIPES MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΩΛΗΝΩΝ',
  },
  {
    value: 4145,
    label: 'PLASTIC GOODS MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΛΑΣΤΙΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4146,
    label: 'POLISH MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΤΙΛΒΩΣΗΣ',
  },
  {
    value: 4147,
    label: 'POTTERY MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΚΕΡΑΜΙΚΩΝ',
  },
  {
    value: 4148,
    label: 'SCIENTIFIC & SURGICAL INSTRUMENTS & APPLIANCE MAKERS',
    labelGreek:
      ' : ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΠΙΣΤΗΜΟΝΙΚΩΝ ΧΕΙΡΟΥΡΓΙΚΑ ΕΡΓΑΛΕΙΩΝ & ΣΥΣΚΕΥΩΝ',
  },
  {
    value: 4149,
    label: 'SPRING & AXLE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΛΑΤΗΡΙΩΝ & ΑΞΟΝΩΝ',
  },
  {
    value: 4150,
    label: 'TOOLMAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΡΓΑΛΕΙΩΝ',
  },
  {
    value: 4151,
    label: 'TWINE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΠΑΓΚΩΝ',
  },
  {
    value: 4152,
    label: 'UMBRELLA MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΟΜΠΡΕΛΩΝ',
  },
  {
    value: 4153,
    label: 'VAT MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΕΞΑΜΕΝΩΝ',
  },
  {
    value: 4154,
    label: 'WIRE ROPE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΣΥΡΜΑΤΟΣΧΟΙΝΩΝ',
  },
  {
    value: 4155,
    label: 'ALUMINIUM MANUFACTURER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΑΛΟΥΜΙΝΙΟΥ',
  },
  {
    value: 4156,
    label: 'ARTIFICIAL STONE MAKERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΤΕΧΝΗΤΗΣ ΠΕΤΡΑΣ',
  },
  {
    value: 4157,
    label: 'BOILERMAKER',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΛΕΒΗΤΑ',
  },
  {
    value: 4158,
    label: 'BRICK MANUFACTURING',
    labelGreek: 'ΠΑΡΑΓΩΓΗ ΤΟΥΒΛΩΝ',
  },
  {
    value: 4159,
    label: 'GRINDSTONE & MILLSTONE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΜΥΛΟΠΕΤΡΑΣ',
  },
  {
    value: 4160,
    label: 'GUN MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΙ ΟΠΛΩΝ',
  },
  {
    value: 4161,
    label: 'WEAPON SMITH',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΟΠΛΩΝ',
  },
  {
    value: 4162,
    label: 'LEATHER DRESSERS & DYERS',
    labelGreek: 'ΕΠΙΚΑΛΥΨΕΙΣ ΚΑΙ ΒΑΨΙΜΟ ΔΕΡΜΑΤΩΝ',
  },
  {
    value: 4163,
    label: 'LEATHER GOODS MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΔΕΡΜΑΤΙΝΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4164,
    label: 'LEATHER GOODS MANUFACTURERS',
    labelGreek: 'ΠΑΡΑΓΩΓΟΙ ΔΕΡΜΑΤΙΝΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4165,
    label: 'CYCLE & MOTORCYCLE COMPONENTS & ACCESSORY MAKERS',
    labelGreek:
      ' : ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΞΑΡΤΗΜΑΤΩΝ & ΑΞΕΣΟΥΑΡ ΠΟΔΗΛΑΤΩΝ & ΜΟΤΟΣΥΚΛΕΤΩΝ',
  },
  {
    value: 4166,
    label: 'CYCLE & MOTORCYCLE MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΟΔΗΛΑΤΩΝ ΚΑΙ ΜΟΤΟΠΟΔΗΛΑΤΩΝ',
  },
  {
    value: 4167,
    label: 'FARM MACHINERY MANUFACTURING',
    labelGreek: 'ΠΑΡΑΓΩΓΟΙ ΑΓΡΟΤΙΚΩΝ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 4168,
    label: 'SALT - PROCESS',
    labelGreek: 'ΕΠΕΞΕΡΓΑΣΤΕΣ ΑΛΑΤΙΟΥ',
  },
  {
    value: 4169,
    label: 'SAW MILLS',
    labelGreek: 'ΠΡΙΟΝΙΣΤΗΡΙΑ',
  },
  {
    value: 4170,
    label: 'SHOEMAKER',
    labelGreek: 'ΥΠΟΔΗΜΑΤΟΠΟΙΟΣ',
  },
  {
    value: 4171,
    label: 'CIGARETTE MANUFACTURER',
    labelGreek: 'ΠΑΡΑΓΩΓΟΣ ΤΣΙΓΑΡΩΝ',
  },
  {
    value: 4172,
    label: 'PIPE (TOBACCO) MAKERS & MOUNTERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΙΠΑΣ ΚΑΙ ΣΥΝΑΡΜΟΛΟΓΗΤΕΣ',
  },
  {
    value: 4173,
    label: 'TOBACCO MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΠΡΟΪΟΝΤΩΝ ΚΑΠΝΟΥ',
  },
  {
    value: 4174,
    label: 'PAPER MANUFACTURERS  DISTRIBUTERS AND SUPPLIERS',
    labelGreek: 'ΧΑΡΤΟΠΟΙΙΕΣ  ΔΙΑΝΟΜΕΙΣ ΚΑΙ ΠΡΟΜΗΘΕΥΤΕΣ',
  },
  {
    value: 4175,
    label: 'EMBOSSERS',
    labelGreek: 'ΕΚΤΥΠΩΤΕΣ',
  },
  {
    value: 4176,
    label: 'INK MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΜΕΛΑΝΙΟΥ',
  },
  {
    value: 4177,
    label: 'IVORY MAKERS & PEARL CUTTERS',
    labelGreek: 'ΕΠΕΞΕΡΓΑΣΙΑ ΕΛΕΦΑΝΤΟΣΤΟΥ ΚΑΙ ΜΑΡΓΑΡΙΤΑΡΙΩΝ',
  },
  {
    value: 4178,
    label: 'LEAD PENCIL MAKERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΩΝ ΜΟΛΥΒΙΟΥ',
  },
  {
    value: 4179,
    label: 'PAPER MANUFACTURERS',
    labelGreek: 'ΧΑΡΤΟΒΙΟΜΗΧΑΝΙΕΣ',
  },
  {
    value: 4180,
    label: 'PAPER MANUFACTURERS  DISTRIBUTERS  SUPPLIERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΧΑΡΤΙΟΥ  ΔΙΑΝΟΜΕΙΣ  ΠΡΟΜΗΘΕΥΤΕΣ',
  },
  {
    value: 4181,
    label: 'PHOTO BLOCK & PROCESS BLOCK MANUFACTURE',
    labelGreek: 'ΚΑΤΑΣΚΕΥΗ ΦΩΤΟΓΡΑΦΙΑΣ',
  },
  {
    value: 4182,
    label: 'PICTURE FRAME MAKERS & GILDERS',
    labelGreek: 'ΚΟΡΝΙΖΟΠΟΙΟΙ',
  },
  {
    value: 4183,
    label: 'STATIONERS (MANUFACTURING AND/OR WHOLESALE)',
    labelGreek: 'ΧΑΡΤΟΠΩΛΕΙΑ (ΠΑΡΑΣΚΕΥΑΣΤΕΣ Ή / ΚΑΙ ΧΟΝΔΡΙΚΗ ΠΩΛΗΣΗ)',
  },
  {
    value: 4184,
    label: 'WAREHOUSE WORKERS',
    labelGreek: 'ΕΡΓΑΤΕΣ ΑΠΟΘΗΚΗΣ',
  },
  {
    value: 4185,
    label: 'WAREHOUSEMEN - HEAVY GOODS',
    labelGreek: 'ΑΠΟΘΗΚΑΡΙΟΙ - ΒΑΡΕΑ ΦΟΡΤΗΓΑ',
  },
  {
    value: 4186,
    label: 'WAREHOUSEMEN - LIGHT PACKAGES AND GOODS',
    labelGreek: 'ΑΠΟΘΗΚΑΡΙΟΙ - ΕΛΑΦΡΙΑ ΠΑΚΕΤΑ ΚΑΙ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4187,
    label: 'WAREHOUSEMEN - MACHINE OPERATORS OR ELEVATORS',
    labelGreek: 'ΑΠΟΘΗΚΑΡΙΟΙ - ΧΕΙΡΙΣΤΕΣ ΜΗΧΑΝΗΜΑΤΩΝ Η ΑΝΕΛΚΥΣΤΗΡΩΝ',
  },
  {
    value: 4188,
    label: 'FURNITURE MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΕΠΙΠΛΩΝ',
  },
  {
    value: 4189,
    label: 'FURNITURE MANUFACTURING - WOOD',
    labelGreek: 'ΚΑΤΑΣΚΕΥΗ ΕΠΙΠΛΩΝ ΑΠΟ ΞΥΛΟ',
  },
  {
    value: 4190,
    label: 'PLYWOOD MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΚΟΝΤΡΑ ΠΛΑΚΕ',
  },
  {
    value: 4191,
    label: 'TILE MANUFACTURERS',
    labelGreek: 'ΠΛΑΚΟΠΟΙΙΑ',
  },
  {
    value: 4192,
    label: 'TILERS FLOOR AND WALL',
    labelGreek: 'ΠΛΑΚΕΣ ΔΑΠΕΔΟΥ ΚΑΙ ΤΟΙΧΟΥ',
  },
  {
    value: 4193,
    label: 'TILERS ROOF',
    labelGreek: 'ΠΛΑΚΕΣ ΣΤΕΓΗΣ',
  },
  {
    value: 4194,
    label: 'UPHOLSTERY AND FURNITURE REPAIRER',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΕΠΙΠΛΩΝ ΚΑΙ ΤΑΠΕΤΣΑΡΙΕΣ',
  },
  {
    value: 4195,
    label: 'VENEER MANUFACTURES',
    labelGreek: 'ΚΑΤΑΣΚΕΥΕΣ ΚΑΠΛΑΜΑ',
  },
  {
    value: 4196,
    label: 'WIRE DRAWERS MANUFACTURERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ  ΣΥΡΤΑΡΙΩΝ',
  },
  {
    value: 4197,
    label: 'WOOD MANUFACTURERS  DISTRIBUTORS AND SUPPLIERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΞΥΛΟΥ  ΔΙΑΝΟΜΕΙΣ ΚΑΙ ΠΡΟΜΗΘΕΥΤΕΣ',
  },
  {
    value: 4198,
    label: 'WOODWARE MANUFACTURERS AND TURNERS',
    labelGreek: 'ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΞΥΛΙΝΩΝ ΕΙΔΩΝ ΚΑΙ ΤΟΡΝΕΥΤΕΣ',
  },
  {
    value: 4199,
    label: 'MACHINE OPERATORS IN FACTORY',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ ΜΗΧΑΝΗΣ ΣΕ ΕΡΓΟΣΤΑΣΙΟ',
  },
  {
    value: 4200,
    label: 'SKILLED WORKERS - HEAVY INDUSTRY',
    labelGreek: 'ΕΙΔΙΚΕΥΜΕΝΟΥΣ ΕΡΓΑΤΕΣ - ΒΑΡΙΑ ΒΙΟΜΗΧΑΝΙΑ',
  },
  {
    value: 4201,
    label: 'SKILLED WORKERS - LIGHT INDUSTRY',
    labelGreek: 'ΕΙΔΙΚΕΥΜΕΝΟΥΣ ΕΡΓΑΤΕΣ - ΕΛΑΦΡΙΑ ΒΙΟΜΗΧΑΝΙΑ',
  },
  {
    value: 4202,
    label: 'AUTHOR',
    labelGreek: 'ΣΥΓΓΡΑΦΕΑΣ',
  },
  {
    value: 4203,
    label: 'BIOGRAPHER',
    labelGreek: 'ΒΙΟΓΡΑΦΟΣ',
  },
  {
    value: 4204,
    label: 'NOVELIST',
    labelGreek: 'ΜΥΘΙΣΤΟΡΙΟΓΡΑΦΟΣ',
  },
  {
    value: 4205,
    label: 'PLAYWRIGHT',
    labelGreek: 'ΔΡΑΜΑΤΟΥΡΓΟΣ',
  },
  {
    value: 4206,
    label: 'SCREENWRITER',
    labelGreek: 'ΣΕΝΑΡΙΟΓΡΑΦΟΣ',
  },
  {
    value: 4207,
    label: 'SIGNWRITER',
    labelGreek: 'ΤΑΜΠΕΛΟΓΡΑΦΟΣ',
  },
  {
    value: 4208,
    label: 'WRITER',
    labelGreek: 'ΣΥΓΓΡΑΦΕΑΣ',
  },
  {
    value: 4209,
    label: 'LYRICIST',
    labelGreek: 'ΣΤΙΧΟΥΡΓΟΣ',
  },
  {
    value: 4210,
    label: 'CAMERAMAN',
    labelGreek: 'ΚΑΜΕΡΑΜΑΝ',
  },
  {
    value: 4211,
    label: 'COMPOSITOR',
    labelGreek: 'ΣΤΟΙΧΕΙΟΘΕΤΗΣ',
  },
  {
    value: 4212,
    label: 'JOURNALISTS',
    labelGreek: 'ΔΗΜΟΣΙΟΓΡΑΦΟΙ',
  },
  {
    value: 4213,
    label: 'LINOTYPER',
    labelGreek: 'ΤΥΠΟΓΡΑΦΟΣ',
  },
  {
    value: 4214,
    label: 'PRESENTER',
    labelGreek: 'ΠΑΡΟΥΣΙΑΣΤΗΣ',
  },
  {
    value: 4215,
    label: 'TYPESETTER',
    labelGreek: 'ΣΤΟΙΧΕΙΟΘΕΤΗΣ',
  },
  {
    value: 4216,
    label: 'COLUMNIST',
    labelGreek: 'ΑΡΘΡΟΓΡΑΦΟΣ',
  },
  {
    value: 4217,
    label: 'CORRESPONDENT',
    labelGreek: 'ΑΝΤΑΠΟΚΡΙΤΕΣ',
  },
  {
    value: 4218,
    label: 'NEWSAGENTS',
    labelGreek: 'ΕΦΗΜΕΡΙΔΟΠΩΛΗΣ',
  },
  {
    value: 4219,
    label: 'NEWSCASTER',
    labelGreek: 'ΡΕΠΟΡΤΕΡ',
  },
  {
    value: 4220,
    label: 'PRESS OFFICER',
    labelGreek: 'ΥΠΑΛΛΗΛΟΣ ΤΥΠΟΥ',
  },
  {
    value: 4221,
    label: 'PRESSMAN',
    labelGreek: 'ΠΙΕΣΤΗΣ',
  },
  {
    value: 4222,
    label: 'PROOF-READER',
    labelGreek: 'ΔΙΟΡΘΩΤΗΣ',
  },
  {
    value: 4223,
    label: 'REPORTER',
    labelGreek: 'ΡΕΠΟΡΤΕΡ',
  },
  {
    value: 4224,
    label: 'NOTARY',
    labelGreek: 'ΣΥΜΒΟΛΑΙΟΓΡΑΦΟΣ',
  },
  {
    value: 4225,
    label: 'BOOKBINDERS',
    labelGreek: 'ΒΙΒΛΙΟΔΕΤΕΣ',
  },
  {
    value: 4226,
    label: 'TYPIST',
    labelGreek: 'ΔΑΚΤΥΛΟΓΡΑΦΟΣ',
  },
  {
    value: 4227,
    label: 'PRINTER',
    labelGreek: 'ΕΚΤΥΠΩΤΗΣ',
  },
  {
    value: 4228,
    label: 'BOOK PUBLISHING',
    labelGreek: 'ΕΚΔΟΣΕΙΣ ΒΙΒΛΙΩΝ',
  },
  {
    value: 4229,
    label: 'EDITOR OR PUBLISHER OF MAGAZINES OR NEWSPAPERS',
    labelGreek: 'ΕΠΙΜΕΛΕΙΑ Ή ΕΚΔΟΣΗ ΠΕΡΙΟΔΙΚΩΝ Ή ΕΦΗΜΕΡΙΔΩΝ',
  },
  {
    value: 4230,
    label: 'DESK TOP PUBLISHER',
    labelGreek: 'ΕΚΔΟΤΗΣ ΕΞΩΦΥΛΩΝ',
  },
  {
    value: 4231,
    label: 'EDITOR OF BOOKS',
    labelGreek: 'ΕΠΙΜΕΛΕΙΑ ΒΙΒΛΙΩΝ',
  },
  {
    value: 4232,
    label: 'MAGAZINE EDITOR',
    labelGreek: 'ΕΚΔΟΤΗΣ ΠΕΡΙΟΔΙΚΟΥ',
  },
  {
    value: 4233,
    label: 'PUBLISHER',
    labelGreek: 'ΕΚΔΟΤΗΣ',
  },
  {
    value: 4234,
    label: 'NIGHT CLUB BARTENDER',
    labelGreek: 'ΜΠΑΡΜΑΝ ΣΕ ΝΥΚΤΕΡΙΝΟ ΚΕΝΤΡΟ',
  },
  {
    value: 4235,
    label: 'BURGER BARS',
    labelGreek: 'ΧΑΜΠΟΥΡΓΕΡΑΔΙΚΟ',
  },
  {
    value: 4236,
    label: 'SANDWICH BAR',
    labelGreek: 'ΣΑΝΤΟΥΙΤΣ ΜΠΑΡ',
  },
  {
    value: 4237,
    label: 'SNACK BAR',
    labelGreek: 'ΣΝΑΚ ΜΠΑΡ',
  },
  {
    value: 4238,
    label: 'NIGHT CLUB WAITER',
    labelGreek: 'ΓΚΑΡΣΟΝΙ ΣΕ ΝΥΚΤΕΡΙΝΟ ΚΕΝΤΡΟ',
  },
  {
    value: 4239,
    label: 'WINE BAR',
    labelGreek: 'ΟΙΝΟΠΩΛΕΙΟ',
  },
  {
    value: 4240,
    label: 'CAFÉ',
    labelGreek: 'ΚΑΦΕΤΕΡΙΑ',
  },
  {
    value: 4241,
    label: 'COFFEE SHOP',
    labelGreek: 'ΚΑΦΕΝΕΙΟ',
  },
  {
    value: 4242,
    label: 'INTERNET CAFÉ',
    labelGreek: 'ΚΑΦΕΤΕΡΙΑ ΔΙΑΔΙΚΤΥΟΥ',
  },
  {
    value: 4243,
    label: 'CAMPGROUNDS',
    labelGreek: 'ΚΑΤΑΣΚΗΝΩΣΕΙΣ',
  },
  {
    value: 4244,
    label: 'CATERER',
    labelGreek: 'ΤΡΟΦΟΔΟΤΗΣ  ΕΤΟΙΜΑ ΦΑΓΗΤΑ',
  },
  {
    value: 4245,
    label: 'CATERER EXCLUDING MOBILE VANS',
    labelGreek: 'ΕΤΟΙΜΑ ΦΑΓΗΤΑ ΠΛΗΝ ΤΩΝ ΚΙΝΗΤΩΝ ΦΟΡΤΗΓΩΝ',
  },
  {
    value: 4246,
    label: 'CATERER INCLUDING MOBILE VANS',
    labelGreek: 'ΕΤΟΙΜΑ ΦΑΓΗΤΑ  ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΤΩΝ ΚΙΝΗΤΩΝ ΦΟΡΤΗΓΩΝ',
  },
  {
    value: 4247,
    label: 'AU - PAIR',
    labelGreek: 'ΑU - PAIR',
  },
  {
    value: 4248,
    label: 'DISHWASHER',
    labelGreek: 'ΠΛΥΝΤΗΡΙΟ ΠΙΑΤΩΝ',
  },
  {
    value: 4249,
    label: 'HOUSEKEEPER',
    labelGreek: 'ΟΙΚΟΝΟΜΟΣ',
  },
  {
    value: 4250,
    label: 'HOUSEMAIDS',
    labelGreek: 'ΥΠΗΡΕΤΡΙΕΣ',
  },
  {
    value: 4251,
    label: 'INNKEEPER',
    labelGreek: 'ΞΕΝΟΔΟΧΟΣ',
  },
  {
    value: 4252,
    label: 'MAIDS',
    labelGreek: 'ΟΙΚΙΑΚΟ ΠΡΟΣΩΠΙΚΟ',
  },
  {
    value: 4253,
    label: 'CONSULTANT HOTEL AND CATERING',
    labelGreek: 'ΣΥΜΒΟΥΛΟΣ ΞΕΝΟΔΟΧΕΙΩΝ  ΚΑΙ ΕΣΤΙΑΣΗΣ',
  },
  {
    value: 4254,
    label: 'CHEF / COOK',
    labelGreek: 'ΜΑΓΕΙΡΑΣ / ΣΕΦ',
  },
  {
    value: 4255,
    label: 'KITCHEN STAFF',
    labelGreek: 'ΠΡΟΣΩΠΙΚΟ ΤΗΣ ΚΟΥΖΙΝΑΣ',
  },
  {
    value: 4256,
    label: 'DISC JOCKEY',
    labelGreek: 'ΝΤΙ ΤΖΕΙ',
  },
  {
    value: 4257,
    label: 'BELLMAN',
    labelGreek: 'ΠΟΡΤΙΕΡΗΣ',
  },
  {
    value: 4258,
    label: 'BUTLER',
    labelGreek: 'ΜΠΑΤΛΕΡ',
  },
  {
    value: 4259,
    label: 'DOMESTIC SERVANTS',
    labelGreek: 'ΟΙΚΙΑΚΟΙ ΒΟΗΘΟΙ',
  },
  {
    value: 4260,
    label: 'DOMESTIC WORKER',
    labelGreek: 'ΟΙΚΙΑΚΗ ΒΟΗΘΟΣ',
  },
  {
    value: 4261,
    label: 'DOORMAN',
    labelGreek: 'ΘΥΡΩΡΟΣ',
  },
  {
    value: 4262,
    label: 'CONFECTIONERS / BAKERS AND ASSISTANT',
    labelGreek: 'ΖΑΧΑΡΟΠΛΑΣΤΕΣ / BAKERS ΚΑΙ ΒΟΗΘΟΣ',
  },
  {
    value: 4263,
    label: 'FAST FOOD',
    labelGreek: 'ΓΡΗΓΟΡΟ ΦΑΓΗΤΟ',
  },
  {
    value: 4264,
    label: 'FISH & CHIP SHOP',
    labelGreek: 'ΕΣΤΙΑΤΟΡΙΟ ΓΙΑ ΨΑΡΙ ΚΑΙ ΠΑΤΑΤΕΣ',
  },
  {
    value: 4265,
    label: 'FOOTMAN',
    labelGreek: 'ΥΠΗΡΕΤΗΣ',
  },
  {
    value: 4266,
    label: 'HOST HOTEL WORKERS',
    labelGreek: 'ΕΡΓΑΤΕΣ ΞΕΝΟΔΟΧΕΙΩΝ',
  },
  {
    value: 4267,
    label: 'HOTEL/RESTAURANT/TAVERNAS/BARS/NIGHT CLUBS',
    labelGreek:
      ' : ΞΕΝΟΔΟΧΕΙΟ / ΕΣΤΙΑΤΟΡΙΟ / ΤΑΒΕΡΝΕΣ / ΜΠΑΡ / ΚΕΝΤΡΑ ΔΙΑΣΚΕΔΑΣΕΩΣ',
  },
  {
    value: 4268,
    label: 'HOTELIER',
    labelGreek: 'ΞΕΝΟΔΟΧΟΣ',
  },
  {
    value: 4269,
    label: 'JANITOR',
    labelGreek: 'ΕΠΙΣΤΑΤΗΣ',
  },
  {
    value: 4270,
    label: 'PORTER',
    labelGreek: 'ΠΟΡΤΙΕΡΗΣ',
  },
  {
    value: 4271,
    label: 'RECEPTIONIST',
    labelGreek: 'ΥΠΑΛΛΗΛΟΣ ΥΠΟΔΟΧΗΣ',
  },
  {
    value: 4272,
    label: 'DOG BEAUTICIAN',
    labelGreek: 'ΑΙΣΘΗΤΙΚΟΣ ΣΚΥΛΩΝ',
  },
  {
    value: 4273,
    label: 'PET ACCESSORY/FOOD STORE',
    labelGreek: 'ΑΞΕΣΟΥΑΡ ΚΑΤΟΙΚΙΔΙΩΝ / ΚΑΤΑΣΤΗΜΑ ΤΡΟΦΙΜΩΝ',
  },
  {
    value: 4274,
    label: 'PET PARLOUR',
    labelGreek: 'ΠΕΡΙΠΟΙΗΣΗ ΚΑΤΟΙΚΙΔΙΩΝ',
  },
  {
    value: 4275,
    label: 'PET SHOPS',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΚΑΤΟΙΚΙΔΙΩΝ ΖΩΩΝ',
  },
  {
    value: 4276,
    label: 'ANTIQUE DEALER',
    labelGreek: 'ΠΩΛΗΤΗΣ ΑΝΤΙΚΩΝ',
  },
  {
    value: 4277,
    label: 'ANTIQUE FURNITURE',
    labelGreek: 'ΕΠΙΠΛΑ ΑΝΤΙΚΕΣ',
  },
  {
    value: 4278,
    label: 'ANTIQUE/RARE BOOKS',
    labelGreek: 'ΑΝΤΙΚΕΣ / ΣΠΑΝΙΑ ΒΙΒΛΙΑ',
  },
  {
    value: 4279,
    label: 'ART DEALER AND GALLERIES',
    labelGreek: 'ΕΜΠΟΡΟΣ ΕΡΓΩΝ ΤΕΧΝΗΣ ΚΑΙ ΓΚΑΛΕΡΙ',
  },
  {
    value: 4280,
    label: 'ARTIST MATERIALS',
    labelGreek: 'ΥΛΙΚΑ ΤΕΧΝΗΣ',
  },
  {
    value: 4281,
    label: 'ARTIST STUDIO',
    labelGreek: 'ΚΑΛΛΙΤΕΧΝΙΚΟ ΣΤΟΥΝΤΙΟ',
  },
  {
    value: 4282,
    label: 'ARTS & CRAFTS (EX. PRECIOUS STONES)',
    labelGreek: 'ARTS & CRAFTS (ΔΕΝ ΣΥΜΠ. ΠΟΛΥΤΙΜΩΝ ΛΙΘΩΝ)',
  },
  {
    value: 4283,
    label: 'ARTS & CRAFTS (INC PRECIOUS STONES)',
    labelGreek: 'ARTS & CRAFTS (ΣΥΜΠ. ΠΟΛΥΤΙΜΩΝ ΛΙΘΩΝ)',
  },
  {
    value: 4284,
    label: 'ARTS CRAFTS AND HOBBY STORE',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΤΕΧΝΗΣ ΚΑΙ ΧΟΜΠΥ',
  },
  {
    value: 4285,
    label: 'GALLERIES',
    labelGreek: 'ΕΚΘΕΣΙΑΚΟΙ ΧΩΡΟΙ',
  },
  {
    value: 4286,
    label: 'AUTOMOBILE ACCESSORIES STORE',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΑΞΕΣΟΥΑΡ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 4287,
    label: 'AUTOMOBILE AND TRUCKS RENTAL AGENCIES',
    labelGreek: 'ΓΡΑΦΕΙΑ ΕΝΟΙΚΙΑΣΗΣ ΑΥΤΟΚΙΝΗΤΩΝ ΚΑΙ ΦΟΡΤΗΓΑ',
  },
  {
    value: 4288,
    label: 'AUTOMOBILE DEALERS NEW AND USED RETAIL',
    labelGreek: 'ΕΜΠΟΡΟΙ ΑΥΤΟΚΙΝΗΤΩΝ ΚΑΙΝΟΥΡΓΙΑ ΚΑΙ ΜΕΤΑΧΕΙΡΙΣΜΕΝΑ ΛΙΑΝΙΚΗΣ',
  },
  {
    value: 4289,
    label: 'AUTOMOBILE STEREO SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΗΧΟΥ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 4290,
    label: 'MOTOR ACCESSORIES',
    labelGreek: 'ΕΞΑΡΤΗΜΑΤΑ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 4291,
    label: 'MOTOR CYCLE DEALERS',
    labelGreek: 'ΕΜΠΟΡΟΙ ΜΟΤΟΣΥΚΛΕΤΩΝ',
  },
  {
    value: 4292,
    label: 'MOTORCYCLE ACCESSORIES',
    labelGreek: 'ΑΞΕΣΟΥΑΡ ΜΟΤΟΣΥΚΛΕΤΑΣ',
  },
  {
    value: 4293,
    label: 'BATHROOM EQUIPMENT',
    labelGreek: 'ΕΙΔΗ ΥΓΙΕΙΝΗΣ',
  },
  {
    value: 4294,
    label: 'BEAUTY PRODUCTS',
    labelGreek: 'ΠΡΟΪΟΝΤΑ ΟΜΟΡΦΙΑΣ',
  },
  {
    value: 4295,
    label: 'BEAUTY SALON',
    labelGreek: 'ΟΙΚΟΣ ΟΜΟΡΦΙΑΣ',
  },
  {
    value: 4296,
    label: 'COSMETICS CURIOS SHOP',
    labelGreek: 'ΚΑΛΛΥΝΤΙΚΑ',
  },
  {
    value: 4297,
    label: 'FASHION ACCESSORIES',
    labelGreek: 'ΑΞΕΣΟΥΑΡ ΜΟΔΑΣ',
  },
  {
    value: 4298,
    label: 'FASHION BOUTIQUE',
    labelGreek: 'ΟΙΚΟΣ ΜΟΔΑΣ',
  },
  {
    value: 4300,
    label: 'HAIRPIECE & WIG',
    labelGreek: 'ΠΕΡΟΥΚΕΣ ΚΑΙ ΑΞΕΣΟΥΑΡ ΜΑΛΙΩΝ',
  },
  {
    value: 4301,
    label: 'HEALTH FOOD STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΥΓΙΕΙΝΗΣ ΔΙΑΤΡΟΦΗΣ',
  },
  {
    value: 4302,
    label: 'JEWELLERS/GOLDSMITHS/SHOPS/MANUFACTURERS/DISTRIBUTERS',
    labelGreek:
      ' : ΚΟΣΜΗΜΑΤΑ / ΠΟΛΥΤΙΜΑ / ΚΑΤΑΣΤΗΜΑΤΑ / ΚΑΤΑΣΚΕΥΑΣΤΕΣ / ΔΙΑΝΟΜΕΙΣ',
  },
  {
    value: 4303,
    label: 'JEWELLERY',
    labelGreek: 'ΚΟΣΜΗΜΑΤΟΠΩΛΕΙΟ',
  },
  {
    value: 4304,
    label: 'JEWELLERY - WHOLESALE',
    labelGreek: 'ΚΟΣΜΗΜΑΤΟΠΩΛΕΙΟ - ΧΟΝΔΡΙΚΗ',
  },
  {
    value: 4305,
    label: 'OPTICIAN',
    labelGreek: 'ΟΠΤΙΚΟΣ',
  },
  {
    value: 4306,
    label: 'ORGANIC FOODS',
    labelGreek: 'ΒΙΟΛΟΓΙΚΑ ΤΡΟΦΙΜΑ',
  },
  {
    value: 4307,
    label: 'PERFUME',
    labelGreek: 'ΑΡΩΜΑ',
  },
  {
    value: 4308,
    label: 'TANNING SALON',
    labelGreek: 'ΣΑΛΟΝΙ ΜΑΥΡΙΣΜΑΤΟΣ',
  },
  {
    value: 4309,
    label: 'TATTOOIST',
    labelGreek: 'ΤΑΤΟΥΑΖ',
  },
  {
    value: 4310,
    label: 'WATCH & CLOCK SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΜΕ ΡΟΛΟΓΙΑ',
  },
  {
    value: 4311,
    label: 'BETTING SHOPS',
    labelGreek: 'ΓΡΑΦΕΙΑ ΣΤΟΙΧΗΜΑΤΩΝ',
  },
  {
    value: 4312,
    label: 'TURF ACCOUNTANT (BETTING SHOP)',
    labelGreek: 'ΛΟΓΙΣΤΗΣ ΣΤΟΙΧΗΜΑΤΙΚΟΥ ΚΑΤΑΣΤΗΜΑΤΟΣ',
  },
  {
    value: 4313,
    label: 'ARMED SERVICES OUTFITTERS',
    labelGreek: 'ΕΝΔΥΜΑΣΙΕΣ ΕΝΟΠΛΩΝ ΥΠΗΡΕΣΙΩΝ',
  },
  {
    value: 4314,
    label: 'BABYWEAR',
    labelGreek: 'ΕΝΔΥΜΑΤΑ ΒΡΕΦΙΚΑ',
  },
  {
    value: 4315,
    label: 'BELTS/SCARVES/GLOVES',
    labelGreek: 'ΖΩΝΕΣ / ΚΑΣΚΟΛ / ΓΑΝΤΙΑ',
  },
  {
    value: 4316,
    label: 'BOUTIQUES',
    labelGreek: 'ΜΠΟΥΤΙΚ',
  },
  {
    value: 4317,
    label: "CHILDREN'S CLOTHING",
    labelGreek: 'ΠΑΙΔΙΚΑ ΕΝΔΥΜΑΤΑ',
  },
  {
    value: 4318,
    label: 'CLOTHING SHOP',
    labelGreek: 'ΠΩΛΗΤΗΣ ΣΕ ΚΑΤΑΣΤΗΜΑ ΕΝΔΥΜΑΤΩΝ',
  },
  {
    value: 4319,
    label: 'DRAPER DRESS',
    labelGreek: 'ΥΦΑΣΜΑΤΟΠΩΛΗΣ',
  },
  {
    value: 4320,
    label: 'DRY CLEANING / LAUNDRY',
    labelGreek: 'ΣΤΕΓΝΟΚΑΘΑΡΙΣΤΗΡΙΟ / ΠΛΥΝΤΗΡΙΟ',
  },
  {
    value: 4321,
    label: 'FABRIC STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΥΦΑΣΜΑΤΩΝ',
  },
  {
    value: 4322,
    label: 'FANCY DRESS CLOTHING HIRING',
    labelGreek: 'ΕΝΟΙΚΙΑΣΕΙΣ ΦΟΡΕΜΑΤΩΝ',
  },
  {
    value: 4323,
    label: 'FORMAL WEAR RENTAL STORES',
    labelGreek: 'ΕΝΟΙΚΙΑΣΕΙΣ ΒΡΑΔΙΝΩΝ ΕΝΔΥΜΑΣΙΩΝ',
  },
  {
    value: 4324,
    label: 'FURRIERS GADGET SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΕΡΓΑΛΕΙΩΝ ΓΙΑ ΓΟΥΝΕΣ',
  },
  {
    value: 4325,
    label: 'GOWN TRADE',
    labelGreek: 'ΕΜΠΟΡΙΟ ΡΟΜΠΑΣ',
  },
  {
    value: 4326,
    label: 'HABERDASHERY',
    labelGreek: 'ΕΜΠΟΡΙΚΟ ΑΝΔΡΙΚΩΝ ΕΙΔΩΝ ΙΜΑΤΙΣΜΟΥ',
  },
  {
    value: 4327,
    label: 'HOSIERY',
    labelGreek: 'ΚΑΛΤΣΕΣ',
  },
  {
    value: 4328,
    label: 'INDUSTRIAL SAFETY WEAR',
    labelGreek: 'ΕΝΔΥΜΑΤΑ ΒΙΟΜΗΧΑΝΙΚΗΣ ΑΣΦΑΛΕΙΑΣ',
  },
  {
    value: 4329,
    label: 'KNITTING MACHINE SHOPS',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΜΗΧΑΝΩΝ ΠΛΕΞΙΜΑΤΟΣ',
  },
  {
    value: 4330,
    label: 'KNITWEAR LAMP SHOPS',
    labelGreek: 'ΠΛΕΚΤΑ',
  },
  {
    value: 4331,
    label: 'LAUNDERETTE (ATTENDED)',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΡΙΑ (ΕΠΑΝΡΩΜΕΝΑ)',
  },
  {
    value: 4332,
    label: 'LAUNDERETTE (UNATTENDED)',
    labelGreek: 'ΚΑΘΑΡΙΣΤΗΡΙΑ (ΜΗ ΕΠΑΝΔΡΩΜΕΝΟΙ)',
  },
  {
    value: 4333,
    label: 'LEATHER AND TRAVEL GOODS',
    labelGreek: 'ΔΕΡΜΑ ΚΑΙ ΕΙΔΗ ΤΑΞΙΔΙΟΥ',
  },
  {
    value: 4334,
    label: 'LEATHER CLOTHING',
    labelGreek: 'ΔΕΡΜΑΤΙΝΑ ΕΙΔΗ',
  },
  {
    value: 4335,
    label: 'LEATHER DRESSERS & DYERS',
    labelGreek: 'ΒΑΦΗ ΔΕΡΜΑΤΙΝΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4336,
    label: 'LEATHER GOODS - EX. CLOTHES',
    labelGreek: 'ΔΕΡΜΑΤΙΝΑ ΕΙΔΗ - EX. ΡΟΥΧΑ',
  },
  {
    value: 4337,
    label: 'LEATHER GOODS - INC CLOTHES',
    labelGreek: 'ΔΕΡΜΑΤΙΝΑ ΕΙΔΗ - ΕΝΔΥΜΑΤΑ INC',
  },
  {
    value: 4338,
    label: 'LEISUREWEAR',
    labelGreek: 'ΔΡΑΣΤΗΡΙΟΤΗΤΕΣ ΑΝΑΨΥΧΗΣ',
  },
  {
    value: 4339,
    label: 'LINEN HIRE',
    labelGreek: 'ΕΝΟΙΚΙΑΣΗ ΛΕΥΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4340,
    label: 'LINGERIE',
    labelGreek: 'ΕΣΩΡΟΥΧΑ',
  },
  {
    value: 4341,
    label: 'MATERNITY WEAR',
    labelGreek: 'ΡΟΥΧΑ ΕΓΚΥΜΟΣΥΝΗΣ',
  },
  {
    value: 4342,
    label: 'MENSWEAR',
    labelGreek: 'ΑΝΔΡΙΚΗ ΕΝΔΥΣΗ',
  },
  {
    value: 4343,
    label: 'MILLINERY',
    labelGreek: 'ΚΑΠΕΛΑΔΙΚΟ',
  },
  {
    value: 4344,
    label: 'NOVELTY/CARNIVAL GOODS',
    labelGreek: 'ΚΑΙΝΟΤΟΜΑ/ΚΑΡΝΑΒΑΛΙΣΤΙΚΑ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4345,
    label: 'OUTSIZE CLOTHING',
    labelGreek: 'ΕΝΔΥΣΗ ΜΕΓΑΛΟΥ ΜΕΓΕΘΟΥΣ',
  },
  {
    value: 4346,
    label: 'SCHOOL UNIFORM SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΣΧΟΛΙΚΩΝ ΣΤΟΛΩΝ',
  },
  {
    value: 4347,
    label: 'SHIRT SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΠΟΥΚΑΜΙΣΩΝ',
  },
  {
    value: 4348,
    label: 'TAILOR AND OUTFITTER',
    labelGreek: 'ΡΑΦΤΗΣ',
  },
  {
    value: 4349,
    label: 'UNIFORM SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΣΤΟΛΩΝ',
  },
  {
    value: 4350,
    label: 'WOOL AND NEEDLEWORK SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΕΝΤΗΜΑΤΩΝ',
  },
  {
    value: 4351,
    label: 'BICYCLE SHOPS SALES AND SERVICES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΠΟΔΗΛΑΤΩΝ - ΠΩΛΗΣΕΙΣ ΚΑΙ ΥΠΗΡΕΣΙΕΣ',
  },
  {
    value: 4352,
    label: 'CYCLE REPAIRING',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΠΟΔΗΛΑΤΩΝ',
  },
  {
    value: 4353,
    label: 'CYCLE SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΠΟΔΗΛΑΤΩΝ',
  },
  {
    value: 4354,
    label: 'DIY - EX. TIMBER',
    labelGreek: 'DIY - ΕΚΤΟΣ ΞΥΛΕΙΑΣ',
  },
  {
    value: 4355,
    label: 'DIY - INC TIMBER',
    labelGreek: 'DIY - ΚΑΙ ΞΥΛΕΙΑ',
  },
  {
    value: 4356,
    label: 'DIY STORE',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ DIY',
  },
  {
    value: 4357,
    label: 'AUDIO/VISUAL MEDIA',
    labelGreek: 'ΟΠΤΙΚΟΑΚΟΥΣΤΙΚΑ ΜΕΣΑ ΕΝΗΜΕΡΩΣΗΣ',
  },
  {
    value: 4358,
    label: 'CAMERA STORES OR SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΦΩΤΟΓΡΑΦΙΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4359,
    label: 'CAR RADIO DEALERS (EX. FITTING)',
    labelGreek:
      ' : ΑΝΤΙΠΡΟΣΩΠΕΙΕΣ ΗΧΟΣΥΣΤΗΜΑΤΩΝ ΑΥΤΟΚΙΝΗΤΩΝ  (ΔΕΝ ΣΥΜΠ. ΤΟΠΟΘΕΤΗΣΗ)',
  },
  {
    value: 4360,
    label: 'CAR RADIO DEALERS (INC. FITTING)',
    labelGreek:
      ' : ΑΝΤΙΠΡΟΣΩΠΕΙΕΣ ΗΧΟΣΥΣΤΗΜΑΤΩΝ ΑΥΤΟΚΙΝΗΤΩΝ  (ΣΥΜΠ. ΤΟΠΟΘΕΤΗΣΗ)',
  },
  {
    value: 4361,
    label: 'CASSETTE & VIDEO/DVD RECORD SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΑΣΣΕΤΩΝ ΚΑΙ DVD',
  },
  {
    value: 4362,
    label:
      'COMPUTER - BUSINESS COMPUTER - GENERAL COMPUTER - HOME & GAMES COMPUTER',
    labelGreek:
      ' : ΥΠΟΛΟΓΙΣΤΕΣ -  ΥΠΟΛΟΓΙΣΤΕΣ ΕΠΙΧΕΙΡΗΣΕΩΝ - ΓΕΝΙΚΟΙ ΥΠΟΛΟΓΙΣΤΕΣ - ΥΠΟΛΟΓΙΣΤΕΣ ΓΙΑ ΠΑΙΧΝΙΔΙΑ ΚΑΙ ΤΟ ΣΠΙΤΙ',
  },
  {
    value: 4363,
    label: 'DVD & VIDEO SHOP',
    labelGreek: 'DVD & VIDEO SHOP',
  },
  {
    value: 4364,
    label: 'DVD/VIDEO RENTALS',
    labelGreek: 'ΕΝΟΙΚΙΑΣΕΙΣ DVD',
  },
  {
    value: 4365,
    label: 'ELECTRIC TOOL',
    labelGreek: 'ΗΛΕΚΤΡΙΚΩΝ ΕΡΓΑΛΕΙΩΝ',
  },
  {
    value: 4366,
    label: 'ELECTRICAL APPLIANCE STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΗΛΕΚΤΡΙΚΗ ΣΥΣΚΕΥΗ',
  },
  {
    value: 4367,
    label: 'ELECTRICAL GOODS',
    labelGreek: 'ΗΛΕΚΤΡΙΚΑ ΕΙΔΗ',
  },
  {
    value: 4368,
    label: 'ELECTRICAL GOODS - HI FI AUDIO TV ETC.',
    labelGreek: 'ΗΛΕΚΤΡΙΚΑ ΕΙΔΗ - ΣΤΕΡΕΟΦΩΝΙΚΑ/ΤΗΛΕΟΡΑΣΕΙΣ',
  },
  {
    value: 4369,
    label: 'ELECTRICAL GOODS - WHITE GOODS',
    labelGreek: 'ΗΛΕΚΤΡΙΚΑ ΕΙΔΗ - ΛΕΥΚΑ ΕΙΔΗ',
  },
  {
    value: 4370,
    label: 'ELECTRICAL GOODS (DOMESTIC)',
    labelGreek: 'ΗΛΕΚΤΡΙΚΑ ΕΙΔΗ (ΟΙΚΙΑΣ)',
  },
  {
    value: 4371,
    label: 'GAMES COMPUTER',
    labelGreek: 'ΥΠΟΛΟΓΙΣΤΕΣ ΠΑΙΧΝΙΔΙΩΝ',
  },
  {
    value: 4372,
    label: 'GAMES SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΠΑΙΧΝΙΔΙΩΝ',
  },
  {
    value: 4373,
    label: 'HARDWARE SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΛΙΚΩΝ',
  },
  {
    value: 4374,
    label: 'INTRUDER ALARMS',
    labelGreek: 'ΣΥΝΑΓΕΡΜΟΙ',
  },
  {
    value: 4375,
    label: 'MOBILE TELEPHONE STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΚΙΝΗΤΩΝ ΤΗΛΕΦΩΝΩΝ',
  },
  {
    value: 4376,
    label: 'MOBILITY SHOP',
    labelGreek: 'ΚΙΝΗΤΟ ΚΑΤΑΣΤΗΜΑ',
  },
  {
    value: 4377,
    label: 'RECORD CD',
    labelGreek: 'ΕΓΓΡΑΦΗ CD',
  },
  {
    value: 4378,
    label: 'REPAIR ELECTRICAL GOODS',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ & ΣΥΝΤΗΡΗΣΗ ΗΛΕΚΤΡΙΚΩΝ ΠΡΟΪΟΝΤΩΝ',
  },
  {
    value: 4379,
    label: 'SATELLITE TV & EQUIPMENT SUPPLIER (EX. INSTALLATION)',
    labelGreek: 'ΔΟΡΥΦΟΡΙΚΗ ΤΗΛΕΟΡΑΣΗ & ΕΞΟΠΛΙΣΜΟΣ (EX. ΕΓΚΑΤΑΣΤΑΣΗ)',
  },
  {
    value: 4380,
    label: 'SECURITY SHOP',
    labelGreek: 'ΑΣΦΑΛΕΙΑΣ ΚΑΤΑΣΤΗΜΑΤΩΝ',
  },
  {
    value: 4381,
    label: 'SUPPLIES COMPUTERS',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΕΣ ΥΛΙΚΩΝ ΓΙΑ ΥΠΟΛΟΓΙΣΤΕΣ',
  },
  {
    value: 4382,
    label: 'TELEVISION REPAIRING',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΤΗΛΕΟΡΑΣΗΣ',
  },
  {
    value: 4383,
    label: 'TELEVISION SALES',
    labelGreek: 'ΤΗΛΕΟΠΤΙΚΕΣ ΠΩΛΗΣΕΙΣ',
  },
  {
    value: 4384,
    label: 'TOY & GAME SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΠΑΙΧΝΙΔΙΩΝ',
  },
  {
    value: 4385,
    label: 'TV AND RADIO RETAIL / REPAIR',
    labelGreek: 'ΛΙΑΝΙΚΟ ΕΜΠΟΡΙΟ/ΕΠΙΣΚΕΥΕΣ ΤΗΛΕΟΡΑΣΕΩΝ ΚΑΙ ΡΑΔΙΟΦΩΝΩΝ',
  },
  {
    value: 4386,
    label: 'TV AND VIDEO REPAIRING',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΤΗΛΕΟΡΑΣΕΩΝ ΚΑΙ ΒΙΝΤΕΟ',
  },
  {
    value: 4387,
    label: 'VACUUM CLEANER REPAIRS AND SERVICE',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΣΚΟΥΠΑΣ ΚΑΘΑΡΙΣΜΟΥ ΚΑΙ ΥΠΗΡΕΣΙΩΝ',
  },
  {
    value: 4388,
    label: 'VIDEO AND DVD RENTAL STORES',
    labelGreek: 'ΒΙΝΤΕΟ ΚΑΙ DVD ΚΑΤΑΣΤΗΜΑΤΑ ΕΝΟΙΚΙΑΣΗΣ',
  },
  {
    value: 4389,
    label: 'VIDEO DVD & COMPUTER GAME HIRE',
    labelGreek: 'ΕΝΟΙΚΙΑΣΗ ΒΙΝΤΕΟ DVD & ΗΛΕΚΤΡΟΝΙΚΑ ΠΑΙΧΝΙΔΙΑ',
  },
  {
    value: 4390,
    label: 'VIDEO EQUIPMENT & TAPE SALES',
    labelGreek: 'ΕΞΟΠΛΙΣΜΟΣ ΒΙΝΤΕΟ & ΠΩΛΗΣΕΙΣ ΤΑΙΝΙΩΝ',
  },
  {
    value: 4391,
    label: 'WASHING MACHINE REPAIRS & SERVICING (EX. WORK-AWAY)',
    labelGreek: 'ΠΛΥΝΤΗΡΙΟ ΕΠΙΣΚΕΥΕΣ & ΣΥΝΤΗΡΗΣΗ (ΕΡΓΑΣΙΑΣ ΕΚΤΟΣ)',
  },
  {
    value: 4392,
    label: 'BABY GOODS',
    labelGreek: 'ΠΑΙΔΙΚΑ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4393,
    label: 'BAKER',
    labelGreek: 'ΦΟΥΡΝΑΡΗΣ',
  },
  {
    value: 4394,
    label: 'BAKERIES COMMERCIAL/WHOLESALE',
    labelGreek: 'ΑΡΤΟΠΟΙΕΙΑ ΕΜΠΟΡΙΚΑ / ΧΟΝΔΡΙΚΗΣ',
  },
  {
    value: 4395,
    label: 'BAKERIES RETAIL',
    labelGreek: 'ΛΙΑΝΙΚΗΣ ΑΡΤΟΠΟΙΙΑΣ',
  },
  {
    value: 4396,
    label: 'BAKERS PRODUCE (EX. BAKING)',
    labelGreek: 'ΠΡΟΪΟΝΤΑ ΦΟΥΡΝΟΥ (EX. ΨΗΣΙΜΑΤΟΣ)',
  },
  {
    value: 4397,
    label: 'BOTTLED WATER PRODUCTION AND DELIVERY',
    labelGreek: 'ΠΑΡΑΓΩΓΗ ΕΜΦΙΑΛΩΜΕΝΟΥ ΝΕΡΟΥ ΚΑΙ ΠΑΡΑΔΟΣΗ',
  },
  {
    value: 4398,
    label: 'BUTCHER',
    labelGreek: 'ΚΡΕΟΠΩΛΕΙΑ',
  },
  {
    value: 4399,
    label: 'DAIRY PRODUCTS',
    labelGreek: 'ΓΑΛΑΚΤΟΚΟΜΙΚΑ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4400,
    label: 'DAIRYMEN',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ ΓΑΛΑΚΤΟΚΟΜΙΚΩΝ',
  },
  {
    value: 4401,
    label: 'DELICATESSEN',
    labelGreek: 'ΑΛΛΑΝΤΙΚΑ',
  },
  {
    value: 4402,
    label: 'FISH AND SEAFOOD DEALERS WHOLESALE AND RETAIL',
    labelGreek: 'ΨΑΡΙΑ ΚΑΙ ΨΑΡΙΚΑ ΕΜΠΟΡΟΙ ΛΙΑΝΙΚΗΣ & ΧΟΝΔΡΙΚΗΣ',
  },
  {
    value: 4403,
    label: 'FOOD STORE',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΤΡΟΦΙΜΩΝ',
  },
  {
    value: 4404,
    label: 'FROZEN FOOD',
    labelGreek: 'ΚΑΤΕΨΥΓΜΕΝΑ ΤΡΟΦΙΜΑ',
  },
  {
    value: 4405,
    label: 'FRUITERER',
    labelGreek: 'ΟΠΩΡΟΦΟΡΑ',
  },
  {
    value: 4406,
    label: 'GREENGROCER',
    labelGreek: 'ΝΥΦΙΚΑ',
  },
  {
    value: 4407,
    label: 'GROCER',
    labelGreek: 'ΜΠΑΚΑΛΗΣ',
  },
  {
    value: 4408,
    label: 'GROCER - LICENSED GROCER-  UNLICENSED',
    labelGreek: 'ΜΠΑΚΑΛΗΣ - ΜΕ ΑΔΕΙΑ & ΧΩΡΙΣ',
  },
  {
    value: 4409,
    label: 'HERBS & SPICES',
    labelGreek: 'ΒΟΤΑΝΑ & ΜΠΑΧΑΡΙΚΑ',
  },
  {
    value: 4410,
    label: 'ICE CREAM PARLOUR IN CAR ENTERTAINMENT',
    labelGreek: 'ΠΩΛΗΤΗΣ ΠΑΓΩΤΟΥ ΑΠΟ ΑΥΤΟΚΙΝΗΤΟ',
  },
  {
    value: 4411,
    label: 'MEAT MARKET SLAUGHTERMAN',
    labelGreek: 'ΣΦΑΓΕΑΣ ΚΡΕΑΤΑΓΟΡΑΣ',
  },
  {
    value: 4412,
    label: 'MEAT MARKET WHOLESALE',
    labelGreek: 'ΚΡΕΑΤΑΓΟΡΑ (ΧΟΝΔΡΙΚΗ)',
  },
  {
    value: 4413,
    label: 'MOBILE FOOD VAN MOBILE',
    labelGreek: 'ΚΙΝΗΤΗ  ΜΟΝΑΔΑ ΦΑΓΗΤΟΥ (ΦΟΡΤΗΓΟ)',
  },
  {
    value: 4414,
    label: 'POULTRY & GAME',
    labelGreek: 'ΠΟΥΛΕΡΙΚΑ & ΠΑΙΧΝΙΔΙ',
  },
  {
    value: 4415,
    label: 'SUPERMARKET',
    labelGreek: 'ΥΠΕΡΑΓΟΡΑ',
  },
  {
    value: 4416,
    label: 'SWEET SHOP',
    labelGreek: 'ΖΑΧΑΡΟΠΛΑΣΤΕΙΟ',
  },
  {
    value: 4417,
    label: 'TAKE AWAY FOOD',
    labelGreek: 'ΕΤΟΙΜΟ ΦΑΓΗΤΟ',
  },
  {
    value: 4418,
    label: 'WINE MERCHANT',
    labelGreek: 'ΕΜΠΟΡΟΣ ΚΡΑΣΙΟΥ',
  },
  {
    value: 4419,
    label: 'FITTED FURNITURE',
    labelGreek: 'ΕΝΤΟΙΧΙΣΜΕΝΩΝ ΕΠΙΠΛΩΝ',
  },
  {
    value: 4420,
    label: 'FLAT PACK FURNITURE',
    labelGreek: 'ΣΥΣΚΕΥΑΣΙΕΣ ΕΠΙΠΛΩΝ',
  },
  {
    value: 4421,
    label: 'FURNITURE',
    labelGreek: 'ΕΠΙΠΛΑ',
  },
  {
    value: 4422,
    label: 'FURNITURE STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΕΠΙΠΛΩΝ',
  },
  {
    value: 4423,
    label: 'NURSERY FURNITURE',
    labelGreek: 'ΕΠΙΠΛΑ ΒΡΕΦΟΚΟΜΕΙΟΥ',
  },
  {
    value: 4424,
    label: 'PINE & CANE FURNITURE',
    labelGreek: 'ΕΠΙΠΛΑ ΠΕΥΚΟΥ',
  },
  {
    value: 4425,
    label: 'REPAIRING FURNITURE',
    labelGreek: 'ΕΠΙΣΚΕΥΕΣ ΕΠΙΠΛΩΝ',
  },
  {
    value: 4426,
    label: 'SECOND HAND FURNITURE',
    labelGreek: 'ΜΕΤΑΧΕΙΡΙΣΜΕΝΑ ΕΠΙΠΛΑ',
  },
  {
    value: 4427,
    label: 'SITE CLEARANCE',
    labelGreek: 'ΚΑΘΑΡΙΣΜΟΣ ΧΩΡΟΥ',
  },
  {
    value: 4428,
    label: 'SOFA BED',
    labelGreek: 'ΚΑΝΑΠΕΣ ΚΡΕΒΑΤΙ',
  },
  {
    value: 4429,
    label: 'SOFT FURNISHINGS',
    labelGreek: 'ΜΑΛΑΚΑ ΕΠΙΠΛΑ',
  },
  {
    value: 4430,
    label: 'FLORIST',
    labelGreek: 'ΑΝΘΟΠΩΛΗΣ',
  },
  {
    value: 4431,
    label: 'GARDEN CENTRE',
    labelGreek: 'ΚΕΝΤΡΟ ΚΗΠΟΥ',
  },
  {
    value: 4432,
    label: 'GARDEN EQUIPMENT',
    labelGreek: 'ΕΞΟΠΛΙΣΜΟΣ ΚΗΠΟΥ',
  },
  {
    value: 4433,
    label: 'GARDEN SUPPLIES',
    labelGreek: 'ΕΙΔΗ ΚΗΠΟΥ',
  },
  {
    value: 4434,
    label: 'HORTICULTURAL GOODS',
    labelGreek: 'ΚΗΠΕΥΤΙΚΑ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4435,
    label: 'POTTERY',
    labelGreek: 'ΑΓΓΕΙΟΠΛΑΣΤΙΚΗ',
  },
  {
    value: 4436,
    label: 'CHINA AND/OR GLASSWARE RETAILING',
    labelGreek: 'ΣΚΕΥΗ ΓΙΑ ΛΙΑΝΙΚΗ ΠΩΛΗΣΗ',
  },
  {
    value: 4437,
    label: 'DOUBLE GLAZING & WINDOW SHOWROOM',
    labelGreek: 'ΔΙΠΛΑ ΥΑΛΟΣΤΑΣΙΑ &  ΕΚΘΕΣΗΣ ΠΑΡΑΘΥΡΩΝ',
  },
  {
    value: 4438,
    label: 'GLASS',
    labelGreek: 'ΓΥΑΛΙ',
  },
  {
    value: 4439,
    label: 'GLASS INSTALLER',
    labelGreek: 'ΕΦΑΡΜΟΓΕΑΣ ΓΥΑΛΙΩΝ',
  },
  {
    value: 4440,
    label: 'GLASSWARE',
    labelGreek: 'ΥΑΛΙΚΑ',
  },
  {
    value: 4441,
    label: 'GLAZIER',
    labelGreek: 'ΤΖΑΜΑΣ',
  },
  {
    value: 4442,
    label: 'MERCHANTS GLASS OR GLASS GOODS',
    labelGreek: 'ΕΜΠΟΡΟΙ ΓΥΑΛΙΟΥ Η ΥΑΛΙΚΩΝ ΠΡΟΪΟΝΤΩΝ',
  },
  {
    value: 4443,
    label: 'HUNTING SHOP AND SUPPLIER',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΑΙ ΠΡΟΜΗΘΕΥΤΕΣ ΕΙΔΩΝ ΚΥΝΗΓΙΟΥ',
  },
  {
    value: 4444,
    label: 'FIREARM STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΠΥΡΟΒΟΛΩΝ ΟΠΛΩΝ',
  },
  {
    value: 4445,
    label: 'GUN SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΟΠΛΩΝ',
  },
  {
    value: 4446,
    label: 'GUNSMITH',
    labelGreek: 'ΟΠΛΟΥΡΓΟΣ',
  },
  {
    value: 4447,
    label: 'HUNTING SHOP - SUPPLIER',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ ΕΙΔΩΝ ΚΥΝΗΓΙΟΥ',
  },
  {
    value: 4448,
    label: 'BED SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΡΕΒΑΤΙΩΝ',
  },
  {
    value: 4449,
    label: 'CURTAIN & BLIND CUTLERY CYCLE HIRING',
    labelGreek: 'ΕΝΟΙΚΙΑΣΕΙΣ ΕΡΓΑΛΕΙΩΝ ΓΙΑ ΚΟΥΡΤΙΝΕΣ',
  },
  {
    value: 4450,
    label: 'DOMESTIC APPLIANCE MAINTENANCE (EX. WORK-AWAY)',
    labelGreek: 'ΟΙΚΙΑΚΗ ΣΥΣΚΕΥΗ ΣΥΝΤΗΡΗΣΗ (EX. ΕΡΓΑΣΙΑΣ-ΜΑΚΡΙΑ)',
  },
  {
    value: 4451,
    label: 'DOMESTIC APPLIANCES',
    labelGreek: 'ΟΙΚΙΑΚΕΣ ΣΥΣΚΕΥΕΣ',
  },
  {
    value: 4452,
    label: 'FLOOR COVERING STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΕΠΕΝΔΥΣΗ ΔΑΠΕΔΟΥ',
  },
  {
    value: 4453,
    label: 'HOME BREW MATERIALS',
    labelGreek: 'ΥΛΙΚΑ ΟΙΚΙΑΚΗΣ ΠΟΤΟΠΟΙΙΑΣ',
  },
  {
    value: 4454,
    label: 'HOUSEHOLD GOODS',
    labelGreek: 'ΕΙΔΩΝ ΟΙΚΙΑΚΗΣ ΧΡΗΣΗΣ',
  },
  {
    value: 4455,
    label: 'INTERIOR DESIGN',
    labelGreek: 'ΣΧΕΔΙΑΣΜΟΣ ΕΣΩΤΕΡΙΚΟΥ ΧΩΡΟΥ',
  },
  {
    value: 4456,
    label: 'INTERIOR DESIGN - INC FITTING',
    labelGreek: 'ΣΧΕΔΙΑΣΜΟΣ ΕΣΩΤΕΡΙΚΟΥ ΧΩΡΟΥ - INC ΤΟΠΟΘΕΤΗΣΗ',
  },
  {
    value: 4457,
    label: 'KITCHEN ACCESSORIES',
    labelGreek: 'ΑΞΕΣΟΥΑΡ ΚΟΥΖΙΝΑΣ',
  },
  {
    value: 4458,
    label: 'KITCHEN/BATHROOM SHOWROOM',
    labelGreek: 'ΕΚΘΕΣΙΑΚΟΣ ΧΩΡΟΣ  ΚΟΥΖΙΝΑ / ΜΠΑΝΙΟ',
  },
  {
    value: 4459,
    label: 'LIGHT FITTING',
    labelGreek: 'ΕΦΑΡΜΟΓΕΣ ΦΩΤΙΣΤΙΚΩΝ',
  },
  {
    value: 4460,
    label: 'LIQUOR STORES',
    labelGreek: 'ΚΑΒΕΣ',
  },
  {
    value: 4461,
    label: 'LOCKSMITHS',
    labelGreek: 'ΚΛΕΙΔΑΡΑΣ',
  },
  {
    value: 4462,
    label: 'LUGGAGE & HANDBAG',
    labelGreek: 'ΑΠΟΣΚΕΥΕΣ & ΤΣΑΝΤΕΣ ΧΕΙΡΟΣ',
  },
  {
    value: 4463,
    label: 'PAINT AND/OR WALLPAPER',
    labelGreek: 'ΒΑΨΙΜΟ  Ή / ΚΑΙ ΤΑΠΕΤΣΑΡΙΑ',
  },
  {
    value: 4464,
    label: 'WALLPAPER SHOPS',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΤΑΠΕΤΣΑΡΙΑΣ',
  },
  {
    value: 4465,
    label: 'STORE MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗΣ ΚΑΤΑΣΤΗΜΑΤΟΣ',
  },
  {
    value: 4466,
    label: 'AGENCY DRUG STORES',
    labelGreek: 'ΑΝΤΙΠΡΟΣΩΠΟΣ ΦΑΡΜΑΚΩΝ',
  },
  {
    value: 4467,
    label: 'NURSERY GOODS SHOPS OFF LICENCE',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΙΑΤΡΙΚΩΝ ΠΡΟΙΟΝΤΩΝ',
  },
  {
    value: 4468,
    label: 'PHARMACEUTICAL WHOLESALER',
    labelGreek: 'ΦΑΡΜΑΚΑΠΟΘΗΚΗ',
  },
  {
    value: 4469,
    label: 'PHARMACY',
    labelGreek: 'ΦΑΡΜΑΚΕΙΟ',
  },
  {
    value: 4470,
    label: 'GUITAR SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΙΘΑΡΩΝ',
  },
  {
    value: 4471,
    label: 'MUSIC',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΕΙΔΩΝ ΜΟΥΣΙΚΗΣ',
  },
  {
    value: 4472,
    label: 'MUSIC STORES',
    labelGreek: 'ΔΙΣΚΟΠΩΛΕΙΑ',
  },
  {
    value: 4473,
    label: 'MUSICAL INSTRUMENTS',
    labelGreek: 'ΜΟΥΣΙΚΑ ΟΡΓΑΝΑ',
  },
  {
    value: 4474,
    label: 'PIANO SALES & REPAIRS PIANO',
    labelGreek: 'ΠΩΛΗΣΕΙΣ & ΕΠΙΣΚΕΥΕΣ ΠΙΑΝΟ',
  },
  {
    value: 4475,
    label: 'OFFICE EQUIPMENT SUPPLIER (EX. ELECTRICAL)',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ ΕΞΟΠΛΙΣΜΟΥ ΓΡΑΦΕΙΟΥ (EX. ΗΛΕΚΤΡΙΚΑ)',
  },
  {
    value: 4476,
    label: 'OFFICE EQUIPMENT SUPPLIER (INC ELECTRICAL)',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ ΕΞΟΠΛΙΣΜΟΥ ΓΡΑΦΕΙΟΥ (INC ΗΛΕΚΤΡΙΚΑ)',
  },
  {
    value: 4477,
    label: 'OFFICE FURNITURE',
    labelGreek: 'ΕΠΙΠΛΑ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 4478,
    label: 'OFFICE SUPPLIES',
    labelGreek: 'ΕΙΔΗ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 4479,
    label: 'AGRICULTURAL IMPLEMENTS',
    labelGreek: 'ΓΕΩΡΓΙΚΑ ΕΡΓΑΛΕΙΑ',
  },
  {
    value: 4480,
    label: 'BAKERS / CONFECTIONERS AND ASSISTANTS',
    labelGreek: 'ΑΡΤΟΠΟΙΟΙΑ / ΖΑΧΑΡΟΠΛΑΣΤΕΣ ΚΑΙ ΟΙ ΒΟΗΘΟΙ',
  },
  {
    value: 4481,
    label: 'AMUSEMENT SHOPS / ARCADES',
    labelGreek: 'ΚΕΝΤΡΑ ΨΥΧΑΓΩΓΙΑΣ / ΣΤΟΕΣ',
  },
  {
    value: 4482,
    label: 'ARCADES/PRECINCTS/MARKETS',
    labelGreek: 'ΣΤΟΕΣ/ ΠΕΡΙΒΟΛΟΣ / ΑΓΟΡΕΣ',
  },
  {
    value: 4483,
    label: 'BAIT AND TACKLE SHOPS',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΔΟΛΩΜΑΤΩΝ',
  },
  {
    value: 4484,
    label: 'BASKET AND/OR BRUSH WARE',
    labelGreek: 'ΚΑΛΑΘΟΙ ΚΑΙ / Ή ΒΟΥΡΤΣΕΣ',
  },
  {
    value: 4485,
    label: 'BOOK STORES - RETAIL',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑΤΑ ΒΙΒΛΙΩΝ - ΛΙΑΝΙΚΗ',
  },
  {
    value: 4486,
    label: 'BRIDAL SHOPS',
    labelGreek: 'ΝΥΦΙΚΑ ΚΑΤΑΣΤΗΜΑΤΑ',
  },
  {
    value: 4487,
    label: 'BUREAU DE CHANGE',
    labelGreek: 'BUREAU DE CHANGE',
  },
  {
    value: 4488,
    label: 'CARD & BOOK SHOP CARPET (ORIENTAL/PERSIAN)',
    labelGreek: 'ΚΑΡΤΟΠΩΛΕΙΑ & ΒΙΒΛΙΟΠΩΛΕΙΑ',
  },
  {
    value: 4489,
    label: 'CARPETS',
    labelGreek: 'ΧΑΛΙΑ',
  },
  {
    value: 4490,
    label: 'CASH & CARRY CATERING EQUIPMENT',
    labelGreek: 'ΕΞΟΠΛΙΣΜΟΣ ΕΣΤΙΑΣΗΣ',
  },
  {
    value: 4491,
    label: 'CASHIER',
    labelGreek: 'ΤΑΜΙΑΣ',
  },
  {
    value: 4492,
    label: 'CATALOGUE GOODS DISTRIBUTOR',
    labelGreek: 'ΚΑΤΑΛΟΓΟΣ ΠΡΟΪΟΝΤΩΝ ΔΙΑΝΟΜΕΑΣ',
  },
  {
    value: 4493,
    label: 'CHANDLERY CHARITY SHOP',
    labelGreek: 'ΦΙΛΑΝΘΡΩΠΙΚΟ ΚΑΤΑΣΤΗΜΑ ΚΗΡΟΠΩΛΕΙΟ',
  },
  {
    value: 4494,
    label: 'DEPARTMENT STORES',
    labelGreek: 'ΠΟΛΥΚΑΤΑΣΤΗΜΑΤΑ',
  },
  {
    value: 4495,
    label: 'DIRECT SELLING ORGANIZATIONS',
    labelGreek: 'ΟΡΓΑΝΙΣΜΟΙ ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗΣ',
  },
  {
    value: 4496,
    label: 'DISCOUNT STORES / ANYTHING FOR £1 SHOPS',
    labelGreek: 'ΕΚΠΤΩTIKA ΚΑΤΑΣΤΗΜΑΤΑ / OΛA £ 1 ΚΑΤΑΣΤΗΜΑΤΑ',
  },
  {
    value: 4497,
    label: 'DISTILLER',
    labelGreek: 'ΟΙΝΟΠΝΕΥΜΑΤΟΠΟΙΟΣ',
  },
  {
    value: 4498,
    label: 'DOLL REPAIRING',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΚΟΥΚΛΩΝ',
  },
  {
    value: 4499,
    label: 'DOLLS HOUSE',
    labelGreek: 'ΚΟΥΚΛΟΣΠΙΤΑ',
  },
  {
    value: 4500,
    label: 'ENGRAVERS',
    labelGreek: 'ΧΑΡΑΚΤΕΣ',
  },
  {
    value: 4501,
    label: 'ENGRAVING ESTATE AGENCY',
    labelGreek: 'ΧΑΡΑΚΤΕΣ ΚΤΗΜΑΤΙΚΩΝ',
  },
  {
    value: 4502,
    label: 'EXPORTERS & IMPORTERS & DISTRIBUTORS',
    labelGreek: 'ΕΞΑΓΩΓΕΙΣ ΚΑΙ ΕΙΣΑΓΩΓΕΙΣ ΚΑΙ ΔΙΑΝΟΜΕΙΣ',
  },
  {
    value: 4503,
    label: 'FANCY GOODS',
    labelGreek: 'ΕΙΔΗ ΠΟΛΥΤΕΛΕΙΑΣ',
  },
  {
    value: 4504,
    label: 'FARM PRODUCE',
    labelGreek: 'ΠΑΡΑΓΩΓΗ ΓΕΩΡΓΙΚΩΝ ΠΡΟΪΟΝΤΩΝ',
  },
  {
    value: 4505,
    label: 'FILM PROCESSING',
    labelGreek: 'ΕΠΕΞΕΡΓΑΣΙΑΣ ΦΙΛΜ',
  },
  {
    value: 4506,
    label: 'FIREPLACE RETAILING',
    labelGreek: 'ΛΙΑΝΙΚΗ ΠΩΛΗΣΗ ΤΖΑΚΙΩΝ',
  },
  {
    value: 4507,
    label: 'FISHING TACKLE',
    labelGreek: 'ΣΥΝΕΡΓΑ ΑΛΙΕΙΑΣ',
  },
  {
    value: 4508,
    label: 'FISHMONGER',
    labelGreek: 'ΙΧΘΥΟΠΩΛΗΣ',
  },
  {
    value: 4509,
    label: 'GEM CUTTER',
    labelGreek: 'ΓΕΜΟΛΟΓΟΣ',
  },
  {
    value: 4510,
    label: 'GIFT SHOPS',
    labelGreek: 'ΕΙΔΗ ΔΩΡΩΝ',
  },
  {
    value: 4511,
    label: 'GOLDSMITH',
    labelGreek: 'ΧΡΥΣΟΧΟΟΣ',
  },
  {
    value: 4512,
    label: 'GREETING CARDS',
    labelGreek: 'ΕΥΧΕΤΗΡΙΕΣ ΚΑΡΤΕΣ',
  },
  {
    value: 4513,
    label: 'HANDICRAFT',
    labelGreek: 'ΧΕΙΡΟΤΕΧΝΙΑΣ',
  },
  {
    value: 4514,
    label: 'IMPORTER',
    labelGreek: 'ΕΙΣΑΓΩΓΕΑΣ',
  },
  {
    value: 4515,
    label: 'IRONMONGER',
    labelGreek: 'ΣΙΔΗΡΟΠΩΛΗΣ',
  },
  {
    value: 4516,
    label: 'JOKE SHOP KEY CUTTERS',
    labelGreek: 'ΚΟΠΗΣ ΚΛΕΙΔΙΩΝ',
  },
  {
    value: 4517,
    label: 'KIOSKS',
    labelGreek: 'ΠΕΡΙΠΤΕΡΟ',
  },
  {
    value: 4518,
    label: 'LICENCED PREMISES',
    labelGreek: 'ΑΔΕΙΟΔΟΤΗΜΕΝΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ',
  },
  {
    value: 4519,
    label: 'LIGHTING',
    labelGreek: 'ΦΩΤΙΣΜΟΣ',
  },
  {
    value: 4520,
    label: 'MARINE STORE DEALERS',
    labelGreek: 'ΕΜΠΟΡΟΣ ΕΙΔΩΝ ΘΑΛΑΣΣΗΣ',
  },
  {
    value: 4521,
    label: 'MARINE STORES',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΕΙΔΩΝ ΘΑΛΑΣΣΗΣ',
  },
  {
    value: 4522,
    label: 'MEDIATOR',
    labelGreek: 'ΜΕΣΙΤΗΣ',
  },
  {
    value: 4523,
    label: 'MERCHANTS',
    labelGreek: 'ΕΜΠΟΡΟΙ',
  },
  {
    value: 4524,
    label: 'MODEL SHOP',
    labelGreek: 'ΠΡΑΚΤΟΡΕΙΟ ΜΟΝΤΕΛΛΩΝ',
  },
  {
    value: 4525,
    label: 'OIL AND PETROLEUM IMPORTERS INCLUDING DISTRIBUTION',
    labelGreek: 'ΕΙΣΑΓΩΓΕΙΣ ΠΕΤΡΕΛΑΙΟΥ ΠΟΥ ΠΕΡΙΛΑΜΒΑΝΕΙ ΔΙΑΝΟΜΗ',
  },
  {
    value: 4526,
    label: 'ORGANIZER',
    labelGreek: 'ΔΙΟΡΓΑΝΩΤΗΣ',
  },
  {
    value: 4527,
    label: 'PAWN BROKING',
    labelGreek: 'ΕΝΕΧΥΡΟΔΑΝΕΙΣΤΗΡΙΟ',
  },
  {
    value: 4528,
    label: 'PETROL STATION',
    labelGreek: 'ΒΕΝΖΙΝΑΔΙΚΑ',
  },
  {
    value: 4529,
    label: 'PUBLIC HOUSES',
    labelGreek: 'ΔΗΜΟΣΙΑ ΚΤΙΡΙΑ',
  },
  {
    value: 4530,
    label: 'RADIATORS',
    labelGreek: 'ΚΑΛΟΡΙΦΕΡ',
  },
  {
    value: 4531,
    label: 'RESTORATION',
    labelGreek: 'ΑΠΟΚΑΤΑΣΤΑΣΗ',
  },
  {
    value: 4532,
    label: 'RETAILER',
    labelGreek: 'ΛΙΑΝΟΠΩΛΗΤΗΣ',
  },
  {
    value: 4533,
    label: 'ROPE MERCHANT',
    labelGreek: 'ΕΜΠΟΡΑΣ ΣΧΟΙΝΙΩΝ',
  },
  {
    value: 4534,
    label: 'SADDLERY & TACK',
    labelGreek: 'ΣΕΛΟΠΟΙΙΑ & ΠΕΤΑΛΩΜΑ',
  },
  {
    value: 4535,
    label: 'SALES STAFF AND SHOWROOM ASSISTANTS',
    labelGreek: 'ΠΡΟΣΩΠΙΚΟ ΠΩΛΗΣΕΩΝ ΚΑΙ ΕΚΘΕΣΙΑΚΩΝ ΧΩΡΩΝ',
  },
  {
    value: 4536,
    label: 'SAW SALES AND REPAIRS',
    labelGreek: 'ΠΩΛΗΣΕΙΣ ΚΑΙ ΕΠΙΣΚΕΥΕΣ ΠΡΙΟΝΙΩΝ',
  },
  {
    value: 4537,
    label: 'SCIENTIFIC INSTRUMENT',
    labelGreek: 'ΕΠΙΣΤΗΜΟΝΙΚΑ ΟΡΓΑΝΑ',
  },
  {
    value: 4538,
    label: 'SECOND-HAND DEALING',
    labelGreek: 'ΕΜΠΟΡΙΟ ΜΕΤΑΧΕΙΡΙΣΜΕΝΩΝ',
  },
  {
    value: 4539,
    label: 'SEWING MACHINE SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΡΑΠΤΟΜΗΧΑΝΩΝ',
  },
  {
    value: 4540,
    label: 'SHARPENING SERVICES',
    labelGreek: 'ΥΠΗΡΕΣΙΕΣ ΑΚΟΝΙΣΜΑΤΟΣ',
  },
  {
    value: 4541,
    label: 'SHOP DECORATORS',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΔΙΑΚΟΣΜΗΣΗΣ',
  },
  {
    value: 4542,
    label: 'SHOP GENERAL STORE',
    labelGreek: 'ΓΕΝΙΚΟ ΚΑΤΑΣΤΗΜΑ',
  },
  {
    value: 4543,
    label: 'SOFTWARE CONFECTIONERY CORNER SHOP',
    labelGreek: 'ΖΑΧΑΡΟΠΛΑΣΤΙΟ',
  },
  {
    value: 4544,
    label: 'SOUVENIR SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΑΝΑΜΝΗΣΤΙΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4545,
    label: 'STAMP DEALING',
    labelGreek: 'ΕΜΠΟΡΙΟ ΣΦΡΑΓΙΔΩΝ',
  },
  {
    value: 4546,
    label: 'SURF SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΕΙΔΩΝ ΣΕΡΦΙΝΓΚ',
  },
  {
    value: 4547,
    label: 'TABLEWARE',
    labelGreek: 'ΕΠΙΤΡΑΠΕΖΙΑ',
  },
  {
    value: 4548,
    label: 'TEA ROOM',
    labelGreek: 'ΔΩΜΑΤΙΑ ΤΣΑΓΙΟΥ',
  },
  {
    value: 4549,
    label: 'TELEMARKETER',
    labelGreek: 'ΤΗΛΕΠΩΛΗΣΕΙΣ',
  },
  {
    value: 4550,
    label: 'TELEPHONE SALES',
    labelGreek: 'ΤΗΛΕΦΩΝΙΚΕΣ ΠΩΛΗΣΕΙΣ',
  },
  {
    value: 4551,
    label: 'TEXTILE SHOP',
    labelGreek: 'ΥΦΑΣΜΑΤΟΠΩΛΕΙΟ',
  },
  {
    value: 4552,
    label: 'TILES',
    labelGreek: 'ΠΛΑΚΑΚΙΑ',
  },
  {
    value: 4553,
    label: 'TOBACCONIST',
    labelGreek: 'ΚΑΠΝΟΠΩΛΗΣ',
  },
  {
    value: 4554,
    label: 'TOILETRIES',
    labelGreek: 'ΕΙΔΗ ΥΓΙΕΙΝΗΣ',
  },
  {
    value: 4555,
    label: 'TOOL',
    labelGreek: 'ΕΡΓΑΛΕΙΟ',
  },
  {
    value: 4556,
    label: 'TRADESMAN',
    labelGreek: 'ΒΙΟΤΕΧΝΗΣ',
  },
  {
    value: 4557,
    label: 'TROPHY',
    labelGreek: 'ΤΡΟΠΑΙΟ',
  },
  {
    value: 4558,
    label: 'VENETIAN BLIND SUPPLIER',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ ΒΕΝΕΤΙΚΩΝ BLIND',
  },
  {
    value: 4559,
    label: 'CAR PAINTERS',
    labelGreek: 'ΒΑΦΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ',
  },
  {
    value: 4560,
    label: 'PHOTO ENGRAVING',
    labelGreek: 'ΦΩΤΟΧΑΡΑΞΗ',
  },
  {
    value: 4561,
    label: 'PHOTO PROCESSING AND PRINTING',
    labelGreek: 'ΕΠΕΞΕΡΓΑΣΙΑ ΚΑΙ ΕΚΤΥΠΩΣΗ ΦΩΤΟΓΡΑΦΙΩΝ',
  },
  {
    value: 4562,
    label: 'PHOTOCOPYING',
    labelGreek: 'ΦΩΤΟΤΥΠΙΚΟ ΚΑΤΑΣΤΗΜΑ',
  },
  {
    value: 4563,
    label: 'PHOTOGRAPHIC EQUIPMENT',
    labelGreek: 'ΦΩΤΟΓΡΑΦΙΚΟ ΥΛΙΚΟ',
  },
  {
    value: 4564,
    label: 'PHOTOGRAPHIC STUDIO',
    labelGreek: 'ΦΩΤΟΓΡΑΦΕΙΟ',
  },
  {
    value: 4565,
    label: 'PICTURE SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΕΙΚΟΝΩΝ',
  },
  {
    value: 4566,
    label: 'SHOP PICTURE FRAMING',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΚΟΡΝΙΖΑΣ',
  },
  {
    value: 4567,
    label: 'PLUMBERS EQUIPMENT',
    labelGreek: 'ΕΞΟΠΛΙΣΜΟΣ ΥΔΡΑΥΛΙΚΩΝ',
  },
  {
    value: 4568,
    label: 'PLUMBERS MERCHANT',
    labelGreek: 'ΕΜΠΟΡΟΣ ΥΔΡΑΥΛΙΚΩΝ ΕΙΔΩΝ',
  },
  {
    value: 4569,
    label: 'RESTAURANT OR SHOP OWNER',
    labelGreek: 'ΕΣΤΙΑΤΟΡΙΟ Η ΙΔΙΟΚΤΗΤΗΣ ΚΑΤΑΣΤΗΜΑΤΟΣ',
  },
  {
    value: 4570,
    label: 'FOOTWEAR',
    labelGreek: 'ΥΠΟΔΗΜΑΤΑ',
  },
  {
    value: 4571,
    label: 'SHOE',
    labelGreek: 'ΠΑΠΟΥΤΣΙΑ',
  },
  {
    value: 4572,
    label: 'SHOE REPAIRING',
    labelGreek: 'ΕΠΙΣΚΕΥΗ ΠΑΠΟΥΤΣΙΩΝ',
  },
  {
    value: 4573,
    label: 'SHOE SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΠΑΠΟΥΤΣΙΩΝ',
  },
  {
    value: 4574,
    label: 'CAMPING & OUTDOOR CAR ACCESSORY SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΑΞΕΣΟΥΑΡ ΑΥΤΟΚΙΝΗΤΟΥ  ΕΙΔΩΝ ΚΑΤΑΣΚΗΝΩΣΗΣ',
  },
  {
    value: 4575,
    label: 'GOLF EQUIPMENT',
    labelGreek: 'ΕΞΟΠΛΙΣΜΟΣ ΓΚΟΛΦ',
  },
  {
    value: 4576,
    label: 'GOLF PROFESSIONALS',
    labelGreek: 'ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΓΚΟΛΦ',
  },
  {
    value: 4577,
    label: 'HOBBY SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΑΣΧΟΛΙΩΝ ΑΝΑΨΥΧΗΣ',
  },
  {
    value: 4578,
    label: 'SNOOKER AND POOL TABLE RETAILING',
    labelGreek: 'ΛΙΑΝΙΚΗ ΠΩΛΗΣΗ ΜΠΙΛΙΑΡΔΟΥ ΚΑΙ ΤΡΑΠΕΖΙ ΜΠΙΛΙΑΡΔΟΥ',
  },
  {
    value: 4579,
    label: 'SPORTING GOODS STORES',
    labelGreek: 'ΑΘΛΗΤΙΚΑ ΕΙΔΗ ΚΑΤΑΣΤΗΜΑΤΑ',
  },
  {
    value: 4580,
    label: 'SPORTS GOODS',
    labelGreek: 'ΕΝΔΥΜΑΤΩΝ',
  },
  {
    value: 4581,
    label: 'SPORTSWEAR',
    labelGreek: 'ΑΘΛΗΤΙΚΑ',
  },
  { value: 4582, label: 'PENS', labelGreek: 'ΣΤΥΛΟ' },
  {
    value: 4583,
    label: 'PRINT SHOP',
    labelGreek: 'ΚΑΤΑΣΤΗΜΑ ΓΙΑ ΕΚΤΥΠΩΣΕΙΣ',
  },
  {
    value: 4584,
    label: 'STATIONERS AND OFFICE SUPPLIES',
    labelGreek: 'ΧΑΡΤΟΠΩΛΕΙΑ ΚΑΙ ΕΙΔΗ ΓΡΑΦΕΙΟΥ',
  },
  {
    value: 4585,
    label: 'SUPPLIER',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ',
  },
  {
    value: 4586,
    label: 'SUPPLIER CLOCK & WATCH REPAIR CLOCK & WATCH SALES',
    labelGreek: 'ΠΡΟΜΗΘΕΥΤΗΣ ΩΡΟΛΟΓΙΩΝ ΕΠΙΣΚΕΥΗ ΩΡΟΛΟΓΙΩΝ ΠΩΛΗΣΕΙΣ',
  },
  {
    value: 4587,
    label: 'SUPPLY CERAMICS AND POTTERY',
    labelGreek: 'ΠΡΟΜΗΘΕΙΑ ΚΕΡΑΜΙΚΗΣ ΚΑΙ ΑΓΓΕΙΟΠΛΑΣΤΙΚΗΣ',
  },
  {
    value: 4588,
    label: 'HIRE CAR PROPRIETORS',
    labelGreek: 'ΕΝΟΙΚΙΑΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ ΙΔΙΟΚΤΗΤΕΣ',
  },
  {
    value: 4589,
    label: 'TICKET AGENT',
    labelGreek: 'ΠΡΑΚΤΟΡΕΙΟ ΕΙΣΗΤΗΡΙΩΝ',
  },
  {
    value: 4590,
    label: 'TRAVELING SALESMAN OR COMMERCIAL TRAVELLER',
    labelGreek: 'ΠΛΑΝΟΔΙΟΣ ΠΩΛΗΤΗΣ  Ή ΕΠΑΓΓΕΛΜΑΤΙΑΣ ΤΑΞΙΔΙΩΤΗΣ',
  },
  {
    value: 4591,
    label: 'PROPERTY AUDITOR',
    labelGreek: 'ΕΛΕΓΚΤΗΣ ΑΚΙΝΗΤΟΥ',
  },
  {
    value: 4592,
    label: 'CARTOGRAPHER',
    labelGreek: 'ΧΑΡΤΟΓΡΑΦΟΣ',
  },
  {
    value: 4593,
    label: 'CHARTERED VALUER',
    labelGreek: 'ΟΡΚΩΤΟΙ ΕΚΤΙΜΗΤΕΣ',
  },
  {
    value: 4594,
    label: 'ENERGY ASSESSMENT - DOMESTIC',
    labelGreek: 'ΕΝΕΡΓΕΙΑΚΗ ΑΞΙΟΛΟΓΗΣΗ - ΟΙΚΙΑΚΗ',
  },
  {
    value: 4595,
    label: 'ENERGY PERFORMANCE CERTIFICATES / TESTING',
    labelGreek: 'ΠΙΣΤΟΠΟΙΗΤΙΚΑ ΕΝΕΡΓΕΙΑΚΗΣ ΑΠΟΔΟΣΗΣ / ΔΟΚΙΜΩΝ',
  },
  {
    value: 4596,
    label: 'ESTATE AGENT - COMMERCIAL',
    labelGreek: 'ΚΤΗΜΑΤΟΜΕΣΙΤΗΣ - (ΒΙΟΜΗΧΑΝΙΑ)',
  },
  {
    value: 4597,
    label: 'ESTATE AGENT - DOMESTIC',
    labelGreek: 'ΚΤΗΜΑΤΟΜΕΣΙΤΗΣ - (ΟΙΚΙΕΣ)',
  },
  {
    value: 4598,
    label: 'PROPERTY SEARCHES',
    labelGreek: 'ΑΝΑΖΗΤΗΣΕΙΣ ΑΚΙΝΗΤΩΝ',
  },
  {
    value: 4599,
    label: 'HOME INFORMATION',
    labelGreek: 'ΠΛΗΡΟΦΟΡΕΙΕΣ ΣΠΙΤΟΥ',
  },
  {
    value: 4600,
    label: 'HANDYMAN (PROPERTY MAINTENANCE ONLY)',
    labelGreek: 'ΠΟΛΥΤΕΧΝΙΤΗΣ (ΣΥΝΤΗΡΗΣΗΣ ΑΚΙΝΗΤΩΝ ΜΟΝΟ)',
  },
  {
    value: 4601,
    label: 'PROPERTY MAINTENANCE / HANDYMAN',
    labelGreek: 'ΣΥΝΤΗΡΗΣΗΣ ΑΚΙΝΗΤΩΝ / ΠΟΛΥΤΕΧΝΙΤΗΣ',
  },
  {
    value: 4602,
    label: 'PACK PRODUCTION',
    labelGreek: 'ΠΑΡΑΓΩΓΗ ΣΥΣΚΕΥΑΣΙΑ',
  },
  {
    value: 4603,
    label: 'PROJECT MANAGER',
    labelGreek: 'ΔΙΕΥΘΥΝΤΗ ΤΟΥ ΣΧΕΔΙΟΥ',
  },
  {
    value: 4604,
    label: 'PROPERTY MANAGING AGENT - COMMERCIAL',
    labelGreek: 'ΠΡΑΚΤΩΡΑΣ ΔΙΑΧΕΡΙΣΗΣ ΠΕΡΙΟΥΣΙΑΣ - ΕΜΠΟΡΙΚΗ',
  },
  {
    value: 4605,
    label: 'PROPERTY MANAGING AGENT - RESIDENTIAL',
    labelGreek: 'ΠΡΑΚΤΩΡΑΣ ΔΙΑΧΕΡΙΣΗΣ ΠΕΡΙΟΥΣΙΑΣ - ΚΑΤΟΙΚΙΩΝ',
  },
  {
    value: 4606,
    label: 'RESIDENTS ASSOCIATION',
    labelGreek: 'ΣΥΛΛΟΓΟΣ ΚΑΤΟΙΚΩΝ',
  },
  {
    value: 4607,
    label: 'QUANTITY SURVEYOR',
    labelGreek: 'ΕΠΙΜΕΤΡΗΤΗΣ',
  },
  {
    value: 4608,
    label: 'SURVEYOR - BUILDING SURVEYING',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΚΤΗΡΙΑΚΕΣ ΜΕΛΕΤΕΣ',
  },
  {
    value: 4609,
    label: 'SURVEYOR - DAMP PROOF & CONTROL',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΑΠΟΔΕΙΞΗΣ & ΕΛΕΓΧΟΥ',
  },
  {
    value: 4610,
    label: 'SURVEYOR - LAND & HYDRO GRAPHIC',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΓΗ & ΥΔΡΟΓΡΑΦΙΚΕΣ',
  },
  {
    value: 4611,
    label: 'SURVEYOR - QUANTITY SURVEYING',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΜΕΤΡΗΣΗ ΠΟΣΟΤΗΤΑΣ',
  },
  {
    value: 4612,
    label: 'SURVEYOR - RATING CONSULTANCY',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΣΥΜΒΟΥΛΩΝ ΒΑΘΜΟΛΟΓΗΣΗΣ',
  },
  {
    value: 4613,
    label: 'SURVEYOR - AUCTIONEER',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΕΚΠΛΕΙΣΤΗΡΙΑΣΤΗ',
  },
  {
    value: 4614,
    label: 'SURVEYOR - BUILDING SOCIETY AGENCY',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ -  ΟΡΓΑΝΙΣΜΟΣ ΚΤΙΡΙΩΝ',
  },
  {
    value: 4615,
    label: 'SURVEYOR - COMMERCIAL',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΕΜΠΟΡΙΚΟΣ',
  },
  {
    value: 4616,
    label: 'SURVEYOR - EXPERT WITNESS',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΕΙΔΙΚΟΣ ΜΑΡΤΥΡΑΣ',
  },
  {
    value: 4617,
    label: 'SURVEYOR - PARTY WALL/SCHEDULES OF DILAPIDATIONS',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΜΕΣΟΤΟΙΧΙΑΣ / ΣΧΗΜΑΤΩΝ',
  },
  {
    value: 4618,
    label: 'SURVEYOR - PLANNING SUPERVISION',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΣΧΕΔΙΑΣΜΟΣ ΕΠΟΠΤΕΙΑ',
  },
  {
    value: 4619,
    label: 'SURVEYOR - PROJECT COORDINATION',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΣΥΝΤΟΝΙΣΜΟΣ ΕΡΓΟΥ',
  },
  {
    value: 4620,
    label: 'SURVEYOR - PROJECT MANAGEMENT',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΔΙΑΧΕΙΡΙΣΗ ΕΡΓΟΥ',
  },
  {
    value: 4621,
    label: 'SURVEYOR - RATING CONSULTANCY',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΙΚΑΝΟΤΗΤΑ ΣΥΜΒΟΥΛΩΝ',
  },
  {
    value: 4622,
    label: 'SURVEYOR - RESIDENTIAL TOWN PLANNER',
    labelGreek: 'ΕΠΙΘΕΩΡΗΤΗΣ - ΚΑΤΟΙΚΙΩΝ ΠΟΛΕΟΔΟΜΟΣ',
  },
  {
    value: 4623,
    label: 'AGENTS',
    labelGreek: 'ΠΡΑΚΤΟΡΕΣ',
  },
  {
    value: 4624,
    label: 'AIR TRAFFIC CONTROLLER',
    labelGreek: 'ΕΛΕΓΚΤΗΣ ΕΝΑΕΡΙΑΣ ΚΥΚΛΟΦΟΡΙΑΣ',
  },
  {
    value: 4625,
    label: 'AVIATION COMPANIES WHERE NO MANUFACTURE IS CARRIED ON',
    labelGreek: 'ΑΕΡΟΠΟΡΙΚΕΣ ΕΤΑΙΡΕΙΕΣ ΣΤΙΣ ΟΠΟΙΕΣ ΔΕΝ ΓΙΝΕΤΑΙ ΔΙΕΡΓΑΣΙΑ',
  },
  {
    value: 4626,
    label: 'AVIATION STAFF (GROUND STAFF)',
    labelGreek: 'ΑΕΡΟΠΟΡΙΑ ΠΡΟΣΩΠΙΚΟ (ΠΡΟΣΩΠΙΚΟ ΕΔΑΦΟΥΣ)',
  },
  {
    value: 4627,
    label: 'FLIGHT ATTENDANT',
    labelGreek: 'ΦΡΟΝΤΙΣΤΗΣ',
  },
  {
    value: 4628,
    label: 'FLIGHT ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΠΤΗΣΗΣ',
  },
  {
    value: 4629,
    label: 'FLIGHT INSTRUCTOR',
    labelGreek: 'ΕΚΠΑΙΔΕΥΤΗΣ ΠΤΗΣΗΣ',
  },
  {
    value: 4630,
    label: 'PILOTS AND ALL EMPLOYEE WHO MAY AT ANY TIME TRAVEL IN AIRCRAFT',
    labelGreek:
      ' : ΠΙΛΟΤΟΙ ΚΑΙ ΟΛΟΙ ΟΙ ΕΡΓΑΖΟΜΕΝΟΙ Ο ΟΠΟΙΟΙ ΜΠΟΡΕΙ ΑΝΑ ΠΑΣΑ ΣΤΙΓΜΗ ΝΑ ΤΑΞΙΔΕΨΟΥΝ ΜΕ ΑΕΡΟΣΚΑΦΟΣ',
  },
  {
    value: 4631,
    label: 'STEWARD OR STEWARDESS',
    labelGreek: 'ΑΕΡΟΣΥΝΟΔΟΙ',
  },
  {
    value: 4632,
    label: 'BUS CHARTER SERVICES',
    labelGreek: 'ΥΠΗΡΕΣΙΕΣ ΝΑΥΛΩΜΕΝΩΝ ΛΕΩΦΟΡΕΙΩΝ',
  },
  {
    value: 4633,
    label: 'BUS OPERATIONS - SCHOOLS',
    labelGreek: 'ΑΠΟΣΤΟΛΕΣ ΛΕΩΦΟΡΕΙΩΝ - ΣΧΟΛΕΙΑ',
  },
  {
    value: 4634,
    label: 'CARRIERS - HEAVY GOODS / ARTICLES',
    labelGreek: 'ΟΧΗΜΑΤΑ - ΒΑΡΕΑ ΑΓΑΘΑ/ΑΡΘΡΩΤΑ',
  },
  {
    value: 4635,
    label: 'CARRIERS - LIGHT PACKAGES AND GOODS',
    labelGreek: 'ΜΕΤΑΦΟΡΕΙΣ - ΕΛΑΦΡΙΑ ΠΑΚΕΤΑ ΚΑΙ ΠΡΟΪΟΝΤΑ',
  },
  {
    value: 4636,
    label: 'DECORATOR DISTRIBUTORS - HEAVY GOODS / ARTICLES',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ ΕΙΔΩΝ ΔΙΑΚΟΣΜΗΣΗΣ - ΒΑΡΕΑ ΑΓΑΘΑ / ΑΡΘΡΩΤΑ',
  },
  {
    value: 4637,
    label: 'DISTRIBUTORS - LIGHT PARCELS OR GOODS',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ - ΕΛΑΦΡΙΑ ΔΕΜΑΤΑ Η ΠΡΟΙΟΝΤΑ',
  },
  {
    value: 4638,
    label: 'DISTRIBUTORS - LIQUID FUEL / GAS KYLIINDRON',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ - ΥΓΡΩΝ ΚΑΥΣΙΜΩΝ / ΚΥΛΙΝΔΡΩΝ ΑΕΡΙΟΥ',
  },
  {
    value: 4639,
    label: 'SALESMAN / DISTRIBUTORS - HEAVY GOODS',
    labelGreek: 'ΠΩΛΗΤΗΣ / ΔΙΑΝΟΜΕΙΣ - ΒΑΡΕΑ ΠΡΟΙΟΝΤΑ',
  },
  {
    value: 4640,
    label: 'SALESMAN / DISTRIBUTORS - LIGHT GOODS',
    labelGreek: 'ΠΩΛΗΤΗΣ / ΔΙΑΝΟΜΕΙΣ - ΕΛΑΦΡΙΑ ΠΡΟΙΟΝΤΑ',
  },
  {
    value: 4641,
    label: 'COMMERCIAL TRAVELLERS',
    labelGreek: 'ΕΜΠΟΡΙΚΟΙ ΤΑΞΙΔΙΩΤΕΣ',
  },
  {
    value: 4642,
    label: 'COURRIER',
    labelGreek: 'ΔΙΑΝΟΜΕΙΣ',
  },
  {
    value: 4643,
    label: 'DELIVERY MEN USING CARS',
    labelGreek: 'ΠΑΡΑΔΟΣΗ ΠΡΟΙΟΝΤΩΝ ΜΕ ΧΡΗΣΗ ΑΥΤΟΚΙΝΗΤΟΥ',
  },
  {
    value: 4644,
    label: 'DELIVERY MEN USING CYCLES',
    labelGreek: 'ΠΑΡΑΔΟΣΗ ΠΡΟΙΟΝΤΩΝ ΤΗ ΒΟΗΘΕΙΑ ΜΟΤΟΣΥΚΛΕΤΑΣ',
  },
  {
    value: 4645,
    label: 'HAULIER',
    labelGreek: 'ΜΕΤΑΦΟΡΕΑΣ',
  },
  {
    value: 4646,
    label: 'MESSENGERS',
    labelGreek: 'ΑΓΓΕΛΙΟΦΟΡΟΙ',
  },
  {
    value: 4647,
    label: 'PARCEL DELIVERY AGENTS CARRIERS & CARTONS',
    labelGreek: 'ΠΡΑΚΤΟΡΕΣ ΠΑΡΑΔΟΣΗΣ ΔΕΜΑΤΩΝ ΚΑΙΟ ΧΑΡΤΟΚΟΥΤΩΝ',
  },
  {
    value: 4648,
    label: 'CUSTOMS BROKERS',
    labelGreek: 'ΕΚΤΕΛΩΝΙΣΤΕΣ',
  },
  {
    value: 4649,
    label: 'DISPATCHER',
    labelGreek: 'ΑΠΟΣΤΟΛΕΑΣ',
  },
  {
    value: 4650,
    label: 'BUS DRIVER',
    labelGreek: 'ΟΔΗΓΟΣ ΛΕΩΦΟΡΕΙΟΥ',
  },
  {
    value: 4651,
    label: 'CHAUFFEUR',
    labelGreek: 'ΟΔΗΓΩΝ',
  },
  {
    value: 4652,
    label: 'DRIVERS (BUS / TAXI)',
    labelGreek: 'ΟΔΗΓΟΙ ( ΛΕΩΦΟΡΙΟ / ΤΑΞΙ )',
  },
  {
    value: 4653,
    label: 'DRIVERS (TRUCK OR OTHER HEAVY-DUTY VEHICLES)',
    labelGreek: 'ΟΔΗΓΟΙ ( ΦΟΡΤΗΓΟ Η ΑΛΛΑ ΟΧΗΜΑΤΑ ΒΑΡΕΩΣ ΤΥΠΟΥ )',
  },
  {
    value: 4654,
    label: 'DRIVERS OF AGRICULTURAL MACHINERY',
    labelGreek: 'ΟΔΗΓΟΙ ΤΩΝ ΓΕΩΡΓΙΚΩΝ ΜΗΧΑΝΗΜΑΤΩΝ',
  },
  {
    value: 4655,
    label: 'GERANODIGOI',
    labelGreek: 'ΧΕΙΡΙΣΤΗΣ ΓΕΡΑΝΟΥ',
  },
  {
    value: 4656,
    label: 'GUIDES (INCIDENT OR OTHER LIGHT DUTY VEHICLES)',
    labelGreek: 'ΟΔΗΓΟΙ (ΟΧΗΜΑΤΑ ΕΛΑΦΡΟΥ ΤΥΠΟΥ)',
  },
  {
    value: 4657,
    label: 'LIMOUSINE SERVICES',
    labelGreek: 'ΥΠΗΡΕΣΙΕΣ ΛΙΜΟΥΖΙΝΑΣ',
  },
  {
    value: 4658,
    label: 'LANDLORD (ALSO LANDLADY)',
    labelGreek: 'ΣΠΙΤΟΝΟΙΚΟΚΥΡΑ/ΣΠΙΤΟΝΟΙΚΟΚΥΡΗΣ',
  },
  {
    value: 4659,
    label: 'LINGUIST',
    labelGreek: 'ΓΛΩΣΣΟΛΟΓΟΣ',
  },
  {
    value: 4660,
    label: 'CABIN BOY',
    labelGreek: 'ΚΑΜΑΡΩΤΟΣ',
  },
  {
    value: 4661,
    label: 'CHIEF MATE',
    labelGreek: 'ΥΠΟΠΛΟΙΑΡΧΟΣ',
  },
  {
    value: 4662,
    label: 'EMPLOYEES ON VESSELS',
    labelGreek: 'ΥΠΑΛΛΗΛΟΙ ΣΕ ΣΚΑΦΗ',
  },
  {
    value: 4663,
    label: 'FIRST/SECOND/THIRD MATE',
    labelGreek: 'ΠΡΩΤΟΣ / ΔΕΥΤΕΡΟΣ / ΤΡΙΤΟΣ ΒΟΗΘΟΣ ΚΑΤΑΣΤΡΩΜΑΤΟΣ',
  },
  {
    value: 4664,
    label: 'MARINE SEAMAN',
    labelGreek: 'ΝΑΥΤΙΚΟΣ (ΚΡΟΥΑΖΙΕΡΟΠΛΟΙΑ)',
  },
  {
    value: 4665,
    label: 'MERCHANT SEAMAN',
    labelGreek: 'ΝΑΥΤΙΚΟΣ(ΕΜΠΟΡΙΚΑ ΠΛΟΙΑ)',
  },
  {
    value: 4666,
    label: 'NAVIGATOR',
    labelGreek: 'ΠΛΟΗΓΟΣ',
  },
  {
    value: 4667,
    label: 'SAILOR',
    labelGreek: 'ΝΑΥΤΗΣ',
  },
  {
    value: 4668,
    label: 'SAILOR AGENTS - EXCEPT OFFICE STAFF',
    labelGreek: 'ΝΑΥΤΙΚΟΙ ΠΡΑΚΤΟΡΕΣ - ΕΚΤΟΣ ΓΡΑΦΕΙΑΚΟΥ ΠΡΟΣΩΠΙΚΟΥ',
  },
  {
    value: 4669,
    label: 'STEAMBOAT CAPTAIN',
    labelGreek: 'ΚΑΠΕΤΑΝΙΟΣ ΑΤΜΟΠΛΟΙΟΥ',
  },
  {
    value: 4670,
    label: 'STEAMBOAT FOREMAN',
    labelGreek: 'ΚΑΠΕΤΑΝΙΟΣ ΑΤΜΟΠΛΟΙΟΥ',
  },
  {
    value: 4671,
    label: 'UNEMPLOYED',
    labelGreek: 'ΑΝΕΡΓΟΙ',
  },
  {
    value: 4672,
    label: 'HOUSEWIFE',
    labelGreek: 'ΝΟΙΚΟΚΥΡΆ',
  },
  {
    value: 4673,
    label: 'STUDENT',
    labelGreek: 'ΦΟΙΤΗΤΗΣ',
  },
  {
    value: 4674,
    label: 'INFANT',
    labelGreek: 'ΒΡΕΦΗ',
  },
  {
    value: 4675,
    label: 'RETIRED',
    labelGreek: 'ΣΥΝΤΑΞΙΟΥΧΟΣ',
  },
  { value: 4676, label: 'DJ', labelGreek: 'DJ' },
  {
    value: 4677,
    label: 'SHIP ENGINEER',
    labelGreek: 'ΜΗΧΑΝΙΚΟΣ ΠΛΟΙΩΝ',
  },
  { value: 4678, label: 'SERGEANT', labelGreek: '' },
  {
    value: 4679,
    label: 'HOTEL BARTENDER',
    labelGreek: 'ΜΠΑΡΜΑΝ ΣΕ ΞΕΝΟΔΟΧΕΙΟ',
  },
  {
    value: 4680,
    label: 'BAR/NIGHT SPOT BARTENDER',
    labelGreek: 'ΜΠΑΡΜΑΝ ΣΕ ΜΠΑΡ',
  },
  {
    value: 4681,
    label: 'RESTAURANT/CAFE BARTENDER',
    labelGreek: 'ΜΠΑΡΜΑΝ ΣΕ ΕΣΤΙΑΤΟΡΙΟ/ΚΑΦΕ',
  },
  {
    value: 4682,
    label: 'HOTEL WAITER',
    labelGreek: 'ΓΚΑΡΣΟΝΙ ΣΕ ΞΕΝΟΔΟΧΕΙΟ',
  },
  {
    value: 4683,
    label: 'BAR/NIGHT SPOT WAITER',
    labelGreek: 'ΓΚΑΡΣΟΝΙ ΣΕ ΜΠΑΡ',
  },
  {
    value: 4684,
    label: 'RESTAURANT/CAFE WAITER',
    labelGreek: 'ΓΚΑΡΣΟΝΙ ΣΕ ΕΣΤΙΑΤΟΡΙΟ/ΚΑΦΕ',
  },
  {
    value: 4685,
    label: 'MANAGER',
    labelGreek: '????T????S',
  },
  { value: 4687, label: 'DRIVER', labelGreek: '???G?S' },
  { value: 4688, label: 'DRIVER', labelGreek: '???G?S' },
  { value: 4689, label: 'DRIVER', labelGreek: '???G?S' },
  {
    value: 4691,
    label: 'MANAGER',
    labelGreek: '????T????S',
  },
  { value: 4692, label: 'DRIVER', labelGreek: '???G?S' },
  { value: 4693, label: 'DRIVER', labelGreek: '???G?S' },
  {
    value: 4695,
    label: 'MANAGER',
    labelGreek: '????T????S',
  },
  { value: 4696, label: 'DRIVER', labelGreek: '???G?S' },
];
