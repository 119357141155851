import React, { useContext } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { retrieveStorage } from '../helpers/storeService';

import FlowDriversInformation from '../pages/FlowDriversInformation';
import FlowDriversInformationExtra from '../pages/FlowDriversInformationExtra';
import FlowHomeInformationExtra from '../pages/FlowHomeInformationExtra';
import FlowAddAdditionalDriver from '../pages/FlowAddAdditionalDriver';
import FlowHomeInformation from '../pages/FlowHomeInformation';
import FlowSearchForVehicle from '../pages/FlowSearchForVehicle';
import FlowCarInformation from '../pages/FlowCarInformation';
import FlowPriceCalculator from '../pages/FlowPriceCalculator';
import FlowPolicyHolderInfo from '../pages/FlowPolicyHolderInfo';
import FlowOverview from '../pages/FlowOverview';
import FlowHomeIndications from '../pages/FlowHomeIndications';
import FlowSafetySystem from '../pages/FlowSafetySystem';
import Login from '../pages/Login';
import FlowPolicyHolderAddress from '../pages/FlowPolicyHolderAddress';
import FlowHealthInsuranceSpecifics from '../pages/FlowHealthInsuranceSpecifics';
import FlowRiskAddress from '../pages/FlowRiskAddress';
import FlowHomeRoomEstimations from '../pages/FlowHomeRoomEstimations';
import FlowHealthExistingMedicalInsuranceQuestionMobile from '../pages/FlowHealthExistingMedicalInsuranceQuestionMobile';
import FlowCarExistingVehicleInsuranceInformationMobile from '../pages/FlowCarExistingVehicleInsuranceInformationMobile';
import FlowThankyou from '../pages/FlowThankyou';
import FlowManualVehicleExtra from '../pages/FlowManualVehicleExtra';
import FlowInsurancePack from '../pages/FlowInsurancePack';
import FlowHealthInformation from '../pages/FlowHealthInformation';
import FlowDoctorInformation from '../pages/FlowDoctorInformation';
import FlowBloodInformation from '../pages/FlowBloodInformation';
import FlowHBA1CInformation from '../pages/FlowHBA1CInformation';
import FlowParentalInformation from '../pages/FlowParentalInformation';
import FlowGeneralInformation from '../pages/FlowGeneralInformation';
import FlowCarInsuranceSpecifics from '../pages/FlowCarInsuranceSpecifics';
import FlowSelectInsurances from '../pages/FlowSelectInsurances';
import FlowHomeBuildingInfo from '../pages/FlowHomeBuildingInfo';
import FlowHomeBuildingInfoExtra from '../pages/FlowHomeBuildingInfoExtra';
import FlowHomeExistingPropertyInsuranceQuestion from '../pages/FlowHomeExistingPropertyInsuranceQuestion';
import FlowCarExistingVehicleInsuranceInformation from '../pages/FlowCarExistingVehicleInsuranceInformation';
import FlowHomeExistingPropertyInsuranceInformation from '../pages/FlowHomeExistingPropertyInsuranceInformation';
import FlowHealthExistingInsuranceInformation from '../pages/FlowHealthExistingInsuranceInformation';
import FlowHealthExistingMedicalInsuranceInformationMobile from '../pages/FlowHealthExistingMedicalInsuranceInformationMobile';
import FlowHealthExistingInsuranceQuestion from '../pages/FlowHealthExistingMedicalInsuranceQuestion';
import FlowCarRegistered from '../pages/FlowCarRegistered';
import FlowReplacementVehicle from '../pages/FlowReplacementVehicle';
import FlowVehiclePurpose from '../pages/FlowVehiclePurpose';
import FlowAverageDistanceTravelled from '../pages/FlowAverageDistanceTravelled';
import FlowInsureExtras from '../pages/FlowInsureExtras';
import FlowInsuranceOptions from '../pages/FlowInsuranceOptions';
import FlowCarPremiumSummary from '../pages/FlowCarPremiumSummary';
import FlowHealthPremiumSummary from '../pages/FlowHealthPremiumSummary';
import FlowCarExistingVehicleInsuranceQuestionMobile from '../pages/FlowCarExistingVehicleInsuranceQuestionMobile';
import FlowSecondRiskAddress from '../pages/FlowSecondRiskAddress';
import FlowHomeInsuranceOptions from '../pages/FlowHomeInsuranceOptions';
import FlowHealthInsuranceOptions from '../pages/FlowHealthInsuranceOptions';
import FlowHomeInsuranceSpecifics from '../pages/FlowHomeInsuranceSpecifics';
import FlowHomeHighRiskSpecifics from '../pages/FlowHomeHighRiskSpecifics';
import FlowHomePremiumSummary from '../pages/FlowHomePremiumSummary';
import FlowSecondCarGeneralInfo from '../pages/FlowSecondCarGeneralInfo';
import FlowPricingComparison from '../pages/FlowPricingComparison';

//mobile flow
import FlowSelectInsurancesMobile from '../pages/FlowSelectInsurancesMobile';
import FlowHomeSurfaceArea from '../pages/FlowHomeSurfaceArea';
// import FlowHomeInformationExtraMobile from './FlowHomeInformationExtraMobile';
import FlowHomeRenovation from '../pages/FlowHomeRenovation';
// import FlowHomeExtraQuestions from './FlowHomeExtraQuestions';
// import FlowHomeStorageUnit from './FlowHomeStorageUnit';
import FlowHomeBuildingUnattended from '../pages/FlowHomeBuildingUnattended';
// import FlowHomeExistingPropertyInsuranceInformationMobile from '../pages/FlowHomeExistingPropertyInsuranceInformationMobile';
import FlowSecondRiskAddressMobile from '../pages/FlowSecondRiskAddressMobile';
import FlowHomeInsuranceSpecificsMobile from '../pages/FlowHomeInsuranceSpecificsMobile';
import FlowHomeHighRiskSpecificsMobile from '../pages/FlowHomeHighRiskSpecificsMobile';
import FlowHomePremiumSummaryMobile from '../pages/FlowHomePremiumSummaryMobile';
import FlowCarRegisteredMobile from '../pages/FlowCarRegisteredMobile';
import FlowCarSecondHandMobile from '../pages/FlowCarSecondHandMobile';
import FlowCarLicensePlateSearchMobile from '../pages/FlowCarLicensePlateSearchMobile';
import FlowCarManualSearchMobile from '../pages/FlowCarManualSearchMobile';
import FlowLicenseLookupResponseMobile from '../pages/FlowLicenseLookupResponseMobile';
import FlowCarInfoExtraMobile from '../pages/FlowCarInfoExtraMobile';
import FlowCarReplacementVehicle from '../pages/FlowCarReplacementVehicle';
import FlowVehiclePurposeMobile from '../pages/FlowVehiclePurposeMobile';
import FlowAverageDistanceTravelledMobile from '../pages/FlowAverageDistanceTravelledMobile';
import FlowDriversInformationMobile from '../pages/FlowDriversInformationMobile';
import FlowCarInsuranceOptionsMobile from '../pages/FlowCarInsuranceOptionsMobile';
import FlowCarPremiumSummaryMobile from '../pages/FlowCarPremiumSummaryMobile';
// import FlowHomeOwnerMobile from '../pages/FlowHomeOwnerMobile';
import FlowInsureExtrasMobile from '../pages/FlowInsureExtrasMobile';

import ImpossibleFlow from '../pages/ImpossibleFlow';

import FlowRouterRedirect from '../components/FlowRouterRedirect';
// import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import { ThemeContext } from '../contexts/ThemingContext';
// import { useFlowStore } from '../contexts/FlowContext';
import FlowInsureExtrasPets from '../pages/FlowInsureExtrasPets';
import FlowInsureExtrasBike from '../pages/FlowInsureExtrasBike';
import FlowDriversInformationPolicy from '../pages/FlowDriversInformationPolicy';
import FlowHealthInformationPolicy from '../pages/FlowHealthInformationPolicy';
import FlowCarExistingVehicleInsuranceQuestion from '../pages/FlowCarExistingVehicleInsuranceQuestion';
import FlowHealthPremiumSummaryMobile from '../pages/FlowHealthPremiumSummaryMobile';
import FlowHealthInsuranceOptionsMobile from '../pages/FlowHealthInsuranceOptionsMobile';
import MobileRouter from './MobileRouter';

import RouteHandler from './RouteHandler';
import FlowHomeInsuranceOptionsMobile from '../pages/FlowHomeInsuranceOptionsMobile';

const FlowRouter = () => {
  const { getFirstRoute } = useRouting();
  const { affinity, insuranceType } = useParams();
  const { id } = retrieveStorage(affinity, insuranceType) || '';
  const themeContext = useContext(ThemeContext);
  themeContext.setTheme(affinity);

  return (
    <Switch>
      <Route path="/:affinity/:insuranceType/verify-user" component={Login} />
      <Route
        path="/:affinity/:insuranceType/not-possible"
        component={ImpossibleFlow}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/user-address/:id"
        component={FlowPolicyHolderAddress}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information/:currentVehicle/:id"
        component={FlowDriversInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-extra/:currentVehicle/:id"
        component={FlowDriversInformationExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-policy-info/:currentVehicle/:id"
        component={FlowDriversInformationPolicy}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/health-policy-info/:currentHealth/:id"
        component={FlowHealthInformationPolicy}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information-extra/:currentVehicle/:id"
        component={FlowManualVehicleExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-surface-area/:currentProperty/:id"
        component={FlowHomeSurfaceArea}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/insure-extras-mobile/:id"
        component={FlowInsureExtrasMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insure-extras-mobile-car/:currentVehicle/:id"
        component={FlowInsureExtrasMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/additional-drivers/:currentVehicle/:id"
        component={FlowAddAdditionalDriver}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insure-extras-mobile-home/:id"
        component={FlowInsureExtrasMobile}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/select-insurances-mobile/:id"
        component={FlowSelectInsurancesMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-already-registered-mobile/:currentVehicle/:id"
        component={FlowCarRegisteredMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/risk-address/:currentProperty/:id"
        component={FlowRiskAddress}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/second-car/:id"
        component={FlowSecondCarGeneralInfo}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/second-risk-address/:currentProperty/:id"
        component={FlowSecondRiskAddress}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-information/:currentProperty/:id"
        component={FlowHomeInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-information-extra/:currentProperty/:id"
        component={FlowHomeInformationExtra}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/home-renovation/:currentProperty/:id"
        component={FlowHomeRenovation}
      />
      {/* <RouteHandler
        path="/:affinity/:insuranceType/session/home-extra-questions/:currentProperty/:id"
        component={FlowHomeExtraQuestions}
      /> */}

      {/* <RouteHandler
        path="/:affinity/:insuranceType/session/storage-unit/:currentProperty/:id"
        component={FlowHomeStorageUnit}
      /> */}
      <RouteHandler
        path="/:affinity/:insuranceType/session/building-information-extra-mobile/:id"
        component={FlowHomeBuildingUnattended}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/health-insurance-policy-information/:currentHealth/:id"
        component={FlowHealthExistingInsuranceInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/health-insurance-policy-information-mobile/:currentHealth/:id"
        component={FlowHealthExistingMedicalInsuranceInformationMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/second-risk-address-mobile/:currentProperty/:id"
        component={FlowSecondRiskAddressMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-options-health/:currentHealth/:id"
        component={FlowHealthInsuranceOptions}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-options-car-mobile/:currentVehicle/:id"
        component={FlowCarInsuranceOptionsMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-premium-summary-mobile/:currentVehicle/:id"
        component={FlowCarPremiumSummaryMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/second-car-registered-mobile/:id"
        component={FlowCarRegisteredMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-specifics-mobile/:currentProperty/:id"
        component={FlowHomeInsuranceSpecificsMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-high-risk-mobile/:currentProperty/:id"
        component={FlowHomeHighRiskSpecificsMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-premium-summary-mobile/:currentProperty/:id"
        component={FlowHomePremiumSummaryMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-second-hand-mobile/:currentVehicle/:id"
        component={FlowCarSecondHandMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/license-plate-lookup-mobile/:currentVehicle/:id"
        component={FlowCarLicensePlateSearchMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-lookup-mobile/:currentVehicle/:id"
        component={FlowCarManualSearchMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/license-lookup-response-mobile/:currentVehicle/:id"
        component={FlowLicenseLookupResponseMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information-extra-mobile/:currentVehicle/:id"
        component={FlowCarInfoExtraMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/replacement-vehicle-mobile/:currentVehicle/:id"
        component={FlowCarReplacementVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/average-km-travelled/:currentVehicle/:id"
        component={FlowAverageDistanceTravelled}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-purpose-mobile/:currentVehicle/:id"
        component={FlowVehiclePurposeMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/average-km-travelled-mobile/:currentVehicle/:id"
        component={FlowAverageDistanceTravelledMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-mobile/:currentVehicle/:id"
        component={FlowDriversInformationMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-extra-mobile/:currentVehicle/:id"
        component={FlowDriversInformationPolicy}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/building-information/:currentProperty/:id"
        component={FlowHomeBuildingInfo}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/building-information-extra/:currentProperty/:id"
        component={FlowHomeBuildingInfoExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-policy/:currentProperty/:id"
        component={FlowHomeExistingPropertyInsuranceQuestion}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-insurance-policy/:currentVehicle/:id"
        component={FlowCarExistingVehicleInsuranceQuestion}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-insurance-policy-mobile/:currentVehicle/:id"
        component={FlowCarExistingVehicleInsuranceQuestionMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/health-insurance-policy/:currentHealth/:id"
        component={FlowHealthExistingInsuranceQuestion}
      />
      <Route
        path="/:affinity/:insuranceType/session/health-insurance-policy-mobile/:currentHealth/:id"
        component={FlowHealthExistingMedicalInsuranceQuestionMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-insurance-policy-information/:currentVehicle/:id"
        component={FlowCarExistingVehicleInsuranceInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-insurance-policy-information-mobile/:currentVehicle/:id"
        component={FlowCarExistingVehicleInsuranceInformationMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-policy-information/:currentProperty/:id"
        component={FlowHomeExistingPropertyInsuranceInformation}
      />
      <Route
        path="/:affinity/:insuranceType/session/insurance-options-home/:currentProperty/:id"
        component={FlowHomeInsuranceOptions}
      />
      <Route
        path="/:affinity/:insuranceType/session/insurance-options-home-mobile/:currentProperty/:id"
        component={FlowHomeInsuranceOptionsMobile}
      />
      <Route
        path="/:affinity/:insuranceType/session/home-specifics/:currentProperty/:id"
        component={FlowHomeInsuranceSpecifics}
      />
      <Route
        path="/:affinity/:insuranceType/session/vehicle-specifics/:currentVehicle/:id"
        component={FlowCarInsuranceSpecifics}
      />
      <Route
        path="/:affinity/:insuranceType/session/health-specifics/:currentHealth/:id"
        component={FlowHealthInsuranceSpecifics}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insure-extras/:id"
        component={FlowInsureExtras}
      />
      <Route
        path="/:affinity/:insuranceType/session/home-high-risk/:currentProperty/:id"
        component={FlowHomeHighRiskSpecifics}
      />
      <Route
        path="/:affinity/:insuranceType/session/home-premium-summary/:currentProperty/:id"
        component={FlowHomePremiumSummary}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-already-registered/:currentVehicle/:id"
        component={FlowCarRegistered}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information/:currentVehicle/:id"
        component={FlowCarInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-room-information/:currentProperty/:id"
        component={FlowHomeRoomEstimations}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-specials/:currentProperty/:id"
        component={FlowHomeIndications}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/thankyou/:id"
        component={FlowThankyou}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-vehicle/:id"
        component={FlowSearchForVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-vehicle-form/:id"
        component={FlowSearchForVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information/:currentVehicle/:id"
        component={FlowCarInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-pack/:id"
        component={FlowInsurancePack}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-extras/:currentProperty/:id"
        component={FlowInsureExtras}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-extras-pets/:currentProperty/:id"
        component={FlowInsureExtrasPets}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-extras-bike/:currentProperty/:id"
        component={FlowInsureExtrasBike}
      />
      <Route
        path="/:affinity/:insuranceType/session/insurance-options-car/:currentVehicle/:id"
        component={FlowInsuranceOptions}
      />
      <Route
        path="/:affinity/:insuranceType/session/price-comparison/:currentVehicle/:id"
        component={FlowPricingComparison}
      />
      <Route
        path="/:affinity/:insuranceType/session/car-premium-summary/:currentVehicle/:id"
        component={FlowCarPremiumSummary}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/replacement-vehicle/:currentVehicle/:id"
        component={FlowReplacementVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/vehicle-purpose/:currentVehicle/:id"
        component={FlowVehiclePurpose}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/safety-systems/:id"
        component={FlowSafetySystem}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/price-calculator/:id"
        component={FlowPriceCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/policyholder-information/:id"
        component={FlowPolicyHolderInfo}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/overview/:id"
        component={FlowOverview}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/health-information/:currentHealth/:id"
        component={FlowHealthInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/blood-information/:currentHealth/:id"
        component={FlowBloodInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/doctor-information/:currentHealth/:id"
        component={FlowDoctorInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/parental-information/:currentHealth/:id"
        component={FlowParentalInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/hba1c-test-information/:currentHealth/:id"
        component={FlowHBA1CInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/general-information/:currentHealth/:id"
        component={FlowGeneralInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/select-insurances/:id"
        component={FlowSelectInsurances}
      />
      <Route
        path="/:affinity/:insuranceType/session/health-premium-summary/:currentHealth/:id"
        component={FlowHealthPremiumSummary}
      />
      <Route
        path="/:affinity/:insuranceType/session/insurance-options-health/:currentHealth/:id"
        component={FlowHealthInsuranceOptions}
      />
      <Route
        path="/:affinity/:insuranceType/session/health-premium-summary-mobile/:currentHealth/:id"
        component={FlowHealthPremiumSummaryMobile}
      />
      <Route
        path="/:affinity/:insuranceType/session/insurance-options-health-mobile/:currentHealth/:id"
        component={FlowHealthInsuranceOptionsMobile}
      />
      <FlowRouterRedirect
        from="/:affinity/:insuranceType/session"
        to={getFirstRoute()}
        id={id}
        loginPath="verify-user"
        exact
      />
      <MobileRouter />
    </Switch>
  );
};

export default FlowRouter;
