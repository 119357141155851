import { setLoggedIn } from './authService';
import { request } from './httpSessionService';
import { ganRequest } from './ganHttpSessionService';
import { DISTRIBUTION_ID } from './brokerDataService';
import {
  AUTH_HOST,
  GANDIRECT_PASSWORD,
  GANDIRECT_USERNAME,
} from '../env.config';
import i18n from '../i18n.js';

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function authMe() {
  const path = 'v1/api/aut/v1/api/auth/me';
  const [resp, status] = await request(path, 'GET');
  if (status === 200) {
    setLoggedIn({
      email: resp.me.email,
      language: resp.me.language,
      role: resp.me.role,
    });
  } else {
    console.error(resp, status);
    alert('Something went wrong @ authme');
  }
  return [resp, status];
}

export async function ganAuth() {
  const path = 'api/Auth';
  const [resp, status] = await ganRequest(path, 'POST', {
    Username: GANDIRECT_USERNAME,
    Password: GANDIRECT_PASSWORD,
  });
  if (status === 200) {
    sessionStorage.setItem('gan-token', resp.token);
  } else {
    console.error(resp, status);
    alert('Something went wrong @ ganAuth');
  }
  return [resp, status];
}

export async function auth(email, password) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request(path, 'POST', {
    email: email,
    password: password,
  });
  if (status === 200) {
    sessionStorage.setItem('access_token', resp?.access_token);
    sessionStorage.setItem('refresh_token', resp?.refresh_token);
  }
  return [resp, status];
}

export async function verifyInit() {
  /*
   * the verify method that is called on page load
   * it checks if you have cookies set.
   *
   * if there are expired cookies, renew them and go to dashboard
   * if no cookies found redirect to login page
   *
   */
  const path = 'v1/api/aut/v1/api/auth/verify';
  const [resp, status] = await request(path, 'GET');
  if (status === 401) {
    return [resp, status];
  } else {
    return await authMe();
  }
}

export async function captchaAuth({
  captcha,
  email,
  firstName,
  lastName,
  askBrokerId,
  company_name,
}) {
  const path = 'auth/captcha';
  const payload = {
    email,
    token: captcha,
    first_name: firstName,
    last_name: lastName,
    ask_broker_id: askBrokerId,
    version: 'V3',
  };
  const [resp, status] = await request(path, 'POST', payload, AUTH_HOST);
  if (status === 200) {
    sessionStorage.setItem('access_token', resp?.access_token);
    sessionStorage.setItem('refresh_token', resp?.refresh_token);
  }
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * BROKER DATA
 * * * * * * * * * * * * * * */

export async function retrieveBrokerById(id) {
  const path = `v1/api/broker/v1/api/broker/${id}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAR DATA
 * * * * * * * * * * * * * * */
export async function retrieveCarBrands(query) {
  const q = query ? `?${query}` : '';
  const path = `v1/api/car/v1/api/brands${q}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileBrands(query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAllAutomobileMakesGAN() {
  const path = `api/V1/Vehicle/Make/All`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileByCar(brand, seriesId, carId) {
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models/${seriesId}/versions/${carId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveCarModels(query, brand) {
  const q = query ? `?${query}` : '';
  const path = `v1/api/car/v1/api/models${q}`;
  const payload = { brand };
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveAutomobileModelsByBrand(brand, query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileModelsByMakeGAN(makeId) {
  const path = `api/v1/Vehicle/Make/${makeId}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function createNewVehicleGAN(payload) {
  const path = `api/v1/Vehicle`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function updateVehicleGAN(payload, vehicleOid) {
  const path = `api/v1/Vehicle/${vehicleOid}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

export async function retrieveAutomobileVersionsBySeries(
  brand,
  seriesId,
  query
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models/${seriesId}/versions${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveCarVerions(query, brand, model) {
  const q = query ? `?${query}` : '';
  const path = `v1/api/car/v1/api/versions${q}`;
  const payload = { brand, model };
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveCarInfo(brand, model, version, model_year, kw) {
  const path = `v1/api/car/v1/api/car`;
  const payload = { brand, model, version, model_year: model_year, kw: kw };
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveCarPrice(
  payload,
  affinity,
  insuranceType,
  insurance,
  queryStrings
) {
  let affinityTag = affinity; // honda, mazda, valckenier, doceo
  if (['de-lijn', 'agoria', 'at-work'].includes(affinity)) {
    affinityTag = 'at_work';
  } else if (['moto-insurance', 'callant'].includes(affinity)) {
    affinityTag = 'motor_insurance';
  }

  const query = queryStrings ? `?${queryStrings}` : '';
  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${affinityTag}/${insuranceType}/${insurance}${query}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveLicensePlateV2(licensePlate) {
  const path = `v1/api/vehicle/v1/api/license_plate/${licensePlate}?country_code=BE&vehicle_type=CAR`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CRM DATA
 * * * * * * * * * * * * * * */

export async function createLead(prospect, risk_objects, affinityName) {
  const payload = {
    origin: `Website - ${affinityName}`,
    prospect,
    risk_objects,
  };
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/leads`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function createOffer(
  quoteId,
  acknowledgements,
  startDate,
  endDate,
  prospect,
  risk_objects
) {
  const payload = {
    quote_id: quoteId,
    acknowledgements,
    start_date: startDate,
    end_date: endDate,
    prospect,
    risk_objects,
  };
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/offers`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}
/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function retrieveAddress(query) {
  const path = `v1/api/address/v1/api/search?q=${query}&country_code=CY&language_code=${i18n.language.toUpperCase()}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAddressInfo(payload) {
  const path = `v1/api/address/v2/api/info`;
  const [resp, status] = await request(path, 'POST', payload);
  if (status === 200) {
    var i;
    for (i = 0; i < resp.links.length; i++) {
      if (
        [
          'aerial_images_marked_satellite',
          'aerial_images_blank_satellite',
        ].includes(resp.links[i].rel)
      ) {
        const path2 = `v1/api/address${
          resp.links[i].href.split('v1/api/address')[1]
        }`;
        const [respImage] = await request(path2, 'GET');
        resp[resp.links[i].rel] = respImage;
      }
    }
  }
  return [resp, status];
}

export async function retrieveAddressStreets(payload) {
  let path;
  if (payload.searchQuery) {
    path = `v1/api/address/v1/api/streets?country_code=CY&q=${payload.searchQuery}&q_city=${payload.city}`;
  } else if (payload.city) {
    path = `v1/api/address/v1/api/streets?country_code=CY&q_city=${payload.city}`;
  }
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAddressCities(payload) {
  let path;
  if (payload.zipcode && !payload.searchQuery) {
    path = `v1/api/address/v1/api/cities?country_code=CY&zipcode=${payload.zipcode}`;
  } else if (payload.searchQuery && !payload.zipcode) {
    path = `v1/api/address/v1/api/cities?country_code=CY&q=${payload.searchQuery}`;
  }
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * POLICY
 * * * * * * * * * * * * * * */

export async function retrieveSignature(payload) {
  const path = `v1/api/policy/v1/api/purchase?lang=${i18n.language}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * INSURERS
 * * * * * * * * * * * * * * */

export async function retrieveInsurers() {
  const path = `v1/api/broker/v1/api/insurance_companies`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * GANDATA
 * * * * * * * * * * * * * * */

export async function ganGetCustomerById(id) {
  const path = `api/v1/Customer/${id}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function ganGetLoyaltyPointsByCustomerId(id) {
  const path = `api/v1/Loyalty/${id}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function ganGetLegalCustomerById(id) {
  const path = `api/v1/Customer/Company/${id}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function ganGetCustomerVehicles(id) {
  const path = `api/v1/Customer/${id}/VehicleList`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

/**
 * Get the gan address object
 * @param {Object} payload - An object.
 */
export async function ganGetAddress(payload) {
  const path = `api/v1/Address`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

/**
 * Create a new GAN customer
 * @param {Object} payload - The payload object.
 */
export async function ganCreateCustomerIndvdl(payload) {
  const path = `api/v1/Customer`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

/**
 * Update an existing GAN customer
 * @param {Object} payload - The payload object, must only contain the values that need to be changed in BE.
 * @param {string | number} id - The customer's Oid string OR identification number
 */
export async function ganUpdateCustomerIndvdl(payload, id) {
  const path = `api/v1/Customer/${id}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

/**
 * Update Payment after Gateway Response
 * @param {Object} payload - An object.
 * @param {string} orderId - A string.
 */
export async function ganUpdatePayment(payload, orderId) {
  const path = `api/v1/Payment/${orderId}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function post(path, params, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

/**
 * Get the gan address object
 * @param {Object} payload - An object.
 */
export async function ganSendPaymentGateway(payload) {
  const origin = 'https://tjccpg.jccsecure.com/EcomPayment/RedirectAuthLink';
  const path = ``;
  const [resp, status] = await request(path, 'POST', payload, origin);
  return [resp, status];
}

/**
 * Get the Gan payment api
 * @param {Object} payload - An object.
 */
export async function ganCreateNewPayment(payload) {
  const path = `api/v1/Payment`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveLicensePlateGan(plate) {
  const path = `api/v1/Vehicle/${plate}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}

export async function createCarPolicyGan(payload) {
  const path = `api/v1/Motor`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function updateVehiclePolicyGan(payload, id) {
  const path = `api/v1/Motor/${id}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

export async function createPropertyPolicyGan(payload) {
  const path = `api/v1/Property`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function updatePropertyPolicyGan(payload, id) {
  const path = `api/v1/Property/${id}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

export async function addAddressToCustomer(payload, _customerId) {
  const path = `api/v1/Customer/${_customerId}/AddressesList`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function createHealthPolicyGan(payload) {
  const path = `api/v1/Medical`;
  const [resp, status] = await ganRequest(path, 'POST', payload);
  return [resp, status];
}

export async function updateHealthPolicyGan(payload, id) {
  const path = `api/v1/Medical/${id}`;
  const [resp, status] = await ganRequest(path, 'PUT', payload);
  return [resp, status];
}

export async function retrieveCompanyByRegistNr(registNr) {
  const path = `api/v1/Company/${registNr}`;
  const [resp, status] = await ganRequest(path, 'GET');
  return [resp, status];
}
