/* eslint-disable react/jsx-key */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { getThemeObject } from '../helpers/themingService.js';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import RoomInput from '../components/RoomInput';

import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';
import CarCatGanIcon from '../assets/icons/CarCatGanIcon';
import HelCatGanIcon from '../assets/icons/HelCatGanIcon';

const FlowSelectInsurances = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [error, setError] = useState();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const history = useHistory();
  const [insurances, setInsurances] = useState();

  useEffect(() => {
    const question = {
      message: i18n.t('What would you like to insure?'),
      slug: 'select-insurances',
    };
    addQuestion(question);
    setInsuranceTypes(insuranceType);
  }, []);

  const setInsuranceTypes = (insuranceType) => {
    switch (insuranceType) {
      case 'car':
        setInsurances({ Car: 0 });
        break;
      case 'home':
        setInsurances({ Home: 0 });
        break;
      case 'health':
        setInsurances({ Health: 0 });
        break;
      case 'all':
        setInsurances({ Home: 0, Car: 0, Health: 0 });
        break;
      default:
        setInsurances(data.insurances);
        break;
    }
  };

  useEffect(() => {
    if (insurances?.Car > 0 || insurances?.Home > 0 || insurances?.Health > 0) {
      setError('');
    }
  }, [insurances]);

  function handleChange(e, type) {
    if (e !== type[1] && e !== insurances[type[0]]) {
      setInsurances({
        ...insurances,
        [type[0]]: e,
      });
    }
  }

  function handleSubmit() {
    if (
      insurances?.Car === 0 &&
      insurances?.Home === 0 &&
      insurances?.Health === 0
    ) {
      return setError('Please select an insurance');
    }
    setError();

    patchStorage({
      payload: {
        insurances: insurances,
        ...(insurances?.Car > 0 ? { cars: new Array(insurances?.Car) } : {}),
        ...(insurances?.Home > 0
          ? { risk_addresses: new Array(insurances?.Home) }
          : {}),
        ...(insurances?.Health > 0
          ? { healths: new Array(insurances?.Health) }
          : {}),
      },
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: i18n.t(
        `I would like to insure ${
          insurances?.Car === 1
            ? 'a car'
            : insurances?.Car > 1
            ? 'carCount cars'
            : ''
        }${
          insurances?.Home === 1 && insurances?.Car >= 1
            ? ' and a property'
            : insurances?.Home > 1 && insurances?.Car >= 1
            ? ' and propertyCount properties'
            : insurances?.Home === 1
            ? 'a property'
            : insurances?.Home > 1
            ? 'propertyCount properties'
            : ''
        }${
          insurances?.Health === 1 &&
          (insurances?.Home >= 1 || insurances?.Car >= 1)
            ? ' and a health'
            : insurances?.Health > 1 &&
              (insurances?.Home >= 1 || insurances?.Car >= 1)
            ? ' and healthCount health'
            : insurances?.Health === 1
            ? 'a health'
            : insurances?.Health > 1
            ? 'healthCount health'
            : ''
        }`,
        {
          carCount: insurances?.Car,
          propertyCount: insurances?.Home,
          healthCount: insurances?.Health,
        }
      ),
      slug: 'building_information',
    };

    addAnswer(answer);

    if (insurances.Home > 0) {
      renderNextRoute(1, { property: 0 });
    } else if (insurances.Car > 0) {
      renderNextRoute(2, { vehicle: 0 });
    } else if (insurances.Health > 0) {
      renderNextRoute(3, { health: 0 });
    }
  }

  function getInsuranceIcon(type) {
    if (type === 'Home') {
      return <HomeCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
    if (type === 'Car') {
      return <CarCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
    if (type === 'Health') {
      return <HelCatGanIcon colors={getThemeObject(affinity)} size={50} />;
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <div>
          <TitleForm>{i18n.t('Choose your insurances')}</TitleForm>
          <div className="insurances">
            {insurances &&
              Object.entries(insurances).map((insuranceType, index) => {
                return (
                  <StyledInsuranceTypeInput
                    key={insuranceType[0]}
                    value={insuranceType[1]}
                    icon={getInsuranceIcon(insuranceType[0])}
                    initialValue={insuranceType[1]}
                    selected={insuranceType[1] > 0}
                    label={i18n.t(insuranceType[0])}
                    maxValue={10}
                    minValue={0}
                    onInputChange={(e) => handleChange(e, insuranceType)}
                  />
                );
              })}
          </div>
        </div>
        <Error>{error}</Error>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <ActionButton onClick={handleSubmit}>{i18n.t('Next')}</ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  justify-content: left;
  font-size: 1.4rem;
  color: #f74040;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledInsuranceTypeInput = styled(RoomInput)`
  margin-bottom: 2rem;

  :last-of-type {
    margin-bottom: 0;
  }

  circle,
  line,
  path,
  polyline,
  rect {
    stroke: ${({ selected }) => (selected ? 'default' : '#CFCECE !important')};
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }

  div.insurances {
    margin-bottom: 3rem;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 8px;
    }
    ::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white; /* should match background, can't be transparent */
      background-color: ${({ theme }) => theme.brand.primary};
    }

    ::-webkit-scrollbar-track-piece {
      background: #f0f1f3;
      border-radius: 5px;
      width: 8px;
      border: 2px solid white; /* should match background, can't be transparent */
    }
  }
`;

export default FlowSelectInsurances;
