import React, { useState } from 'react';
import styled from 'styled-components';

import {
  ActionButton,
  IconActionRead,
  IconActionUnRead,
  TextInput,
  PhoneInput,
} from 'wg-fe-ui';
import LoadingActionButton from './LoadingActionButton';
import { detect } from 'detect-browser';
import { useTranslation } from 'react-i18next';

const FlowPolicyHolderInfoFormDefaultInputs = ({
  defaultValues,
  errors,
  values,
  handleChange,
  loading,
}) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  return (
    <>
      <FlexWrapper>
        <PhoneInput
          name="telephonenr"
          country="cy"
          error={errors.telephonenr}
          handleChange={handleChange}
          value={values.telephonenr}
          specialLabel=""
        >
          {t('Phonenumber')} *
        </PhoneInput>
        <TextInput
          name="email"
          error={errors.email}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues.email !== undefined
              ? defaultValues.email
              : values.email
          }
        >
          {t('Email')} *
        </TextInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledPasswordInput
          error={errors?.password}
          type={
            passwordVisible || detect().name === 'chrome' ? 'text' : 'password'
          }
          passVisible={passwordVisible}
          name="password"
          value={values?.password}
          onChange={handleChange}
          pattern="[^' ']+"
        >
          {t('Password')}*
          <EyeButton
            error={errors.password}
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? (
              <IconActionRead color="#000" />
            ) : (
              <IconActionUnRead color="#000" />
            )}
          </EyeButton>
        </StyledPasswordInput>
        <StyledPasswordInput
          error={errors?.confirmPassword}
          type={
            confirmPasswordVisible || detect().name === 'chrome'
              ? 'text'
              : 'confirmPassword'
          }
          passVisible={confirmPasswordVisible}
          name="confirmPassword"
          value={values?.confirmPassword}
          onChange={handleChange}
          pattern="[^' ']+"
        >
          {t('Confirm password')} *
          <EyeButton
            error={errors.confirmPassword}
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            {confirmPasswordVisible ? (
              <IconActionRead color="#000" />
            ) : (
              <IconActionUnRead color="#000" />
            )}
          </EyeButton>
        </StyledPasswordInput>
      </FlexWrapper>
      <Required>* {t('Required fields')}</Required>
      <ButtonContainer>
        {loading ? (
          <LoadingActionButton
            type="submit"
            value="Confirm"
            data-test-id="risk_address_flow_loading"
          >
            {t('Next')}
          </LoadingActionButton>
        ) : (
          <StyledActionButton
            type="submit"
            value="Next"
            data-test-id="policy_holder_flow_submit"
          >
            {t('Next')}
          </StyledActionButton>
        )}
      </ButtonContainer>
    </>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 1.6rem;
  @media (max-width: 768px) {
    text-align: center;
    margin: 2rem auto 2rem 2rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    width: 100%;
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;

    & > div + div {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
`;

const EyeButton = styled.div`
  position: absolute;
  right: ${({ error }) => (error ? '2.5rem' : '1rem')};
  transition: 0.2s;
  width: 2rem;
  bottom: 0.3rem;
  user-select: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;

const StyledPasswordInput = styled(TextInput)`
  height: 9rem;
  input {
    -webkit-text-security: ${({ passVisible }) =>
      !passVisible ? 'disc' : null};
  }
  label {
    color: #8990a3;
  }
`;

export default FlowPolicyHolderInfoFormDefaultInputs;
