import React from 'react';

const ArtGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#024284" />
          <stop offset="0.81" stopColor="#004587" />
          <stop offset="0.82" stopColor="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <rect
            x="20.43"
            y="27.83"
            width="38.74"
            height="29.43"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <polyline
            points="30.66 27.49 39.8 18.35 48.95 27.49"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <polyline
            points="24.09 46.78 35.9 34.98 44.54 43.62"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <path
            d="M40.39,48l8.14-8.15,5,5"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default ArtGanIcon;
