export const driverLicenseTypeOptions = [
  { value: 'Definitive', label: 'Definitive' },
  { value: 'Provisional', label: 'Provisional' },
];

export const disabilityAmountEnum = [];

export const DeductibleEnum = [
  { value: '1', label: 0 },
  { value: '8', label: 0 },
  { value: '2', label: 250 },
  { value: '3', label: 450 },
  { value: '4', label: 600 },
  { value: '5', label: 850 },
  { value: '6', label: 1700 },
  { value: '7', label: 5000 },
  { value: '9', label: 7500 },
  { value: '10', label: 10000 },
];

export const coInsuranceEnum = [
  { value: '1', label: 0 },
  { value: '2', label: 10 },
  { value: '3', label: 20 },
  { value: '4', label: 30 },
];

export const DurationLiveAbroadEnum = [
  {
    value: 0,
    label: '0 - 3 months',
  },
  {
    value: 1,
    label: '3 - 6 months',
  },
  {
    value: 2,
    label: 'More than 6 months',
  },
];

export const AnualLimitEnum = [
  {
    value: 1,
    label: '100.000,00',
  },
  {
    value: 2,
    label: '200.000,00',
  },
  {
    value: 3,
    label: '2.000.000,00',
  },
];

export const ReasonForDoctorVisitEnum = [
  { value: 1, label: 'Consultation' },
  { value: 2, label: 'Sickness' },
  { value: 3, label: 'The cold, flue, etc' },
  { value: 4, label: 'Checkup' },
];

export const primaryOptions = [
  { value: 'yes', label: 'yes' },
  { value: 'no', label: 'no' },
];

export const yesNoOptions = [
  { value: true, label: 'yes' },
  { value: false, label: 'no' },
];

export const titleOptions = [
  { value: 0, label: 'Mr' },
  { value: 1, label: 'Mrs' },
  { value: 2, label: 'Miss' },
  { value: 3, label: 'Ms' },
];

export const workDistanceOptions = [
  { value: 'LESS_THAN_15', label: 'LESS_THAN_15' },
  { value: 'BETWEEN_15_AND_25', label: 'BETWEEN_15_AND_25' },
  { value: 'BETWEEN_25_AND_50', label: 'BETWEEN_25_AND_50' },
  { value: 'MORE_THAN_50', label: 'MORE_THAN_50' },
];

///* VEHICLE *///

export const fuelTypeGan = [
  { value: 'UNLEADED', label: 'UNLEADED' },
  { value: 'DIESEL', label: 'DIESEL' },
  { value: 'ELECTRIC', label: 'ELECTRIC' },
  { value: 'HYBRID', label: 'HYBRID' },
  { value: 'CNG', label: 'CNG' },
];

export const styleOptions = [
  { value: 'Convertible/Hard-Top', label: 'Convertible Hard-Top' },
  { value: 'Convertible/Semi-covered', label: 'Convertible Semi-covered' },
  { value: 'Convertible/Soft-Top', label: 'Convertible Soft-Top' },
  { value: 'Ordinary', label: 'Ordinary' },
];

export const steeringPositionOptions = [
  { value: 0, label: 'Right' },
  { value: 1, label: 'Left' },
  { value: 2, label: 'Middle' },
  { value: 3, label: 'Both sides' },
];

export const ownedFinancedOrLeasedOptions = [
  { value: 0, label: 'Owned' },
  { value: 1, label: 'Financed' },
  { value: 2, label: 'Leased' },
  { value: 3, label: 'Joint ownership' },
  { value: 4, label: 'Joint onwership and financed ' },
];

export const useOfVehicleOptions = [
  { value: 0, label: 'Personal' },
  { value: 1, label: 'Pleasure' },
  { value: 2, label: 'Business' },
  { value: 3, label: 'Farming' },
];

export const bodyTypeOptionsGAN = [
  { value: 1, label: 'Saloon', labelGreek: 'Επιβατικό' },
  { value: 2, label: 'Own Goods', labelGreek: 'Ιδιών Εμπορευμάτων' },
  { value: 3, label: 'Cycles', labelGreek: 'Μοτοσυκλέτα' },
  {
    value: 4,
    label: 'Goods Carried',
    labelGreek: 'Μεταφοράς Εμπορευμάτων (Δημόσια Χρήση)',
  },
  { value: 7, label: 'Private Bus', labelGreek: 'Ιδιωτικό Λεοφωρείο' },
  { value: 8, label: 'Special Type', labelGreek: 'Ειδικού Τύπου' },
  { value: 10, label: 'Tricycles', labelGreek: 'Τρίκυκλα' },
];

export const styleOptionsGAN = [
  {
    value: '6ca1e8e1-72a4-4f79-bb6f-5caa2902445e',
    label: 'Convertible/hard-top',
    labelGreek: 'Ανοικτής Οροφής/Σκληρή Οροφή',
  },
  {
    value: 'cb2f1511-a724-48f4-ace3-f83f44638217',
    label: 'Convertible/semi- covered',
    labelGreek: 'Ανοικτής Οροφής/Ημικαλυπτόμενη',
  },
  {
    value: 'f6dc11da-9a90-4b4f-8fc0-6471a954c46f',
    label: 'Convertible/soft-top',
    labelGreek: 'Ανοικτής Οροφής/Μαλακή Οροφή',
  },
  {
    value: 'd4c1a84d-f637-4733-a3b1-cf885dffc0a2',
    label: 'Ordinary',
    labelGreek: 'Κανονικό',
  },
];

export const vehicleColorOptions = [
  { value: 2, label: 'Silver', labelGreek: 'ΑΣΗΜΙ' },
  { value: 4, label: 'White', labelGreek: 'ΑΣΠΡΟ' },
  { value: 5, label: 'Black', labelGreek: 'ΜΑΥΡΟ' },
  { value: 6, label: 'Blue', labelGreek: 'ΜΠΛΕ' },
  { value: 8, label: 'Grey', labelGreek: 'ΓΚΡΙΖΟ' },
  { value: 9, label: 'Red', labelGreek: 'ΚΟΚΚΙΝΟ' },
  { value: 10, label: 'Green', labelGreek: 'ΠΡΑΣΙΝΟ' },
  { value: 11, label: 'Gold', labelGreek: 'ΧΡΥΣΟ' },
  { value: 12, label: 'Beige', labelGreek: 'ΜΠΕΖ' },
  { value: 13, label: 'Brown', labelGreek: 'ΚΑΦΕ' },
  { value: 14, label: 'Yellow', labelGreek: 'ΚΙΤΡΙΝΟ' },
  { value: 15, label: 'Orange', labelGreek: 'ΠΟΡΤΟΚΑΛΙ' },
  { value: 16, label: 'Pink', labelGreek: 'ΡΟΖ' },
  { value: 17, label: 'Purple', labelGreek: 'ΜΩΒ' },
];

export const vehicleSupplyOptions = [
  { value: 1, label: 'Diesel', labelGreek: 'ΠΕΤΡΕΛΑΙΟ' },
  { value: 3, label: 'Electric', labelGreek: 'ΗΛΕΚΤΡΙΚΟ' },
  { value: 4, label: 'Gas', labelGreek: 'ΓΚΑΖΙ' },
  { value: 5, label: 'Hybrid', labelGreek: 'ΥΒΡΙΔΙΚΟ' },
  { value: 7, label: 'Solar', labelGreek: 'ΗΛΙΑΚΗ ΕΝΕΡΓΕΙΑ' },
  { value: 2, label: 'Unleaded', labelGreek: 'ΒΕΝΖΙΝΗ' },
];

export const annualMileageOptions = [
  { value: 1, from: 3001, to: 5000 },
  { value: 2, from: 20001, to: 25000 },
  { value: 3, from: 0, to: 3000 },
  { value: 4, from: 5001, to: 10000 },
  { value: 5, from: 10001, to: 15000 },
  { value: 6, from: 15001, to: 20000 },
  { value: 7, from: 25001, to: 30000 },
  { value: 8, from: 30001, to: 50000 },
  { value: 9, from: 50001, to: 100000 },
  { value: 10, from: 100001, to: 200000 },
];

///* OTHERS *///

export const accaptances = [
  {
    key: 'generalAcceptanceCloseOnline',
    title: 'Online communication',
    short: 'I agree to receive my documents by e-mail',
    long:
      'By purchasing this insurance online, I agree to receive my contract (general and special conditions) and all other documents relating to it by e-mail',
  },
  {
    key: 'generalAcceptanceNotTerminated',
    title: 'No prior notice of termination due to damage',
    short:
      'I confirm that my previous insurances were not cancelled due to a claim',
    long:
      'I confirm that one or more of my previous insurances, if any, have not been cancelled by the respective insurance companies for a claim',
  },
  {
    key: 'generalAcceptanceNotRefused',
    title: 'No previous refusal',
    short:
      'I confirm that I have no previous refused applications for the selected coverages',
    long:
      'I confirm that I do not have any previous applications refused by an insurance company for any of the selected coverages',
  },
  {
    key: 'generalAcceptanceTerms',
    title: 'General terms and conditions',
    short: 'I agree with the terms and conditions',
    long:
      'I agree to the terms and conditions including my 14 calendar day right of withdrawal and confirm that the information provided is truthful',
  },
  {
    key: 'generalAcceptancePrivacy',
    title: 'Privacy policy',
    short: 'I agree with the privacy policy',
    long:
      'I agree with the privacy policy and confirm that the content of the chosen insurance contract meets my requirements and needs',
  },
];

export const duration_time = [
  {
    label: '3 months',
    value: 3,
  },
  {
    label: '6 months',
    value: 6,
  },
  {
    label: '12 months',
    value: 12,
  },
  {
    label: 'Other',
    value: 0,
  },
];

export const column_material_options = [
  {
    label: 'Metal',
    value: 'Metal',
  },
  {
    label: 'Timber',
    value: 'Timber',
  },
  {
    label: 'Steel & Concrete',
    value: 'Steel & Concrete',
  },
];

export const claim_options = [
  {
    label: '0',
    value: '0_claim',
  },
  {
    label: '1',
    value: '1_claim',
  },
  {
    label: '2',
    value: '2_claims',
  },
  {
    label: '3',
    value: '3_claims',
  },
];

export const roof_options = [
  {
    label: 'Concrete',
    value: 'Concrete',
  },
  {
    label: 'Concrete & Tiles',
    value: 'Concrete & Tiles',
  },
  {
    label: 'Timber & Tiles',
    value: 'Timber & Tiles',
  },
];

export const walls_options = [
  {
    label: 'Bricks',
    value: 'Bricks',
  },
  {
    label: 'Bricks & Concrete',
    value: 'Bricks & Concrete',
  },
  {
    label: 'Bricks & PlasterBoard',
    value: 'Bricks & PlasterBoard',
  },
  {
    label: 'PlasterBoard',
    value: 'PlasterBoard',
  },
  {
    label: 'Stone',
    value: 'Stone',
  },
  {
    label: 'Timber',
    value: 'Timber',
  },
];

export const heating_pipes_options = [
  {
    label: 'Copper Pipes',
    value: 1,
  },
  {
    label: 'Hose',
    value: 2,
  },
  {
    label: 'Pipe In Pipe',
    value: 3,
  },
  {
    label: 'Plastic Pipes',
    value: 4,
  },
];

export const floor_options = [
  {
    label: 'Wood',
    value: 'Wood',
  },
  {
    label: 'Concrete',
    value: 'Concrete',
  },
  {
    label: 'Tiles',
    value: 'Tiles',
  },
  {
    label: 'Laminate',
    value: 'Laminate',
  },
];

export const insurance_packages = [{ value: 'Value' }, { value: 'Premium' }];

export const heating_method_options = [
  {
    label: 'Petrol Central Heating',
    value: 'Petrol Central Heating',
  },
  {
    label: 'Electricity Central Heating',
    value: 'Electricity Central Heating',
  },
  {
    label: 'Storage Heaters',
    value: 'Storage Heaters',
  },
  {
    label: 'Gas Fire Portable Heating',
    value: 'Gas Fire Portable Heating',
  },
  {
    label: 'Petrol Portable Heating',
    value: 'Petrol Portable Heating',
  },
  {
    label: 'Builded Fireplace',
    value: 'Builded Fireplace',
  },
  {
    label: 'Timber Frame Fireplace',
    value: 'Timber Frame Fireplace',
  },
  {
    label: 'Air-Conditioning',
    value: 'Air-Conditioning',
  },
  {
    label: 'Solar Heating',
    value: 'Solar Heating',
  },
  {
    label: 'Underfloor Electric Heating',
    value: 'Underfloor Electric Heating',
  },
  {
    label: 'Wood Stove',
    value: 'Wood Stove',
  },
  {
    label: 'Gas Central Heating',
    value: 'Gas Central Heating',
  },
  {
    label: 'None',
    value: 'none',
  },
];

export const insurance_companies = [
  { value: 'None', label: 'None' },
  { value: 'ABBEYGATE INSURANCE CO.', label: 'ABBEYGATE INSURANCE CO.' },
  { value: 'ALLIANZ INSURANCE CO.', label: 'ALLIANZ INSURANCE CO.' },
  { value: 'ALTIUS INSURANCE LTD', label: 'ALTIUS INSURANCE LTD' },
  {
    value: 'AMERICAN HOME ASSURANCE CO. (AIG)',
    label: 'AMERICAN HOME ASSURANCE CO. (AIG)',
  },
  { value: 'ANYTIME INSURANCE', label: 'ANYTIME INSURANCE' },
  { value: 'ATLANTIC INSURANCE CO. LTD', label: 'ATLANTIC INSURANCE CO. LTD' },
  { value: 'CNP ASFALISTIKI LTD', label: 'CNP ASFALISTIKI LTD' },
  { value: 'COMMERCIAL GENERAL', label: 'COMMERCIAL GENERAL' },
  { value: 'COSMOS INSURANCE CO. LTD', label: 'COSMOS INSURANCE CO. LTD' },
  { value: 'CYPRUS HIRE RISKS POOL', label: 'CYPRUS HIRE RISKS POOL' },
  { value: 'EMS', label: 'EMS' },
  { value: 'EUROLIFE INSURANCE', label: 'EUROLIFE INSURANCE' },
  { value: 'EUROSURE INSURANCE CO. LTD', label: 'EUROSURE INSURANCE CO. LTD' },
  { value: 'GAN DIRECT INSURANCE LTD', label: 'GAN DIRECT INSURANCE LTD' },
  {
    value: 'GAP VASSILOPOULOS INSURANCES',
    label: 'GAP VASSILOPOULOS INSURANCES',
  },
  {
    value: 'GENERAL INSURANCE OF CYPRUS',
    label: 'GENERAL INSURANCE OF CYPRUS',
  },
  { value: 'HYDRA INSURANCE CO. LTD', label: 'HYDRA INSURANCE CO. LTD' },
  { value: 'KENTRIKI INSURANCE CO. LTD', label: 'KENTRIKI INSURANCE CO. LTD' },
  { value: 'LEDRA INSURANCE LTD', label: 'LEDRA INSURANCE LTD' },
  { value: 'LUMEN INSURANCE CO LTD.', label: 'LUMEN INSURANCE CO LTD.' },
  { value: 'MEDLIFE', label: 'MEDLIFE' },
  { value: 'MIF', label: 'MIF' },
  { value: 'MINERVA INSURANCE CO. LTD', label: 'MINERVA INSURANCE CO. LTD' },
  { value: 'OLYMPIC INSURANCE LTD', label: 'OLYMPIC INSURANCE LTD' },
  { value: 'OVERSEAS', label: 'OVERSEAS' },
  { value: 'PANCYPRIAN INSURANCE LTD', label: 'PANCYPRIAN INSURANCE LTD' },
  { value: 'PRIME INSURANCE', label: 'PRIME INSURANCE' },
  {
    value: 'PROGRESSIVE INSURANCE CO. LTD',
    label: 'PROGRESSIVE INSURANCE CO. LTD',
  },
  {
    value: 'ROYAL CROWN INSURANCE CO. LTD',
    label: 'ROYAL CROWN INSURANCE CO. LTD',
  },
  {
    value: 'THE ETHNIKI. HELLENIC GENERAL INSURANCE S.A.',
    label: 'THE ETHNIKI. HELLENIC GENERAL INSURANCE S.A.',
  },
  {
    value: 'TRUST INTERNATIONAL INSURANCE CO. (CYPRUS) LTD',
    label: 'TRUST INTERNATIONAL INSURANCE CO. (CYPRUS) LTD',
  },
  { value: 'UNIVERSAL LIFE', label: 'UNIVERSAL LIFE' },
  {
    value: 'YDROGIOS INSURANCE CO (CYPRUS) LTD',
    label: 'YDROGIOS INSURANCE CO (CYPRUS) LTD',
  },
];

export const claim_types = [
  { value: 'OD Claim', label: 'OD Claim' },
  { value: 'TP Claim', label: 'TP Claim' },
  { value: 'OD & TP CLaim', label: 'OD & TP CLaim' },
  { value: 'Windscreen Claim', label: 'Windscreen Claim' },
];
