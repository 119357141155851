import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ActionButton,
  TextInput,
  IconActionChevronLeft,
  DateInputV4,
} from 'wg-fe-ui';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import { getNestedObject } from '../helpers/objectService';
import * as Yup from 'yup';
import { insurance_companies } from '../constants/FlowSearchSelectData';
import { number, string } from '../constants/validationSchemas';
import useForm from '../hooks/useForm';

const FlowHomeExistingPropertyInsuranceInformation = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [defaultValues, setDefaultValues] = useState({});
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    insurance_company_name: string.required,
    end_of_current_contract: Yup.date().when('insurance_company_name', {
      is: 'None' || 'GAN DIRECT INSURANCE LTD' || 'OVERSEAS',
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .required('Required')
        .min(new Date(), 'Date must be in the future'),
    }),
    insurance_price: Yup.number().when('insurance_company_name', {
      is: 'None' || 'GAN DIRECT INSURANCE LTD' || 'OVERSEAS',
      then: number.notRequired,
      otherwise: number.required,
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t('Great, can you give me some <span>details</span>?'),
      slug: 'existing_property_insurance_information',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      insurances: data.insurances,
      insurance_company_name: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'insurance_company_name',
      ]),
      end_of_current_contract: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'end_of_current_contract',
      ]),
      insurance_price: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'insurance_price',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.insurance_company_name != null) {
      handleChange({
        name: 'insurance_company_name',
        value: defaultValues.insurance_company_name,
      });
    }
    if (defaultValues.end_of_current_contract != null) {
      handleChange({
        name: 'end_of_current_contract',
        value: defaultValues.end_of_current_contract,
      });
    }
    if (defaultValues.insurance_price != null) {
      handleChange({
        name: 'insurance_price',
        value: defaultValues.insurance_price,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = () => {
    setLoading(true);

    const insurance_info = {
      insurance_company_name: values.insurance_company_name,
      end_of_current_contract: values.end_of_current_contract
        ? values.end_of_current_contract
        : null,
      insurance_price: values.insurance_price ? values.insurance_price : null,
    };

    patchStorage({
      payload: insurance_info,
      path: ['risk_addresses', [currentProperty], 'current_insurance_info'],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: `I have an insurance with <span>${
        values.insurance_company_name
      }</span> that ends on <span>${new Date(
        values.end_of_current_contract
      ).toLocaleDateString()}</span>.`,
      slug: 'existing_property_insurance_information',
    };

    addAnswer(answer);

    renderNextRoute(1, { property: currentProperty });

    setLoading(false);
  };

  function goBack() {
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <TitleForm>{i18n.t('Previous / Current Insurance')}</TitleForm>
            <SearchSelectInputV2
              name="insurance_company_name"
              error={errors.insurance_company_name}
              options={insurance_companies}
              onSelected={(val) => {
                handleChange(val);
              }}
            >
              <Label>{i18n.t('Insurance company')}</Label>
            </SearchSelectInputV2>
            {values.insurance_company_name == null ||
              (values.insurance_company_name !== 'None' &&
                values.insurance_company_name !== 'GAN DIRECT INSURANCE LTD' &&
                values.insurance_company_name !== 'OVERSEAS' && (
                  <>
                    <DateInputV4
                      name="end_of_current_contract"
                      error={errors.end_of_current_contract}
                      value={values.end_of_current_contract}
                      onChange={(val) => {
                        handleChange(val);
                      }}
                    >
                      <Label>{i18n.t('End date')}</Label>
                    </DateInputV4>
                    <StyledTextInput
                      name="insurance_price"
                      type="number"
                      min="0"
                      error={errors.insurance_price}
                      value={values.insurance_price}
                      onChange={(val) => {
                        handleChange(val);
                      }}
                    >
                      {i18n.t('Price')}
                      <p className="valueType">&euro;</p>
                    </StyledTextInput>
                  </>
                ))}
          </div>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Label = styled.p`
  color: #8990a3;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.2rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    > div > div {
      height: 9rem;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeExistingPropertyInsuranceInformation;
