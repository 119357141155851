import React from 'react';

const CarPremGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <line
            x1="30.1"
            y1="47.63"
            x2="51.51"
            y2="47.63"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <path
            d="M64.05,47.63h2.46a1.39,1.39,0,0,0,1.35-1.42V40.75a2.36,2.36,0,0,0-2-2.37l-13.38-2a16.4,16.4,0,0,0-13.47-7.12H26.44L21.3,36l-6.54,1.17V46a1.59,1.59,0,0,0,1.58,1.59h1.54"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="24.03"
            cy="46.46"
            r="4.83"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="24.03"
            cy="46.46"
            r="4.83"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="57.74"
            cy="46.46"
            r="4.83"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <line
            x1="21.3"
            y1="36.02"
            x2="52.52"
            y2="36.41"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <line
            x1="36.91"
            y1="29.29"
            x2="36.91"
            y2="36.02"
            style={{
              fill: 'none',
              stroke: '#c29b67',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default CarPremGanIcon;
