import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';
import i18n from '../i18n';
import {
  ActionButton,
  Alert,
  IconStatusCheck,
  IconActionDropDown,
  PieProgressIndicator,
  SearchSelectInput,
  IconCertificateFilled,
  SmallLoader,
  IconActionClose,
} from 'wg-fe-ui';
import MobileCoverDetail from '../components/MobileCoverDetail';
import Tooltip from '../components/Tooltip';
import { getCoverDetailsHome } from '../helpers/CoverDetailsService.js';
import { useQuery } from 'react-query';
import { createPropertyPolicyGan } from '../helpers/apiRouterService.js';
import { useTranslation } from 'react-i18next';

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowHomePremiumSummaryMobile = () => {
  const { renderNextRoute } = useRouting();
  const { t } = useTranslation();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [open, setOpen] = useState();
  const [openDiscounts, setOpenDiscounts] = useState();
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [discounts, setDiscounts] = useState([]);
  const [cachedData, setCachedData] = useState({});
  const [coverData, setCoverData] = useState(storage?.data);

  const getConstructionMaterialString = (package_info) => {
    return `${package_info?.column_material
      ?.map((value) => `Columns / ${value?.value}`)
      .join(';')};${package_info?.floor
      ?.map((value) => `Floor / ${value?.value}`)
      .join(';')};${package_info?.roof
      ?.map((value) => `Roof / ${value?.value}`)
      .join(';')};${package_info?.walls
      ?.map((value) => `Walls / ${value?.value}`)
      .join(';')}`;
  };

  const getPropertyType = (type) => {
    switch (type) {
      case 'open':
        return 35;
      case 'halfopen':
        return 30;
      case 'Detached':
        return 29;
      case 'Appartment':
        return 25;
      default:
        return 30;
    }
  };

  const getPayload = (_data, selected, currentProperty) => {
    const isPremium = selected === 'Premium';
    const payload = {
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      PropertyStatus: 1,
      AvailiableProductsForCustomer: _data?.risk_addresses[currentProperty]
        ?.is_landlord
        ? 'FTP_and_PL_and_EL'
        : 'HHC_and_EL',
      SelectedProduct:
        selected === 'Value'
          ? _data?.risk_addresses[currentProperty]?.is_landlord
            ? 1
            : 3
          : 0,
      DoYouWishToHaveCargoInsurance: 0,
      DoesYourBusinessEmployeeAnyAdditionalPeople: 0,
      DoPeopleOtherThanEmployeesEnterYourPremisses: 0,
      DoesYourBusinessProvideProfessionalAdviseOrConstultationToCustomers: 0,
      DoesYourBusinessSupplieProductsOrComponentsToCustomers: 0,
      CouldTheftOrDishonestyByEmployeesBeAProblem: 0,
      PremisesHasMachineryStockOrOtherContents: 0,
      WillYourBusinessBeAffectedByAnyInterruptionLikeFireFloodDeniedAccess: 0,
      BusinessIsInvolvedInTransportinGoodsOrProducts: 0,
      BusinessOperateMotorVechiclesOrUsesStaffOwnVechicles: 0,
      BusinessProvidesHealthBenefitsToStaff: 0,
      ExistingInsuranceCompany: {
        InsuranceCompany:
          _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.insurance_company_name || 'None',
      },
      ExpiringDateOfYourCurrentPolicy: _data?.risk_addresses[currentProperty]
        ?.parcel?.main_building?.insurance_company_name
        ? _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.risk_addresses[currentProperty]
        ?.parcel?.main_building?.insurance_company_name
        ? _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.insurance_price || null
        : null,
      BuildingAddress: _data?.risk_addresses[currentProperty]?.Oid,
      FinancialInstitution:
        _data.risk_addresses[currentProperty]?.FinancialInstitution || null,
      JointOwnership: 0,
      PropertyType: getPropertyType(
        _data.risk_addresses[currentProperty]?.parcel?.main_building?.house_type
      ),
      ManufactureYear:
        _data?.risk_addresses[currentProperty]?.parcel?.main_building
          ?.construction_year,
      DateOfLastRenovation:
        _data?.risk_addresses[currentProperty]?.parcel?.main_building
          ?.renovation_year ||
        new Date(
          _data?.risk_addresses[
            currentProperty
          ]?.parcel?.main_building?.construction_year
        ).toISOString(),
      ConsecutiveDaysUnoccupiedFor:
        _data?.ConsecutiveDaysUnoccupiedFor?.value || 1,
      ConstructionMaterial: getConstructionMaterialString(
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.construction_details
      ),
      MethodsOfHeating:
        _data.risk_addresses[
          currentProperty
        ].parcel?.main_building?.construction_details?.heating_method
          ?.map((value) => value?.value)
          ?.join(';') || 'None',
      WaterPipes:
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.WaterPipes || 1,
      UseOfProperty: 1,
      DoesYourPropertyHaveAntisesmicSurvey:
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.DoesYourPropertyHaveAntisesmicSurvey,
      TypeOfPremises: null,
      DoYouHaveAnyLargeQuantitiesOfWaterWithinPremises:
        'No Large Water Quantities',
      SafetyFeatures: '',
      IsThePropertyNeighbouringToAnOpenAreaWithBushesOrShrubsOrTreesWithinThirtyMetersFromProperty: 0,
      IsThereAManMadeOrNaturalFirePortectionZone: 0,
      IsThePropertyAtARemoteDestination: 0,
      PolicyDurationMonths:
        _data.risk_addresses[currentProperty]?.policy_details
          ?.insurance_duration,
      StartDate: new Date(
        _data.risk_addresses[currentProperty]?.policy_details?.starting_date
      ).toISOString(),
      EndDate: new Date(
        _data.risk_addresses[currentProperty]?.policy_details?.ending_date
      ).toISOString(),
      BuildingSumInsured:
        _data.BuildingSumInsured ||
        _data.risk_addresses[currentProperty]?.parcel?.main_building?.surface *
          1200,
      AccidentalDamageToBuilding: _data?.AccidentalDamageToBuilding || 0,
      ContentsSumInsured:
        _data.ContentsSumInsured ||
        _data.risk_addresses[currentProperty]?.parcel?.content_sum
          ?.insurance_price ||
        0,
      AccidentalDamageToContents: _data?.AccidentalDamageToContents || 0,
      TotalValueOfHighRiskItems: 0,

      SpecifiedItemsTotalValue: _data?.SpecifiedItemsTotalValue || 0,
      DomesticFreezeContents: _data?.DomesticFreezeContents || 0,
      TracingTheLeakCoverAmount:
        _data?.TracingTheLeakCoverAmount?.value ||
        '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a',
      ValuableAndPersonalEffects: _data?.ValuableAndPersonalEffects || 0,
      PersonalMoneyAndCreditCards: _data?.PersonalMoneyAndCreditCards || 0,
      UsePedalCycles: !!_data?.UsePedalCycles ? 1 : 0,
      PetInsurance: _data?.PetInsurance && isPremium ? 1 : 0,
      PetsInProperty:
        _data.risk_addresses[currentProperty]?.PetsInProperty || null,
      PropertyInventory:
        _data.risk_addresses[currentProperty]?.PropertyInventory?.length > 0
          ? _data.risk_addresses[currentProperty]?.PropertyInventory
          : null,
      ClassicVehicleInsurance:
        _data.risk_addresses[currentProperty]?.ClassicVehicleInsurance || 0,
      ClassicVehicleMarketValue: null,
      AccidentsToDomesticStaff: isPremium ? 1 : 0,
      VesselLiability: 0,
      SkiersLiability: 0,
      PassengerLiability: 0,
      HuntersLiability: 0,
      MedicalForDomesticStaff: 0,
      VesselInProperty: null,
      PedalCycles: _data.risk_addresses[currentProperty]?.Bikes
        ? _data.risk_addresses[currentProperty]?.Bikes
        : null,
      DomesticStaff: null,
      ContentsSumInsuredExcludingSAndC: null,
      TheftForContents: 0,
      StockSumInsured: null,
      TheftForStock: 0,
      OwnComputerEquipmentSumInsured: null,
      TotalElectronicEquipmentSumInsured: null,
      MoneyCoverSumInsured: null,
      BusinessInterruptionSumInsured: null,
      BusinessInterruptionForRegisteredCoreEmployeesSalaries: null,
      NetAnnualSalaryForAllCoreRegisteredEmployees: null,
      LimitOfIdemnityRequiredForAnyOneClaimSumInsured: null,
      NoOfAllEmployees: null,
      AnnualWagesAndSalariesForAllEmployees: null,
      SumInsuredByAnyOneConsignment: null,
      PublicLiability: 0,
      DoYouHaveAWrittenQualityProceduresManual: 0,
      ProductLiability: 0,
      EmployersLiablity: _data.EmployersLiablity || 0,
      EmployersSocialSecurityNo: null,
      EmployersLiabilityList: null,
      PersonalAccident: 0,
      NoOfPersonsForPersonalAccidentCover: null,
      NoOfUnitsForEachEmployee: null,
      PersonalAccidentPersons: null,
      GlassSumInsured: null,
      SignSumInsured: null,
      PanesOfGlassWhereLengthAndWidthExceed5mOrTotalAreaExceeds10cm: null,
      HomeBusinessSecure: _data.risk_addresses[currentProperty]
        ?.HomeBusinessSecure || { Oid: isPremium ? 2 : 5 },
      TotalCoverAreaOfGlassInSquareCm: null,
      SpecifiedItemsSumInsured: null,
      UnspecifiedItemsSumInsured: null,
      ProfessionalIdemnity: 0,
      NoOfEmployeesThatYouWishToInsureForProfessionalIdemity: null,
      CountryYourBusinessIsDomicited: null,
      TotalTurnoverOfTheLastYearIncludingFeeIncome: null,
      HaveYouBeenChargedWithACriminalOffence: 0,
      TotalResponse: _data?.TotalResponse?.value || 4,
      AutomaticRenewal: _data?.AutomaticRenewal || false,
      EarthquakeExcess:
        _data.risk_addresses[currentProperty]?.EarthquakeExcess || 1148,
    };
    return payload;
  };

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createPropertyPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(coverData, selected, currentProperty)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['propertyData', selected, coverData],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    if (coverData !== storage?.data && policyData[selected]) {
      setCachedData({ ...cachedData, [selected]: { ...coverData } });
      refetch();
    }
  }, [coverData]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (cachedData[selected]) {
      setCoverData(cachedData[selected]);
    } else {
      const _data = { ...storage?.data };
      _data['BuildingSumInsured'] =
        _data.risk_addresses[currentProperty]?.parcel?.main_building?.surface *
        1200;
      setCoverData(_data);
    }
  }, [selected]);

  useEffect(() => {
    if (storage.data?.risk_addresses[currentProperty].home_insurance_pack) {
      setSelected(
        storage.data?.risk_addresses[currentProperty].home_insurance_pack?.value
      );
    }
  }, []);

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      FirstHomeDiscount: 10,
      HouseProtectiveDevicesDiscount: 10,
      NewMoveDiscount: 10,
      PGThirtyThreeDiscount: 33,
      FiftyContentsDiscount: 50,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  const handleNextRoute = async () => {
    const payload = getPayload(coverData, selected, currentProperty);
    payload.SavePolicy = 'true';
    const resp = await handleApiCall(payload);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        home_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['risk_addresses', currentProperty],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Home > +currentProperty + 1) {
        let nextProperty = 1 + +currentProperty;
        renderNextRoute(1, { property: nextProperty });
      } else if (storage?.data?.insurances?.Car > 0) {
        renderNextRoute(2, { vehicle: 0 });
      } else if (storage?.data?.insurances?.Health > 0) {
        renderNextRoute(3, { health: 0 });
      } else {
        renderNextRoute(3);
      }
    }
  };
  useEffect(() => {
    if (storage?.data?.insurance_pack) {
      setSelected(storage?.data?.insurance_pack?.value);
    }
  }, []);
  const premium_summary_items = [
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  const insurance_packages = [
    { value: 'Value', label: 'Value' },
    { value: 'Premium', label: 'Premium' },
  ];

  function receiveChanges(val) {
    const _coverData = { ...coverData };
    if (!val?.toggle && !val?.amountOptionsNoToggle) {
      delete _coverData[val?.key];
      return setCoverData(_coverData);
    }
    if (val?.toggleOnValue) {
      _coverData[val?.key] = val?.toggleOnValue || '';
      setCoverData(_coverData);
    } else if (val?.toggleOnAmount) {
      _coverData[val?.key] = val?.toggleOnAmount || '';
      setCoverData(_coverData);
    } else if (val.amount) {
      _coverData[val?.key] = val?.amount || '';
      setCoverData(_coverData);
    } else {
      _coverData[val?.key] = val?.amount || '';
    }
  }

  function handleCoverDetailClick(detail) {
    setShowModal(true);
    setModalContent(detail);
  }

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData]);

  return (
    <Container>
      {!showModal ? (
        <>
          {isFetching && policyData[selected]?.Data?.TotalwithFees && (
            <UpdatingContainer>
              <SmallLoader color="" />
            </UpdatingContainer>
          )}
          <TopContainer>
            <SearchSelectInput
              initial={insurance_packages[1]}
              value={
                selected != null
                  ? { label: selected, value: selected }
                  : insurance_packages[1]
              }
              options={insurance_packages}
              onSelected={(val) => setSelected(val.value)}
            />
            <TotalPremium>
              <p>{i18n.t('Your total premium')}</p>
              {policyData[selected]?.Data?.TotalwithFees ? (
                <span>{policyData[selected]?.Data?.TotalwithFees} &euro;</span>
              ) : (
                isFetching && <SmallLoader color="" />
              )}
            </TotalPremium>
          </TopContainer>
          <BottomContainer>
            <div>
              <Title>{i18n.t('Premium summary')}</Title>
              <PremiumItems>
                {premium_summary_items.map((item, i) => {
                  return (
                    <div key={i}>
                      <span>{item.value}</span>
                      <p>{item.amount}</p>
                    </div>
                  );
                })}
              </PremiumItems>
            </div>
            <EnabledDiscountsAccordion
              open={open}
              onClick={() => setOpen(!open)}
            >
              <span>
                <span>{i18n.t('Enabled discounts')}</span>

                <div open={open}>
                  <DiscountCount>
                    <div>
                      <PieProgressIndicator
                        percentage={
                          discounts.length === 0
                            ? 0
                            : (discounts?.filter(
                                (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                              )?.length /
                                discounts?.length) *
                              100
                        }
                      />
                    </div>
                    <p>
                      {
                        discounts?.filter(
                          (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                        )?.length
                      }
                      /{discounts?.length}
                    </p>
                  </DiscountCount>
                  <StyledDropDownIcon open={open}>
                    <IconActionDropDown color="white" />
                  </StyledDropDownIcon>
                </div>
              </span>
              <div className="accordion-content">
                <AccordionInfo>
                  {discounts?.map((discount, i) => {
                    if (discount[1] !== '0,00' && discount[0] !== 'Discount') {
                      return (
                        <div className="discount-row" key={i}>
                          <IconCertificateFilled color="#8990A3" />
                          <p className="discount-label">
                            {i18n.t(discount[0])}
                          </p>
                          <p className="discount-price">{discount[1]} &euro;</p>
                        </div>
                      );
                    } else return '';
                  })}
                  <Header>Possible discounts</Header>
                  {discounts?.map((discount, i) => {
                    if (discount[1] === '0,00' && discount[0] !== 'Discount') {
                      return (
                        <div className="discount-row" key={i}>
                          <IconCertificateFilled color="#8990A3" />
                          <p className="discount-label">
                            {i18n.t(discount[0])}
                          </p>
                          <p className="discount-price">
                            -{getDiscountPrice(discount[0])} &euro;
                          </p>
                        </div>
                      );
                    } else return '';
                  })}
                </AccordionInfo>
              </div>
            </EnabledDiscountsAccordion>
            <CoverDetailAccordion open={openDiscounts}>
              <span onClick={() => setOpenDiscounts(!openDiscounts)}>
                <span>{i18n.t('Cover detail')}</span>
                <div open={openDiscounts}>
                  {openDiscounts ? i18n.t('See less') : i18n.t('See more')}
                  <StyledDropDownIcon open={openDiscounts}>
                    <IconActionDropDown color="white" />
                  </StyledDropDownIcon>
                </div>
              </span>
              {data?.Error && (
                <ErrorBoxLine>
                  <strong>{t('Error! ')}</strong>
                  {data?.Description}
                </ErrorBoxLine>
              )}
              <StyledAlert icon>
                {i18n.t(
                  'Please complete the missing information highlighted by the exclamation mark'
                )}
              </StyledAlert>
              <div className="accordion-content">
                {selected === 'Value'
                  ? getCoverDetailsHome(
                      'Value',
                      cachedData,
                      storage?.data.risk_addresses[currentProperty]
                    ).map((details, i) => {
                      return (
                        <div key={i}>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            prices={data?.Data}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <div>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </div>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : selected === 'Premium'
                  ? getCoverDetailsHome(
                      'Premium',
                      cachedData,
                      storage?.data.risk_addresses[currentProperty]
                    ).map((details, i) => {
                      return (
                        <div key={i}>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            prices={data?.Data}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <div>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </div>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : null}
              </div>
            </CoverDetailAccordion>
          </BottomContainer>
          <ContinueContainer>
            <ContinueActionButton onClick={() => handleNextRoute()}>
              {i18n.t('Continue')}
            </ContinueActionButton>
          </ContinueContainer>
        </>
      ) : (
        <>
          {modalContent && (
            <MobileCoverDetail
              details={modalContent}
              data={data?.Data}
              sendChanges={receiveChanges}
              isLoading={isLoading}
              selected={selected}
              policyData={policyData}
              setShowModal={setShowModal}
            />
          )}
          <ContinueContainer>
            <PremiumContainer>
              <p>Premium</p>
              <p>
                {isLoading ? (
                  <SmallLoader color="" />
                ) : (
                  <span>
                    {policyData[selected]?.Data?.TotalwithFees} &euro;
                  </span>
                )}
              </p>
            </PremiumContainer>
            <ContinueActionButton onClick={() => setShowModal(false)}>
              {i18n.t('Back')}
            </ContinueActionButton>
          </ContinueContainer>
        </>
      )}
    </Container>
  );
};

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0.8rem;
  & > p {
    margin-right: 0.8rem;
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin: 2rem 0 1.5rem 1rem;
`;

const AccordionInfo = styled.div`
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2rem;
  }

  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }
    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const StyledAlert = styled(Alert)`
  align-self: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  > div {
    color: ${({ theme }) => theme.brand.primary};
    border-color: ${({ theme }) => theme.brand.primary};
  }
  margin-bottom: 2.4rem;
`;

const EnabledDiscountsAccordion = styled.div`
  background-color: #f5f6f7;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow-y: auto;

  font-family: ${({ theme }) => theme.font};
  position: static;
  bottom: 0;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 18rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1.6rem;
  > .accordion-content {
    display: ${({ open }) => (!open ? 'none' : 'flex')};
    flex-direction: column;
    overflow-y: auto;
  }
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem;
    > span {
      font-size: 1.6rem;
      font-weight: 550;
    }
    > div {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
    }
  }
`;

const StyledDropDownIcon = styled.div`
  transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
  transition: transform 0.3s;
  background-color: ${({ theme }) => theme.brand.primary};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-left: 1.6rem;
  & > svg {
    display: block;
    width: 90%;
  }
`;

const StyledIconActionClose = styled(IconActionClose)`
  path {
    fill: red !important;
  }
`;

const CoverDetailAccordion = styled(EnabledDiscountsAccordion)`
  position: static;
  background-color: transparent;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 12rem)')};
`;

const TotalPremium = styled.div`
  align-self: center;
  flex-direction: column;
  height: fit-content;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
  @media (max-width: 312px) {
    p {
      font-size: 1.4rem;
    }
    span {
      font-size: 1.6rem;
    }
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  div:last-child {
    border-top: 1px solid #e5e5e5;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    > p {
      font-weight: 550;
    }
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 30px;
`;

const CoverDetailsContent = styled.div`
  cursor: ${({ click }) => (click ? 'pointer' : 'unset')};
  background-color: white;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 0.34rem rgba(0, 0, 0, 0.08);
  border: 0.1rem solid #8990a1;
  border-radius: 0.5rem;
  padding: 1.2rem 0;
  margin-bottom: 0.8rem;

  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      align-self: center;
      color: rgba(48, 55, 61, 0.66);
      font-size: 1.6rem;
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      align-items: center;
    }
    > span {
      width: 5rem;
      > div {
        padding: 0;
      }
    }
  }
  input[type='checkbox'] {
    height: 25px;
    margin: 0;
    width: 40px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  grid-template-columns: 46% 46%;
  grid-gap: 2rem;
  height: 8rem;
  margin: 2rem;
  margin-top: 0;
  padding-bottom: 2rem;
  div:first-child {
    min-height: unset;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const BottomContainer = styled.div`
  height: auto;
  > div:first-child {
    padding: 2rem;
    padding-top: 1rem;
  }
`;

const ContinueActionButton = styled(ActionButton)`
  width: 90%;
  margin: 0 auto;
`;

const ContinueContainer = styled.div`
  padding-bottom: 2rem;
  margin-top: auto;
`;

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: flex-end;

  > div {
    width: 3rem;
    margin-right: 0.8rem;
    transform: translateY(0.7rem);
  }
  > p {
    color: black;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

const ErrorBoxLine = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 5px;

  strong {
    font-weight: bold;
  }
`;

const PremiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8990a3;
  width: 90%;
  margin: 0 auto;
  height: 3rem;
  margin-bottom: 2.4rem;
  > p:first-child {
    font-weight: 550;
  }
  > p {
    font-size: 1.6rem;
  }
`;

export default FlowHomePremiumSummaryMobile;
