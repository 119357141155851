import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';
import FlowAddAdditionalDriver from '../components/FlowAddAdditionalDriver.jsx';

const FlowCarInformation = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const { addQuestion } = useContext(ChatContext);

  useEffect(() => {
    loadDefaultValues();
    const question = {
      message: i18n.t('Are there any additional drivers'),
      slug: 'additional_drivers',
    };
    addQuestion(question);
  }, []);

  const loadDefaultValues = async () => {
    if (cars[currentVehicle] != null) {
      setDefaultValues(cars[currentVehicle]);
    }
  };

  function handleSubmit(payload) {
    patchStorage({
      payload: {
        extra_drivers: payload,
      },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });
  }

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      {!isMobile() && <FlowChatBox />}
      <RightContainer>
        <FlowAddAdditionalDriver
          goNextRoute={() => renderNextRoute(1, { vehicle: currentVehicle })}
          storedValues={data?.cars?.[currentVehicle]?.extra_drivers}
          handleFormValues={handleSubmit}
          defaultValues={
            defaultValues.health ? defaultValues.health : defaultValues
          }
        />
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.div`
  padding-left: 4rem;
  padding-right: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowCarInformation;
