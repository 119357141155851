import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton, LoadingSpinner } from 'wg-fe-ui';
import CheckBox from './CheckBox';
import FlowOverviewDiscountsSection from './FlowOverviewDiscountsSection';
import FlowOverviewPremiumSection from './FlowOverviewPremiumSection';
import SearchSelectInputV2 from './SearchSelectInputV2';
import styled, { css } from 'styled-components';
import FlowOverviewPolicyDetailHeader from './FlowOverviewPolicyDetailHeader';

interface Props {
  policy: any;
  initialOpen: boolean;
  id: number;
  type: string;
  inCart?: boolean;
  updatePayload: ({
    type,
    key,
    payloadKey,
    value,
  }: {
    type: string;
    payloadKey: string;
    key: number;
    value: boolean;
  }) => void;
  removePolicy: (policyKey: number) => void;
}

const FlowOverviewPolicyDetail: React.FC<Props> = ({
  policy,
  removePolicy,
  initialOpen = false,
  inCart = true,
  id,
  type,
  updatePayload,
}) => {
  const [open, setOpen] = useState<boolean>(initialOpen);
  const [areDiscountsOpen, setAreDiscountsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!inCart) {
      setOpen(false);
    }
  }, [inCart]);

  const togglePolicy = () => {
    removePolicy(1);
  };

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ProductItem inCart={inCart}>
      {!policy.finished && (
        <Loading>
          <LoadingSpinner />
        </Loading>
      )}
      <FlowOverviewPolicyDetailHeader
        policy={policy}
        toggleOpen={toggleOpen}
        type={type}
        open={open}
      />
      <Line />
      <Content
        open={open}
        onClick={(e) => e.stopPropagation()}
        calculatedHeight={400}
      >
        {policy?.loyaltyPointOptions?.length > 1 && (
          <SearchSelectInputV2
            name="LoyaltyPoints"
            options={policy.loyaltyPointOptions}
            value={policy?.loyaltyPointOptions?.find(
              (item: any) => item.value === policy?.loyaltyPoints
            )}
            onSelected={(e: any) =>
              updatePayload({
                type,
                key: id,
                payloadKey: 'RedeemLoyaltyPoints',
                value: e.value,
              })
            }
          >
            {t('How many loyalty points would you like to use?')}
          </SearchSelectInputV2>
        )}
        {!areDiscountsOpen && (
          <FlowOverviewPremiumSection policy={policy} type={type} />
        )}
        <FlowOverviewDiscountsSection
          setAreDiscountsOpen={setAreDiscountsOpen}
          areDiscountsOpen={areDiscountsOpen}
          policy={policy}
        />
      </Content>
      <InputContainer>
        <StyledCheckBox
          name={`AutomaticRenewal_${type}_${id}`}
          disabled={!inCart || !policy.finished}
          checked={policy?.payload?.AutomaticRenewal}
          onChange={() =>
            updatePayload({
              type,
              key: id,
              payloadKey: 'AutomaticRenewal',
              value: !policy?.payload?.AutomaticRenewal,
            })
          }
        >
          {t('Automatic renewal')}
        </StyledCheckBox>
        <ActionButton
          disabled={!policy.finished}
          secondary={!inCart}
          onClick={togglePolicy}
        >
          {inCart ? t('Remove') : t('Add to basket')}
        </ActionButton>
      </InputContainer>
    </ProductItem>
  );
};

export default FlowOverviewPolicyDetail;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

const Line = styled.div`
  height: 1px;
  background-color: #f0f1f3;
`;

const Content = styled.div<{ open?: boolean; calculatedHeight: number }>`
  border-radius: 5px;
  background-color: #f0f1f3;
  overflow: hidden;
  margin: 20px;
  box-sizing: content-box;
  cursor: default;
  margin-bottom: ${({ open }) => (open ? `20px` : '0')};
  padding: ${({ open }) => (open ? `20px` : '0')} 24px;
  max-height: ${({ open }) => (open ? `unset` : '0')};
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

const StyledCheckBox = styled(CheckBox)`
  flex: 0;
  white-space: nowrap;
`;

const ProductItem = styled.article<{ inCart?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2rem;
  overflow: hidden;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 1px solid white;
  transition: all 0.2s ease;

  ${({ inCart }) =>
    !inCart &&
    css`
      background: #f5f5f5;
    `}

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1),
      0px 0px 1px rgba(0, 0, 0, 0.1);
  }
`;
