import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ActionButton,
  TextInput,
  IconActionChevronLeft,
  ToggleInput,
  DateInputV4 as DateInputComponent,
} from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import { construction_year, string } from '../constants/validationSchemas';
import { isFuture, differenceInYears, parseISO, format } from 'date-fns';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeInformationExtra = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setError] = useState();
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const history = useHistory();

  function handleRenovationYearValidation5Years() {
    const { renovation_year } = this.parent;
    return differenceInYears(new Date(), parseISO(renovation_year)) <= 5;
  }

  function handleRenovationYearValidationFuture() {
    const { renovation_year } = this.parent;
    return !isFuture(parseISO(renovation_year));
  }

  const validationSchema = Yup.object().shape({
    construction_year: construction_year,
    hasRenovated: Yup.boolean(),
    renovation_year: Yup.mixed().when('hasRenovated', {
      is: true,
      then: string.required
        .test(
          'valid-renovation_year-5years',
          i18n.t('The renovation date can not be more than 5 years ago'),
          handleRenovationYearValidation5Years
        )
        .test(
          'valid-renovation_year-future',
          i18n.t('The renovation date can not be in the future'),
          handleRenovationYearValidationFuture
        ),
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  console.log(values.hasRenovated);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Ok! In <span>what year</span> was the building constructed?'
      ),
      slug: 'construction_year',
    };
    addQuestion(question);

    loadDefaultValues();
  }, []);

  useEffect(() => {
    if (!values?.hasRenovated) {
      handleChange({ name: 'renovation_year', value: undefined });
    }
  }, [values?.hasRenovated]);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      construction_year: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'construction_year',
      ]),
      renovation_year: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'renovation_year',
      ]),
      hasRenovated: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'hasRenovated',
      ]),
      DoesYourPropertyHaveAntisesmicSurvey: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'DoesYourPropertyHaveAntisesmicSurvey',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.construction_year != null) {
      handleChange({
        name: 'construction_year',
        value: defaultValues.construction_year,
      });
    }
    if (defaultValues.renovation_year != null) {
      handleChange({
        name: 'renovation_year',
        value: defaultValues.renovation_year,
      });
    }
    if (defaultValues.hasRenovated != null) {
      handleChange({
        name: 'hasRenovated',
        value: defaultValues.hasRenovated,
      });
    }
    if (defaultValues.DoesYourPropertyHaveAntisesmicSurvey != null) {
      handleChange({
        name: 'DoesYourPropertyHaveAntisesmicSurvey',
        value: defaultValues.DoesYourPropertyHaveAntisesmicSurvey ? 1 : 0,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = () => {
    if (values.construction_year.length < 4) {
      setError('Please enter a valid year');
    }

    setLoading(true);

    patchStorage({
      payload: {
        construction_year: values.construction_year,
        DoesYourPropertyHaveAntisesmicSurvey:
          values.construction_year <= 1985
            ? values?.DoesYourPropertyHaveAntisesmicSurvey
              ? 1
              : 0
            : 1,
        hasRenovated: values.hasRenovated,
        renovation_year: values.hasRenovated
          ? values.renovation_year
            ? values.renovation_year
            : null
          : null,
      },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });

    if (values?.hasRenovated) {
      const answer = {
        message: i18n.t(
          `The building was constructed <span>in ${
            values?.construction_year
          }</span> and renovated <span>in ${format(
            parseISO(values?.renovation_year, 'ddMM/yyyy'),
            'dd/MM/yyyy'
          )}</span>`
        ),
        slug: 'construction_year',
      };
      addAnswer(answer);
    } else {
      const answer = {
        message: i18n.t(
          `The building was constructed <span>in ${Number(
            values?.construction_year
          )}</span>`
        ),
        slug: 'construction_year',
      };
      addAnswer(answer);
    }

    renderNextRoute(1, { property: currentProperty });

    setLoading(false);
  };

  function handleEnter(e) {
    if (e?.key === 'Enter') {
      submitHandler();
    }
  }

  function goBack() {
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <QuestionContainer>
              <TitleForm>
                {i18n.t('In what year is the building constructed?')}
              </TitleForm>
              <StyledTextInput
                name="construction_year"
                type="number"
                min="0"
                error={errors.construction_year}
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.construction_year}
                onKeyUp={handleEnter}
              >
                <p className="label">{i18n.t('Construction year')} *</p>
              </StyledTextInput>
              {parseInt(values?.construction_year) <= 1985 && (
                <>
                  <TitleForm>
                    {i18n.t('Does your property have antiseismic survey?')}
                  </TitleForm>
                  <ToggleInput
                    checked={values?.DoesYourPropertyHaveAntisesmicSurvey}
                    falseLabel={i18n.t('No')}
                    trueLabel={i18n.t('Yes')}
                    onChange={() =>
                      handleChange({
                        name: 'DoesYourPropertyHaveAntisesmicSurvey',
                        value: !values?.DoesYourPropertyHaveAntisesmicSurvey,
                      })
                    }
                  />
                </>
              )}
            </QuestionContainer>
            <QuestionContainer>
              <TitleForm>
                {i18n.t('Did you renovate your building in the last 5 years?')}
              </TitleForm>
              <ToggleInput
                checked={values.hasRenovated}
                falseLabel={i18n.t('No')}
                trueLabel={i18n.t('Yes')}
                onChange={() =>
                  handleChange({
                    name: 'hasRenovated',
                    value: !values.hasRenovated,
                  })
                }
              />
              {values?.hasRenovated && (
                <DateInputComponent
                  name="renovation_year"
                  type="number"
                  min="0"
                  iso
                  error={errors.renovation_year}
                  onChange={(val) => {
                    handleChange(val);
                  }}
                  value={values.renovation_year}
                  onKeyUp={handleEnter}
                >
                  <p className="label">{i18n.t('Date of last renovation')} *</p>
                </DateInputComponent>
              )}
            </QuestionContainer>
          </div>
          <ButtonContainer>
            <Required>* {i18n.t('Required fields')}</Required>
            <div>
              <BackButton type="button" name="back" onClick={() => goBack()}>
                <IconActionChevronLeft />
                {i18n.t('Back')}
              </BackButton>
              {loading ? (
                <LoadingActionButton
                  type="submit"
                  value="Confirm"
                  data-test-id="risk_address_flow_loading"
                >
                  {i18n.t('Loading')}
                </LoadingActionButton>
              ) : (
                <ActionButton
                  type="submit"
                  value="Confirm"
                  data-test-id="risk_address_flow_submit"
                >
                  {i18n.t('Next')}
                </ActionButton>
              )}
            </div>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const QuestionContainer = styled.div`
  margin-bottom: 3rem;
  > div {
    padding-top: 0;
  }
`;

const BackButton = styled.div`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;
  font-weight: 550;
  height: 5rem;
  min-width: 12rem;
  width: 12rem;
  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  width: 50%;

  p.label {
    color: #8990a3;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
  }
  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

export default FlowHomeInformationExtra;
