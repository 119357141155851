import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import CarEcoGan from '../assets/icons/CarEcoGan';
import CarPremGanIcon from '../assets/icons/CarPremGanIcon';
import CarBasicGanIcon from '../assets/icons/CarBasicGanIcon';
import CarValueGanIcon from '../assets/icons/CarValueGanIcon';
import {
  ActionButton,
  SmallLoader,
  IconStatusCheck,
  IconActionDropDown,
} from 'wg-fe-ui';
import { useParams } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { createCarPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import { handlePolicyStartDate } from '../helpers/dateService';

const getPayload = (_data, selected, currentVehicle) => {
  let payload = {
    SavePolicy: _data?.SavePolicy || 'False',
    Customer: {
      Oid: _data.ganCustomer.Oid,
    },
    VehicleOrVessel: 0,
    Vehicle: {
      Oid: _data.cars?.[currentVehicle].vehicle_info.Oid,
    },
    MotorStatus: 1,
    ExistingInsuranceCompany: {
      InsuranceCompany: _data?.cars?.[currentVehicle]?.insurance_company_name,
    },
    ExpiringDateOfYourCurrentPolicy: _data?.cars?.[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars?.[currentVehicle]?.start_of_contract || null
      : null,
    ExistingInsuranceCompanyPrice: _data?.cars?.[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars?.[currentVehicle]?.insurance_price || null
      : null,
    PolicyHolderAsDriver: _data.cars?.[currentVehicle].main_driver
      ?.same_as_policy
      ? 1
      : 0,
    MotorDrivers: [
      {
        Oid: _data.cars[currentVehicle].main_driver?.same_as_policy
          ? _data?.ganCustomer?.Oid
          : _data?.cars?.[currentVehicle]?.main_driver?.Oid,
      },
      ..._data?.cars?.[currentVehicle]?.extra_drivers?.map((item) => ({
        Oid: item?.Oid,
      })),
    ],
    EuroCompPackageBenefit: _data?.EuroCompPackageBenefit?.value || '',
    LossOfUseBenefit: _data?.LossOfUseBenefit?.value || '',
    GuranteeAssetProtectionBenefit:
      _data?.GuranteeAssetProtectionBenefit?.value || '',
    ClaimProtectionBenefit: _data?.ClaimProtectionBenefit || '',
    RiotsStrikesBenefit: _data?.RiotsStrikesBenefit?.value || '',
    ConvulsionOfNatureBenefit: _data?.ConvulsionOfNatureBenefit?.value || '',
    PersonalEffectsBenefit: _data?.PersonalEffectsBenefit || '',
    MedicalExpensesBenefit: _data?.MedicalExpensesBenefit?.value || '',
    StayMobile: _data.cars[currentVehicle]?.replacement_vehicle ? 1 : 0,
    DrivingOtherCarsBenefit: _data?.DrivingOtherCarsBenefit || '',
    UnlimitedWindscreenBenefit: _data?.UnlimitedWindscreenBenefit || '',
    ExcessProtectionBenefit: _data?.ExcessProtectionBenefit?.value || '',
    Trailer: _data?.Trailer?.value || 0,
    TrailerChassisNumber: '',
    Relay: _data?.Relay || 0,
    PolicyStartDate: handlePolicyStartDate(
      _data.cars[currentVehicle]?.policy_details?.starting_date
    ),
    PolicyEndDate: new Date(
      _data.cars[currentVehicle]?.policy_details?.ending_date
    ).toISOString(),
    PolicyDuration:
      _data.cars[currentVehicle]?.policy_details?.insurance_duration,
    VehicleCoverType: 2,
    VehicleExcess: 250,
    InsuredAmount: _data.cars[currentVehicle]?.vehicle_info?.invoice_value,
  };

  switch (selected) {
    case 'Basic Plus':
      payload.VehicleCoverType = 0;
      break;
    case 'Economic':
      payload.VehicleCoverType = 1;
      break;
    case 'Value':
      payload.VehicleCoverType = 2;
      break;
    case 'Premium':
      payload.VehicleCoverType = 2;
      payload.EuroCompPackageBenefit = 1;
      break;
    default:
      payload.VehicleCoverType = 9;
      break;
  }
  return payload;
};

const BasicPlusDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  // { value: 'Unlimited Windscreen' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  // { value: 'Trailer' },
  { value: 'Relay' },
  // { value: 'Stay mobile' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: '24hr Glass replacement' },
];

const EconomicPackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Unlimited Windscreen' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Loss of use' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: '24hr Glass replacement' },
];

const ValuePackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Unlimited Windscreen' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Guarantee Asset Protection' },
  { value: 'Personal Effects' },
  { value: 'Excess Protection' },
  { value: 'Convulsion of nature' },
  { value: 'Riots & Strikes' },
  { value: 'Loss of use' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: 'Hire purchase, Loan or long term rental agreement' },
  { value: 'Own damage for the Insured Vehicle' },
  { value: '24hr Glass replacement' },
];

const PremiumPackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Guarantee Asset Protection' },
  { value: 'Excess Protection' },
  { value: 'Convulsion of nature' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Hire purchase, Loan or long term rental agreement' },
  { value: 'Own damage for the Insured Vehicle' },
  { value: '24hr Glass replacement' },
  { value: 'Replacement of Motor Vehicle with New' },
  { value: 'Claim Protection' },
  { value: 'Natural hazards' },
  { value: 'Riots & Strikes' },
  { value: 'Personal Effects' },
  { value: 'Loss of use' },
  { value: 'Driving other cars' },
  { value: 'Unlimited Windscreen' },
];

const packages = [
  {
    value: 'Basic Plus',
    description:
      'Basic Plus Insurance Policy covers you against all sums including claimant’s costs and expenses which the Insured or any Authorized Driver shall become legally liable to pay in respect of death or bodily injury to any person, emergency treatment to any person or damage to property that may arise out of an accident caused by the use of the Insured Vehicle',
    benefits: BasicPlusDetails,
  },
  {
    value: 'Economic',
    description:
      'Economic Insurance Policy covers you against all sums including claimant’s costs and expenses which the Insured or any Authorized Driver shall become legally liable to pay in respect of death or bodily injury to any person, emergency treatment to any person or damage to property that may arise out of an accident caused by the use of the Insured Vehicle. It also covers you in case of fire, external explosion, self-ignition, lightning or burglary, theft or attempted theft to the Motor vehicle and its accessories and spare parts whilst thereon',
    benefits: EconomicPackageDetails,
  },
  {
    value: 'Value',
    description:
      'Value Insurance Policy covers you against the damages you may cause to a third party, against Fire & Theft and also against accidental damage to your own Motor Vehicle. It completely covers your own damage and the third party’s damage',
    benefits: ValuePackageDetails,
  },
  {
    value: 'Premium',
    description:
      'Premium Insurance Policy is a special deal package that consists of all the covers included in Value Policy plus many more additional benefits which are otherwise offered at an extra cost',
    benefits: PremiumPackageDetails,
  },
];

const FlowCarInsuranceOptionsMobile = () => {
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [open, setOpen] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createCarPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentVehicle)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, failureCount } = useQuery(
    ['policyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  useEffect(() => {
    const packageIndex = packages.findIndex(
      (pack) =>
        pack.value === storage?.data?.cars[currentVehicle]?.insurance_pack
    );
    if (packageIndex > -1) setSelected(packages[packageIndex]);
  }, []);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  function iconType(icon) {
    switch (icon) {
      case 'Basic Plus': //terraced
        return <CarBasicGanIcon />;
      case 'Economic': //semi-detached
        return <CarEcoGan />;
      case 'Value':
        return <CarValueGanIcon />;
      case 'Premium':
        return <CarPremGanIcon />;
      default:
        return <CarValueGanIcon />;
    }
  }

  const submitHandler = () => {
    patchStorage({
      payload: {
        insurance_pack: selected?.value,
        insurance_info: policyData[selected?.value]?.Data,
      },
      path: ['cars', currentVehicle],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  return (
    <Container>
      <LeftContainer>
        <TitleForm>{i18n.t('Choose your package')}</TitleForm>
        <TotalPremium>
          <p>{i18n.t('Your total premium')}</p>
          {isLoading ? (
            <UpdatingContainer>
              <SmallLoader color="" />
            </UpdatingContainer>
          ) : (
            <span>
              <strong>
                {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
              </strong>{' '}
            </span>
          )}
        </TotalPremium>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected?.value === insurance.value}
                onClick={() => setSelected(insurance)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
      </LeftContainer>
      <RightContainer>
        {selected != null && (
          <MenuWrapper open={open} onClick={() => setOpen(!open)}>
            <span>
              <span>{i18n.t('Specifics')}</span>
              <p open={open}>
                {open ? i18n.t('See less') : i18n.t('See more')}
                <IconActionDropDown open={open} />
              </p>
            </span>
            <EconomicPackageDetailsContainer open={open}>
              {selected?.benefits?.map((detail) => {
                return (
                  <DetailContainer>
                    <IconStatusCheck />
                    <p>{detail.value}</p>
                  </DetailContainer>
                );
              })}
              <div>{selected?.description}</div>
            </EconomicPackageDetailsContainer>
          </MenuWrapper>
        )}
        <ButtonContainer>
          <ActionButton
            onClick={() => submitHandler()}
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Specify')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const MenuWrapper = styled.div`
  box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  overflow: auto;

  font-family: ${({ theme }) => theme.font};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 11rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 7rem;
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.7rem 2rem 0 2rem;
    > span {
      color: ${({ theme }) => theme.brand.primary};
      font-weight: 900;
      font-size: 2rem;
    }
    p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
      > svg {
        margin-left: 1rem;
        transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
        transition: transform 700ms cubic-bezier(1, 0, 0, 1);
        > path {
          stroke: ${({ theme }) => theme.brand.primary};
        }
      }
    }
  }
`;

const TotalPremium = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1.6rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
  }
  span {
    color: #0e0e0e;
    display: flex;
    font-size: 2.1rem;
    font-weight: 550;
    strong {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  height: 100%;
  align-items: flex-end;
  width: 100%;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: ${({ open }) => (!open ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: scroll;
  div:last-child {
    font-size: 1.25rem;
    color: rgba(48, 55, 61, 0.66);
    padding: 2rem;
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  p {
    align-self: center;
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.brand.primary}` : '2px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-evenly;
  width: 100%;

  > p {
    font-size: 1.2rem;
    text-align: center;
  }
  span > svg {
    height: 4rem;
    width: 4rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  justify-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: scroll;
  flex-direction: column;
  padding: 2rem;
`;

const RightContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin: 1rem 0;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 35% 65%;
  width: 100%;
`;

const ErrorBox = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-items: center;
`;

const UpdatingContainer = styled.div`
  height: 2rem;
`;

export default FlowCarInsuranceOptionsMobile;
