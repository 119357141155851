import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import { TextInput, DateInputV4 as DateInput } from 'wg-fe-ui';
import '../assets/css/cogoToast.css';
import { object, func } from 'prop-types';
import SearchSelectInputV2 from './SearchSelectInputV2';
import {
  string,
  email,
  firstName,
  lastName,
  telephonenr,
  id_number,
} from '../constants/validationSchemas';
import { differenceInYears, isBefore, parseISO } from 'date-fns';

import {
  primaryOptions as primaryOptionsConstant,
  titleOptions as titleOptionsConstant,
  yesNoOptions as yesNoOptionsConstant,
} from '../constants/FlowSearchSelectData';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import FlowPolicyHolderInfoFormDefaultInputs from './FlowPolicyHolderInfoFormDefaultInputs';
import { useParams } from 'react-router';
import { countries, occupations } from '../constants/customerData';

const occupations_lowerCase = occupations.map((o) => {
  o.label =
    i18n.language?.toLowerCase() === 'el'
      ? o.labelGreek?.toLowerCase()
      : o.label?.toLowerCase();
  return o;
});

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const FlowPolicyHolderInfoFormPrivate = ({
  sendSubmit,
  defaultValues,
  loading,
}) => {
  const { insuranceType } = useParams();
  const [, setPrimaryOptions] = useState([]);
  const [, setYesNoOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [, setBillingAddressOptions] = useState([]);
  const [, setBirthValue] = useState(false);
  const validationSchema = Yup.object().shape({
    email: email,
    firstName: firstName,
    lastName: lastName,
    idNo: id_number,
    birth: string.required.test(
      'valid-birth',
      i18n.t('The policy holder must be at least 17 years of age'),
      handleBirthValidation
    ),
    primary: insuranceType === 'car' ? string.required : string.notRequired,
    title: string.required,
    occupation: string.required,
    country: string.required,
    password: string.required,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], i18n.t('Passwords must match'))
      .required(i18n.t(`required`)),
    resident_in_cyprus_since: Yup.mixed().when('country', {
      is: '6e0e5110-ebbf-4a71-abfc-491a2778e24f',
      then: undefined,
      otherwise: string.required.test(
        'valid-residence',
        i18n.t('Cannot be before birth date'),
        handleResidentInCyprusValidation
      ),
    }),
    telephonenr: telephonenr.required.test(
      'Phone number',
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
  });

  function handleBirthValidation() {
    const { birth } = this.parent;
    return (
      17 <= differenceInYears(new Date(), parseISO(birth)) &&
      differenceInYears(new Date(), parseISO(birth)) <= 100
    );
  }
  function handleResidentInCyprusValidation() {
    const { birth, resident_in_cyprus_since } = this.parent;
    return !isBefore(parseISO(resident_in_cyprus_since), parseISO(birth));
  }
  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return undefined;
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : undefined });
  }

  const SelectPlaceholder = i18n.t('Choose your option');

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setTitleOptions(
      titleOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setBillingAddressOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setYesNoOptions(
      yesNoOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  return (
    <form onSubmit={handleForm}>
      <Divider />
      <Title>{i18n.t('Your information')}</Title>
      <FlexWrapper>
        <TextInput
          name="firstName"
          error={errors.firstName}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues.firstName !== undefined
              ? defaultValues.firstName
              : values.firstName
          }
        >
          {i18n.t('First Name')} *
        </TextInput>
        <TextInput
          name="lastName"
          error={errors.lastName}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues.lastName !== undefined
              ? defaultValues.lastName
              : values.lastName
          }
        >
          {i18n.t('Last Name')} *
        </TextInput>
      </FlexWrapper>
      <FlexWrapper>
        <SearchSelectInputV2
          error={errors.title}
          name="title"
          onChange={(val) => {
            handleSelectChange(val, 'title');
          }}
          options={titleOptions}
          placeholder={SelectPlaceholder}
          value={handleSelectValue(titleOptions, 'title') || undefined}
        >
          {i18n.t('Title')} *
        </SearchSelectInputV2>
        <DateInput
          error={errors.birth}
          name="birth"
          iso
          onChange={(val) => {
            if (val.value !== undefined && val.value.length === 10) {
              setBirthValue(true);
            }
            handleChange(val);
          }}
          value={
            defaultValues.birth !== undefined
              ? defaultValues.birth
              : values.birth
          }
        >
          {i18n.t('Date Of Birth')} *
        </DateInput>
      </FlexWrapper>
      <FlexWrapper>
        <TextInput
          name="idNo"
          error={errors.idNo}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues.idNo !== undefined ? defaultValues.idNo : values.idNo
          }
        >
          {i18n.t('ID number')} *
        </TextInput>
        <SearchSelectInputV2
          error={errors.occupation}
          name="occupation"
          onChange={(val) => {
            handleSelectChange(val, 'occupation');
          }}
          options={occupations_lowerCase}
          placeholder={SelectPlaceholder}
          value={handleSelectValue(occupations_lowerCase, 'occupation')}
        >
          {i18n.t('Occupation')} *
        </SearchSelectInputV2>
      </FlexWrapper>
      <FlexWrapper>
        <SearchSelectInputV2
          error={errors.country}
          name="country"
          onChange={(val) => {
            handleSelectChange(val, 'country');
          }}
          options={countries}
          placeholder={SelectPlaceholder}
          value={handleSelectValue(countries, 'country')}
        >
          {i18n.t('Country of Birth')} *
        </SearchSelectInputV2>
        {values?.country !== '6e0e5110-ebbf-4a71-abfc-491a2778e24f' &&
        values?.country && ( // Oid of Cyprus
            <FlexWrapper>
              <DateInput
                error={errors.resident_in_cyprus_since}
                iso
                name="resident_in_cyprus_since"
                onChange={(val) => {
                  if (val.value !== undefined && val.value.length === 10) {
                    setBirthValue(true);
                  }
                  handleChange(val);
                }}
                value={
                  defaultValues.resident_in_cyprus_since !== undefined
                    ? defaultValues.resident_in_cyprus_since
                    : values.resident_in_cyprus_since
                }
              >
                {i18n.t('Resident in Cyprus since')} *
              </DateInput>
            </FlexWrapper>
          )}
      </FlexWrapper>
      <Divider />
      <FlowPolicyHolderInfoFormDefaultInputs
        errors={errors}
        values={values}
        loading={loading}
        defaultValues={defaultValues}
        handleChange={handleChange}
      />
    </form>
  );
};

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    width: 100%;
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;

    & > div + div {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e4e4e4;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

FlowPolicyHolderInfoFormPrivate.propTypes = {
  defaultValues: object.isRequired,
  sendSubmit: func.isRequired,
};

export default FlowPolicyHolderInfoFormPrivate;
