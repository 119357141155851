import React from 'react';

const LandlordGanIcon = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#024284" />
          <stop offset="0.81" stopColor="#004587" />
          <stop offset="0.82" stopColor="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <path
            d="M32.44,48.72h9.81a3.22,3.22,0,1,0,0-6.43H29.33c-5.19.67-10.09,2.6-11.75,9.36l4.91,7.85,6.12-4.63H46s11.42-11,14.85-14.47-1.16-7.76-4.6-4.32S44.85,47.61,44.85,47.61"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="39.53"
            cy="26.43"
            r="9.59"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <path
            d="M41.17,32.08a5.65,5.65,0,0,1,0-11.29h.05"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
          <line
            x1="34.18"
            y1="27.48"
            x2="41.5"
            y2="27.48"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
          <line
            x1="34.18"
            y1="25.54"
            x2="41.5"
            y2="25.54"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default LandlordGanIcon;