import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { func, array, string } from 'prop-types';
import BicycleGanIcon from '../assets/icons/BicycleGanIcon';
import ArtGanIcon from '../assets/icons/ArtGanIcon';
import DroneGanIcon from '../assets/icons/DroneGanIcon';
import GardenGanIcon from '../assets/icons/GardenGanIcon';
import JewelleryGanIcon from '../assets/icons/JewelleryGanIcon';
import PetsGanIcon from '../assets/icons/PetsGanIcon';
import ScooterGanIcon from '../assets/icons/ScooterGanIcon';
import MobileGanIcon from '../assets/icons/MobileGanIcon';
import NoGanIcon from '../assets/icons/NoGanIcon';

const ExtraInsuranceObject = ({ value, checked, onChange, selected }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    onChange({ name: value, value: isSelected });
  }, [isSelected]);

  useEffect(() => {
    setIsSelected(checked);
  }, [checked]);

  function handleChange() {
    setIsSelected(!isSelected);
  }

  useEffect(() => {
    if (selected?.includes(value)) {
      setIsSelected(true);
    }
  }, [selected]);

  function iconType(icon) {
    switch (icon) {
      case 'Digital devices': //terraced
        return <MobileGanIcon />;
      case 'Bicycles': //semi-detached
        return <BicycleGanIcon />;
      case 'Electric Scooter':
        return <ScooterGanIcon />;
      case 'Drone':
        return <DroneGanIcon />;
      case 'Art or other valuables':
        return <ArtGanIcon />;
      case 'Pets':
        return <PetsGanIcon />;
      case 'Jewelry':
        return <JewelleryGanIcon />;
      case 'Garden':
        return <GardenGanIcon />;
      case 'No':
        return <NoGanIcon />;
      default:
        return <GardenGanIcon />;
    }
  }

  return (
    <Extra selected={isSelected} onClick={() => handleChange()}>
      <span>{iconType(value)}</span>
      <p>{value}</p>
    </Extra>
  );
};

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `3px solid ${theme.brand.primary}` : '1px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 5px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 10rem;
  justify-content: space-evenly;
  > p {
    font-size: 1.4rem;
    text-align: center;
  }
`;

ExtraInsuranceObject.propTypes = {
  onChange: func,
  selected: array,
  value: string,
};

export default ExtraInsuranceObject;
