import React from 'react';
import Main from './pages/Main.js';
import GlobalStyle from './resetStyling';
import CircularFont from './assets/fonts/circular';
import { ThemeContextProvider } from './contexts/ThemingContext';
import { ChatContextProvider } from './contexts/ChatContext';
import { FlowContextProvider } from './contexts/FlowContext';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18next from 'i18next';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import PWAPrompt from 'react-ios-pwa-prompt';

import './i18n';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('7l7lpz/callant-affinity');
  setupLogRocketReact(LogRocket);
}

function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider reCaptchaKey="6Le9ptcZAAAAAGgE2EANAqeQfqYvq_jpnjJEpYjW">
        <ThemeContextProvider>
          <GlobalStyle />
          <CircularFont />
          <Helmet>
            <html lang={i18next.language} />
          </Helmet>
          <ChatContextProvider>
            <FlowContextProvider>
              <Main />
            </FlowContextProvider>
          </ChatContextProvider>
        </ThemeContextProvider>
      </GoogleReCaptchaProvider>
      <PWAPrompt
        timesToShow={3}
        copyClosePrompt="Close"
        copyTitle="Add Gan to Home Screen"
        copyBody="Gan has app functionality. Add it to your home screen to use it in fullscreen."
      />
    </div>
  );
}

export default Sentry.withProfiler(App);
