import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import {
  IconConsumerFilled,
  IconBikeFilled,
  IconLegalFilled,
  IconActionChevronLeft,
  IconResetFilled,
} from 'wg-fe-ui';

import PropertyGanIcon from '../assets/icons/PropertyGanIcon';
import HelGanIcon from '../assets/icons/HelGanIcon';
import UserGanIcon from '../assets/icons/UserGanIcon';
import VehicleGanIcon from '../assets/icons/VehicleGanIcon';
import LandlordGanIcon from '../assets/icons/LandlordGanIcon';
import CartGanIcon from '../assets/icons/CartGanIcon';
import FlowProgressBarStepContainer from './FlowProgressBarStepContainer';

import LogoSrc from '../assets/images/brand-logos/new-gandirect-logo.svg';
import MobileLogoSrc from '../assets/images/gan-direct-avatar.svg';
import i18n from '../i18n';

const FLowProgressBar = () => {
  const { insuranceType } = useParams();
  const mobile =
    insuranceType === 'allMobile' ||
    insuranceType === 'carMobile' ||
    insuranceType === 'homeMobile' ||
    insuranceType === 'healthMobile';
  const history = useHistory();
  const firstIcon =
    insuranceType === 'two-wheeler' ? (
      <IconBikeFilled key={1} color="white" />
    ) : insuranceType === 'health' ? (
      <IconConsumerFilled key={1} color="white" />
    ) : insuranceType === 'legal' ? (
      <IconLegalFilled key={1} color="white" />
    ) : (
      <VehicleGanIcon key={1} size={50} />
    );
  const icons = [
    <UserGanIcon key={0} size={50} />,
    firstIcon,
    <LandlordGanIcon key={2} size={50} />,
    <CartGanIcon key={3} size={50} />,
  ];

  const allIcons = [
    <UserGanIcon key={0} size={50} />,
    <PropertyGanIcon key={1} size={50} />,
    <VehicleGanIcon key={2} size={50} />,
    <HelGanIcon key={3} size={50} />,
    <CartGanIcon key={4} size={50} />,
  ];

  const beginningPath = `/gan-direct/${insuranceType}/verify-user`;

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function reset(e) {
    e.preventDefault();
    history.push(`/gan-direct/${insuranceType}/verify-user`);
  }

  return (
    <StyledBar mobile={mobile}>
      {mobile ? (
        <Mobile>
          {history.location.pathname !== beginningPath ? (
            <>
              <span onClick={goBack}>
                <IconActionChevronLeft data-test-it="underwriting_goback_nav" />
              </span>
            </>
          ) : (
            <span></span>
          )}
          <MobileProgressBar>
            {insuranceType === 'all' || insuranceType === 'allMobile'
              ? allIcons.map((icon, key) => {
                  return (
                    <FlowProgressBarStepContainer
                      icon={icon}
                      key={key}
                      id={key + 1}
                    />
                  );
                })
              : icons.map((icon, key) => {
                  return (
                    <FlowProgressBarStepContainer
                      icon={icon}
                      key={key}
                      id={key + 1}
                    />
                  );
                })}
          </MobileProgressBar>
          {history.location.pathname !== beginningPath && (
            <ResetButton onClick={reset}>
              <IconResetFilled
                color="#224074"
                data-test-it="underwriting_goback_nav"
              />
            </ResetButton>
          )}
          <Logo src={MobileLogoSrc} width="7rem" />
        </Mobile>
      ) : (
        //flow progress bar for mobile flow -> restyle
        <>
          <Logo src={LogoSrc} />
          <ProgressBar>
            {insuranceType === 'all'
              ? allIcons.map((icon, key) => {
                  return (
                    <FlowProgressBarStepContainer
                      icon={icon}
                      key={key}
                      id={key + 1}
                    />
                  );
                })
              : icons.map((icon, key) => {
                  return (
                    <FlowProgressBarStepContainer
                      icon={icon}
                      key={key}
                      id={key + 1}
                    />
                  );
                })}
          </ProgressBar>
          {history.location.pathname !== beginningPath && (
            <ResetButton onClick={reset}>
              <IconResetFilled
                color="#224074"
                data-test-it="underwriting_goback_nav"
              />
              <p>{i18n.t('Restart')}</p>
            </ResetButton>
          )}
        </>
      )}
    </StyledBar>
  );
};

const Mobile = styled.div`
  display: flex;
  width: 100%;
  margin: 0 2rem;
  justify-content: space-between;
  > span > svg {
    width: 5rem;
    margin-left: -1rem;
  }
`;

const MobileProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  ${'' /* width: 60%; */}
`;

const Logo = styled.img`
  margin-left: 0.25rem;
  width: ${({ width }) => width};
`;

const ResetButton = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  color: ${({ theme }) => theme.brand.primary};

  & > svg {
    max-width: 4rem;
    max-height: 4rem;
    fill: ${({ theme }) => theme.brand.primary};
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: ${({ mobile }) => (mobile ? '100%' : '75%')};
  margin: ${({ mobile }) => (mobile ? '2rem 1rem' : '0 0 2rem')};
  height: ${({ mobile }) => (mobile ? '4rem' : 'unset')};
  @media screen and (max-width: 425px) {
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  }
`;

export default FLowProgressBar;
