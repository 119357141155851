import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  SearchSelectInput,
  Label,
  DateInputV4,
  IconActionChevronLeft,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import i18n from '../i18n';

import { string } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router';
import { primaryOptions as primaryOptionsConstants } from '../constants/FlowSearchSelectData';

const FlowParentalInformationForm = ({ handleFormValues, defaultValues }) => {
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [selected, setSelected] = useState({});
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    insurance_company_name: string.notRequired,
    currently_have_health_insurance: string.notRequired,
    CanYouProvideMedicalCertificate: Yup.mixed().when(
      ['insurance_company_name', 'currently_have_health_insurance'],
      {
        is: (insurance_company_name, currently_have_health_insurance) =>
          currently_have_health_insurance && insurance_company_name !== 'None',
        then: string.required,
      }
    ),
    CurrentMediclaPolicyFromDate: Yup.date().required('Required'),
    CurrentMediclaPolicyToDate: Yup.date().required('Required'),
  });

  const SelectPlaceholder = i18n.t('Choose your option');

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstants.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (['CanYouProvideMedicalCertificate'].includes(name)) {
        handleSelected({ name, value: defaultValues[name] });
      } else {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }

  const getEnumValue = (name, value) => {
    switch (name) {
      case 'CanYouProvideMedicalCertificate':
        return primaryOptions.find((item) => item.value === value);
      default:
        return;
    }
  };

  const handleSelected = ({ value, name }) => {
    const selectedVal = getEnumValue(name, value);
    setSelected((prevValue) => ({
      ...prevValue,
      [name]: selectedVal,
    }));
    handleChange({ value, name });
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t('Health information')} </CatTitle>

        <SelectInput
          error={errors.CanYouProvideMedicalCertificate}
          name="CanYouProvideMedicalCertificate"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.CanYouProvideMedicalCertificate}
        >
          <StyledLabel>
            {i18n.t('Can you provide a medical certificate?')} *
          </StyledLabel>
        </SelectInput>
        <FlexWrapper>
          {values?.currently_have_health_insurance !== 1 && (
            <DateInputV4
              name="CurrentMediclaPolicyFromDate"
              error={errors.CurrentMediclaPolicyFromDate}
              onChange={handleChange}
              value={values.CurrentMediclaPolicyFromDate}
            >
              <StyledLabel>
                {i18n.t('Current medical policy start date')} *
              </StyledLabel>
            </DateInputV4>
          )}
          <DateInputV4
            name="CurrentMediclaPolicyToDate"
            error={errors.CurrentMediclaPolicyToDate}
            onChange={handleChange}
            value={values.CurrentMediclaPolicyToDate}
          >
            <StyledLabel>
              {i18n.t('Current medical policy end date')} *
            </StyledLabel>
          </DateInputV4>
        </FlexWrapper>
        <Required>* {i18n.t('Required fields')}</Required>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <StyledActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    & > div + div {
      margin-left: 0;
    }
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.text};
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

export default FlowParentalInformationForm;
