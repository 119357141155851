import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router';
import {
  WG_GANDIRECT_PROXY_USERNAME,
  WG_GANDIRECT_PROXY_PASSWORD,
} from '../env.config';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';
import {
  ganCreateNewPayment,
  post,
  ganUpdatePayment,
  updateHealthPolicyGan,
  updatePropertyPolicyGan,
  ganGetLoyaltyPointsByCustomerId,
  updateVehiclePolicyGan,
} from '../helpers/apiRouterService';
import { isEqual } from 'lodash';
import FlowOverviewForm from '../components/FlowOverviewForm';
import pack from 'locutus/php/misc/pack';
import FlowOverviewLeftSide from '../components/FlowOverviewLeftSide';
import { sha1 } from '../helpers/encryptionService';
import { parse } from 'query-string';
import { ActionButton, LoadingSpinner } from 'wg-fe-ui';
import useRouting from '../hooks/useRouting';
import cogoToast from 'cogo-toast';
import i18n from '../i18n';
import { format } from 'date-fns';

const getPaymentMethodEnum = (value) => {
  switch (value) {
    case 'full':
    case 'renewal':
      return '96ffe63a-742c-484c-b0f1-089251082f4c';
    default:
      return 'bbed16f2-f9da-40e5-8646-3d041a2ec8d0';
  }
};

const updatefuncs = {
  cars: updateVehiclePolicyGan,
  risk_addresses: updatePropertyPolicyGan,
  healths: updateHealthPolicyGan,
};

const FlowOverview = () => {
  const { id, affinity, insuranceType } = useParams();
  const [firstRender, setFirstRender] = useState(true);
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const [updatedPolicies, setUpdatedPolicies] = useState(
    data?.updatedPolicies || {
      cars: [],
      healths: [],
      risk_addresses: [],
    }
  );
  const [paymentMethod, setPaymentMethod] = useState(
    data?.paymentMethod || 'full'
  );
  const location = useLocation();
  const { renderNextRoute } = useRouting();
  const [errorMsg, setErrorMsg] = useState('');
  const [loyaltyPointCount, setLoyaltyPointCount] = useState(
    data?.loyaltyPointCount > 0 ? data?.loyaltyPointCount : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsPayloadFailed] = useState(false);

  const isLoadingPolicies = useMemo(() => {
    let totalLoadedPolicies = 0;
    Object.values(updatedPolicies || {}).forEach((policyType) => {
      totalLoadedPolicies += policyType.length;
    });
    const totalPoliciesToLoad =
      (data?.cars?.length || 0) +
      (data?.risk_addresses?.length || 0) +
      (data?.healths?.length || 0);
    return totalLoadedPolicies !== totalPoliciesToLoad;
  }, [updatedPolicies, data]);

  useEffect(() => {
    if (
      updatedPolicies &&
      (updatedPolicies?.cars?.length ||
        updatedPolicies?.healths?.length ||
        updatedPolicies?.risk_addresses?.length)
    ) {
      patchStorage({
        payload: {
          updatedPolicies,
          ...(loyaltyPointCount ? { loyaltyPointCount } : {}),
        },
        id,
        affinity,
        insuranceType,
      });
    }
  }, [updatedPolicies, loyaltyPointCount]);

  async function handleSubmit() {
    let Products = [];
    let Amount = 0;
    if (updatedPolicies?.risk_addresses?.forEach) {
      updatedPolicies.risk_addresses
        .filter((item) => item.inCart)
        .forEach((item) => {
          if (item?.response?.PolicyCode) {
            Products.push(item?.response?.PolicyCode);
          }
          Amount += +item?.response?.TotalwithFees.replace(',', '.') || 0;
        });
    }
    if (updatedPolicies?.cars?.forEach) {
      updatedPolicies.cars
        .filter((item) => item.inCart)
        .forEach((item) => {
          if (item?.response?.PolicyCode) {
            Products.push(item?.response?.PolicyCode);
          }
          Amount += +item?.response?.TotalwithFees.replace(',', '.') || 0;
        });
    }
    if (updatedPolicies?.healths?.forEach) {
      updatedPolicies.healths
        .filter((item) => item.inCart)
        .forEach((item) => {
          if (item?.response?.PolicyCode) {
            Products.push(item?.response?.PolicyCode);
          }
          Amount += +item?.response?.TotalwithFees.replace(',', '.') || 0;
        });
    }
    Products = Products.join(',');

    const payload = {
      Customer: data?.ganCustomer?.Oid,
      Amount,
      Installments: paymentMethod !== 'full' ? 1 : 0,
      Products,
    };

    patchStorage({
      payload: { paymentMethod },
      id,
      affinity,
      insuranceType,
    });
    const [resp, status] = await ganCreateNewPayment(payload);
    if (status !== 200) return;
    if (resp?.Error) return;
    patchStorage({
      payload: {
        paymentData: resp.Data,
      },
      id,
      affinity,
      insuranceType,
    });
    let purchaseAmt = Amount.toFixed(2);
    purchaseAmt = `${purchaseAmt}`.padStart(13, '0');
    let formattedPurchaseAmt = purchaseAmt.substr(0, 10);
    formattedPurchaseAmt = formattedPurchaseAmt + purchaseAmt.substr(11);
    const currency = 978;
    const merchantID = '0016392011';
    const acquirerID = '402971';
    const orderID = resp?.Data?.OrderId;
    const password = 'NXm482pp';
    const toEncrypt =
      password +
      merchantID +
      acquirerID +
      orderID +
      formattedPurchaseAmt +
      currency;
    const sha1Signature = sha1(toEncrypt);
    const bufferSig = pack('H*', sha1Signature);
    const base64Sha1Signature = btoa(bufferSig);
    const formattedToday = format(new Date(), 'ddMMyyyy');

    const paymentPayload = {
      Version: '1.0.0',
      MerID: '0016392011',
      AcqID: '402971',
      firstInstalmentDate: paymentMethod !== 'full' ? formattedToday : null,
      MerRespURL: `https://${WG_GANDIRECT_PROXY_USERNAME}:${WG_GANDIRECT_PROXY_PASSWORD}@gandirect.api.proxy.wegroup.io/gandirect-api/payment?r=${encodeURIComponent(
        window.location.href.split('?')[0]
      )}`,
      PurchaseAmt: formattedPurchaseAmt,
      PurchaseCurrency: '978',

      PurchaseCurrencyExponent: '2',
      OrderID: resp?.Data?.OrderId,
      CaptureFlag: 'A',
      Signature: base64Sha1Signature,
      SignatureMethod: 'SHA1',
    };
    post(
      'https://tjccpg.jccsecure.com/EcomPayment/RedirectAuthLink',
      paymentPayload
    );
  }

  const handleSuccessPayment = async () => {
    setIsLoading(true);
    const {
      ResponseCode,
      ReasonCodeDesc,
      ReasonCode,
      ResponseSignature,
      CExpiryYear,
      CExpiryMonth,
      AuthCode,
      PaddedCardNo,
      HashValue,
      OrderID,
    } = parse(location.search);

    const [res, status] = await ganUpdatePayment(
      {
        ResponseCode,
        ReasonCode,
        ReasonCodeDesc,
        ResponseSignature,
        CExpiryYear: CExpiryYear === '' ? null : CExpiryYear,
        CExpiryMonth: CExpiryMonth === '' ? null : CExpiryMonth,
        AuthCode,
        PaddedCardNo,
        HashValue,
      },
      OrderID
    );
    if (status === 200 && !res.Error) {
      renderNextRoute();
    } else {
      setErrorMsg(`${i18n.t(`Payment failed`)}: ${res?.Data || ''}`);
      setIsLoading(false);
    }
  };

  const handleErrorPayment = () => {
    setIsPayloadFailed(true);
    const { ReasonCodeDesc } = parse(location.search);
    setErrorMsg(
      `${i18n.t(`Payment failed`)}: ${decodeURIComponent(ReasonCodeDesc)}`
    );
  };

  const handlePolicies = async () => {
    const initialPointOptions = [];
    const fetchedLoyaltyPointCount = await getLoyaltyPointCount();
    for (let i = 0; i <= Math.floor(fetchedLoyaltyPointCount / 10); i++) {
      initialPointOptions.push({ label: i * 10, value: i * 10 });
    }

    const policies = { cars: [], healths: [], risk_addresses: [] };

    const paymentMethodValue = getPaymentMethodEnum(paymentMethod);
    // update all policies to NP with payment method as full

    Object.keys(updatedPolicies).forEach((key) => {
      if (data?.[key]?.length > 0) {
        data[key]
          .filter((item) => item?.insurance_info)
          .forEach(async (item) => {
            let payload;
            switch (key) {
              case 'cars':
                payload = { ...item?.car_package_cover_details };
                payload.MotorStatus = 2;
                break;
              case 'healths':
                payload = { ...item?.health_package_cover_details };
                payload.MedicalStatus = 2;
                break;
              case 'risk_addresses':
                payload = { ...item?.home_package_cover_details };
                payload.PropertyStatus = 2;
                break;
              default:
                break;
            }

            payload.RedeemLoyaltyPoints = 0;
            payload.PaymentMethod = {
              Oid: paymentMethodValue,
            };
            const [resp, status] = await updatefuncs[key](
              payload,
              item?.insurance_info?.PolicyCode
            );
            if (status !== 200 || resp.Error) {
              policies[key].push({
                payload,
                error: true,
                response: resp,
                inCart: true,
                allData: item,
                finished: true,
                loyaltyPoints: 0,
                loyaltyPointOptions: initialPointOptions,
              });
              setUpdatedPolicies((prev) => {
                const temp = Object.assign({}, prev);
                temp[key] = policies[key];
                return temp;
              });
              return cogoToast.error(
                i18n.t('Update policy failed, please contact us')
              );
            }
            policies[key].push({
              payload,
              // response: item.insurance_info,
              response: resp?.Data,
              inCart: true,
              allData: item,
              finished: true,
              loyaltyPoints: 0,
              loyaltyPointOptions: initialPointOptions,
            });
            setUpdatedPolicies((prev) => {
              const temp = Object.assign({}, prev);
              temp[key] = policies[key];
              return temp;
            });
          });
      }
    });
  };

  const handlePoliciesPaymentMethodUpdate = async () => {
    const paymentMethodValue = getPaymentMethodEnum(paymentMethod);
    // update all policies to NP with payment method as full

    Object.keys(updatedPolicies).forEach((key) => {
      if (updatedPolicies?.[key]?.length > 0) {
        updatedPolicies[key].forEach(async (item, i) => {
          const payload = item.payload;
          payload.PaymentMethod = {
            Oid: paymentMethodValue,
          };
          setUpdatedPolicies((prev) => {
            const temp = Object.assign({}, prev);
            prev[key][i].finished = false;
            return temp;
          });
          const [resp, status] = await updatefuncs[key](
            payload,
            item?.response?.PolicyCode
          );
          if (status !== 200 || resp.Error) {
            return cogoToast.error(
              i18n.t('Update policy failed, please contact us')
            );
          }

          setUpdatedPolicies((prev) => {
            const temp = Object.assign({}, prev);
            temp[key][i].payload = payload;
            temp[key][i].response = resp?.Data;
            temp[key][i].finished = true;
            return temp;
          });
        });
      }
    });
  };

  const updatePolicy = async ({ type, key, payloadKey, value }) => {
    let temp = Object.assign({}, updatedPolicies);
    temp[type][key].payload[payloadKey] = value;
    temp[type][key].finished = false;
    if (payloadKey === 'RedeemLoyaltyPoints')
      temp[type][key].loyaltyPoints = value;
    setUpdatedPolicies((prev) => {
      const tempUpdate = Object.assign({}, prev);
      tempUpdate[type][key] = temp[type][key];
      return tempUpdate;
    });
    const [resp, status] = await updatefuncs[type](
      temp[type][key].payload,
      temp[type][key].response?.PolicyCode
    );
    temp = Object.assign({}, updatedPolicies);
    if (status !== 200 || resp.Error) {
      cogoToast.error(i18n.t('Update policy failed, please contact us'));
    } else {
      temp[type][key].response = resp?.Data;
    }
    temp[type][key].finished = true;
    setUpdatedPolicies((prev) => {
      const tempUpdate = Object.assign({}, prev);
      tempUpdate[type][key] = temp[type][key];
      return tempUpdate;
    });
    return temp;
  };

  const togglePolicy = (type, key) => {
    setUpdatedPolicies((prev) => {
      const temp = Object.assign({}, prev);
      temp[type][key].inCart = !temp[type][key].inCart;
      return temp;
    });
  };

  const updatePayload = async ({ type, key, payloadKey, value }) => {
    if (updatedPolicies[type][key].payload[payloadKey] === value) return;
    if (payloadKey === 'RedeemLoyaltyPoints') {
      setUpdatedPolicies((prev) => {
        const temp = Object.assign({}, prev);
        let totalUsedLoyaltyPoints = 0;
        Object.values(temp).forEach((typeList) => {
          typeList.forEach((policy) => {
            totalUsedLoyaltyPoints += policy.loyaltyPoints;
          });
        });
        const spareLoyaltyPoints = loyaltyPointCount - totalUsedLoyaltyPoints;
        Object.keys(temp).forEach((key) => {
          temp[key].forEach((policy, i) => {
            if (spareLoyaltyPoints > policy.loyaltyPoints) {
              temp[key][i].loyaltyPointOptions = [];
              for (let i = 0; i <= Math.floor(loyaltyPointCount / 10); i++) {
                temp[key][i].loyaltyPointOptions.push({
                  label: i * 10,
                  value: i * 10,
                });
              }
            } else {
              temp[key][i].loyaltyPointOptions = [];
              for (let i = 0; i <= Math.floor(policy.loyaltyPoints / 10); i++) {
                temp[key][i].loyaltyPointOptions.push({
                  label: i * 10,
                  value: i * 10,
                });
              }
            }
          });
        });
        return temp;
      });
    }
    updatePolicy({ type, key, payloadKey, value });
  };

  const getLoyaltyPointCount = async () => {
    const [resp, status] = await ganGetLoyaltyPointsByCustomerId(
      data?.ganCustomer?.Oid
    );
    if (status === 200 && !resp.Error) {
      setLoyaltyPointCount(
        resp.LoyaltyPointsAvailable > 0 ? resp.LoyaltyPointsAvailable : 0
      );
      return resp.LoyaltyPointsAvailable > 0 ? resp.LoyaltyPointsAvailable : 0;
    }
    return 0;
  };

  const needsUpdate = () => {
    let updateNeeded = false;
    if (data.cars)
      data.cars.forEach((item, i) => {
        if (
          !isEqual(
            item?.car_package_cover_details,
            updatedPolicies.cars?.[i]?.allData?.car_package_cover_details
          )
        ) {
          updateNeeded = true;
          setUpdatedPolicies((prev) => {
            const temp = Object.assign({}, prev);
            if (temp?.cars?.[i]) {
              temp.cars[i].finished = false;
            }
            return temp;
          });
        }
      });
    if (data.healths)
      data.healths.forEach((item, i) => {
        if (
          !isEqual(
            item?.health_package_cover_details,
            updatedPolicies.healths?.[i]?.allData?.health_package_cover_details
          )
        ) {
          updateNeeded = true;
          setUpdatedPolicies((prev) => {
            const temp = Object.assign({}, prev);
            if (temp?.healths?.[i]) {
              temp.healths[i].finished = false;
            }
            return temp;
          });
        }
      });
    if (data.risk_addresses)
      data.risk_addresses.forEach((item, i) => {
        if (
          !isEqual(
            item?.home_package_cover_details,
            updatedPolicies.risk_addresses?.[i]?.allData
              ?.home_package_cover_details
          )
        ) {
          updateNeeded = true;
          setUpdatedPolicies((prev) => {
            const temp = Object.assign({}, prev);
            if (temp?.risk_addresses?.[i]) {
              temp.risk_addresses[i].finished = false;
            }
            return temp;
          });
        }
      });
    return updateNeeded;
  };

  useEffect(() => {
    const policiesSet =
      updatedPolicies?.cars?.length ||
      updatedPolicies?.healths?.length ||
      updatedPolicies?.risk_addresses?.length;
    const { ResponseCode } = parse(location.search);
    getLoyaltyPointCount();
    if ((!ResponseCode && !policiesSet) || (policiesSet && needsUpdate())) {
      handlePolicies();
    } else {
      if (!ResponseCode) return;
      if (+ResponseCode === 1) {
        handleSuccessPayment();
      } else {
        handleErrorPayment();
      }
    }
  }, []);

  useEffect(() => {
    if (paymentMethod && !firstRender) {
      handlePoliciesPaymentMethodUpdate();
    } else {
      if (paymentMethod) setFirstRender(false);
    }
  }, [paymentMethod]);

  return (
    <SplitContainer>
      {!isLoading ? (
        <>
          <LeftSplit>
            <FlowOverviewLeftSide
              errorMsg={errorMsg}
              storageData={data}
              updatedPolicies={updatedPolicies}
              paymentState={[paymentMethod, setPaymentMethod]}
              handleSubmit={handleSubmit}
            />
          </LeftSplit>
          <RightSplit>
            <FlowOverviewForm
              updatedPolicies={updatedPolicies}
              storageData={data}
              isLoading={isLoadingPolicies}
              togglePolicy={togglePolicy}
              updatePayload={updatePayload}
            />
          </RightSplit>
          <ErrorMessage>{errorMsg}</ErrorMessage>
          <StyledActionButton disabled={!paymentMethod} onClick={handleSubmit}>
            {i18n.t('Checkout')}
          </StyledActionButton>
        </>
      ) : (
        <LoadingSpinner isAbsolute />
      )}
    </SplitContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-weight: bold;
  padding: 1.5rem;
  width: 90%;
  margin: 1.6rem auto;
  &:not(:disabled) {
    background: #224074;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LeftSplit = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-right: 5rem;
  :after {
    @media screen and (min-width: 769px) {
      overflow-y: auto;
      content: '';
      width: 2px;
      height: calc(100% - 10rem);
      background: #f5f6f7;
      position: absolute;
      left: 50%;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 2.4rem;
    padding-right: 0;
    height: auto;
  }
`;

const RightSplit = styled.div`
  overflow-y: auto;
  padding-left: 5rem;
  padding-right: 3px;
  height: 100%;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    height: unset;
    overflow-y: unset;
  }
`;

const SplitContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: reverse;
    flex-direction: column;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.status.error};
  margin-top: 1.6rem;
  @media (min-width: 768px) {
    display: none;
  }
`;

export default FlowOverview;
