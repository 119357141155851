import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import FlowDoctorInformationForm from '../components/FlowDoctorInformationForm.jsx';
import { ChatContext } from '../contexts/ChatContext';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import FlowChatBox from '../components/FlowChatBox';
import { ReasonForDoctorVisitEnum } from '../constants/FlowSearchSelectData';
import { format } from 'date-fns';

const FlowHealthInformation = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { id, affinity, insuranceType, currentHealth } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { healths } = data;
    if (healths[currentHealth] !== null) {
      setDefaultValues(healths[currentHealth]);
    }
    const question = {
      message: i18n.t('When was your last doctors appointment'),
      slug: 'doctor-information',
    };
    addQuestion(question);
  }, []);

  function handleSubmit(values) {
    const { Doctor, ReasonOfLastVisit, LastDateSeenByTheDoctor } = values || {};

    const payload = {
      Doctor,
      ReasonOfLastVisit,
      LastDateSeenByTheDoctor,
    };

    const answer = {
      message: i18n.t(`My last appointment was for a`, {
        date: format(new Date(LastDateSeenByTheDoctor), 'dd/MM/yyyy'),
        reason: i18n.t(
          ReasonForDoctorVisitEnum.find(
            (item) => item.value === ReasonOfLastVisit
          ).label
        ),
      }),
      slug: 'doctor-information',
    };

    addAnswer(answer);

    patchStorage({
      payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { health: currentHealth });
  }

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <SplitContainer>
      {!isMobile() && <FlowChatBox />}
      <RightSplit>
        <FlowDoctorInformationForm
          handleFormValues={handleSubmit}
          defaultValues={
            defaultValues.health ? defaultValues.health : defaultValues
          }
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightSplit = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;

export default FlowHealthInformation;
