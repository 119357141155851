import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton, ToggleInput, IconActionChevronLeft } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import {
  ganGetCustomerVehicles,
  retrieveLicensePlateGan,
} from '../helpers/apiRouterService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import LoadingSpinner from '../components/LoadingSpinner';

const FlowCarRegistered = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState();
  const [customerCars, setCustomerCars] = useState([]);
  const { renderNextRoute } = useRouting();
  const [text, setText] = useState();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { cars, ganCustomer } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [carAlreadyRegistered, setCarAlreadyRegistered] = useState();
  const [defaultValues, setDefaultValues] = useState({});
  const [isSecondHandCar, setIsSecondHandCar] = useState(false);
  const [licensePlateError, setLicensePlateError] = useState();
  const [error, setError] = useState();
  const history = useHistory();

  useEffect(() => {
    if (currentVehicle === 0) {
      const question = {
        message: i18n.t(
          "No problem! Let's look at the car insurance you wanted Is your <span>car already registered</span>?"
        ),
        slug: 'car_already_registered',
      };
      addQuestion(question);
    } else {
      const question = {
        message: i18n.t('Is this <span>car already registered</span>?'),
        slug: 'car_already_registered',
      };
      addQuestion(question);
    }
    loadDefaultValues();
  }, []);

  function handleInputChange(e) {
    setLicensePlateError();
    const code = e.keyCode || e.which;
    if (code === 13) {
      handleSearch();
    }
    setText(e.currentTarget.value);
  }

  const loadDefaultValues = async () => {
    setDefaultValues(cars?.[currentVehicle]);
    const [resp, status] = await ganGetCustomerVehicles(ganCustomer?.Oid);
    if (status === 200) {
      const data = resp.Data.map((data) => ({
        label: data?.VehicleRef,
        value: data,
      }));
      data.push({
        label: i18n.t('Other'),
        value: 'OTHER',
      });
      setCustomerCars(data);
      cars[currentVehicle]?.customer_vehicle &&
      cars[currentVehicle]?.customer_vehicle !== 'OTHER'
        ? setOption({
            label: cars[currentVehicle]?.customer_vehicle?.VehicleRef,
            value: cars[currentVehicle]?.customer_vehicle,
          })
        : setOption({
            label: i18n.t('Other'),
            value: 'OTHER',
          });
    }
  };

  useEffect(() => {
    if (defaultValues?.car_already_registered != null) {
      setCarAlreadyRegistered(defaultValues?.car_already_registered);
    }
    if (defaultValues?.license_plate != null) {
      setText(defaultValues?.license_plate);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (text != null) {
      setError();
    }
  }, [text]);

  async function handleSearch(e) {
    if (e) {
      e.preventDefault();
    }
    if (
      !option?.value ||
      (option?.value === 'OTHER' && carAlreadyRegistered === undefined)
    ) {
      setError('Please answer the question(s) above');
      return;
    } else if (
      option?.value === 'OTHER' &&
      carAlreadyRegistered === true &&
      text == null
    ) {
      setError('Please answer the question(s) above');
      return;
    } else {
      setError();
    }
    setLoading(true);
    const [resp, status] = await retrieveLicensePlateGan(text);

    if (option.value === 'OTHER') {
      if (carAlreadyRegistered) {
        setLoading(false);
        if (status === 204) {
          setLicensePlateError(
            'Please enter a valid registered registration number'
          );
          return;
        } else if (status !== 200) {
          setLicensePlateError(
            'Something went wrong while looking up your registration number. Please try again.'
          );
          return;
        }

        let car_information = {
          car_already_registered: carAlreadyRegistered,
          license_plate: text,
          second_hand_car: isSecondHandCar,
          vehicle_info: resp,
        };

        cars[currentVehicle] = car_information;

        patchStorage({
          payload: { cars },
          id,
          affinity,
          insuranceType,
        });
      } else {
        let car_information = {
          car_already_registered: carAlreadyRegistered,
        };

        cars[currentVehicle] = car_information;

        patchStorage({
          payload: { cars },
          id,
          affinity,
          insuranceType,
        });
      }
    } else {
      let car_information = {
        car_already_registered: true,
        license_plate: option?.value?.RegNumber,
        second_hand_car: false,
        vehicle_info: option?.value,
        customer_vehicle: option?.value,
      };

      cars[currentVehicle] = car_information;

      patchStorage({
        payload: { cars },
        id,
        affinity,
        insuranceType,
      });
    }
    const answer = {
      message: carAlreadyRegistered
        ? {
            car_information: resp,
            msg: i18n.t(`Yes, the registration number is`, {
              licensePlate: text.toUpperCase(),
            }),
          }
        : i18n.t('No'),
      slug: 'car_already_registered',
    };

    addAnswer(answer);
    renderNextRoute(1, { vehicle: currentVehicle });
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form>
          <div>
            <TitleForm>{i18n.t('Select your vehicle')}</TitleForm>
            <SearchSelectInputV2
              key={`existing_vehicle`}
              name="Title"
              disabled={customerCars?.length < 1}
              onChange={(val) => {
                setOption(val);
              }}
              options={customerCars}
              placeholder={i18n.t('Select your vehicle')}
              value={option}
            />
            {customerCars?.length < 1 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingSpinner />
              </div>
            )}
            {option?.value === 'OTHER' && (
              <>
                <TitleForm>
                  {i18n.t('Is your car already registered?')}
                </TitleForm>
                <AddressToInsureQ>
                  <StyledButton
                    active={carAlreadyRegistered === true}
                    onClick={(e) => {
                      e.preventDefault();
                      setCarAlreadyRegistered(true);
                    }}
                  >
                    {i18n.t('Yes')}
                  </StyledButton>
                  <StyledButton
                    active={carAlreadyRegistered === false}
                    onClick={(e) => {
                      e.preventDefault();
                      setCarAlreadyRegistered(false);
                    }}
                  >
                    {i18n.t('No')}
                  </StyledButton>
                </AddressToInsureQ>
                {carAlreadyRegistered && (
                  <RegisteredQuestion>
                    <h3>{i18n.t('Did you buy this car secondhand?')}</h3>
                    <ToggleInput
                      falseLabel={i18n.t('No')}
                      trueLabel={i18n.t('Yes')}
                      checked={isSecondHandCar}
                      onChange={() => setIsSecondHandCar(!isSecondHandCar)}
                    />
                    <TitleForm>
                      {i18n.t('What’s your registration number?')}
                    </TitleForm>
                    <LicenseLookup>
                      <LicenseInputContainer>
                        <LicenseInputLetter>CY</LicenseInputLetter>
                        <LicenseInput
                          onChange={handleInputChange}
                          onKeyPress={handleInputChange}
                          value={text}
                          type="text"
                        />
                      </LicenseInputContainer>
                      <LicenseError>{licensePlateError}</LicenseError>
                    </LicenseLookup>
                  </RegisteredQuestion>
                )}
              </>
            )}
          </div>
          <Error>{error}</Error>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Next')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                onClick={handleSearch}
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  align-self: center;
  color: #f74040;
  font-size: 1.4rem;
  justify-content: left;
`;

const LicenseError = styled.div`
  color: #f74040;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const LicenseLookup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const LicenseInputContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const LicenseInputLetter = styled.div`
  align-items: center;
  background-color: #fbfbfb;
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: 0;
  color: #5b5550;
  display: flex;
  flex: 0 0 5rem;
  font-size: 2.5rem;
  font-weight: 900;
  height: 5rem;
  justify-content: center;
  line-height: 4.6rem;
  width: 5rem;
`;

const LicenseInput = styled.input`
  border: 1px solid #e4e4e4;
  border-radius: 0 0.5rem 0.5rem 0;
  flex: 1 0 auto;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  width: 20rem;

  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.brand.primary};
    outline: 0;
  }
`;

const RegisteredQuestion = styled.div`
  h3 {
    color: #8990a3;
    font-size: 1.4rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
  > div {
    margin-bottom: 2rem;
  }
`;

const AddressToInsureQ = styled.div`
  display: flex;
  width: 100%;
  padding: 3rem 0;
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  margin-right: 2.4rem;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  max-width: 21.3rem;
  width: 48%;
  height: 5.3rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }

  &:first-of-type {
    margin-right: 3rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowCarRegistered;
