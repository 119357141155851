import React from 'react';

const CartGanIcon = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 79.61 79.61">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <polyline
            points="20.13 21.63 25.67 21.63 29.99 43.68 52.72 43.68 59.48 27.28 27.11 27.5"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <path
            d="M30,43.68c-5.09,0-5.43,6.77,0,6.77H52.61"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="31.44"
            cy="55.21"
            r="2.77"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <circle
            cx="50.39"
            cy="55.21"
            r="2.77"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default CartGanIcon;