import React from 'react';
import { Redirect, Route } from 'react-router';
import { useFlowStore } from '../contexts/FlowContext';
import { getNestedObject } from '../helpers/objectService';
import { retrieveStorageById } from '../helpers/storeService';

let firstRender = true;

const RouteHandler = ({ component: Component, ...rest }) => {
  const [, flowDispatch] = useFlowStore();

  return (
    <Route
      { ...rest }
      render={(props) => {
        if (firstRender) {
          const { data } = 
            retrieveStorageById(
              getNestedObject(props, ['match', 'params', 'id']),
              getNestedObject(props, ['match', 'params', 'affinity']),
              getNestedObject(props, ['match', 'params', 'insuranceType'])
            ) || {};
          if (data) {
            flowDispatch({
              type: 'FILL_STORAGE_DATA',
              payload: {
                initial: false,
                ganAddress: data?.ganAddress || {},
                ganCustomer: data?.ganCustomer || {},
                customer: data?.customer || {},
                ganVehicle: data?.ganVehicle || {},
                ganRiskAddress: data?.ganRiskAddress || {},
              },
            });
          }
          firstRender = false;
        }
        return retrieveStorageById(
          getNestedObject(props, ['match', 'params', 'id']),
          getNestedObject(props, ['match', 'params', 'affinity']),
          getNestedObject(props, ['match', 'params', 'insuranceType'])
        ) ? (
          <Component { ...rest } />
        ) : (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/${getNestedObject(props, [
                'match',
                'params',
                'insuranceType',
              ])}/verify-user`,
            }}
          />
        );
      }}
    />
  );
};

export default RouteHandler;