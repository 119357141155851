import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  SearchSelectInput,
  IconActionChevronLeft,
  TextInput,
  NumbersInputWithSymbol,
  DateInputV4,
  LoadingSpinner,
  ToggleInput,
} from 'wg-fe-ui';
import useRouting from '../hooks/useRouting.js';
import OptionsPriceInput from './VATInputSwitcherV2';
import { useHistory } from 'react-router-dom';
import i18n from '../i18n';
import { capitalize, numberWithCommas } from '../helpers/stringService';
import { patchStorage } from '../helpers/storeService.js';
import {
  createNewVehicleGAN,
  updateVehicleGAN,
  retrieveAllAutomobileMakesGAN,
  retrieveAutomobileModelsByMakeGAN,
} from '../helpers/apiRouterService';
import { useParams } from 'react-router';
import { ChatContext } from '../contexts/ChatContext';
import {
  ganBool,
  number,
  oidObject,
  positiveNumber,
  string,
  vinNumber,
} from '../constants/validationSchemas.js';
import { object, date, bool, mixed, number as yupNumber } from 'yup';
import useForm from '../hooks/useForm.js';
import {
  bodyTypeOptionsGAN,
  steeringPositionOptions,
  styleOptionsGAN,
  vehicleColorOptions,
  vehicleSupplyOptions,
  ownedFinancedOrLeasedOptions,
  useOfVehicleOptions,
  annualMileageOptions,
} from '../constants/FlowSearchSelectData.js';
import { financial_institutions as bankOptions } from '../constants/FinancialInstitutionsData.js';
import {
  getObjectDifferences,
  isObjectEmpty,
} from '../helpers/objectService.js';
import { isFuture } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce/lib';

//** CONSTANTS AND SELECTIONS **//
const defaultPayload = {
  VehicleModificationAlteration: [],
  ExistingVehicleDamages: [],
  VehicleInspections: [],

  JointOwnershipWith: null,
  Bank: null,

  IsThereAnyExistingDamageOnTheVehicle: 0,
  AnyAlterationModificationOnVehicle: 0,
  OverseasUse: 0,
};

const bankRequired = [1, 2, 4];
const jointOwnershipWithRequired = [3, 4];
const oidObjects = [
  'Make',
  'Model',
  'BodyType',
  'Style',
  'Color',
  'Supply',
  'Bank',
]; // Objects that have nested Oid
const selectPlaceholder = i18n.t('Choose your option');

const bodyTypeOptionsLocal = bodyTypeOptionsGAN.map(({ value, label }) => ({
  value,
  label: i18n.t(label),
}));
const styleOptionsLocal = styleOptionsGAN.map(({ value, label }) => ({
  value,
  label: i18n.t(label),
}));
const vehicleColorOptionsLocal = vehicleColorOptions.map(
  ({ value, label }) => ({
    value,
    label: i18n.t(label),
  })
);
const vehicleSupplyOptionsLocal = vehicleSupplyOptions.map(
  ({ value, label }) => ({
    value,
    label: i18n.t(label),
  })
);
// Removing joint ownership from options, until we have more info about this
const ownedFinancedOrLeasedOptionsLocal = ownedFinancedOrLeasedOptions.filter(
  ({ value }) => !jointOwnershipWithRequired.includes(value)
);

const VehicleLookupComponent = ({ storageData }) => {
  //** STATES AND HOOKS **//
  const { t } = useTranslation();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const history = useHistory();

  const { ganCustomer, cars: storedVechicles } = storageData;
  const storedVehicle = storedVechicles[currentVehicle].vehicle_info;

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [annualMileage, setAnnualMileage] = useState(0);

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const { addAnswer } = useContext(ChatContext);

  //** SUBMIT AND FORM VALIDATION/HANDLING **//
  const validatePastDate = (validationName) => [
    validationName,
    t('Please provide a date in the past'),
    (date) => !isFuture(date),
  ];

  const VehicleSchema = object({
    // Reg and vin
    RegNumber: string.notRequired,
    VinNumber: vinNumber,

    // Numbers
    VehicleSeatsNo: positiveNumber,
    EngineCapacity: positiveNumber,
    EngineHP: positiveNumber,
    UnladenWeight: positiveNumber,
    invoice_value: yupNumber()
      .typeError(i18n.t(`not a number`))
      .min(1, i18n.t(`Value cannot be 0`))
      .max(200000, i18n.t(`Value cannot be more than 200.000`))
      .required(i18n.t(`required`)),
    catalogue_value: number.notRequired,
    SteeringPosition: number.required,
    OwnedFinancedOrLeased: number.required,
    PrimaryUseOfVehicle: number.required,

    // Mixed
    Bank: mixed().when('OwnedFinancedOrLeased', {
      is: (OwnedFinancedOrLeased) =>
        bankRequired.includes(OwnedFinancedOrLeased),
      then: oidObject.number,
    }),
    JointOwnershipWith: mixed().when('OwnedFinancedOrLeased', {
      is: (OwnedFinancedOrLeased) =>
        jointOwnershipWithRequired.includes(OwnedFinancedOrLeased),
      then: number.required,
    }),

    // Dates
    ManufacturedDate: date()
      .required(t('required'))
      .test(...validatePastDate('manufactured-date-validation')),
    SinceWhenDoYouHaveProvenExperienceWithThisTypeOfVehicle: date()
      .required(t('required'))
      .test(...validatePastDate('sincewhenexperience-date-validation')),

    // Standard oidObject shapes
    BodyType: oidObject.number,
    Style: oidObject.string,
    Color: oidObject.number,
    Supply: oidObject.number,
    AnnualMileage: oidObject.number,

    // Other shapes
    Make: object({
      VehicleMakeDesc: string.required,
      Oid: number.required,
    }),
    Model: object({
      VehicleModelDesc: string.required,
      Authorization: bool().required(),
      Oid: number.required,
    }),

    // Bools
    OverseasUse: ganBool,
    AnyAlterationModificationOnVehicle: ganBool,
  });

  const { values, errors, handleChange, handleSubmit } = useForm({
    validationSchema: VehicleSchema,
    initialValues: storedVehicle || {},
  });

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(createNewVehicle);
  };

  //** MAKES & MODELS FETCHING AND VEHICLE HANDLING **//
  //* Makes *//
  useEffect(() => {
    setIsFetching(true);

    const loadMakes = async () => {
      const [fetchedMakes, status] = await retrieveAllAutomobileMakesGAN();
      if (status === 200 && !fetchedMakes?.Error) {
        setMakes(fetchedMakes?.Data);
      } else {
        setIsFetchingFailed(true);
      }
      setIsFetching(false);
    };

    loadMakes();
  }, []);

  async function handleMakes(value) {
    if (!makes) return [];

    return makes
      .filter(({ VehicleMakeDesc }) =>
        VehicleMakeDesc.toLowerCase().includes(value.toLowerCase())
      )
      .map((make) => {
        const { VehicleMakeDesc } = make;
        return {
          label: VehicleMakeDesc,
          value: make,
        };
      });
  }

  //* Models *//
  useEffect(() => {
    if (!values.Make) return;
    setIsFetching(true);

    const loadModels = async () => {
      const [fetchedModels, status] = await retrieveAutomobileModelsByMakeGAN(
        values.Make.Oid
      );
      if (status === 200 && !fetchedModels?.Error) {
        setModels(fetchedModels?.Data?.ModelList);
      } else {
        setIsFetchingFailed(true);
      }
      setIsFetching(false);
    };

    loadModels();
  }, [values.Make]);

  async function handleModels(value) {
    if (!models) return [];

    return models
      .filter(({ VehicleModelDesc }) =>
        VehicleModelDesc.toLowerCase().includes(value.toLowerCase())
      )
      .map((model) => {
        const { VehicleModelDesc } = model;
        return {
          label: capitalize(VehicleModelDesc),
          value: model,
        };
      });
  }

  //** FORM HELPERS **//
  useEffect(() => {
    setErrorMessage(null);

    if (!isObjectEmpty(errors))
      setErrorMessage(i18n.t('Please resolve errors in the form'));
  }, [errors]);

  const handleSelectModel = ({ value }) => {
    delete value.VehicleMake;
    handleChange({ name: 'Model', value });
  };

  function handleSelectChange(val, name) {
    if (oidObjects.includes(name)) {
      return handleChange({
        name,
        // Nesting in Oid for some functions, see comment above declaration of oidObjects[]
        value: { Oid: val ? val.value : undefined },
      });
    }
    handleChange({ name, value: val ? val.value : undefined });
  }

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return undefined;

    let key = null;
    if (oidObjects.includes(inputName)) {
      key = values[inputName]['Oid'];
    } else {
      key = values[inputName];
    }

    return object.find(({ value }) => value === key);
  }

  /* Annual mileage handling */
  const [handleAnnualMileage] = useDebouncedCallback((foundOption) => {
    handleChange({
      name: 'AnnualMileage',
      value: {
        Oid: foundOption.value,
      },
    });
  }, 400);

  useEffect(() => {
    const foundOption = annualMileageOptions.find(
      (option) => annualMileage >= option.from && annualMileage <= option.to
    );

    foundOption && handleAnnualMileage(foundOption);
  }, [annualMileage]);

  /**
   * This function will take into account that some inputs have nested Oid's in them, and parse them as the error if present
   * @param {string} inputName Name of input
   * @returns The error, if found, for the input
   */
  const selectError = (inputName) => {
    if (oidObjects.includes(inputName)) {
      return errors[inputName + '.Oid'];
    } else {
      return errors[inputName];
    }
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const updateVehicle = async (vehicleWithUpdates) => {
    setIsFetching(true);

    const updatedValues = getObjectDifferences(
      vehicleWithUpdates,
      storedVehicle
    );

    // If updated data was empty, close form and do nothing
    if (isObjectEmpty(updatedValues)) {
      setIsFetching(false);
      return handleNextRoute(vehicleWithUpdates);
    }

    const [resp, status] = await updateVehicleGAN(
      updatedValues,
      storedVehicle.Oid
    );

    if (status !== 200) {
      setErrorMessage('Something went wrong at GanDirect');
      return setIsFetching(false);
    }

    if (resp.Error) {
      setErrorMessage(resp.Description);
      return setIsFetching(false);
    }

    handleNextRoute(vehicleWithUpdates);
  };

  //** DAT POST HANDLING **//
  const createNewVehicle = async (values) => {
    // If vehicle already exists in storge, update instead
    const tempValues = { ...values };
    delete tempValues.invoice_value;
    if (!tempValues?.VinNumber) tempValues.VinNumber = null;
    if (!tempValues?.RegNumber) tempValues.RegNumber = null;
    if (storedVehicle) return updateVehicle(tempValues);
    setIsFetching(true);

    const newVehicle = {
      ...defaultPayload,
      ...tempValues,
      Owner: {
        Oid: ganCustomer?.Oid,
      },
    };
    const [resp, status] = await createNewVehicleGAN(newVehicle);

    if (status !== 200) {
      setErrorMessage('Something went wrong at GanDirect');
      return setIsFetching(false);
    }

    if (resp.Error) {
      setErrorMessage(resp.Description);
      return setIsFetching(false);
    }

    newVehicle.Oid = resp.Data;
    handleNextRoute(newVehicle);
  };

  const handleNextRoute = (vehicle) => {
    const { Make, Model } = vehicle;

    const answerElement = {
      message: {
        car_image: '',
        car_name: Make?.VehicleMakeDesc,
        model_year: Model?.VehicleModelDesc,
      },
      slug: 'car_response',
    };
    addAnswer(answerElement);

    patchStorage({
      payload: {
        vehicle_info: {
          ...vehicle,
          invoice_value: values?.invoice_value,
          catalogue_value: values?.catalogue_value,
        },
      },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  return (
    <>
      <VehicleSearchContainer onSubmit={formSubmitHandler}>
        {isFetching ? (
          <LoadingSpinner />
        ) : isFetchingFailed ? (
          <div>
            <SubTitle>Oops!</SubTitle>
            <LabelName>
              Something went wrong at our end. We are looking into fixing this
              as soon as possible. Please try later!
            </LabelName>
          </div>
        ) : (
          <Scroll>
            {/* ----------- Brand and model ----------- */}
            <SubTitle>{t('Brand and model')}</SubTitle>
            <FlexWrapper>
              <SelectInput
                async
                name="Make"
                placeholder={selectPlaceholder}
                loadOptions={handleMakes}
                onSelected={(val) => {
                  if (values.Model) {
                    handleChange({ name: 'Model', value: undefined }); //Reset model
                  }
                  handleChange(val);
                }}
                value={values.Make && { label: values?.Make?.VehicleMakeDesc }}
                error={selectError('Make')}
                isClearable
              >
                <LabelName>{t('Brand')} *</LabelName>
              </SelectInput>

              <SelectInput
                async
                name="Model"
                placeholder={selectPlaceholder}
                loadOptions={handleModels}
                onSelected={handleSelectModel}
                value={
                  values.Model && { label: values.Model?.VehicleModelDesc }
                }
                error={selectError('Model')}
                disabled={!values.Make}
              >
                <LabelName>{t('Model')} *</LabelName>
              </SelectInput>
            </FlexWrapper>

            {/* ----------- Metadata ----------- */}
            <SubTitle>{t('Car information')}</SubTitle>
            <FlexWrapper>
              <TextInput
                name="RegNumber"
                onChange={handleChange}
                value={values.RegNumber}
                error={selectError('RegNumber')}
              >
                <LabelName>{t('Registration number')}</LabelName>
              </TextInput>
              <TextInput
                name="VinNumber"
                onChange={handleChange}
                value={values.VinNumber}
                error={selectError('VinNumber')}
              >
                <LabelName>{t('VIN number')} *</LabelName>
              </TextInput>
            </FlexWrapper>

            <DateInputV4
              name="ManufacturedDate"
              onChange={handleChange}
              value={values.ManufacturedDate}
              error={selectError('ManufacturedDate')}
            >
              <LabelName>{t('Manufactured date')} *</LabelName>
            </DateInputV4>

            <RangeWrapper>
              <LabelName>
                {i18n.t(
                  'How many km/year will the vehicle travel approximately?'
                )}
              </LabelName>
              <div>
                <RangeContainer>
                  <p className="min">0 km</p>
                  <p className="max">{numberWithCommas(200000) + ' km'}</p>
                </RangeContainer>
                <MaxConsecutiveDays>
                  <input
                    type="range"
                    min="0"
                    max="200000"
                    onChange={(e) => setAnnualMileage(e.target.value)}
                    step="2500"
                  />
                  <p>
                    <span> {numberWithCommas(annualMileage)} </span>
                    km
                  </p>
                </MaxConsecutiveDays>
              </div>
            </RangeWrapper>

            {/* ----------- Vehicle properties ----------- */}
            <SubTitle>{t('Vehicle properties')}</SubTitle>
            <FlexWrapper>
              <TextInput
                name="VehicleSeatsNo"
                onChange={({ name, value }) =>
                  handleChange({ name, value: parseInt(value) || value })
                }
                value={values.VehicleSeatsNo}
                error={selectError('VehicleSeatsNo')}
              >
                <LabelName>{t('Amount of vehicle seats')} *</LabelName>
              </TextInput>
              <StyledNumbersInputWithSymbol
                name="UnladenWeight"
                onChange={({ name, value }) =>
                  handleChange({ name, value: parseInt(value) || value })
                }
                value={values.UnladenWeight}
                error={selectError('UnladenWeight')}
                icon="KG"
              >
                <LabelName>{t('Weight in unladen state')} *</LabelName>
              </StyledNumbersInputWithSymbol>
            </FlexWrapper>

            <FlexWrapper>
              <SelectInput
                name="BodyType"
                onChange={(val) => handleSelectChange(val, 'BodyType')}
                options={bodyTypeOptionsLocal}
                placeholder={selectPlaceholder}
                value={handleSelectValue(bodyTypeOptionsLocal, 'BodyType')}
                error={selectError('BodyType')}
              >
                <LabelName>{t('Body type')} *</LabelName>
              </SelectInput>
              <SelectInput
                name="Style"
                onChange={(val) => handleSelectChange(val, 'Style')}
                options={styleOptionsLocal}
                placeholder={selectPlaceholder}
                value={handleSelectValue(styleOptionsLocal, 'Style')}
                error={selectError('Style')}
              >
                <LabelName>{t('Vehicle style')} *</LabelName>
              </SelectInput>
            </FlexWrapper>

            <FlexWrapper>
              <SelectInput
                name="Color"
                onChange={(val) => handleSelectChange(val, 'Color')}
                options={vehicleColorOptionsLocal}
                placeholder={selectPlaceholder}
                value={handleSelectValue(vehicleColorOptionsLocal, 'Color')}
                error={selectError('Color')}
              >
                <LabelName>{t('Vehicle color')} *</LabelName>
              </SelectInput>
              <SelectInput
                name="Supply"
                onChange={(val) => handleSelectChange(val, 'Supply')}
                options={vehicleSupplyOptionsLocal}
                placeholder={selectPlaceholder}
                value={handleSelectValue(vehicleSupplyOptionsLocal, 'Supply')}
                error={selectError('Supply')}
              >
                <LabelName>{t('Vehicle supply')} *</LabelName>
              </SelectInput>
            </FlexWrapper>

            <FlexWrapper>
              <SelectInput
                name="SteeringPosition"
                onChange={(val) => handleSelectChange(val, 'SteeringPosition')}
                options={steeringPositionOptions}
                placeholder={selectPlaceholder}
                value={handleSelectValue(
                  steeringPositionOptions,
                  'SteeringPosition'
                )}
                error={selectError('SteeringPosition')}
              >
                <LabelName>{t('Steering position')} *</LabelName>
              </SelectInput>
              <div>
                <LabelName>Any alteration modification on vehicle?</LabelName>
                <ToggleInput
                  checked={values.AnyAlterationModificationOnVehicle}
                  name="AnyAlterationModificationOnVehicle"
                  onChange={(val) =>
                    handleChange({
                      name: 'AnyAlterationModificationOnVehicle',
                      value: val.checked ? 1 : 0,
                    })
                  }
                  error={selectError('AnyAlterationModificationOnVehicle')}
                  falseLabel={t('no')}
                  trueLabel={t('yes')}
                />
              </div>
            </FlexWrapper>

            {/* ----------- Engine properties ----------- */}
            <SubTitle>{t('Engine properties')}</SubTitle>
            <FlexWrapper>
              <StyledNumbersInputWithSymbol
                name="EngineCapacity"
                onChange={({ name, value }) =>
                  handleChange({ name, value: parseInt(value) || value })
                }
                value={values.EngineCapacity}
                error={selectError('EngineCapacity')}
                icon="CC"
              >
                <LabelName>{t('Engine capacity')} *</LabelName>
              </StyledNumbersInputWithSymbol>
              <StyledNumbersInputWithSymbol
                name="EngineHP"
                onChange={({ name, value }) =>
                  handleChange({ name, value: parseInt(value) || value })
                }
                value={values.EngineHP}
                error={selectError('EngineHP')}
                icon="HP"
              >
                <LabelName>{t('Engine horsepower')} *</LabelName>
              </StyledNumbersInputWithSymbol>
            </FlexWrapper>

            {/* ----------- Personal information ----------- */}
            <SubTitle>{t('Personal information')}</SubTitle>
            <DateInputV4
              name="SinceWhenDoYouHaveProvenExperienceWithThisTypeOfVehicle"
              onChange={handleChange}
              value={
                values.SinceWhenDoYouHaveProvenExperienceWithThisTypeOfVehicle
              }
              error={selectError(
                'SinceWhenDoYouHaveProvenExperienceWithThisTypeOfVehicle'
              )}
            >
              <LabelName>
                {t('Experienced with this type of vehicle since')} *
              </LabelName>
            </DateInputV4>

            <FlexWrapper>
              <SelectInput
                name="PrimaryUseOfVehicle"
                onChange={(val) =>
                  handleSelectChange(val, 'PrimaryUseOfVehicle')
                }
                options={useOfVehicleOptions}
                placeholder={selectPlaceholder}
                value={handleSelectValue(
                  useOfVehicleOptions,
                  'PrimaryUseOfVehicle'
                )}
                error={selectError('PrimaryUseOfVehicle')}
              >
                <LabelName>{t('Primary use of vehicle')} *</LabelName>
              </SelectInput>
              <div>
                <LabelName>Vehicle used overseas?</LabelName>
                <ToggleInput
                  checked={values.OverseasUse}
                  name="OverseasUse"
                  onChange={(val) =>
                    handleChange({
                      name: 'OverseasUse',
                      value: val.checked ? 1 : 0,
                    })
                  }
                  error={selectError('OverseasUse')}
                  falseLabel={t('no')}
                  trueLabel={t('yes')}
                />
              </div>
            </FlexWrapper>

            <OptionsPriceInput
              name="invoice_value"
              id="invoice_value"
              error={errors.invoice_value}
              onChange={({ value, isVATInclusive }) =>
                handleChange({
                  name: 'invoice_value',
                  value: isVATInclusive ? (value / 1.19).toFixed(2) : value,
                })
              }
              value={values.invoice_value}
            >
              {i18n.t('Insured value')}
            </OptionsPriceInput>
            <CatalogueValueDetail>
              {i18n.t(
                'Carefully check the details on your invoice / order form'
              )}
            </CatalogueValueDetail>

            <FlexWrapper>
              <SelectInput
                name="OwnedFinancedOrLeased"
                onChange={(val) =>
                  handleSelectChange(val, 'OwnedFinancedOrLeased')
                }
                options={ownedFinancedOrLeasedOptionsLocal}
                placeholder={selectPlaceholder}
                value={handleSelectValue(
                  ownedFinancedOrLeasedOptionsLocal,
                  'OwnedFinancedOrLeased'
                )}
                error={selectError('OwnedFinancedOrLeased')}
              >
                <LabelName>{t('Financial status of vehicle')} *</LabelName>
              </SelectInput>

              {jointOwnershipWithRequired.includes(
                values.OwnedFinancedOrLeased
              ) && (
                <TextInput
                  name="JointOwnershipWith"
                  onChange={handleChange}
                  value={values.JointOwnershipWith}
                  error={selectError('JointOwnershipWith')}
                >
                  <LabelName>{t('Joint ownership with')} *</LabelName>
                </TextInput>
              )}
              {bankRequired.includes(values.OwnedFinancedOrLeased) && (
                <SelectInput
                  name="Bank"
                  error={selectError('Bank')}
                  value={handleSelectValue(bankOptions, 'Bank')}
                  onChange={(val) => handleSelectChange(val, 'Bank')}
                  options={bankOptions}
                  placeholder="Choose an option"
                >
                  <LabelName>
                    {i18n.t('Select the financial institution')} *
                  </LabelName>
                </SelectInput>
              )}
            </FlexWrapper>
          </Scroll>
        )}

        <ButtonContainer>
          <Error errorMessage={errorMessage}>{errorMessage}</Error>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              disabled={isFetchingFailed || isFetching}
              type="submit"
              data-test-id="manual_vehicle_flow_submit"
            >
              {t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </VehicleSearchContainer>
    </>
  );
};

const RangeWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 6rem;
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  & div {
    margin-bottom: 0;
  }
`;

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-bottom: 1rem;
  margin-top: 3.2rem;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 1.65rem;
`;

const StyledNumbersInputWithSymbol = styled(NumbersInputWithSymbol)`
  & span {
    top: 3.2rem;
  }
`;

const Error = styled.p`
  opacity: ${({ errorMessage }) => (errorMessage ? '1' : '0')};
  color: #f74040;
`;

const LabelName = styled.p`
  color: #8990a3;
  line-height: 120%;
  font-size: 1.4rem;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4;
  }

  @media screen and (max-width: 1135px) {
    display: block;
    & > div {
      width: unset;
    }
  }
`;

const CatalogueValueDetail = styled.p`
  color: grey;
  font-size: 1.3rem;
  margin: -2rem 0 2rem 0.5rem;
`;

const Scroll = styled.div`
  padding-right: 1rem;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 15rem;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const VehicleSearchContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

export default VehicleLookupComponent;
