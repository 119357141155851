export default [
  {
    title: 'user-address',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'select-insurances',
    },
  },
  {
    title: 'second-risk-address',
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: 'home-information',
    },
  },
  {
    title: 'select-insurances',
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: 'risk-address',
      2: 'car-already-registered',
      3: 'health-information',
    },
  },
  {
    title: 'risk-address',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'home-information',
    },
  },
  {
    title: 'home-information',
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: 'home-information-extra',
    },
  },
  {
    title: 'home-information-extra',
    step: 2,
    subStep: 2,
    nextRoutes: {
      1: 'building-information',
    },
  },
  {
    title: 'building-information',
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: 'property-insurance-policy',
    },
  },
  {
    title: 'property-insurance-policy',
    step: 2,
    subStep: 4,
    nextRoutes: {
      1: 'property-insurance-policy-information',
      2: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-policy-information',
    step: 2,
    subStep: 5,
    nextRoutes: {
      1: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-extras',
    step: 2,
    subStep: 6,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-bike',
      3: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'property-insurance-extras-pets',
    step: 2,
    subStep: 8,
    nextRoutes: {
      1: 'home-specifics',
    },
  },
  {
    title: 'property-insurance-extras-bike',
    step: 2,
    subStep: 7,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'home-specifics',
    step: 2,
    subStep: 8,
    nextRoutes: {
      1: 'home-high-risk',
    },
  },
  {
    title: 'home-high-risk',
    step: 2,
    subStep: 9,
    nextRoutes: {
      1: 'insurance-options-home',
    },
  },
  {
    title: 'insurance-options-home',
    step: 2,
    subStep: 10,
    nextRoutes: {
      1: 'home-premium-summary',
    },
  },
  {
    title: 'home-premium-summary',
    step: 2,
    subStep: 11,
    nextRoutes: {
      1: 'second-risk-address',
      2: 'car-already-registered',
      3: 'health-information',
      4: 'overview',
    },
  },

  {
    title: 'car-already-registered',
    step: 3,
    subStep: 0,
    nextRoutes: {
      1: 'car-information',
    },
  },
  {
    title: 'car-information',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'replacement-vehicle',
    },
  },
  {
    title: 'replacement-vehicle',
    step: 3,
    subStep: 3,
    nextRoutes: {
      1: 'vehicle-purpose',
    },
  },
  {
    title: 'vehicle-purpose',
    step: 3,
    subStep: 4,
    nextRoutes: {
      1: 'drivers-information',
    },
  },
  {
    title: 'drivers-information',
    step: 3,
    subStep: 6,
    nextRoutes: {
      1: 'additional-drivers',
    },
  },
  {
    title: 'drivers-information-extra',
    step: 3,
    subStep: 7,
    nextRoutes: {
      1: 'additional-drivers',
    },
  },
  {
    title: 'additional-drivers',
    step: 3,
    subStep: 8,
    nextRoutes: {
      1: 'vehicle-insurance-policy',
    },
  },
  {
    title: 'vehicle-insurance-policy',
    step: 3,
    subStep: 9,
    nextRoutes: {
      1: 'vehicle-insurance-policy-information',
      2: 'vehicle-specifics',
    },
  },
  {
    title: 'vehicle-insurance-policy-information',
    step: 3,
    subStep: 10,
    nextRoutes: {
      1: 'vehicle-specifics',
    },
  },
  {
    title: 'vehicle-specifics',
    step: 3,
    subStep: 11,
    nextRoutes: {
      1: 'insurance-options-car',
    },
  },
  {
    title: 'insurance-options-car',
    step: 3,
    subStep: 12,
    nextRoutes: {
      1: 'car-premium-summary',
      2: 'price-comparison',
    },
  },
  {
    title: 'price-comparison',
    step: 3,
    subStep: 13,
    nextRoutes: {
      1: 'car-premium-summary',
    },
  },
  {
    title: 'car-premium-summary',
    step: 3,
    subStep: 14,
    nextRoutes: {
      1: 'car-already-registered',
      2: 'health-information',
      3: 'overview',
    },
  },
  {
    title: 'health-information',
    step: 4,
    subStep: 0,
    nextRoutes: {
      1: 'doctor-information',
      2: 'blood-information',
      3: 'hba1c-test-information',
    },
  },
  {
    title: 'blood-information',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'doctor-information',
      2: 'hba1c-test-information',
    },
  },
  {
    title: 'hba1c-test-information',
    step: 4,
    subStep: 2,
    nextRoutes: {
      1: 'doctor-information',
    },
  },
  {
    title: 'doctor-information',
    step: 4,
    subStep: 3,
    nextRoutes: {
      1: 'parental-information',
    },
  },
  {
    title: 'parental-information',
    step: 4,
    subStep: 4,
    nextRoutes: {
      1: 'health-insurance-policy',
    },
  },
  {
    title: 'health-insurance-policy',
    step: 4,
    subStep: 5,
    nextRoutes: {
      1: 'health-insurance-policy-information',
      2: 'general-information',
      3: 'health-specifics',
    },
  },
  {
    title: 'health-insurance-policy-information',
    step: 4,
    subStep: 6,
    nextRoutes: {
      1: 'general-information',
    },
  },
  {
    title: 'general-information',
    step: 4,
    subStep: 7,
    nextRoutes: {
      1: 'health-specifics',
    },
  },
  {
    title: 'health-specifics',
    step: 4,
    subStep: 8,
    nextRoutes: {
      1: 'insurance-options-health',
    },
  },
  {
    title: 'insurance-options-health',
    step: 4,
    subStep: 9,
    nextRoutes: {
      1: 'health-premium-summary',
    },
  },
  {
    title: 'health-premium-summary',
    step: 4,
    subStep: 10,
    nextRoutes: {
      1: 'health-information',
      2: 'overview',
    },
  },
  {
    title: 'overview',
    step: 5,
    subStep: 0,
    nextRoutes: {
      1: 'thankyou',
    },
  },
  {
    title: 'thankyou',
    step: 5,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];
