import React, { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useBreakPoint() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const [debounce] = useDebouncedCallback(handleWindowResize, 100);
  useEffect(() => {
    window.addEventListener('resize', debounce);
    return () => window.removeEventListener('resize', debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return the width so we can use it in our components
  return { width, height };
}
