import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { ActionButton, Label, IconActionChevronLeft } from 'wg-fe-ui';
import * as Yup from 'yup';
import DateInput from '../components/DateInput';
import { ChatContext } from '../contexts/ChatContext';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import FlowChatBox from '../components/FlowChatBox';
import { date } from '../constants/validationSchemas';
import useForm from '../hooks/useForm';

const FlowDriversInformationPolicy = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { addQuestion } = useContext(ChatContext);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    PolicyStartDate: date,
    PolicyEndDate: date,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema,
  });

  function onSubmit(payload) {
    patchStorage({
      payload: payload,
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { vehicle: currentVehicle });
  }

  useEffect(() => {
    const question = {
      message: i18n.t(`What is the start and end date of your current Policy?`),
      slug: 'policy_information',
    };
    const { PolicyStartDate, PolicyEndDate } = data?.cars[currentVehicle];
    if (PolicyStartDate)
      handleChange({ name: 'PolicyStartDate', value: PolicyStartDate });
    if (PolicyEndDate)
      handleChange({ name: 'PolicyEndDate', value: PolicyEndDate });
    addQuestion(question);
  }, []);

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <TitleForm>
          {i18n.t('Fill in the start date & end date of your current policy')}
        </TitleForm>
        <div>
          <StyledLabel>{i18n.t('Starting date')} *</StyledLabel>
          <DateInput
            name="PolicyStartDate"
            error={errors.PolicyStartDate}
            onChange={handleChange}
            value={values.PolicyStartDate}
          />
        </div>
        <div>
          <StyledLabel>{i18n.t('Ending date')} *</StyledLabel>
          <DateInput
            name="PolicyEndDate"
            error={errors.PolicyEndDate}
            onChange={handleChange}
            value={values.PolicyEndDate}
          />
        </div>
        <ButtonContainer>
          <Required>* {i18n.t('Required fields')}</Required>
          <div>
            <BackButton
              type="button"
              name="back"
              onClick={() => history.goBack()}
            >
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => handleSubmit(onSubmit)}
              type="submit"
              value="Submit"
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;

  > div {
    margin-top: 1.6rem;
  }

  @media (max-width: 680px) {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2rem;

    form {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2.8rem;
  flex-shrink: 0;
  margin-bottom: 4rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.brand.primary};

  @media (max-width: 680px) {
    font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-size: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }

  @media (max-width: 680px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & button {
      height: 4rem;
      width: 100%;
      &:focus {
        outline: 0;
      }
    }
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  align-self: center;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;
const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

export default FlowDriversInformationPolicy;
