import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById } from '../helpers/storeService.js';
import FlowManualVehicleFormRegistered from '../components/FlowManualVehicleFormRegistered.jsx';
import VehicleLookupComponent from '../components/VehicleLookupComponent.jsx';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowCarInformation = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const { addQuestion } = useContext(ChatContext);

  useEffect(() => {
    loadDefaultValues();
    const question = {
      message: i18n.t(
        'Is this <span>information correct</span>? If not, you can adjust the data on the right side'
      ),
      slug: 'car_information',
    };
    addQuestion(question);
  }, []);

  const loadDefaultValues = async () => {
    if (cars[currentVehicle] != null) {
      setDefaultValues(cars[currentVehicle]);
    }
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        {cars[currentVehicle]?.car_already_registered ? (
          <>
            <TitleForm>
              {i18n.t('Is this information correct?')}
              <span>{i18n.t('(Change the info by clicking on it)')}</span>
            </TitleForm>
            <FlowManualVehicleFormRegistered
              renderNextRoute={renderNextRoute}
              existingVehicle={
                data?.cars?.[currentVehicle]?.car_already_registered
              }
              data={data?.cars?.[currentVehicle]?.vehicle_info}
              defaultValues={defaultValues?.vehicle_info}
            />
          </>
        ) : (
          <>
            <TitleForm>{i18n.t('Information about your vehicle')}</TitleForm>
            <VehicleLookupComponent storageData={data} />
          </>
        )}
      </RightContainer>
    </Container>
  );
};

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 120%;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.brand.primary};
  span {
    color: ${({ theme }) => theme.brand.secondary};
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowCarInformation;
