import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

const FlowProgressBarStep = ({ icon, percent, active }) => {
  const { insuranceType } = useParams();
  const mobile =
    insuranceType === 'allMobile' ||
    insuranceType === 'carMobile' ||
    insuranceType === 'homeMobile';

  return (
    <>
      <ProgressStep>
        <StepIcon alt={icon.key}>{icon}</StepIcon>
        {mobile ? (
          ''
        ) : (
          <MiniProgressBar>
            <Progress percent={percent} />
          </MiniProgressBar>
        )}
      </ProgressStep>
    </>
  );
};

const StepIcon = styled.div`
  > svg {
    max-width: 4rem;
    max-height: 4rem;
  }

  @media screen and (max-width: 1000px) {
    > svg {
      max-width: 2.4rem;
      max-height: 2.4rem;
    }
  }

  margin: 0 1rem;
`;

const ProgressStep = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    width: 50%;

    & > div:last-child {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    transform: scale(0.95);
  }
`;

const Progress = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  border-radius: 1rem;
  transition: width 0.6s ease;
  will-change: width;
  width: ${(props) => props.percent}%;
  height: 7px;
`;

const MiniProgressBar = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  border-radius: 1rem;
  width: 70%;
  height: 0.7rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    width: 45%;
  }
`;

export default FlowProgressBarStep;
