export function initializeStorage(id, affinity, insuranceType) {
  const storedData =
    JSON.parse(
      sessionStorage.getItem(`storage_${affinity}_${insuranceType}`)
    ) || [];

  storedData.push({ id, data: {} });

  sessionStorage.setItem(
    `storage_${affinity}_${insuranceType}`,
    JSON.stringify(storedData)
  );
  return [...storedData].pop();
}

export function retrieveStorage(affinity, insuranceType) {
  const storedData =
    JSON.parse(
      sessionStorage.getItem(`storage_${affinity}_${insuranceType}`)
    ) || [];

  return [...storedData].pop();
}

export function clearStorage(affinity, insuranceType) {
  const storedData = [];
  sessionStorage.setItem(
    `storage_${affinity}_${insuranceType}`,
    JSON.stringify(storedData)
  );

  return [...storedData].pop();
}

function setDeep(obj, path, value, setrecursively = true) {
  let level = 0;
  path.reduce((a, b) => {
    level++;
    if (
      setrecursively &&
      typeof a[b] === 'undefined' &&
      level !== path.length
    ) {
      a[b] = {};
      return a[b];
    }
    if (level === path.length) {
      if (typeof a[b] === 'undefined' || !a[b]) {
        a[b] = {};
      }
      Object.assign(a[b], value);
      return value;
    } else {
      return a[b];
    }
  }, obj);
}

export function patchStorage({ payload, path, id, affinity, insuranceType }) {
  const storedData =
    JSON.parse(
      sessionStorage.getItem(`storage_${affinity}_${insuranceType}`)
    ) || [];
  const index = storedData.findIndex((x) => x.id === id) || 0;
  if (path) {
    setDeep(storedData[index].data, path, payload, true);
  } else {
    Object.assign(storedData?.[index].data, payload);
  }
  sessionStorage.setItem(
    `storage_${affinity}_${insuranceType}`,
    JSON.stringify(storedData)
  );
}

export function retrieveStorageById(id, affinity, insuranceType) {
  const storedData =
    JSON.parse(
      sessionStorage.getItem(`storage_${affinity}_${insuranceType}`)
    ) || [];
  return storedData.filter((x) => x.id === id)[0];
}

export function retrieveStorageInfo(affinity, insuranceType) {
  const storedData =
    JSON.parse(
      sessionStorage.getItem(`storage_${affinity}_${insuranceType}`)
    ) || [];
  return storedData;
}
