import React from 'react';
import styled from 'styled-components';
import CarPremGanIcon from '../assets/icons/CarPremGanIcon';
import CarBasicGanIcon from '../assets/icons/CarBasicGanIcon';
import CarValueGanIcon from '../assets/icons/CarValueGanIcon';
import CarEcoGan from '../assets/icons/CarEcoGan';
import HomeBasicGanIcon from '../assets/icons/HomeBasicGanIcon.jsx';
import HomeEcoGanIcon from '../assets/icons/HomeEcoGanIcon.jsx';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon.jsx';
import HomePremGanIcon from '../assets/icons/HomePremGanIcon.jsx';
import HelEcoGanIcon from '../assets/icons/HelEcoGanIcon';
import HelPremiumGanIcon from '../assets/icons/HelPremiumGanIcon';
import HelValueGanIcon from '../assets/icons/HelValueGanIcon';

function iconType(icon: string, insuranceType: string) {
  switch (insuranceType) {
    case 'cars':
      switch (icon) {
        case 'Basic Plus': //terraced
          return <CarBasicGanIcon />;
        case 'Economic': //semi-detached
          return <CarEcoGan />;
        case 'Value':
          return <CarValueGanIcon />;
        case 'Premium':
          return <CarPremGanIcon />;
        default:
          return <CarValueGanIcon />;
      }
    case 'risk_addresses':
      switch (icon) {
        case 'Basic Plus': //terraced
          return <HomeBasicGanIcon />;
        case 'Economic': //semi-detached
          return <HomeEcoGanIcon />;
        case 'Value':
          return <HomeCatGanIcon />;
        case 'Premium':
          return <HomePremGanIcon />;
        default:
          return <HomeBasicGanIcon />;
      }
    case 'healths':
      switch (icon) {
        case 'Economy': //semi-detached
          return <HelEcoGanIcon />;
        case 'Value':
          return <HelValueGanIcon />;
        case 'Premium':
          return <HelPremiumGanIcon />;
        default:
          return <HelEcoGanIcon />;
      }
  }
}

interface Props {
  type: string;
  insurance_pack: string;
}

const FlowOverviewPolicyDetailHeaderIcon: React.FC<Props> = ({
  type,
  insurance_pack,
}) => {
  return <ProductIcon>{iconType(insurance_pack, type)}</ProductIcon>;
};

export default FlowOverviewPolicyDetailHeaderIcon;

const ProductIcon = styled.div`
  & svg {
    max-height: 4rem;
  }
`;
