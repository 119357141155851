import React, { useState } from 'react';
import styled from 'styled-components';
import { string, node, func, object } from 'prop-types';

const Tooltip = ({
  className,
  toggleIcon,
  children,
  onClick,
  display,
  ...otherProps
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <TooltipWrapper
      onMouseLeave={() => setIsActive(false)}
      className={className}
      onClick={onClick}
      {...otherProps}
    >
      <TooltipToggle
        onMouseMove={() => setIsActive(true)}
        displayToggle={display === 'none'}
      >
        {toggleIcon}
      </TooltipToggle>
      {display === 'none' ? null : (
        <TooltipContent active={isActive}>{children}</TooltipContent>
      )}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  outline: none;
  appearance: none;
`;

const TooltipToggle = styled.a`
  display: flex;
  z-index: 10;
  width: ${({ displayToggle }) => (displayToggle ? '1.5rem' : '2rem')};
  height: ${({ displayToggle }) => (displayToggle ? '1.5rem' : '2rem')};
  border-radius: 9999px;
  border: 1.5px solid #8990a3;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ displayToggle }) => (displayToggle ? '1rem' : '1.2rem')};
  line-height: 1.5rem;
  color: #8990a3;

  &:hover {
    color: ${({ theme }) => theme.brand.primary};
    border: 1.5px solid ${({ theme }) => theme.brand.primary};
  }
`;

const TooltipContent = styled.div`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  position: absolute;
  left: unset;
  opacity: ${({ active }) => (active ? '1' : '0')};
  top: 100%;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #11141c;
  width: 400px;
  border-radius: 5px;
  transform: ${({ active }) =>
    active ? 'translate(-50%, 0)' : 'translate(-50%, 1em)'};
  font-family: ${({ theme }) => theme.font};
  transition: all 0.2s ease;
  font-size: 14px;
  line-height: 1.8rem;
  color: white;
  z-index: 9000000;
`;

Tooltip.defaultProps = {
  toggleIcon: '?',
  children: 'Please provide text to be shown inside the tooltip.',
};

Tooltip.propTypes = {
  /** Extra class to be passed to the component. */
  className: string,
  /** Icon to be shown inside the toggle. (String) */
  toggleIcon: string.isRequired,
  /** Content to be shown inside the toggle.  */
  children: node.isRequired,
  /** Optional onClick function to be passed. */
  onClick: func,
  /** Extra props to be passed to the component. */
  otherProps: object,
};

export default Tooltip;
