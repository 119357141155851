import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
  packages: {},
};

export const storeReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_PACKAGE':
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.payload.type]: {
            ...(state?.packages?.[action.payload.type] || {}),
            [action.payload.current]: {
              ...(state?.packages?.[action.payload.type]?.[
                action?.payload?.current
              ] || {}),
              [action.payload.selected]: {
                ...action?.payload?.data,
              },
            },
          },
        },
      };
    case 'EDIT_PACKAGE':
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.payload.type]: {
            ...state?.packages?.[action.payload.type],
            [action.payload.current]: {
              ...state?.packages?.[action.payload.type]?.[
                action?.payload?.current
              ],
              [action.payload.selected]: {
                ...state?.packages?.[action.payload.type]?.[
                  action?.payload?.current
                ]?.[action.payload.selected],
                ...action?.payload?.data,
              },
            },
          },
        },
      };
    case 'RESET':
      return { ...initialState };
    case 'FILL_STORAGE_DATA':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const PremiumSummaryContext = createContext(initialState);

const PremiumSummaryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const addPackage = async (payload) => {
    dispatch({
      type: 'ADD_PACKAGE',
      payload,
    });
  };

  const editPackage = async (payload) => {
    dispatch({
      type: 'EDIT_PACKAGE',
      payload,
    });
  };

  return (
    <PremiumSummaryContext.Provider
      value={[
        {
          state,
          addPackage,
          editPackage,
        },
        dispatch,
      ]}
    >
      {children}
    </PremiumSummaryContext.Provider>
  );
};

function usePremiumSummaryStore() {
  const context = useContext(PremiumSummaryContext);
  if (context === undefined) {
    throw new Error(
      'usePremiumSummaryStore must be used within a PremiumSummaryContextProvider'
    );
  }
  return context;
}

export { PremiumSummaryContextProvider, usePremiumSummaryStore };
