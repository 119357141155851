/* stylelint-disable value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Drawer from 'react-drag-drawer';
import { string, bool, func } from 'prop-types';

const InfoPopup = ({
  info,
  title,
  buttonText,
  isLink,
  onClick,
  className,
  inverted,
}) => {
  const [togglen, setToggle] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setToggle(false);
      }
    });
  }, []);

  const toggleDrawer = (closing) => {
    setToggle(!togglen);
    onClick(closing);
  };

  return togglen ? (
    <>
      <InfoButton className={className} isLink={isLink}>
        {buttonText}
      </InfoButton>
      <Drawer open={true} onRequestClose={() => toggleDrawer(true)}>
        <InfoContainer>
          <CloseButton onClick={() => toggleDrawer(true)}>
            {' '}
            &times;{' '}
          </CloseButton>
          <InfoBlock>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: info }} />
          </InfoBlock>
        </InfoContainer>
      </Drawer>
    </>
  ) : (
    <InfoButton
      className={className}
      isLink={isLink}
      onClick={() => toggleDrawer(false)}
      inverted={inverted}
    >
      {buttonText}
    </InfoButton>
  );
};

const InfoContainer = styled.div`
  color: white;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 66rem;
  overflow: auto;
  z-index: 9999;
  @media (max-width: 700px) {
    width: 45rem;
  }
  @media (max-width: 470px) {
    width: 35rem;
  }
`;

const InfoBlock = styled.div`
  padding: 5rem;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font};

  & h2 {
    font-size: 1.6rem;
    color: black;
    margin-bottom: 2rem;
    font-weight: 900;

    ::first-letter {
      text-transform: capitalize;
    }
  }

  & p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.typo.subTitle};
  }

  & h3 {
    font-weight: bold;
  }

  & span {
    font-weight: bold;
  }
`;

const InfoButton = styled.a`
  font-size: 1.7rem;
  color: ${({ theme, isLink, inverted }) =>
    isLink ? theme.brand.secondary : inverted ? theme.brand.primary : 'white'};
  display: inline-block;
  background-color: ${({ theme, inverted }) =>
    inverted ? 'white' : theme.brand.primary};
  width: 2.6rem;
  height: 2.6rem;
  text-align: center;
  font-weight: 900;
  border-radius: 50%;
  margin-left: 1rem;
  transition: background-color 0.1s ease;
  border: none;
  padding: 0.15rem;
  line-height: 1.3;
  vertical-align: middle;

  ${(props) =>
    props.isLink
      ? `
    width: auto;
    height: auto;
    font-weight: bold;
    font-size: 1.4rem;
    background: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    margin-top: -.3rem;
    line-height: auto;
  `
      : null}

  &:hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    cursor: pointer;
    color: white;
    ${(props) =>
      props.isLink
        ? `
    background: none;
  `
        : null}
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: black;
  font-weight: 900;
  font-size: 20px;
  font-family: Arial Unicode MS;
  position: absolute;
  right: 0;
  padding: 1.5rem;
`;

InfoPopup.defaultProps = {
  info: '',
  title: '',
  buttonText: '?',
  onClick: () => {},
  isLink: false,
  inverted: false,
};

InfoPopup.propTypes = {
  info: string,
  title: string,
  buttonText: string,
  isLink: bool,
  onClick: func,
  className: string,
};

export default InfoPopup;
