import {
  ganDirectCar,
  ganDirectHome,
  ganDirectHealth,
  ganDirectCarMobile,
  ganDirectAll,
  ganDirectAllMobile,
  ganDirectHomeMobile,
} from '../constants/routingDataGanDirect';
import { getNestedObject } from './objectService';

const flowObject = {
  'gan-direct': {
    car: ganDirectCar,
    home: ganDirectHome,
    health: ganDirectHealth,
    all: ganDirectAll,
    allMobile: ganDirectAllMobile,
    carMobile: ganDirectCarMobile,
    healthMoble: ganDirectHealth,
    homeMobile: ganDirectHomeMobile,
  },
};

let brandData = {};

export const getAffinityFlow = ({ affinity, insuranceType }) => {
  brandData =
    getNestedObject(flowObject, [affinity, insuranceType, 'brand']) || {};
  return getNestedObject(flowObject, [affinity, insuranceType, 'flow']) || [];
};

export const getBrandData = () => brandData;

export const doesFlowExist = ({ affinity, insuranceType }) =>
  getNestedObject(flowObject, [affinity, insuranceType, 'flow']) ? true : false;
