/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InfoPopup from './InfoPopup';
import i18n from '../i18n';

const RoomData = ({ value, handleCountChange, name }) => {
  const [count, setCount] = useState(value);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const text = {
    living_rooms:
      'Two spaces should be counted if the living room is larger than 36m²',
    garages:
      'Number is counted on the basis of the number of pitches for passenger cars',
    other_rooms:
      'Desks, play or hobby rooms, wellness areas, dressing rooms, verandas, laundry or storage areas',
    storage_rooms:
      'Small rooms are defined as those with an area of less than 5 m²',
  };

  const roomNames = {
    living_rooms: 'Living rooms',
    kitchens: 'Kitchens',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    garages: 'Garages',
    other_rooms: 'Other rooms',
    storage_rooms: 'Small rooms',
    porch: 'Porch',
  };

  const handleClick = (change) => {
    if (name === 'porch' && count === 1 && change === '+') {
      return;
    } else {
      switch (change) {
        case '-':
          if (count > 0) {
            setCount(count - 1);
          }
          break;
        case '+':
          setCount(count + 1);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    handleCountChange(name, count);
  }, [count]);

  return (
    <StyledDiv>
      <StyledSrc>
        <TitleContainer>
          <p className="count">{i18n.t(roomNames[name])}</p>
          {text[name] !== undefined ? (
            <StyledPopup
              title={i18n.t(roomNames[name])}
              info={i18n.t(text[name])}
            />
          ) : (
            ''
          )}
        </TitleContainer>
        <Counter>
          <p className="minus" onClick={() => handleClick('-')}>
            -
          </p>
          {count}
          <p className="plus" onClick={() => handleClick('+')}>
            +
          </p>
        </Counter>
      </StyledSrc>
    </StyledDiv>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: center;
`;

const StyledPopup = styled(InfoPopup)`
  background-color: #114c9a;
  margin-left: 1rem !important;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Counter = styled.div`
  align-items: center;
  color: #114c9a;
  display: flex;
  font-size: 2rem;
  width: 50%;
  justify-content: space-between;
  p {
    width: 3rem;
    height: 3rem;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    font-size: 2.5rem;
    background-color: #114c9a;
    .minus {
      color: ${({ count }) => (count > 0 ? '#2a2a2a' : '#D3D4D8')};
    }
  }
`;

const StyledDiv = styled.div`
  .count {
    white-space: nowrap;
    color: black;
    font-weight: 700;
  }
`;

const StyledSrc = styled.div`
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  display: flex;
  justify-content: space-evenly;

  p {
    grid-column: 1/4;
    grid-row-start: 4;
    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;

export default RoomData;
