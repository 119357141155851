import React, { useEffect, useState } from 'react';
import { LoadingSpinner, ActionButton } from 'wg-fe-ui';
import styled from 'styled-components';
import { useParams } from 'react-router';
import FlowPriceCalculatorOptions from '../components/FlowPriceCalculatorOptions';
import InfoPopup from '../components/InfoPopup';

import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { parseIncDateToObject } from '../helpers/dateService';
import { getCarObject } from '../helpers/riskObjectService';
import i18n from '../i18n';
import { retrieveCarPrice } from '../helpers/apiRouterService';
import { differenceInCalendarYears, format } from 'date-fns';
import { getNestedObject } from '../helpers/objectService';

const FlowPriceCalculator = () => {
  const { id, affinity, insuranceType } = useParams();
  const storageData = retrieveStorageById(id, affinity, insuranceType);

  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const [carAge, setCarAge] = useState(0);
  const [omniumPrice, setOmniumPrice] = useState('');
  const [miniOmniumPrice, setMiniOmniumPrice] = useState('');
  const [civilLiabilityPrice, setCivilLiabilityPrice] = useState('');
  const [quoteId, setQuoteId] = useState(null);
  const [subPrices, setSubPrices] = useState({});
  const [totalPremium, setTotalPremium] = useState('');
  const { renderNextRoute } = useRouting();
  const [disableOmnium, setDisableOmnium] = useState(false);
  const [disableMiniOmnium, setDisableMiniOmnium] = useState(false);

  async function setOptionsPrices() {
    const response = await callCarPrice(
      'option=driver&option=legal&option=assistance_vehicle',
      selectedType
    );
    setSubPrices(response);
  }

  function handleSubmit() {
    patchStorage({
      payload: { price_information: { quoteId } },
      id,
      affinity,
      insuranceType,
    });
    if (storageData.data.vehicleType === '6WHEELS') {
      if (
        storageData.data.vehicleType === 'LIGHT_SCOOTER' ||
        storageData.data.bikeType === 'LIGHT_SCOOTER'
      ) {
        return renderNextRoute(3);
      } else {
        return renderNextRoute(2);
      }
    } else if (
      storageData.data.vehicleType === 'MOTO_ALREADY_INSURED' ||
      storageData.data.vehicleType === '6WHEELS' ||
      storageData.data.vehicleType === 'MOTOR_COMPANY_CAR' ||
      storageData.data.vehicleType === 'CAR_ALREADY_INSURED'
    ) {
      return renderNextRoute(1);
    } else {
      return renderNextRoute();
    }
  }

  useEffect(() => {
    calculateCarAge();
    calculateBasePrices();
  }, []);

  useEffect(() => {
    setOptionsPrices();
  }, [selectedType]);

  async function calculateBasePrices() {
    const omniumResp = await callCarPrice('option=legal', 'omnium');
    const miniOmniumResp = await callCarPrice('option=legal', 'mini_omnium');
    const civilLiabilityResp = await callCarPrice(
      'option=legal',
      'civil_liability'
    );

    setOmniumPrice(
      getNestedObject(omniumResp, ['premium', 'total_premium']) + 40
    );
    setMiniOmniumPrice(
      getNestedObject(miniOmniumResp, ['premium', 'total_premium']) + 40
    );
    setCivilLiabilityPrice(
      getNestedObject(civilLiabilityResp, ['premium', 'total_premium']) + 40
    );
    await setLoading(false);
  }

  function calculateCarAge() {
    const { data } = storageData || {};
    const { model_year } = data || {};
    const carAge = differenceInCalendarYears(
      new Date(),
      new Date(model_year, 0, 1)
    );

    setCarAge(carAge);
  }
  useEffect(() => {
    if (carAge >= 8) {
      setDisableOmnium(true);
      setDisableMiniOmnium(true);
      setSelectedType('civil_liability');
    } else if (4 >= carAge && carAge <= 8) {
      setSelectedType('mini_omnium');
      if (carAge >= 5) {
        setDisableOmnium(true);
      }
    } else if (carAge >= 5) {
      setDisableOmnium(true);
    }
  }, [carAge]);

  async function callCarPrice(query = '', insurance) {
    const { data } = storageData || {};

    const currentDateObj = parseIncDateToObject(
      format(new Date(), 'dd/MM/yyyy')
    );
    data.currentDateObj = currentDateObj;

    const payload = {
      car: getCarObject(data),
      quote_specifications: {
        currency: 'EUR',
        payment_interval: 'annual',
        save_quote: true,
      },
    };
    const [resp, status] = await retrieveCarPrice(
      payload,
      insuranceType === '6-wheeler' ? 'callant' : affinity,
      insuranceType === '6-wheeler' ? 'car' : insuranceType,
      insurance,
      query
    );

    if ((await status) === 200);
    else return {};

    return resp;
  }

  async function retrievePrice(queryString) {
    const { premium, id } =
      (await callCarPrice(queryString, selectedType)) || {};

    const { total_premium } = premium || {};
    setTotalPremium(total_premium);
    setQuoteId(id);
  }

  var myObj = {
    style: 'currency',
    currency: 'EUR',
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t('Please wait')}</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <PriceCalculatorContainer>
        <StyledDiv>
          <Label
            for="omnium"
            disable={disableOmnium}
            checked={selectedType === 'omnium' && !disableOmnium}
          >
            <RadioInput
              type="radio"
              name="price"
              value="omnium"
              id="omnium"
              onClick={() => {
                if (!disableOmnium) {
                  setSelectedType('omnium');
                } else {
                  return;
                }
              }}
              checked={selectedType === 'omnium'}
            />
            {disableOmnium ? (
              <InfoPopup
                title={i18n.t('Vehicle age not qualified')}
                info={i18n.t(
                  "A vehicle older than 5 years doesn't qualify for this option"
                )}
              ></InfoPopup>
            ) : (
              ''
            )}
            <TitleLabel>{i18n.t(`Omnium + BA + Legal aid`)}</TitleLabel>
            <PriceLabel>
              {!omniumPrice ? (
                <Error>{i18n.t("Couldn't calculate price")}</Error>
              ) : (
                `${i18n.t(`From`)} ${parseFloat(omniumPrice).toLocaleString(
                  'nl-BE',
                  myObj
                )}`
              )}
            </PriceLabel>
            {carAge < 4 && omniumPrice ? (
              <span>{i18n.t(`Recommended`)}</span>
            ) : null}
          </Label>
          <Label
            for="mini_omnium"
            disable={disableMiniOmnium}
            checked={selectedType === 'mini_omnium' && !disableMiniOmnium}
          >
            <RadioInput
              type="radio"
              name="price"
              value="mini_omnium"
              id="mini_omnium"
              onClick={() => {
                if (!disableMiniOmnium) {
                  setSelectedType('mini_omnium');
                } else {
                  return;
                }
              }}
              checked={selectedType === 'mini_omnium'}
            />
            {disableMiniOmnium ? (
              <InfoPopup
                title={i18n.t('Vehicle age not qualified')}
                info={i18n.t(
                  "A vehicle older than 8 years doesn't qualify for this option"
                )}
              ></InfoPopup>
            ) : (
              ''
            )}
            <TitleLabel>{i18n.t(`Mini Omnium + BA + Legal aid`)}</TitleLabel>
            <PriceLabel>
              {i18n.t(`From`)}{' '}
              {parseFloat(miniOmniumPrice).toLocaleString('nl-BE', myObj)}
            </PriceLabel>
            {4 <= carAge && carAge <= 8 && miniOmniumPrice ? (
              <span>{i18n.t(`Recommended`)}</span>
            ) : null}
          </Label>
          <Label
            for="civil_liability"
            checked={selectedType === 'civil_liability'}
          >
            <RadioInput
              type="radio"
              name="price"
              value="civil_liability"
              id="civil_liability"
              onClick={() => setSelectedType('civil_liability')}
              checked={selectedType === 'civil_liability'}
            />
            <TitleLabel>{i18n.t(`BA + Legal aid`)}</TitleLabel>
            <PriceLabel>
              {i18n.t(`From`)}{' '}
              {parseFloat(civilLiabilityPrice).toLocaleString('nl-BE', myObj)}
            </PriceLabel>
            {carAge > 8 ? <span>{i18n.t(`Recommended`)}</span> : null}
          </Label>
        </StyledDiv>
        <div>
          <FlowPriceCalculatorOptions
            carAge={carAge}
            subPrices={subPrices}
            onChange={(queryString) => retrievePrice(queryString)}
            selectedType={selectedType}
          />
        </div>
      </PriceCalculatorContainer>
      <CheckoutContainer>
        <TotalPrice>
          {i18n.t(`Total price`)}{' '}
          <span>{parseFloat(totalPremium).toLocaleString('nl-BE', myObj)}</span>
        </TotalPrice>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          {i18n.t(`Next`)}
        </Button>
      </CheckoutContainer>
    </SplitContainer>
  );
};

const Error = styled.p``;

const CheckoutContainer = styled.div`
  display: flex;
`;

const TitleLabel = styled.p``;

const TotalPrice = styled.p`
  font-size: 2rem;
  align-self: center;
  > span {
    font-weight: 700;
  }
`;

const PriceLabel = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  padding: 1rem;
  transition: 0.2s;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  border: 2px solid
    ${({ checked, theme }) => (checked ? theme.brand.primary : 'black')};
  text-align: center;
  background-color: ${({ checked, theme, disable }) =>
    disable ? '#CCCC' : checked ? theme.brand.lightest : 'white'};
  color: ${({ checked, theme }) => (checked ? theme.brand.primary : 'black')};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: auto;
  line-height: 3rem;
  font-weight: 700;
  font-size: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  > span {
    font-size: 1.4rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
    padding: 0.2rem 2rem;
    font-weight: 600;
    border-radius: 2rem;
  }
  a {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
  }
`;

const RadioInput = styled.input`
  display: none;
`;

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
`;

const PriceCalculatorContainer = styled.div`
  display: grid;
  grid-gap: 4.5rem;
  grid-template-rows: 12rem auto;
  height: 100%;
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  background-color: default;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  @media (max-width: 1140px) {
    justify-content: center;
  }
`;

export default FlowPriceCalculator;
