import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import {
  ToggleInput,
  TextInput,
  SearchSelectInput,
  IconStatusCheck,
} from 'wg-fe-ui';

const CoverDetail = ({ details, sendChanges, prices }) => {
  const [toggleState, setToggleState] = useState(details?.toggle);
  const [coverLimit, setCoverLimit] = useState(details?.amount);
  const [premium, setPremium] = useState();
  const [sum, setSum] = useState();
  useEffect(() => {
    const _details = { ...details };
    _details.toggle = toggleState;
    if (coverLimit != null) {
      _details.amount = coverLimit;
    }
    if (_details?.search && sum != null) {
      _details.amount = sum;
    }
    if (premium != null) {
      _details.premium = premium;
    }
    if (
      _details?.amountOptions != null &&
      !!_details?.amount &&
      !_details?.toggle
    )
      _details.toggle = toggleState;
    sendChanges(_details);
  }, [toggleState, sum, coverLimit]);

  useEffect(() => {
    setToggleState(details?.toggle);
  }, [details?.toggle]);

  const handleSumInsuredChange = (val) => {
    setSum(Math.round(val.value));
  };

  const validateNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.returnValue = false;
      e.preventDefault();
    }
  };

  return (
    <div>
      <CoverDetailsContent>
        <div>
          <span>
            {details?.toggle != null ? (
              <ToggleInput
                onChange={() => {
                  setToggleState(!toggleState);
                }}
                checked={toggleState}
              />
            ) : (
              <IconStatusCheck />
            )}
          </span>
          <p>{details?.value}</p>
        </div>
      </CoverDetailsContent>
      <CoverLimitContent>
        {details?.amountOptionsNoToggle ? (
          <SelectInput
            onChange={setCoverLimit}
            placeholder=""
            value={coverLimit}
            options={details?.amountOptionsNoToggle}
          />
        ) : details?.amountOptions != null && toggleState === true ? (
          <SelectInput
            onChange={(val) => {
              setToggleState(true);
              setCoverLimit(val);
              if (val?.premium != null) {
                setPremium(val.premium);
              }
            }}
            value={details?.amount || coverLimit}
            placeholder=""
            options={details?.amountOptions}
          />
        ) : details?.search && toggleState === true ? (
          <StyledTextInput
            type="number"
            placeholder="Sum insured"
            min="0"
            onKeyPress={(e) => validateNumber(e)}
            onChange={(value) => handleSumInsuredChange(value)}
            value={details?.amount}
          />
        ) : details?.toggle != null && toggleState === true ? (
          details?.toggleOnAmount
        ) : typeof details?.amount !== 'object' ? (
          details?.amount
        ) : (
          ''
        )}
      </CoverLimitContent>
      <PremiumContent>
        <p>
          {prices?.[details?.responseKey] &&
          prices?.[details?.responseKey] !== '0,00'
            ? prices?.[details?.responseKey]
            : details?.premium
            ? details?.premium
            : details?.selectedPremium != null && toggleState === true
            ? details?.selectedPremium
            : details?.premium != null
            ? details?.premium
            : null}
        </p>
        {details?.detail && <Tooltip toggleIcon="?">{details?.detail}</Tooltip>}
      </PremiumContent>
    </div>
  );
};

const StyledTextInput = styled(TextInput)`
  height: unset;
  input {
    font-size: 1.4rem;
    height: 3rem;
    margin: 0;
    width: 80%;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  max-height: 3rem;
  min-height: unset;
  label > div {
    margin: 0;
    width: 80%;
  }
  .Select {
    &__control {
      height: 3rem;
      border-radius: 0.5rem;
      border: 1px solid #e4e4e4;
      z-index: 10;

      :hover {
        border-color: #e4e4e4;
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : ' #e4e4e4'};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : ' #e4e4e4'};
        }
      }
    }
    &__value-container {
      height: 3rem;
      font-size: 1.4rem;
      > div {
        margin: 0;
      }
    }
    &__input {
    }
    &__menu-list {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 8px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: ${({ theme }) => theme.brand.primary};
      }

      &::-webkit-scrollbar-track-piece {
        background: #f0f1f3;
        border-radius: 5px;
        width: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
      }
    }

    &__menu-notice--no-options,
    &__menu-notice--loading {
      min-height: 4.8rem;
      display: flex;
      align-items: center;
    }

    &__option {
      z-index: 60;
      line-height: 2.1rem;
      color: #8990a3;

      :hover {
        color: ${({ theme }) => theme.brand.primary};
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.grey};
      }

      &--is-focused {
        color: ${({ theme }) => theme.brand.primary};
        background-color: ${({ theme }) => theme.brand.grey};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

const CoverDetailsContent = styled.div`
  align-self: center;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    > p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
      padding-left: 1rem;
    }
    > span {
      width: 5rem;
      > div {
        padding: 0;
      }
    }
  }
  input[type='checkbox'] {
    height: 25px;
    margin: 0;
    width: 40px;
  }
  path {
    fill: #224074;
  }
`;

const PremiumContent = styled.div`
  align-self: center;
  color: #8990a3;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
`;
const CoverLimitContent = styled.div`
  align-self: center;
  color: #8990a3;
  font-size: 1.4rem;
`;

export default CoverDetail;
