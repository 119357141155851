import React, { useMemo } from 'react';
import i18n from '../i18n';
import styled, { css } from 'styled-components';
import { ActionButton } from 'wg-fe-ui';

const FlowOverviewLeftSide = ({
  handleSubmit,
  paymentState,
  errorMsg,
  updatedPolicies,
}) => {
  const [paymentType, setPaymentType] = paymentState;
  const disabled = useMemo(
    () =>
      updatedPolicies?.cars?.some((policy) => !policy.finished) ||
      updatedPolicies?.healths?.some((policy) => !policy.finished) ||
      updatedPolicies?.risk_addresses?.some((policy) => !policy.finished) ||
      (updatedPolicies?.cars?.every((policy) => !policy.inCart) &&
        updatedPolicies?.healths?.every((policy) => !policy.inCart) &&
        updatedPolicies?.risk_addresses?.every((policy) => !policy.inCart)),
    [updatedPolicies]
  );
  return (
    <Container>
      <Title>{i18n.t('Choose your payment method')}</Title>
      <SubTitle>
        {i18n.t(
          'Please select one of the following options to complete your purchase'
        )}
      </SubTitle>
      <InputContainer>
        <InputLabel htmlFor="full" checked={paymentType === 'full'}>
          <input
            type="radio"
            value="full"
            name="full"
            id="full"
            onChange={() => setPaymentType('full')}
            checked={paymentType === 'full'}
          />
          <InputTitle>{i18n.t('Pay the full amount')}</InputTitle>
          <InputSubTitle>
            {i18n.t(
              'You will pay the full amount through our payment provider'
            )}
          </InputSubTitle>
        </InputLabel>
        <InputLabel
          htmlFor="installments"
          checked={paymentType === 'installments'}
        >
          <input
            type="radio"
            value="installments"
            name="installments"
            id="installments"
            onChange={() => setPaymentType('installments')}
            checked={paymentType === 'installments'}
          />
          <InputTitle>{i18n.t('Pay in installments')}</InputTitle>
          <InputSubTitle>
            {i18n.t('Choose the recurring monthly amount you wish to pay')}
          </InputSubTitle>
        </InputLabel>
      </InputContainer>
      <ErrorMessage>{errorMsg}</ErrorMessage>
      <StyledActionButton
        disabled={!paymentType || disabled}
        onClick={handleSubmit}
      >
        {i18n.t('Checkout')}
      </StyledActionButton>
    </Container>
  );
};

const StyledActionButton = styled(ActionButton)`
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-weight: bold;
  padding: 1.5rem;
  width: 100%;

  &:not(:disabled) {
    background: #224074;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  height: 100%;
  overflow: auto;
  flex-direction: column;
  display: flex;
  padding: 3px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  &::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;
const Title = styled.h2`
  color: #224074;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3rem;
`;
const SubTitle = styled.p`
  font-weight: normal;
  font-size: 1.6rem;
  display: flex;
  line-height: 1.25;
  color: #8990a3;
  margin-bottom: 4rem;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;
const InputLabel = styled.label`
  padding: 2rem 3rem;
  margin-bottom: 2rem;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 1px solid white;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    border-color: #024284;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: #e5e9f1;
      border-color: #024284;
    `}

  & > input {
    display: none;
  }
`;
const InputTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 2.1rem;
  margin-bottom: 0.6rem;
`;
const InputSubTitle = styled.p`
  color: #8990a3;
`;

const ErrorMessage = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.status.error};
  margin-bottom: 1.6rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
export default FlowOverviewLeftSide;
