import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import EN from '../assets/images/flags/EN.svg';
import GR from '../assets/images/flags/GR.svg';
import { useHistory, useLocation } from 'react-router';
import { parse, stringify } from 'query-string';
import i18n from '../i18n';

const LanguageSelector = ({ onChange }) => {
  const location = useLocation();
  const history = useHistory();
  const [langIcon, setLangIcon] = useState();

  const setLanguage = (lang) => {
    const queryStrings = parse(location.search) || {};
    queryStrings.lang = lang;
    if (lang === 'el') {
      setLangIcon(GR);
    }
    if (lang === 'en') {
      setLangIcon(EN);
    }
    onChange();
    history.push({
      pathname: location.pathname,
      search: stringify(queryStrings),
    });
  };

  useEffect(() => {
    if (i18n.language?.toLowerCase() === 'en') {
      setLangIcon(EN);
    }
    if (i18n.language?.toLowerCase() === 'el') {
      setLangIcon(GR);
    }
  }, []);

  return (
    <LangSelector>
      <FlagIcon
        src={langIcon}
        alt={i18n.language}
        width="30"
        height="30"
        button={true}
      />
      <DropDownContainer>
        <button type="button" onClick={() => setLanguage('en')}>
          <FlagIcon src={EN} alt="English" width="30" height="30" /> EN
        </button>
        <button type="button" onClick={() => setLanguage('el')}>
          <FlagIcon src={GR} alt="Greek" width="30" height="30" /> EL
        </button>
      </DropDownContainer>
    </LangSelector>
  );
};

const DropDownContainer = styled.div`
  display: none;
  position: absolute;
  left: 1rem;
  flex-direction: column;
  background: #fafafa;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  width: auto;
  border-radius: 0.5rem;
  padding: 0.7rem 0;

  & button {
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    align-items: center;
    outline: none;
    display: flex;
    padding: 0.7rem 1.5rem 0.7rem 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    padding-right: 4.8rem;

    &:active {
      border: none;
    }
    &:hover {
      color: ${({ theme }) => theme.brand.primary};
      background-color: #ececec;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > img {
      margin: 0 1.5rem;
    }
  }
`;

const FlagIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-left: 1.5rem;
`;

const LangSelector = styled.div`
  margin-top: 2rem;
  position: absolute;
  /* stylelint-disable-next-line */
  z-index: 99 !important;
  &:hover {
    & ${DropDownContainer} {
      display: flex;
    }
  }
`;

export default LanguageSelector;
