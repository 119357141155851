import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useParams } from 'react-router-dom';
import { ActionButton } from 'wg-fe-ui';
import useRouting from '../hooks/useRouting';
import ListItemCard from '../components/ListItemCard';
import _ from 'lodash';
import {
  propertyInventoryCategories,
  propertyInventoryItems,
} from '../constants/InventoryData';
import InventoryForm from '../components/InventoryForm';

const FlowHomeHighRiskSpecificsMobile = () => {
  const { renderNextRoute } = useRouting();
  const [inventoryList, setInventoryList] = useState([]);
  const { id, affinity, insuranceType, currentProperty } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setInventoryList(
      data?.risk_addresses[currentProperty]?.PropertyInventory || []
    );
  }, []);

  const submitHandler = () => {
    patchStorage({
      payload: {
        PropertyInventory: inventoryList,
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  function handleDeleteItem(item) {
    let temp = inventoryList.filter((listItem) => !_.isEqual(listItem, item));
    setInventoryList(temp);
  }

  return (
    <Container>
      <Title>{i18n.t('Inventory list of high risk or specified items')}</Title>
      <SpecificsContainer>
        <div>
          {inventoryList.length > 0 && (
            <List>
              {inventoryList.map((item, i) => {
                return (
                  <ListItemCard
                    title={
                      propertyInventoryItems.find(
                        (p) => p.value === item.InventoryItemName
                      )?.label
                    }
                    subTitle={
                      propertyInventoryCategories.find(
                        (p) => p.value === item.InventoryCategory
                      )?.label
                    }
                    price={item.PriceOfItem}
                    onRemove={() => handleDeleteItem(item)}
                    key={i}
                  />
                );
              })}
            </List>
          )}
          <InventoryForm
            onAdd={(newItem) => setInventoryList([...inventoryList, newItem])}
          />
        </div>
      </SpecificsContainer>
      <ButtonContainer>
        <ActionButton
          onClick={() => submitHandler()}
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t('Save')}
        </ActionButton>
      </ButtonContainer>
    </Container>
  );
};

const List = styled.ul`
  display: flex;
  margin-bottom: 1.6rem;
  justify-content: left;
  overflow-x: auto;
  padding: 1.6rem 0;
  min-height: 13rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const SpecificsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  > div > p {
    color: #30373d;
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 550;
    font-size: 1.6rem;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.brand.primary};
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
`;

export default FlowHomeHighRiskSpecificsMobile;
