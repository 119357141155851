import React from 'react';
import styled from 'styled-components';
import { IconCarFilled } from 'wg-fe-ui';

const CarResponse = ({ car }) => {
  return (
    <div>
      <Image src={car?.car_image != null && car?.car_image} />
      <CarInformation>
        <IconCarFilled className="property" />
        <div>
          <p>
            <span>{car?.car_name != null && car?.car_name}</span>
            <span>{car?.model_year != null && car?.model_year}</span>
          </p>
        </div>
      </CarInformation>
    </div>
  );
};

const CarInformation = styled.div`
  background: #ffffff;
  border-radius: 5px;
  bottom: 2rem;
  box-shadow: 0px 0px 34.29px rgba(0, 0, 0, 0.08);
  color: black;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 6rem;
  position: absolute;
  width: 75%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  > div {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 550;
    grid-column: span 4 / auto;
    justify-content: center;
    > p {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  svg.property {
    align-self: center;
    grid-column: span 1 / auto;
    justify-self: center;
    path {
      fill: #e4e4e4;
    }
  }
  @media (max-width: 900px) {
    > div {
      font-size: 1.2rem;
    }
  }
`;

const Image = styled.img`
  border-radius: 1rem;
  position: relative;
  width: 100%;
`;

export default CarResponse;
