import React from "react";

const SvgComponent = ({ colors }) => (
  <svg viewBox="0 0 400 400" width="100%">
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors}
    />
    <g fill="#fff">
      <path d="m312.23 153.82h-10.66a3.82 3.82 0 0 0 -3.57 2.48l-10.09 27.32a21.33 21.33 0 0 1 -18.55 13.87l-4.74-45.49a29.77 29.77 0 0 0 -29.62-26.7h-60.13a88 88 0 0 0 -84.87 65l-5.1 19a27.8 27.8 0 0 0 26.84 35h141.87a41 41 0 0 1 38.74 27.52l5.65 16.33a3.8 3.8 0 0 0 3.59 2.55h10.61a3.8 3.8 0 0 0 3.8-3.8v-129.28a3.8 3.8 0 0 0 -3.77-3.8zm-200.45 71.46a8.79 8.79 0 0 1 -8.48-11.07l5.1-19a69 69 0 0 1 66.47-50.94h60.13a10.8 10.8 0 0 1 10.71 9.73l6.19 59.34a10.82 10.82 0 0 1 -10.76 11.94z" />
      <path d="m204.3 210a31.41 31.41 0 0 0 -35.24-.71l-7.06-10.9a44.43 44.43 0 0 1 49.85 1z" />
      <path d="m221.12 188.55a58.63 58.63 0 0 0 -68.31-1.15l-7.42-10.67a71.6 71.6 0 0 1 83.49 1.4z" />
    </g>
  </svg>
);

export default SvgComponent;
