import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowVehiclePurpose = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const history = useHistory();
  const [vehicleUsedProfessionally, setVehicleUsedProfessionally] = useState();
  const [error, setError] = useState();

  const loadDefaultValues = async () => {
    if (cars[currentVehicle].vehicle_used_professionally != null) {
      setVehicleUsedProfessionally(
        cars[currentVehicle].vehicle_used_professionally
      );
    }
  };

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Do you use the vehicle for <span>professional use</span>?'
      ),
      slug: 'vehicle_used_professionally',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    if (vehicleUsedProfessionally == null) {
      setError('Please answer the question above');
      return;
    } else {
      setError();
    }

    patchStorage({
      payload: { vehicle_used_professionally: vehicleUsedProfessionally },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: vehicleUsedProfessionally
        ? i18n.t('Yes, the car is used <span>professionally</span>')
        : i18n.t('No, the car is for <span>private use</span>'),
      slug: 'vehicle_used_professionally',
    };
    addAnswer(answer);

    renderNextRoute(1, { vehicle: currentVehicle });
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={formSubmitHandler}>
        <Scroll>
          <TitleForm>
            {i18n.t('Do you use the vehicle for professional purposes?')}
          </TitleForm>
          <ReplacementCarAnswers>
            <StyledButton
              active={vehicleUsedProfessionally === true}
              onClick={(e) => {
                e.preventDefault();
                setVehicleUsedProfessionally(true);
              }}
            >
              {i18n.t('Yes')}
            </StyledButton>
            <StyledButton
              active={vehicleUsedProfessionally === false}
              onClick={(e) => {
                e.preventDefault();
                setVehicleUsedProfessionally(false);
              }}
            >
              {i18n.t('No')}
            </StyledButton>
          </ReplacementCarAnswers>
        </Scroll>
        <Error>{error}</Error>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {i18n.t('Next')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  align-self: center;
  color: #f74040;
  font-size: 1.4rem;
  justify-content: left;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ReplacementCarAnswers = styled.div`
  display: flex;
  width: 100%;
  padding: 3rem 0;
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  margin-right: 2.4rem;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  max-width: 21.3rem;
  width: 48%;
  height: 5.3rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
  span {
    color: #aeaeae;
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 1.5rem;
  }
`;

const RightContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  padding-left: 4rem;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowVehiclePurpose;
