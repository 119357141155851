import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import LoadingActionButton from './LoadingActionButton';

import { TextInput, ActionButton } from 'wg-fe-ui';
import {
  telephonenr,
  companyName,
  string,
  firstName,
  lastName,
  email,
} from '../constants/validationSchemas';
import {
  retrieveCompanyByRegistNr,
  ganAuth,
} from '../helpers/apiRouterService';
import { titleOptions as titleOptionsConstant } from '../constants/FlowSearchSelectData';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import FlowPolicyHolderInfoFormDefaultInputs from './FlowPolicyHolderInfoFormDefaultInputs';
import { occupations } from '../constants/customerData';
import { useTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import SearchSelectInputV2 from './SearchSelectInputV2';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const occupations_lowerCase = occupations
  .map((o) => {
    o.label = o.label.toLowerCase();
    return o;
  })
  .sort(function(a, b) {
    return a.label < b.label;
  });

const FlowPolicyHolderInfoFormLegal = ({
  sendSubmit,
  defaultValues,
  loading,
}) => {
  const { t } = useTranslation();
  const [vatSearched, setVatSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleOptions, setTitleOptions] = useState([]);
  const validationSchema = Yup.object().shape({
    company_name: companyName,
    cbe: string.required,
    occupation: string.required,
    title: string.required,
    firstName: firstName,
    lastName: lastName,
    password: string.required,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], i18n.t('Passwords must match'))
      .required(i18n.t(`required`)),
    telephonenr: telephonenr.required.test(
      'Phone number',
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
    email: email,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  const handleSearchVat = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const [, statusAuth] = await ganAuth();
    if (statusAuth !== 200) return setVatSearched(true);
    const [resp, status] = await retrieveCompanyByRegistNr(values.cbe);
    if (status === 200 || (resp?.Error !== undefined && resp?.Error)) {
      setIsLoading(false);
      handleChange({ name: 'company_name', value: resp?.CompanyName });
      handleChange({ name: 'company_oid', value: resp?.Oid });
      setVatSearched(true);
    } else {
      setIsLoading(false);
      setVatSearched(false);
      cogoToast.error(
        resp?.Description || t('Company registration number could not be found')
      );
    }
  };

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setTitleOptions(
      titleOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : undefined });
  }

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return undefined;
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  const handleKeyDownCbe = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSearchVat(e);
    }
  };

  return (
    <form onSubmit={handleForm}>
      <Divider />
      <Title>{t('Company information')}</Title>
      <VatSearch>
        <TextInput
          name="cbe"
          type="number"
          error={errors.cbe}
          onKeyDown={handleKeyDownCbe}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues.cbe !== undefined ? defaultValues.cbe : values.cbe
          }
        >
          {i18n.t('Company Registration Number')}
        </TextInput>
        {isLoading ? (
          <LoadingActionButton>Searching</LoadingActionButton>
        ) : (
          <ActionButton type="button" onClick={(e) => handleSearchVat(e)}>
            Search
          </ActionButton>
        )}
      </VatSearch>
      {vatSearched && (
        <>
          <FlexWrapper>
            <TextInput
              name="company_name"
              error={errors.company_name}
              disabled
              value={
                defaultValues.company_name !== undefined
                  ? defaultValues.company_name
                  : values.company_name
              }
            >
              {i18n.t('Company name')} *
            </TextInput>
            <TextInput
              name="cbe"
              error={errors.cbe}
              disabled
              value={
                defaultValues.cbe !== undefined ? defaultValues.cbe : values.cbe
              }
            >
              {i18n.t('Company Registration Number')}
            </TextInput>
          </FlexWrapper>
          <SearchSelectInputV2
            error={errors.occupation}
            name="occupation"
            onChange={(val) => {
              handleSelectChange(val, 'occupation');
            }}
            options={occupations_lowerCase}
            placeholder={i18n.t('Choose your option')}
            value={handleSelectValue(occupations_lowerCase, 'occupation')}
          >
            {i18n.t('Occupation')} *
          </SearchSelectInputV2>
          <Title>{t('Contact person')}</Title>
          <SearchSelectInputV2
            error={errors.title}
            name="title"
            onChange={(val) => {
              handleSelectChange(val, 'title');
            }}
            options={titleOptions}
            placeholder={i18n.t('Choose your option')}
            value={handleSelectValue(titleOptions, 'title') || undefined}
          >
            {i18n.t('Title')} *
          </SearchSelectInputV2>
          <FlexWrapper>
            <TextInput
              name="firstName"
              error={errors.firstName}
              onChange={(val) => handleChange(val)}
              value={
                defaultValues.firstName !== undefined
                  ? defaultValues.firstName
                  : values.firstName
              }
            >
              <NameInput>{i18n.t('First Name')} *</NameInput>
            </TextInput>
            <TextInput
              name="lastName"
              error={errors.lastName}
              onChange={(val) => handleChange(val)}
              value={
                defaultValues.lastName !== undefined
                  ? defaultValues.lastName
                  : values.lastName
              }
            >
              <NameInput>{i18n.t('Last Name')} *</NameInput>
            </TextInput>
          </FlexWrapper>
          <FlowPolicyHolderInfoFormDefaultInputs
            defaultValues={defaultValues}
            errors={errors}
            loading={loading}
            values={values}
            handleChange={handleChange}
          />
        </>
      )}
    </form>
  );
};

const VatSearch = styled.div`
  display: flex;
  align-items: flex-end;

  & > button {
    margin-bottom: 2.6rem;
    margin-left: 1rem;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    width: 100%;
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;

    & > div + div {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const NameInput = styled.p`
  color: #8990a3;
  height: 1rem;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e4e4e4;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

export default FlowPolicyHolderInfoFormLegal;
