import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ActionButton,
  TextInput,
  IconActionChevronLeft,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import { getNestedObject } from '../helpers/objectService';
import { insurance_companies } from '../constants/FlowSearchSelectData';
import { format } from 'date-fns';

const FlowHomeExistingPropertyInsuranceInformation = () => {
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [defaultValues, setDefaultValues] = useState({});
  const [insuranceCompany, setInsuranceCompany] = useState();
  const [insurancePrice, setInsurancePrice] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [startDate, setStartDate] = useState();
  const history = useHistory();

  useEffect(() => {
    const question = {
      message: i18n.t('Great, can you give me some <span>details</span>?'),
      slug: 'existing_health_insurance_information',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      insurances: data.insurances,
      insurance_price: getNestedObject(data, [
        'healths',
        [currentHealth],
        'insurance_price',
      ]),
      insurance_company_name: getNestedObject(data, [
        'healths',
        [currentHealth],
        'insurance_company_name',
      ]),
      start_of_contract: getNestedObject(data, [
        'healths',
        [currentHealth],
        'start_of_contract',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues?.insurance_company_name) {
      setInsuranceCompany({
        label: defaultValues?.insurance_company_name,
        value: defaultValues?.insurance_company_name,
      });
    }
    if (defaultValues?.insurance_price) {
      setInsurancePrice({
        name: 'insurance_price',
        value: defaultValues?.insurance_price,
      });
    }
    if (defaultValues?.start_of_contract) {
      setStartDate({
        name: 'start_of_contract',
        value: defaultValues?.start_of_contract,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const submitHandler = () => {
    if (
      insuranceCompany == null ||
      startDate == null ||
      insurancePrice == null
    ) {
      if (
        (insuranceCompany?.value !== 'None' &&
          insuranceCompany?.value !== 'GAN DIRECT INSURANCE LTD' &&
          insuranceCompany?.value !== 'OVERSEAS') ||
        insuranceCompany == null
      ) {
        setErrorMessage(
          i18n.t('Please make sure you answer all of the questions')
        );
        return;
      }
    } else {
      setErrorMessage();
    }

    setLoading(true);
    const payload = {
      insurance_price: insurancePrice?.value,
      insurance_company_name: insuranceCompany?.value,
      start_of_contract: startDate?.value,
      CanYouProvideMedicalCertificate: null,
    };
    patchStorage({
      payload: payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    if (insuranceCompany?.value != null && startDate?.value != null) {
      const answer = {
        message: `I have an insurance with ${
          insuranceCompany.value
        } that was started on ${format(
          new Date(startDate.value),
          'dd/MM/yyyy'
        )}`,
        slug: 'existing_health_insurance_information',
      };
      addAnswer(answer);
    }
    renderNextRoute(1, { health: currentHealth });

    setLoading(false);
  };
  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <TitleForm>{i18n.t('Previous / Current Insurance')}</TitleForm>
            <SearchSelectInputV2
              name="insurance_company_name"
              options={insurance_companies}
              onSelected={(val) => {
                setInsuranceCompany(val);
              }}
            >
              <Label>{i18n.t('Insurance company')}</Label>
            </SearchSelectInputV2>
            {insuranceCompany == null ||
              (insuranceCompany?.value !== 'None' &&
                insuranceCompany?.value !== 'GAN DIRECT INSURANCE LTD' &&
                insuranceCompany?.value !== 'OVERSEAS' && (
                  <>
                    <DateInput
                      iso
                      name="start_of_contract"
                      value={startDate?.value}
                      onChange={(val) => {
                        setStartDate(val);
                      }}
                    >
                      <Label>{i18n.t('End date')}</Label>
                    </DateInput>
                    <StyledTextInput
                      name="insurance_price"
                      type="number"
                      value={insurancePrice?.value}
                      onChange={(val) => {
                        setInsurancePrice(val);
                      }}
                    >
                      <p>{i18n.t('Price')}</p>
                      <p className="valueType">&euro;</p>
                    </StyledTextInput>
                  </>
                ))}
          </div>
          <Error>{errorMessage}</Error>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  text-align: center;
  font-size: 1.4rem;
  color: #f74040;
`;

const Label = styled.p`
  color: #8990a3;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.2rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    > div > div {
      height: 9rem;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeExistingPropertyInsuranceInformation;
