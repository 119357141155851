import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import useRouting from '../hooks/useRouting';
import { ActionButton, TextInput } from 'wg-fe-ui';
import { getNestedObject } from '../helpers/objectService';
import i18n from '../i18n';
import LoadingActionButton from '../components/LoadingActionButton';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeSurfaceArea = () => {
  const [loading, setLoading] = useState(false);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const { renderNextRoute } = useRouting();
  const [defaultValues, setDefaultValues] = useState({});
  const { addQuestion, addAnswer } = useContext(ChatContext);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Alright! How many square meters <span>habitual surface</span> does it contain?'
      ),
      slug: 'home-surface-area',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      buildingSurface: getNestedObject(risk_addresses, [
        [currentProperty],
        'parcel',
        'main_building',
        'surface',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const submitHandler = () => {
    setLoading(true);

    const answer = {
      message: i18n.t(
        `There is <span>${defaultValues?.buildingSurface} m2</span> of habitable space`
      ),
      slug: 'home-surface-area',
    };
    addAnswer(answer);

    patchStorage({
      payload: { surface: Math.round(defaultValues?.buildingSurface) },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
    setLoading(false);
  };

  return (
    <SplitContainer>
      <FlowChatBox />
      <RightSplit>
        <form onSubmit={handleForm}>
          <div>
            <StyledTextInput
              name="habitable_surface_area"
              type="number"
              min="1"
              step="1"
              onChange={(val) => {
                defaultValues.buildingSurface = val.value;
                setDefaultValues(defaultValues);
              }}
              value={
                defaultValues?.buildingSurface !== null &&
                defaultValues?.buildingSurface
              }
            >
              <p>{i18n.t('Habitable surface area')}</p>
              <p className="valueType">{i18n.t('m²')}</p>
            </StyledTextInput>
          </div>
          <ButtonContainer>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Submit')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }

  p.valueType {
    padding: 1.5rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const RightSplit = styled.div`
  display: flex;
  padding-left: 4rem;
  overflow-y: auto;
  position: relative;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowHomeSurfaceArea;
