import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n.js';
import GanDirectAvatar from '../assets/images/gan-direct-avatar.svg';
import { getBrandData } from '../helpers/tagRouterService';
import { ActionButton } from 'wg-fe-ui';
import useRouting from '../hooks/useRouting.js';
import { clearStorage } from '../helpers/storeService.js';
import { useParams } from 'react-router';

const FlowThankyou = () => {
  const affinityName = getBrandData().name;
  const { renderNextRoute } = useRouting();
  const { affinity, insuranceType } = useParams();

  const handleClose = () => {
    clearStorage(affinity, insuranceType);
    renderNextRoute();
  };
  return (
    <Container>
      <RoundImage src={GanDirectAvatar} alt="Gan" />
      <Title
        dangerouslySetInnerHTML={{
          __html: i18n.t('Thank you, purchase complete!', {
            affinityName,
          }),
        }}
      />
      <Content>
        <p>{i18n.t('Thank you for using Gan Direct')}</p>
        <p>{i18n.t('We will contact you soon about your proposal!')}</p>
      </Content>
      <StyledActionButton onClick={() => handleClose()}>
        Close Gan Direct
      </StyledActionButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  font-size: 1.6rem;
  color: #8990a3;
  line-height: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 2.6rem;
  font-size: 3.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.brand.primary};
`;

const RoundImage = styled.img`
  display: block;
  max-height: 35rem;
  margin-bottom: -2rem;
  width: auto;
  align-self: center;
`;

const StyledActionButton = styled(ActionButton)`
  width: 90%;
  max-width: 40rem;
  font-weight: 550;
  height: 5.5rem;
  margin-top: 3.2rem;
`;

export default FlowThankyou;
