import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../i18n';
import CarEcoGan from '../assets/icons/CarEcoGan';
import CarPremGanIcon from '../assets/icons/CarPremGanIcon';
import CarBasicGanIcon from '../assets/icons/CarBasicGanIcon';
import CarValueGanIcon from '../assets/icons/CarValueGanIcon';
import {
  IconStar,
  IconActionChevronLeft,
  LoadingSpinner,
  IconStatusCheck,
  ActionButton,
  IconActionClose,
} from 'wg-fe-ui';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { createCarPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import useRouting from '../hooks/useRouting';
import { handlePolicyStartDate } from '../helpers/dateService';

const getPayload = (_data, selected, currentVehicle) => {
  const payload = {
    SavePolicy: _data?.SavePolicy || 'False',
    Customer: {
      Oid: _data.ganCustomer.Oid,
    },
    VehicleOrVessel: 0,
    Vehicle: {
      Oid: _data.cars[currentVehicle].vehicle_info.Oid,
    },
    MotorStatus: 1,
    ExistingInsuranceCompany: {
      InsuranceCompany: _data?.cars[currentVehicle]?.insurance_company_name,
    },
    ExpiringDateOfYourCurrentPolicy: _data?.cars[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars[currentVehicle]?.start_of_contract || null
      : null,
    ExistingInsuranceCompanyPrice: _data?.cars[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars[currentVehicle]?.insurance_price || null
      : null,
    PolicyHolderAsDriver: _data.cars[currentVehicle].main_driver?.same_as_policy
      ? 1
      : 0,
    MotorDrivers: [
      {
        Oid: _data.cars[currentVehicle].main_driver?.same_as_policy
          ? _data?.ganCustomer?.Oid
          : _data?.cars?.[currentVehicle]?.main_driver?.Oid,
      },
      ..._data?.cars?.[currentVehicle]?.extra_drivers?.map((item) => ({
        Oid: item?.Oid,
      })),
    ],
    EuroCompPackageBenefit: _data?.EuroCompPackageBenefit?.value || '',
    LossOfUseBenefit: _data?.LossOfUseBenefit?.value || '',
    GuranteeAssetProtectionBenefit:
      _data?.GuranteeAssetProtectionBenefit?.value || '',
    ClaimProtectionBenefit: _data?.ClaimProtectionBenefit || '',
    RiotsStrikesBenefit: _data?.RiotsStrikesBenefit?.value || '',
    ConvulsionOfNatureBenefit: _data?.ConvulsionOfNatureBenefit?.value || '',
    PersonalEffectsBenefit: _data?.PersonalEffectsBenefit || '',
    MedicalExpensesBenefit: _data?.MedicalExpensesBenefit?.value || '',
    StayMobile: _data.cars[currentVehicle]?.replacement_vehicle ? 1 : 0,
    DrivingOtherCarsBenefit: _data?.DrivingOtherCarsBenefit || '',
    UnlimitedWindscreenBenefit: _data?.UnlimitedWindscreenBenefit || '',
    ExcessProtectionBenefit: _data?.ExcessProtectionBenefit?.value || '',
    Trailer: _data?.Trailer?.value || 0,
    TrailerChassisNumber: '',
    Relay: _data?.Relay || 0,
    PolicyStartDate: handlePolicyStartDate(
      _data.cars[currentVehicle]?.policy_details?.starting_date
    ),
    PolicyEndDate: new Date(
      _data.cars[currentVehicle]?.policy_details?.ending_date
    ).toISOString(),
    PolicyDuration:
      _data.cars[currentVehicle]?.policy_details?.insurance_duration,
    VehicleCoverType: 2,
    VehicleExcess: 250,
    InsuredAmount: parseInt(
      _data.cars[currentVehicle]?.vehicle_info?.invoice_value
    ),
  };

  switch (selected) {
    case 'Basic Plus':
      payload.VehicleCoverType = 0;
      break;
    case 'Economic':
      payload.VehicleCoverType = 1;
      break;
    case 'Value':
      payload.VehicleCoverType = 2;
      break;
    case 'Premium':
      payload.VehicleCoverType = 2;
      payload.EuroCompPackageBenefit = 1;
      break;
    default:
      payload.VehicleCoverType = 9;
      break;
  }
  return payload;
};

const FlowPricingComparison = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const history = useHistory();

  const handleApiCall = async () => {
    const data = {};
    const [res1, status1] = await createCarPolicyGan(
      getPayload(storage?.data, 'Basic Plus', currentVehicle)
    );
    const [res2, status2] = await createCarPolicyGan(
      getPayload(storage?.data, 'Economic', currentVehicle)
    );
    const [res3, status3] = await createCarPolicyGan(
      getPayload(storage?.data, 'Value', currentVehicle)
    );
    const [res4, status4] = await createCarPolicyGan(
      getPayload(storage?.data, 'Premium', currentVehicle)
    );
    if (status1 === 200) data['Basic Plus'] = res1;
    if (status2 === 200) data['Economic'] = res2;
    if (status3 === 200) data['Value'] = res3;
    if (status4 === 200) data['Premium'] = res4;
    if (Object.keys(data)?.length !== 4) throw new Error(selected?.value);
    return data;
  };

  const { data, isLoading, failureCount } = useQuery(
    ['policyData', 'All'],
    handleApiCall,
    {
      // enabled: policyData === {},
      retry: 2,
    }
  );

  useEffect(() => {
    if (data) {
      setPolicyData(data);
    }
  }, [data, selected, failureCount]);

  const cover_details = [
    { value: 'Excellent coverage for the driver and passenger', covered: true },
    { value: 'Excellent coverage of the third-parties', covered: true },
    { value: 'Okay legal coverage', covered: true },
    { value: 'Limited medical coverage and assistance', covered: false },
    { value: 'Limited theft coverage', covered: false },
    { value: 'Limited road assistance', covered: false },
  ];

  function iconType(icon) {
    switch (icon) {
      case 'Basic Plus': //terraced
        return <CarBasicGanIcon />;
      case 'Economic': //semi-detached
        return <CarEcoGan />;
      case 'Value':
        return <CarValueGanIcon />;
      case 'Premium':
        return <CarPremGanIcon />;
      default:
        return <CarValueGanIcon />;
    }
  }

  const submitHandler = () => {
    if (selected == null) {
      // setErrorText("Please choose an insurance package");
      console.error('No package selected');
      return;
    } else {
      // setErrorText();
    }
    patchStorage({
      payload: {
        insurance_pack: selected?.value,
        insurance_info: policyData[selected?.value]?.Data,
      },
      path: ['cars', currentVehicle],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  return (
    <>
      {isLoading ? (
        <LoadingBox>
          <LoadingSpinner className="spinner" />
        </LoadingBox>
      ) : (
        <Container>
          <LeftContainer>
            <TitleForm>{i18n.t('Choose your package')}</TitleForm>
            {Object.entries(policyData)?.map(([key, item]) => {
              return (
                <Package
                  onClick={() => setSelected({ value: key })}
                  selected={selected?.value === key}
                  type={key}
                >
                  <div>
                    {iconType(key)}
                    <p>{key}</p>
                  </div>
                  <p>
                    {item?.Data?.TotalwithFees} &euro;<span>yearly</span>
                  </p>
                </Package>
              );
            })}
            <TotalPremiumContainer>
              <Premium>
                <p>Your total premium</p>
                <span>{selected?.premium} €</span>
              </Premium>
              <ButtonContainer>
                <div onClick={() => history.goBack()}>
                  <IconActionChevronLeft />
                  Back
                </div>
                <ActionButton onClick={() => submitHandler()}>
                  Specify
                </ActionButton>
              </ButtonContainer>
            </TotalPremiumContainer>
          </LeftContainer>
          <RightContainer>
            <Title>Compared against</Title>
            <InsuracePackages>
              <Insurance>
                <TopHalf>
                  <Name>
                    <p>Baloise</p>
                    <span>Package name</span>
                  </Name>
                  <Score>
                    <p>Comparison score</p>
                    <div>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon>
                        <IconStar />
                      </StarIcon>
                    </div>
                  </Score>
                  <Price>
                    <p>500 €</p>
                    <span>yearly</span>
                  </Price>
                </TopHalf>
                <BottomHalf>
                  <P>Coverage score</P>
                  <CoverDetails>
                    {cover_details?.map((cover) => {
                      return (
                        <p>
                          {cover?.covered ? (
                            <IconStatusCheck />
                          ) : (
                            <IconActionClose />
                          )}
                          {cover.value}
                        </p>
                      );
                    })}
                  </CoverDetails>
                </BottomHalf>
              </Insurance>
              <Insurance>
                <TopHalf>
                  <Name>
                    <p>Baloise</p>
                    <span>Package name</span>
                  </Name>
                  <Score>
                    <p>Comparison score</p>
                    <div>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon pass>
                        <IconStar />
                      </StarIcon>
                      <StarIcon>
                        <IconStar />
                      </StarIcon>
                      <StarIcon>
                        <IconStar />
                      </StarIcon>
                    </div>
                  </Score>
                  <Price>
                    <p>500 €</p>
                    <span>yearly</span>
                  </Price>
                </TopHalf>
                <BottomHalf>
                  <P>Coverage score</P>
                  <CoverDetails>
                    {cover_details?.map((cover) => {
                      return (
                        <p>
                          {cover?.covered ? (
                            <IconStatusCheck />
                          ) : (
                            <IconActionClose />
                          )}
                          {cover.value}
                        </p>
                      );
                    })}
                  </CoverDetails>
                </BottomHalf>
              </Insurance>
            </InsuracePackages>
          </RightContainer>
        </Container>
      )}
    </>
  );
};

const StarIcon = styled.div`
  svg {
    width: 2rem;
    margin-right: 0.2rem;
    path {
      fill: ${({ pass }) =>
        pass === true ? '#FFCD1E !important' : '#F0F1F3 !important'};
    }
  }
`;

const CoverDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > p {
    color: rgba(48, 55, 61, 0.66);
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 3rem auto;
    grid-column: span 1;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    line-height: 150%;
    svg {
      width: 3rem;
      margin-top: -0.25rem;
    }
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const LoadingBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const P = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2rem;
  margin-left: 0.75rem;
`;

const Name = styled.div`
  > p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.9rem;
    margin-bottom: 0.5rem;
  }
  > span {
    color: #8990a3;
    font-size: 1.6rem;
    font-weight: 545;
    line-height: 1.9rem;
  }
`;

const Score = styled.div`
  > div {
    display: flex;
  }
  > p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.9rem;
  }
`;

const Price = styled.div`
  > p {
    font-size: 2.1rem;
    font-weight: bold;
    line-height: 115%;
  }
  > span {
    color: #8990a3;
    display: flex;
    font-weight: 545;
    justify-content: flex-end;
  }
`;

const TopHalf = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`;

const BottomHalf = styled.div`
  font-size: 1.6rem;
  letter-spacing: 0.01em;
  padding: 2rem;
`;

const Insurance = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(181, 181, 181, 0.25);
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const RightContainer = styled.div`
  overflow: auto;
  padding-left: 4rem;

  @media (max-width: 900px) {
    padding-left: 1.6rem;
  }
`;

const InsuracePackages = styled.div`
  overflow-y: auto;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.brand.primary};
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.02em;
  margin-bottom: 4rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  > div {
    align-items: center;
    margin-right: 1.6rem;
    display: flex;
    font-weight: bold;
    margin-left: 1rem;
    cursor: pointer;
    transition: 0.2s;
    > svg {
      margin-bottom: 0.2rem;
    }
    &:active {
      transform: scale(0.95);
    }
  }
`;

const Premium = styled.div`
  > p {
    color: #8990a3;
    font-size: 1.6rem;
    line-height: 20px;
  }
  > span {
    font-size: 2.1rem;
    font-weight: bold;
    line-height: 2.7rem;
  }
`;

const TotalPremiumContainer = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  margin-top: auto;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 2.4rem;
  }
`;

const Package = styled.div`
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border: ${({ selected, type }) =>
    selected && type === 'Basic Plus'
      ? `2px solid #98989B`
      : selected && type === 'Economic'
      ? `2px solid #00A3E4`
      : selected && type === 'Value'
      ? '2px solid #024284'
      : selected && type === 'Premium'
      ? '2px solid #C29B67'
      : '2px solid #e4e4e4'};
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-left: 0.1rem;
  padding: 1.5rem;
  > div {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 2rem;
    }
    > p {
      color: ${({ type }) =>
        type === 'Basic Plus'
          ? '#98989B'
          : type === 'Economic'
          ? '#00A3E4'
          : type === 'Value'
          ? '#024284'
          : '#C29B67'};
      font-weight: 550;
    }
  }
  > p {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    font-weight: 550;
    font-size: 2.1rem;
    line-height: 115%;
    > span {
      color: #8990a3;
      font-size: 1.3rem;
      text-align: right;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2.8rem;
  flex-shrink: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  border-right: 1px solid #f5f6f7;
  padding-right: 4rem;

  @media (max-width: 900px) {
    padding-right: 1.6rem;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  overflow: hidden;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowPricingComparison;
