import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import '../assets/css/cogoToast.css';
import FlowChatBox from '../components/FlowChatBox';
import useRouting from '../hooks/useRouting.js';
import { useHistory } from 'react-router';
import { ChatContext } from '../contexts/ChatContext';

const FlowPolicyHolderUserInfo = () => {
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const { addQuestion } = useContext(ChatContext);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Hi new user, to find the perfect insurance for you, were going to need some of your <span>personal information</span>'
      ),
      slug: 'new-user',
    };

    addQuestion(question);
  }, []);

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function loadInfoDoc(e) {
    e.preventDefault();
    renderNextRoute();
  }

  return (
    <Container>
      <FlowChatBox />
      <CustomerInformationContainer>
        <StyledButton onClick={loadInfoDoc}>
          {i18n.t('Fill in info')}
        </StyledButton>
        <StyledButton className="cancel" onClick={goBack}>
          {i18n.t('Cancel')}
        </StyledButton>
      </CustomerInformationContainer>
    </Container>
  );
};

const CustomerInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
  justify-content: center;
  align-content: center;
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.brand.primary};
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  height: 4.5rem;
  border: none;
  font-weight: bold;
  font-size: 1.4rem;
  height: 5rem;
  &.cancel {
    background-color: ${({ theme }) => theme.brand.secondary};
  }
  &:focus {
    outline: 0;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowPolicyHolderUserInfo;
