import React, { createContext, useReducer, useContext } from "react";
import {
  ganGetAddress,
  ganCreateCustomerIndvdl,
  addAddressToCustomer,
} from "../helpers/apiRouterService";
import { PremiumSummaryContextProvider } from "./PremiumSummaryContext";

const initialState = {
  initial: true,
  isGanCustomer: false,
  ganCustomer: {},
  customer: {},
  ganVehicle: {},
  ganAddress: {},
};

export const storeReducer = (state, action) => {
  switch (action.type) {
    case "ADD_GAN_CUSTOMER":
      return {
        ...state,
        isGanCustomer: true,
        initial: false,
        ganCustomer: { ...state.ganCustomer, ...action.payload },
      };
    case "ADD_NEW_CUSTOMER":
      return {
        ...state,
        isGanCustomer: false,
        initial: false,
        customer: { ...state.customer, ...action.payload },
      };
    case "ADD_GAN_VEHICLE":
      return {
        ...state,
        ganVehicle: { ...state.ganVehicle, ...action.payload },
      };
    case "ADD_GAN_POLICYHOLDER_ADDRESS":
      return {
        ...state,
        ganAddress: { ...state.ganAddress, ...action.payload },
      };
    case "ADD_GAN_RISK_OBJECT_ADDRESS":
      return {
        ...state,
        ganRiskAddress: { ...state.ganRiskAddress, ...action.payload },
      };
    case "RESET_FLOW":
      return { ...initialState };
    case "FILL_STORAGE_DATA":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const FlowContext = createContext(initialState);

const FlowContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);
  const createGanCustomer = async (payload) => {
    const [resp, status] = await ganCreateCustomerIndvdl(payload);
    if (status === 200 && !resp.Error) {
      dispatch({
        type: "ADD_GAN_CUSTOMER",
        payload: resp.Data,
      });
    }
    return [resp, status];
  };

  const addGanAddress = async (payload) => {
    const [resp, status] = await ganGetAddress(payload);
    if (status === 200 && !resp.Error) {
      dispatch({
        type: "ADD_GAN_POLICYHOLDER_ADDRESS",
        payload: resp.Data?.[0],
      });
    }
    return [resp, status];
  };

  const addGanRiskAddress = async (payload, _customerId) => {
    const [resp, status] = await ganGetAddress(payload);
    if (status === 200 && !resp.Error) {
      const addressExists = state?.ganCustomer.AddressesList?.find((item) => {
        return (
          item?.Address?.Oid === resp?.Data?.[0]?.Oid &&
          item?.StreetNumber === payload?.HouseNumber
        );
      });
      if (addressExists) {
        dispatch({
          type: "ADD_GAN_RISK_OBJECT_ADDRESS",
          payload: addressExists,
        });
        return [addressExists, 200];
      } else {
        const addPayload = [
          {
            Mailing: false,
            Address: {
              FullAddress: resp?.Data?.[0]?.FullAddress,
            },
            HouseNumber: null,
            BuildingName: payload?.BuildingName || null,
            StreetNumber: payload?.HouseNumber || null,
            Type: payload?.Type || 0,
            OwnershipStatus: payload?.OwnershipStatus || 0,
            SquareMeters: payload?.SquareMeters || 0,
            isPrimary: false,
          },
        ];
        const [respAdd, statusAdd] = await addAddressToCustomer(
          addPayload,
          _customerId
        );
        if (statusAdd === 200 && !respAdd.Error) {
          const addData = {
            ...resp?.Data?.[0],
            Oid: respAdd?.Data?.[0],
          };
          dispatch({
            type: "ADD_GAN_RISK_OBJECT_ADDRESS",
            payload: addData,
          });
          return [addData, 200];
        }
      }
    }
    return [resp, status];
  };

  return (
    <FlowContext.Provider
      value={[
        {
          ...state,
          addGanAddress,
          addGanRiskAddress,
          createGanCustomer,
        },
        dispatch,
      ]}
    >
      <PremiumSummaryContextProvider>{children}</PremiumSummaryContextProvider>
    </FlowContext.Provider>
  );
};

function useFlowStore() {
  const context = useContext(FlowContext);
  if (context === undefined) {
    throw new Error("useFlowStore must be used within a FlowContextProvider");
  }
  return context;
}

export { FlowContextProvider, useFlowStore };
