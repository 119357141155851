import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeBusinessPurposeRooms = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Are there any rooms in the building that are used for <span>business purposes?</span>'
      ),
      slug: 'business_purpose_rooms',
    };
    addQuestion(question);

    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      business_rooms_present: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'business_rooms_present',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  const submitHandler = () => {
    patchStorage({
      payload: {
        business_rooms_present: defaultValues?.business_rooms_present,
      },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });
    const answer = {
      message: i18n.t(
        `There are<span>${
          defaultValues?.business_rooms_present === true ? '' : ' no'
        } business rooms</span> in the building`
      ),
      slug: 'building_information',
    };

    addAnswer(answer);
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <AddressToInsureQ>
          <StyledButton
            active={defaultValues.business_rooms_present === true}
            onClick={() => {
              defaultValues.business_rooms_present = true;
              submitHandler();
            }}
          >
            {i18n.t('Yes')}
          </StyledButton>
          <StyledButton
            active={defaultValues.business_rooms_present === false}
            onClick={() => {
              defaultValues.business_rooms_present = false;
              submitHandler();
            }}
          >
            {i18n.t('No')}
          </StyledButton>
        </AddressToInsureQ>
      </RightContainer>
    </Container>
  );
};

const AddressToInsureQ = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 45% 45%;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  height: 4rem;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: hidden;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

export default FlowHomeBusinessPurposeRooms;
