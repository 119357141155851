import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import { ActionButton, TextInput } from 'wg-fe-ui';
import { number, email } from '../constants/validationSchemas';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { id_number } from '../constants/validationSchemas.js';
import LoadingActionButton from './LoadingActionButton';

const FlowPolicyHolderInfoFormLegal = ({
  sendSubmit,
  defaultValues,
  askEmailExistingCustomer,
  loading,
  personType,
}) => {
  // Place validationSchema in a state so it can be changed after first render
  const [validationSchema, setValidationSchema] = useState(
    askEmailExistingCustomer
      ? Yup.object().shape({
          idNumber: id_number,
          email: email,
        })
      : Yup.object().shape({
          idNumber: id_number,
        })
  );
  const [change, setChange] = useState(false);

  useEffect(() => {
    // Change yup validation depending if email is needed or not
    setValidationSchema(
      askEmailExistingCustomer
        ? Yup.object().shape({
            idNumber: number.required,
            email: email,
          })
        : Yup.object().shape({
            idNumber: number.required,
          })
    );

    // Trigger a useForm update
    setChange(askEmailExistingCustomer);
  }, [askEmailExistingCustomer]);

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
    change,
  });

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  return (
    <Form onSubmit={handleForm}>
      {personType && (
        <>
          <Divider />
          <Title>
            {personType !== 'legal'
              ? i18n.t('Your information')
              : i18n.t('Company information')}{' '}
          </Title>
          <FlexWrapper>
            <TextInput
              name="idNumber"
              error={errors.idNumber}
              onChange={(val) => handleChange(val)}
              value={
                defaultValues.idNumber !== undefined
                  ? defaultValues.idNumber
                  : values.idNumber
              }
            >
              {personType !== 'legal'
                ? i18n.t('ID number')
                : i18n.t('Company registration number')}{' '}
              *
            </TextInput>
          </FlexWrapper>
          {askEmailExistingCustomer && (
            <FlexWrapper>
              <TextInput
                name="email"
                error={errors.email}
                onChange={(val) => handleChange(val)}
                value={
                  defaultValues.email !== undefined
                    ? defaultValues.email
                    : values.email
                }
              >
                {i18n.t('No email address found please enter your email here')}{' '}
                *
              </TextInput>
            </FlexWrapper>
          )}
          <ButtonContainer>
            <Required>* {i18n.t('Required fields')}</Required>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Next')}
              </LoadingActionButton>
            ) : (
              <StyledActionButton
                type="submit"
                value="Next"
                data-test-id="policy_holder_flow_submit"
              >
                {i18n.t('Next')}
              </StyledActionButton>
            )}
          </ButtonContainer>
        </>
      )}
    </Form>
  );
};

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e4e4e4;
  margin-top: 0;
  margin-bottom: 3rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;

export default FlowPolicyHolderInfoFormLegal;
