import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import HelEcoGanIcon from '../assets/icons/HelEcoGanIcon';
import HelPremiumGanIcon from '../assets/icons/HelPremiumGanIcon';
import HelValueGanIcon from '../assets/icons/HelValueGanIcon';
import {
  ActionButton,
  SmallLoader,
  IconStatusCheck,
  IconActionDropDown,
} from 'wg-fe-ui';
import { useParams } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { createHealthPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import { handlePolicyStartDate } from '../helpers/dateService';

const getBoolean = (value) => {
  if (value === 'yes') return 1;
  return 0;
};

const getPayload = (_data, selected, currentHealth) => {
  const payload = {
    SavePolicy: _data?.SavePolicy || 'False',
    Customer: {
      Oid: _data?.ganCustomer?.Oid,
    },
    MedicalStatus: 1,
    DoYouSmoke: getBoolean(_data?.healths[currentHealth]?.DoYouSmoke),
    ExistingInsuranceCompany: {
      InsuranceCompany: _data?.healths?.[currentHealth]?.insurance_company_name,
    },
    ExpiringDateOfYourCurrentPolicy: _data?.healths?.[currentHealth]
      ?.insurance_company_name
      ? _data?.healths?.[currentHealth]?.start_of_contract || null
      : null,
    ExistingInsuranceCompanyPrice: _data?.healths?.[currentHealth]
      ?.insurance_company_name
      ? _data?.healths?.[currentHealth]?.insurance_price || null
      : null,
    NumberOfCigarettesPerDay:
      _data?.healths[currentHealth]?.NumberOfCigarettesPerDay,
    AnyChronicOrLongTermMedicalCondition: getBoolean(
      _data?.healths[currentHealth]?.AnyChronicOrLongTermMedicalCondition
    ),
    PolicyStartDate: handlePolicyStartDate(
      _data.healths[currentHealth]?.policy_details?.starting_date
    ),
    PolicyEndDate: new Date(
      _data.healths[currentHealth]?.policy_details?.ending_date
    ).toISOString(),
    policyDuration:
      _data.healths[currentHealth]?.policy_details?.insurance_duration,
    WeightInKg: _data?.healths[currentHealth]?.WeightInKg,
    HeightInCM: _data?.healths[currentHealth]?.HeightInCM,
    CurrentlyDisabledPregnantOrUnableToPerformNormalActivities: getBoolean(
      _data?.healths[currentHealth]
        ?.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
    ),
    EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth: getBoolean(
      _data?.healths[currentHealth]
        ?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
    ),
    AnyDiseasesOfTheCirculatorySystemHeart: getBoolean(
      _data?.healths[currentHealth]?.AnyDiseasesOfTheCirculatorySystemHeart
    ),
    SpecifyTheDisease: _data?.healths[currentHealth]?.SpecifyTheDisease,
    DateOfRecentBloodPressureReading:
      _data?.healths[currentHealth]?.DateOfRecentBloodPressureReading,
    BloodPresureResult: _data?.healths[currentHealth]?.BloodPresureResult,
    MedicationTaken: _data?.healths[currentHealth]?.MedicationTaken,
    NutritionalAndMetabolicDiseases: getBoolean(
      _data?.healths[currentHealth]?.NutritionalAndMetabolicDiseases
    ),
    DiabetesDisease: _data?.healths[currentHealth]?.DiabetesDisease,
    DiabetesDiagnosed: _data?.healths[currentHealth]?.DiabetesDiagnosed,
    ControlledByDiet: getBoolean(
      _data?.healths[currentHealth]?.ControlledByDiet
    ),
    DiabetesMedicationTaken:
      _data?.healths[currentHealth]?.DiabetesMedicationTaken,
    RecentHba1cTest: _data?.healths[currentHealth]?.RecentHba1cTest,
    ResultHba1c: _data?.healths[currentHealth]?.ResultHba1c,
    HowManyMonthsOfAYearDoYouLiveAbroad:
      _data?.healths[currentHealth]?.HowManyMonthsOfAYearDoYouLiveAbroad,
    AreBothOYourParentsSmokers: getBoolean(
      _data?.healths[currentHealth]?.AreBothOYourParentsSmokers
    ),
    NoOfCigarettesParentsSmoke:
      _data?.healths[currentHealth]?.NoOfCigarettesParentsSmoke,
    ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus: getBoolean(
      getBoolean(
        _data?.healths[currentHealth]
          ?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
      )
    ),

    YoungestParentAge: _data?.healths[currentHealth]?.YoungestParentAge
      ? parseInt(_data?.healths[currentHealth]?.YoungestParentAge)
      : null,
    Doctor: _data?.healths[currentHealth]?.Doctor,
    LastDateSeenByTheDoctor:
      _data?.healths[currentHealth]?.LastDateSeenByTheDoctor,
    ReasonOfLastVisit: _data?.healths[currentHealth]?.ReasonOfLastVisit,
    CanYouProvideMedicalCertificate: getBoolean(
      _data?.healths[currentHealth]?.CanYouProvideMedicalCertificate
    ),
    CurrentMediclaPolicyFromDate:
      _data?.healths[currentHealth]?.CurrentMediclaPolicyFromDate,
    CurrentMediclaPolicyToDate:
      _data?.healths[currentHealth]?.CurrentMediclaPolicyToDate,
    InsuranceScheduleFromPreviousInsuranceCompany: null,
    MedicalInPatient: getBoolean(
      _data?.healths[currentHealth]?.MedicalInPatient
    ),
    InPatientAnnualLimit: _data?.healths[currentHealth]?.InPatientAnnualLimit,
    InPatientDeductible: _data?.healths[currentHealth]?.InPatientDeductible,
    InPatientCoInsurance: _data?.healths[currentHealth]?.InPatientCoInsurance,
    NewBornBaby: getBoolean(_data?.healths[currentHealth]?.NewBornBaby),
    MedicalOutPatient: 0,
    GeographicalArea: _data?.healths[currentHealth]?.GeographicalArea,
    ReimbursementMethod:
      _data?.healths[currentHealth]?.ReimbursementMethod || 1,
    MedicalCallCenteService: getBoolean(
      _data?.healths[currentHealth]?.MedicalCallCenteService
    ),
    TravelTransportationAndOutOfGeorgaphycalArea: 0,
    DeathBenefit: getBoolean(_data?.healths[currentHealth]?.DeathBenefit),
    DeathAmountInsured: _data?.healths[currentHealth]?.DeathAmountInsured,
    MortgageFinancialInstitution:
      _data?.healths[currentHealth]?.MortgageFinancialInstitution,
    DreadDisease: _data?.healths[currentHealth]?.DreadDisease,
    DreadSumInsured: _data?.healths[currentHealth]?.DreadSumInsured,
    PermanentTotalOrPartialDisability: getBoolean(
      _data?.healths[currentHealth]?.PermanentTotalOrPartialDisability
    ),
    DisabilityAmount: _data?.healths[currentHealth]?.DisabilityAmount,
    MedicalDiseases:
      _data?.healths[currentHealth]?.MedicalDiseases?.map((item) => ({
        Oid: item.value,
      })) || undefined,
  };
  const clone = {};
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] === 'undefined') clone[key] = null;
    else clone[key] = payload[key];
  });

  switch (selected) {
    case 'Economy':
      clone.MedicalInPatient = 1;
      clone.InPatientAnnualLimit = 1;
      clone.GeographicalArea = 1;
      clone.InPatientDeductible = 3;
      clone.ReimbursementMethod = 1;
      clone.InPatientCoInsurance = 1;
      clone.NewBornBaby = 0;
      clone.MedicalOutPatient = 0;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
      clone.DeathBenefit = 0;
      clone.DreadDisease = 0;
      clone.PermanentTotalOrPartialDisability = 0;
      clone.MedicalCallCenteService = 0;
      break;
    case 'Value':
      clone.NewBornBaby = 1;
      clone.MedicalInPatient = 1;
      clone.GeographicalArea = 3;
      clone.InPatientAnnualLimit = 2;
      clone.InPatientDeductible = 5;
      clone.ReimbursementMethod = 3;
      clone.InPatientCoInsurance = 1;
      clone.MedicalOutPatient = 0;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
      clone.DeathBenefit = 0;
      clone.DreadDisease = 0;
      clone.PermanentTotalOrPartialDisability = 0;
      clone.MedicalCallCenteService = 1;
      break;
    case 'Premium':
      clone.MedicalOutPatient = 1;
      clone.MedicalInPatient = 1;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 1;
      clone.DeathBenefit = 1;
      clone.DeathAmountInsured = 1;
      clone.GeographicalArea = 4;
      clone.DreadDisease = 1;
      clone.DreadSumInsured = 5;
      clone.InPatientCoInsurance = 1;
      clone.InPatientAnnualLimit = 3;
      clone.InPatientDeductible = 8;
      clone.NewBornBaby = 1;
      clone.MedicalCallCenteService = 1;
      clone.ReimbursementMethod = 4;
      break;
    default:
      break;
  }
  return clone;
};

const EconomyDetails = [
  {
    value: 'In patient & day patient treatment',
  },
];

const ValueDetails = [
  {
    value: 'In patient & day patient treatment',
  },
  { value: 'New born baby benefit' },
  { value: 'Medical call center service' },
  { value: '24Hrs health helpline assistance' },
  { value: '24Hrs casuality care assistance' },
  { value: 'Second opinion' },
  { value: 'Legal protection assistance' },
];

const PremiumDetails = [
  {
    value: 'In patient & day patient treatment',
  },
  { value: 'Worldwide' },
  { value: 'New born baby benefit' },
  { value: 'Medical call center service' },
  { value: 'Out patient treatment' },
  { value: 'Travel transportation and Out of Area benefit' },
  { value: 'Death benefit' },
  { value: 'Dread benefit' },
  { value: 'Permanent total disability benefit' },
  { value: 'Medical call center service' },
  { value: '24Hrs health helpline assistance' },
  { value: '24Hrs casuality care assistance' },
  { value: 'Second opinion' },
  { value: 'Legal protection assistance' },
];

const packages = [
  {
    value: 'Economy',
    description: '',
    benefits: EconomyDetails,
  },
  {
    value: 'Value',
    description: '',
    benefits: ValueDetails,
  },
  {
    value: 'Premium',
    description: '',
    benefits: PremiumDetails,
  },
];

const FlowHealthInsuranceOptionsMobile = () => {
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [open, setOpen] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createHealthPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentHealth)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, failureCount } = useQuery(
    ['policyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  useEffect(() => {
    const packageIndex = packages.findIndex(
      (pack) =>
        pack.value === storage?.data?.healths[currentHealth]?.insurance_pack
    );
    if (packageIndex > -1) setSelected(packages[packageIndex]);
  }, []);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  function iconType(icon) {
    switch (icon) {
      case 'Value': //terraced
        return <HelValueGanIcon />;
      case 'Economy': //semi-detached
        return <HelEcoGanIcon />;
      case 'Premium':
        return <HelPremiumGanIcon />;
      default:
        return <HelValueGanIcon />;
    }
  }

  const submitHandler = () => {
    patchStorage({
      payload: {
        insurance_pack: selected?.value,
        insurance_info: policyData[selected?.value]?.Data,
      },
      path: ['healths', currentHealth],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { health: currentHealth });
  };

  return (
    <Container>
      <LeftContainer>
        <TitleForm>{i18n.t('Choose your package')}</TitleForm>
        <TotalPremium>
          <p>{i18n.t('Your total premium')}</p>
          {isLoading ? (
            <UpdatingContainer>
              <SmallLoader color="" />
            </UpdatingContainer>
          ) : (
            <span>
              <strong>
                {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
              </strong>{' '}
            </span>
          )}
        </TotalPremium>
        <PackagesContainer>
          {packages?.map((insurance, i) => (
            <Extra
              key={i}
              selected={selected?.value === insurance.value}
              onClick={() => setSelected(insurance)}
            >
              <span>{iconType(insurance.value)}</span>
              <p>{insurance.value}</p>
            </Extra>
          ))}
        </PackagesContainer>
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
      </LeftContainer>
      <RightContainer>
        {selected != null && (
          <MenuWrapper open={open} onClick={() => setOpen(!open)}>
            <span>
              <span>{i18n.t('Specifics')}</span>
              <p open={open}>
                {open ? i18n.t('See less') : i18n.t('See more')}
                <IconActionDropDown open={open} />
              </p>
            </span>
            <EconomicPackageDetailsContainer open={open}>
              {selected?.benefits?.map((detail, i) => (
                <DetailContainer key={i}>
                  <IconStatusCheck />
                  <p>{detail.value}</p>
                </DetailContainer>
              ))}
              <div>{selected?.description}</div>
            </EconomicPackageDetailsContainer>
          </MenuWrapper>
        )}
        <ButtonContainer>
          <ActionButton
            onClick={() => submitHandler()}
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Specify')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const MenuWrapper = styled.div`
  box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  overflow: auto;

  font-family: ${({ theme }) => theme.font};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 11rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 7rem;
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.7rem 2rem 0 2rem;
    > span {
      color: ${({ theme }) => theme.brand.primary};
      font-weight: 900;
      font-size: 2rem;
    }
    p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
      > svg {
        margin-left: 1rem;
        transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
        transition: transform 700ms cubic-bezier(1, 0, 0, 1);
        > path {
          stroke: ${({ theme }) => theme.brand.primary};
        }
      }
    }
  }
`;

const TotalPremium = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1.6rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
  }
  span {
    color: #0e0e0e;
    display: flex;
    font-size: 2.1rem;
    font-weight: 550;
    strong {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  height: 100%;
  align-items: flex-end;
  width: 100%;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: ${({ open }) => (!open ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: scroll;
  div:last-child {
    font-size: 1.25rem;
    color: rgba(48, 55, 61, 0.66);
    padding: 2rem;
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  p {
    align-self: center;
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.brand.primary}` : '2px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-evenly;
  width: 100%;

  > p {
    font-size: 1.2rem;
    text-align: center;
  }
  span > svg {
    height: 4rem;
    width: 4rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  justify-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: scroll;
  flex-direction: column;
  padding: 2rem;
`;

const RightContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin: 1rem 0;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 35% 65%;
  width: 100%;
`;

const UpdatingContainer = styled.div`
  height: 2rem;
`;

const ErrorBox = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-items: center;
`;

export default FlowHealthInsuranceOptionsMobile;
