import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import useForm from '../hooks/useForm.js';
import {
  TextInput,
  SearchSelectInput,
  ActionButton,
  IconActionChevronLeft,
} from 'wg-fe-ui';
import { numberWithCommas } from '../helpers/stringService';
import * as Yup from 'yup';
import {
  string,
  date,
  number,
  oidObject,
} from '../constants/validationSchemas.js';
import {
  fuelTypeGan,
  annualMileageOptions,
} from '../constants/FlowSearchSelectData.js';
import OptionsPriceInput from './VATInputSwitcherV2';
import i18n from '../i18n';
import { formatISODate } from '../helpers/dateService.js';
import DateInput from './DateInput';
import { patchStorage } from '../helpers/storeService.js';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';

const FlowManualVehicleForm = ({
  defaultValues,
  renderNextRoute,
  existingVehicle,
  data,
}) => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [annualMileage, setAnnualMileage] = useState(0);
  const SelectPlaceholder = i18n.t('Choose your option');
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    brand: string.required,
    series: string.required,
    motor_type: string.notRequired,
    transmission_type: string.notRequired,
    registration_first: date,
    power: number.required,
    engine_capacity: number.required,
    UnladenWeight: number.required,
    AnnualMileage: oidObject.number,
    invoice_value: Yup.number()
      .typeError(i18n.t(`not a number`))
      .min(1, i18n.t(`Value cannot be 0`))
      .max(200000, i18n.t(`Value cannot be more than 200.000`))
      .required(i18n.t(`required`)),
  });

  const { errors, values, handleChange } = useForm({
    validationSchema,
    mode: 'onChange',
  });

  const [handleAnnualMileage] = useDebouncedCallback((foundOption) => {
    handleChange({
      name: 'AnnualMileage',
      value: {
        Oid: foundOption.value,
      },
    });
  }, 400);

  useEffect(() => {
    const foundOption = annualMileageOptions.find(
      (option) => annualMileage >= option.from && annualMileage <= option.to
    );

    foundOption && handleAnnualMileage(foundOption);
  }, [annualMileage]);

  const handleValueRemapping = () => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((name) => {
        switch (name) {
          case 'Make':
            handleChange({
              name: 'brand',
              value: defaultValues[name].VehicleMakeDesc,
            });
            break;
          case 'Model':
            handleChange({
              name: 'series',
              value: defaultValues[name].VehicleModelDesc,
            });
            break;
          case 'EngineHP':
            handleChange({
              name: 'power',
              value: defaultValues[name],
            });
            break;
          case 'Supply':
            handleChange({
              name: 'motor_type',
              value: defaultValues[name].PowerSupply,
            });
            break;
          case 'ManufacturedDate':
            handleChange({
              name: 'registration_first',
              value: formatISODate(defaultValues[name]),
            });
            break;
          case 'annualMileage':
            // handleChange({
            //   name: 'annualMileage',
            //   value: formatISODate(defaultValues[name]),
            // });
            setAnnualMileage(defaultValues[name]);
            break;
          case 'AnnualMileage':
            if (!defaultValues?.annualMileage) {
              setAnnualMileage(
                annualMileageOptions?.find(
                  (item) => item.value === defaultValues[name]?.Oid
                )?.to
              );
            }
            break;
          case 'EngineCapacity':
            handleChange({
              name: 'engine_capacity',
              value: defaultValues[name],
            });
            break;

          default:
            handleChange({ name, value: defaultValues[name] });
            break;
        }
      });
    }
  };

  useEffect(() => {
    handleValueRemapping();
  }, [defaultValues]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    if (!isEmpty(errors)) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
      return;
    }

    patchStorage({
      payload: { ...values, annualMileage },
      path: ['cars', [currentVehicle], 'vehicle_info'],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setFuelTypeOptions(
      fuelTypeGan.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  console.log(existingVehicle, data);

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  const transmissionTypes = [
    { value: 'auto', label: i18n.t('Automatic') },
    { value: 'man', label: i18n.t('Manual') },
  ];

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Scroll>
        <FlexWrapper>
          <StyledTextInput
            name="brand"
            error={errors.brand}
            disabled={existingVehicle && data?.Make}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.brand}
          >
            <p>{i18n.t('Brand')} *</p>
          </StyledTextInput>
          <StyledTextInput
            name="series"
            disabled={existingVehicle && data?.Model}
            error={errors.series}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.series}
          >
            <p>{i18n.t('Model')} *</p>
          </StyledTextInput>
        </FlexWrapper>
        <FlexWrapper>
          <SearchSelectInput
            onChange={(val) => {
              handleSelectChange(val, 'motor_type');
            }}
            name="motor_type"
            disabled={existingVehicle && data?.Supply}
            error={errors.motor_type}
            options={fuelTypeOptions}
            value={handleSelectValue(fuelTypeOptions, 'motor_type')}
            placeholder={SelectPlaceholder}
          >
            <LabelName>{i18n.t('Fuel type')}</LabelName>
          </SearchSelectInput>
          <SearchSelectInput
            name="transmission_type"
            isClearable
            value={handleSelectValue(transmissionTypes, 'transmission_type')}
            placeholder={i18n.t('Choose your option')}
            options={transmissionTypes}
            onSelected={({ value }) => {
              // setTransmissionType(value);
            }}
          >
            <LabelName>{i18n.t('Transmission type')}</LabelName>
          </SearchSelectInput>
        </FlexWrapper>
        <FlexWrapper>
          <StyledDateInput
            iso
            name="registration_first"
            error={errors.registration_first}
            disabled={existingVehicle && data?.ManufacturedDate}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.registration_first}
          >
            <p>{i18n.t('First registration date')} *</p>
          </StyledDateInput>
          <StyledTextInput
            name="power"
            error={errors.power}
            disabled={existingVehicle && data?.EngineHP}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.power}
          >
            <StyledLabel>{i18n.t('HP')} *</StyledLabel>
            <p className="valueType">{i18n.t('HP')}</p>
          </StyledTextInput>
        </FlexWrapper>
        <FlexWrapper>
          <StyledTextInput
            name="engine_capacity"
            disabled={existingVehicle && data?.EngineCapacity}
            error={errors.engine_capacity}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.engine_capacity}
          >
            <StyledLabel>{i18n.t('Engine capacity')} *</StyledLabel>
            <p className="valueType">{i18n.t('CC')}</p>
          </StyledTextInput>
          <StyledTextInput
            name="UnladenWeight"
            disabled={existingVehicle && data?.UnladenWeight}
            error={errors.UnladenWeight}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.UnladenWeight}
          >
            <StyledLabel>{i18n.t('Unladen weight')} *</StyledLabel>
            <p className="valueType">{i18n.t('KG')}</p>
          </StyledTextInput>
        </FlexWrapper>
        <RangeWrapper>
          <LabelName>
            {i18n.t('How many km/year will the vehicle travel approximately?')}
          </LabelName>
          <div>
            <RangeContainer>
              <p className="min">0 km</p>
              <p className="max">{numberWithCommas(200000) + ' km'}</p>
            </RangeContainer>
            <MaxConsecutiveDays>
              <input
                type="range"
                min="0"
                max="200000"
                value={annualMileage}
                onChange={(e) => setAnnualMileage(e.target.value)}
                step="2500"
              />
              <p>
                <span> {annualMileage} </span>
                km
              </p>
            </MaxConsecutiveDays>
          </div>
        </RangeWrapper>
        <OptionsPriceInput
          name="invoice_value"
          id="invoice_value"
          error={errors.invoice_value}
          onChange={({ value, isVATInclusive }) =>
            handleChange({
              name: 'invoice_value',
              value: isVATInclusive ? (value / 1.19).toFixed(2) : value,
            })
          }
          value={values.price}
          defaultValue={defaultValues?.invoice_value}
        >
          {i18n.t('Insured value')}
        </OptionsPriceInput>
      </Scroll>
      <ButtonContainer>
        <Required>* {i18n.t('Required fields')}</Required>
        <div>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {i18n.t('Next')}
          </ActionButton>
        </div>
      </ButtonContainer>
    </Form>
  );
};

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const RangeWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 6rem;
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const LabelName = styled.p`
  color: #8990a3;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledLabel = styled.div`
  color: #8990a3;
  height: 1rem;
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: ${(props) => (props.error ? `#F74040` : ` #8990a3`)};
  }
  p.valueType {
    border-left: 1px solid #e4e4e4;
    margin: 0;
    padding: 1.4rem;
    position: absolute;
    right: 0rem;
    top: 2.5rem;
  }
  input {
    border: 0.1rem solid ${(props) => (props.error ? `#F74040` : ` #e4e4e4`)};
    border-radius: 0.5rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  & > div {
    width: 48%;
  }

  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const StyledDateInput = styled(DateInput)`
  margin-bottom: 3rem;
  p {
    color: #8990a3;
  }
`;

export default FlowManualVehicleForm;
