export const BASE_URL = process.env.REACT_APP_API_HOST;
export const AUTH_HOST = process.env.REACT_APP_AUTH_HOST;
export const GAN_API = process.env.REACT_APP_GAN_API;
export const PROXY = process.env.REACT_APP_PROXY;
export const PROXY_CREDS = process.env.REACT_APP_PROXY_CREDS;
export const GANDIRECT_USERNAME = process.env.REACT_APP_GANDIRECT_USERNAME;
export const GANDIRECT_PASSWORD = process.env.REACT_APP_GANDIRECT_PASSWORD;
export const WG_GANDIRECT_PROXY_USERNAME =
  process.env.REACT_APP_WG_GANDIRECT_PROXY_USERNAME;
export const WG_GANDIRECT_PROXY_PASSWORD =
  process.env.REACT_APP_WG_GANDIRECT_PROXY_PASSWORD;
export const CONFIGCAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY;
export const AFFINITY_BASE_URL = process.env.REACT_APP_AFFINITY_BASE_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const RELEASE = process.env.REACT_APP_RELEASE;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const PROJECT_NAME = 'wg-fe-gan-direct';
