/* eslint-disable react/jsx-key */
import React from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import CheckBoxBorderedBlockWithIcon from '../components/CheckBoxBorderedBlockWithIcon.jsx';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { getThemeObject } from '../helpers/themingService.js';
import GanDirectAvatar from '../assets/images/gan-direct-avatar.svg';

const FlowSafetySystem = () => {
  const defaultImg = GanDirectAvatar;
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const carBrandImage = data.car_img || defaultImg;
  const colors = getThemeObject(affinity).brand.primary;

  const drive_assistance_system = data.drive_assistance_system || {
    automatic_emergency_braking: false,
    adaptive_cruise_control: false,
    lane_support: false,
    blind_spot_check: false,
    attention_assist: false,
    ecall: false,
    automatic_parking: false,
    parking_aid: false,
  };

  function handleChange({ type, value }) {
    drive_assistance_system[type] = value;
  }

  const questions = [
    {
      type: 'automatic_emergency_braking',
      value: 'AEB (Automatic Emergency Braking)',
    },
    {
      type: 'adaptive_cruise_control',
      value: 'Adaptive Cruise Control',
    },
    { type: 'lane_support', value: 'Lane Support' },
    { type: 'blind_spot_check', value: 'Dodehoekcontrole' },
    { type: 'attention_assist', value: 'Attention Assist' },
    { type: 'ecall', value: 'eCall' },
    { type: 'automatic_parking', value: 'Automatisch Parkeren' },
    { type: 'parking_aid', value: 'Parking Aid' },
  ];

  function handleSubmit(drive_assistance_system) {
    patchStorage({
      payload: { drive_assistance_system },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                `I also need to know what driving aid systems are available on your car`
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <TitleForm>{i18n.t('Safety Systems')}</TitleForm>
        <div>
          {questions.map((question) => {
            return (
              <CheckBoxBorderedBlockWithIcon
                key={question}
                initial={drive_assistance_system[question.type]}
                onChange={(value) => handleChange(value)}
                question={question}
                colors={colors}
              />
            );
          })}
        </div>
        <ButtonContainer>
          <StyledActionButton
            onClick={() => handleSubmit(drive_assistance_system)}
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: White;
  background-color: default;
  font-weight: normal;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 71% 25%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: '';
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: 50%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  justify-self: center;
`;

export default FlowSafetySystem;
