import React from 'react';
import styled from 'styled-components';
import LogoSrc from '../assets/images/brand-logos/new-gandirect-logo.svg';

import PropertyGanIcon from '../assets/icons/PropertyGanIcon';
import UserGanIcon from '../assets/icons/UserGanIcon';
import CartGanIcon from '../assets/icons/CartGanIcon';
import LandlordGanIcon from '../assets/icons/LandlordGanIcon';

import FlowProgressBarStepContainer from './FlowProgressBarStepContainer';

const icons = [
  <UserGanIcon key={0} size={50} />,
  <PropertyGanIcon key={1} size={50} />,
  <LandlordGanIcon key={2} size={50} />,
  <CartGanIcon key={3} size={50} />,
];

const FLowHomeProgressBar = () => {
  return (
    <StyledBar>
      <Logo src={LogoSrc} />

      <ProgressBar>
        {icons.map((icon, key) => {
          return (
            <FlowProgressBarStepContainer icon={icon} key={key} id={key + 1} />
          );
        })}
      </ProgressBar>
    </StyledBar>
  );
};

const Logo = styled.img`
  margin-left: 0.25rem;
  width: ${({ width }) => width};
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 85%;
  margin-bottom: 3rem;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default FLowHomeProgressBar;
