import React from 'react';

function NoGanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 70 70"
    >
      <g clipPath="url(#clip0_2727_1232)">
        <path
          stroke="url(#paint0_linear_2727_1232)"
          strokeMiterlimit="10"
          strokeWidth="2.482"
          d="M34.995 68.91A33.914 33.914 0 1168.91 34.996 33.949 33.949 0 0134.995 68.91v0z"
        ></path>
        <path
          fill="#024284"
          fillRule="evenodd"
          d="M48.115 48.877a1.24 1.24 0 101.755-1.754L37.255 34.5l12.622-12.615a1.24 1.24 0 10-1.755-1.756L35.501 32.746 22.884 20.123a1.24 1.24 0 10-1.755 1.754L33.745 34.5 21.122 47.116a1.24 1.24 0 101.755 1.755l12.622-12.616 12.616 12.622z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2727_1232"
          x1="4.695"
          x2="65.304"
          y1="17.507"
          y2="52.493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#024284"></stop>
          <stop offset="0.81" stopColor="#004587"></stop>
          <stop offset="0.82" stopColor="#68A93E"></stop>
        </linearGradient>
        <clipPath id="clip0_2727_1232">
          <path fill="#fff" d="M0 0H70V70H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoGanIcon;
