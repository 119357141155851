import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import i18n from '../i18n';
import Tooltip from '../components/Tooltip';
import {
  ActionButton,
  Alert,
  IconStatusCheck,
  IconActionDropDown,
  PieProgressIndicator,
  IconActionClose,
  IconCertificateFilled,
  SmallLoader,
  SearchSelectInput,
} from 'wg-fe-ui';
import MobileCoverDetail from '../components/MobileCoverDetail';
import { createCarPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import { getCoverDetails } from '../helpers/CoverDetailsService.js';
import { handlePolicyStartDate } from '../helpers/dateService.js';

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

let firstRender = true;

const FlowCarPremiumSummaryMobile = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [open, setOpen] = useState();
  const [openDiscounts, setOpenDiscounts] = useState();
  const [selected, setSelected] = useState();
  const { cars } = storage?.data;
  const [policyData, setPolicyData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [discounts, setDiscounts] = useState([]);
  const [cachedData, setCachedData] = useState({});
  const [cachedPayload] = useState(
    cars?.[currentVehicle]?.car_package_cover_details
  );
  const [coverData, setCoverData] = useState(storage?.data);

  const getPayload = (_data, _selected, _currentVehicle) => {
    let payload = {
      SavePolicy: _data?.SavePolicy || 'False',
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      VehicleOrVessel: 0,
      Vehicle: {
        Oid: _data.cars?.[_currentVehicle]?.vehicle_info?.Oid,
      },
      MotorStatus: 1,
      ExistingInsuranceCompany: {
        InsuranceCompany: _data?.cars?.[currentVehicle]?.insurance_company_name,
      },
      ExpiringDateOfYourCurrentPolicy: _data?.cars?.[currentVehicle]
        ?.insurance_company_name
        ? _data?.cars?.[currentVehicle]?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.cars?.[currentVehicle]?.parcel
        ?.main_building?.insurance_company_name
        ? _data?.cars?.[currentVehicle]?.insurance_price || null
        : null,
      PolicyHolderAsDriver: _data.cars?.[_currentVehicle]?.main_driver
        ?.same_as_policy
        ? 1
        : 0,
      MotorDrivers: [
        {
          Oid: _data.cars[currentVehicle].main_driver?.same_as_policy
            ? _data?.ganCustomer?.Oid
            : _data?.cars?.[currentVehicle]?.main_driver?.Oid,
        },
        ..._data?.cars?.[currentVehicle]?.extra_drivers?.map((item) => ({
          Oid: item?.Oid,
        })),
      ],
      EuroCompPackageBenefit: _data?.EuroCompPackageBenefit?.value || '',
      LossOfUseBenefit: _data?.LossOfUseBenefit?.value || '',
      GuranteeAssetProtectionBenefit:
        _data?.GuranteeAssetProtectionBenefit?.value || '',
      ClaimProtectionBenefit: _data?.ClaimProtectionBenefit || '',
      RiotsStrikesBenefit: _data?.RiotsStrikesBenefit?.value || '',
      ConvulsionOfNatureBenefit: _data?.ConvulsionOfNatureBenefit?.value || '',
      PersonalEffectsBenefit: _data?.PersonalEffectsBenefit || '',
      MedicalExpensesBenefit: _data?.MedicalExpensesBenefit?.value || '',
      StayMobile: _data.cars?.[_currentVehicle]?.replacement_vehicle ? 1 : 0,
      DrivingOtherCarsBenefit: _data?.DrivingOtherCarsBenefit || '',
      UnlimitedWindscreenBenefit: _data?.UnlimitedWindscreenBenefit || '',
      ExcessProtectionBenefit: _data?.ExcessProtectionBenefit?.value || '',
      Trailer: _data?.Trailer?.value || 0,
      TrailerChassisNumber: '',
      Relay: _data?.Relay || 0,
      PolicyStartDate: handlePolicyStartDate(
        _data.cars[currentVehicle]?.insurance_package_info?.starting_date
      ),
      PolicyEndDate: new Date(
        _data.cars[currentVehicle]?.insurance_package_info?.ending_date
      ).toISOString(),
      PolicyDuration: 12,
      VehicleCoverType: 2,
      VehicleExcess: 250,
      AutomaticRenewal: _data?.AutomaticRenewal || false,
      InsuredAmount: _data.cars?.[_currentVehicle]?.vehicle_info?.invoice_value,
    };
    if (firstRender && cachedPayload) payload = cachedPayload;
    if (_data?.StayMobile) payload.StayMobile = 1;
    else if (_data?.cars?.[_currentVehicle] && firstRender)
      payload.StayMobile = _data?.cars?.[_currentVehicle].replacement_vehicle
        ? 1
        : 0;
    else payload.StayMobile = 0;

    switch (_selected) {
      case 'Basic Plus':
        payload.VehicleCoverType = 0;
        break;
      case 'Economic':
        payload.VehicleCoverType = 1;
        break;
      case 'Value':
        payload.VehicleCoverType = 2;
        break;
      case 'Premium':
        payload.VehicleCoverType = 2;
        payload.EuroCompPackageBenefit = 1;
        break;
      default:
        payload.VehicleCoverType = 9;
        break;
    }
    firstRender = false;
    return payload;
  };

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createCarPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(coverData, selected, currentVehicle, storage)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['policyData', selected, coverData],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    if (coverData !== storage?.data && policyData[selected]) {
      setCachedData({ ...cachedData, [selected]: { ...coverData } });
      refetch();
    }
  }, [coverData]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (cachedData[selected]) setCoverData(cachedData[selected]);
    else setCoverData(storage?.data);
  }, [selected]);

  useEffect(() => {
    if (cars?.[currentVehicle]?.insurance_pack) {
      setSelected(cars?.[currentVehicle].insurance_pack);
    }
  }, []);

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      MultipolicyDiscount: 10,
      FiveYearsNoClaimDiscount: 5,
      TenYearsNoClaimDiscount: 10,
      FleetDiscount: 5,
      MyRideDiscount: 50,
      NewGraduateDiscount: 5,
      BrandNewCarDiscount: 10,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  async function handleNextRoute() {
    const payload = getPayload(coverData, selected, currentVehicle);
    payload.SavePolicy = 'true';
    const resp = await handleApiCall(payload);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        car_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['cars', currentVehicle],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Car > +currentVehicle + 1) {
        let nextVehicle = 1 + +currentVehicle;
        renderNextRoute(1, { vehicle: nextVehicle });
      } else if (storage?.data?.insurances?.Health > 0) {
        renderNextRoute(2, { health: 0 });
      } else {
        renderNextRoute(2);
      }
    }
  }

  useEffect(() => {
    if (storage?.data?.insurance_pack) {
      setSelected(storage?.data?.insurance_pack?.value);
    }
  }, []);

  const premium_summary_items = [
    {
      value: 'MIF',
      amount: parseFloat(
        policyData[selected]?.Data?.MIF?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  const insurance_packages = [
    { value: 'Basic Plus', label: 'Basic Plus' },
    { value: 'Economic', label: 'Economic' },
    { value: 'Value', label: 'Value' },
    { value: 'Premium', label: 'Premium' },
  ];

  function handleCoverDetailClick(detail) {
    setShowModal(true);
    setModalContent(detail);
  }

  function receiveChanges(val) {
    const _coverData = { ...coverData };
    if (!val?.toggle) {
      delete _coverData[val?.key];
      return setCoverData(_coverData);
    }
    if (val?.toggleOnValue) {
      _coverData[val?.key] = val?.toggleOnValue || '';
      setCoverData(_coverData);
    } else if (val?.toggleOnAmount) {
      _coverData[val?.key] = val?.toggleOnAmount || '';
      setCoverData(_coverData);
    } else if (val.amount) {
      _coverData[val?.key] = val?.amount || '';
      setCoverData(_coverData);
    } else {
      _coverData[val?.key] = val?.amount || '';
    }
  }

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData]);

  return (
    <Container>
      {!showModal ? (
        <>
          {isFetching && policyData[selected]?.Data?.TotalwithFees && (
            <UpdatingContainer>
              <SmallLoader color="" />
            </UpdatingContainer>
          )}
          <TopContainer>
            <SearchSelectInput
              initial={insurance_packages[1]}
              value={
                selected != null
                  ? { label: selected, value: selected }
                  : insurance_packages[1]
              }
              options={insurance_packages}
              onSelected={(val) => setSelected(val.value)}
            />
            <TotalPremium>
              <p>{i18n.t('Your total premium')}</p>
              {policyData[selected]?.Data?.TotalwithFees ? (
                <span>{policyData[selected]?.Data?.TotalwithFees} &euro;</span>
              ) : (
                isFetching && <SmallLoader color="" />
              )}
            </TotalPremium>
          </TopContainer>
          <BottomContainer>
            <div>
              <Title>{i18n.t('Premium summary')}</Title>
              <PremiumItems>
                {premium_summary_items.map((item) => {
                  return (
                    <div>
                      <span>{item.value}</span>
                      <p>{item.amount}</p>
                    </div>
                  );
                })}
              </PremiumItems>
            </div>
            <EnabledDiscountsAccordion
              open={open}
              onClick={() => setOpen(!open)}
            >
              <span>
                <span>{i18n.t('Enabled discounts')}</span>

                <p open={open}>
                  <DiscountCount>
                    <div>
                      <PieProgressIndicator
                        percentage={
                          discounts.length === 0
                            ? 0
                            : (discounts?.filter(
                                (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                              )?.length /
                                discounts?.length) *
                              100
                        }
                      />
                    </div>
                    <p>
                      {
                        discounts?.filter(
                          (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                        )?.length
                      }
                      /{discounts?.length}
                    </p>
                  </DiscountCount>
                  <StyledDropDownIcon open={open}>
                    <IconActionDropDown color="white" />
                  </StyledDropDownIcon>
                </p>
              </span>
              <div className="accordion-content">
                <AccordionInfo>
                  {discounts
                    ?.filter(
                      (discount) =>
                        discount[1] !== '0,00' && discount[0] !== 'Discount'
                    )
                    ?.map((discount) => (
                      <div className="discount-row">
                        <IconCertificateFilled color="#8990A3" />
                        <p className="discount-label">{i18n.t(discount[0])}</p>
                        <p className="discount-price">{discount[1]} &euro;</p>
                      </div>
                    ))}
                  <Header>Possible discounts</Header>
                  {discounts
                    ?.filter(
                      (discount) =>
                        discount[1] === '0,00' && discount[0] !== 'Discount'
                    )
                    ?.map((discount) => (
                      <div className="discount-row">
                        <IconCertificateFilled color="#8990A3" />
                        <p className="discount-label">{i18n.t(discount[0])}</p>
                        <p className="discount-price">
                          -{getDiscountPrice(discount[0])} &euro;
                        </p>
                      </div>
                    ))}
                </AccordionInfo>
              </div>
            </EnabledDiscountsAccordion>
            <CoverDetailAccordion open={openDiscounts}>
              <span onClick={() => setOpenDiscounts(!openDiscounts)}>
                <span>{i18n.t('Cover detail')}</span>
                <p open={openDiscounts}>
                  {openDiscounts ? i18n.t('See less') : i18n.t('See more')}
                  <StyledDropDownIcon open={openDiscounts}>
                    <IconActionDropDown color="white" />
                  </StyledDropDownIcon>
                </p>
              </span>
              <StyledAlert icon>
                {i18n.t(
                  'Please complete the missing information highlighted by the exclamation mark'
                )}
              </StyledAlert>
              <div className="accordion-content">
                {selected === 'Economic'
                  ? getCoverDetails('Economic', cachedData).map((details) => {
                      return (
                        <div>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle == null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <p>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </p>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : selected === 'Basic Plus'
                  ? getCoverDetails('Basic Plus', cachedData).map((details) => {
                      return (
                        <div>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            prices={data?.Data}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle == null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <p>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </p>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : selected === 'Value'
                  ? getCoverDetails('Value', cachedData).map((details) => {
                      return (
                        <div>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            prices={data?.Data}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle == null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <p>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </p>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : selected === 'Premium'
                  ? getCoverDetails('Premium', cachedData).map((details) => {
                      return (
                        <div>
                          <CoverDetailsContent
                            click={details?.toggle != null}
                            prices={data?.Data}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoverDetailClick(details);
                            }}
                          >
                            <div>
                              <span>
                                {details?.toggle === true ? (
                                  <IconStatusCheck />
                                ) : details?.toggle == null ? (
                                  <IconStatusCheck />
                                ) : details?.toggle === false ? (
                                  <StyledIconActionClose />
                                ) : null}
                              </span>
                              <p>
                                {details.value}{' '}
                                {details?.toggle != null && (
                                  <Tooltip toggleIcon="!" display="none" />
                                )}
                              </p>
                            </div>
                          </CoverDetailsContent>
                        </div>
                      );
                    })
                  : null}
              </div>
            </CoverDetailAccordion>
          </BottomContainer>
          <ContinueContainer>
            <ContinueActionButton onClick={() => handleNextRoute()}>
              {i18n.t('Continue')}
            </ContinueActionButton>
          </ContinueContainer>
        </>
      ) : (
        <>
          {modalContent && (
            <MobileCoverDetail
              details={modalContent}
              sendChanges={receiveChanges}
            />
          )}
          <ContinueContainer>
            <PremiumContainer>
              <p>Premium</p>
              <p>
                {isLoading ? (
                  <SmallLoader color="" />
                ) : (
                  <span>
                    {policyData[selected]?.Data?.TotalwithFees} &euro;
                  </span>
                )}
              </p>
            </PremiumContainer>
            <ContinueActionButton onClick={() => setShowModal(false)}>
              {i18n.t('Back')}
            </ContinueActionButton>
          </ContinueContainer>
        </>
      )}
    </Container>
  );
};

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0.8rem;
  & > p {
    margin-right: 0.8rem;
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin: 2rem 0 1.5rem 1rem;
`;

const AccordionInfo = styled.div`
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2rem;
  }

  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }
    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const StyledAlert = styled(Alert)`
  align-self: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  > div {
    color: ${({ theme }) => theme.brand.primary};
    border-color: ${({ theme }) => theme.brand.primary};
  }
  margin-bottom: 2.4rem;
`;

const EnabledDiscountsAccordion = styled.div`
  background-color: #f5f6f7;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow-y: auto;

  font-family: ${({ theme }) => theme.font};
  position: static;
  bottom: 0;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 18rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1.6rem;
  > .accordion-content {
    display: ${({ open }) => (!open ? 'none' : 'flex')};
    flex-direction: column;
    overflow-y: auto;
  }
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem;
    > span {
      font-size: 1.6rem;
      font-weight: 550;
    }
    > p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
    }
  }
`;

const StyledDropDownIcon = styled.div`
  transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
  transition: transform 0.3s;
  background-color: ${({ theme }) => theme.brand.primary};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-left: 1.6rem;
  & > svg {
    display: block;
    width: 90%;
  }
`;

const StyledIconActionClose = styled(IconActionClose)`
  path {
    fill: red !important;
  }
`;

const CoverDetailAccordion = styled(EnabledDiscountsAccordion)`
  position: static;
  background-color: transparent;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 12rem)')};
`;

const TotalPremium = styled.div`
  align-self: center;
  flex-direction: column;
  height: fit-content;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
  @media (max-width: 312px) {
    p {
      font-size: 1.4rem;
    }
    span {
      font-size: 1.6rem;
    }
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  div:last-child {
    border-top: 1px solid #e5e5e5;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    > p {
      font-weight: 550;
    }
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 30px;
`;

const CoverDetailsContent = styled.div`
  cursor: ${({ click }) => (click ? 'pointer' : 'unset')};
  background-color: white;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 0.34rem rgba(0, 0, 0, 0.08);
  border: 0.1rem solid #8990a1;
  border-radius: 0.5rem;
  padding: 1.2rem 0;
  margin-bottom: 0.8rem;

  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    > p {
      align-self: center;
      color: rgba(48, 55, 61, 0.66);
      font-size: 1.6rem;
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      align-items: center;
    }
    > span {
      width: 5rem;
      > div {
        padding: 0;
      }
    }
  }
  input[type='checkbox'] {
    height: 25px;
    margin: 0;
    width: 40px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  grid-template-columns: 46% 46%;
  grid-gap: 2rem;
  height: 8rem;
  margin: 2rem;
  margin-top: 0;
  padding-bottom: 2rem;
  div:first-child {
    min-height: unset;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const BottomContainer = styled.div`
  height: auto;
  > div:first-child {
    padding: 2rem;
    padding-top: 1rem;
  }
`;

const ContinueActionButton = styled(ActionButton)`
  width: 90%;
  margin: 0 auto;
`;

const ContinueContainer = styled.div`
  padding-bottom: 2rem;
  margin-top: auto;
`;

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: flex-end;

  > div {
    width: 3rem;
    margin-right: 0.8rem;
    transform: translateY(0.7rem);
  }
  > p {
    color: black;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

const PremiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8990a3;
  width: 90%;
  margin: 0 auto;
  height: 3rem;
  margin-bottom: 2.4rem;
  > p:first-child {
    font-weight: 550;
  }
  > p {
    font-size: 1.6rem;
  }
`;

export default FlowCarPremiumSummaryMobile;
