import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import FlowGeneralInformationForm from '../components/FlowGeneralInformationForm.jsx';
import { ChatContext } from '../contexts/ChatContext';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import FlowChatBox from '../components/FlowChatBox';

const FlowHealthInformation = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { addQuestion } = useContext(ChatContext);
  const { id, affinity, insuranceType, currentHealth } = useParams();

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { healths } = data;
    if (healths[currentHealth] !== null) {
      setDefaultValues(healths[currentHealth]);
    }
    const question = {
      message: i18n.t('Please fill in the general information questions'),
      slug: 'general-information',
    };
    addQuestion(question);
  }, []);

  function handleSubmit(values) {
    const {
      CanYouProvideMedicalCertificate,
      CurrentMediclaPolicyFromDate,
      CurrentMediclaPolicyToDate,
    } = values || {};

    const payload = {
      CanYouProvideMedicalCertificate,
      CurrentMediclaPolicyFromDate,
      CurrentMediclaPolicyToDate,
    };

    patchStorage({
      payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { health: currentHealth });
  }

  return (
    <SplitContainer>
      {!isMobile() && <FlowChatBox />}
      <RightSplit>
        <FlowGeneralInformationForm
          handleFormValues={handleSubmit}
          defaultValues={
            defaultValues.health ? defaultValues.health : defaultValues
          }
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightSplit = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;

export default FlowHealthInformation;
