import React, { useState, useLayoutEffect, FocusEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SearchSelectCustomMenuList from './SearchSelectCustomMenuList';
import SearchSelectCustomDropdownIndicator from './SearchSelectCustomDropdownIndicator';
import SearchSelectCustomOption from './SearchSelectCustomOption';
import { bp, customScrollBar } from '../../helpers/styledComponenstService';
import i18n from '../../i18n';
import useBreakPoint from '../../hooks/useBreakPoint';
import ConditonalWrapper from './ConditionalWrapper';
import MobileSelectInput from './MobileSelectInput';
import { isMobile as isMobileDevice } from 'react-device-detect';

interface OptionLabel {
  label_secondary?: string;
  label: string;
  value: string | number;
}

interface OptionProps {
  label: string;
  value?: unknown;
}

interface ReturnOptionProps {
  name: string;
  label: string;
  value?: unknown;
}

interface Props {
  dataTestId?: string;
  async?: boolean;
  className?: string;
  onSelected: (p: ReturnOptionProps) => void;
  name: string;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: string;
  options?: OptionProps[];
  loading?: boolean;
  loadOptions?: (
    p: string,
    callback: (p: unknown) => void // callback for useDebouncedCallback
  ) => Promise<OptionProps[]> | void;
  withCheckmark?: boolean;
  initial?: OptionProps;
  value?: OptionProps | OptionProps[];
  noOptionMessage?: string;
  loadingMessage?: string;
  fallbackMessage?: string;
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  onFallback?: () => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (name: string) => void;
  inputValue?: string;
  cacheOptions?: boolean;
  prefilled?: boolean;
  enableMobileSearch?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

const SearchSelectInputV3 = React.forwardRef<HTMLElement, Props>(
  (
    {
      async = false,
      className,
      onSelected,
      dataTestId,
      loading = false,
      loadOptions,
      options = [],
      name,
      value = null,
      disabled = false,
      children = '',
      initial = null,
      noOptionMessage = i18n.t('No options'),
      loadingMessage = i18n.t('Loading'),
      fallbackMessage,
      placeholder = i18n.t('Choose your option'),
      isMulti = false,
      error,
      isClearable = false,
      withCheckmark = false,
      onFallback,
      onBlur = () => null,
      cacheOptions = true,
      prefilled = false,
      enableMobileSearch = false,
      menuPlacement,
      ...otherProps
    },
    ref
  ) => {
    const sortedOptions = options.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    // Check wich input needs to be shown
    const Input = async ? StyledAsyncSelect : StyledSelect;
    const { width } = useBreakPoint();
    const isMobile = isMobileDevice || width < bp.laptop;
    const [components, setComponents] = useState({});
    const [selected, setSelected] = useState<OptionProps | OptionProps[]>();
    const handleChange = (option: OptionProps) => {
      setSelected(option || []);
      if (!option) {
        onSelected({ name, label: '', value: undefined });
        return;
      }
      onSelected({
        name,
        label: option.label,
        value: Array.isArray(option) ? option : option.value,
      });
    };

    const mobileProps = {
      ...(isMobile && { menuIsOpen: isMobile }),
    };

    useLayoutEffect(() => {
      const _components = {
        IndicatorSeparator: () => null,
        DropdownIndicator: SearchSelectCustomDropdownIndicator,
      };
      if (fallbackMessage) {
        _components['MenuList'] = SearchSelectCustomMenuList;
      }
      setComponents(_components);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isMobile)
        setComponents((prev) => ({
          ...prev,
          MenuList: SearchSelectCustomMenuList,
        }));
    }, [isMobile]);

    return (
      <Container className={className} data-test-id={dataTestId}>
        <Label>
          {children && <Title className="SearchSelect_label">{children}</Title>}
          <ConditonalWrapper
            condition={isMobile}
            wrapper={(children) => (
              <MobileSelectInput
                enableSearch={enableMobileSearch || async}
                selected={selected || value}
                placeholder={placeholder}
                error={error}
                disabled={disabled}
              >
                {children}
              </MobileSelectInput>
            )}
          >
            <Input
              ref={ref}
              openMenuOnFocus={isMobile}
              isDisabled={disabled}
              isMobile={isMobile}
              onChange={handleChange}
              name={name}
              options={sortedOptions}
              value={selected || value}
              noOptionsMessage={() => noOptionMessage}
              placeholder={loading ? loadingMessage : placeholder}
              classNamePrefix="Select"
              isMulti={isMulti}
              isClearable={isClearable}
              closeMenuOnSelect={!isMulti}
              menuShouldScrollIntoView={true}
              error={!!error}
              cacheOptions={cacheOptions}
              loadOptions={loadOptions}
              innerProps={{
                fallbackOptionMessage: fallbackMessage,
                onFallback,
                withCheckmark,
              }}
              defaultOptions
              formatOptionLabel={(props: OptionLabel) => (
                <SearchSelectCustomOption
                  isSelected={
                    ((selected as OptionProps)?.value ||
                      (value as OptionProps)?.value) === props?.value
                  }
                  {...props}
                />
              )}
              components={components}
              onBlur={() => onBlur(name)}
              prefilled={prefilled}
              maxMenuHeight={220}
              menuPlacement={menuPlacement}
              {...otherProps}
              {...mobileProps}
            />
          </ConditonalWrapper>
          {error && <Error>{error}</Error>}
        </Label>
      </Container>
    );
  }
);

const Title = styled.span`
  margin-bottom: 0.8rem;
`;

const Error = styled.p`
  color: ${({ theme }) => theme.status.error};
  margin-top: 0.4rem;
  text-align: right;
  font-size: 1.4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  transition: 0.2s;
  line-height: 120%;
  color: #8990a3;
`;

const styles = css<{
  hasValue: boolean;
  error?: boolean;
  prefilled?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  justify-self: center;
  position: relative;
  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : '#C1C1C1'};
  }

  .Select {
    width: 100%;
    &__control {
      background-color: ${({ isDisabled, theme }) =>
        isDisabled ? `#E9EAEC` : 'white'};
      border-radius: 5px;
      height: 4.5rem;
      border: 1px solid ${({ theme }) => `#DBDDE1` || '#E2E3E6'};
      position: relative;
      border-color: ${({ error, theme }) => error && theme.status.error};

      ${({ prefilled }) =>
        prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}

      :hover {
        border-color: ${({ theme }) => `#DBDDE1` || '#E2E3E6'};
      }

      &--is-focused {
        border-color: ${({ theme }) => theme.brand.primary};
        box-shadow: none;
        z-index: ${({ isMobile }) => !isMobile && '10'};

        :hover {
          border-color: ${({ theme }) => theme.brand.primary};
        }
      }
    }

    &__placeholder {
      color: #c1c1c1;
    }

    &__value-container {
      padding: 0 1.2rem;
      font-size: 1.6rem;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: #000;
      font-size: 1.6rem;
      line-height: 120%;

      & .custom-option_secondary-label,
      .custom-option_radioIcon {
        display: none;
      }
    }

    &__menu {
      font-size: 1.6rem;
      background: none;
      border: 1px solid ${({ theme }) => `#DBDDE1` || '#E2E3E6'};
      box-shadow: none;
      border-radius: 5px;
      margin: 0;
    }

    &__dropdown-indicator {
      display: ${({ isDisabled }) => isDisabled && 'none'};
    }

    &__menu-list {
      background-color: white;

      ${customScrollBar}
    }

    &__menu-notice--no-options,
    &__menu-notice--loading {
      min-height: 4.8rem;
      display: flex;
      align-items: center;
    }

    &__option {
      background: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #f0f1f3;
      min-height: ${({ isMobile }) => (isMobile ? '5.2rem' : '4.5rem')};
      padding: ${({ isMobile }) => (isMobile ? '0.4rem' : '0.4rem 1.2rem')};

      &:last-of-type {
        border-bottom: none;
      }

      & .custom-option_container {
        width: 100%;
        display: flex;
        align-items: center;
        padding: ${({ isMobile }) => (isMobile ? '0.8rem' : '0')};
        min-height: ${({ isMobile }) => (isMobile ? '4.4rem' : 'unset')};
        border-radius: 6px;
      }

      & .custom-option_primary-label {
        color: #000;
        font-size: 1.6rem;
      }

      & .custom-option_secondary-label {
        font-size: 1.2rem;
      }

      &--is-focused,
      &:hover {
        background-color: ${({ isMobile }) => (isMobile ? 'white' : `#8990A3`)};
      }

      & .custom-option_container:active {
        background-color: ${({ isMobile }) => isMobile && `#E3F8FE`};
      }

      &--is-selected {
        background-color: white;

        & .custom-option_primary-label {
          color: ${({ theme }) => theme.brand.primary};
        }
        & .custom-option_secondary-label {
          color: ${({ theme }) => theme.brand.secondary};
        }

        ${({ isMobile }) =>
          isMobile &&
          css`
            & .custom-option_container {
              background-color: #e3f8fe;
            }

            & .custom-option_radioIcon {
              border-color: ${({ theme }) => theme.brand.primary};
            }
            & .custom-option_radioIcon_fill {
              display: block;
            }
          `}
      }
    }
  }
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${styles}
`;

const StyledSelect = styled(Select)`
  ${styles}
`;

SearchSelectInputV3.displayName = 'SearchSelectInputV3';
export default SearchSelectInputV3;
