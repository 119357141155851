import React from 'react';
import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { getThemeObject } from '../helpers/themingService';
import BrokerBackground from '../assets/images/bg-broker';
import Div100vh from 'react-div-100vh';
import FlowRoutes from '../router/FlowRouter';
import FlowProgressBar from '../components/FlowProgressBar';
import FlowHomeProgressBar from '../components/FlowHomeProgressBar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
const queryClient = new QueryClient();
const Flow = () => {
  const dataUri = `url("data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<BrokerBackground colors={getThemeObject()} />)
  )}")`;

  const { affinity, insuranceType } = useParams();

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Helmet>
          <link rel="apple-touch-icon" href="favicon.ico" />
          <link rel="icon" href="favicon.ico" />
          <meta name="description" content={'GAN Direct tarification flow'} />
          <title>{i18n.t(affinity)} Insurance</title>
        </Helmet>
        <Div100vh>
          <Background img={dataUri}>
            {insuranceType === 'home' ? (
              <FlowHomeProgressBar />
            ) : (
              <FlowProgressBar />
            )}
            <ContentContainer
              mobile={
                insuranceType === 'allMobile' ||
                insuranceType === 'carMobile' ||
                insuranceType === 'homeMobile'
              }
            >
              <Children
                mobile={
                  insuranceType === 'allMobile' ||
                  insuranceType === 'carMobile' ||
                  insuranceType === 'homeMobile'
                }
              >
                <FlowRoutes />
              </Children>
              {insuranceType !== 'allMobile' &&
              insuranceType !== 'carMobile' &&
              insuranceType !== 'homeMobile' ? (
                <BorderAccent>
                  <span></span>
                </BorderAccent>
              ) : (
                ''
              )}
            </ContentContainer>
          </Background>
        </Div100vh>
      </>
    </QueryClientProvider>
  );
};

const Children = styled.div`
  min-height: 100%;
  overflow: auto;
  width: 100%;
  padding: ${({ mobile }) => (mobile ? '0' : '5rem')};
  display: flex;
`;

const BorderAccent = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25rem;
  width: 5rem;
  border-right: 1rem solid #1b6ea6;
  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 1rem;
    border-bottom: ${({ theme }) => `1rem solid ${theme.brand.light}`};
    width: 5rem;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  max-width: 1440px;
  width: ${({ mobile }) => (mobile ? '100%' : '90%')};
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: ${({ mobile }) => (mobile ? '0' : '1rem 1rem 0 1rem')};
  max-height: 1024px;
  height: ${({ mobile }) => (mobile ? '100%' : '80%')};
  background-color: white;
  overflow: auto;
  position: relative;

  @media screen and (max-width: 425px) {
    max-height: none;
    height: 95%;
  }

  @media screen and (max-width: 425px) {
    padding: ${({ mobile }) => (!mobile ? '2rem 1.5rem' : '0')};
  }
`;

const Background = styled.main`
  align-items: center;
  background-color: ${({ theme }) => theme.background.primary};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font};
  height: 100%;
  justify-content: center;
  overflow: hidden;
`;

export default Flow;
