import React from 'react';
import styled from 'styled-components';
import { object } from 'prop-types';
import BrandLogo from './BrandLogo';

const LicensePlateResBalloon = ({ carInfo }) => {
  const { Color, Make, Model, RegNumber } = carInfo;
  return (
    <Container>
      <BrandLogo brand={Make?.VehicleMakeDesc} />
      <div>
        <Title>
          {Make?.VehicleMakeDesc?.toLowerCase()}{' '}
          {Model?.VehicleModelDesc?.toLowerCase()}
        </Title>
        <StyledColor color={Color?.Color?.toLowerCase()}>
          {Color?.Color?.toLowerCase()}
        </StyledColor>
        <RegNumberItem>{RegNumber}</RegNumberItem>
      </div>
    </Container>
  );
};

const Title = styled.p`
  font-weight: 600;
`;

const Container = styled.div`
  text-transform: capitalize;
  margin-top: 1rem;
  display: flex;
  max-width: 100%;
  background: white;
  padding: 1rem;
  padding-right: 2.4rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #cccccc;

  & img {
    margin-right: 2rem;
  }
`;

const RegNumberItem = styled.p`
  display: flex;
  font-size: 1.4rem;
  color: #8990a3;
  align-items: center;
`;

const StyledColor = styled.p`
  display: flex;
  font-size: 1.4rem;
  color: #8990a3;
  align-items: center;
  &::before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    margin-right: 1rem;
    background-color: ${({ color }) => color};
  }
`;

LicensePlateResBalloon.propTypes = {
  carInfo: object,
};

export default LicensePlateResBalloon;
