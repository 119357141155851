import React, { useContext } from 'react';
import styled from 'styled-components';
import FlowChatBalloon from './FlowChatBalloon';
import { ChatContext } from '../contexts/ChatContext';
import { useParams } from 'react-router';

const FlowChatBox = () => {
  const { insuranceType } = useParams();
  const { chatMessages } = useContext(ChatContext);

  return (
    <FlowChatBoxContainer
      width="100%"
      mobile={
        insuranceType === 'allMobile' ||
        insuranceType === 'carMobile' ||
        insuranceType === 'homeMobile'
      }
    >
      {JSON.parse(JSON.stringify(chatMessages))
        .reverse()
        .map((chatMessage) => (
          <FlowChatBalloon
            left={chatMessage.isQuestion}
            question={chatMessage.slug}
          >
            {chatMessage.message}
          </FlowChatBalloon>
        ))}
    </FlowChatBoxContainer>
  );
};

const FlowChatBoxContainer = styled.div`
  width: ${({ width }) => width};
  padding: ${({ mobile }) => (mobile ? '0 2rem 2rem 2.5rem' : '0 4rem 0 2rem')};
  display: flex;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column-reverse;
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }

  @media (max-width: 768px) {
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  }
`;

export default FlowChatBox;
