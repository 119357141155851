import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useParams, useHistory } from 'react-router-dom';
import {
  ActionButton,
  IconActionChevronLeft,
  Label,
  SearchSelectInput,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import useRouting from '../hooks/useRouting';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';

import { string } from '../constants/validationSchemas.js';
import { duration_time } from '../constants/FlowSearchSelectData';
import {
  add,
  isFuture,
  isToday,
  parseISO,
  differenceInDays,
  isAfter,
} from 'date-fns';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeInsuranceSpecifics = () => {
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const durationTimeOptions = useMemo(
    () =>
      duration_time.map((item) => ({
        value: item.value,
        label: i18n.t(item.label),
      })),
    [duration_time]
  );
  const { id, affinity, insuranceType, currentHealth } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { healths } = data;
    if (healths?.[currentHealth]?.policy_details)
      defaultValuesHandler(healths?.[currentHealth]?.policy_details);
  }, []);

  function defaultValuesHandler(data) {
    Object.keys(data || {}).forEach((name) => {
      handleChange({ name, value: data[name] });
    });
  }

  function handleFuture(value) {
    return isToday(parseISO(value)) || isFuture(parseISO(value));
  }

  function handleFutureDays(value) {
    return differenceInDays(parseISO(value), new Date()) <= 35;
  }

  function handleEndAfterStart(value) {
    return isAfter(parseISO(value), parseISO(this.parent.starting_date));
  }

  function handleOtherEndDate(value) {
    return (
      differenceInDays(parseISO(value), parseISO(this.parent.starting_date)) <=
      365
    );
  }

  const SignupSchema = Yup.object().shape({
    insurance_duration: Yup.mixed().required(i18n.t(`required`)),
    starting_date: Yup.mixed()
      .test(
        'valid-start-date',
        i18n.t('The start date must be today or in the future'),
        handleFuture
      )
      .test(
        'validate-start-date-days',
        i18n.t('The start date cannot be after 35 days from today'),
        handleFutureDays
      ),
    ending_date: Yup.string().when('insurance_duration', {
      is: 0,
      then: string.required
        .test(
          'validate-end-date',
          i18n.t(
            'The end date cannot be more than 1 year after the start date'
          ),
          handleOtherEndDate
        )
        .test(
          'validate-end-date',
          i18n.t('The end date cannot be before the start date'),
          handleEndAfterStart
        ),
    }),
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
  });

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const submitHandler = () => {
    const payload = {
      policy_details: {
        ending_date: values.ending_date
          ? values.ending_date
          : add(new Date(values?.starting_date), {
              months: values?.insurance_duration,
            }).toISOString(),
        starting_date: new Date(values?.starting_date)?.toISOString(),
        insurance_duration: values?.insurance_duration,
      },
    };

    patchStorage({
      payload: payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { health: currentHealth });
  };

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      {!isMobile() && <FlowChatBox />}
      <RightContainer>
        <Title>{i18n.t('Policy details')}</Title>
        <SpecificsContainer>
          <div>
            <SelectInput
              name="insurance_duration"
              error={errors.insurance_duration}
              value={handleSelectValue(
                durationTimeOptions,
                'insurance_duration'
              )}
              onChange={(val) => {
                handleSelectChange(val, 'insurance_duration');
              }}
              options={durationTimeOptions}
              placeholder="Choose an option"
            >
              <StyledLabel>{i18n.t('Duration')} *</StyledLabel>
            </SelectInput>
            <DateInput
              name="starting_date"
              error={errors.starting_date}
              onChange={handleChange}
              value={values.starting_date}
            >
              <StyledLabel>{i18n.t('Starting date')} *</StyledLabel>
            </DateInput>
          </div>
          {values.insurance_duration === 0 && (
            <div>
              <DateInput
                name="ending_date"
                error={errors.ending_date}
                onChange={handleChange}
                value={values.ending_date}
              >
                <StyledLabel>{i18n.t('Ending date')} *</StyledLabel>
              </DateInput>
            </div>
          )}
        </SpecificsContainer>
        <ButtonContainer>
          <Required>* {i18n.t('Required fields')}</Required>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => handleSubmit(submitHandler)}
              type="submit"
              value="Submit"
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  align-self: center;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  min-height: 7rem;
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
  .Select__control {
    border-color: ${({ error }) => !error && '#e4e4e4'} !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-size: 1.5rem;
`;

const SpecificsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  > div {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    > * {
      grid-column: span 3 / auto;
    }
  }
  > p {
    color: #30373d;
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 550;
    font-size: 1.6rem;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
  @media (max-width: 850px) {
    > div > * {
      grid-column: span 6 / auto;
      height: 9rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeInsuranceSpecifics;
