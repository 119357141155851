import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import { ActionButton, IconActionChevronLeft, ToggleInput } from 'wg-fe-ui';
import FlowChatBox from '../components/FlowChatBox';
import { retrieveLicensePlateGan } from '../helpers/apiRouterService';
import _ from 'lodash';

const FlowSecondCarGeneralInfo = () => {
  const [carAlreadyRegistered, setCarAlreadyRegistered] = useState();
  const [loading, setLoading] = useState(false);
  const [, setIsLoading] = useState(false);
  const [isSecondHandCar] = useState();
  const [licensePlateError, setLicensePlateError] = useState();
  const [text, setText] = useState('');
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;

  function handleInputChange(e) {
    setLicensePlateError();
    const code = e.keyCode || e.which;
    if (code === 13) {
      handleSearch();
    }
    setText(e.currentTarget.value);
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    const question = {
      message: i18n.t(
        "Let's continue with the next car, is this <span>car already registered</span>?"
      ),
      slug: 'next-car',
    };
    addQuestion(question);
  }, []);

  async function handleSearch(e) {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    setIsLoading(true);
    const [resp, status] = await retrieveLicensePlateGan(text);
    if (carAlreadyRegistered) {
      await setIsLoading(false);
      setLoading(false);
      if (status === 204) {
        setLicensePlateError(
          'Please enter a valid registered registration number'
        );
        return;
      } else if (status !== 200) return;
      let car_information = {
        car_already_registered: carAlreadyRegistered,
        license_plate: text,
        second_hand_car: isSecondHandCar,
        vehicle_info: resp,
      };

      if (!_.isEqual(cars[cars.length - 1]?.vehicle_info, resp)) {
        cars.push(car_information);
        patchStorage({
          payload: { cars },
          id,
          affinity,
          insuranceType,
        });
      }
    } else {
      let car_information = {
        car_already_registered: carAlreadyRegistered,
      };
      cars.push(car_information);
      patchStorage({
        payload: { cars },
        id,
        affinity,
        insuranceType,
      });
    }

    const answer = {
      message: carAlreadyRegistered
        ? {
            car_information: resp,
            msg: i18n.t(`Yes, the registration number is`, {
              licensePlate: text.toUpperCase(),
            }),
          }
        : i18n.t('No'),
      slug: 'car_already_registered',
    };

    addAnswer(answer);

    renderNextRoute();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form>
          <div>
            <TitleForm>{i18n.t('Is your car already registered?')}</TitleForm>
            <AddressToInsureQ>
              <StyledButton
                active={carAlreadyRegistered}
                onClick={(e) => {
                  e.preventDefault();
                  setCarAlreadyRegistered(true);
                }}
              >
                {i18n.t('Yes')}
              </StyledButton>
              <StyledButton
                active={!carAlreadyRegistered}
                onClick={(e) => {
                  e.preventDefault();
                  setCarAlreadyRegistered(false);
                }}
              >
                {i18n.t('No')}
              </StyledButton>
            </AddressToInsureQ>
            {carAlreadyRegistered && (
              <RegisteredQuestion>
                <h3>{i18n.t('Did you buy this car secondhand?')}</h3>
                <ToggleInput
                  falseLabel={i18n.t('No')}
                  trueLabel={i18n.t('Yes')}
                />
                <TitleForm>
                  {i18n.t('What’s your registration number?')}
                </TitleForm>
                <LicenseLookup>
                  <LicenseInputContainer>
                    <LicenseInputLetter>CY</LicenseInputLetter>
                    <LicenseInput
                      onChange={handleInputChange}
                      onKeyPress={handleInputChange}
                      value={text}
                      type="text"
                    />
                  </LicenseInputContainer>
                  <LicenseError>{licensePlateError}</LicenseError>
                </LicenseLookup>
              </RegisteredQuestion>
            )}
          </div>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Next')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                onClick={handleSearch}
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const LicenseError = styled.div`
  color: #f74040;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const LicenseLookup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const LicenseInputContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const LicenseInputLetter = styled.div`
  align-items: center;
  background-color: #fbfbfb;
  border: 1px solid #ccc;
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: 0;
  color: #5b5550;
  display: flex;
  flex: 0 0 5rem;
  font-size: 2.5rem;
  font-weight: 900;
  height: 5rem;
  justify-content: center;
  line-height: 4.6rem;
  width: 5rem;
`;

const LicenseInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 0 0.5rem 0.5rem 0;
  flex: 1 0 auto;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  width: 20rem;

  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.brand.primary};
    outline: 0;
  }
`;

const RegisteredQuestion = styled.div`
  h3 {
    color: #8990a3;
    font-size: 1.4rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
  > div {
    margin-bottom: 2rem;
  }
`;

const AddressToInsureQ = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  width: 100%;
  justify-content: space-between;
  padding: 3rem 0;
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  height: 4.5rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;
const RightContainer = styled.div`
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export default FlowSecondCarGeneralInfo;
