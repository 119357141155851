import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import FlowAddressContainer from '../components/FlowAddressContainer';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import { ActionButton, IconActionChevronLeft, ToggleInput } from 'wg-fe-ui';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { getNestedObject } from '../helpers/objectService';
import { retrieveAddressInfo } from '../helpers/apiRouterService';
import { useFlowStore } from '../contexts/FlowContext';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';
import FlowChatBox from '../components/FlowChatBox';
import { financial_institutions } from '../constants/FinancialInstitutionsData';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';

const FlowRiskAddress = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const [PHAddressIsToBeInsured, setPHAddressIsToBeInsured] = useState(true);
  const [isLandlord, setIsLandlord] = useState();
  const [error, setError] = useState();
  const [policyHolderAddress, setPolicyHolderAddress] = useState();
  const [propertyMortaged, setPropertyMortgaged] = useState(false);
  const [flowStore] = useFlowStore();
  const { addGanRiskAddress } = flowStore || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
    buildingName: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (isLandlord != null) {
      setError();
    }
  }, [isLandlord]);

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Is this the address you wish to insure If not please fill in the address that you would like to insure'
      ),
      slug: 'risk-address',
    };
    addQuestion(question);
    loadInitValues();
  }, []);

  const loadInitValues = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    if (data?.risk_addresses[currentProperty]?.FinancialInstitution) {
      handleChange({
        name: 'financial_institution',
        value: data?.risk_addresses[currentProperty]?.FinancialInstitution,
      });
    }

    // Address
    if (data?.risk_addresses[currentProperty] != null) {
      if (data?.risk_addresses[currentProperty].property_mortaged != null) {
        setPropertyMortgaged(
          data.risk_addresses[currentProperty].property_mortaged
        );
      }
      if (data?.risk_addresses[currentProperty].is_landlord != null) {
        setIsLandlord(data.risk_addresses[currentProperty].is_landlord);
      }
    }

    if (data?.risk_addresses?.[currentProperty] != null) {
      const riskAddress =
        getNestedObject(data, [
          'risk_addresses',
          [currentProperty],
          'address',
        ]) || {};
      const {
        street,
        city,
        zipcode,
        boxnr,
        housenr,
        buildingName,
      } = riskAddress;
      const tempData = {
        streetName: street,
        municipalityName: city,
        postalCode: zipcode,
        boxNumber: boxnr,
        streetNumber: housenr,
        buildingName: buildingName,
      };
      Object.keys(tempData).forEach((key) =>
        tempData[key] === undefined ? delete tempData[key] : {}
      );
      setPolicyHolderAddress(tempData);
    } else {
      const riskAddress =
        getNestedObject(data, ['policy_holder_address']) || {};
      const {
        streetName,
        municipalityName,
        postalCode,
        boxNumber,
        streetNumber,
        buildingName,
      } = riskAddress;
      const tempData = {
        streetName: streetName,
        municipalityName: municipalityName,
        postalCode: postalCode,
        boxNumber: boxNumber,
        streetNumber: streetNumber,
        buildingName: buildingName,
      };
      Object.keys(tempData).forEach((key) =>
        tempData[key] === undefined ? delete tempData[key] : {}
      );
      setPolicyHolderAddress(tempData);
    }
  };

  useEffect(() => {
    defaultValuesHandler();
  }, [PHAddressIsToBeInsured]);

  useEffect(() => {
    if (PHAddressIsToBeInsured) {
      setDefaultValues(policyHolderAddress);
    } else {
      setDefaultValues({});
      defaultValuesHandler({});
    }
  }, [PHAddressIsToBeInsured]);

  useEffect(() => {
    defaultValuesHandler();
  }, [policyHolderAddress]);

  function receiveNewAddress(address) {
    setDefaultValues(address);
    defaultValuesHandler();
  }

  function defaultValuesHandler() {
    if (PHAddressIsToBeInsured && policyHolderAddress != null) {
      Object.keys(policyHolderAddress || {}).forEach((name) => {
        handleChange({ name, value: policyHolderAddress[name] });
      });
    } else {
      Object.keys(defaultValues || {}).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (values) => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    if (isLandlord == null) {
      setError('Please answer all of the above questions');
      return;
    } else {
      setError();
    }
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
      buildingName,
    } = PHAddressIsToBeInsured ? policyHolderAddress : values;

    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: 'CY',
      boxnr: boxNumber,
      city: municipalityName,
    });

    const [respGan] = await addGanRiskAddress(
      {
        StreetName: streetName,
        HouseNumber: streetNumber.toLowerCase(),
        ZipCode: postalCode,
        BuildingName: buildingName,
      },
      data?.ganCustomer?.Oid
    );

    if (status / 100 === 2) {
      const element = {
        message: {
          address: {
            street: streetName,
            zipcode: postalCode,
            housenr: streetNumber.toLowerCase(),
            country_code: 'CY',
            boxnr: boxNumber,
            city: municipalityName,
          },
          blankImage: resp.aerial_images_blank_satellite,
          image: resp.aerial_images_marked_satellite,
        },
        slug: 'address_response',
      };

      addAnswer(element);

      const { financial_institution } = values;
      respGan.property_mortaged = propertyMortaged;
      respGan.FinancialInstitution = propertyMortaged
        ? financial_institution
        : null;
      respGan.is_landlord = isLandlord;

      delete resp.parcel.main_building.surface;
      delete resp.parcel.main_building.house_type;

      patchStorage({
        payload: { ...resp, ...respGan },
        path: ['risk_addresses', currentProperty],
        id,
        affinity,
        insuranceType,
      });
    } else {
      const { financial_institution } = values;
      respGan.property_mortaged = propertyMortaged;
      respGan.FinancialInstitution = propertyMortaged
        ? financial_institution
        : null;
      respGan.is_landlord = isLandlord;

      const tempResp = {
        parcel: {
          main_building: {},
        },
      };

      patchStorage({
        payload: { ...tempResp, ...respGan },
        path: ['risk_addresses', currentProperty],
        id,
        affinity,
        insuranceType,
      });
    }
    setLoading(false);
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <div>
          <SectionContainer>
            <TitleForm>
              {i18n.t('Is this the address you wish to insure')}
            </TitleForm>
            <AddressToInsureQ>
              <StyledButton
                active={PHAddressIsToBeInsured}
                onClick={() => {
                  // e.preventDefault();
                  setPHAddressIsToBeInsured(true);
                }}
              >
                {i18n.t('Yes')}
              </StyledButton>
              <StyledButton
                active={!PHAddressIsToBeInsured}
                onClick={() => {
                  // e.preventDefault();
                  setPHAddressIsToBeInsured(false);
                }}
              >
                {i18n.t('No')}
              </StyledButton>
            </AddressToInsureQ>

            {PHAddressIsToBeInsured ? (
              <AddressContainer>
                <HomeCatGanIcon />
                <div>
                  <p>{`${policyHolderAddress?.streetName} ${policyHolderAddress?.streetNumber},`}</p>
                  <p>{`${policyHolderAddress?.postalCode} ${policyHolderAddress?.municipalityName}`}</p>
                </div>
              </AddressContainer>
            ) : (
              <>
                <TitleForm>
                  {i18n.t('What adress would you like to insure?')}
                </TitleForm>
                <FlowAddressContainer
                  title={i18n.t(`Fill in the address you would like to insure`)}
                  errors={errors}
                  values={values}
                  defaultValues={defaultValues}
                  sendAddress={receiveNewAddress}
                  handleChange={handleChange}
                />
              </>
            )}
          </SectionContainer>
          <SectionContainer>
            <TitleForm>{i18n.t('Are you a tenant or landlord?')}</TitleForm>
            <OwnerType>
              <StyledButton
                active={isLandlord}
                onClick={() => {
                  // e.preventDefault();
                  setIsLandlord(true);
                }}
              >
                {i18n.t('Landlord')}
              </StyledButton>
              <StyledButton
                active={isLandlord === false}
                onClick={() => {
                  // e.preventDefault();
                  setIsLandlord(false);
                }}
              >
                {i18n.t('Tenant')}
              </StyledButton>
            </OwnerType>
          </SectionContainer>
          <div>
            <Label>{i18n.t('Is this property mortgaged?')}</Label>
            <ToggleInput
              name="property_mortaged"
              falseLabel={i18n.t('No')}
              trueLabel={i18n.t('Yes')}
              checked={propertyMortaged}
              onChange={() => setPropertyMortgaged(!propertyMortaged)}
            />
          </div>
          {propertyMortaged && (
            <div>
              <SearchSelectInputV2
                name="financial_institution"
                error={errors.financial_institution}
                value={handleSelectValue(
                  financial_institutions,
                  'financial_institution'
                )}
                onChange={(val) => {
                  handleChange({
                    name: 'financial_institution',
                    value: val ? val.value : '',
                  });
                }}
                options={financial_institutions}
                // onSelected={(val) => setInsuranceDuration(val)}
                placeholder="Choose an option"
              >
                {i18n.t('Select the financial institution')}
              </SearchSelectInputV2>
            </div>
          )}
        </div>
        <form onSubmit={handleForm}>
          <Error>{error}</Error>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Next')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const SectionContainer = styled.div`
  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Error = styled.div`
  font-size: 1.2rem;
  color: #f74040;
  margin-bottom: 1rem;
  text-align: center;
`;

const Label = styled.h2`
  color: #8990a3;
  font-size: 1.4rem;
  margin-top: 2rem;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const AddressToInsureQ = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
`;

const OwnerType = styled(AddressToInsureQ)`
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  max-width: 21.3rem;
  width: 48%;
  height: 5.3rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }

  &:first-of-type  {
    margin-right: 2.4rem;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-color: #e4e4e4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 1.6rem;
  max-width: 45rem;

  > svg {
    max-width: 5rem;
    height: 100%;
    width: 100%;
    margin-right: 1.6rem;
  }

  > div {
    font-size: 1.6rem;
    line-height: 120%;
    font-weight: 500;

    > p:last-child {
      color: #8990a3;
      font-weight: 400;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export default FlowRiskAddress;
