import React from 'react';
import { Switch } from 'react-router';
import FlowPolicyHolderLogin from '../pages/FlowPolicyHolderLogin';
import FlowPolicyHolderUserInfo from '../pages/FlowPolicyHolderUserInfo';
import FlowPolicyHolderUserInfoForm from '../pages/FlowPolicyHolderUserInfoForm';
import FlowPolicyHolderAddress from '../pages/FlowPolicyHolderAddress';
import FlowSelectInsurancesMobile from '../pages/FlowSelectInsurancesMobile';

import FlowRiskAddressMobile from '../pages/FlowRiskAddressMobile';
import FlowActualRiskAddressMobile from '../pages/FlowActualRiskAddressMobile';
import FlowRiskAddressMortagedMobile from '../pages/FlowRiskAddressMortagedMobile';
import FlowSecondRiskAddressMobile from '../pages/FlowSecondRiskAddressMobile';
import FlowHomeOwnerMobile from '../pages/FlowHomeOwnerMobile';
import FlowHomeBuildingType from '../pages/FlowHomeBuildingType';
import FlowHomeConstruction from '../pages/FlowHomeConstruction';
import FlowHomeBusinessPurposeRooms from '../pages/FlowHomeBusinessPurposeRooms';
import FlowHomeExistingPropertyInsuranceQuestionMobile from '../pages/FlowHomeExistingPropertyInsuranceQuestionMobile';
import FlowHomeExistingPropertyInsuranceInformationMobile from '../pages/FlowHomeExistingPropertyInsuranceInformationMobile';
import FlowInsureExtrasMobile from '../pages/FlowInsureExtrasMobile';

import RouteHandler from './RouteHandler';

const MobileRouter = (props) => {
  console.log(props);

  return (
    <Switch>
      <RouteHandler
        path="/:affinity/:insuranceType/session/login/:id"
        component={FlowPolicyHolderLogin}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/user-info/:id"
        component={FlowPolicyHolderUserInfo}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/fill-user-info/:id"
        component={FlowPolicyHolderUserInfoForm}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/user-address-mobile/:id"
        component={FlowPolicyHolderAddress}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/select-insurances-mobile/:id"
        component={FlowSelectInsurancesMobile}
      />

      {/* Home */}
      <RouteHandler
        path="/:affinity/:insuranceType/session/risk-address-mobile/:currentProperty/:id"
        component={FlowRiskAddressMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/address-to-insure/:currentProperty/:id"
        component={FlowActualRiskAddressMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/risk-address-mortaged-mobile/:currentProperty/:id"
        component={FlowRiskAddressMortagedMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/homeowner/:currentProperty/:id"
        component={FlowHomeOwnerMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-building-type/:currentProperty/:id"
        component={FlowHomeBuildingType}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-construction/:currentProperty/:id"
        component={FlowHomeConstruction}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/business-purpose-rooms/:currentProperty/:id"
        component={FlowHomeBusinessPurposeRooms}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-policy-mobile/:currentProperty/:id"
        component={FlowHomeExistingPropertyInsuranceQuestionMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/property-insurance-policy-information-mobile/:currentProperty/:id"
        component={FlowHomeExistingPropertyInsuranceInformationMobile}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-options-home-mobile/:currentProperty/:id"
        component={FlowInsureExtrasMobile}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/second-risk-address-mobile/:currentProperty/:id"
        component={FlowSecondRiskAddressMobile}
      />
      {/* Car */}

      {/* Health */}
    </Switch>
  );
};

export default MobileRouter;
