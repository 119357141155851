import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ActionButton,
  TextInput,
  Label,
  ToggleInput,
  DateInputV4 as DateInputComponent,
} from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useForm from '../hooks/useForm';
import { isFuture, differenceInYears, parseISO, format } from 'date-fns';
import * as Yup from 'yup';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { construction_year, string } from '../constants/validationSchemas';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeConstruction = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);

  function handleRenovationYearValidation5Years() {
    const { renovation_year } = this.parent;
    return differenceInYears(new Date(), parseISO(renovation_year)) <= 5;
  }

  function handleRenovationYearValidationFuture() {
    const { renovation_year } = this.parent;
    return !isFuture(parseISO(renovation_year));
  }

  const validationSchema = Yup.object().shape({
    construction_year: construction_year,
    hasRenovated: Yup.boolean(),
    renovation_year: Yup.mixed().when('hasRenovated', {
      is: true,
      then: string.required
        .test(
          'valid-renovation_year-5years',
          i18n.t('The renovation date can not be more than 5 years ago'),
          handleRenovationYearValidation5Years
        )
        .test(
          'valid-renovation_year-future',
          i18n.t('The renovation date can not be in the future'),
          handleRenovationYearValidationFuture
        ),
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Ok! In <span>what year</span> was the building constructed?'
      ),
      slug: 'construction_year',
    };
    addQuestion(question);

    loadDefaultValues();
  }, []);

  useEffect(() => {
    if (!values?.hasRenovated) {
      handleChange({ name: 'renovation_year', value: undefined });
    }
  }, [values?.hasRenovated]);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      construction_year: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'construction_year',
      ]),
      renovation_year: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'renovation_year',
      ]),
      hasRenovated: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'hasRenovated',
      ]),
      DoesYourPropertyHaveAntisesmicSurvey: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'DoesYourPropertyHaveAntisesmicSurvey',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.construction_year != null) {
      handleChange({
        name: 'construction_year',
        value: defaultValues.construction_year,
      });
    }
    if (defaultValues.renovation_year != null) {
      handleChange({
        name: 'renovation_year',
        value: defaultValues.renovation_year,
      });
    }
    if (defaultValues.hasRenovated != null) {
      handleChange({
        name: 'hasRenovated',
        value: defaultValues.hasRenovated,
      });
    }
    if (defaultValues.DoesYourPropertyHaveAntisesmicSurvey != null) {
      handleChange({
        name: 'DoesYourPropertyHaveAntisesmicSurvey',
        value: defaultValues.DoesYourPropertyHaveAntisesmicSurvey ? 1 : 0,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = () => {
    setLoading(true);

    if (
      !risk_addresses[currentProperty].parcel.main_building
        .construction_year === values.construction_year ||
      risk_addresses[currentProperty].parcel.main_building.construction_year ==
        null
    ) {
      patchStorage({
        payload: {
          construction_year: Number(values.construction_year),
          hasRenovated: values.hasRenovated,
          DoesYourPropertyHaveAntisesmicSurvey:
            values.construction_year <= 1985
              ? values?.DoesYourPropertyHaveAntisesmicSurvey
                ? 1
                : 0
              : 1,
          renovation_year: values?.hasRenovated
            ? values.renovation_year
              ? values.renovation_year
              : null
            : null,
        },
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
    }
    if (values?.hasRenovated) {
      const answer = {
        message: i18n.t(
          `The building was constructed <span>in ${
            values?.construction_year
          }</span> and renovated <span>in ${format(
            parseISO(values?.renovation_year, 'ddMM/yyyy'),
            'dd/MM/yyyy'
          )}</span>`
        ),
        slug: 'construction_year',
      };
      addAnswer(answer);
    } else {
      const answer = {
        message: i18n.t(
          `The building was constructed <span>in ${Number(
            values?.construction_year
          )}</span>`
        ),
        slug: 'construction_year',
      };
      addAnswer(answer);
    }

    renderNextRoute(1, { property: currentProperty });

    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <StyledTextInput
              name="construction_year"
              type="number"
              min="0"
              error={errors.construction_year}
              onChange={(val) => handleChange(val)}
              value={defaultValues.construction_year}
            >
              <p className="label">{i18n.t('Construction year')} *</p>
            </StyledTextInput>
          </div>
          <div>
            {parseInt(values?.construction_year) <= 1985 && (
              <>
                <Label>
                  {i18n.t('Does your property have antiseismic survey?')}
                </Label>
                <ToggleInput
                  checked={values?.DoesYourPropertyHaveAntisesmicSurvey}
                  falseLabel={i18n.t('No')}
                  trueLabel={i18n.t('Yes')}
                  onChange={() =>
                    handleChange({
                      name: 'DoesYourPropertyHaveAntisesmicSurvey',
                      value: !values?.DoesYourPropertyHaveAntisesmicSurvey,
                    })
                  }
                />
              </>
            )}
          </div>
          <div>
            <Label>
              {i18n.t('Did you renovate your building in the last 5 years?')}
            </Label>
            <ToggleInput
              checked={values?.hasRenovated}
              falseLabel={i18n.t('No')}
              trueLabel={i18n.t('Yes')}
              onChange={() =>
                handleChange({
                  name: 'hasRenovated',
                  value: !values.hasRenovated,
                })
              }
            />
            {values?.hasRenovated && (
              <DateInputComponent
                name="renovation_year"
                type="number"
                min="0"
                iso
                error={errors.renovation_year}
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.renovation_year}
              >
                <p className="label">{i18n.t('Date of last renovation')} *</p>
              </DateInputComponent>
            )}
          </div>
          <ButtonContainer>
            <Required>* {i18n.t('Required fields')}</Required>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Submit')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;

  p.label {
    color: #8990a3;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: hidden;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

export default FlowHomeConstruction;
