import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { func, array, string } from 'prop-types';

const RiskObjectExtra = ({ value, onChange, selected }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    onChange({ name: value, value: isSelected });
  }, [isSelected]);

  function handleChange() {
    setIsSelected(!isSelected);
  }

  useEffect(() => {
    if (selected?.includes(value)) {
      setIsSelected(true);
    }
  }, [selected]);

  return (
    <TypeLabel checked={isSelected} onClick={() => handleChange()}>
      {value}
    </TypeLabel>
  );
};

const TypeLabel = styled.div`
  background: ${({ checked }) => (checked ? '#224074' : '#f0f1f3')};
  border-radius: 50px;
  color: ${({ checked }) => (checked ? '#f0f1f3' : '#8990a3')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
`;

RiskObjectExtra.propTypes = {
  onChange: func,
  selected: array,
  value: string,
};

export default RiskObjectExtra;
