import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeBuildingInfoExtra = () => {
  const { id, affinity, insuranceType } = useParams();
  const [loading, setLoading] = useState(false);
  const [, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [rangeInput, setRangeInput] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const question = {
      message: i18n.t(
        'What’s the <span>maximum of consecutive days</span> the building can be left unattended?'
      ),
      slug: 'building_information_extra',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const handleForm = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const loadDefaultValues = async () => {
    let defaultValuesTemp;
    defaultValuesTemp = {
      max_consecutive_days_unattended: getNestedObject(data, [
        'risk_addresses',
        [risk_addresses.length - 1],
        'parcel',
        'main_building',
        'max_consecutive_days_unattended',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
    if (defaultValuesTemp?.max_consecutive_days_unattended != null) {
      setRangeInput(defaultValuesTemp?.max_consecutive_days_unattended);
    }
  };

  const submitHandler = () => {
    setLoading(true);
    if (
      !risk_addresses[risk_addresses.length - 1].parcel.main_building
        .max_consecutive_days_unattended === rangeInput ||
      risk_addresses[risk_addresses.length - 1].parcel.main_building
        .max_consecutive_days_unattended == null
    ) {
      patchStorage({
        payload: { max_consecutive_days_unattended: rangeInput },
        path: [
          'risk_addresses',
          [risk_addresses.length - 1],
          'parcel',
          'main_building',
        ],
        id,
        affinity,
        insuranceType,
      });
    }

    const answer = {
      message: i18n.t(`Maximum <span>${rangeInput} days</span> a year`),
      slug: 'building_information_extra',
    };
    addAnswer(answer);
    renderNextRoute();
    setLoading(false);
  };

  function handleRangeInput(e) {
    setRangeInput(e.target.value);
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <TitleForm>
              {i18n.t(
                'What’s the maximum of consecutive days the building can be left unattended?'
              )}
            </TitleForm>
            <div>
              <RangeContainer>
                <p className="min">{i18n.t('1 day')}</p>
                <p className="max">{i18n.t('365 days')}</p>
              </RangeContainer>
              <MaxConsecutiveDays>
                <input
                  type="range"
                  min="0"
                  max="365"
                  value={rangeInput}
                  onChange={(e) => handleRangeInput(e)}
                  step="1"
                />
                <p>
                  <span>{rangeInput}</span> {i18n.t('days')}
                </p>
              </MaxConsecutiveDays>
            </div>
          </div>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeBuildingInfoExtra;
