import React from 'react';
import styled from 'styled-components';
import { string, number, bool, node, object } from 'prop-types';
import CircleSpinner from './CircleSpinner';

const Button = ({
  level,
  fullwidth,
  icon,
  className,
  padding,
  size,
  color,
  sizeUnit,
  children,
  long,
  ...otherProps
}) => (
  <StyledButton
    level={level}
    fullwidth={fullwidth}
    padding={padding}
    className={className}
    long={long}
    disabled={true}
    {...otherProps}
  >
    <Text>
      {icon ? <Image src={icon} /> : null}
      {children}
    </Text>
    <StyledCircleSpinner size={size} color={color} sizeUnit={sizeUnit} />
  </StyledButton>
);

const Image = styled.img`
  margin-right: 1rem;
  height: 2rem;
`;

const Text = styled.p`
  display: inline-block;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font};
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  right: 1rem;
`;

const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.font};
  font-size: 1.6rem;
  border-radius: 0.5rem;
  position: relative;
  min-width: 14rem;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
  height: 4rem;
  transition: 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.brand.lighter};

  color: white;

  border: 0;

  padding: ${({ padding }) => padding || '1rem 0.4rem'};

  :active {
    transform: scale(0.95);
  }

  :focus {
    outline: none;
  }
`;

Button.defaultProps = {
  className: '',
  size: 20,
  color: '#fff',
  sizeUnit: 'px',
  text: '',
  long: false,
  level: 'primary',
  fullwidth: false,
  icon: null,
  otherProps: {},
};

Button.propTypes = {
  className: string,
  size: number,
  color: string,
  sizeUnit: string,
  text: string,
  long: bool,
  children: node,
  /** The color theme */
  level: string,
  /** If true button width is 100% */
  fullwidth: bool,
  /** Triggered when button is clicked */
  padding: string,
  /** Adds icon to button */
  icon: node,
  /** Adds extra props to the element */
  otherProps: object,
};

export default Button;
