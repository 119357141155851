import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import ExtraInsuranceObject from '../components/ExtraInsuranceObject';
import { ActionButton, SearchSelectInput } from 'wg-fe-ui';
import _ from 'lodash';

const FlowInsureExtrasMobile = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [chosenExtras, setChosenExtras] = useState([]);
  const [dogPresent, setDogPresent] = useState(false);
  const [catPresent, setCatPresent] = useState(false);
  const [chosenDogBreed, setChosenDogBreed] = useState();
  const [chosenCatBreed, setChosenCatBreed] = useState();

  const insuranceExtras = [
    // { value: 'Digital devices' },
    { value: 'Bicycle' },
    // { value: 'Electric Scooter' },
    // { value: 'Drone' },
    // { value: 'Art or other valuables' },
    { value: 'Pets' },
    { value: 'Jewelry' },
    // { value: 'Garden' },
  ];

  const cat_breeds = [
    { value: '83d5afdf-7ba0-4f20-af89-23cd9d477daa', label: 'ABYSSINIAN' },
    { value: '26104382-e46b-4b61-8b2e-998800566e68', label: 'ALBINO SIAMESE' },
    {
      value: '88fb101e-ec9b-48b1-9a2c-d24f6bc08ceb',
      label: 'AMERICAN BOBTAIL',
    },
    { value: '79be69e8-474e-42a7-aa0e-f264a022f39f', label: 'AMERICAN CURL' },
    {
      value: '2b5a575e-f744-4660-b2aa-a7400ef45d22',
      label: 'AMERICAN LONGHAIR',
    },
    {
      value: '7034ed09-5834-484c-b5a3-64104558cbcd',
      label: 'AMERICAN SHORTHAIR',
    },
    {
      value: 'e2422db6-4b80-47fd-8cb0-eca013eb4dce',
      label: 'AMERICAN WIREHAIR',
    },
    { value: '1f085495-30dd-45a0-9091-f80203999d5b', label: 'ANGORA' },
    {
      value: '13fbf173-88fe-4a13-b3f6-752070c3c70a',
      label: 'ASIAN SELF BREED',
    },
    { value: '74cd7144-adf3-4ffa-9679-a7fb4e86d760', label: 'ASIAN TIC TABBY' },
    { value: '4bde77cd-0c53-47aa-8c15-f825b2116637', label: 'AUSTRALIAN MIST' },
    { value: 'cd3ae311-4502-4cf9-b93e-91edb2d64018', label: 'BALINESE' },
    { value: '29ce44b6-6dbe-4dec-90a8-fc9fda422707', label: 'BENGAL' },
    { value: 'd2c50e5f-8f89-463a-b440-d54632689dbd', label: 'BI-COLOUR' },
    { value: 'ac8a9f71-c330-4a74-a861-3697856dd3c3', label: 'BIRMAN' },
    { value: '4a793264-bdb8-4d07-be3c-a65c5636470d', label: 'BLACK RUSSIAN' },
    { value: 'aa67cd77-5861-40f4-95c9-b0e8323efbd4', label: 'BLUE BURMESE' },
    {
      value: '3723ac5b-bf89-49d2-b525-0881602da28b',
      label: 'BLUE MACKEREL TABBY',
    },
    {
      value: '6eb93fbd-409b-4107-bd45-a4a7da6c9717',
      label: 'BLUE POINT SIAMESE',
    },
    { value: 'd19bdff2-b6b0-48ab-906b-66b175dc9ac4', label: 'BOMBAY' },
    {
      value: 'bb26bded-66ec-4587-9d88-8f4207b7843e',
      label: 'BRAZILIAN SHORTHAIR',
    },
    { value: '82ad15b6-4d3e-4049-a3f6-4f576db64fe0', label: 'BRITISH BLUE' },
    { value: '53deff60-28a3-4154-af58-20dca64218e0', label: 'BRITISH CREAM' },
    { value: '15ebcc2e-8a3c-4220-8db8-0dbffb5ea0dc', label: 'BRITISH LILAC' },
    {
      value: 'f95ca607-6030-4fb1-a64f-ab24be967e61',
      label: 'BRITISH LONG HAIR',
    },
    { value: 'ef6a1c9d-9cf8-41c7-b829-1e8f9cb40bd9', label: 'BRITISH RED' },
    {
      value: 'e1cbfd1a-3c18-41e0-a301-998e094efd1f',
      label: 'BRITISH SHORT HAIR',
    },
    { value: '0998249c-cc45-491f-8415-01499271cc3f', label: 'BROWN BURMESE' },
    { value: '8fa5547f-37fa-46ce-9f1e-0a0f3f648af0', label: 'BROWN TABBY' },
    { value: '719d72b9-b983-4ef1-a9a0-73b80670bd3f', label: 'BURMESE' },
    { value: '464155a7-34a4-4baa-a924-0744dd5c33b0', label: 'BURMESE CROSS' },
    { value: '7e2799dc-a13f-408e-8085-ee46bf048b89', label: 'BURMILLA' },
    {
      value: 'a5035fd9-ac39-4675-96c3-24f7e383b1fa',
      label: 'CANADIAN HAIRLESS (SPHYNX)',
    },
    { value: '737d16fa-00ff-4291-a4d8-f4a39244c43c', label: 'CEYLON' },
    { value: 'a7a22e47-ecfe-4569-b76d-1e892b2928ab', label: 'CHARTREUX' },
    { value: 'b5ff70fc-8961-4c91-9bfa-ea7934b4dcac', label: 'CHINCHILLA' },
    {
      value: '8b86223d-267a-4aa4-88bc-9d05f7156121',
      label: 'CHINCHILLA PERSIAN',
    },
    {
      value: '239a64e9-4f10-4881-b1ec-2a6f76e64bc7',
      label: 'CHOCOLATE BURMESE',
    },
    {
      value: '203e797e-7d52-44fd-aaa1-324c8ddcf593',
      label: 'CHOCOLATE POINT SIAMESE',
    },
    { value: '95418c5a-c5ec-47bc-9684-e1256c8ecc89', label: 'COLOURPOINT' },
    { value: '31e3551d-959f-4f75-ba8e-8575d18a4f19', label: 'CORNISH REX' },
    {
      value: '73d7ac6b-90ac-405a-8fda-be4edab51534',
      label: 'CREAM POINT SIAMESE',
    },
    {
      value: 'c6d45bbe-fb7e-409b-ac83-46b2b1ae332f',
      label: 'CREAM SHADED CAMEO',
    },
    { value: '9cb3860d-72aa-48e8-8003-9aeec3f61033', label: 'CROSSBREED' },
    { value: 'd34c6eb4-1792-47b7-bff9-679deed43979', label: 'DEVON REX' },
    {
      value: '49bed152-199b-4df1-89cf-225a5df3dfff',
      label: 'DOMESTIC LONG HAIR',
    },
    {
      value: '1e34a979-bfa3-44d5-9924-3e0f8c1f85bc',
      label: 'DOMESTIC SHORT HAIR',
    },
    { value: 'd632e15c-484b-4f29-a0c6-25ee7f239b0d', label: 'EGYPTIAN MAU' },
    {
      value: '1e6ff03c-5655-4df4-a0fa-a389ca8463a2',
      label: 'EUROPEAN BURMESE',
    },
    {
      value: '2082877f-af62-4869-8c78-3cbedff4c4cd',
      label: 'EXOTIC SHORTHAIR',
    },
    {
      value: '499dc336-c74d-49a1-94df-1ed2ed5fda3f',
      label: 'FOREIGN LONG HAIR',
    },
    { value: '98fb9960-cfca-4932-a980-65bc39af0c1c', label: 'GERMAN REX' },
    { value: '7af567cd-453b-4ffb-b8f4-c5012e851c19', label: 'GINGER' },
    { value: 'ca56a915-5c10-4264-9a03-ee5d69a03a35', label: 'GOLDEN TABBY' },
    { value: '278f4fd6-ac09-4dff-b0db-e2bd6201e9f0', label: 'HAVANA' },
    { value: '3523e1bf-ccc2-4b3e-8627-8b944db0b0c0', label: 'HIMALAYA' },
    { value: '3ac54257-131c-48a0-a6cd-199050993721', label: 'HIMALAYANS' },
    {
      value: 'cc85c504-24f0-42de-908a-1a39423ebf11',
      label: 'JAPANESE BOBTAIL',
    },
    { value: 'eab1a7d8-6fb0-4868-83ce-5d0fea5a6931', label: 'JAVANESE' },
    { value: '8ed50ad0-3cb6-493a-bb86-3fe5028befd5', label: 'KASHMIR' },
    { value: '3a117bb5-a19a-46ec-8883-0008454492f5', label: 'KORAT' },
    { value: 'edac3034-e5bc-4851-90a1-e4ff5ccbc0a6', label: 'LAPERM' },
    {
      value: '973cd9e0-bcc5-4010-b520-4506c58fa07e',
      label: 'LILAC POINT SIAMESE',
    },
    {
      value: '4bddf181-c676-48c1-91d4-14d7acf5c514',
      label: 'LILAC TORTIE BURMESE',
    },
    { value: '02765fea-b6e5-4037-bb6f-04d16cd14a44', label: 'MAINE COON' },
    { value: '3b5d8bd5-8303-4b41-98c5-c57fc51d4e70', label: 'MANX' },
    { value: 'f6f3e0f8-17ce-449b-ad11-a1affe4ac9b0', label: 'MOGGY' },
    { value: '568bad16-cc05-4003-884e-22a02eeebceb', label: 'MUNCHKIN' },
    {
      value: 'fae49174-d745-4a24-adb0-04421cd695bb',
      label: 'NORWEGIAN FOREST CAT',
    },
    { value: '971ebf7e-c187-4767-a8f3-504a3f3f2f2c', label: 'OCICAT' },
    { value: '968356c9-d3f9-4610-8923-ea46df02ec71', label: 'ORIENTAL' },
    {
      value: 'b86c9611-de90-4a56-ae96-c846f899f63a',
      label: 'ORIENTAL CARAMEL TICKED TABBY',
    },
    {
      value: 'c5e54e40-6068-4e4f-97c3-ca86ddf48732',
      label: 'ORIENTAL CHOCOLATE TICK TABBY',
    },
    {
      value: '2b8a9a87-2cc8-4fd0-9a38-78f61a36dcaa',
      label: 'PEKE-FACED (PERSIAN)',
    },
    { value: '4d2a8701-8372-43dd-b886-41e4fccbcbc8', label: 'PERSIAN' },
    { value: '2d21231f-84dc-4386-aa9d-baaaed8b474b', label: 'PERSIAN CROSS' },
    { value: 'c108e2ae-1460-42b6-8034-9f97474a87b5', label: 'PIXIE-BOB' },
    { value: '7c14a686-2703-4c5f-80ae-ca280b09a50e', label: 'RAG DOLL' },
    { value: '036338ec-e1e5-4e9c-a163-74b9b2953f4b', label: 'RAGAMUFFING' },
    {
      value: '2270657c-7fba-4b51-af10-467898dcd3c9',
      label: 'RED POINT SIAMESE',
    },
    { value: '1cea43b1-1c89-4edc-bc78-3230360d2248', label: 'RUSSIAN BLUE' },
    { value: 'fa1ce08d-8647-41a0-a53c-f7646c9e47c3', label: 'RUSSIAN WHITE' },
    { value: '8edb8e43-f4b0-4896-a6dc-725e6ca9a775', label: 'SCOTTISH FOLD' },
    {
      value: 'e0d82ee2-274d-4f98-89b7-062807385fdf',
      label: 'SEAL POINT SIAMESE',
    },
    { value: 'a4bddbe4-fc7c-4bb9-8677-162d68442610', label: 'SELKIRK REX' },
    { value: 'cda9c386-fb62-440c-8a1e-870e436e5041', label: 'SERENGETI' },
    { value: '790788ed-e014-4739-af84-5f69a8801b34', label: 'SIAMESE' },
    { value: '9a422772-463c-4cd5-9092-00d1e9375830', label: 'SIAMESE CROSS' },
    {
      value: '5baf2c40-dc0f-425f-ae80-89514d8df190',
      label: 'SIBERIAN FOREST CAT',
    },
    {
      value: 'c36de0e4-b564-409e-a0d0-f05f6c7f9ff2',
      label: 'SILVER SPOTTED TABBY',
    },
    { value: '996fc2d5-70bc-45cb-a370-3c1f96528e56', label: 'SILVER TABBY' },
    { value: '7e59eacf-a6bd-4f58-9251-a8949d7b6269', label: 'SINGAPURA' },
    { value: '46562851-f03b-49aa-b3c1-28004b667c11', label: 'SNOWSHOE' },
    { value: '36dd0427-0f7e-4c1c-bfbb-8d5f521efea7', label: 'SOMALI' },
    { value: '4c335c73-d38a-4a4b-aaac-15aae79d10e9', label: 'SPHYNX' },
    { value: '2f582566-798c-4b13-9486-073f62aae40a', label: 'SPOTTED MIST' },
    { value: '7881c3fa-cd59-48a3-a329-f87a9d5f9f13', label: 'TABBY' },
    {
      value: '377429bb-ada2-481d-bf6c-678048fa51f0',
      label: 'TABBY POINT SIAMESE',
    },
    { value: '284f5965-a74e-4b0b-b87d-d96064eb6983', label: 'THE BRITISH TIP' },
    { value: '1ba8315f-db9d-43e5-a47f-8da18af91e12', label: 'TIFFANY' },
    { value: 'c7840715-6677-450f-ade6-b54d9062929b', label: 'TONKINESE' },
    {
      value: '4f8614aa-16a7-4fcd-9062-df8e1a041a45',
      label: 'TORTIE POINT SIAMESE',
    },
    { value: '73add109-bf8e-4409-804c-ac38392109d3', label: 'TORTOISESHELL' },
    {
      value: 'f355a15f-abfd-4e89-b6db-6ef67a214a2c',
      label: 'TORTOISESHELL WHITE',
    },
    { value: '839a00e4-1b0e-4e64-a6b4-7b8e682046e1', label: 'TOYGER' },
    { value: 'c57106a0-8987-4511-8b1d-731d81a07ed2', label: 'TURKISH VAN' },
  ];

  const dog_breeds = [
    {
      value: '99af8a91-5508-4a59-86ae-018747dd4b9f',
      label: 'ABERDEEN TERRIER',
    },
    { value: '0bddff84-feed-463f-8ac3-6cf79f910594', label: 'AFFENPINSCHER' },
    { value: '3782637c-ecd4-4a0d-850c-16daabfa7e52', label: 'AFGHAN HOUND' },
    { value: '94bca30f-2c67-4ab9-b3d6-2ff2a7b06a0a', label: 'AIREDALE' },
    { value: 'cf76d85c-ef4a-484f-b428-bd5316f5c86c', label: 'AKBASH' },
    { value: 'db4f0cff-4d90-4809-aa53-5ee68249c5fa', label: 'AKITA' },
    {
      value: '6708f3d8-4f79-4fea-8ad6-4b4d230607fd',
      label: 'ALAPAHA BLUE BLOOD BULLDOG',
    },
    { value: '5e762b16-874d-43df-8511-4c92bc994583', label: 'ALASKAN HUSKY' },
    {
      value: 'a5a063b7-fb6c-4ab6-a26b-db871d9ae960',
      label: 'ALASKAN KLEE KAI',
    },
    {
      value: 'ca1555a7-d6dc-49e6-b088-162a32200a52',
      label: 'ALASKAN MALAMUTE',
    },
    {
      value: 'd4561933-7a54-4c1e-b60a-a14fd720535b',
      label: 'ALPINE DACHSBRACKE',
    },
    { value: 'ce88fc5d-fb19-4628-96cb-177520c4cb4a', label: 'ALSATIAN' },
    { value: '903edcb0-2844-460b-98c9-ee4ba9653892', label: 'AMERICAN' },
    {
      value: 'b10fcb53-4c58-4024-b1da-d1de1263236d',
      label: 'AMERICAN BLACK AND TAN COONHOUND',
    },
    {
      value: '66358947-ff16-48a5-b285-c5ebbc975776',
      label: 'AMERICAN BULLDOG',
    },
    {
      value: 'a9cca9d8-077c-4bd3-8395-49616e3c35aa',
      label: 'AMERICAN COCKER SPANIEL',
    },
    {
      value: 'eb791e31-1be0-4ab0-8502-365588fdec6d',
      label: 'AMERICAN ESKIMO DOG',
    },
    {
      value: '43b1f246-9596-46d0-acd6-88afdf652a41',
      label: 'AMERICAN HAIRLESS TERRIER',
    },
    {
      value: '19be5356-a6a3-4ee0-96f8-2d48eb365b45',
      label: 'AMERICAN INDIAN DOG',
    },
    {
      value: 'cb10764b-f347-455b-a212-01497b1140f2',
      label: 'AMERICAN PIT BULL',
    },
    { value: 'ef1be546-9e87-4941-ac10-267e15361c24', label: 'AMERICAN SPITZ' },
    {
      value: 'f0d84771-20bd-4939-9d6c-b697be354ac8',
      label: 'AMERICAN WATER SPANIEL',
    },
    {
      value: 'a757a9bf-7d10-461e-8a2e-253b00abe0fc',
      label: 'ANATOLIAN KARABASH',
    },
    {
      value: 'ea0e593c-afa1-4afa-b27e-9cb03af946c8',
      label: 'ANATOLIAN SHEPHERD DOG',
    },
    {
      value: '84109be0-d754-4a9d-8a16-709bb5748031',
      label: 'ANGLO-FRANCAIS DE MOYENNE VENERIE',
    },
    {
      value: 'fb344b85-6edb-41f3-8df3-20ce60a41e00',
      label: 'ANGLO-FRANCAIS DE PETITE VENERIE',
    },
    {
      value: '7c619f20-d2c8-4944-a0a8-86cf93ce0a99',
      label: 'APPENZELLER SENNENHUND',
    },
    {
      value: '392662f8-48f9-4c22-be96-9e91a32c9e75',
      label: 'ARGENTINEAN MASTIFF',
    },
    {
      value: '874068ea-19fd-41cd-9b7f-d6ce7469e3f1',
      label: 'AUSTRALIAN CATTLEDOG',
    },
    {
      value: 'd00f7022-077e-40e2-9ab8-aaaed22f164d',
      label: 'AUSTRALIAN HEELER',
    },
    {
      value: '331d62c0-1e0d-4b27-9c29-79f528113f06',
      label: 'AUSTRALIAN KELPIE SHEEPDOG',
    },
    {
      value: 'f6af7363-f086-40ea-93b9-445e086eec2c',
      label: 'AUSTRALIAN SHEPHERD',
    },
    {
      value: 'b1c0ad43-a5ac-4dc7-beed-16e9d4579755',
      label: 'AUSTRALIAN SILKY TERRIER',
    },
    {
      value: '449962c7-3c22-492b-922d-a514ec6fea14',
      label: 'AUSTRALIAN TERRIER',
    },
    { value: '43fc07c4-1168-4ca1-b405-c2c6257f2423', label: 'AZAWAKH' },
    { value: 'bfa73d0c-cf55-45cd-bf67-cbdc2d7ca2bc', label: 'BARBET' },
    { value: '95ddc002-cb55-4c3a-9c9e-96c23bf11f6e', label: 'BASENJI' },
    { value: '6d102ee2-2c78-44c9-b371-baa0d2b658a9', label: 'BASSET FAUVE DE' },
    { value: '9689ea49-6dfc-4ec7-82e8-8dbc43744e96', label: 'BASSET HOUND' },
    {
      value: 'd3279c21-7900-4c22-84b0-3423ac52433b',
      label: 'BASSETT ARTESIEN NORMAND',
    },
    {
      value: '8997d89e-74e2-4fbe-b840-8ece0b721a88',
      label: 'BASSETT BLEU DE GASCOGNE',
    },
    {
      value: 'd62c767a-b245-4365-a512-b7f000deb86b',
      label: 'BAVARIAN MOUNTAIN DOG',
    },
    { value: '9d67b7fd-482b-4022-bf8b-b1f84dec7158', label: 'BEAGLE' },
    { value: '7219eb57-77a7-430c-bf49-ad075469843a', label: 'BEAGLE HARRIER' },
    { value: '3317ccaa-a249-42e1-92c9-08eeb1faf220', label: 'BEARDED COLLIE' },
    { value: 'd963ffd4-8cd0-4b54-acd2-c87013dee12f', label: 'BEAUCERON' },
    {
      value: '6d563f1b-7cd1-4c30-bd4a-ff5c5885579f',
      label: 'BEDLINGTON TERRIER',
    },
    { value: '000e88a3-ec1b-47b5-80c1-7b4b4167c197', label: 'BELGIAN GRIFFON' },
    {
      value: 'd540574c-824d-46e1-a8ac-2525a816774c',
      label: 'BELGIAN SHEPHERD DOG',
    },
    {
      value: 'e38eb284-82f2-4b2c-aac0-df9fc49fc910',
      label: 'BERGAMESE SHEPHERD DOG',
    },
    {
      value: '0dc95f40-e512-45d9-9d37-66569e8a55ea',
      label: 'BERGER DES PICARD',
    },
    {
      value: '8f282270-4cf8-413d-abff-e5f01f553369',
      label: 'BERGER DES PYRENEES',
    },
    {
      value: '0f3d8ca5-e2f1-4ce4-839b-440b4896c0e4',
      label: 'BERNESE MOUNTAIN DOG',
    },
    {
      value: '18e73a27-3397-4a6f-bcfa-1ef860db4814',
      label: 'BICHON FRISE BLACK + TAN COONHOUND',
    },
    {
      value: 'c45c4100-8be5-4fc2-93ec-422f375c6510',
      label: 'BLACK FOREST HOUND',
    },
    { value: 'bd08c37d-221e-47cc-a85a-1a14014e2397', label: 'BLACK LABRADOR' },
    { value: '6e80d60e-eab5-4065-bfb9-f11023b2b22b', label: 'BLACK MOUTH CUR' },
    {
      value: '70fe4ba8-29fa-470b-bd23-d6411ea7fd5a',
      label: 'BLACK RUSSIAN TERRIER',
    },
    { value: '3e2ce28b-d199-4153-9279-39cf1775a721', label: 'BLOODHOUND' },
    {
      value: 'f0864e04-f6d8-40cc-9f2d-8a6b1a52473f',
      label: 'BLUE MERLE WELSH COLLIE',
    },
    {
      value: '5e1dfccc-2074-4b63-b4d9-96cc97611478',
      label: 'BLUES DE GASCOGNE HOUND',
    },
    {
      value: 'a593baf5-2964-4e66-9f73-3bea2cc54c72',
      label: 'BLUETICK COONHOUND',
    },
    { value: 'badbc6e0-2f5e-4cdd-9b72-efc049d21f98', label: 'BOERBEOL' },
    { value: '62135e4c-92e9-4feb-9d8f-154945191fc4', label: 'BOLOGNESE' },
    {
      value: 'b04b59c0-47c3-4cf9-89c0-2baad5625ab5',
      label: 'BORDEAUX MASTIFF',
    },
    { value: '4f1ff701-41a5-4b1e-b087-a5985df87200', label: 'BORDER COLLIE' },
    { value: '15667128-8a36-4c72-bafa-bae427bd08fc', label: 'BORDER TERRIER' },
    { value: 'b6c74a0f-2a58-4f1c-bc87-2b62351798e0', label: 'BORZOI' },
    { value: '0f092475-5b6d-4fe4-897f-cb02a3c46465', label: 'BOSTON TERRIER' },
    {
      value: '809f0253-f31b-47c8-9730-0797077fd4b8',
      label: 'BOUVIER DES FLANDRES',
    },
    { value: '0abc0ac3-289d-4cde-a99f-edf685d01398', label: 'BOXER' },
    { value: 'a1b75df1-1285-4bbb-af0e-a475050016d6', label: 'BOXER CROSS' },
    { value: 'f94e02d9-702c-495b-8dc3-b33d3a0ddae5', label: 'BOYKIN SPANIEL' },
    { value: '0f17297a-1cb7-4ef0-aedc-fb4df884eac5', label: 'BRACCO' },
    {
      value: '183145ca-780c-4512-a497-722ba5c9073c',
      label: 'BRAQUE DE AUVERGNE',
    },
    {
      value: '22a7490b-5478-44b6-9f90-43245eac2b34',
      label: 'BRAQUE DE BOURBANNAIS',
    },
    {
      value: 'faab574f-4e26-4c02-bee6-638d30e48eaa',
      label: 'BRAQUE FRANCAIS DE GRAND TAILLE',
    },
    {
      value: '20d478d4-21cc-41b7-a4d4-0dd19c9c49ed',
      label: 'BRAQUE FRANCAIS DE PETITE TAILLE',
    },
    {
      value: 'a2eb8b7b-897e-4729-a0c2-145085ca964e',
      label: 'BRAQUE SAINT-GERMAINE',
    },
    {
      value: 'b8b5ea9d-2006-4fa5-bf34-5d2ccd0a6d19',
      label: 'BRAZILIAN MATIFF',
    },
    { value: 'b26d9aee-d796-4e4d-882f-db77999e5ccc', label: 'BRETAGNE' },
    { value: 'a563fab9-1fc3-4976-badc-9485c2d1b666', label: 'BRIARD' },
    {
      value: 'a1028634-aaaf-4418-bcaa-d968697d6434',
      label: 'BRIQUET GRIFFON VENDEEN',
    },
    {
      value: '7b94cfdd-bc82-4434-a5f1-a32b8bf199ad',
      label: 'BRITTANNY SPANIEL',
    },
    {
      value: 'cdc83697-1056-4d83-abc7-50daf3af0b0c',
      label: 'BRUSSELS GRIFFON',
    },
    { value: 'ffe82192-1582-4862-8dcb-1a8ab08369b9', label: 'BULL TERRIER' },
    { value: '1956907d-6a80-42c8-b280-f930d8fd933d', label: 'BULLDOG' },
    { value: '296ceb9e-4c9f-46dc-b951-0c7e1c0cbec4', label: 'BULLMASTIFF' },
    { value: '80a60ff8-0807-4f17-baad-7506834affdd', label: 'CAIRN TERRIER' },
    { value: 'b036e7ae-37b9-495f-a6c2-a062e721f98f', label: 'CANAAN' },
    { value: 'bd7ca0e8-3601-4dfe-8926-b42bf5653f0e', label: 'CANE CORSO' },
    {
      value: '3a29be0e-8340-42b7-b053-f5993d6cba76',
      label: 'CAO DE CASTRO LABOREIRO',
    },
    {
      value: 'e7ec534a-d41a-49e3-abe9-05b922fd8147',
      label: 'CAO DE FILA DE SAO MIGUEL',
    },
    {
      value: 'ea330eca-5a74-4525-8094-e6b066c66fce',
      label: 'CAO DE SERRA DE AIRES',
    },
    { value: '60bc0383-a7c8-42c4-a305-72b2a4245ae1', label: 'CARDIGAN' },
    { value: '5f7383f9-44fc-4d3b-8f3e-a0bc6881cdd0', label: 'CAROLINA DOG' },
    {
      value: '049181b8-bcc2-4897-b836-27b3bd7180ef',
      label: 'CATALAN SHEEPDOG',
    },
    {
      value: 'c4602018-7501-40ba-8be9-dd2a842c8d4b',
      label: 'CAUCASIAN OVTCHARKA',
    },
    { value: '4a03c870-cd86-4643-b07a-a5b04f9f8146', label: 'CAVALIER KING' },
    {
      value: '1fe2ce27-bb6c-428c-91ba-054e2c1e125b',
      label: 'CENTRAL ASIAN OVCHARKA',
    },
    { value: '50c5081b-dff1-44b4-a8ba-b6b98e9e857c', label: 'CESKY FOUSEK' },
    { value: '607b10bb-e946-4cee-8778-da43ca9dbcfa', label: 'CESKY TERRIER' },
    { value: '659de311-66e1-4813-9c52-becd1a93a95b', label: 'CHARLES SPANIEL' },
    {
      value: 'baeffe12-cfab-4538-9c35-345a298d426d',
      label: 'CHESAPEAKE BAY RETRIEVER',
    },
    {
      value: '51ba45e1-85f3-49f8-b1ab-88d4417a55ee',
      label: 'CHIEN FRANCAIS BLANC ET NOIR',
    },
    {
      value: '93f60ebb-f5d1-4353-ae5b-cb6b39eefc04',
      label: 'CHIEN FRANCAIS BLANC ET ORANGE',
    },
    { value: '5fd83c26-f326-47a3-8868-a24c7fbe8b54', label: 'CHIHUAHUA' },
    { value: 'fb7c8325-210f-48c9-8853-2f3d6ece9623', label: 'CHINESE' },
    { value: '6300dede-5ec0-4d42-bb04-4d5ac2f90496', label: 'CHINOOK' },
    {
      value: '8907b9fe-6143-466a-bb8c-32ff970a23c0',
      label: 'CHOCOLATE LABRADOR',
    },
    { value: '6d1f29b6-9773-41bd-90bd-ff131705f1a6', label: 'CHOW' },
    {
      value: 'c7356aef-6dc8-4714-959f-f35816e40588',
      label: 'CHOW CIRNECO DELL-ETNA',
    },
    { value: '64e5c892-9110-46c1-b0e5-96dabf3fb5b6', label: 'CLUMBER SPANIEL' },
    {
      value: 'f1f7b9f3-4180-4527-8e0a-1633a2952f39',
      label: 'COATED RETRIEVER',
    },
    { value: '463de09d-118a-4f8d-858d-8e380c5f4751', label: 'COCKER SPANIEL' },
    { value: '91482b51-1cdb-40e8-8637-49135078290a', label: 'COLLIE' },
    { value: 'a5dd9e04-a9e8-4812-b320-00e5a716a805', label: 'COLLIE CROSS' },
    { value: 'd40b3419-c3c7-4ba5-bfb6-4b6eb7d49543', label: 'CORGI' },
    { value: 'afd707f7-90b9-462c-80bb-34555daf1d85', label: 'COTON DE TULEAR' },
    { value: '0e3b0963-b988-49c9-951b-21c209268f19', label: 'CRESTED' },
    {
      value: 'd377d19a-d54e-48eb-8f03-a67edc613600',
      label: 'CROSSBREED - LARGE',
    },
    {
      value: 'f524a137-6fec-4154-a8e5-27b5783f6902',
      label: 'CROSSBREED - MEDIUM',
    },
    {
      value: '7b6867c9-35d1-4e27-9b93-47e7d65b456c',
      label: 'CROSSBREED - SMALL',
    },
    { value: 'a8f42226-9e34-4bcc-ac2e-00db11cd59c7', label: 'CURLY' },
    { value: 'e1b79340-be0f-430a-bfa5-4dbd6f944acb', label: 'DACHSHUND' },
    { value: 'd66405d7-b31f-4b8c-83c4-67be34ec426d', label: 'DALMATIAN' },
    { value: '71099a52-c16d-4670-9078-065eddbbd30d', label: 'DANDIE' },
    { value: 'eb4e4df7-4cf6-49cb-aa54-8f93b5a6a705', label: 'DEERHOUND' },
    { value: '77a66a1e-9d28-4351-8623-b903ba660670', label: 'DINMONT TERRIER' },
    {
      value: '7e081be4-de41-482b-afc5-b73a3b6e2c8c',
      label: 'DOBERMAN PINSCHER',
    },
    { value: '8e863258-5754-4289-8d07-4e497719f0e8', label: 'DOBERMANN' },
    { value: '8676916c-f3d2-4885-8ba4-5954a11a04a2', label: 'DOGO ARGENTINO' },
    {
      value: '0b4b5dba-88e4-4931-b600-cd0d7cf5f393',
      label: 'DOGUE DE BORDEAUX',
    },
    { value: '86059a65-73fa-4567-8d31-f4a737f70d32', label: 'DORGIE' },
    { value: '0ed81707-1788-4e1a-b6fd-0de93967fad3', label: 'DREVER' },
    { value: 'f3eaba7c-58da-43c4-989d-554ca97f8339', label: 'DUTCH SHEEP DOG' },
    {
      value: '9c8a6adb-6ebb-4b1c-980a-45559e80ded7',
      label: 'EAST EUROPEAN SHEPHERD',
    },
    {
      value: '89028911-fe94-4765-a790-eb89b644ecb0',
      label: 'EAST SIBERIAN LAIKA',
    },
    { value: '396252a8-29cb-49af-9a1d-aca1dc5a249a', label: 'ELKHOUND' },
    {
      value: 'b62fd1bb-0a9f-4eb2-8565-c6ffc3b43479',
      label: 'ENGLISH COONHOUND',
    },
    { value: '05183fe2-56de-40ea-82e8-bc6d0acbbe78', label: 'ENGLISH POINTER' },
    { value: '925e0a63-7d3d-4813-8a95-f18579d86d40', label: 'ENGLISH SETTER' },
    {
      value: '97830313-e1f6-47ed-84b9-644cf228b8f1',
      label: 'ENGLISH SHEPHERD',
    },
    {
      value: '75798d84-913c-4451-bbdc-ea5955612d98',
      label: 'ENGLISH SPRINGER SPANIEL',
    },
    {
      value: 'a166c133-3571-4389-b765-abded0bc40e5',
      label: 'ENGLISH TOY TERRIER',
    },
    { value: '1a888580-0c9b-4d49-b3e4-2c3c18fa6954', label: 'ENTLEBUCHER' },
    { value: '72ab2abe-55a5-4493-bee4-93699ff6a0ee', label: 'ESKIMO DOG' },
    { value: '9a2f8f06-03ac-479f-9ae2-db885b70cc85', label: 'ESTONIAN HOUND' },
    { value: 'bff71bba-cb7d-4a77-beb6-3cf7d81c2dd9', label: 'ESTRELA' },
    {
      value: '8977ba86-d0df-460e-a6b6-00078b360847',
      label: 'EURASIAN FELL TERRIER',
    },
    { value: 'abb76569-406e-4364-bc0c-0a8ba0652acc', label: 'FIELD SPANIEL' },
    { value: 'ac414f27-c139-4494-b06f-53a062908f49', label: 'FILA BRASILEIRO' },
    { value: '9e000317-e67f-4bcd-a7a3-0e6af1dbbb6e', label: 'FINNISH HOUND' },
    { value: '049f829e-044f-4a41-934e-04bdda53c1c1', label: 'FINNISH LAPHUND' },
    { value: '682aadbd-bc92-4794-b6db-fc72c6c80720', label: 'FINNISH SPITZ' },
    {
      value: 'fd4e342b-7e65-43c7-90d5-a405755f9795',
      label: 'FLAT COATED RETRIEVER',
    },
    { value: '2e27325f-ae01-4452-8f85-fe9661e3f201', label: 'FOX TERRIER' },
    { value: 'a508960c-dcff-4289-8db1-2df000d1a630', label: 'FOXHOUND' },
    { value: '8bd4e734-7d1c-4df6-8c9b-01406e4b7451', label: 'FRENCH BULLDOG' },
    { value: 'b21dbf81-d258-406b-b939-264ed27d13f3', label: 'FRENCH SPANIEL' },
    { value: '8febbc9c-115d-4e69-add4-48793fb91ae7', label: 'GAZELLE HOUND' },
    {
      value: 'da18553f-987e-4530-a3ea-669b3f265697',
      label: 'GERMAN HUNTING TERRIER',
    },
    { value: '899a0c1d-bfb7-41fd-9826-712f767e6bcf', label: 'GERMAN PINSHER' },
    { value: 'c71709bb-5063-47cd-a929-7d95a67e1455', label: 'GERMAN POINTER' },
    {
      value: '0df2f5fa-9b1a-4b49-a0df-95a279322c12',
      label: 'GERMAN SHEPHERD CROSS',
    },
    {
      value: '1c2d214a-2fd2-45b4-95a7-f4d08b8cbdb8',
      label: 'GERMAN SHEPHERD DOG',
    },
    { value: '91973958-0669-47a3-b2ed-f10195b903ce', label: 'GERMAN SPANIEL' },
    { value: '4fc074b0-0349-4664-aa42-135271eda334', label: 'GERMAN SPITZ' },
    {
      value: '0bc7bb80-6582-4778-b2a0-c11fa2ae4059',
      label: 'GERMAN WIREHAIRED POINTER',
    },
    { value: 'd833a43d-f8a6-4f47-8b93-956400db1d38', label: 'GIANT SCHNAUZER' },
    {
      value: 'c33d8770-29be-475c-8257-e39ac1b19257',
      label: 'GLEN OF IMAAL TERRIER',
    },
    {
      value: '72e4f88a-0cbf-44f3-b745-a5c26c186d86',
      label: 'GOLDEN RETRIEVER',
    },
    { value: '64bda933-3427-462d-8c92-506fe6c355b1', label: 'GORDON SETTER' },
    {
      value: 'db2cf7d3-9c87-47ab-bf84-3fa1037835fa',
      label: 'GRAND ANGLO FRANCAIS',
    },
    { value: '9e2d1a96-ca7b-4c00-8187-fc4d7f23c4ee', label: 'GRAND BASSET' },
    {
      value: 'b0426fb4-b62b-4f23-8368-db8414da790e',
      label: 'GRAND BLEU DE GASCOIGNE',
    },
    {
      value: '07105d7a-fd6f-4e66-bf5f-8bc968a50a2d',
      label: 'GRAND GASCON SAINTONGEOIS',
    },
    {
      value: '66d5d011-fe98-413f-a3f8-a5cc6ddc6f91',
      label: 'GRAND GRIFFON VENDEEN',
    },
    {
      value: 'c34926ac-c9f5-4812-8cdd-46764ac53636',
      label: 'GREAT DANE GREATER SWISS MOUNTAIN DOG',
    },
    { value: '7d13892b-f43a-42ea-bcf2-899fc939ca85', label: 'GREEK SHEEPDOG' },
    { value: 'e471cc74-f6c1-4357-ad22-b1349a99250c', label: 'GREENLAND DOG' },
    { value: '93f7230d-f48e-426c-b354-533c705f8b9b', label: 'GREYHOUND' },
    {
      value: '0b20779b-2b7d-4b0c-8838-189ae1aca818',
      label: 'GRIFFON BRABANCON',
    },
    {
      value: 'aba4f39e-c406-44ac-a459-9f3685d46c20',
      label: 'GRIFFON BRUXELLOIS',
    },
    {
      value: 'a139aafc-53ab-4c7b-bc40-9a836276df6f',
      label: 'GRIFFON FAUVE DE BRETAGNE',
    },
    {
      value: '4d1df90d-3c16-4adc-a637-eeac97f0430d',
      label: 'GRIFFON NIVERNAIS',
    },
    { value: '555addb2-8f1e-4135-967f-9b2be626247c', label: 'GRIFFON VENDEEN' },
    { value: 'cc9bd0c1-1ae0-4661-b5df-496146bb5aba', label: 'GROENENDAEL' },
    { value: 'd3d4b319-a5f9-41c5-b12e-5f555295ab3a', label: 'HAMILTONSTOVARE' },
    {
      value: 'df5ee9a1-f66e-42d4-8e50-5a40c11287fe',
      label: 'HANOVARIAN HOUND',
    },
    { value: 'cbd58458-71ab-436f-b4b4-cbd7dd4aaeb0', label: 'HARRIER' },
    { value: '88e9fd4a-668c-4107-a247-8fe0668eb1ff', label: 'HAVANESE' },
    {
      value: '67e08a63-6818-4e25-87a5-b4a562233660',
      label: 'HAWAIIAN POI DOG',
    },
    { value: '7b40324c-2f6b-455a-b411-2c2cab09ed3a', label: 'HEELER' },
    {
      value: '81b490d4-e49f-4d70-84d3-4b151be52ab2',
      label: 'HIMALAYAN SHEEPDOG',
    },
    {
      value: '4c40df99-43f7-4b63-a3ac-5210a21fe484',
      label: 'HOUND ICELANDIC SHEEPDOG',
    },
    { value: '4d020389-44f5-4d27-ae53-ec6795604633', label: 'HOVAWART' },
    {
      value: '5368ee4d-1c14-4f09-801f-cd4f809ccac2',
      label: 'HUNGARIAN KUVASZ',
    },
    { value: '62456c8f-1f22-4034-bb94-e3e1b6746412', label: 'HUNGARIAN PULI' },
    { value: '107f51ca-34d0-4362-9165-d01fce2f247b', label: 'HUNGARIAN PUMI' },
    {
      value: '2bc59d9a-7afb-403a-b1ea-66d41cf90399',
      label: 'HUNGARIAN VIZSLA IBIZAN',
    },
    { value: 'c4653bbc-c56e-47a3-b740-b670e474023f', label: 'HUSKIES ' },
    {
      value: '68036fd5-6951-4bf6-ad1b-031428764c99',
      label: 'INCA HAIRLESS DOG',
    },
    {
      value: 'a1b64603-7d87-435b-9a02-20bf258c6194',
      label: 'IRISH RED AND WHITE SETTER',
    },
    { value: 'f795a4f6-201c-47ae-9d36-b02971e7753e', label: 'IRISH SETTER' },
    { value: '7f8fb365-0247-4733-83cf-20d4392c7915', label: 'IRISH TERRIER' },
    {
      value: '8d543967-8dc0-4f3b-a923-726920a50bea',
      label: 'IRISH WATER SPANIEL',
    },
    {
      value: 'd66182eb-8db7-46c1-872a-2602c8591bcc',
      label: 'IRISH WHEATEN TERRIER',
    },
    { value: '5f95bad3-5624-43f0-bc01-0d4fa3b16707', label: 'IRISH WOLFHOUND' },
    {
      value: 'db842c88-82e2-4857-a397-126da2daec96',
      label: 'ITALIAN GREYHOUND',
    },
    {
      value: '41391696-8f62-459e-90c2-73d13ea26b23',
      label: 'JACK RUSSELL TERRIER',
    },
    { value: 'de9634a0-d7c4-4eea-81dd-0e467e4ea997', label: 'JAPANESE AKITA' },
    { value: '55936f3f-8d0f-40ff-8236-12c6f92abd3c', label: 'JAPANESE CHIN' },
    {
      value: '9f981ad5-4f70-4051-b4b4-cd92fb2052e6',
      label: 'JAPANESE SPITZ JINDO',
    },
    { value: '8bea4fa9-c7f7-4464-870f-b17290d27bcb', label: 'JAPANESE TOSA ' },
    { value: '01ac1b77-27c0-4722-9987-ea92983697d8', label: 'KAI DOG' },
    { value: 'f624ae46-3873-40df-8eb5-dd66c21d4b3c', label: 'KANGAL DOG' },
    {
      value: 'a4bd26bc-f918-441e-abf4-17b6e422de32',
      label: 'KARELIAN BEAR DOG',
    },
    { value: '601ed5e3-b6ec-4c6b-85b0-5d0fd96135c7', label: 'KEESHOND' },
    {
      value: 'f1613d7b-829b-4ccf-b9b8-7f7fa3f6d4b1',
      label: 'KERRY BLUE TERRIER',
    },
    {
      value: '36f7c51b-fe4e-4e55-9f73-fd90aadbfd8b',
      label: 'KING CHARLES SPANIEL',
    },
    { value: 'adf8e759-e802-4649-a650-e8a5032f8839', label: 'KOMONDOR' },
    { value: '129803eb-b0eb-45d6-874d-effecdce9008', label: 'KOOIKERHONDJE' },
    {
      value: 'eff09d2c-6d83-4d82-9a90-5437ada0c20e',
      label: 'KORTHALL GRIFFON',
    },
    { value: 'bf054404-d6a1-4027-859a-edbb26bcd880', label: 'KRASKY OVCAR' },
    { value: 'e8f52cfe-dc42-4a46-b7ac-c159078df845', label: 'KROMFOHRLANDER' },
    { value: '19b86ef7-dce8-4eb1-b474-5ea17da4f29d', label: 'KUVASZ' },
    { value: '0762aa0d-859e-484f-9e54-2e7b523c1788', label: 'LABRADOR CROSS' },
    {
      value: '26f78899-d483-4267-ac43-038fe67fad3f',
      label: 'LABRADOR RETRIEVER',
    },
    { value: '06d9104a-b606-4c3e-8b07-0ec7cceb4656', label: 'LABRODOODLE' },
    { value: '39bac844-7fbe-4e71-815c-cd57bc9843d6', label: 'LAEKENOIS' },
    {
      value: 'e6666c04-25e0-498b-b178-f133f0cb4bad',
      label: 'LAGOTTO ROMAGNOLO',
    },
    {
      value: '19e9a8cf-6d66-4ae1-9e7e-c215c3801dc4',
      label: 'LAKELAND TERRIER',
    },
    {
      value: '90382d77-82e6-4b88-b1f0-bd1b82932d67',
      label: 'LANCASHIRE HEELER',
    },
    {
      value: '0fd0f4f2-2cff-4806-a666-7a475d661a59',
      label: 'LARGE MUNSTERLANDER',
    },
    { value: '19653ea6-c431-4e6a-97fb-843d2875e5aa', label: 'LEONBURGER' },
    { value: 'c8e5cd48-c975-44d3-b144-be5ac903e105', label: 'LEOPARD CUR' },
    { value: '7332d840-687a-4743-ab90-244f65d4b446', label: 'LEVESQUE' },
    { value: '6e942ebc-3e07-471d-9385-1d9589da2e1c', label: 'LHASA APSO' },
    {
      value: 'faeddf7c-2bcb-45df-9cbe-2492d72d3324',
      label: 'LIBYAN DESERT DOG',
    },
    {
      value: 'fbe36d16-1924-4763-9c70-c5cd8c297402',
      label: 'LOWCHEN (LITTLE LION DOG)',
    },
    { value: '2c57d7d1-5724-4dcc-bbf4-98a08bf9cd0d', label: 'LUCAS TERRIER' },
    { value: '3b2e09d1-8ef5-4fdf-a001-a517476782d2', label: 'LUNDEHUND' },
    { value: '8c19fffc-6f0e-456f-b9a2-c47c3a3fa680', label: 'LURCHER' },
    { value: 'afae1a28-2590-4ad7-adf4-c72bce6346b0', label: 'LURCHER CROSS' },
    {
      value: '8c1140b0-33b4-4e08-b259-5f376e37d2f2',
      label: 'MAJESTIC TREE HOUND',
    },
    { value: '0cae9391-2a6f-4fdd-8fbf-b05530edf9a4', label: 'MALINOIS' },
    { value: '0bd3fcfe-d64c-401a-82d2-459d5be43fe0', label: 'MALTESE' },
    {
      value: 'bbb141ad-fdac-4327-aba0-9de3c817b1b7',
      label: 'MANCHESTER TERRIER',
    },
    { value: 'b3b95cb8-092b-48c2-b2ad-1572b2f7a2a8', label: 'MAREMMA' },
    { value: '30f44bba-85b1-4384-b6d8-7d317e2d5006', label: 'MASTIFF' },
    { value: '104d9dd4-fa8f-4445-a2cf-8b7ab01a2da4', label: 'MASTIFF' },
    {
      value: '4f0f4f34-b7c8-412c-9b1e-cda49e3f66e1',
      label: 'MEXICAN HAIRLESS',
    },
    {
      value: '100868b6-40d1-4110-9c15-f63f70c7f96e',
      label: 'MINIATURE BULL TERRIER',
    },
    {
      value: 'e5c98e35-2707-4ad3-a08c-ff4123aeb141',
      label: 'MINIATURE DACHSHUND',
    },
    {
      value: '7ed9bbe4-cf69-4578-a3ad-9fa2d90eb5bd',
      label: 'MINIATURE PINSCHER',
    },
    {
      value: 'ba1af494-da45-4911-8648-a13bc79b1570',
      label: 'MINIATURE POODLE',
    },
    {
      value: '4a377610-2fe4-4aaa-ba51-0de614114695',
      label: 'MINIATURE SCHNAUZER',
    },
    {
      value: '597f3600-5be9-4345-b5a7-29d27bdfa355',
      label: 'MINIATURE YORKSHIRE TERRIER',
    },
    { value: '2bfbd00d-9730-418e-a762-2ec2f425ed2c', label: 'MONGREL - LARGE' },
    {
      value: '27a9c2bd-2ba6-4550-95c9-25b6c890b77a',
      label: 'MONGREL - MEDIUM',
    },
    { value: 'b6f94f39-2677-4663-bc26-f260ec1cb301', label: 'MONGREL - SMALL' },
    {
      value: 'f54cb850-05d7-4ba7-8e14-36789fda6175',
      label: 'MOSCOW WATCHDOG NEOPOLITAN',
    },
    {
      value: 'ce10efa4-73c5-488d-99fd-31e86b7ca84d',
      label: 'NEW ZEALAND HUNTAWAY',
    },
    { value: '9745c1b5-2947-4ebd-9ead-97eb479a2e71', label: 'NEWFOUNDLAND' },
    {
      value: '09c1d733-0757-4e0e-9487-4428ac2524b7',
      label: 'NORFOLK LABRADOR',
    },
    { value: '4f0e47ed-0f14-487a-8055-2168dd746783', label: 'NORFOLK TERRIER' },
    { value: 'a7a7245d-5d3b-4f34-8d71-9cf78f8abf7a', label: 'NORSK LUNDEHUND' },
    { value: '7dfcf8d5-b0a4-4dc3-8a54-84697200cce9', label: 'NORTHERN INUIT' },
    {
      value: '00cfbfba-057f-452a-8edd-4c035533af3e',
      label: 'NORWEGIAN BUHUND',
    },
    { value: '28ca803d-a381-4945-84f4-cecba1f50260', label: 'NORWICH TERRIER' },
    {
      value: '7a49f2e2-d7fe-40fa-97dc-3b5382485e23',
      label: 'NOVASCOTIA DUCK TOLLING RETRIEVER',
    },
    {
      value: 'd5409f04-d7b8-4589-8cef-f532ca999bd2',
      label: 'OLD ENGLISH SHEEP DOG',
    },
    { value: '724cff54-ae23-48b3-80e9-b275901f3798', label: 'OTTERHOUND' },
    { value: '79d7b997-76bf-4b5b-bde4-7dbd8ad66d69', label: 'OWCZAREK' },
    { value: 'fa52733c-84e9-4a74-a57d-4d49256bcfcb', label: 'PACHON NAVARRO' },
    { value: 'a91499a8-c20e-47e2-8d98-94191f716a63', label: 'PAPILLON' },
    {
      value: 'ada36f04-5e63-4159-9639-6d53719d7511',
      label: 'PARSON JACK RUSSELL TERRIER',
    },
    {
      value: '8a174ea9-24a7-4f50-8fa7-bef8ebb63a05',
      label: 'PATTERDALE TERRIER',
    },
    { value: '9f698a68-bfaa-4211-ac83-491a668f870f', label: 'PEKINGESE' },
    { value: '90ec57b4-9d9c-4f7d-91b6-df932a122de2', label: 'PEMBROKE CORGI' },
    {
      value: '6e37ec4e-3572-4287-9da9-888d06102329',
      label: 'PERDIGUERO DE BURGOS',
    },
    {
      value: 'a70be7cb-63d1-421c-9f20-59203ceff8a9',
      label: 'PERDIGUERO NAVARRO',
    },
    {
      value: '8077ca1f-37ae-44e5-919d-8dbd7d9d0c4e',
      label: 'PERUVIAN INCA ORCHID',
    },
    {
      value: 'b3237320-c0bb-4dcb-a99f-aceac88d0bf4',
      label: 'PETIT BASSET GRIFFON VENDEEN',
    },
    {
      value: '23176481-5daf-4be4-855e-b94e591913e1',
      label: 'PETIT BLEU DE GASCOGNE',
    },
    { value: 'b8b25162-6d0b-4a62-ad2d-4a9ef13a32a4', label: 'PETIT BRABANCON' },
    { value: '1be3038f-c62c-4a74-80a4-e8b0c4bf8cb4', label: 'PHARAOH HOUND' },
    {
      value: '8a332569-d69f-4d5b-819b-cb8eab010442',
      label: 'PICARDY SHEEPDOG',
    },
    { value: 'c5ffd63c-9b9c-4030-9af3-49d01d2cd276', label: 'PINSCHER' },
    { value: '8c90c68d-a14a-41ed-8034-648f5b776f4b', label: 'PLOTT HOUND' },
    { value: '6a75a698-3813-4e5c-ad34-f9631b4ddd29', label: 'PLUMMER TERRIER' },
    { value: 'bacc7dc6-4acf-4f2d-80fe-aa2f9382a0b3', label: 'PODENGO' },
    { value: '2c52d085-83cb-4a86-bbbf-41d0d2202439', label: 'PODHALANSKI' },
    { value: '74f56f8e-656a-4061-aebc-c31608e1c0e8', label: 'POITEVIN' },
    { value: '98a108f6-a713-4f32-b014-82c3495cff2d', label: 'POLISH HOUND' },
    {
      value: 'a7f82298-1763-4a16-b679-5d1dd4d5ce5b',
      label: 'POLISH LOWLAND SHEEPDOG',
    },
    {
      value: '0e05f5bf-d971-41c0-89c2-9396d45af69c',
      label: 'POLISH OWCZAREK NIZINNY',
    },
    {
      value: '61e7cedf-9b03-426f-ab82-f0510d4c347b',
      label: 'POMERANIAN POODLE',
    },
    {
      value: 'b869b01e-4675-415d-9035-4f2b421c8c55',
      label: 'POODLE - MINIATURE',
    },
    {
      value: 'd995a2b5-bc90-4ac4-8d0f-b222205a42ca',
      label: 'POODLE - STANDARD',
    },
    {
      value: '339c5c55-95a9-4d31-a255-1887eb57ae2d',
      label: 'POODLE - TOY POODLE CROSS',
    },
    { value: '85e7ea90-ef14-4148-a313-66b76a54b0a0', label: 'PORCELAINE' },
    {
      value: 'a2bf865e-5549-4a7a-8d90-65589b65dcab',
      label: 'PORTUGESE SHEEPDOG',
    },
    {
      value: '795edda8-a1f2-4eee-a2a6-0387d9886190',
      label: 'PORTUGUESE POINTER',
    },
    {
      value: 'f7af70df-0fa7-4b0b-8bda-27da6eef2b4a',
      label: 'PORTUGUESE WATER DOG',
    },
    { value: 'e5a575e7-d211-42d7-832a-bebd37397849', label: 'PRESA CANARIOS' },
    { value: '0c55502b-daf0-4791-a5f6-9feb05104259', label: 'PUDELPOINTER' },
    { value: '6e65b471-4ca7-459c-ae73-28b107058dda', label: 'PUG' },
    { value: 'fa3839c5-e3d4-4242-a6fc-d2a821345457', label: 'PUGGLE' },
    {
      value: '767e2c80-09e5-499d-8227-28e7d821e5f3',
      label: 'PYRENEAN MOUNTAIN DOG',
    },
    {
      value: 'c801acb3-eb4d-44e3-a418-dc3a8c268eb3',
      label: 'PYRENEAN SHEEPDOG RAFEIRO DO ALENTEJO',
    },
    { value: '863672b1-7f11-4f2e-afc6-708da8324955', label: 'RED SETTER' },
    {
      value: 'ad9ea501-cf1c-497a-a723-8c6a0a072dd5',
      label: 'REDBONE COONHOUND',
    },
    { value: '2524265d-6739-4b6e-b063-3ccf66d0fcd4', label: 'RETREIVER CROSS' },
    { value: '23d9d9f3-c914-4c75-8d78-6241a0628520', label: 'RHODESIAN' },
    { value: '30387012-1870-4a9a-a6a1-93af01496238', label: 'RIDGEBACK' },
    { value: '953cc7d0-075b-474c-ab9d-c473cf72b67b', label: 'ROTTWEILER' },
    { value: '8d3dcd91-eddc-4dd1-9dcd-9ba2950b806d', label: 'ROUGH COLLIE' },
    {
      value: '595557f7-d029-4456-afb4-4081621eabe4',
      label: 'RUSSO-EUROPEAN LAIKA',
    },
    {
      value: '50b60fb5-a2c8-4367-8015-64c65e9a8e0d',
      label: 'SABUESOS ESPANOLES',
    },
    { value: '9f2f85d4-b69a-4a11-9324-dc5104ddcf28', label: 'SALUKI' },
    { value: '2ac80c26-b5b7-4f8f-bf3b-5c2ef4ab5198', label: 'SAMOYED' },
    { value: 'c177ea53-86cd-4fcb-bffa-84f0a6588c1a', label: 'SARPLANINAC' },
    { value: '71320315-469c-46f2-a00d-5e5b06a52416', label: 'SCHIPPERKE' },
    { value: '9e162412-5b51-4fc5-9bbb-4abbf56ac598', label: 'SCHNAUZER' },
    {
      value: 'f2b92fb4-8cd5-4297-8197-c1e11a1546b1',
      label: 'SCOTTISH TERRIER',
    },
    {
      value: '4e81ddc7-85ea-49d7-893c-e7722c8d4f6c',
      label: 'SEALYHAM TERRIER',
    },
    {
      value: 'b8fadc0c-08ac-4589-a1cc-57d2e6870357',
      label: 'SEGUGIO ITALIANO',
    },
    { value: 'addf9d2e-508a-4e7a-a5e2-d9beb965008f', label: 'SHAR PEI' },
    { value: '7bd5edc2-2ed2-4274-ae71-21e4effcbac2', label: 'SHAR PEI CROSS' },
    { value: '92b51c1f-c115-444c-ad8d-ae2b26df403d', label: 'SHEEPDOG' },
    { value: '349d1af9-e3e1-463f-a226-784e132b5b2b', label: 'SHELTIE' },
    { value: '167f62dd-4dd4-4bab-bfd3-4bf2939bef3c', label: 'SHETLAND' },
    { value: '39079eb9-e46a-49eb-8f2e-285f6fd9c92f', label: 'SHIBA INU' },
    { value: 'ea04ffbc-41b8-49bc-9d2e-cda33a9b657c', label: 'SHIH TZU' },
    { value: 'ae77eea5-c9c6-4e77-824a-9a45930d4287', label: 'SIBERIAN HUSKIE' },
    { value: 'c5a13fc1-5ec5-433a-bfec-6498985bd02f', label: 'SKYE TERRIER' },
    { value: 'c40da2d2-6d15-40e5-afb4-75a112c31b17', label: 'SLOUGHIS' },
    {
      value: '352b103c-a5d8-460e-a424-cce307bc0577',
      label: 'SLOVAKIAN ROUGH HAIRED POINTER',
    },
    { value: '6e7c4d99-9a16-48b7-8967-0fd127989d23', label: 'SLOVENSKY CUVAC' },
    {
      value: '260a4720-d640-4e4b-a141-548348486e04',
      label: 'SMALL MUNSTERLANDER',
    },
    { value: 'dfbdfe94-f2f1-4943-9968-45a0da472645', label: 'SPANIEL' },
    { value: 'ca790ebe-20b3-4d7a-9564-38fa528aa7a4', label: 'SPANIEL CROSS' },
    {
      value: '903e4952-c3aa-415e-bf85-7414e710bc44',
      label: 'SPANIEL ST BERNARD',
    },
    {
      value: '2c18c928-814f-46c6-b855-5b16ad3bba4b',
      label: 'SPANISH HUNTING DOG',
    },
    {
      value: '0ae153d4-369b-4f7d-82f3-45bc24291589',
      label: 'SPANISH WATER DOG',
    },
    { value: '29e07af8-da6e-48d5-9997-0e2f5c04b4ea', label: 'SPINONE' },
    { value: 'aac3f565-8f5e-41f1-82fe-1045108963d1', label: 'SPITZ MITTEL' },
    { value: '88c9458a-42e9-4519-a4b5-f0bd69b2cc40', label: 'SPRINGER' },
    {
      value: '031b1e64-d7a7-497d-aaf0-354ab8249052',
      label: 'ST JOHNS WATERDOG',
    },
    { value: '60845a12-5acc-469e-899e-ddb93c105d12', label: 'STABYHOUN' },
    {
      value: '27e09884-aaa5-4162-9307-be07cc3ff99e',
      label: 'STAFFORDSHIRE BULL TERRIER',
    },
    { value: 'c6cbe1d5-fecb-4faa-ad15-48a0be89f351', label: 'STANDARD' },
    { value: '58005d88-d782-4aaf-a635-9d4efc8998c7', label: 'STANDARD POODLE' },
    {
      value: 'e293c536-445a-43ff-9144-7d2a6ed1ff9c',
      label: 'STUMPY TAIL CATTLE DOG',
    },
    { value: 'f245836d-623b-40f9-af5c-9f9976505fcb', label: 'SUSSEX' },
    {
      value: 'c0e5a385-f560-40d9-800d-2f5bd0913596',
      label: 'SWEDISH LAPPHUND',
    },
    {
      value: '53bf9198-d538-4feb-b758-f31d8ab2b5b6',
      label: 'SWEDISH VALLHUND',
    },
    {
      value: '0662ddfc-6918-40f0-b463-f4d0d00b1ee3',
      label: 'SWISS APPENZELOIS',
    },
    { value: '9dc79db9-9107-4215-b8cb-dcf91e91ecaa', label: 'TAMASKAN' },
    {
      value: '230c9539-3788-4d2b-9311-f54649a199e4',
      label: 'TENNESSEE BRINDLE',
    },
    { value: '8afb1eb3-fde7-4c14-965e-ab46b0979b79', label: 'TERRIER CROSS' },
    { value: '1601073f-412c-4250-bf30-a62a08a64a19', label: 'TERRIER TOY FOX' },
    {
      value: '80b05e49-bae3-4b5a-b530-b30e38616b1b',
      label: 'TERRIER TOY POODLE',
    },
    { value: '94fa6704-be48-4d57-84c1-b8a539b62633', label: 'TERVUEREN' },
    { value: '8fd6f7b2-393b-42db-b639-09ee9000b4a4', label: 'THAI RIDGEBACK' },
    { value: 'f8ae92ed-a129-4b8c-afb4-8dca60d2d9fa', label: 'TIBETAN' },
    { value: 'f8e86cd4-c438-4828-a9a1-06a9e465f266', label: 'TIBETAN MASTIFF' },
    { value: '7940d669-7cbd-419b-8c1a-f585fc8d3e2b', label: 'TIBETAN SPANIEL' },
    { value: '3732b583-1acd-4639-a85c-23e2a8414a7b', label: 'TOSA INU' },
    { value: 'e02de4e9-46fa-4fa0-a763-bde1ffb6e8be', label: 'TRAIL HOUND' },
    { value: '5d616aea-e0e7-48a8-82b8-994120a09dc9', label: 'TREEING' },
    { value: '38563afb-db64-49df-8e76-de3624f9f5e1', label: 'TREEING' },
    {
      value: '576c8983-ef8c-4642-99a1-8b1a1dfa8cc8',
      label: 'WALKER COONHOUND TURKISH KANGAL UTONAGON VICTORIAN BULLDOG',
    },
    { value: 'acf8db84-a5f5-40ec-bdd1-787368e9e7f6', label: 'WATER SPANIEL' },
    { value: '11641ec8-9100-417e-add0-b2f877e5c481', label: 'WEIMARANER' },
    { value: 'df39b506-3be1-4b80-a61d-f6c83464eaad', label: 'WELSH CORGI' },
    { value: '0c43f6bd-21d4-4571-8c14-a13bc6b6be02', label: 'WELSH SHEEPDOG' },
    {
      value: 'a95d3987-a660-4fbb-b833-81ad83816125',
      label: 'WELSH SPRINGER SPANIEL',
    },
    { value: '9636ad94-7796-43b5-935b-3e62b6e0a2ee', label: 'WELSH TERRIER' },
    { value: '7484f178-9ee6-4745-992c-4864fc17e64b', label: 'WEST HIGHLAND' },
    { value: '3ece24b7-c828-4027-8ed1-3c292dee96e9', label: 'WHEATEN TERRIER' },
    { value: '9285860c-bae9-4073-a0bd-a05a25d6b160', label: 'WHIPPET' },
    {
      value: '30df482c-23bb-45f2-9282-472e1afa91ce',
      label: 'WHITE WEST SIBERIAN LAIKA',
    },
    {
      value: 'f95e3d2e-a86d-420a-8f14-8fd14ef212fe',
      label: 'WIREHAIRED DACHSHUND',
    },
    {
      value: 'c828c45d-3dad-4c79-a58b-3591fd232807',
      label: 'WIREHAIRED POINTING GRIFFON',
    },
    {
      value: '676fbf91-0c4d-4094-9f40-7cd9378586c9',
      label: 'WOLF - HYBRIDS  ',
    },
    {
      value: 'c2f40034-3c26-4e7d-aaf9-55921cbf3e37',
      label: 'WORKING SHEEPDOG',
    },
    { value: '0d9e98f4-4ebf-4a1c-a009-3e288e886cdb', label: 'XOLOITZCUINTLI' },
    { value: 'a8a6ef6d-2df2-4646-aa83-f8ebdedd3f05', label: 'YELLOW LABRADOR' },
    { value: '755336a6-d54e-4773-8791-65c4cf27983b', label: 'YOODLE' },
    {
      value: 'cbd14a51-d3b9-4946-935b-07e6b38c8de4',
      label: 'YORKSHIRE TERRIER',
    },
    {
      value: '913ecd54-4922-4965-be0a-4741ba01633b',
      label: 'YUGOSLAVIAN HOUND',
    },
  ];

  useEffect(() => {
    const question = {
      message: i18n.t(`Would you like to insure anything else?`),
      slug: 'insure_extras',
    };
    addQuestion(question);
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  const submitHandler = () => {
    if (chosenDogBreed) {
      patchStorage({
        payload: {
          extra_items_to_insure: chosenExtras,
          dog_breed: chosenDogBreed,
        },
        id,
        affinity,
        insuranceType,
      });
    } else if (chosenCatBreed) {
      patchStorage({
        payload: {
          extra_items_to_insure: chosenExtras,
          cat_breed: chosenCatBreed,
        },
        id,
        affinity,
        insuranceType,
      });
    } else if (chosenCatBreed && chosenDogBreed) {
      patchStorage({
        payload: {
          extra_items_to_insure: chosenExtras,
          cat_breed: chosenCatBreed,
          dog_breed: chosenDogBreed,
        },
        id,
        affinity,
        insuranceType,
      });
    } else {
      patchStorage({
        payload: { extra_items_to_insure: chosenExtras },
        id,
        affinity,
        insuranceType,
      });
    }

    renderNextRoute(1, { property: currentProperty });
    let answer;
    if (chosenExtras?.length) {
      answer = {
        message: i18n.t(`Yes, please insure these extras`),
        slug: 'insure_extras_answer',
      };
    } else {
      answer = {
        message: i18n.t(`No`),
        slug: 'insure_extras_answer',
      };
    }
    addAnswer(answer);
  };

  function handleExtras({ value, name }) {
    let arr = [];
    if (value) {
      arr = [...chosenExtras, name];
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    arr = _.uniq(arr);
    setChosenExtras(arr);
  }

  return (
    <RightContainer>
      <Scroll>
        <TitleForm>
          {i18n.t('Would you like to insure anything else?')}
        </TitleForm>
        <HouseTypesContainer>
          {insuranceExtras?.map((type) => {
            return (
              <ExtraInsuranceObject
                selected={defaultValues?.extra_items_to_insure}
                value={type.value}
                onChange={(val) => handleExtras(val)}
              />
            );
          })}
        </HouseTypesContainer>
      </Scroll>
      {chosenExtras?.includes('Pets') && (
        <>
          <PetType>
            <Pet onClick={() => setDogPresent(!dogPresent)} active={dogPresent}>
              Dog
            </Pet>
            <Pet onClick={() => setCatPresent(!catPresent)} active={catPresent}>
              Cat
            </Pet>
          </PetType>
          {catPresent && (
            <SearchSelect
              hidden={!catPresent}
              options={cat_breeds}
              onSelected={(val) => setChosenCatBreed(val.value)}
            >
              Cat Breed
            </SearchSelect>
          )}
          {dogPresent && (
            <SearchSelect
              hidden={!dogPresent}
              options={dog_breeds}
              onSelected={(val) => setChosenDogBreed(val.value)}
            >
              Dog Breed
            </SearchSelect>
          )}
        </>
      )}
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Confirm"
          onClick={() => submitHandler()}
          data-test-id="risk_address_flow_submit"
        >
          {i18n.t('Submit')}
        </ActionButton>
      </ButtonContainer>
    </RightContainer>
  );
};

const PetType = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  width: 100%;
`;

const Pet = styled.div`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-size: 1.4rem;
  height: 4.5rem;
  justify-content: center;
  width: 48%;
  &:focus {
    outline: 0;
  }
`;

const SearchSelect = styled(SearchSelectInput)`
  display: ${({ hidden }) => (hidden ? 'none' : 'unset')};
  min-height: 8rem;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const HouseTypesContainer = styled.div`
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 3rem;
  > div {
    height: 9rem;
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
  justify-content: space-between;
`;

export default FlowInsureExtrasMobile;
