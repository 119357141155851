import React, { useEffect, useState } from 'react';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import styled from 'styled-components';
import i18n from '../i18n';
import { useParams } from 'react-router';
import AccidentInput from './AccidentInput';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { retrieveStorageById } from '../helpers/storeService';
import { useHistory } from 'react-router-dom';

const FlowDriversInformationExtraForm = ({
  sendAccidentArr,
  handleFormValues,
}) => {
  const { id, affinity, insuranceType } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [accidentArr, setAccidentArr] = useState([]);
  const history = useHistory();

  const SignupSchema = Yup.object().shape({});

  const { handleSubmit } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  useEffect(() => {
    if (defaultValues?.accidents != null) {
      setAccidentArr(defaultValues.accidents);
    }
  }, [defaultValues]);

  useEffect(() => {
    sendAccidentArr(accidentArr);
  }, [accidentArr]);

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Scroll>
        <TitleForm>{i18n.t('Primary driver information')}</TitleForm>
        <CatTitle>{i18n.t('Accidents in the last five years')}</CatTitle>
        <p>
          {i18n.t(
            `The primary driver had ${accidentArr?.length} claims in the last 5 years`
          )}
        </p>
        {accidentArr?.map((val) => {
          return (
            <AccidentInfo>
              <ClaimInfo>
                <p>{val.claim_date}</p>
                <span>&euro;{val.claim_amount}</span>
              </ClaimInfo>
              <ClaimName>Claim name</ClaimName>
            </AccidentInfo>
          );
        })}
        <AccidentInput
          accidents={defaultValues?.accidents}
          sendAccidentArr={(val) => setAccidentArr(val)}
        />
      </Scroll>
      <ButtonContainer insurance={insuranceType}>
        {insuranceType !== 'allMobile' && (
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
        )}
        <ActionButton
          type="submit"
          value="Login"
          data-test-id="social_login_submit"
        >
          {insuranceType === 'allMobile' ? i18n.t('Submit') : i18n.t('Next')}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Scroll = styled.div`
  margin-bottom: 1rem;
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const AccidentInfo = styled.div`
  background: #ffffff;
  border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  border-radius: 5px;
  box-shadow: 0px 0px 34.29px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const ClaimInfo = styled.div`
  display: flex;
  font-weight: 550;
  justify-content: space-between;
`;

const ClaimName = styled.div`
  color: ${({ theme }) => theme.brand.primary};
  margin-top: 1rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  > div > p {
    color: #8990a3;
    margin-bottom: 3rem;
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: ${({ insurance }) =>
      insurance === 'allMobile' ? '500' : '550'};
    height: ${({ insurance }) => (insurance === 'allMobile' ? '4rem' : '5rem')};
    min-width: 12rem;
    width: ${({ insurance }) => (insurance === 'allMobile' ? '100%' : '12rem')};
    &:focus {
      outline: 0;
    }
  }
`;

export default FlowDriversInformationExtraForm;
