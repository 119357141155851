export default [
  {
    title: 'verify-user',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'login',
      2: 'user-info',
    },
  },
  {
    title: 'login',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'user-address-mobile',
    },
  },
  {
    title: 'user-info',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'fill-user-info',
    },
  },
  {
    title: 'fill-user-info',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'user-address-mobile',
    },
  },
  {
    title: 'user-address-mobile',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'select-insurances-mobile',
    },
  },
  {
    title: 'select-insurances-mobile',
    step: 1,
    subStep: 2,
    nextRoutes: {
      2: 'car-already-registered-mobile',
    },
  },
  {
    title: 'car-already-registered-mobile',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'car-second-hand-mobile',
      2: 'license-plate-response-mobile',
    },
  },
  {
    title: 'car-second-hand-mobile',
    step: 3,
    subStep: 2,
    nextRoutes: {
      1: 'car-lookup-mobile',
      2: 'license-plate-lookup-mobile',
    },
  },
  {
    title: 'car-lookup-mobile',
    step: 3,
    subStep: 3,
    nextRoutes: {
      // 1: 'car-information-extra-mobile',
      1: 'replacement-vehicle-mobile',
    },
  },
  {
    title: 'license-plate-lookup-mobile',
    step: 3,
    subStep: 3,
    nextRoutes: {
      1: 'license-lookup-response-mobile',
    },
  },
  {
    title: 'license-lookup-response-mobile',
    step: 3,
    subStep: 3,
    nextRoutes: {
      // 1: 'car-information-extra-mobile',
      1: 'replacement-vehicle-mobile',
    },
  },
  // {
  //   title: 'car-information-extra-mobile',
  //   step: 3,
  //   subStep: 4,
  //   nextRoutes: {
  //     1: 'replacement-vehicle-mobile',
  //   },
  // },
  {
    title: 'replacement-vehicle-mobile',
    step: 3,
    subStep: 5,
    nextRoutes: {
      1: 'vehicle-purpose-mobile',
    },
  },
  {
    title: 'vehicle-purpose-mobile',
    step: 3,
    subStep: 6,
    nextRoutes: {
      1: 'average-km-travelled-mobile',
    },
  },
  {
    title: 'average-km-travelled-mobile',
    step: 3,
    subStep: 7,
    nextRoutes: {
      1: 'drivers-information-mobile',
    },
  },
  {
    title: 'drivers-information-mobile',
    step: 3,
    subStep: 8,
    nextRoutes: {
      1: 'insure-extras-mobile-car',
    },
  },
  {
    title: 'insure-extras-mobile-car',
    step: 3,
    subStep: 8,
    nextRoutes: {
      // 1: "drivers-information-extra-mobile"
      1: 'drivers-policy-info',
    },
  },
  {
    title: 'drivers-information-extra-mobile',
    step: 3,
    subStep: 9,
    nextRoutes: {
      1: 'drivers-policy-info',
    },
  },
  {
    title: 'drivers-policy-info',
    step: 3,
    subStep: 10,
    nextRoutes: {
      1: 'insurance-options-car-mobile',
    },
  },
  {
    title: 'insurance-options-car-mobile',
    step: 3,
    subStep: 9,
    nextRoutes: {
      1: 'car-premium-summary-mobile',
    },
  },
  {
    title: 'car-premium-summary-mobile',
    step: 3,
    subStep: 10,
    nextRoutes: {
      1: 'car-already-registered-mobile',
      2: 'overview',
    },
  },
  {
    title: 'overview',
    step: 3,
    subStep: 8,
    nextRoutes: {},
  },
  {
    title: 'thankyou',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];
