import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import HomeBasicGanIcon from '../assets/icons/HomeBasicGanIcon';
import HomeEcoGanIcon from '../assets/icons/HomeEcoGanIcon';
import HomePremGanIcon from '../assets/icons/HomePremGanIcon';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';

import {
  ActionButton,
  LoadingSpinner,
  IconActionChevronLeft,
  IconStatusCheck,
} from 'wg-fe-ui';
import { useParams, useHistory } from 'react-router-dom';
import { createPropertyPolicyGan } from '../helpers/apiRouterService';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useQuery } from 'react-query';

const getPropertyType = (type) => {
  switch (type) {
    case 'open':
      return 35;
    case 'halfopen':
      return 30;
    case 'Detached':
      return 29;
    case 'Appartment':
      return 25;
    default:
      return 30;
  }
};

const getConstructionMaterialString = (package_info) => {
  return `${package_info?.column_material
    ?.map((value) => `Columns / ${value?.value}`)
    .join(';')};${package_info?.floor
    ?.map((value) => `Floor / ${value?.value}`)
    .join(';')};${package_info?.roof
    ?.map((value) => `Roof / ${value?.value}`)
    .join(';')};${package_info?.walls
    ?.map((value) => `Walls / ${value?.value}`)
    .join(';')}`;
  // ${package_info?.pipes
  // ?.map((value) => `Water pipes / ${value?.value}`)
  // .join(';')}`;
};

const getPayload = (_data, selected, currentProperty) => {
  const isPremium = selected === 'Premium';
  const payload = {
    Customer: {
      Oid: _data?.ganCustomer?.Oid,
    },
    PropertyStatus: 1, // What do all the enums entail?
    AvailiableProductsForCustomer: _data?.risk_addresses[currentProperty]
      ?.is_landlord
      ? 'FTP_and_PL_and_EL'
      : 'HHC_and_EL',
    SelectedProduct:
      selected === 'Value'
        ? _data?.risk_addresses[currentProperty]?.is_landlord
          ? 1
          : 3
        : 0,
    DoYouWishToHaveCargoInsurance: 0,
    DoesYourBusinessEmployeeAnyAdditionalPeople: 0,
    DoPeopleOtherThanEmployeesEnterYourPremisses: 0,
    DoesYourBusinessProvideProfessionalAdviseOrConstultationToCustomers: 0,
    DoesYourBusinessSupplieProductsOrComponentsToCustomers: 0,
    CouldTheftOrDishonestyByEmployeesBeAProblem: 0,
    PremisesHasMachineryStockOrOtherContents: 0,
    WillYourBusinessBeAffectedByAnyInterruptionLikeFireFloodDeniedAccess: 0,
    BusinessIsInvolvedInTransportinGoodsOrProducts: 0,
    BusinessOperateMotorVechiclesOrUsesStaffOwnVechicles: 0,
    BusinessProvidesHealthBenefitsToStaff: 0,
    ExistingInsuranceCompany: {
      InsuranceCompany:
        _data?.risk_addresses[currentProperty]?.current_insurance_info
          ?.insurance_company_name || 'None',
    },
    ExpiringDateOfYourCurrentPolicy:
      _data?.risk_addresses[currentProperty]?.current_insurance_info
        ?.end_of_current_contract || null,
    ExistingInsuranceCompanyPrice:
      _data?.risk_addresses[currentProperty]?.current_insurance_info
        ?.insurance_price || null,
    BuildingAddress: _data?.risk_addresses[currentProperty]?.Oid,
    FinancialInstitution:
      _data.risk_addresses[currentProperty]?.FinancialInstitution || null,
    JointOwnership: 0,
    PropertyType: getPropertyType(
      _data.risk_addresses[currentProperty]?.parcel?.main_building?.house_type
    ), // What are the oids of these building types?
    ManufactureYear:
      _data?.risk_addresses[currentProperty]?.parcel?.main_building
        ?.construction_year,
    DateOfLastRenovation:
      _data?.risk_addresses[currentProperty]?.parcel?.main_building
        ?.renovation_year ||
      new Date(
        _data?.risk_addresses[
          currentProperty
        ]?.parcel?.main_building?.construction_year
      ).toISOString(),
    ConsecutiveDaysUnoccupiedFor:
      _data?.ConsecutiveDaysUnoccupiedFor?.value || 1,
    ConstructionMaterial: getConstructionMaterialString(
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.construction_details
    ),
    MethodsOfHeating:
      _data.risk_addresses[
        currentProperty
      ].parcel?.main_building?.construction_details?.heating_method
        ?.map((value) => value?.value)
        ?.join(';') || 'None',
    WaterPipes:
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.WaterPipes || 1,
    UseOfProperty: 1,
    DoesYourPropertyHaveAntisesmicSurvey:
      _data.risk_addresses[currentProperty]?.parcel?.main_building
        ?.DoesYourPropertyHaveAntisesmicSurvey, // What does this entail?
    TypeOfPremises: null, // What are the kids of PremisesType table?
    DoYouHaveAnyLargeQuantitiesOfWaterWithinPremises:
      'No Large Water Quantities', // What are the allowed enums WaterQuantitiesTypes table?
    SafetyFeatures: '',
    IsThePropertyNeighbouringToAnOpenAreaWithBushesOrShrubsOrTreesWithinThirtyMetersFromProperty: 0,
    IsThereAManMadeOrNaturalFirePortectionZone: 0,
    IsThePropertyAtARemoteDestination: 0,
    PolicyDurationMonths:
      _data.risk_addresses[currentProperty]?.policy_details?.insurance_duration,
    StartDate: new Date(
      _data.risk_addresses[currentProperty]?.policy_details?.starting_date
    ).toISOString(),
    EndDate: new Date(
      _data.risk_addresses[currentProperty]?.policy_details?.ending_date
    ).toISOString(),
    BuildingSumInsured:
      selected === 'Tenant'
        ? 0
        : _data?.BuildingSumInsured ||
          _data.risk_addresses[currentProperty]?.parcel?.main_building
            ?.surface * 1200,
    AccidentalDamageToBuilding: _data?.AccidentalDamageToBuilding || 0,
    ContentsSumInsured:
      _data.ContentsSumInsured ||
      _data.risk_addresses[currentProperty]?.parcel?.content_sum
        ?.insurance_price ||
      0,
    AccidentalDamageToContents: _data?.AccidentalDamageToContents || 0,
    TotalValueOfHighRiskItems: 0,

    SpecifiedItemsTotalValue: _data?.SpecifiedItemsTotalValue || 0,
    DomesticFreezeContents: _data?.DomesticFreezeContents || 0, // What does this entail?
    TracingTheLeakCoverAmount:
      _data?.TracingTheLeakCoverAmount?.value ||
      '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a',
    ValuableAndPersonalEffects: _data?.ValuableAndPersonalEffects || 0,
    PersonalMoneyAndCreditCards: _data?.PersonalMoneyAndCreditCards || 0,
    UsePedalCycles: !!_data?.UsePedalCycles ? 1 : 0,
    PetInsurance:
      _data.risk_addresses[currentProperty]?.extra_items_to_insure?.includes(
        'Pets'
      ) && isPremium
        ? 1
        : 0,
    PetsInProperty:
      _data.risk_addresses[currentProperty]?.PetsInProperty || null,
    PropertyInventory:
      _data.risk_addresses[currentProperty]?.PropertyInventory?.length > 0
        ? _data.risk_addresses[currentProperty]?.PropertyInventory
        : null,
    ClassicVehicleInsurance: 0,
    ClassicVehicleMarketValue: null,
    AccidentsToDomesticStaff: isPremium ? 1 : 0,
    VesselLiability: 0,
    SkiersLiability: 0,
    PassengerLiability: 0,
    HuntersLiability: 0,
    MedicalForDomesticStaff: 0,
    VesselInProperty: null,
    PedalCycles: _data.risk_addresses[currentProperty]?.Bikes
      ? _data.risk_addresses[currentProperty]?.Bikes
      : null,
    DomesticStaff: null,
    ContentsSumInsuredExcludingSAndC: null, // Some more explanation?
    TheftForContents: 0,
    StockSumInsured: null,
    TheftForStock: 0,
    OwnComputerEquipmentSumInsured: null,
    TotalElectronicEquipmentSumInsured: null,
    MoneyCoverSumInsured: null,
    BusinessInterruptionSumInsured: null,
    BusinessInterruptionForRegisteredCoreEmployeesSalaries: null,
    NetAnnualSalaryForAllCoreRegisteredEmployees: null,
    LimitOfIdemnityRequiredForAnyOneClaimSumInsured: null,
    NoOfAllEmployees: null,
    AnnualWagesAndSalariesForAllEmployees: null,
    SumInsuredByAnyOneConsignment: null,
    PublicLiability: 0,
    DoYouHaveAWrittenQualityProceduresManual: 0,
    ProductLiability: 0,
    EmployersLiablity: _data.EmployersLiablity || 0,
    EmployersSocialSecurityNo: null,
    EmployersLiabilityList: null,
    PersonalAccident: 0,
    NoOfPersonsForPersonalAccidentCover: null,
    NoOfUnitsForEachEmployee: null, // What do these units entail?
    PersonalAccidentPersons: null,
    GlassSumInsured: null,
    SignSumInsured: null,
    PanesOfGlassWhereLengthAndWidthExceed5mOrTotalAreaExceeds10cm: null, // TotalAreaExceeds10cm, shouldn’t this be 10m?
    HomeBusinessSecure: { Oid: isPremium ? 2 : 5 },
    TotalCoverAreaOfGlassInSquareCm: null,
    SpecifiedItemsSumInsured: null,
    UnspecifiedItemsSumInsured: null,
    ProfessionalIdemnity: 0, // What does this entail?
    NoOfEmployeesThatYouWishToInsureForProfessionalIdemity: null,
    CountryYourBusinessIsDomicited: null, // Where can I get _data from Countries  Table?
    TotalTurnoverOfTheLastYearIncludingFeeIncome: null,
    HaveYouBeenChargedWithACriminalOffence: 0,
    TotalResponse: _data?.TotalResponse?.value || 4, // What does this entail + where can I get data from PropertyTotalResponse Table
    EarthquakeExcess: 1148,
  };
  return payload;
};

const FlowHomeInsuranceOptions = () => {
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [error, setError] = useState();
  const history = useHistory();
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createPropertyPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentProperty)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };

  const { data, isLoading, failureCount, isFetching } = useQuery(
    ['propertyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  const tenantDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
  ];

  const ValueDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
  ];

  const PremiumDetails = [
    {
      value: 'Building cover',
    },
    { value: 'Contents cover' },
    { value: 'High risk items sum insured' },
    { value: 'Earthquake excess' },
    { value: 'Consecutive days unoccupied' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
    { value: 'Legal Protection Assistance' },
    { value: 'Claim support service' },
    { value: 'Accidental damage to building' },
    { value: 'Accidental damage to contents' },
    { value: 'Valuable & personal effects' },
    { value: 'Specified items' },
    { value: 'Contents of frozen food cabinet' },
    { value: 'Personal money & credit cards' },
    { value: ' Tracing the leak' },
    { value: 'Pedal cycles' },
    { value: 'Pet insurance' },
    { value: 'Classic vehicle cover' },
    { value: 'Speed boats third perty liability' },
    { value: 'Jet-skiers liability' },
    { value: 'Passengers liability' },
    { value: 'Hunters liability' },
    { value: 'Medical to domestic staff' },
    { value: 'Accidents to domestic staff' },
    { value: 'Public liability to the public' },
    { value: 'Employers liability' },
  ];

  const packages = storage?.data?.risk_addresses[currentProperty]?.is_landlord
    ? [
        {
          value: 'Value',
          description:
            'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
          benefits: ValueDetails,
        },
        {
          value: 'Premium',
          description:
            'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
          benefits: PremiumDetails,
        },
      ]
    : [
        {
          value: 'Tenant',
          description:
            'Home insurance is designed to provide protection of your property including the building, its contents, and your belongings. And for those times you feel that you need more protection, you can add additional covers to your policy',
          benefits: tenantDetails,
        },
      ];

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    if (data.risk_addresses[currentProperty].home_insurance_pack) {
      setSelected(data.risk_addresses[currentProperty].home_insurance_pack);
    }
  }, []);

  function iconType(icon) {
    switch (icon) {
      case 'Value': //terraced
        return <HomeBasicGanIcon />;
      case 'Economic': //semi-detached
        return <HomeEcoGanIcon />;
      case 'Premium':
        return <HomePremGanIcon />;
      default:
        return <HomeCatGanIcon />;
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  const submitHandler = () => {
    if (selected == null) {
      setError('Please choose an insurance package');
      return;
    } else {
      setError();
    }
    patchStorage({
      payload: { home_insurance_pack: selected },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <LeftContainer>
        <TitleForm>{i18n.t('Choose your package')}</TitleForm>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected?.value === insurance.value}
                onClick={() => setSelected(insurance)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
        <Error>{error}</Error>
      </LeftContainer>
      <RightContainer>
        {error?.toString()?.includes(selected?.value) &&
          !policyData[selected?.value] &&
          !isFetching && (
            <ErrorBox>
              <p>Something went wrong while fetching your quote</p>
            </ErrorBox>
          )}
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
        {isLoading ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : (
          selected &&
          !data?.Error && (
            <>
              <TopHalf>
                <Title>{selected.value}</Title>
                <Description>{selected.description}</Description>
              </TopHalf>

              <SubTitle>{selected.value} package includes:</SubTitle>
              <EconomicPackageDetailsContainer>
                {selected?.benefits?.map((detail) => {
                  return (
                    <DetailContainer>
                      <IconStatusCheck />
                      <p>{detail.value}</p>
                    </DetailContainer>
                  );
                })}
              </EconomicPackageDetailsContainer>
            </>
          )
        )}
        <ButtonContainer>
          <TotalPremium>
            <p>{i18n.t('Your total premium')}</p>
            <span>
              {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
            </span>
          </TotalPremium>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => submitHandler()}
              type="submit"
              value="Submit"
              disabled={data?.Error || !selected || isLoading}
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Specify')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  font-size: 1.2rem;
  color: #f74040;
  margin-bottom: 1rem;
  text-align: center;
`;

const TotalPremium = styled.div`
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0;

  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  width: 100%;
  p {
    align-self: center;
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 3rem;
    margin-right: 1rem;
    width: 5rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.p`
  margin-top: 3rem;

  color: #30373d;
  font-size: 1.8rem;
  font-weight: 550;
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `3px solid ${theme.brand.primary}` : '1px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: space-evenly;
  > p {
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
  }
  span > svg {
    height: 7rem;
    width: 7rem;
  }
  @media (max-width: 1000px) {
    height: 12rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  border-right: 1px solid #f5f6f7;
  padding-right: 4rem;
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;

  overflow: auto;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2.8rem;
  flex-shrink: 0;
  margin-bottom: 4rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ErrorBox = styled(LoadingBox)`
  height: 100%;
  align-items: center;
`;

const Description = styled.div`
  line-height: 120%;
`;

export default FlowHomeInsuranceOptions;
