/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { string, array, func } from 'prop-types';
import {
  SearchSelectInput,
  IconActionPlus,
  DateInputV4 as DateInputComponent,
} from 'wg-fe-ui';
import styled from 'styled-components';
import PriceInput_V2 from './PriceInput_V2';
import useForm from '../hooks/useForm';
import { string as validationString } from '../constants/validationSchemas';
import { isObjectEmpty } from '../helpers/objectService';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const PetSelector = ({ name, dogBreeds, catBreeds, onChange }) => {
  const [type, setType] = useState();
  const { t } = useTranslation();
  const bleedArr = type === 'dog' ? dogBreeds : type === 'cat' && catBreeds;
  const validationSchema = Yup.object().shape({
    PetsBreed: Yup.string().required(`required`),
    DateOfBirth: validationString.required,
    PurchaseValue: Yup.number()
      .min(450, t('Purchase value has to be at least 450 EUR'))
      .max(1700, t('Purchase value can only be 1700 EUR'))
      .required(t(`required`)),
    PetsSex: Yup.number().required(t(`required`)),
  });

  const { values, errors, resetForm, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  const handleAdd = (vals) => {
    let typeOid;
    if (type === 'dog') typeOid = '3631273c-c5c1-42cc-aeee-6ad491f8d08d';
    else typeOid = 'c6372008-4fec-4131-9157-4938c6bdeeca';
    const { DateOfBirth, PetsSex, PurchaseValue, PetsBreed } = vals;
    const payload = {
      TypeOfPet: typeOid,
      NameOfPet: type,
      PurchaseValue: PurchaseValue || 0,
      PetsSex,
      DateOfBirth,
      Microchipped: 0,
      NeuteredOrSprayed: 0,
      PetsBreed,
      IllnessOrAccidentTheLastThreeYears: 0,
    };
    onChange(payload);
    setType();
    resetForm();
  };

  useEffect(() => {
    resetForm();
  }, [type]);

  return (
    <div>
      <PetType>
        <Pet onClick={() => setType('dog')} active={type === 'dog'}>
          Dog
        </Pet>
        <Pet onClick={() => setType('cat')} active={type === 'cat'}>
          Cat
        </Pet>
      </PetType>
      {type && (
        <>
          <SearchSelect
            error={errors?.PetsBreed}
            options={bleedArr}
            name="PetsBreed"
            onSelected={handleChange}
            value={
              !isObjectEmpty(values)
                ? bleedArr.find(({ value }) => value === values?.PetsBreed)
                : {}
            }
          >
            {i18n.t(`Choose Breed`)}
          </SearchSelect>
          <Wrapper>
            <DateInputComponent
              error={errors?.DateOfBirth}
              name="DateOfBirth"
              iso
              onChange={handleChange}
            >
              {i18n.t(`Date of Birth`)}
            </DateInputComponent>
            <SearchSelect
              error={errors?.PetsSex}
              options={[
                { label: 'Male', value: 0 },
                { label: 'female', value: 1 },
              ]}
              name="PetsSex"
              onSelected={handleChange}
            >
              {i18n.t(`Pets Sex`)}
            </SearchSelect>
          </Wrapper>
          <PriceInput_V2
            onChange={(value) => handleChange({ name: 'PurchaseValue', value })}
            value={values?.PurchaseValue}
            name="PurchaseValue"
            error={errors?.PurchaseValue}
          >
            {i18n.t(`Purchase value`)}
          </PriceInput_V2>
        </>
      )}
      <AddItemsToInventory
        disabled={!isObjectEmpty(errors) || isObjectEmpty(values)}
        onClick={() => handleSubmit(handleAdd)}
      >
        <IconActionPlus />
        Add {name}
      </AddItemsToInventory>
    </div>
  );
};

const AddItemsToInventory = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme, disabled }) =>
    disabled ? theme.ui.disabled : theme.brand.primary};
  border-radius: 5px;
  font-size: 1.6rem;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border: none;
  margin-bottom: 3rem;
  grid-column: span 6 / auto !important;
  margin-top: 1rem;
  > svg {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.ui.disabled : theme.brand.primary};
    }
  }
`;

const SearchSelect = styled(SearchSelectInput)`
  display: ${({ hidden }) => (hidden ? 'none' : 'unset')};
  min-height: 8rem;
`;

const PetType = styled.div`
  display: flex;
  margin-bottom: 2.4rem;
  justify-content: space-between;
`;

const Pet = styled.div`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-size: 1.4rem;
  height: 5.3rem;
  justify-content: center;
  width: 48%;
  &:focus {
    outline: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    width: 48%;
  }
`;

PetSelector.propTypes = {
  name: string,
  dogBreeds: array,
  catBreeds: array,
  onChange: func,
};

export default PetSelector;
