import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

const ListItem = ({ selectedPack, value, type }) => {
  return (
    <LI checked={selectedPack} type={type}>
      {i18n.t(value)}
    </LI>
  );
};

const LI = styled.li`
  color: ${({ checked, type }) =>
    checked === 'BRONZE' && type === 'BRONZE'
      ? 'green'
      : checked === 'SILVER' && (type === 'BRONZE' || type === 'SILVER')
      ? 'green'
      : checked === 'GOLD'
      ? 'GREEN'
      : 'grey'};
`;
export default ListItem;
