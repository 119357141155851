import React from 'react';

const PropertyGanIcon = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79.61 79.61"
    >
      <g data-name="Layer 2">
        <g fill="none" stroke="#024284" strokeWidth="2.482" data-name="Layer 1">
          <path
            strokeMiterlimit="10"
            d="M39.8 78.37A38.57 38.57 0 1178.37 39.8 38.61 38.61 0 0139.8 78.37z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M56.27 36.17L44.38 36.17 44.38 24.28 35.86 24.28 35.86 36.17 23.98 36.17 23.98 44.69 35.86 44.69 35.86 56.57 44.38 56.57 44.38 44.69 56.27 44.69 56.27 36.17z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default PropertyGanIcon;