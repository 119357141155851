import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconCertificateFilled } from 'wg-fe-ui';

interface Props {
  discounts: any;
  policy: any;
}

const FlowOverviewDiscountsSectionContent: React.FC<Props> = ({
  discounts,
  policy,
}) => {
  const { t } = useTranslation();

  const getDiscountPrice = (value: string) => {
    const discountPercentages = {
      MultipolicyDiscount: 10,
      FiveYearsNoClaimDiscount: 5,
      TenYearsNoClaimDiscount: 10,
      FleetDiscount: 5,
      MyRideDiscount: 50,
      NewGraduateDiscount: 5,
      BrandNewCarDiscount: 10,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policy?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  return (
    <AccordionInfo>
      {discounts
        ?.filter(
          (discount: any) =>
            discount[1] !== '0,00' && discount[0] !== 'Discount'
        )
        ?.map((discount: any) => (
          <div className="discount-row">
            <IconCertificateFilled color="#8990A3" />
            <p className="discount-label">{t(discount[0])}</p>
            <p className="discount-price">{discount[1]} &euro;</p>
          </div>
        ))}
      <Header>Possible discounts</Header>
      {discounts
        ?.filter(
          (discount: any) =>
            discount[1] === '0,00' && discount[0] !== 'Discount'
        )
        ?.map((discount: any) => (
          <div className="discount-row">
            <IconCertificateFilled color="#8990A3" />
            <p className="discount-label">{t(discount[0])}</p>
            <p className="discount-price">
              -{getDiscountPrice(discount[0])} &euro;
            </p>
          </div>
        ))}
    </AccordionInfo>
  );
};

export default FlowOverviewDiscountsSectionContent;
const Header = styled.p`
  font-weight: 550;
  margin-bottom: 1.5rem;
  margin-top: 2.4rem;
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const AccordionInfo = styled(Scroll)`
  overflow-y: auto;
  max-height: 33vh;
  padding-right: 1.6rem;

  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }

    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;
