import React from 'react';

const HomeBasicGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M39.8,78.37A38.57,38.57,0,1,1,78.37,39.8,38.61,38.61,0,0,1,39.8,78.37Z"
            style={{
              fill: 'none',
              stroke: '#98989b',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <polyline
            points="23.73 33.01 23.73 56.36 55.82 56.36 55.82 32.87"
            style={{
              fill: 'none',
              stroke: '#98989b',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <polyline
            points="20.52 35.93 39.78 16.68 58.88 35.78"
            style={{
              fill: 'none',
              stroke: '#98989b',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <rect
            x="34.76"
            y="41.6"
            width="10.17"
            height="14.61"
            style={{
              fill: 'none',
              stroke: '#98989b',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeBasicGanIcon;
