import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton, DateInputV4 as DateInput } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { string } from '../constants/validationSchemas';
import FlowChatBox from '../components/FlowChatBox';
import { differenceInYears, parseISO, format, isFuture } from 'date-fns';

const FlowHomeRenovation = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);

  function handleRenovationYearValidation5Years() {
    const { renovation_year } = this.parent;
    return differenceInYears(new Date(), parseISO(renovation_year)) <= 5;
  }

  function handleRenovationYearValidationFuture() {
    const { renovation_year } = this.parent;
    return !isFuture(parseISO(renovation_year));
  }

  const validationSchema = Yup.object().shape({
    hasRenovated: Yup.boolean(),
    renovation_year: Yup.mixed().when('hasRenovated', {
      is: true,
      then: string.required
        .test(
          'valid-renovation_year-5years',
          i18n.t('The renovation date can not be more than 5 years ago'),
          handleRenovationYearValidation5Years
        )
        .test(
          'valid-renovation_year-future',
          i18n.t('The renovation date can not be in the future'),
          handleRenovationYearValidationFuture
        ),
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Did you renovate your property in <span>the last 5 years</span>?'
      ),
      slug: 'renovation_year',
    };
    addQuestion(question);

    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      renovation_year: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'renovation_year',
      ]),
      hasRenovated: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'hasRenovated',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.renovation_year != null) {
      handleChange({
        name: 'renovation_year',
        value: defaultValues.renovation_year,
      });
    }
    if (defaultValues.hasRenovated != null) {
      handleChange({
        name: 'hasRenovated',
        value: defaultValues.hasRenovated,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = () => {
    setLoading(true);

    patchStorage({
      payload: {
        renovation_year: values.renovation_year,
        hasRenovated: values?.hasRenovated,
      },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });

    if (values?.hasRenovated) {
      const answer = {
        message: i18n.t(
          `The building was recently renovated <span>in ${format(
            parseISO(values?.renovation_year),
            'dd/MM/yyyy'
          )}</span>`
        ),
        slug: 'renovation_year',
      };
      addAnswer(answer);
    } else {
      const answer = {
        message: i18n.t(`The building was not recently renovated`),
        slug: 'renovation_year',
      };
      addAnswer(answer);
    }

    renderNextRoute(1, { property: currentProperty });

    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <QButtonContainer>
          <StyledButton
            active={!values?.hasRenovated}
            onClick={() => handleChange({ name: 'hasRenovated', value: false })}
          >
            {i18n.t('No')}
          </StyledButton>
          <StyledButton
            active={values?.hasRenovated}
            onClick={() => handleChange({ name: 'hasRenovated', value: true })}
          >
            {i18n.t('Yes')}
          </StyledButton>
        </QButtonContainer>
        <form onSubmit={handleForm}>
          {values?.hasRenovated && (
            <div>
              <DateInput
                name="renovation_year"
                type="number"
                min="0"
                iso
                error={errors.renovation_year}
                onChange={(val) => handleChange(val)}
                value={values.renovation_year}
              >
                <p className="label">{i18n.t('Renovation year')} *</p>
              </DateInput>
            </div>
          )}
          <ButtonContainer>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Submit')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const QButtonContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 45% 45%;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.4rem;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  height: 4rem;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: hidden;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

export default FlowHomeRenovation;
