/* 
  output: string
  ex.: 1d7b4dda-be30-4960-a963-ad74a8e41bee

  https://gist.github.com/jed/982883
*/

export const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (a) =>
    (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
  );
};
