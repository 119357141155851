import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { LoadingSpinner } from 'wg-fe-ui';
import { retrieveAutomobileBrands } from '../helpers/apiRouterService';

const BrandLogo = ({ brand }) => {
  const [logoURL, setLogoURL] = useState();
  const { isLoading, data, isError } = useQuery(
    ['brandIcon', brand],
    () => retrieveAutomobileBrands(`q=${brand}`),
    {
      enabled: !!brand,
    }
  );

  useEffect(() => {
    if (data) {
      const [resp] = data;
      const _itm = resp?.items[0];
      setLogoURL(_itm?.brand?.logo_url);
    }
  }, [data]);

  if (isLoading || !logoURL) return <StyledLoadingSpinner />;
  if (isError) return <p>Error</p>;
  return <Logo src={logoURL} alt={`${brand} logo`} />;
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  min-width: 4rem;
  width: 4rem;
  height: 4rem;
  transform-origin: 2rem 2rem 2rem;
  min-height: 4rem;
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
  max-width: 4rem;
  max-height: 4rem;
`;

export default BrandLogo;
