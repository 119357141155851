import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import useRouting from '../hooks/useRouting';
import { ActionButton, TextInput, IconActionChevronLeft } from 'wg-fe-ui';
import { getNestedObject } from '../helpers/objectService';
import i18n from '../i18n';
import LoadingActionButton from '../components/LoadingActionButton';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import SearchSelectInputV3 from '../components/SearchSelectInputV3';
import { building_types } from '../constants/BuildingTypesData';
import useForm from '../hooks/useForm';
import { string } from '../constants/validationSchemas';

const FlowHomeInformation = () => {
  const [loading, setLoading] = useState(false);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const { renderNextRoute } = useRouting();
  const [defaultValues, setDefaultValues] = useState({});
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    house_type: Yup.string().required(i18n.t('required')),
    habitable_surface_area: string.required,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Great location! What <span>type of building</span> is it?'
      ),
      slug: 'home-information',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      house_type: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'house_type',
      ]),
      buildingSurface: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'surface',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.house_type != null) {
      handleChange({
        name: 'house_type',
        value: defaultValues.house_type,
      });
    }
    if (defaultValues.buildingSurface != null) {
      handleChange({
        name: 'habitable_surface_area',
        value: defaultValues.buildingSurface,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    console.log('Form handler');
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const submitHandler = () => {
    console.log(values);
    setLoading(true);

    const answer = {
      message: i18n.t(`I live in a with habitable space`, {
        houseType: `<span>${
          building_types?.find((item) => item?.value === values.house_type)
            ?.label
        }</span>`,
        surface: values.habitable_surface_area,
      }),
      slug: 'house-type',
    };
    addAnswer(answer);

    if (
      risk_addresses[currentProperty]?.parcel?.main_building?.house_type !==
      values.house_type
    ) {
      patchStorage({
        payload: { house_type: values.house_type },
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
    }

    if (
      risk_addresses[currentProperty]?.parcel?.main_building?.surface !==
      values.habitable_surface_area
    ) {
      patchStorage({
        payload: { surface: Math.round(values.habitable_surface_area) },
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
    }

    renderNextRoute(1, { property: currentProperty });
    setLoading(false);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <SplitContainer>
      <FlowChatBox />
      <RightSplit>
        <form onSubmit={handleForm}>
          <div>
            <TitleForm>{i18n.t('Details about the property')}</TitleForm>
            <SelectInput
              isClearable
              name="house_type"
              error={errors.house_type}
              value={handleSelectValue(building_types, 'house_type')}
              onSelected={({ value }) => {
                handleChange({
                  name: 'house_type',
                  value: value,
                });
              }}
              options={building_types}
              placeholder="Choose an option"
            >
              {i18n.t('Select the building type')}
            </SelectInput>
            <StyledTextInput
              name="habitable_surface_area"
              error={errors.habitable_surface_area}
              type="number"
              min="1"
              step="1"
              onChange={(val) => {
                handleChange(val);
              }}
              value={values.habitable_surface_area}
            >
              <p>{i18n.t('Habitable surface area')}</p>
              <p className="valueType">{i18n.t('m²')}</p>
            </StyledTextInput>
          </div>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const SelectInput = styled(SearchSelectInputV3)`
  margin-bottom: 2rem;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;

  p.error {
    color: red;
  }

  p.valueType {
    padding: 1.5rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
  span {
    color: #aeaeae;
    font-size: 1.4rem;
    margin-left: 2rem;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const RightSplit = styled.div`
  display: flex;
  padding-left: 4rem;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;

    & > div {
      width: 100%;
    }
  }
`;

const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeInformation;
