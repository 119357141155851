export default [
  {
    title: 'verify-user',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'login',
      2: 'user-info',
    },
  },
  {
    title: 'login',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'user-address-mobile',
    },
  },
  {
    title: 'user-info',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'fill-user-info',
    },
  },
  {
    title: 'fill-user-info',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'user-address-mobile',
    },
  },
  {
    title: 'user-address-mobile',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'select-insurances-mobile',
    },
  },
  {
    title: 'select-insurances-mobile',
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: 'risk-address-mobile',
    },
  },
  {
    title: 'risk-address-mobile',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'risk-address-mortaged-mobile',
      2: 'address-to-insure',
    },
  },
  {
    title: 'risk-address-mortaged-mobile',
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: 'homeowner',
    },
  },
  {
    title: 'homeowner',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'home-building-type',
    },
  },
  {
    title: 'address-to-insure',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'risk-address-mortaged-mobile',
    },
  },
  {
    title: 'home-building-type',
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: 'home-surface-area',
    },
  },
  {
    title: 'home-surface-area',
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: 'home-construction',
    },
  },
  // {
  //   title: 'home-information-extra-mobile',
  //   step: 2,
  //   subStep: 2,
  //   nextRoutes: {
  //     1: 'home-construction',
  //   },
  // },
  {
    title: 'home-construction',
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: 'business-purpose-rooms',
      2: 'home-renovation',
    },
  },
  {
    title: 'home-renovation',
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: 'business-purpose-rooms',
    },
  },
  // {
  //   title: 'home-extra-questions',
  //   step: 2,
  //   subStep: 3,
  //   nextRoutes: {
  //     1: 'business-purpose-rooms',
  //   },
  // },
  {
    title: 'business-purpose-rooms',
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: 'property-insurance-policy-mobile',
    },
  },
  // {
  //   title: 'storage-unit',
  //   step: 2,
  //   subStep: 4,
  //   nextRoutes: {
  //     1: 'property-insurance-policy-mobile',
  //   },
  // },
  {
    title: 'property-insurance-policy-mobile',
    step: 2,
    subStep: 5,
    nextRoutes: {
      1: 'property-insurance-policy-information-mobile',
      2: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-policy-information-mobile',
    step: 2,
    subStep: 6,
    nextRoutes: {
      1: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-extras',
    step: 2,
    subStep: 7,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-bike',
      3: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'property-insurance-extras-pets',
    step: 2,
    subStep: 9,
    nextRoutes: {
      1: 'home-specifics',
    },
  },
  {
    title: 'property-insurance-extras-bike',
    step: 2,
    subStep: 8,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'home-specifics-mobile',
    step: 1,
    subStep: 7,
    nextRoutes: {
      1: 'home-high-risk-mobile',
    },
  },
  {
    title: 'home-high-risk-mobile',
    step: 1,
    subStep: 8,
    nextRoutes: {
      1: 'insurance-options-home-mobile',
    },
  },
  {
    title: 'insurance-options-home-mobile',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'home-premium-summary-mobile',
    },
  },
  {
    title: 'home-premium-summary-mobile',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'second-risk-address-mobile',
      2: 'car-already-registered-mobile',
      3: 'overview',
    },
  },
  {
    title: 'overview',
    step: 3,
    subStep: 8,
    nextRoutes: {},
  },
  {
    title: 'thankyou',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];
