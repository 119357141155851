import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import { ganGetCustomerVehicles } from '../helpers/apiRouterService';
import LoadingSpinner from '../components/LoadingSpinner';

const FlowCarRegisteredMobile = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const [customerCars, setCustomerCars] = useState([]);
  const [option, setOption] = useState();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { ganCustomer } = data || {};
  const { cars } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);

  useEffect(() => {
    if (currentVehicle === '0') {
      const question = {
        message: i18n.t(
          "No problem! Let's look at the car insurance you wanted"
        ),
        slug: 'car_already_registered',
      };
      addQuestion(question);
    } else {
      const question = {
        message: i18n.t('Is this <span>car already registered</span>?'),
        slug: 'car_already_registered',
      };
      addQuestion(question);
    }
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const [resp, status] = await ganGetCustomerVehicles(ganCustomer?.Oid);
    if (status === 200) {
      const data = resp.Data.map((data) => ({
        label: data?.VehicleRef,
        value: data,
      }));
      data.push({
        label: 'Other',
        value: 'OTHER',
      });
      setCustomerCars(data);
      cars[currentVehicle]?.customer_vehicle &&
      cars[currentVehicle]?.customer_vehicle !== 'OTHER'
        ? setOption({
            label: cars[currentVehicle]?.customer_vehicle?.VehicleRef,
            value: cars[currentVehicle]?.customer_vehicle,
          })
        : setOption({
            label: 'Other',
            value: 'OTHER',
          });
    }
  };

  const submitHandler = (carRegistered) => {
    if (option?.value === 'OTHER') {
      let car_information = {
        car_already_registered: carRegistered,
      };
      cars[currentVehicle] = car_information;

      const answer = {
        message: i18n.t(carRegistered ? `Yes` : 'No'),
        slug: 'car_already_registered',
      };

      addAnswer(answer);
    } else {
      let car_information = {
        car_already_registered: true,
        license_plate: option?.value?.RegNumber,
        second_hand_car: false,
        vehicle_info: option?.value,
        customer_vehicle: option?.value,
      };

      cars[currentVehicle] = car_information;
    }

    patchStorage({
      payload: { cars },
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(option?.value === 'OTHER' ? 1 : 2, {
      vehicle: currentVehicle,
    });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <TitleForm>{i18n.t('Select your vehicle')}</TitleForm>
        <SearchSelectInputV2
          key={`existing_vehicle`}
          name="Title"
          disabled={customerCars?.length < 1}
          onChange={(val) => {
            setOption(val);
          }}
          options={customerCars}
          placeholder={i18n.t('Select your vehicle')}
          value={option}
        />
        {customerCars?.length < 1 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LoadingSpinner />
          </div>
        )}
        {option?.value === 'OTHER' ? (
          <>
            <TitleForm>{i18n.t('Is your car already registered?')}</TitleForm>
            <AddressToInsureQ>
              <StyledButton
                onClick={(e) => {
                  e.preventDefault();
                  submitHandler(true);
                }}
              >
                {i18n.t('Yes')}
              </StyledButton>
              <StyledButton
                onClick={(e) => {
                  e.preventDefault();
                  submitHandler(false);
                }}
              >
                {i18n.t('No')}
              </StyledButton>
            </AddressToInsureQ>
          </>
        ) : (
          <StyledButton
            onClick={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            {i18n.t('Confirm')}
          </StyledButton>
        )}
      </RightContainer>
    </Container>
  );
};

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const AddressToInsureQ = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  height: 4rem;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: hidden;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

export default FlowCarRegisteredMobile;
