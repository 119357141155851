import React from 'react';
import styled from 'styled-components';
import { SearchSelectInput } from 'wg-fe-ui';

const SearchSelectInputV2 = ({ children, ...rest }) => {
  return <StyledSearchSelect {...rest}>{children}</StyledSearchSelect>;
};

const StyledSearchSelect = styled(SearchSelectInput)`
  width: 100%;
  justify-self: center;
  position: relative;
  text-transform: capitalize;

  label {
    line-height: 120% !important;
  }

  span {
    margin-top: 0.8rem;
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : '#e4e4e4'};
  }

  &:focus {
    outline: ${({ theme }) => theme.brand.primary};
  }

  & .Select {
    width: 100%;
    &__control {
      border-radius: 0.3rem;
      min-height: 4rem;
      border: 1px solid;
      border-color: ${({ theme, error, touched }) =>
        error
          ? theme.status.error
          : touched & !error
          ? theme.status.succes
          : '#e4e4e4'};
      position: relative;

      :hover {
        border-color: ${({ theme }) => theme.brand.primary};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : '#e4e4e4'};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : '#e4e4e4'};
        }
      }
    }

    &__placeholder {
      color: #c1c1c1;
    }

    &__control--menu-is-open {
      &:hover {
        border-color: ${({ theme }) => theme.ui.interactive};
      }

      .Select__dropdown-indicator {
        & svg {
          transform: rotate(180deg);
          transition: 0.1s ease;
        }
      }
    }

    &__value-container {
      padding: 0 0.7rem;
      font-size: 1.6rem;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    &__single-value {
      color: black;
      overflow: visible;
      overflow: initial;
    }

    &__menu {
      font-size: 1.6rem;
      box-shadow: none;
      top: 3rem;
      border: 1px solid #e4e4e4;
      border-top: none;
      border-radius: 0 0 0.3rem 0.3rem;
      overflow: hidden;
      position: absolute;
      background: none;
      padding-top: 0.8rem;
      color: #050505;
    }

    &__menu-list {
      max-height: 16rem;
      background-color: white;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: #c7c7c7;
      }

      &::-webkit-scrollbar-track-piece {
        background: #f0f1f3;
        border-radius: 5px;
        width: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
      }

      &::-webkit-scrollbar:vertical {
        width: 12px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }
    }

    &__menu-notice--no-options,
    &__menu-notice--loading {
      min-height: 4.5rem;
      display: flex;
      align-items: center;
    }

    &__option {
      background: white;
      padding: 0.4rem 1.2rem;
      min-height: 4rem;
      display: flex;
      color: ${({ theme }) => theme.typo.title};
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #f0f1f3;

      &:last-of-type {
        border-bottom: none;
      }

      & .custom-option_primary-label {
        color: ${({ theme }) => theme.typo.title};
        font-size: 1.6rem;
      }

      & .custom-option_secondary-label {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.typo.title};
      }

      &:hover {
        background-color: ${({ theme }) => theme.labels.guarantee};
        color: ${({ theme }) => theme.brand.primary};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.labels.guarantee};
      }

      &--is-selected {
        background-color: white;
        color: ${({ theme }) => theme.brand.primary};
        & .custom-option_primary-label {
          color: ${({ theme }) => theme.brand.primary} !important;
        }
        & .custom-option_secondary-label {
          color: ${({ theme }) => theme.brand.secondary} !important;
        }
      }
    }

    &__multi-value {
      background: rgba(255, 128, 0, 0.05);
      border: 1px solid ${({ theme }) => theme.brand.primary};
      box-sizing: border-box;
      border-radius: 3px;

      &__label {
        color: ${({ theme }) => theme.brand.primary};
        line-height: 120%;
        padding: 0.2rem 0.2rem 0.2rem 0.4rem;
        box-sizing: border-box;
      }
      &__remove {
        cursor: pointer;

        & svg {
          fill: ${({ theme }) => theme.brand.primary};
        }

        &:hover {
          background-color: initial;
        }
      }
    }
  }
`;

export default SearchSelectInputV2;
