import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';
import i18n from '../i18n';
import {
  ActionButton,
  TextInput,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';
import { getNestedObject } from '../helpers/objectService';
import { insurance_companies } from '../constants/FlowSearchSelectData';
import { string, number } from '../constants/validationSchemas';
import SearchSelectInputV3 from '../components/SearchSelectInputV3';

const FlowHomeExistingPropertyInsuranceInformationMobile = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { addAnswer } = useContext(ChatContext);
  const [defaultValues, setDefaultValues] = useState({});

  const validationSchema = Yup.object().shape({
    insurance_company_name: string.required,
    end_of_current_contract: Yup.date().when('insurance_company_name', {
      is: 'None' || 'GAN DIRECT INSURANCE LTD' || 'OVERSEAS',
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .required('Required')
        .min(new Date(), 'Date must be in the future'),
    }),
    insurance_price: Yup.number().when('insurance_company_name', {
      is: 'None' || 'GAN DIRECT INSURANCE LTD' || 'OVERSEAS',
      then: number.notRequired,
      otherwise: number.required,
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      insurances: data.insurances,
      insurance_company_name: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'insurance_company_name',
      ]),
      end_of_current_contract: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'end_of_current_contract',
      ]),
      insurance_price: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'current_insurance_info',
        'insurance_price',
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues.insurance_company_name != null) {
      handleChange({
        name: 'insurance_company_name',
        value: defaultValues.insurance_company_name,
      });
    }
    if (defaultValues.end_of_current_contract != null) {
      handleChange({
        name: 'end_of_current_contract',
        value: defaultValues.end_of_current_contract,
      });
    }
    if (defaultValues.insurance_price != null) {
      handleChange({
        name: 'insurance_price',
        value: defaultValues.insurance_price,
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const submitHandler = () => {
    setLoading(true);
    const insurance_info = {
      insurance_company_name: values.insurance_company_name,
      end_of_current_contract: values.end_of_current_contract
        ? values.end_of_current_contract
        : null,
      insurance_price: values.insurance_price ? values.insurance_price : null,
    };

    patchStorage({
      payload: insurance_info,
      path: ['risk_addresses', [currentProperty], 'current_insurance_info'],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: `I have an insurance with <span>${
        values.insurance_company_name
      }</span> that ends on <span>${new Date(
        values.end_of_current_contract
      ).toLocaleDateString()}</span>.`,
      slug: 'existing_property_insurance_information',
    };

    addAnswer(answer);

    renderNextRoute(1, { property: currentProperty });

    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <SearchSelectInputV3
              name="insurance_company_name"
              placeholder="Choose an option"
              error={errors.insurance_company_name}
              options={insurance_companies}
              value={handleSelectValue(
                insurance_companies,
                'insurance_company_name'
              )}
              onSelected={({ value }) => {
                handleChange({
                  name: 'insurance_company_name',
                  value: value,
                })
              }}
            >
              <Label>{i18n.t('Insurance company')}</Label>
            </SearchSelectInputV3>
            {values.insurance_company_name == null ||
              (values.insurance_company_name !== 'None' &&
                values.insurance_company_name !== 'GAN DIRECT INSURANCE LTD' &&
                values.insurance_company_name !== 'OVERSEAS' && (
                  <>
                    <DateInput
                      name="end_of_current_contract"
                      error={errors.end_of_current_contract}
                      value={values.end_of_current_contract}
                      onChange={(val) => {
                        handleChange(val);
                      }}
                    >
                      <Label>{i18n.t('End date')}</Label>
                    </DateInput>
                    <StyledTextInput
                      name="insurance_price"
                      type="number"
                      min="0"
                      error={errors.insurance_price}
                      value={values.insurance_price}
                      onChange={(val) => {
                        handleChange(val);
                      }}
                    >
                      <p>{i18n.t('Price')}</p>
                      <p className="valueType">&euro;</p>
                    </StyledTextInput>
                  </>
                ))}
          </div>
          <ButtonContainer>
            <ActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Submit')}
            </ActionButton>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const Label = styled.p`
  color: #8990a3;
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.5rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  form {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    > div > div {
      height: 9rem;
    }
    .Select__control {
      border-color: #e4e4e4 !important;
    }
    .Select__indicator-separator {
      background-color: #e4e4e4 !important;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowHomeExistingPropertyInsuranceInformationMobile;
