import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n.js';
import FlowOverviewPolicyDetail from './FlowOverviewPolicyDetail.tsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowOverviewForm = ({
  updatePayload,
  isLoading,
  updatedPolicies,
  togglePolicy,
}) => {
  const [premiumSummary, setPremiumSummary] = useState([]);

  const getSummaryPrices = (type) => {
    let totalSummary = 0;
    let property_prices = 0;
    let vehicle_prices = 0;
    let health_prices = 0;

    if (type !== 'MIF') {
      if (updatedPolicies?.risk_addresses)
        updatedPolicies.risk_addresses
          .filter((item) => item.inCart)
          .forEach(
            (item) =>
              (property_prices += parseFloat(
                item?.response?.[type]?.replace(',', '.')
              ))
          );
    }

    if (updatedPolicies?.cars)
      updatedPolicies.cars
        .filter((item) => item.inCart)
        .forEach(
          (item) =>
            (vehicle_prices += parseFloat(
              item?.response?.[type]?.replace(',', '.')
            ))
        );
    if (updatedPolicies?.healths)
      updatedPolicies.healths
        .filter((item) => item.inCart)
        .forEach(
          (item) =>
            (health_prices += parseFloat(
              item?.response?.[type]?.replace(',', '.')
            ))
        );

    if (property_prices) totalSummary += property_prices;
    if (vehicle_prices) totalSummary += vehicle_prices;
    if (health_prices) totalSummary += health_prices;
    return (Math.round((totalSummary + Number.EPSILON) * 100) / 100)
      .toString()
      ?.replace('.', ',');
  };

  useEffect(() => {
    if (updatedPolicies) {
      const premium_summary_items = [
        ...(updatedPolicies?.cars?.length
          ? [
              {
                value: 'MIF',
                amount: parseFloat(
                  getSummaryPrices('MIF')?.replace(/,/g, '.') || 0
                ).toLocaleString('nl-BE', myObj),
              },
            ]
          : []),
        {
          value: 'Premium',
          amount: (
            parseFloat(getSummaryPrices('Total')?.replace(/,/g, '.') || 0) +
              -1 *
                (parseFloat(getSummaryPrices('Discount')?.replace(/,/g, '.')) +
                  parseFloat(
                    getSummaryPrices('LoyaltyDiscount')?.replace(/,/g, '.')
                  )) || 0
          ).toLocaleString('nl-BE', myObj),
        },
        {
          value: 'Discounts',
          amount: (
            parseFloat(getSummaryPrices('Discount')?.replace(/,/g, '.')) +
              parseFloat(
                getSummaryPrices('LoyaltyDiscount')?.replace(/,/g, '.')
              ) || 0
          ).toLocaleString('nl-BE', myObj),
        },
        {
          value: 'Stamp & fees',
          amount: parseFloat(
            getSummaryPrices('Fees')?.replace(/,/g, '.') || 0
          ).toLocaleString('nl-BE', myObj),
        },
        {
          value: 'Total',
          amount: parseFloat(
            getSummaryPrices('TotalwithFees')?.replace(/,/g, '.') || 0
          ).toLocaleString('nl-BE', myObj),
        },
      ];
      setPremiumSummary(premium_summary_items);
    }
  }, [updatedPolicies]);

  return (
    <Container>
      <Title>{i18n.t('Your chosen products')}</Title>
      <p>{i18n.t('Click on each product for more information')}</p>
      <ProductsContainer>
        {updatedPolicies?.risk_addresses?.map((item, i) => (
          <FlowOverviewPolicyDetail
            policy={item}
            inCart={item?.inCart}
            id={i}
            type="risk_addresses"
            updatePayload={updatePayload}
            removePolicy={() => togglePolicy('risk_addresses', i)}
          />
        ))}
        {updatedPolicies?.cars?.map((item, i) => (
          <FlowOverviewPolicyDetail
            policy={item}
            inCart={item?.inCart}
            id={i}
            updatePayload={updatePayload}
            type="cars"
            removePolicy={() => togglePolicy('cars', i)}
          />
        ))}
        {updatedPolicies?.healths?.map((item, i) => (
          <FlowOverviewPolicyDetail
            policy={item}
            inCart={item?.inCart}
            id={i}
            updatePayload={updatePayload}
            type="healths"
            removePolicy={() => togglePolicy('healths', i)}
          />
        ))}
        {isLoading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </ProductsContainer>
      <PremiumContainer>
        <div>
          <PremiumTitle>{i18n.t('Premium summary')}</PremiumTitle>
          <PremiumItems>
            {premiumSummary.map((item) => {
              return (
                <div>
                  <span>{item.value}</span>
                  <p>{item.amount}</p>
                </div>
              );
            })}
          </PremiumItems>
          <TotalContainer>
            <p>{i18n.t('Your total premium')}</p>
            <strong>
              {parseFloat(
                getSummaryPrices('TotalwithFees')?.replace(/,/g, '.') || 0
              ).toLocaleString('nl-BE', myObj)}{' '}
            </strong>
          </TotalContainer>
        </div>
      </PremiumContainer>
    </Container>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  height: 100%;
  overflow: auto;
  flex-direction: column;
  display: flex;
  padding: 3px;

  @media (max-width: 768px) {
    padding: 0;
    overflow: visible;
  }
`;

const PremiumTitle = styled.h2`
  color: #30373d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    padding: 0.4rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const PremiumContainer = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.6rem;

  @media (max-width: 768px) {
    padding: 0;
    background: transparent;
  }
`;

const ProductsContainer = styled.div`
  flex: 1 1 100%;
`;

const Title = styled.h1`
  color: #224074;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 120%;
  & + p {
    margin-bottom: 2rem;
  }

  @media (max-width: 1200px) {
    font-size: 2.4rem;
  }
`;

const TotalContainer = styled.div`
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  border-top: solid 0.1rem #e4e4e4;

  & > p {
    font-size: 1.6rem;
    color: #8990a3;
    margin-bottom: 0.8rem;
  }

  & > strong {
    font-size: 2.1rem;
    font-weight: 700;
    color: black;
  }
  @media (max-width: 768px) {
    text-align: right;
    margin-top: 0;
  }
`;

export default FlowOverviewForm;
