import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';
import useRouting from '../hooks/useRouting';
import { useParams } from 'react-router';
import RoomData from '../components/RoomData.jsx';
import FlowLeftSideMap from '../components/FlowLeftSideMap';
import { getNestedObject } from '../helpers/objectService';

const FlowHomeInformationExtra = () => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { renderNextRoute } = useRouting();
  const { risk_address } = data;
  const [errorRooms, setErrorRooms] = useState('');
  const [roomData, setRoomData] = useState({
    bathrooms: 1,
    bedrooms: 2,
    garages: 1,
    kitchens: 1,
    living_rooms: 1,
    other_rooms: 1,
    porch: 0,
    storage_rooms: 0,
  });

  useEffect(() => {
    const roomsObj = { ...roomData };
    const backendRooms =
      getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'room_estimation',
      ]) || {};
    setRoomData(Object.assign(roomsObj, backendRooms));
  }, []);

  let arrRooms = [];
  function handleRisk(name, count) {
    if (count >= 0) {
      arrRooms = { ...roomData, [name]: count };
    }
    setRoomData(arrRooms);
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    let totalRooms = 0;
    Object.values(roomData).forEach((room) => {
      totalRooms += room;
    });
    if (totalRooms >= 3 && totalRooms <= 13) {
      setErrorRooms('');
      patchStorage({
        payload: roomData,
        path: ['risk_address', 'parcel', 'main_building', 'room_estimation'],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute();
    } else {
      setErrorRooms(
        "There's a minimum of 3 and a maximum of 13 rooms in total"
      );
    }
  }

  return (
    <SplitContainer>
      <FlowLeftSideMap
        chatText={
          <>
            <Label>{i18n.t('Address')}</Label>
            <Address>
              {risk_address.address.street} {risk_address.address.housenr},{' '}
              {risk_address.address.zipcode} {risk_address.address.city}
            </Address>
          </>
        }
        addressInfo={risk_address}
      />
      <RightSplit onSubmit={(e) => formSubmitHandler(e)}>
        <h1>{i18n.t('Risk address')}</h1>
        <RoomEstimationsContainer>
          {Object.keys(roomData).map((key) => {
            return (
              <RoomData
                key={key}
                name={key}
                value={roomData[key]}
                handleCountChange={handleRisk}
              />
            );
          })}
        </RoomEstimationsContainer>
        <ButtonContainer>
          <ErrorMsg>{i18n.t(errorRooms)}</ErrorMsg>
          <StyledActionButton>{i18n.t('Next')}</StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};
const ErrorMsg = styled.div`
  text-align: right;
  color: #f74040;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: -1rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const Label = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.typo.title};
`;

const Address = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 600;
`;

const RoomEstimationsContainer = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 1rem;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  & button {
    margin-top: 2rem;
    margin-left: auto;
    width: 20rem;
  }
`;

const RightSplit = styled.form`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowHomeInformationExtra;
