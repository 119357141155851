import { useState, useEffect } from 'react';
import { isObjectEmpty } from '../helpers/objectService';

const defaultOptions = {
  mode: 'onSubmit',
  validationSchema: {},
};

const useForm = (passedOptions) => {
  const [options, setOptions] = useState(
    Object.assign({}, defaultOptions, passedOptions)
  );
  useEffect(() => {
    if (!passedOptions) return;
    setOptions((prev) => Object.assign(prev, passedOptions));
  }, [passedOptions]);

  const [values, setValues] = useState(options.initialValues || {});
  const [errors, setErrors] = useState({});

  const handleChange = ({ name, value }) => {
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  useEffect(() => {
    setOptions({ ...options, mode: passedOptions.mode });
  }, [passedOptions.mode]);

  useEffect(() => {
    if (options.mode === 'onChange') {
      const valuesObject = values;
      validate(valuesObject).then((callbackErrors) => {
        setErrors(callbackErrors);
      });
    }
  }, [values]);

  /* TODO: fix to handle arrays
    for now if object is an array, its error
    obj has 'clients[0].first_name' key as a string.
    E.g {'clients[0].first_name': 'this is required'}
  */
  const validate = (valuesObject, schema) =>
    new Promise((resolve) => {
      let { validationSchema } = options || {};
      if (schema) validationSchema = schema;
      const tempErrors = {};
      const validators = validationSchema._nodes.map((field) =>
        validationSchema.validateAt(field, valuesObject)
      );

      Promise.all(validators.map((p) => p.catch((e) => e))).then((errors) => {
        const inValidResults = errors.filter(
          (result) => result instanceof Error
        );
        inValidResults.forEach((err) => {
          const { errors } = err || {};
          const [firstError] = errors || [];
          tempErrors[err.path] = firstError;
        });
        resolve(tempErrors);
      });
    });

  const handleSubmit = async (callback) => {
    setOptions((values) => ({
      ...values,
      mode: 'onChange',
    }));

    const callbackErrors = await validate(values);

    setErrors(callbackErrors);
    if (isObjectEmpty(callbackErrors)) {
      callback(values);
    }
  };

  const resetForm = (fullReset) => {
    if (passedOptions.mode !== 'onChange')
      setOptions({ ...options, mode: 'onSubmit' });

    setValues({});
    setErrors({});
  };

  return {
    handleChange,
    handleSubmit,
    resetForm,
    values,
    setValues,
    errors,
    setErrors,
    validate,
  };
};

export default useForm;
