import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import i18n from '../i18n';
import Tooltip from '../components/Tooltip';
import {
  ActionButton,
  Alert,
  IconStatusCheck,
  IconActionDropDown,
  PieProgressIndicator,
  IconActionClose,
  IconCertificateFilled,
  SmallLoader,
  SearchSelectInput,
} from 'wg-fe-ui';
import MobileCoverDetail from '../components/MobileCoverDetailHealth';
import { createHealthPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import { getCoverDetailsHealth } from '../helpers/CoverDetailsService.js';
import { usePremiumSummaryStore } from '../contexts/PremiumSummaryContext.js';
import { handlePolicyStartDate } from '../helpers/dateService.js';

const getBoolean = (value) => {
  if (value === 'yes') return 1;
  return 0;
};

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowHealthPremiumSummaryMobile = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [open, setOpen] = useState();
  const [openDiscounts, setOpenDiscounts] = useState();
  const [selected, setSelected] = useState();
  const { healths } = storage?.data;
  const [policyData, setPolicyData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [discounts, setDiscounts] = useState([]);
  const [{ state, addPackage, editPackage }] = usePremiumSummaryStore();

  const getPayload = (_data, selected, currentHealth) => {
    if (!selected || !currentHealth) return;
    if (state?.packages?.health?.[currentHealth]?.[selected]) {
      return state?.packages?.health?.[currentHealth]?.[selected];
    }
    const payload = {
      SavePolicy: _data?.SavePolicy || 'False',
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      MedicalStatus: 1,
      DoYouSmoke: getBoolean(_data?.healths[currentHealth]?.DoYouSmoke),
      ExistingInsuranceCompany: {
        InsuranceCompany:
          _data?.healths?.[currentHealth]?.insurance_company_name,
      },
      ExpiringDateOfYourCurrentPolicy: _data?.healths?.[currentHealth]
        ?.insurance_company_name
        ? _data?.healths?.[currentHealth]?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.healths?.[currentHealth]
        ?.insurance_company_name
        ? _data?.healths?.[currentHealth]?.insurance_price || null
        : null,
      NumberOfCigarettesPerDay:
        _data?.healths[currentHealth]?.NumberOfCigarettesPerDay,
      AnyChronicOrLongTermMedicalCondition: getBoolean(
        _data?.healths[currentHealth]?.AnyChronicOrLongTermMedicalCondition
      ),
      PolicyStartDate: handlePolicyStartDate(
        _data.healths[currentHealth]?.policy_details?.starting_date
      ),
      PolicyEndDate: new Date(
        _data.healths[currentHealth]?.policy_details?.ending_date
      ).toISOString(),
      policyDuration:
        _data.healths[currentHealth]?.policy_details?.insurance_duration,
      WeightInKg: _data?.healths[currentHealth]?.WeightInKg,
      HeightInCM: _data?.healths[currentHealth]?.HeightInCM,
      CurrentlyDisabledPregnantOrUnableToPerformNormalActivities: getBoolean(
        _data?.healths[currentHealth]
          ?.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
      ),
      EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth: getBoolean(
        _data?.healths[currentHealth]
          ?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
      ),
      AnyDiseasesOfTheCirculatorySystemHeart: getBoolean(
        _data?.healths[currentHealth]?.AnyDiseasesOfTheCirculatorySystemHeart
      ),
      SpecifyTheDisease: _data?.healths[currentHealth]?.SpecifyTheDisease,
      DateOfRecentBloodPressureReading:
        _data?.healths[currentHealth]?.DateOfRecentBloodPressureReading,
      BloodPresureResult: _data?.healths[currentHealth]?.BloodPresureResult,
      MedicationTaken: _data?.healths[currentHealth]?.MedicationTaken,
      NutritionalAndMetabolicDiseases: getBoolean(
        _data?.healths[currentHealth]?.NutritionalAndMetabolicDiseases
      ),
      DiabetesDisease: _data?.healths[currentHealth]?.DiabetesDisease,
      DiabetesDiagnosed: _data?.healths[currentHealth]?.DiabetesDiagnosed,
      ControlledByDiet: getBoolean(
        _data?.healths[currentHealth]?.ControlledByDiet
      ),
      DiabetesMedicationTaken:
        _data?.healths[currentHealth]?.DiabetesMedicationTaken,
      RecentHba1cTest: _data?.healths[currentHealth]?.RecentHba1cTest,
      ResultHba1c: _data?.healths[currentHealth]?.ResultHba1c,
      HowManyMonthsOfAYearDoYouLiveAbroad:
        _data?.healths[currentHealth]?.HowManyMonthsOfAYearDoYouLiveAbroad,
      AreBothOYourParentsSmokers: getBoolean(
        _data?.healths[currentHealth]?.AreBothOYourParentsSmokers
      ),
      NoOfCigarettesParentsSmoke:
        _data?.healths[currentHealth]?.NoOfCigarettesParentsSmoke,
      ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus: getBoolean(
        getBoolean(
          _data?.healths[currentHealth]
            ?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
        )
      ),

      YoungestParentAge: _data?.healths[currentHealth]?.YoungestParentAge,
      Doctor: _data?.healths[currentHealth]?.Doctor,
      LastDateSeenByTheDoctor:
        _data?.healths[currentHealth]?.LastDateSeenByTheDoctor,
      ReasonOfLastVisit: _data?.healths[currentHealth]?.ReasonOfLastVisit,
      CanYouProvideMedicalCertificate: getBoolean(
        _data?.healths[currentHealth]?.CanYouProvideMedicalCertificate
      ),
      CurrentMediclaPolicyFromDate:
        _data?.healths[currentHealth]?.CurrentMediclaPolicyFromDate,
      CurrentMediclaPolicyToDate:
        _data?.healths[currentHealth]?.CurrentMediclaPolicyToDate,
      InsuranceScheduleFromPreviousInsuranceCompany: null,
      MedicalInPatient: getBoolean(
        _data?.healths[currentHealth]?.MedicalInPatient
      ),
      InPatientAnnualLimit: _data?.healths[currentHealth]?.InPatientAnnualLimit,
      InPatientDeductible: _data?.healths[currentHealth]?.InPatientDeductible,
      InPatientCoInsurance: _data?.healths[currentHealth]?.InPatientCoInsurance,
      NewBornBaby: getBoolean(_data?.healths[currentHealth]?.NewBornBaby),
      MedicalOutPatient: 0,
      GeographicalArea: _data?.healths[currentHealth]?.GeographicalArea,
      ReimbursementMethod:
        _data?.healths[currentHealth]?.ReimbursementMethod || 1,
      MedicalCallCenteService: getBoolean(
        _data?.healths[currentHealth]?.MedicalCallCenteService
      ),
      TravelTransportationAndOutOfGeorgaphycalArea: 0,
      DeathBenefit: getBoolean(_data?.healths[currentHealth]?.DeathBenefit),
      DeathAmountInsured: _data?.healths[currentHealth]?.DeathAmountInsured,
      MortgageFinancialInstitution:
        _data?.healths[currentHealth]?.MortgageFinancialInstitution,
      DreadDisease: _data?.healths[currentHealth]?.DreadDisease,
      DreadSumInsured: _data?.healths[currentHealth]?.DreadSumInsured,
      PermanentTotalOrPartialDisability: getBoolean(
        _data?.healths[currentHealth]?.PermanentTotalOrPartialDisability
      ),
      DisabilityAmount: _data?.healths[currentHealth]?.DisabilityAmount,
      AutomaticRenewal:
        _data?.healths[currentHealth]?.AutomaticRenewal || false,
      MedicalDiseases:
        _data?.healths[currentHealth]?.MedicalDiseases?.map((item) => ({
          Oid: item.value,
        })) || undefined,
    };
    const clone = {};
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'undefined') clone[key] = null;
      else clone[key] = payload[key];
    });

    switch (selected) {
      case 'Economy':
        clone.MedicalInPatient = 1;
        clone.InPatientAnnualLimit = 1;
        clone.GeographicalArea = 1;
        clone.InPatientDeductible = 3;
        clone.ReimbursementMethod = 1;
        clone.InPatientCoInsurance = 1;
        clone.NewBornBaby = 0;
        clone.MedicalOutPatient = 0;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
        clone.DeathBenefit = 0;
        clone.DreadDisease = 0;
        clone.PermanentTotalOrPartialDisability = 0;
        clone.MedicalCallCenteService = 0;
        break;
      case 'Value':
        clone.NewBornBaby = 1;
        clone.MedicalInPatient = 1;
        clone.GeographicalArea = 3;
        clone.InPatientAnnualLimit = 2;
        clone.InPatientDeductible = 5;
        clone.ReimbursementMethod = 3;
        clone.InPatientCoInsurance = 1;
        clone.MedicalOutPatient = 0;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
        clone.DeathBenefit = 0;
        clone.DreadDisease = 0;
        clone.PermanentTotalOrPartialDisability = 0;
        clone.MedicalCallCenteService = 1;
        break;
      case 'Premium':
        clone.MedicalOutPatient = 1;
        clone.MedicalInPatient = 1;
        clone.TravelTransportationAndOutOfGeorgaphycalArea = 1;
        clone.DeathBenefit = 1;
        clone.DeathAmountInsured = 1;
        clone.GeographicalArea = 4;
        clone.DreadDisease = 1;
        clone.DreadSumInsured = 5;
        clone.InPatientCoInsurance = 1;
        clone.InPatientAnnualLimit = 3;
        clone.InPatientDeductible = 8;
        clone.NewBornBaby = 1;
        clone.MedicalCallCenteService = 1;
        clone.ReimbursementMethod = 4;
        break;
      default:
        break;
    }
    addPackage({
      type: 'health',
      current: currentHealth,
      selected,
      data: clone,
    });
    return clone;
  };

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createHealthPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected, currentHealth)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['policyData', selected, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    refetch();
  }, [selected, state?.packages?.health?.[currentHealth]]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (healths?.[currentHealth]?.insurance_pack) {
      setSelected(healths?.[currentHealth].insurance_pack);
    }
  }, []);

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData[selected]]);

  const premium_summary_items = [
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  const insurance_packages = [
    { value: 'Economy', label: 'Economy' },
    { value: 'Value', label: 'Value' },
    { value: 'Premium', label: 'Premium' },
  ];

  function handleCoverDetailClick(detail) {
    setShowModal(true);
    setModalContent(detail);
  }

  function receiveChanges(val) {
    const _coverData = {
      ...state?.packages?.health?.[currentHealth]?.[selected],
    };
    if (val?.toggle === false) {
      if (val?.type === 'BOOL') {
        _coverData[val?.key] = 0;
      } else if (val?.toggleOffValue !== undefined) {
        _coverData[val?.key] = val?.toggleOffValue;
      } else delete _coverData[val?.key];
    } else {
      if (val?.amount) {
        if (val?.type === 'ENUM') {
          if (val?.targetKey) {
            _coverData[val?.targetKey] =
              val?.amount?.value || val?.amount || '';
            _coverData[val?.key] = 1;
          } else {
            _coverData[val?.key] = val?.amount?.value || val?.amount || '';
          }
        } else _coverData[val?.key] = val?.amount || '';
      } else if (val?.toggleOnValue) {
        if (val?.targetKey) {
          _coverData[val?.targetKey] = val?.toggleOnValue || '';
          _coverData[val?.key] = 1;
        } else {
          _coverData[val?.key] = val?.toggleOnValue || '';
        }
      } else if (val?.toggleOnAmount) {
        _coverData[val?.key] = val?.toggleOnAmount || '';
      } else {
        if (val?.type === 'ENUM') {
          _coverData[val?.key] = val?.amount?.value || val?.amount || '';
        } else _coverData[val?.key] = val?.amount || '';
      }
    }
    if (val?.amount !== 'Included') {
      editPackage({
        type: 'health',
        current: currentHealth,
        selected,
        data: _coverData,
      });
    }
  }

  async function handleNextRoute() {
    const payload = getPayload(storage?.data, selected, currentHealth);
    payload.SavePolicy = 'true';
    const resp = await handleApiCall(payload);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        health_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['healths', currentHealth],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Health > +currentHealth + 1) {
        let nextHealth = 1 + +currentHealth;
        renderNextRoute(1, { health: nextHealth });
      } else {
        renderNextRoute(2);
      }
    }
  }

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      MultipolicyDiscount: 10,
      FiveYearsNoClaimDiscount: 5,
      TenYearsNoClaimDiscount: 10,
      FleetDiscount: 5,
      MyRideDiscount: 50,
      NewGraduateDiscount: 5,
      BrandNewCarDiscount: 10,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  return (
    <Container>
      {showModal && (
        <>
          {modalContent && (
            <MobileCoverDetail
              details={modalContent}
              data={state?.packages?.health?.[currentHealth]?.[selected]}
              sendChanges={receiveChanges}
              isLoading={isLoading}
              selected={selected}
              policyData={policyData}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
      <>
        {isFetching && policyData[selected]?.Data?.TotalwithFees && (
          <UpdatingContainer>
            <SmallLoader color="" />
          </UpdatingContainer>
        )}
        <TopContainer>
          <SearchSelectInput
            initial={insurance_packages[1]}
            value={
              selected != null
                ? { label: selected, value: selected }
                : insurance_packages[1]
            }
            options={insurance_packages}
            onSelected={(val) => setSelected(val.value)}
          />
          <TotalPremium>
            <p>{i18n.t('Your total premium')}</p>
            {policyData[selected]?.Data?.TotalwithFees ? (
              <span>{policyData[selected]?.Data?.TotalwithFees} &euro;</span>
            ) : (
              isFetching && <SmallLoader color="" />
            )}
          </TotalPremium>
        </TopContainer>
        <BottomContainer>
          <div>
            <Title>{i18n.t('Premium summary')}</Title>
            <PremiumItems>
              {premium_summary_items.map((item) => {
                return (
                  <div>
                    <span>{item.value}</span>
                    <p>{item.amount}</p>
                  </div>
                );
              })}
            </PremiumItems>
          </div>
          <EnabledDiscountsAccordion open={open} onClick={() => setOpen(!open)}>
            <span>
              <span>{i18n.t('Enabled discounts')}</span>

              <p open={open}>
                <DiscountCount>
                  <div>
                    <PieProgressIndicator
                      percentage={
                        discounts.length === 0
                          ? 0
                          : (discounts?.filter(
                              (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                            )?.length /
                              discounts?.length) *
                            100
                      }
                    />
                  </div>
                  <p>
                    {
                      discounts?.filter(
                        (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                      )?.length
                    }
                    /{discounts?.length}
                  </p>
                </DiscountCount>
                <StyledDropDownIcon open={open}>
                  <IconActionDropDown color="white" />
                </StyledDropDownIcon>
              </p>
            </span>
            <div className="accordion-content">
              <AccordionInfo>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] !== '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{i18n.t(discount[0])}</p>
                      <p className="discount-price">{discount[1]} &euro;</p>
                    </div>
                  ))}
                <Header>Possible discounts</Header>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] === '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{i18n.t(discount[0])}</p>
                      <p className="discount-price">
                        -{getDiscountPrice(discount[0])} &euro;
                      </p>
                    </div>
                  ))}
              </AccordionInfo>
            </div>
          </EnabledDiscountsAccordion>
          <CoverDetailAccordion open={openDiscounts}>
            <span onClick={() => setOpenDiscounts(!openDiscounts)}>
              <span>{i18n.t('Cover detail')}</span>
              <p open={openDiscounts}>
                {openDiscounts ? i18n.t('See less') : i18n.t('See more')}
                <StyledDropDownIcon open={openDiscounts}>
                  <IconActionDropDown color="white" />
                </StyledDropDownIcon>
              </p>
            </span>
            <StyledAlert icon>
              {i18n.t(
                'Please complete the missing information highlighted by the exclamation mark'
              )}
            </StyledAlert>
            <div className="accordion-content">
              {getCoverDetailsHealth(
                selected,
                state?.packages?.health?.[currentHealth]?.[selected]
              )?.map((details) => (
                <div key={`${selected}_${details?.key}`}>
                  <CoverDetailsContent
                    click={details?.toggle != null}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCoverDetailClick(details);
                    }}
                  >
                    <div>
                      <span>
                        {details?.toggle === true ? (
                          <IconStatusCheck />
                        ) : details?.toggle == null ? (
                          <IconStatusCheck />
                        ) : details?.toggle === false ? (
                          <StyledIconActionClose />
                        ) : null}
                      </span>
                      <p>
                        {details.value}{' '}
                        {details?.toggle != null && (
                          <Tooltip toggleIcon="!" display="none" />
                        )}
                      </p>
                    </div>
                  </CoverDetailsContent>
                </div>
              ))}
            </div>
          </CoverDetailAccordion>
        </BottomContainer>
        <ContinueContainer>
          <ContinueActionButton onClick={() => handleNextRoute()}>
            {i18n.t('Continue')}
          </ContinueActionButton>
        </ContinueContainer>
      </>
    </Container>
  );
};

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0.8rem;
  & > p {
    margin-right: 0.8rem;
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin: 2rem 0 1.5rem 1rem;
`;

const AccordionInfo = styled.div`
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2rem;
  }

  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }
    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const StyledAlert = styled(Alert)`
  align-self: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  > div {
    color: ${({ theme }) => theme.brand.primary};
    border-color: ${({ theme }) => theme.brand.primary};
  }
  margin-bottom: 2.4rem;
`;

const EnabledDiscountsAccordion = styled.div`
  background-color: #f5f6f7;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow-y: auto;

  font-family: ${({ theme }) => theme.font};
  position: static;
  bottom: 0;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 18rem)')};
  transition: top 700ms cubic-bezier(1, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1.6rem;
  > .accordion-content {
    display: ${({ open }) => (!open ? 'none' : 'flex')};
    flex-direction: column;
    overflow-y: auto;
  }
  > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem;
    > span {
      font-size: 1.6rem;
      font-weight: 550;
    }
    > p {
      align-items: center;
      color: #8990a3;
      display: flex;
      font-size: 1.4rem;
    }
  }
`;

const StyledDropDownIcon = styled.div`
  transform: ${({ open }) => (!open ? 'rotate(180deg)' : '')};
  transition: transform 0.3s;
  background-color: ${({ theme }) => theme.brand.primary};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-left: 1.6rem;
  & > svg {
    display: block;
    width: 90%;
  }
`;

const StyledIconActionClose = styled(IconActionClose)`
  path {
    fill: red !important;
  }
`;

const CoverDetailAccordion = styled(EnabledDiscountsAccordion)`
  position: static;
  background-color: transparent;
  top: ${({ open }) => (open ? '20rem' : 'calc(100% - 12rem)')};
`;

const TotalPremium = styled.div`
  align-self: center;
  flex-direction: column;
  height: fit-content;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
  @media (max-width: 312px) {
    p {
      font-size: 1.4rem;
    }
    span {
      font-size: 1.6rem;
    }
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  div:last-child {
    border-top: 1px solid #e5e5e5;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    > p {
      font-weight: 550;
    }
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 30px;
`;

const CoverDetailsContent = styled.div`
  cursor: ${({ click }) => (click ? 'pointer' : 'unset')};
  background-color: white;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 0.34rem rgba(0, 0, 0, 0.08);
  border: 0.1rem solid #8990a1;
  border-radius: 0.5rem;
  padding: 1.2rem 0;
  margin-bottom: 0.8rem;

  svg {
    height: 2rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    width: 3rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    > p {
      align-self: center;
      color: rgba(48, 55, 61, 0.66);
      font-size: 1.6rem;
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      align-items: center;
    }
    > span {
      width: 5rem;
      > div {
        padding: 0;
      }
    }
  }
  input[type='checkbox'] {
    height: 25px;
    margin: 0;
    width: 40px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  grid-template-columns: 46% 46%;
  grid-gap: 2rem;
  height: 8rem;
  margin: 2rem;
  margin-top: 0;
  padding-bottom: 2rem;
  div:first-child {
    min-height: unset;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const BottomContainer = styled.div`
  height: auto;
  > div:first-child {
    padding: 2rem;
    padding-top: 1rem;
  }
`;

const ContinueActionButton = styled(ActionButton)`
  width: 90%;
  margin: 0 auto;
`;

const ContinueContainer = styled.div`
  padding-bottom: 2rem;
  margin-top: auto;
`;

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: flex-end;

  > div {
    width: 3rem;
    margin-right: 0.8rem;
    transform: translateY(0.7rem);
  }
  > p {
    color: black;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export default FlowHealthPremiumSummaryMobile;
