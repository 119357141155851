import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import el from './locales/gr.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const missingLanguages = {};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en,
      el,
    },
    whitelist: ['en', 'el'],
    ns: ['translations'],
    defaultNS: 'translations',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    parseMissingKeyHandler: (key) => {
      console.warn(
        `React-i18next key "${key}" not found in translation files.`
      );
      if (!missingLanguages[key]) {
        missingLanguages[key] = key;
      }
      console.log(JSON.stringify(missingLanguages));
      return key;
    },
  });

export default i18n;
