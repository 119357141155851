import React, { useMemo } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { TextInput, IconActionPlus, ActionButton } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import {
  propertyInventoryCategories,
  propertyInventoryItems,
  propertyInventoryRooms,
} from '../constants/InventoryData';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import { isObjectEmpty } from '../helpers/objectService';
import { useState } from 'react';

const InventoryForm = ({ onAdd }) => {
  const [showForm, setShowForm] = useState(false);
  const validationSchema = Yup.object().shape({
    InventoryItemName: Yup.number().required(`required`),
    InventoryCategory: Yup.number().required(`required`),
    Room: Yup.number().required(`required`),
    PriceOfItem: Yup.number().required(`required`),
  });
  const propertyInventoryItemsMapped = useMemo(
    () =>
      propertyInventoryItems.map((item) => ({
        value: item.value,
        label: item.label[i18n.language] || item.label,
      })),
    [i18n.language]
  );
  const propertyInventoryRoomsMapped = useMemo(
    () =>
      propertyInventoryRooms.map((item) => ({
        value: item.value,
        label: item.label[i18n.language] || item.label,
      })),
    [i18n.language]
  );
  const propertyInventoryCategoriesMapped = useMemo(
    () =>
      propertyInventoryCategories.map((item) => ({
        value: item.value,
        label: item.label[i18n.language] || item.label,
      })),
    [i18n.language]
  );
  const { values, errors, resetForm, handleSubmit, handleChange } = useForm({
    validationSchema,
  });
  const { t } = useTranslation();

  const onSubmit = (val) => {
    val.NumberOfSameItems = 1;
    val.SerialNumber = null;
    onAdd(val);
    resetForm();
  };
  return (
    <InputContainer>
      {!showForm ? (
        <StyledActionButton onClick={() => setShowForm(true)}>
          {t('Add item')}
        </StyledActionButton>
      ) : (
        <>
          <SearchSelectInputV2
            error={errors?.InventoryItemName}
            options={propertyInventoryItemsMapped}
            name="InventoryItemName"
            onSelected={handleChange}
            value={
              !isObjectEmpty(values)
                ? propertyInventoryItemsMapped.find(
                    ({ value }) => value === values?.InventoryItemName
                  )
                : {}
            }
          >
            {t('Item name')}
          </SearchSelectInputV2>
          <SearchSelectInputV2
            error={errors?.InventoryCategory}
            options={propertyInventoryCategoriesMapped}
            name="InventoryCategory"
            onSelected={handleChange}
            value={
              !isObjectEmpty(values)
                ? propertyInventoryCategoriesMapped.find(
                    ({ value }) => value === values?.InventoryCategory
                  )
                : {}
            }
          >
            {t('Item category')}
          </SearchSelectInputV2>
          <SearchSelectInputV2
            error={errors?.Room}
            options={propertyInventoryRoomsMapped}
            name="Room"
            onSelected={handleChange}
            value={
              !isObjectEmpty(values)
                ? propertyInventoryRoomsMapped.find(
                    ({ value }) => value === values?.Room
                  )
                : {}
            }
          >
            {t('Item located in')}
          </SearchSelectInputV2>
          <StyledTextInput
            type="number"
            min="0"
            error={errors?.PriceOfItem}
            onChange={handleChange}
            value={values?.PriceOfItem}
            name="PriceOfItem"
          >
            <p>{i18n.t('Price')}</p>
            <p className="valueType">&euro;</p>
          </StyledTextInput>
          <AddItemsToInventory
            disabled={!isObjectEmpty(errors) || isObjectEmpty(values)}
            onClick={async () => {
              await handleSubmit(onSubmit);
            }}
          >
            <IconActionPlus />
            {t('Add item to inventory')}
          </AddItemsToInventory>
          <StyledActionButton onClick={() => setShowForm(false)}>
            {t('Cancel')}
          </StyledActionButton>
        </>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: 48%;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.2rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.4rem;
    color: black;
  }
  /* removal of arrows due to input number */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledActionButton = styled(ActionButton)`
  grid-column: span 6 / auto !important;
  width: 100%;
`;

const AddItemsToInventory = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme, disabled }) =>
    disabled ? theme.ui.disabled : '#8990A3'};
  border-radius: 5px;
  font-size: 1.6rem;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border: none;
  margin-bottom: 3rem;
  grid-column: span 6 / auto !important;
  margin-top: 1rem;
  > svg {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.ui.disabled : '#8990A3'};
    }
  }
`;

InventoryForm.propTypes = {
  onAdd: func,
};

export default InventoryForm;
