import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import CurrencyInput from './CurrencyInput';

const PriceInput_V2 = ({
  name,
  error,
  value,
  onChange,
  defaultValue,
  className,
  children,
}) => {
  const [stateValue, setStateValue] = useState(value);
  useEffect(() => {
    setStateValue(value);
  }, [value]);

  return (
    <BTWContainer className={className}>
      <InputContainer>
        <CurrencyInput
          value={stateValue ? stateValue : 0}
          error={error}
          name={name}
          onChange={({ value }) => {
            setStateValue(value);
            onChange(value);
          }}
        >
          <LabelName>{i18n.t(children)}</LabelName>
        </CurrencyInput>
        <EuroSign>&euro;</EuroSign>
      </InputContainer>
    </BTWContainer>
  );
};

const LabelName = styled.p`
  color: #8990a3;
`;

const BTWContainer = styled.div`
  display: flex;
  align-items: center;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
`;
const EuroSign = styled.span`
  color: black;
  padding: 0 1.4rem;
  display: flex;
  align-items: center;
  border-left: 1px solid #e4e4e4;
  position: absolute;
  margin: 0;
  right: 0rem;
  top: 2.5rem;
  height: 3.8rem;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: 1rem;
`;

export default PriceInput_V2;
