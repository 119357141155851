export const propertyInventoryRooms = [
  { value: '18', label: { en: 'Basement', gr: 'Υπόγειο' } },
  {
    value: '14',
    label: { en: "Boy's Bedroom", gr: 'Υπνοδωμάτιο του Αγοριού' },
  },
  { value: '12', label: { en: 'Craft Room', gr: 'Δωμάτιο Χειροτεχνίας' } },
  { value: '5', label: { en: 'Dining Room', gr: 'Τραπεζαρία' } },
  { value: '7', label: { en: 'Family Room', gr: 'Οικογενειακό Δωμάτιο' } },
  { value: '19', label: { en: 'Garage', gr: 'Γκαράζ' } },
  { value: '13', label: { en: "Girl's Bedroom", gr: 'Δωμάτιο του Κοριτσιού' } },
  { value: '15', label: { en: 'Guest Room', gr: 'Ξενώνας' } },
  { value: '3', label: { en: 'Guests Bathroom', gr: 'Μπάνιο Επισκεπτών' } },
  { value: '9', label: { en: 'Home Office', gr: 'Γραφείο' } },
  { value: '6', label: { en: 'Kitchen', gr: 'Κουζίνα' } },
  { value: '10', label: { en: 'Laundry Room', gr: 'Πλυσταριό' } },
  {
    value: '17',
    label: { en: 'Library/Study Room', gr: 'Βιβλιοθήκη/Δωμάτιο Μελέτης' },
  },
  { value: '4', label: { en: 'Living Room', gr: 'Σαλόνι' } },
  { value: '2', label: { en: 'Main Bathroom', gr: 'Κυρίως Μπάνιο' } },
  { value: '8', label: { en: 'Miscellaneous', gr: 'Διάφορα' } },
  { value: '1', label: { en: 'Other Bedroom', gr: 'Άλλο Υπνοδωμάτιο' } },
  { value: '16', label: { en: 'Outdoor', gr: 'Εξωτερικοί Χώροι' } },
  { value: '11', label: { en: 'Utility Room', gr: 'Βοηθητικό Δωμάτιο' } },
];

export const propertyInventoryCategories = [
  {
    label: { en: 'Business Contents', gr: 'Περιεχόμενα Επιχείρησης' },
    value: 2,
  },
  {
    label: { en: 'High Risk Items', gr: 'Αντικείμενα Υψηλού Κινδύνου' },
    value: 3,
  },
  {
    label: { en: 'House Contents', gr: 'Περιεχόμενα Κατοικίας' },
    value: 1,
  },
  {
    label: { en: 'Specified Items', gr: 'Κατονομαζόμενα Αντικείμενα' },
    value: 4,
  },
];

export const propertyInventoryItems = [
  { value: '103', label: { en: 'Accessories', gr: 'Αξεσουάρ' } },
  { value: '86', label: { en: 'Alarm Clock', gr: 'Ξυπνητήρι' } },
  { value: '145', label: { en: 'Anklets (Ankie Bracelets)', gr: 'Ποδιές' } },
  { value: '22', label: { en: 'Art wall decor', gr: 'Διακόσμηση Τοίχων' } },
  { value: '81', label: { en: 'Bed', gr: 'Κρεβάτι' } },
  { value: '85', label: { en: 'Bed linens', gr: 'Κλινοσκεπάσματα' } },
  { value: '102', label: { en: 'Belts', gr: 'Ζώνες' } },
  { value: '113', label: { en: 'Bicycles', gr: 'Ποδήλατα' } },
  { value: '24', label: { en: 'Blankets', gr: 'Κουβέρτες' } },
  { value: '53', label: { en: 'Blender', gr: 'Μίξερ' } },
  { value: '123', label: { en: 'Boat Trailer', gr: ' Ρυμουλκό Σκάφους' } },
  {
    value: '144',
    label: { en: 'Body Piercing Jewellery', gr: 'Κοσμήματα Σώματος' },
  },
  { value: '15', label: { en: 'Books', gr: 'Βιβλία' } },
  { value: '98', label: { en: 'Boots', gr: 'Μπότες' } },
  { value: '60', label: { en: 'Bowls', gr: 'Μπολ' } },
  { value: '134', label: { en: 'Bracelet', gr: 'Βραχιόλι' } },
  { value: '142', label: { en: 'Breastplates', gr: 'Μπρελόκ' } },
  { value: '143', label: { en: 'Brooches', gr: 'Καρφίτσες' } },
  { value: '67', label: { en: 'Buffet', gr: 'Μπουφές' } },
  { value: '42', label: { en: 'Cabinets', gr: 'Ερμάρια' } },
  { value: '17', label: { en: 'Carpet', gr: 'Χαλί' } },
  {
    value: '11',
    label: { en: 'CDs DVDs Tapes records', gr: 'Δίσκοι (CDs DVDs Κασέττες)' },
  },
  { value: '2', label: { en: 'Chair', gr: 'Καρέκλα' } },
  { value: '138', label: { en: 'Championship Ring', gr: 'Μετάλιο' } },
  { value: '132', label: { en: 'Choker', gr: 'Περιδέραιο' } },
  { value: '74', label: { en: 'Cleaning Supplies', gr: 'Καθαριστικά' } },
  { value: '73', label: { en: 'Cleaning Tools', gr: 'Εργαλεία Καθαρισμού' } },
  { value: '29', label: { en: 'Clock', gr: 'Ρολόι' } },
  { value: '71', label: { en: 'Clothes dryer', gr: 'Στεγνωτήριο Ρούχων' } },
  { value: '70', label: { en: 'Clothes washer', gr: 'Πλυντήριο' } },
  { value: '51', label: { en: 'Coffee maker', gr: 'Καφετιέρα' } },
  { value: '3', label: { en: 'Coffee Table', gr: 'Τραπεζάκι Καφέ' } },
  { value: '105', label: { en: 'Collections', gr: 'Συλλογές' } },
  { value: '4', label: { en: 'Computer', gr: 'Ηλεκτρονικός Υπολογιστής' } },
  { value: '5', label: { en: 'Computer Monitor', gr: 'Οθόνη Υπολογιστή' } },
  {
    value: '7',
    label: { en: 'Computer peripherals', gr: 'Περιφερειακά Υπολογιστών' },
  },
  { value: '49', label: { en: 'Convection oven', gr: 'Φούρνος' } },
  { value: '63', label: { en: 'Cookbooks', gr: 'Βιβλία Μαγειρικής' } },
  {
    value: '126',
    label: { en: 'Cooking Equipment', gr: 'Εξοπλισμός Μαγειρικής' },
  },
  { value: '62', label: { en: 'Cooking utensils', gr: ' Μαγειρικά Σκεύη' } },
  { value: '68', label: { en: 'Crystal', gr: 'Κρύσταλλο' } },
  { value: '136', label: { en: 'Cuff Link', gr: 'Μανικετόκουμπα' } },
  { value: '108', label: { en: 'Curling Iron', gr: ' Σίδερο για Mπούκλες' } },
  { value: '20', label: { en: 'Curtains', gr: 'Κουρτίνες' } },
  { value: '57', label: { en: 'Dishes', gr: 'Πιάτα' } },
  { value: '41', label: { en: 'Dishwasher', gr: 'Πλυντήριο Πιάτων' } },
  { value: '93', label: { en: 'Dress Pants', gr: 'Παντελόνι-Φόρεμα' } },
  { value: '87', label: { en: 'Dresses', gr: 'Φορέματα' } },
  { value: '155', label: { en: 'Earrings', gr: 'Σκουλαρίκια' } },
  { value: '50', label: { en: 'Electric Grill', gr: ' Ηλεκτρική Σχάρα' } },
  { value: '107', label: { en: 'Electric Razor', gr: ' Ηλεκτρικό Ξυράφι' } },
  { value: '139', label: { en: 'Engagement Ring', gr: 'Δαχτυλίδι Αρραβώνων' } },
  {
    value: '6',
    label: { en: 'External hard drive', gr: 'Εξωτερικός Σκληρός Δίσκος' },
  },
  { value: '31', label: { en: 'Fax Machine', gr: 'Φαξ' } },
  { value: '36', label: { en: 'File Cabinets', gr: 'Αρχειοθήκη' } },
  {
    value: '18',
    label: { en: 'Fireplace Equipment', gr: ' Εξοπλισμός για Τζάκι' },
  },
  { value: '43', label: { en: 'Freezer', gr: 'Καταψύκτης' } },
  {
    value: '44',
    label: { en: 'Freezer contents', gr: 'Περιεχόμενα Καταψύκτη' },
  },
  { value: '135', label: { en: 'Friendship Bracelet', gr: 'Βραχιόλι Φιλίας' } },
  {
    value: '115',
    label: {
      en: 'Garden/yard Tools Containers Supplies',
      gr: 'Εργαλεία/Προμήθειες Κήπου',
    },
  },
  { value: '153', label: { en: 'General Machinery', gr: 'Γενικά Μηχανήματα' } },
  { value: '58', label: { en: 'Glasses', gr: 'Γυαλιά' } },
  { value: '99', label: { en: 'Gloves', gr: 'Γάντια' } },
  { value: '109', label: { en: 'Hair Dryer', gr: ' Στεγνωτήρας Μαλλιών' } },
  { value: '129', label: { en: 'Hairpin', gr: 'Φουρκέτα' } },
  { value: '100', label: { en: 'Hats', gr: 'Καπέλα' } },
  { value: '130', label: { en: 'Head Jewelery', gr: 'Κοσμήματα' } },
  {
    value: '114',
    label: { en: 'Holiday Decorations', gr: 'Διακόσμηση Διακοπών' },
  },
  { value: '76', label: { en: 'Iron', gr: 'Σίδερο' } },
  { value: '77', label: { en: 'Ironing Board', gr: 'Σιδερώστρα' } },
  { value: '124', label: { en: 'Jacuzzi', gr: 'Τζακούζι' } },
  { value: '101', label: { en: 'Jewellery', gr: 'Κοσμήματα' } },
  { value: '23', label: { en: 'Knickknacks', gr: 'Μπιχλιμπίδια' } },
  { value: '119', label: { en: 'Ladders', gr: 'Σκάλες' } },
  { value: '26', label: { en: 'Lamps', gr: 'Λάμπες' } },
  { value: '32', label: { en: 'Laptop Computer', gr: 'Φορητός Υπολογιστής' } },
  { value: '116', label: { en: 'Lawn Mower', gr: 'Μηχανή του Γκαζόν' } },
  { value: '117', label: { en: 'Leaf Blower', gr: 'Φυσητήρας Φύλλων' } },
  { value: '27', label: { en: 'Light fixtures', gr: 'Φωτιστικά' } },
  { value: '65', label: { en: 'Linens', gr: 'Λευκά είδη' } },
  { value: '82', label: { en: 'Mattress', gr: 'Στρώμα' } },
  {
    value: '147',
    label: {
      en: 'Medical Alert Jewellery',
      gr: 'Ιατρικά Κοσμήματα Ειδοποίησης',
    },
  },
  { value: '111', label: { en: 'Medicines', gr: 'Φάρμακα' } },
  { value: '151', label: { en: 'Membership Pins', gr: 'Ακίδες Μέλους' } },
  { value: '48', label: { en: 'Microwave', gr: 'Φούρνος Μικροκυμμάτων' } },
  { value: '16', label: { en: 'Mirror', gr: 'Καθρέφτης' } },
  { value: '54', label: { en: 'Mixer', gr: 'Αναμικτής' } },
  { value: '19', label: { en: 'Musical Instruments', gr: 'Μουσικά Όργανα' } },
  { value: '131', label: { en: 'Necklace', gr: 'Κολιέ' } },
  { value: '37', label: { en: 'Office Supplies', gr: ' Προμήθειες Γραφείου' } },
  {
    value: '125',
    label: { en: 'Outdoor games/ toys', gr: 'Παιχνίδια Εξωτερικού Χώρου' },
  },
  { value: '152', label: { en: 'Paintings', gr: 'Πίνακες' } },
  { value: '64', label: { en: 'Pantry items / food', gr: 'Τρόφιμα' } },
  { value: '88', label: { en: 'Pants', gr: 'Παντελόνι' } },
  { value: '127', label: { en: 'Pet Supplies', gr: 'Προμήθειες Κατοικιδίου' } },
  { value: '21', label: { en: 'Pillows', gr: 'Μαξιλάρια' } },
  { value: '59', label: { en: 'Pots and Pans', gr: 'Κατσαρόλες και Τηγάνια' } },
  {
    value: '148',
    label: { en: 'Prayer Jewellery', gr: 'Κοσμήματα Προσευχής' },
  },
  { value: '56', label: { en: 'Radio', gr: 'Ραδιόφωνο' } },
  { value: '39', label: { en: 'Refrigerator', gr: 'Ψυγείο' } },
  {
    value: '40',
    label: { en: 'Refrigerator contents', gr: 'Περιεχόμενα Ψυγείου' },
  },
  { value: '137', label: { en: 'Ring', gr: 'Δαχτυλίδι' } },
  { value: '30', label: { en: 'Scanner', gr: 'Σαρωτής' } },
  { value: '78', label: { en: 'Sewing machine', gr: 'Ραπτομηχανή' } },
  { value: '79', label: { en: 'Sewing supplies', gr: 'Είδη Ραπτικής' } },
  { value: '75', label: { en: 'Shelving units', gr: 'Ράφια' } },
  { value: '89', label: { en: 'Shirts blouses', gr: 'Πουκάμισα - μπλούζες' } },
  { value: '97', label: { en: 'Shoes', gr: 'Παπούτσια' } },
  { value: '122', label: { en: 'Shovels', gr: 'Φτυάρια' } },
  { value: '112', label: { en: 'Shower Curtain', gr: 'Κουρτίνα Μπάνιου' } },
  { value: '149', label: { en: 'Signet Rings', gr: 'Δαχτυλίδια Σήμανσης' } },
  { value: '61', label: { en: 'Silverware', gr: 'Ασημικά' } },
  { value: '91', label: { en: 'Skirts', gr: 'Φούστες' } },
  { value: '1', label: { en: 'Sofa', gr: 'Καναπές' } },
  { value: '14', label: { en: 'Sound System', gr: 'Ηχοσύστημα' } },
  { value: '118', label: { en: 'Sprinkler', gr: 'Ψεκαστήρας' } },
  { value: '9', label: { en: 'Stereo', gr: 'Στέρεο' } },
  {
    value: '10',
    label: { en: 'Stereo Equipment', gr: 'Στερεοφωνικός εξοπλισμός' },
  },
  { value: '128', label: { en: 'Stock', gr: 'Απόθεμα' } },
  { value: '47', label: { en: 'Stool', gr: 'Σκαμνί' } },
  { value: '38', label: { en: 'Stove', gr: 'Κουζίνα' } },
  { value: '90', label: { en: 'Suits', gr: 'Κοστούμια' } },
  { value: '92', label: { en: 'Sweaters', gr: 'Πουλόβερ' } },
  { value: '45', label: { en: 'Table', gr: 'Τραπέζι' } },
  { value: '33', label: { en: 'Tablet', gr: 'Tablet' } },
  { value: '69', label: { en: 'Tea / Coffee sets', gr: 'Σετ τσαγιού / καφέ' } },
  { value: '28', label: { en: 'Telephone', gr: 'Τηλέφωνο' } },
  { value: '8', label: { en: 'Television', gr: 'Τηλεόραση' } },
  { value: '96', label: { en: 'Tie', gr: 'Γραβάτα' } },
  { value: '52', label: { en: 'Toaster', gr: 'Φρυγανιέρα' } },
  { value: '110', label: { en: 'Toiletries', gr: 'Καλλυντικά' } },
  { value: '120', label: { en: 'Tool box', gr: 'Κουτί εργαλείων' } },
  { value: '121', label: { en: 'Tools', gr: 'Εργαλεία' } },
  { value: '106', label: { en: 'Towels', gr: 'Πετσέτες' } },
  { value: '104', label: { en: 'Toys', gr: 'Παιχνίδια' } },
  { value: '72', label: { en: 'Vacuum Cleaner', gr: 'Ηλεκτρική σκούπα' } },
  {
    value: '12',
    label: { en: 'Video Game Console', gr: 'Κονσόλα Ηλεκτρονικών Παιχνιδιών' },
  },
  { value: '13', label: { en: 'Video Games', gr: 'Βιντεοπαιχνίδια' } },
  { value: '34', label: { en: 'Video Recorder', gr: 'Βίντεο' } },
  { value: '154', label: { en: 'Watch', gr: 'Ρολόι Χεριού' } },
  { value: '140', label: { en: 'Wedding Ring', gr: 'Βέρα' } },
];
