export const financial_institutions = [
  {
    value: 11,
    label: 'Alpha Bank Cyprus Ltd',
  },
  {
    value: 21,
    label: 'ALTAMIRA ASSET MANAGEMENT (CYPRUS) LIMITED',
  },
  {
    value: 4,
    label: 'Ancoria Bank Limited',
  },
  {
    value: 5,
    label: 'Astrobank Limited',
  },
  {
    value: 6,
    label: 'Bank of Cyprus Public Company Ltd',
  },
  {
    value: 29,
    label: 'BBAC Bank PLC',
  },
  {
    value: 16,
    label: 'Central Bank of Cyprus',
  },
  {
    value: 7,
    label: 'Cyprus Cooperative Bank Ltd',
  },
  {
    value: 8,
    label: 'Cyprus Development Bank Public Company Limited',
  },
  {
    value: 27,
    label: 'Cyprus Electricity Authority',
  },
  {
    value: 28,
    label: 'Cyprus Telecommunication Authority (CYTA)',
  },
  {
    value: 26,
    label: 'Cyprus Tourism Organization',
  },
  {
    value: 24,
    label: 'E.F. Easy Finance Ltd',
  },
  {
    value: 22,
    label: 'Ellinas Finance Public Company',
  },
  {
    value: 12,
    label: 'Eurobank Cyprus Ltd',
  },
  {
    value: 17,
    label: 'FBME Bank Ltd',
  },
  {
    value: 2,
    label: 'Hellenic Bank Public Company Limited',
  },
  {
    value: 9,
    label: 'Housing Finance Corporation',
  },
  {
    value: 13,
    label: 'National Bank of Greece (Cyprus) Ltd',
  },
  {
    value: 10,
    label: 'RCB Bank LTD',
  },
  {
    value: 23,
    label: 'Republic of Cyprus',
  },
  {
    value: 14,
    label: 'Societe Generale Bank-Cyprus Limited',
  },
  {
    value: 30,
    label: 'TA MERI FINANCE PVT. LTD',
  },
  {
    value: 15,
    label: 'USB Bank Plc',
  },
  {
    value: 25,
    label: 'Volkswagen Bank',
  },
  {
    value: 19,
    label: 'ΚΕΔΙΠΕΣ (ΚΥΠΡΙΑΚΗ ΕΤΑΙΡΕΙΑ ΔΙΑΧΕΙΡΙΣΗΣ ΠΕΡΙΟΥΣΙΑΚΩΝ ΣΤΟΙΧΕΙΩΝ ΛΤΔ)',
  },
  {
    value: 20,
    label:
      'ΣΕΔΙΠΕΣ (ΣΥΝΕΡΓΑΤΙΚΗ ΕΤΑΙΡΕΙΑ ΔΙΑΧΕΙΡΙΣΗΣ ΠΕΡΙΟΥΣΙΑΚΩΝ ΣΤΟΙΧΕΙΩΝ ΛΤΔ)',
  },
  {
    value: 18,
    label: 'ΤΑΜΕΙΟ ΣΥΝΤΑΞΕΩΝ ΚΑΙ ΧΟΡΗΓΗΜΑΤΩΝ ΑΗΚ',
  },
];
