import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import { useHistory } from 'react-router-dom';
import {
  AccordionBox,
  ActionButton,
  IconActionChevronLeft,
  LoadingSpinner,
  SmallLoader,
  IconCertificateFilled,
  PieProgressIndicator,
} from 'wg-fe-ui';
import CoverDetail from '../components/CoverDetail';
import { createCarPolicyGan } from '../helpers/apiRouterService';

import CarEcoGan from '../assets/icons/CarEcoGan';
import CarPremGanIcon from '../assets/icons/CarPremGanIcon';
import CarBasicGanIcon from '../assets/icons/CarBasicGanIcon';
import CarValueGanIcon from '../assets/icons/CarValueGanIcon';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getCoverDetails } from '../helpers/CoverDetailsService.js';
import { handlePolicyStartDate } from '../helpers/dateService.js';

let firstRender = true;

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowCarPremiumSummary = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { renderNextRoute } = useRouting();
  const [isAdding, setIsAdding] = useState(false);
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = storage?.data;
  const [cachedPayload] = useState(
    cars?.[currentVehicle]?.car_package_cover_details
  );
  const [coverData, setCoverData] = useState(storage?.data);
  const [cachedData, setCachedData] = useState({});
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [areDiscountsOpen, setAreDiscountsOpen] = useState(false);

  const getPayload = (_data, _selected, currentVehicle) => {
    let payload = {
      SavePolicy: _data?.SavePolicy || 'False',
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      VehicleOrVessel: 0,
      Vehicle: {
        Oid: _data.cars?.[currentVehicle]?.vehicle_info?.Oid,
      },
      MotorStatus: 1,
      ExistingInsuranceCompany: {
        InsuranceCompany: _data?.cars?.[currentVehicle]?.insurance_company_name,
      },
      ExpiringDateOfYourCurrentPolicy: _data?.cars?.[currentVehicle]
        ?.insurance_company_name
        ? _data?.cars?.[currentVehicle]?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.cars[currentVehicle]
        ?.insurance_company_name
        ? _data?.cars[currentVehicle]?.insurance_price || null
        : null,
      PolicyHolderAsDriver: _data.cars?.[currentVehicle]?.main_driver
        ?.same_as_policy
        ? 1
        : 0,
      MotorDrivers: [
        {
          Oid: _data.cars[currentVehicle].main_driver?.same_as_policy
            ? _data?.ganCustomer?.Oid
            : _data?.cars?.[currentVehicle]?.main_driver?.Oid,
        },
        ..._data?.cars?.[currentVehicle]?.extra_drivers?.map((item) => ({
          Oid: item?.Oid,
        })),
      ],
      EuroCompPackageBenefit: _data?.EuroCompPackageBenefit?.value || '',
      LossOfUseBenefit: _data?.LossOfUseBenefit?.value || '',
      GuranteeAssetProtectionBenefit:
        _data?.GuranteeAssetProtectionBenefit?.value || '',
      ClaimProtectionBenefit: _data?.ClaimProtectionBenefit || '',
      RiotsStrikesBenefit: _data?.RiotsStrikesBenefit?.value || '',
      ConvulsionOfNatureBenefit: _data?.ConvulsionOfNatureBenefit?.value || '',
      PersonalEffectsBenefit: _data?.PersonalEffectsBenefit || '',
      MedicalExpensesBenefit: _data?.MedicalExpensesBenefit?.value || '',
      StayMobile: _data.cars?.[currentVehicle]?.replacement_vehicle ? 1 : 0,
      DrivingOtherCarsBenefit: _data?.DrivingOtherCarsBenefit || '',
      UnlimitedWindscreenBenefit: _data?.UnlimitedWindscreenBenefit || '',
      ExcessProtectionBenefit: _data?.ExcessProtectionBenefit?.value || '',
      Trailer: _data?.Trailer?.value || 0,
      TrailerChassisNumber: '',
      Relay: _data?.Relay || 0,
      PolicyStartDate: handlePolicyStartDate(
        _data.cars[currentVehicle]?.policy_details?.starting_date
      ),
      PolicyEndDate: new Date(
        _data.cars[currentVehicle]?.policy_details?.ending_date
      ).toISOString(),
      PolicyDuration:
        _data.cars[currentVehicle]?.policy_details?.insurance_duration,
      VehicleCoverType: 2,
      AutomaticRenewal: _data?.AutomaticRenewal || false,
      VehicleExcess: 250,
      InsuredAmount: parseInt(
        _data.cars[currentVehicle]?.vehicle_info?.invoice_value
      ),
    };
    if (firstRender && cachedPayload) payload = cachedPayload;
    if (_data?.StayMobile) payload.StayMobile = 1;
    else if (_data?.cars?.[currentVehicle] && firstRender)
      payload.StayMobile = _data?.cars?.[currentVehicle].replacement_vehicle
        ? 1
        : 0;
    else payload.StayMobile = 0;

    switch (_selected) {
      case 'Basic Plus':
        payload.VehicleCoverType = 0;
        break;
      case 'Economic':
        payload.VehicleCoverType = 1;
        break;
      case 'Value':
        payload.VehicleCoverType = 2;
        break;
      case 'Premium':
        payload.VehicleCoverType = 2;
        payload.EuroCompPackageBenefit = 1;
        break;
      default:
        payload.VehicleCoverType = 9;
        break;
    }
    firstRender = false;
    return payload;
  };

  useEffect(() => {
    firstRender = true;
  }, []);

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createCarPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(coverData, selected, currentVehicle, storage)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['policyData', selected, coverData],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    if (coverData !== storage?.data && policyData[selected]) {
      setCachedData({ ...cachedData, [selected]: { ...coverData } });
      refetch();
    }
  }, [coverData]);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (cachedData[selected]) setCoverData(cachedData[selected]);
    else setCoverData(storage?.data);
  }, [selected]);

  useEffect(() => {
    if (cars?.[currentVehicle]?.insurance_pack) {
      setSelected(cars?.[currentVehicle].insurance_pack);
    }
  }, []);

  function iconType(icon) {
    switch (icon) {
      case 'Basic Plus': //terraced
        return <CarBasicGanIcon />;
      case 'Economic': //semi-detached
        return <CarEcoGan />;
      case 'Value':
        return <CarValueGanIcon />;
      case 'Premium':
        return <CarPremGanIcon />;
      default:
        return <CarValueGanIcon />;
    }
  }

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      MultipolicyDiscount: 10,
      FiveYearsNoClaimDiscount: 5,
      TenYearsNoClaimDiscount: 10,
      FleetDiscount: 5,
      MyRideDiscount: 50,
      NewGraduateDiscount: 5,
      BrandNewCarDiscount: 10,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  const getPackageColor = (insuranceType) => {
    switch (insuranceType) {
      case 'Basic Plus': //terraced
        return '#98989B';
      case 'Economic': //semi-detached
        return '#00A3E4';
      case 'Value':
        return '#024284';
      case 'Premium':
        return '#C29B67';
      default:
        return;
    }
  };

  const packages = [
    {
      value: 'Basic Plus',
    },
    {
      value: 'Economic',
    },
    {
      value: 'Value',
    },
    {
      value: 'Premium',
    },
  ];

  const premium_summary_items = [
    {
      value: 'MIF',
      amount: parseFloat(
        policyData[selected]?.Data?.MIF?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  function receiveChanges(val) {
    const _coverData = { ...coverData };
    if (!val?.toggle) {
      delete _coverData[val?.key];
      return setCoverData(_coverData);
    }
    if (val?.toggleOnValue) {
      _coverData[val?.key] = val?.toggleOnValue || '';
      setCoverData(_coverData);
    } else if (val?.toggleOnAmount) {
      _coverData[val?.key] = val?.toggleOnAmount || '';
      setCoverData(_coverData);
    } else if (val.amount) {
      _coverData[val?.key] = val?.amount || '';
      setCoverData(_coverData);
    } else {
      _coverData[val?.key] = val?.amount || '';
    }
  }

  async function handleNextRoute() {
    setIsAdding(true);
    const payload = getPayload(coverData, selected, currentVehicle);
    payload.SavePolicy = 'true';
    const resp = await handleApiCall(payload);
    setIsAdding(false);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        car_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['cars', currentVehicle],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Car > +currentVehicle + 1) {
        let nextVehicle = 1 + +currentVehicle;
        renderNextRoute(1, { vehicle: nextVehicle });
      } else if (storage?.data?.insurances?.Health > 0) {
        renderNextRoute(2, { health: 0 });
      } else {
        renderNextRoute(3);
      }
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData[selected]]);

  return (
    <Container>
      <TopContainer>
        <BackButton type="button" name="back" onClick={goBack}>
          <IconActionChevronLeft />
          {t('Back')}
        </BackButton>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected === insurance.value}
                borderColor={getPackageColor(insurance.value)}
                onClick={() => setSelected(insurance.value)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
      </TopContainer>
      <BottomContainer>
        <LeftContainer>
          <CoverDetailsTable>
            <Headers>
              <p>{t('Cover details')}</p>
              <p>{t('Cover limit')}</p>
              <p>{t('Premium')}</p>
            </Headers>
            {isLoading && !policyData[selected] ? (
              <LoadingBox>
                <LoadingSpinner className="spinner" />
              </LoadingBox>
            ) : policyData[selected] ? (
              <Scroll>
                <Content>
                  {data?.Error && (
                    <ErrorBoxLine>
                      <strong>{t('Error! ')}</strong>
                      {data?.Description}
                    </ErrorBoxLine>
                  )}
                  {selected === 'Economic'
                    ? getCoverDetails('Economic', cachedData).map((details) => {
                        return (
                          <CoverDetail
                            prices={data?.Data}
                            details={details}
                            sendChanges={receiveChanges}
                          />
                        );
                      })
                    : selected === 'Basic Plus'
                    ? getCoverDetails('Basic Plus', cachedData).map(
                        (details) => {
                          return (
                            <CoverDetail
                              prices={data?.Data}
                              details={details}
                              sendChanges={receiveChanges}
                            />
                          );
                        }
                      )
                    : selected === 'Value'
                    ? getCoverDetails('Value', cachedData).map((details) => {
                        return (
                          <CoverDetail
                            prices={data?.Data}
                            details={details}
                            sendChanges={receiveChanges}
                          />
                        );
                      })
                    : selected === 'Premium'
                    ? getCoverDetails('Premium', cachedData).map((details) => {
                        return (
                          <CoverDetail
                            prices={data?.Data}
                            details={details}
                            sendChanges={receiveChanges}
                          />
                        );
                      })
                    : null}
                </Content>
              </Scroll>
            ) : null}
          </CoverDetailsTable>
        </LeftContainer>
        <RightContainer>
          {!areDiscountsOpen && (
            <div>
              <Title>{t('Premium summary')}</Title>
              <PremiumItems>
                {premium_summary_items.map((item) => {
                  return (
                    <div>
                      <span>{item.value}</span>
                      <p>{item.amount}</p>
                    </div>
                  );
                })}
              </PremiumItems>
            </div>
          )}

          <StyledAccordionBox
            isOpen={areDiscountsOpen}
            onOpen={(open) => setAreDiscountsOpen(open)}
          >
            <AccordionBox.Header>
              <AccordionHeader>
                <p>{t('Enabled discounts')}</p>
                <DiscountCount>
                  <PieProgressIndicator
                    percentage={
                      discounts.length === 0
                        ? 0
                        : (discounts?.filter(
                            (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                          )?.length /
                            discounts?.length) *
                          100
                    }
                  />
                  <p>
                    {
                      discounts?.filter(
                        (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                      )?.length
                    }
                    /{discounts?.length}
                  </p>
                </DiscountCount>
              </AccordionHeader>
            </AccordionBox.Header>
            <AccordionBox.Content>
              <AccordionInfo>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] !== '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{t(discount[0])}</p>
                      <p className="discount-price">{discount[1]} &euro;</p>
                    </div>
                  ))}
                <Header>Possible discounts</Header>
                {discounts
                  ?.filter(
                    (discount) =>
                      discount[1] === '0,00' && discount[0] !== 'Discount'
                  )
                  ?.map((discount) => (
                    <div className="discount-row">
                      <IconCertificateFilled color="#8990A3" />
                      <p className="discount-label">{t(discount[0])}</p>
                      <p className="discount-price">
                        -{getDiscountPrice(discount[0])} &euro;
                      </p>
                    </div>
                  ))}
              </AccordionInfo>
            </AccordionBox.Content>
          </StyledAccordionBox>
          {isFetching && policyData[selected] && (
            <UpdatingContainer>
              <p>Updating price</p> <SmallLoader color="" />
            </UpdatingContainer>
          )}
          <ButtonContainer>
            <Total>
              <p>{t('Your total premium')}</p>
              <span>&euro; {policyData[selected]?.Data?.TotalwithFees}</span>
            </Total>
            <ActionButton
              disabled={data?.Error || isLoading || isAdding}
              onClick={() => handleNextRoute()}
            >
              {t('Add to basket')}{' '}
              {isAdding && (
                <SmallLoader style={{ marginLeft: '20px' }} color="" />
              )}
            </ActionButton>
          </ButtonContainer>
        </RightContainer>
      </BottomContainer>
    </Container>
  );
};

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
`;

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  & > p {
    margin-right: 0.8rem;
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin-bottom: 1.5rem;
  margin-top: 2.4rem;
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const AccordionInfo = styled(Scroll)`
  margin-top: -5.5rem;
  overflow-y: auto;
  max-height: 33vh;
  width: 100%;
  padding-right: 1.6rem;
  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }

    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1.8rem;
  border-top: 0.1rem solid #e4e4e4;

  & button {
    font-weight: bold;
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  min-width: 8rem;
  height: 4rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6.4rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 8rem;
    height: 2rem;
    justify-content: center;
    > div {
      margin-right: -3rem;
    }
  }
  p {
    font-weight: 550;
  }
`;

const StyledAccordionBox = styled(AccordionBox)`
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;
  margin-top: ${({ isOpen }) => (!isOpen ? 'auto' : '0')};
`;

const Total = styled.div`
  align-self: center;
  margin-bottom: 1rem;
  width: 100%;
  margin-top: 1rem;
  > p {
    font-size: 1.4rem;
    color: #8990a3;
    margin-bottom: 0.5rem;
  }
  > span {
    font-weight: 550;
    font-size: 2.1rem;
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    padding: 0.4rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
`;

const Headers = styled.div`
  border-bottom: 1px solid #e5e5e5;
  color: #8990a3;
  display: grid;
  font-weight: 550;
  grid-template-columns: 55% 25% 20%;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  p:first-child {
    padding-left: 6rem;
  }
`;

const Content = styled.div`
  padding-right: 1.5rem;

  > div {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    padding: 0.8rem 0;
  }
`;

const CoverDetailsTable = styled.div`
  height: 100%;
  margin: 0;
`;

const LeftContainer = styled.div`
  height: 100%;
  margin: 0;
  overflow: hidden;
`;

const RightContainer = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
  padding: 1.5rem;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected }) => (selected ? `0.2rem solid` : '1px solid')};
  border-color: ${({ selected, borderColor }) =>
    selected ? borderColor : '#e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 0.5rem;
  color: ${({ selected, borderColor }) => (selected ? borderColor : '#cfcece')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  > p {
    font-size: 1.75rem;
    text-align: center;
    width: 100%;
  }
  span > svg {
    height: 4rem;
    width: 3rem;
  }
  @media (max-width: 1000px) {
    padding: 0 1rem;
    p {
      font-size: 1.5rem;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 15% 85%;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    grid-template-rows: 18% 82%;
  }
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ErrorBoxLine = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 5px;

  strong {
    font-weight: bold;
  }
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 2rem;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-rows: 45% 55%;
    grid-template-columns: unset;
  }
`;

export default FlowCarPremiumSummary;
