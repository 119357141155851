import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import HelEcoGanIcon from '../assets/icons/HelEcoGanIcon';
import HelPremiumGanIcon from '../assets/icons/HelPremiumGanIcon';
import HelValueGanIcon from '../assets/icons/HelValueGanIcon';

import {
  ActionButton,
  LoadingSpinner,
  IconActionChevronLeft,
  IconStatusCheck,
} from 'wg-fe-ui';
import { useParams, useHistory } from 'react-router-dom';
import { createHealthPolicyGan } from '../helpers/apiRouterService';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useQuery } from 'react-query';
import { add, formatISO, isToday } from 'date-fns';

const getBoolean = (value) => {
  if (value === 'yes') return 1;
  return 0;
};

const getPayload = (_data, selected, currentHealth) => {
  const payload = {
    SavePolicy: _data?.SavePolicy || 'False',
    Customer: {
      Oid: _data?.ganCustomer?.Oid,
    },
    MedicalStatus: 1,
    DoYouSmoke: getBoolean(_data?.healths[currentHealth]?.DoYouSmoke),
    ExistingInsuranceCompany: {
      InsuranceCompany: _data?.healths?.[currentHealth]?.insurance_company_name,
    },
    ExpiringDateOfYourCurrentPolicy: _data?.healths?.[currentHealth]
      ?.insurance_company_name
      ? _data?.healths?.[currentHealth]?.start_of_contract || null
      : null,
    ExistingInsuranceCompanyPrice: _data?.healths?.[currentHealth]
      ?.insurance_company_name
      ? _data?.healths?.[currentHealth]?.insurance_price || null
      : null,
    NumberOfCigarettesPerDay:
      _data?.healths[currentHealth]?.NumberOfCigarettesPerDay,
    AnyChronicOrLongTermMedicalCondition: getBoolean(
      _data?.healths[currentHealth]?.AnyChronicOrLongTermMedicalCondition
    ),
    /* --------- */

    // Temporary workaround if starting date is today, get current hour/min/sec so BE validates it as today
    PolicyStartDate: isToday(
      new Date(_data.healths[currentHealth]?.policy_details?.starting_date)
    )
      ? formatISO(add(new Date(), { minutes: 1 })) // Increased by 1min to compensate for request delay
      : _data.healths[currentHealth]?.policy_details?.starting_date,

    /* --------- */
    PolicyEndDate: new Date(
      _data.healths[currentHealth]?.policy_details?.ending_date
    ).toISOString(),
    policyDuration:
      _data.healths[currentHealth]?.policy_details?.insurance_duration,
    WeightInKg: _data?.healths[currentHealth]?.WeightInKg,
    HeightInCM: _data?.healths[currentHealth]?.HeightInCM,
    CurrentlyDisabledPregnantOrUnableToPerformNormalActivities: getBoolean(
      _data?.healths[currentHealth]
        ?.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
    ),
    EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth: getBoolean(
      _data?.healths[currentHealth]
        ?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
    ),
    AnyDiseasesOfTheCirculatorySystemHeart: getBoolean(
      _data?.healths[currentHealth]?.AnyDiseasesOfTheCirculatorySystemHeart
    ),
    SpecifyTheDisease: _data?.healths[currentHealth]?.SpecifyTheDisease,
    DateOfRecentBloodPressureReading:
      _data?.healths[currentHealth]?.DateOfRecentBloodPressureReading,
    BloodPresureResult: _data?.healths[currentHealth]?.BloodPresureResult,
    MedicationTaken: _data?.healths[currentHealth]?.MedicationTaken,
    NutritionalAndMetabolicDiseases: getBoolean(
      _data?.healths[currentHealth]?.NutritionalAndMetabolicDiseases
    ),
    DiabetesDisease: _data?.healths[currentHealth]?.DiabetesDisease,
    DiabetesDiagnosed: _data?.healths[currentHealth]?.DiabetesDiagnosed,
    ControlledByDiet: getBoolean(
      _data?.healths[currentHealth]?.ControlledByDiet
    ),
    DiabetesMedicationTaken:
      _data?.healths[currentHealth]?.DiabetesMedicationTaken,
    RecentHba1cTest: _data?.healths[currentHealth]?.RecentHba1cTest,
    ResultHba1c: _data?.healths[currentHealth]?.ResultHba1c,
    HowManyMonthsOfAYearDoYouLiveAbroad:
      _data?.healths[currentHealth]?.HowManyMonthsOfAYearDoYouLiveAbroad,
    AreBothOYourParentsSmokers: getBoolean(
      _data?.healths[currentHealth]?.AreBothOYourParentsSmokers
    ),
    NoOfCigarettesParentsSmoke:
      _data?.healths[currentHealth]?.NoOfCigarettesParentsSmoke,
    ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus: getBoolean(
      getBoolean(
        _data?.healths[currentHealth]
          ?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
      )
    ),

    YoungestParentAge: _data?.healths[currentHealth]?.YoungestParentAge
      ? parseInt(_data?.healths[currentHealth]?.YoungestParentAge)
      : null,
    Doctor: _data?.healths[currentHealth]?.Doctor,
    LastDateSeenByTheDoctor:
      _data?.healths[currentHealth]?.LastDateSeenByTheDoctor,
    ReasonOfLastVisit: _data?.healths[currentHealth]?.ReasonOfLastVisit,
    CanYouProvideMedicalCertificate: getBoolean(
      _data?.healths[currentHealth]?.CanYouProvideMedicalCertificate
    ),
    CurrentMediclaPolicyFromDate:
      _data?.healths[currentHealth]?.CurrentMediclaPolicyFromDate,
    CurrentMediclaPolicyToDate:
      _data?.healths[currentHealth]?.CurrentMediclaPolicyToDate,
    InsuranceScheduleFromPreviousInsuranceCompany: null,
    MedicalInPatient: getBoolean(
      _data?.healths[currentHealth]?.MedicalInPatient
    ),
    InPatientAnnualLimit: _data?.healths[currentHealth]?.InPatientAnnualLimit,
    InPatientDeductible: _data?.healths[currentHealth]?.InPatientDeductible,
    InPatientCoInsurance: _data?.healths[currentHealth]?.InPatientCoInsurance,
    NewBornBaby: getBoolean(_data?.healths[currentHealth]?.NewBornBaby),
    MedicalOutPatient: 0,
    GeographicalArea: _data?.healths[currentHealth]?.GeographicalArea,
    ReimbursementMethod:
      _data?.healths[currentHealth]?.ReimbursementMethod || 1,
    MedicalCallCenteService: getBoolean(
      _data?.healths[currentHealth]?.MedicalCallCenteService
    ),
    TravelTransportationAndOutOfGeorgaphycalArea: 0,
    DeathBenefit: getBoolean(_data?.healths[currentHealth]?.DeathBenefit),
    DeathAmountInsured: _data?.healths[currentHealth]?.DeathAmountInsured,
    MortgageFinancialInstitution:
      _data?.healths[currentHealth]?.MortgageFinancialInstitution,
    DreadDisease: _data?.healths[currentHealth]?.DreadDisease,
    DreadSumInsured: _data?.healths[currentHealth]?.DreadSumInsured,
    PermanentTotalOrPartialDisability: getBoolean(
      _data?.healths[currentHealth]?.PermanentTotalOrPartialDisability
    ),
    DisabilityAmount: _data?.healths[currentHealth]?.DisabilityAmount,
    MedicalDiseases:
      _data?.healths[currentHealth]?.MedicalDiseases?.map((item) => ({
        Oid: item.value,
      })) || undefined,
  };
  const clone = {};
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] === 'undefined') clone[key] = null;
    else clone[key] = payload[key];
  });

  switch (selected) {
    case 'Economy':
      clone.MedicalInPatient = 1;
      clone.InPatientAnnualLimit = 1;
      clone.GeographicalArea = 1;
      clone.InPatientDeductible = 3;
      clone.ReimbursementMethod = 1;
      clone.InPatientCoInsurance = 1;
      clone.NewBornBaby = 0;
      clone.MedicalOutPatient = 0;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
      clone.DeathBenefit = 0;
      clone.DreadDisease = 0;
      clone.PermanentTotalOrPartialDisability = 0;
      clone.MedicalCallCenteService = 0;
      break;
    case 'Value':
      clone.NewBornBaby = 1;
      clone.MedicalInPatient = 1;
      clone.GeographicalArea = 3;
      clone.InPatientAnnualLimit = 2;
      clone.ReimbursementMethod = 3;
      clone.InPatientDeductible = 5;
      clone.InPatientCoInsurance = 1;
      clone.MedicalOutPatient = 0;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 0;
      clone.DeathBenefit = 0;
      clone.DreadDisease = 0;
      clone.PermanentTotalOrPartialDisability = 0;
      clone.MedicalCallCenteService = 1;
      break;
    case 'Premium':
      clone.MedicalOutPatient = 1;
      clone.MedicalInPatient = 1;
      clone.TravelTransportationAndOutOfGeorgaphycalArea = 1;
      clone.DeathBenefit = 1;
      clone.DeathAmountInsured = 1;
      clone.GeographicalArea = 4;
      clone.DreadDisease = 1;
      clone.DreadSumInsured = 5;
      clone.InPatientCoInsurance = 1;
      clone.InPatientAnnualLimit = 3;
      clone.InPatientDeductible = 8;
      clone.NewBornBaby = 1;
      clone.MedicalCallCenteService = 1;
      clone.ReimbursementMethod = 4;
      break;
    default:
      break;
  }
  return clone;
};

const FlowHealthInsuranceOptions = () => {
  const [selected, setSelected] = useState();
  const [policyData, setPolicyData] = useState({});
  const [error, setError] = useState();
  const history = useHistory();
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentHealth } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createHealthPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentHealth)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };

  const { data, isLoading, failureCount } = useQuery(
    ['propertyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  const EconomyDetails = [
    {
      value: 'In patient & day patient treatment',
    },
  ];

  const ValueDetails = [
    {
      value: 'In patient & day patient treatment',
    },
    { value: 'New born baby benefit' },
    { value: 'Medical call center service' },
    { value: '24Hrs health helpline assistance' },
    { value: '24Hrs casuality care assistance' },
    { value: 'Second opinion' },
    { value: 'Legal protection assistance' },
  ];

  const PremiumDetails = [
    {
      value: 'In patient & day patient treatment',
    },
    { value: 'Worldwide' },
    { value: 'New born baby benefit' },
    { value: 'Medical call center service' },
    { value: 'Out patient treatment' },
    { value: 'Travel transportation and Out of Area benefit' },
    { value: 'Death benefit' },
    { value: 'Dread benefit' },
    { value: 'Permanent total disability benefit' },
    { value: 'Medical call center service' },
    { value: '24Hrs health helpline assistance' },
    { value: '24Hrs casuality care assistance' },
    { value: 'Second opinion' },
    { value: 'Legal protection assistance' },
  ];

  const packages = [
    {
      value: 'Economy',
      description: '',
      benefits: EconomyDetails,
    },
    {
      value: 'Value',
      description: '',
      benefits: ValueDetails,
    },
    {
      value: 'Premium',
      description: '',
      benefits: PremiumDetails,
    },
  ];

  useEffect(() => {
    const packageIndex = packages.findIndex(
      (pack) =>
        pack.value === storage?.data?.healths[currentHealth]?.insurance_pack
    );
    if (packageIndex > -1) setSelected(packages[packageIndex]);
  }, []);

  function iconType(icon) {
    switch (icon) {
      case 'Value': //terraced
        return <HelValueGanIcon />;
      case 'Economy': //semi-detached
        return <HelEcoGanIcon />;
      case 'Premium':
        return <HelPremiumGanIcon />;
      default:
        return <HelValueGanIcon />;
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  const submitHandler = () => {
    if (selected == null) {
      setError('Please choose an insurance package');
      return;
    } else {
      setError();
    }
    patchStorage({
      payload: {
        insurance_pack: selected?.value,
        insurance_info: policyData[selected?.value]?.Data,
      },
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { health: currentHealth });
  };

  return (
    <Container>
      <LeftContainer>
        <TitleForm>{i18n.t('Choose your package')}</TitleForm>
        <PackagesContainer>
          {packages?.map((insurance) => {
            return (
              <Extra
                selected={selected?.value === insurance.value}
                onClick={() => setSelected(insurance)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
        <Error>{error}</Error>
      </LeftContainer>
      <RightContainer>
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
        {isLoading ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : (
          selected &&
          !data?.Error && (
            <>
              <TopHalf>
                <Title>{selected.value}</Title>
                <Description>{selected.description}</Description>
              </TopHalf>

              <SubTitle>{selected.value} package includes:</SubTitle>
              <EconomicPackageDetailsContainer>
                {selected.benefits.map((detail) => {
                  return (
                    <DetailContainer>
                      <IconStatusCheck />
                      <p>{detail.value}</p>
                    </DetailContainer>
                  );
                })}
              </EconomicPackageDetailsContainer>
            </>
          )
        )}
        <ButtonContainer>
          <TotalPremium>
            <p>{i18n.t('Your total premium')}</p>
            <span>
              {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
            </span>
          </TotalPremium>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => submitHandler()}
              type="submit"
              value="Submit"
              disabled={data?.Error || !selected || isLoading}
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Specify')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Error = styled.div`
  font-size: 1.2rem;
  color: #f74040;
  margin-bottom: 1rem;
  text-align: center;
`;

const TotalPremium = styled.div`
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  width: 100%;
  align-items: center;
  p {
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 3rem;
    margin-right: 1rem;
    width: 5rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.p`
  margin-top: 3rem;

  color: #30373d;
  font-size: 1.8rem;
  font-weight: 550;
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `3px solid ${theme.brand.primary}` : '1px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: space-evenly;
  > p {
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
  }
  span > svg {
    height: 7rem;
    width: 7rem;
  }
  @media (max-width: 1000px) {
    height: 12rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  border-right: 1px solid #f5f6f7;
  padding-right: 4rem;
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;

  overflow: auto;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2.8rem;
  flex-shrink: 0;
  margin-bottom: 4rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ErrorBox = styled(LoadingBox)`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  line-height: 120%;
`;

export default FlowHealthInsuranceOptions;
