import React from 'react';

const JewelleryGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#024284" />
          <stop offset="0.81" stop-color="#004587" />
          <stop offset="0.82" stop-color="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <circle
            cx="39.43"
            cy="43.16"
            r="16.29"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <polygon
            points="39.43 24.46 35.38 18.45 39.43 15.15 43.48 18.45 39.43 24.46"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default JewelleryGanIcon;
