import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import SafetySystemItem from '../components/SafetySystemItem';
import i18n from '../i18n';
import RiskObjectExtra from '../components/RiskObjectExtra';
import _ from 'lodash';
import { ActionButton } from 'wg-fe-ui';
import FlowChatBox from '../components/FlowChatBox';
import { ChatContext } from '../contexts/ChatContext';

const FlowCarInfoExtraMobile = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [chosenExtras, setChosenExtras] = useState([]);
  const [chosenSafeties, setChosenSafeties] = useState([]);
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;

  useEffect(() => {
    const question = {
      message: i18n.t('Please provide use with some extra information'),
      slug: 'license_response_data',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    setDefaultValues(cars[currentVehicle].car_extras);
  };

  const CarTypes = [
    { value: 'SUV' },
    { value: 'Sportscar' },
    { value: 'Cabrio' },
  ];

  const SafetySystems = [
    { value: 'Automatic Emergency Braking (AEB)' },
    { value: 'Adaptive Cruise Control' },
    { value: 'Lane support' },
    { value: 'Blind Spot Monitor' },
    { value: 'Attention Assist' },
    { value: 'eCall' },
    { value: 'Automatic Parking' },
    { value: 'Parking Aid' },
  ];

  function handleExtras({ value, name }) {
    let arr = [];
    if (value) {
      arr = [...chosenExtras, name];
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    arr = _.uniq(arr);
    setChosenExtras(arr);
  }

  function handleSafetySystems({ value, name }) {
    let arr = [];
    if (value) {
      arr = [...chosenSafeties, name];
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    arr = _.uniq(arr);
    setChosenSafeties(arr);
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    const payload = {
      car_type: chosenExtras,
      safety_systems: chosenSafeties,
    };

    patchStorage({
      payload: { car_extras: payload },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const element = {
      message: i18n.t(
        'Please take these extras and safety systems into consideration'
      ),
      slug: 'car_response',
    };
    addAnswer(element);

    renderNextRoute(1, { vehicle: currentVehicle });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={formSubmitHandler}>
        <Scroll>
          <TitleForm>
            {i18n.t('Extras')}
            <span>{i18n.t('Select the right tags')}</span>
          </TitleForm>
          <ExtrasContainer>
            {CarTypes.map((car) => {
              return (
                <RiskObjectExtra
                  onChange={(val) => {
                    handleExtras(val);
                  }}
                  selected={defaultValues?.car_type}
                  value={car.value}
                />
              );
            })}
          </ExtrasContainer>
          <TitleForm>{i18n.t('Safety systems')}</TitleForm>
          {SafetySystems.map((safety) => {
            return (
              <SafetySystemItem
                onChange={(val) => {
                  handleSafetySystems(val);
                }}
                selected={defaultValues?.safety_systems}
                value={safety.value}
              />
            );
          })}
        </Scroll>
        <ButtonContainer>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {i18n.t('Next')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const ExtrasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  > div {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
  span {
    color: #aeaeae;
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 1.5rem;
  }
`;

const RightContainer = styled.form`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  overflow: hidden;
  width: 100%;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

export default FlowCarInfoExtraMobile;
