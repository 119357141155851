import React, { useState, useEffect } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import styled from 'styled-components';
import { IconChevronDown, IconActionClose } from 'wg-fe-ui';
import useBreakPoint from '../../hooks/useBreakPoint';
import { DisableBodyScroll } from '../../helpers/styledComponenstService';

interface OptionProps {
  label: string;
  value?: unknown;
}

interface Props {
  selected?: OptionProps | OptionProps[] | null;
  placeholder?: string;
  enableSearch?: boolean;
  error?: string;
  disabled?: boolean;
}

const MobileSelectInput: React.FC<Props> = ({
  children,
  selected,
  placeholder,
  enableSearch,
  error,
  disabled,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { height } = useBreakPoint();
  const menu = document.querySelector('.Select__Custom-menu');
  const drawerHandle = document.querySelector('.drawer__handle-wrapper');

  useEffect(() => {
    const element = document.getElementById('launcher');
    if (isMenuOpen) {
      // Hide support button when menu is open
      if (element) (element as HTMLElement).style.display = 'none';
      /**
       * IOS SAFARI BUG FIX
       * This disables the bottom drawer handle to prevent grabbing the handle while this menu is open.
       */
      if (menu) disableBodyScroll(menu);
    } else {
      // Show support button when menu is open
      if (element) (element as HTMLElement).style.display = 'block';
      if (drawerHandle)
        (drawerHandle as HTMLElement).style.pointerEvents = 'auto';
      clearAllBodyScrollLocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);
  return (
    <Container onClick={() => !disabled && setIsMenuOpen((prev) => !prev)}>
      <div onClick={(e) => e.preventDefault()}>
        <FakeInput
          className="Select__control"
          disabled={disabled}
          error={error}
        >
          {!Array.isArray(selected)
            ? selected?.label || (
                <span className="mobileSelect-placeholder">{placeholder}</span>
              )
            : selected.map((opt) => (
                <span
                  className="mobileSelect-multi"
                  key={`selected-${opt.value}`}
                >
                  {opt.label}
                </span>
              ))}
          <IconChevronDown color="#C1C1C1" />
        </FakeInput>
      </div>
      {isMenuOpen && (
        <>
          <DisableBodyScroll />
          <MobileContainer enableSearch={enableSearch} fullHeight={height}>
            <div style={{ position: 'relative' }}>
              {children}
              <CloseButton onClick={() => setIsMenuOpen(false)}>
                <IconActionClose color="#A4A8B2" />
              </CloseButton>
            </div>
          </MobileContainer>
        </>
      )}
    </Container>
  );
};

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  background-color: white;
  border: 1px;
  border-color: #E4E4E4};
  border-radius: 9999px;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08),
    0 0.5px 2px rgba(96, 97, 112, 0.16);
  cursor: 'pointer';
  z-index: '5';
`;

const FakeInput = styled.div<{ error?: string; disabled?: boolean }>`
  border-radius: 5px;
  height: 4.5rem;
  border: 1px solid #E2E3E6};
  border-color: ${({ error }) => error && 'red'};
  color: black;
  position: relative;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  grid-gap: 0.8rem;
  background: ${({ disabled }) => disabled && '#E2E3E6'};

  & svg {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
  }

  & .mobileSelect-placeholder {
    color: #8990A3;
  }

  & .mobileSelect-multi {
    background: #E4E4E4;
  }
`;

const MobileContainer = styled.div<{
  enableSearch?: boolean;
  fullHeight: number;
}>`
  /** Safari fix for z-index */
  transform: translate3d(0, 0, 200px);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  > div {
    width: 90vw;
    max-width: 40rem;
    max-height: ${({ fullHeight }) =>
      `calc(${fullHeight}px - 4.8rem)`}; /* iPhone support */
    border-radius: 5px;
    transition: 0.2s;

    .Select__control {
      width: calc(100% - 1.6rem);
      z-index: 2;
      position: absolute;
      background-color: #f7f8fb;
      border: none;
      margin: 0.8rem;
      display: ${({ enableSearch }) => !enableSearch && 'none'};

      height: 4.4rem;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .Select__multi-value {
      height: 3.4rem;
      display: flex;
      align-items: center;
      & .custom-option_radioIcon {
        display: none;
      }
    }

    .Select__value-container {
      width: auto;
    }

    .Select__menu {
      background: white;
      border-radius: 5px;
      top: -0.8rem;
      height: auto;
      max-height: ${({ fullHeight }) =>
        `calc(${fullHeight}px - 4.8rem)`}; /* iPhone support */
      transition: 0.2s;
      position: static;
      width: calc(100%);
      margin-top: 0;
      z-index: 10;
      -webkit-appearance: initial;
      transform: translate3d(0, 0, 0);
      padding-top: ${({ enableSearch }) =>
        enableSearch ? '6rem !important' : '0'};
    }

    .Select__menu-list,
    .Select__Custom-menu {
      max-height: ${({ fullHeight, enableSearch }) =>
        enableSearch
          ? `calc(${fullHeight}px - 5.6rem - 6rem)`
          : `calc(${fullHeight}px - 5.6rem)`};
      -webkit-overflow-scrolling: touch;
    }
  }
`;

const Container = styled.div``;

export default MobileSelectInput;
