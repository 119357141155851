import { parseIntSafely } from './intService';
import { parseIncDateToObject } from './dateService';

export const getTwoWheelerObject = (data) => {
  const bikeType =
    data.is_main_vehicle === true ? data.vehicleType : data.bikeType;
  return {
    hp: parseIntSafely(data.horsepower),
    brand: data.brand,
    model: data.series,
    version: data.model,
    category: data.category,
    cc: parseIntSafely(data.cc),
    value: parseIntSafely(data.catalogue_value),
    options_value: parseIntSafely(data.options_value),
    clothing_value: parseIntSafely(data.clothing_value),
    vehicle_type: bikeType,
    motor_type: data.motor_type,
    is_main_vehicle: data.is_main_vehicle,
    registration_first: data.currentDateObj,
    drivers: [
      {
        first_name: data.main_driver.firstName,
        last_name: data.main_driver.lastName,
        birth: parseIncDateToObject(data.main_driver.birth),
        issue_license_date: parseIncDateToObject(
          data.main_driver.issueLicenseDate
        ),
        address: {
          street: data.policy_holder_address.streetName,
          zipcode: data.policy_holder_address.postalCode,
          housenr: data.policy_holder_address.streetNumber,
          city: data.policy_holder_address.municipalityName,
          country_code: 'CY',
        },
        primary: true,
        profession: data.main_driver.currentProfession,
      },
    ],
  };
};

export const getCarObject = (data) => {
  return {
    kw: data.kw,
    motor_type: data.motorType,
    brand: data.brand,
    version: data.version ? data.version.substring(0, 34) : null,
    model: data.model,
    used_for: data.usedFor !== undefined ? data.usedFor.toLowerCase() : null,
    value: parseIntSafely(data.catalogue_value),
    options_value: parseIntSafely(data.options),
    registration_first: data.currentDateObj,
    seats: data.seats,
    previous_insurance: {
      bonus_malus: data.bonusMalus,
    },
    drivers: [
      {
        first_name: data.main_driver.firstName,
        last_name: data.main_driver.lastName,
        birth: parseIncDateToObject(data.main_driver.birth),
        issue_license_date: parseIncDateToObject(
          data.main_driver.issueLicenseDate
        ),
        primary: true,
        accident_statement: null,
        driver_license_type: data.main_driver.driverLicenseType.toUpperCase(),
        address: {
          street: data.policy_holder_address.streetName,
          zipcode: data.policy_holder_address.postalCode,
          housenr: data.policy_holder_address.streetNumber,
          city: data.policy_holder_address.municipalityName,
          country_code: 'CY',
        },
        profession: data.currentProfession,
      },
    ],
  };
};

export const getTeacherObject = (data) => {
  return {
    manipulates_heavy_machinery: data.teacher_values.teacher_type === 'HEAVY',
  };
};

export const getFamilyObject = (data) => {
  return {
    status:
      data.teacher_values.family_type === 'FAMILY'
        ? 'with_children'
        : 'single_without_children',
    birth: parseIncDateToObject(data.birth),
  };
};

export const getHomeObject = (data) => {
  const { parcel } = data.risk_address || {};
  const { main_building, annexes } = parcel || {};
  const { enquiries, room_estimation } = main_building || {};
  return {
    address: data.risk_address.address,
    holder_state: main_building.propertyType,
    attachment: main_building.house_type,
    main_building: {
      rooms: {
        living_rooms: room_estimation.living_rooms,
        kitchens: room_estimation.kitchens,
        bedrooms: room_estimation.bedrooms,
        bathrooms: room_estimation.bathrooms,
        garages: room_estimation.garages,
        other_rooms: room_estimation.other_rooms,
        storage_rooms: room_estimation.storage_rooms,
      },
      surface: main_building.surface,
      volume: main_building.volume,
      height: main_building.height,
      age: main_building.buildingAge,
      has_special_flooring: enquiries.special_flooring,
      has_special_roofing: enquiries.special_roofing,
      has_cellar: enquiries.has_basement,
      has_attic: enquiries.has_attic,
      is_cellar_unhabited: !enquiries.inhabitable_basement,
      is_attic_unhabited: !enquiries.inhabitable_attic,
      // is_under_construction: null  Question is not asked yet
      // was_recently_renovated: null  Question is not asked yet
      construction_type: enquiries.traditional_materials ? 'BRICK' : 'WOOD',
      has_elevator: enquiries.has_elevator,
      has_porch: room_estimation.porch === 1,
      distance_nearest_neighbor_in_meters: parcel.distances.nearest_neighbor,
      facade: {
        width: main_building.facade_width,
        finishing_type: enquiries.facade_natural_stone
          ? 'NATURAL_STONE'
          : 'BRICK',
        is_protected: enquiries.dwelling_protected,
      },
      has_inside_swimming_pool: enquiries.indoor_pool,
    },
    parcel_area: parcel.surface,
    // rental_price: null  Question is not asked yet
    annexes: annexes ? annexes : [],
    // has_outside_swimming_pool: null  Question is not asked yet
    has_solar_panels: parcel.properties.ha_solar_panels,
    // are_solar_panels_anchored":null  Question is not asked yet
    has_garden: parcel.has_garden,
    is_primary: enquiries.main_residence,
    occupation: enquiries.regularly_occupied
      ? 'LESS_THAN_90_CONSECUTIVE_DAYS_GONE'
      : 'MORE_THAN_90_CONSECUTIVE_DAYS_GONE', // TODO: Might switch depending on the contract chosen
    has_company_registered: enquiries.exercising_liberal_profession,
    was_flooded: enquiries.flood_last_10_years
      ? 'ONCE_OR_MORE_IN_LAST_10_YEARS'
      : 'NOT_IN_LAST_10_YEARS',
  };
};
