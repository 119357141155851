import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ActionButton, SearchSelectInput } from 'wg-fe-ui';
import useRouting from '../hooks/useRouting.js';
import i18n from '../i18n';
import { capitalize } from '../helpers/stringService';
import { patchStorage } from '../helpers/storeService.js';
import {
  retrieveAutomobileVersionsBySeries,
  retrieveAutomobileByCar,
  retrieveAutomobileBrands,
  retrieveAutomobileModelsByBrand,
} from '../helpers/apiRouterService';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const DEBOUNCE_CONFIG = {
  time: 500,
  options: {
    leading: true,
  },
};

const FlowCarManualSearchMobile = () => {
  const { t } = useTranslation();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const [vehicle, setVehicle] = useState();
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [fuel, setFuel] = useState();
  const [transmissionType, setTransmissionType] = useState();
  const [versions, setVersions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { addQuestion, addAnswer } = useContext(ChatContext);

  async function handleBrands(value) {
    const { time, options } = DEBOUNCE_CONFIG;

    const debounceBrands = debounce(retrieveAutomobileBrands, time, options);
    const [resp, status] = await debounceBrands(computeStringToQuery(value));
    const { items } = resp;

    if (status !== 200) return null;

    return items.map(({ brand }) => ({ label: brand.name, value: brand.key }));
  }

  function computeStringToQuery(value) {
    if (value) return `q=${value}`;
  }

  async function handleModels(value, _brand) {
    const { time, options } = DEBOUNCE_CONFIG;

    const debounceModels = debounce(
      retrieveAutomobileModelsByBrand,
      time,
      options
    );
    const [resp, status] = await debounceModels(
      _brand,
      computeStringToQuery(value)
    );
    if (status !== 200) return null;
    const { items } = resp;

    return items.map(({ model }) => ({
      label: model.model,
      value: {
        brandKey: model.brand_key,
        id: model.id,
        keyCategory: model.key_category,
        model: model.model,
      },
    }));
  }

  function onChange(val) {
    setVehicle(val);
  }

  useEffect(() => {
    const question = {
      message: i18n.t('Please search your car based on car make and model'),
      slug: 'car_manual_search',
    };
    addQuestion(question);
  }, []);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    setModel();
    setFuel();
    setVersions([]);
    onChange();
  }, [brand]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    setFuel();
    setVersions([]);
    onChange();
  }, [model]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    onChange();
  }, [fuel]);

  useEffect(() => {
    if (fuel && transmissionType) {
      getVersions(brand, model, fuel);
    } else {
      setVersions([]);
    }
    onChange();
  }, [transmissionType]);

  async function getVersions(_brand, _model, _fuel) {
    const [resp, status] = await retrieveAutomobileVersionsBySeries(
      _brand,
      _model.id,
      `motor_type=${_fuel}&transmission_type=${transmissionType}&pagelen=500`
    );

    if (status !== 200) return null;

    const { items } = resp;

    const computedVersions = items.map(({ version }) => ({
      id: version.id,
      kw: version.kw,
      version: version.version,
      brand: _brand,
      model: _model.model,
      modelYear: version.model_year,
    }));
    if (computedVersions.length === 0) {
      setNoResults(true);
    }
    setVersions(computedVersions.length > 0 ? computedVersions : null);
  }

  async function handleSelectedCar(_brand, modelId, brandId) {
    const [resp, status] = await retrieveAutomobileByCar(
      _brand,
      modelId,
      brandId
    );

    if (status !== 200) return;

    const { car } = resp;
    if (model?.model != null) {
      car.model = model?.model;
    }
    onChange(car);
  }

  const fuelTypes = {
    PET: t('Petrol'),
    DIE: t('Diesel'),
    LPG: t('LPG'),
    DIE_LPG: t('Hybrid (Diesel / LPG)'),
    ELE: t('Electricity'),
    ELE_PET: t('Hybrid (Electricity / Petrol)'),
    ELE_DIE: t('Hybrid (Electricity / Diesel)'),
    ELE_LPG: t('Hybrid (Electricity / LPG)'),
    ELE_HYDRO: t('Hybrid (Electricity / Hydrogen)'),
    HYDRO: t('Hydrogen'),
    CNG: t('CNG'),
    CNG_PET: t('Hybrid (CNG / Petrol)'),
    BIO: t('Bioethanol'),
    OTHER: t('Other'),
  };

  const transmissionTypes = [
    { value: 'auto', label: t('Automatic') },
    { value: 'man', label: t('Manual') },
  ];

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    if (vehicle == null) {
      setErrorMessage('Please fill in car information');
      return;
    }

    const element = {
      message: {
        car_image: vehicle?.images[1],
        car_name: vehicle?.name,
        model_year: vehicle?.model_year,
      },
      slug: 'car_response',
    };
    addAnswer(element);

    const payload = {
      brand: vehicle?.brand,
      car_value: vehicle?.price,
      cc: vehicle?.cc,
      co2: vehicle?.co2,
      category: vehicle?.category,
      febiacid: vehicle?.febiacid,
      id: vehicle?.id,
      is_cabrio: vehicle?.is_cabrio || false,
      is_jeep: vehicle?.is_jeep || false,
      is_second_hand: vehicle?.is_second_hand || false,
      is_sportscar: vehicle?.is_sportscar || false,
      kw: vehicle?.kw,
      kwh: vehicle?.kwh,
      model: vehicle?.model,
      model_year: vehicle?.model_year,
      motor_type: vehicle?.motor_type,
      name: vehicle?.name,
      transmission_type: vehicle?.transmission_type,
      version: vehicle?.version,
      seats: vehicle?.amount_of_seats_incl_driver,
    };

    patchStorage({
      payload: { vehicle_info: payload },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={formSubmitHandler}>
        <Scroll>
          <FlexWrapper>
            <SearchSelectInput
              async
              name="brand"
              placeholder={t('Choose your option')}
              loadOptions={handleBrands}
              onSelected={({ value }) => setBrand(value)}
              isClearable
            >
              <LabelName>{t('Brand')} *</LabelName>
            </SearchSelectInput>
            <SearchSelectInput
              async
              name="model"
              disabled={!brand}
              placeholder={t('Choose your option')}
              key={brand}
              loadOptions={(val) => handleModels(val, brand)}
              isClearable
              onSelected={({ value }) => setModel(value)}
            >
              <LabelName>{t('Model')} *</LabelName>
            </SearchSelectInput>
          </FlexWrapper>
          <FlexWrapper>
            <SearchSelectInput
              name="motorType"
              isClearable
              disabled={!model}
              placeholder={t('Choose your option')}
              options={Object.entries(fuelTypes).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              onSelected={({ value }) => {
                setFuel(value);
              }}
            >
              <LabelName>{t('Fuel type')} *</LabelName>
            </SearchSelectInput>
            <SearchSelectInput
              name="transmissionType"
              isClearable
              disabled={!fuel}
              placeholder={t('Choose your option')}
              key={`my_unique_select_key__${fuel}`}
              options={transmissionTypes}
              onSelected={({ value }) => {
                setTransmissionType(value);
              }}
            >
              <LabelName>{t('Transmission type')} *</LabelName>
            </SearchSelectInput>
          </FlexWrapper>
          <VersionLabel>
            <span style={{ width: '70%' }}>{t('Version')}</span>
            <span style={{ width: '10%' }}>{t('Kw')}</span>
            <span style={{ width: '20%', textAlign: 'right' }}>
              {t('Model')}
            </span>
          </VersionLabel>
          <VersionList
            disabled={versions?.length === 0 || versions === null}
            key={`${fuel} - ${model?.id}`}
          >
            {versions?.length !== 0 && versions !== null ? (
              versions.map((version) => (
                <VersionItem
                  isSelected={
                    version?.version === vehicle?.version &&
                    version?.modelYear === vehicle?.model_year
                  }
                  key={version.id}
                  onClick={() =>
                    handleSelectedCar(version.brand, model.id, version.id)
                  }
                >
                  <Version>{`${capitalize(version.brand)} - ${version.model} ${
                    version.version
                  }`}</Version>
                  <Kw>{version.kw}</Kw>
                  <ModelYear>{version.modelYear}</ModelYear>
                </VersionItem>
              ))
            ) : noResults ? (
              <NoResults>{t('No results')}</NoResults>
            ) : (
              ''
            )}
          </VersionList>
          <div>
            {errorMessage ? <Error>{errorMessage}</Error> : <p></p>}
            <VehicleNotPresent onClick={() => console.log('vehicle not found')}>
              {t('I dont see my vehicle')}
            </VehicleNotPresent>
          </div>
        </Scroll>
        <ButtonContainer>
          <Required error={errorMessage != null}>
            * {t('Required fields')}
          </Required>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {t('Next')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.form`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 20% 80%;
  overflow: hidden;
  width: 100%;
`;

const Error = styled.p`
  color: #f74040;
`;

const LabelName = styled.p`
  color: #8990a3;
`;

const VehicleNotPresent = styled.p`
  color: #224074;
  cursor: pointer;
  font-weight: bold;
  text-align: end;
  font-size: 1.6rem;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme, error }) => (error ? '#f74040' : theme.typo.label)};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
  p {
    margin-bottom: 1.5rem;
    width: 100%;
    text-align: end;
  }
`;

const NoResults = styled.div`
  font-size: 2.1rem;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const VersionLabel = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.6;
  width: 100%;
  color: #8990a3;
`;

const Version = styled.span`
  width: 70%;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const Kw = styled.span`
  width: 10%;
`;

const ModelYear = styled.span`
  width: 20%;
`;

const VersionItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme, isSelected }) => (isSelected ? 'white' : theme.typo.text)};
  width: 100%;

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : 'none'};
  cursor: pointer;
  padding: 1rem 2rem;

  & > span:last-child {
    text-align: right;
  }

  & .version {
    width: 50%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionList = styled.ul`
  background-color: ${({ disabled }) => (disabled ? 'hsl(0,0%,95%)' : 'white')};
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  height: 30rem;
  margin-bottom: 3rem;
  overflow-y: scroll;
  width: 100%;
  flex-direction: column;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
  > div:last-child {
    display: flex;
    margin-top: -1rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default FlowCarManualSearchMobile;
