import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  policy: any;
  type: string;
}

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowOverviewPremiumSection: React.FC<Props> = ({ policy, type }) => {
  const { t } = useTranslation();
  const premium_summary_items = [
    ...(type === 'cars'
      ? [
          {
            value: 'MIF',
            amount: parseFloat(
              policy?.response?.MIF?.replace(/,/g, '.') || 0
            ).toLocaleString('nl-BE', myObj),
          },
        ]
      : []),
    {
      value: 'Premium',
      amount: (
        parseFloat(policy?.response?.Total?.replace(/,/g, '.') || 0) +
          -1 *
            (parseFloat(policy?.response?.Discount?.replace(/,/g, '.')) +
              parseFloat(
                policy?.response?.LoyaltyDiscount?.replace(/,/g, '.')
              )) || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: (
        parseFloat(policy?.response?.Discount?.replace(/,/g, '.')) +
          parseFloat(policy?.response?.LoyaltyDiscount?.replace(/,/g, '.')) || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policy?.response?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policy?.response?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  return (
    <div>
      <Title>{t('Premium summary')}</Title>
      <PremiumItems>
        {premium_summary_items.map((item) => {
          return (
            <div>
              <span>{item.value}</span>
              <p>{item.amount}</p>
            </div>
          );
        })}
      </PremiumItems>
    </div>
  );
};

export default FlowOverviewPremiumSection;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    padding: 0.4rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
`;
