import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { func, string, number } from 'prop-types';
import InfoPopup from './InfoPopup';
import i18n from '../i18n';

import ToggleButton from './ToggleButton.jsx';
import DownloadButton from './DownloadButton.jsx';

const FlowPriceCalculatorOption = ({
  title,
  ipidLink,
  termsLink,
  onChange,
  id,
  mandatory,
  price,
  helperText,
  defaultValue,
  insuranceType,
  lightScooter,
  disabled,
}) => {
  const [isSelected, setIsSelected] = useState(mandatory || defaultValue);

  useEffect(() => {
    onChange(id, isSelected);
  }, [isSelected]);

  var myObj = {
    style: 'currency',
    currency: 'EUR',
  };

  return (
    <OptionWrapper>
      <ToggleButton
        className="toggle"
        disabled={disabled ? true : mandatory}
        onChange={(toggle) => setIsSelected(toggle)}
        defaultCheck={mandatory || defaultValue}
      />
      <OptionContent
        isSelected={isSelected}
        insuranceType={insuranceType}
        lightScooter={lightScooter}
      >
        <TitleAndPrice>
          <InfoPopup title={title} info={helperText} />
          <OptionTitle>
            {i18n.t(title)} {mandatory ? `(${i18n.t('mandatory')})` : null}
            {disabled ? (
              <StyledInfoPopup
                isLink={true}
                buttonText={'(disabled)'}
                title={i18n.t('Vehicle age not qualified')}
                info={i18n.t(
                  "A vehicle older than 10 years doesn't qualify for this option"
                )}
              >
                disabled
              </StyledInfoPopup>
            ) : (
              ''
            )}
          </OptionTitle>
          <OptionPrice>
            {parseFloat(price).toLocaleString('nl-BE', myObj)}/{i18n.t('y')}
          </OptionPrice>
        </TitleAndPrice>
        <DownloadContainer>
          <DownloadIpid
            insuranceType={insuranceType}
            lightScooter={lightScooter}
          >
            IPID
          </DownloadIpid>
          <DownloadTerms>{i18n.t('Terms and Conditions')}</DownloadTerms>
        </DownloadContainer>
      </OptionContent>
    </OptionWrapper>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  font-size: 1.7rem;
  :hover {
    color: #c2d989;
  }
`;

const DownloadTerms = styled(DownloadButton)`
  padding: 0;
  width: 23rem;
  margin-left: 2rem;
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 2rem;
    width: 16.5rem;
    margin-left: 0.5rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const DownloadIpid = styled(DownloadButton)`
  padding: 0;
  margin-left: ${({ insuranceType, lightScooter }) =>
    insuranceType === 'legal'
      ? 'auto'
      : lightScooter === 'lightScooter'
      ? 'auto'
      : '0'};
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 2rem;
    min-width: 7rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const TitleAndPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 4rem;
`;

const DownloadContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

const OptionContent = styled.div`
  flex-direction: ${({ insuranceType, lightScooter }) =>
    insuranceType === 'legal'
      ? 'column'
      : lightScooter === 'lightScooter'
      ? 'column'
      : 'initial'};
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  width: 100%;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.6)};
  transition-duration: 0.4s;
  transition-property: opacity;
  @media (max-width: 570px) {
    margin-left: 1rem;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  border-radius: 0.75rem;
  align-items: center;
  @media (max-width: 570px) {
    margin-bottom: 1.5rem;
  }
`;

const OptionPrice = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: auto;
  width: 27%;
  text-align: right;
`;

const OptionTitle = styled.h2`
  margin-left: 2rem;
  width: 70%;
  font-size: 1.8rem;
  word-break: break-word;
  font-weight: 700;
  color: ${({ theme }) => theme.typo.subTitle};
  @media (max-width: 570px) {
    font-size: 1.75rem;
  }
`;

FlowPriceCalculatorOption.defaultProps = {
  onChange: () => {},
  title: '',
  ipidLink: '',
  termsLink: '',
  price: 0,
  helperText: '',
};

FlowPriceCalculatorOption.propTypes = {
  onChange: func,
  title: string,
  ipidLink: string,
  termsLink: string,
  price: number,
  helperText: string,
};

export default FlowPriceCalculatorOption;
