import 'isomorphic-fetch';
import {
  GAN_API,
  PROXY,
  WG_GANDIRECT_PROXY_USERNAME,
  WG_GANDIRECT_PROXY_PASSWORD,
} from '../env.config';
import { ganAuth } from './apiRouterService';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Basic ${btoa(
    `${WG_GANDIRECT_PROXY_USERNAME}:${WG_GANDIRECT_PROXY_PASSWORD}`
  )}`,
};

export async function ganRequest(path, method, payload = null) {
  let [resp, status] = await _request(path, method, payload);
  if (status === 401) {
    await ganAuth();
    [resp, status] = await _request(path, method, payload);
  }
  return [resp, status];
}

async function _request(uri, method, payload) {
  let req;
  let tempHeaders = headers;
  // if (uri !== "api/Auth") {
  // }

  console.log(`[API REQUEST] ${method} ${uri}`);
  if (payload == null) {
    req = await fetch(PROXY, {
      method: 'POST',
      headers: tempHeaders,
      body: JSON.stringify({
        uri,
        host: GAN_API,
        method,
        token:
          uri !== 'api/Auth' ? sessionStorage.getItem('gan-token') : undefined,
      }),
    });
  } else {
    req = await fetch(PROXY, {
      method: 'POST',
      headers: tempHeaders,
      body: JSON.stringify({
        uri,
        host: GAN_API,
        method,
        payload,
        token:
          uri !== 'api/Auth' ? sessionStorage.getItem('gan-token') : undefined,
      }),
    });
  }
  console.log(`[API RESPONSE] ${method} ${uri} status: ${req.status}`);
  let resp;
  if (req.status === 200) {
    resp = await req?.json();
  }
  return [resp, req.status];
}
