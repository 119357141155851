import { createGlobalStyle } from 'styled-components';

const customMediaQuery = (minWidth: number): string =>
  `@media (min-width: ${minWidth}px)`;

export const bp = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 520,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktopS: 1920,
  desktop: 2560,
};

export const mq = {
  custom: customMediaQuery,
  mobileS: customMediaQuery(bp.mobileS),
  mobileM: customMediaQuery(bp.mobileM),
  mobileL: customMediaQuery(bp.mobileL),
  tabletS: customMediaQuery(bp.tabletS),
  tablet: customMediaQuery(bp.tablet),
  laptop: customMediaQuery(bp.laptop),
  laptopL: customMediaQuery(bp.laptopL),
  desktopS: customMediaQuery(bp.desktopS),
  desktop: customMediaQuery(bp.desktop),
};

export const chakraCustomScrollBar = {
  '&::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
  },
  '&::-webkit-scrollbar:vertical': {
    width: '12px',
  },
  '&::-webkit-scrollbar:horizontal': {
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    visibility: 'unset',
    borderRadius: '8px',
    border: '2px solid white',
    backgroundColor: '#c7c7c7',
  },
  '&::-webkit-scrollbar-track-piece': {
    background: '#f0f1f3',
    borderRadius: '5px',
    width: '8px',
    border: '2px solid white',
  },
};

export const customScrollBar = `
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    visibility: unset;
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #c7c7c7;
  }

  &::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }`;

export const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
