import React from "react";

const SvgComponent = ({ colors }) => (
  <svg viewBox="0 0 400 400" width="100%">
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors}
    />
    <circle cx={199.92} cy={204.68} fill="#fff" r={86.86} />
    <path
      d="m188.12 52.81-8.83 10.87 9.49 7.71c-68.52 5.72-122.54 63.32-122.54 133.29h16a117.92 117.92 0 0 1 107-117.27l-6.66 8.2 10.86 8.83 23.16-28.44z"
      fill="#fff"
    />
    <path
      d="m199.92 186.45a18.11 18.11 0 0 0 -6 1l-28-27.66a8 8 0 0 0 -11.25 11.38l28 27.65a18.22 18.22 0 1 0 17.26-12.41z"
      fill={colors}
    />
  </svg>
);

export default SvgComponent;
