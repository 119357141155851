import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ChatContext } from '../contexts/ChatContext';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowAverageDistanceTravelled = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const [rangeInput, setRangeInput] = useState(0);
  const history = useHistory();

  const loadDefaultValues = async () => {
    if (cars[currentVehicle].average_km_yearly != null) {
      setRangeInput(cars[currentVehicle].average_km_yearly);
    } else if (cars[currentVehicle].vehicle_info?.AnnualMileage?.ToRange) {
      setRangeInput(cars[currentVehicle].vehicle_info?.AnnualMileage?.ToRange);
    }
  };

  useEffect(() => {
    const question = {
      message: i18n.t(
        'How <span>many km/year</span> will the vehicle travel approximately?'
      ),
      slug: 'average_km_yearly',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    patchStorage({
      payload: { average_km_yearly: rangeInput },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: i18n.t(`Around`, { km: rangeInput }),
      slug: 'average_km_yearly',
    };
    addAnswer(answer);

    renderNextRoute(1, { vehicle: currentVehicle });
  };

  function handleRangeInput(e) {
    setRangeInput(e.target.value);
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={formSubmitHandler}>
        <div>
          <TitleForm>
            {i18n.t('How many km/year will the vehicle travel approximately?')}
          </TitleForm>
          <div>
            <RangeContainer>
              <p className="min">{i18n.t('5,000 km')}</p>
              <p className="max">{i18n.t('25,000 km')}</p>
            </RangeContainer>
            <MaxConsecutiveDays>
              <input
                type="range"
                min="5000"
                max="25000"
                value={rangeInput}
                onChange={(e) => handleRangeInput(e)}
                step="100"
              />
              <p>
                <span>{rangeInput} </span>
                km
              </p>
            </MaxConsecutiveDays>
          </div>
        </div>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {i18n.t('Next')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  color: ${({ theme }) => theme.brand.primary};
  flex-shrink: 0;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  span {
    color: #aeaeae;
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 1.5rem;
  }
`;

const RightContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  padding-left: 4rem;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowAverageDistanceTravelled;
