import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeBuildingInfo = () => {
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_addresses } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [businessRooms, setBusinessRooms] = useState();
  const [errors, setErrors] = useState({});
  // const [storageUnit, setStorageUnit] = useState();
  const history = useHistory();

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Are there any rooms in the building that are used for <span>business purposes</span>?'
      ),
      slug: 'building_information',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  useEffect(() => {
    if (businessRooms === true || businessRooms === false)
      setErrors({ ...errors, businessRooms: false });
  }, [businessRooms]);

  const loadDefaultValues = async () => {
    const defaultValuesTemp = {
      business_rooms_present: getNestedObject(data, [
        'risk_addresses',
        [currentProperty],
        'parcel',
        'main_building',
        'business_rooms_present',
      ]),
      // currently_renting_storage_unit: getNestedObject(data, [
      //   'risk_addresses',
      //   [currentProperty],
      //   'parcel',
      //   'main_building',
      //   'currently_renting_storage_unit',
      // ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  useEffect(() => {
    if (defaultValues?.business_rooms_present != null) {
      setBusinessRooms(defaultValues?.business_rooms_present);
    }
    // if (defaultValues?.currently_renting_storage_unit != null) {
    //   setStorageUnit(defaultValues?.currently_renting_storage_unit);
    // }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    if (businessRooms !== true && businessRooms !== false)
      setErrors({ ...errors, businessRooms: true });
    else {
      submitHandler();
    }
  };

  const submitHandler = () => {
    setLoading(true);
    const payload = {
      business_rooms_present: businessRooms,
      // currently_renting_storage_unit: storageUnit,
    };

    if (
      !risk_addresses[currentProperty].parcel.main_building
        .business_rooms_present === businessRooms ||
      risk_addresses[currentProperty].parcel.main_building
        .business_rooms_present == null
      //    ||
      // !risk_addresses[currentProperty].parcel.main_building
      //   .currently_renting_storage_unit === storageUnit ||
      // risk_addresses[currentProperty].parcel.main_building
      //   .currently_renting_storage_unit == null
    ) {
      patchStorage({
        payload: payload,
        path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
        id,
        affinity,
        insuranceType,
      });
    }

    const answer = {
      message: i18n.t(
        `There are<span>${
          businessRooms === true ? '' : ' no'
        } business rooms</span> in the building`
      ),
      slug: 'building_information',
    };

    addAnswer(answer);

    renderNextRoute(1, { property: currentProperty });
    setLoading(false);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <QuestionContainer>
              <TitleForm>
                {i18n.t(
                  'Are there any rooms in the building that are used for business purposes?'
                )}
              </TitleForm>
              <YesNoContainer>
                <StyledButton
                  active={businessRooms}
                  onClick={(e) => {
                    e.preventDefault();
                    setBusinessRooms(true);
                  }}
                >
                  {i18n.t('Yes')}
                </StyledButton>
                <StyledButton
                  active={businessRooms === false}
                  onClick={(e) => {
                    e.preventDefault();
                    setBusinessRooms(false);
                  }}
                >
                  {i18n.t('No')}
                </StyledButton>
              </YesNoContainer>
              {errors?.businessRooms && (
                <ValidationError>
                  {i18n.t('This question is required')}
                </ValidationError>
              )}
            </QuestionContainer>
            {/* <QuestionContainer>
              <TitleForm>
                {i18n.t(
                  'Do you rent a storage unit that is not located at this address?'
                )}
              </TitleForm>
              <YesNoContainer>
                <StyledButton
                  active={storageUnit}
                  onClick={(e) => {
                    e.preventDefault();
                    setStorageUnit(true);
                  }}
                >
                  {i18n.t('Yes')}
                </StyledButton>
                <StyledButton
                  active={storageUnit === false}
                  onClick={(e) => {
                    e.preventDefault();
                    setStorageUnit(false);
                  }}
                >
                  {i18n.t('No')}
                </StyledButton>
              </YesNoContainer>
            </QuestionContainer> */}
          </div>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const ValidationError = styled.p`
  color: ${({ theme }) => theme.status.error};
`;

const YesNoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 3rem 0;
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  margin-right: 2.4rem;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  max-width: 21.3rem;
  width: 48%;
  height: 5.3rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const QuestionContainer = styled.div`
  margin-bottom: 3rem;
  > div {
    padding-top: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

export default FlowHomeBuildingInfo;
