import React from 'react';
import styled from 'styled-components';
import { IconActionClose, IconPencilFilled } from 'wg-fe-ui';
import { number, string, func } from 'prop-types';

const ListItemCard = ({
  title,
  subTitle,
  price,
  className,
  onRemove,
  onEdit,
}) => {
  return (
    <Container className={className}>
      <Content>
        <p>
          {title} <br /> <span>{subTitle}</span>
        </p>
        {price && <p>&euro; {price}</p>}
      </Content>
      <ButtonContainer>
        {onEdit && (
          <EditButton className="button" onClick={onEdit}>
            <IconPencilFilled color="white" />
          </EditButton>
        )}
        {onRemove && (
          <RemoveButton className="button" onClick={onRemove}>
            <IconActionClose color="white" />
          </RemoveButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.li`
  display: grid;

  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.brand.primary};
  border-radius: 0.5rem;

  min-width: 24rem;
  max-height: 12rem;
  margin-right: 2.4rem;
  margin-bottom: 1.6rem;

  &:hover {
    & .button {
      transform: scale(1);
    }
  }

  & p {
    color: black;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 125%;
  }

  & span {
    font-weight: normal;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.brand.primary};
  }
`;

const Content = styled.div`
  grid-row: 1;
  grid-column: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1.6rem;
`;

const ButtonContainer = styled.div`
  grid-row: 1;
  grid-column: 1;

  display: flex;

  justify-self: flex-end;
  margin-top: -1.2rem;
  margin-right: -1.2rem;
`;

const Button = styled.div`
  cursor: pointer;

  margin-left: 1.5rem;
  width: 3rem;
  height: 3rem;

  border-radius: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: scale(0);
  transition: 0.04s;
`;

const RemoveButton = styled(Button)`
  background: ${({ theme }) => theme.status.error};
`;

const EditButton = styled(Button)`
  background: ${({ theme }) => theme.brand.primary};
`;

ListItemCard.propTypes = {
  title: string,
  subTitle: string,
  price: number || string,
  className: string,
  onRemove: func,
  onEdit: func,
};

export default ListItemCard;
