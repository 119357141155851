import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  TextInput,
  CheckBox_v2 as CheckBox,
  IconActionChevronLeft,
  PhoneInput,
  DateInputV4,
  ToggleInput,
  Label,
  NumbersInputWithSymbol,
  IconDisabilityFilled,
} from 'wg-fe-ui';
import { countries, occupations } from '../constants/customerData';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import * as Yup from 'yup';
import i18n from '../i18n';
import { differenceInYears, parseISO, isWithinInterval } from 'date-fns';
import { isObjectEmpty } from '../helpers/objectService';

import { titleOptions as titleOptionsConstant } from '../constants/FlowSearchSelectData';
import {
  string,
  firstName,
  lastName,
  number,
  telephonenr,
  email,
} from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useParams } from 'react-router';
import { retrieveStorageById } from '../helpers/storeService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const titleOptions = titleOptionsConstant.map(({ value, label }) => {
  return { value, label: i18n.t(label) };
});
const occupations_lowerCase = occupations.map((o) => {
  o.label = o.label.toLowerCase();
  return o;
});

const oidObjects = [
  'DrivingLicenseIssueCountry',
  'ReasonForDrivingPoints',
  'AnyLicenseRestrictions',
];

function validateDriveLicenseAge() {
  const { DrivingLicenseIssueDate, DateOfBirth } = this.parent;

  let minimumDrivingAge = 17;

  return (
    differenceInYears(
      parseISO(DrivingLicenseIssueDate),
      parseISO(DateOfBirth)
    ) >= minimumDrivingAge
  );
}

function validateResidentInCyprusSince() {
  const { DateOfBirth, ResidentInCyprusSince } = this.parent;

  if (!DateOfBirth || !ResidentInCyprusSince) return false;

  return isWithinInterval(new Date(ResidentInCyprusSince), {
    start: new Date(DateOfBirth),
    end: new Date(),
  });
}

function validateLeftSiteOfRoadDate() {
  const { DrivingLicenseIssueDate, DrivingInLeftSiteOfRoadSince } = this.parent;

  if (!DrivingLicenseIssueDate || !DrivingInLeftSiteOfRoadSince) return false;

  return isWithinInterval(new Date(DrivingInLeftSiteOfRoadSince), {
    start: new Date(DrivingLicenseIssueDate),
    end: new Date(),
  });
}

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const selectPlaceholder = i18n.t('Choose your option');
const cyprusOid = '6e0e5110-ebbf-4a71-abfc-491a2778e24f';

const FlowDriversInformationForm = ({ handleFormValues }) => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { t } = useTranslation();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [primaryDriverIsPH, setPrimaryDriverIsPH] = useState();
  const [defaultValues, setDefaultValues] = useState({});
  const SelectPlaceholder = i18n.t('Choose your option');
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: string.required.test(
      'valid-birth',
      i18n.t('The driver must be at least 17 years of age'),
      handleBirthValidation
    ),

    /* Medical info */
    MedicalConditionOrDisability: Yup.number(/^[01]$/), // either 0 or 1
    YearsOfDisability: Yup.mixed().when('MedicalConditionOrDisability', {
      is: 1,
      then: number.required
        .positive(i18n.t('Please provide a positive number'))
        .notOneOf([0], i18n.t('Please provide a number other than 0')),
    }),
    DrivingLicenseIssueDate: Yup.date()
      .test(
        'valid-license-date',
        t('The driver is too young to obtain a driving licence'),
        validateDriveLicenseAge
      )
      .required(t('required')),
    DrivingLicenseIssueCountry: Yup.object({
      Oid: string.required,
    }),
    DrivingInLeftSiteOfRoadSince: Yup.date()
      .test(
        'valid-leftside-road-date',
        t(
          'This date can only range from the start of your license issue date until today'
        ),
        validateLeftSiteOfRoadDate
      )
      .required(t('required')),
    LearnerDrivingLicense: Yup.bool(),
  });
  const notPrimarySignupSchema = Yup.object().shape({
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: string.required.test(
      'valid-birth',
      i18n.t('The driver must be at least 17 years of age'),
      handleBirthValidation
    ),
    Title: string.required,
    CustomerCountry: string.required,
    ResidentInCyprusSince: Yup.mixed().when('CustomerCountry', {
      is: cyprusOid,
      then: undefined,
      otherwise: Yup.date().test(
        'valid-resident-cyprus',
        t('Provide a valid date, starting from your birth date until today'),
        validateResidentInCyprusSince
      ),
    }),
    Occupation: string.required,
    Email: email,
    ContactTelephoneNumber: telephonenr.required.test(
      'Phone number',
      t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),

    /* Driving info */
    DrivingLicenseIssueDate: Yup.date()
      .test(
        'valid-license-date',
        t('The driver is too young to obtain a driving licence'),
        validateDriveLicenseAge
      )
      .required(t('required')),
    DrivingLicenseIssueCountry: Yup.object({
      Oid: string.required,
    }),
    DrivingInLeftSiteOfRoadSince: Yup.date()
      .test(
        'valid-leftside-road-date',
        t(
          'This date can only range from the start of your license issue date until today'
        ),
        validateLeftSiteOfRoadDate
      )
      .required(t('required')),
    LearnerDrivingLicense: Yup.bool(),

    /* Medical info */
    MedicalConditionOrDisability: Yup.number(/^[01]$/), // either 0 or 1
    YearsOfDisability: Yup.mixed().when('MedicalConditionOrDisability', {
      is: 1,
      then: number.required
        .positive(t('Please provide a positive number'))
        .notOneOf([0], t('Please provide a number other than 0')),
    }),
    /* Medical info */
  });

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: primaryDriverIsPH ? SignupSchema : notPrimarySignupSchema,
    change: () => {},
  });

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return undefined;

    let key = null;
    if (oidObjects.includes(inputName)) {
      key = values[inputName]['Oid'];
    } else {
      key = values[inputName];
    }

    return object.find(({ value }) => value === key);
  }

  function handleSelectChange(val, name) {
    if (oidObjects.includes(name)) {
      return handleChange({
        name,
        // Nesting in Oid for some properties, see comment above declaration of oidObjects[]
        value: { Oid: val ? val.value : undefined },
      });
    }
    handleChange({ name, value: val ? val.value : undefined });
  }

  useEffect(() => {
    setDefaultValues(data);
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(defaultValues)) {
      if (
        defaultValues?.cars[currentVehicle] != null ||
        !defaultValues?.cars[currentVehicle]?.main_driver ||
        isObjectEmpty(defaultValues?.cars[currentVehicle]?.main_driver) ||
        defaultValues?.cars[currentVehicle]?.main_driver === null
      ) {
        if (data?.personType === 'natural') {
          setPrimaryDriverIsPH(true);
          handleChange({ name: 'same_as_policy', value: true });
        } else {
          setPrimaryDriverIsPH(false);
          handleChange({ name: 'same_as_policy', value: false });
        }
      } else {
        setPrimaryDriverIsPH(
          defaultValues?.cars[currentVehicle].same_as_policy
        );
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!isObjectEmpty(defaultValues)) {
      defaultValuesHandler(defaultValues);
    }
  }, [defaultValues]);

  function defaultValuesHandler() {
    if (defaultValues?.cars[currentVehicle]?.main_driver != null) {
      Object.keys(
        defaultValues?.cars[currentVehicle]?.main_driver || {}
      ).forEach((name) => {
        handleChange({
          name,
          value: defaultValues?.cars[currentVehicle]?.main_driver[name],
        });
      });
    } else {
      Object.keys(defaultValues || {}).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
    if (
      defaultValues?.ganCustomer?.DrivingLicenseIssueDate &&
      !defaultValues?.cars[currentVehicle]?.main_driver?.DrivingLicenseIssueDate
    ) {
      handleChange({
        name: 'DrivingLicenseIssueDate',
        value: defaultValues?.ganCustomer?.DrivingLicenseIssueDate,
      });
    } else {
      handleChange({
        name: 'DrivingLicenseIssueDate',
        value:
          defaultValues?.cars[currentVehicle].main_driver
            ?.DrivingLicenseIssueDate,
      });
    }
  }

  function handleBirthValidation() {
    const { DateOfBirth } = this.parent;
    return (
      17 <= differenceInYears(new Date(), parseISO(DateOfBirth)) &&
      differenceInYears(new Date(), parseISO(DateOfBirth)) <= 100
    );
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit((values) => handleFormValues({ ...values }));
  }

  useEffect(() => {
    if (primaryDriverIsPH) {
      Object.entries(defaultValues?.ganCustomer || {}).forEach(
        ([key, value]) => {
          if (
            key === 'LearnerDrivingLicense' &&
            (value === null || value === undefined)
          ) {
            handleChange({
              name: key,
              value: false,
            });
          } else if (key === 'DrivingLicenseIssueCountry' && !value) {
            handleChange({
              name: key,
              value: { Oid: defaultValues?.ganCustomer?.CustomerCountry?.Oid },
            });
          } else if (key === 'MedicalConditionOrDisability') {
            handleChange({
              name: key,
              value: value ? 1 : 0,
            });
          } else {
            handleChange({
              name: key,
              value,
            });
          }
        }
      );
    } else {
      if (!defaultValues?.cars?.[currentVehicle]?.main_driver) {
        resetForm();
      } else {
        Object.entries(
          defaultValues?.cars?.[currentVehicle]?.main_driver || {}
        ).forEach(([key, value]) => {
          handleChange({
            name: key,
            value: value,
          });
          if (key === 'DrivingLicenseIssueDate')
            handleChange({
              name: 'DrivingInLeftSiteOfRoadSince',
              value: value,
            });
        });
      }
    }
  }, [primaryDriverIsPH]);

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <Scroll>
          <TitleForm>{i18n.t('Primary driver information')}</TitleForm>
          <CheckBox
            name="same_as_policy"
            disabled={data?.personType === 'legal'}
            onChange={(e) => {
              handleChange(e);
              setPrimaryDriverIsPH(!primaryDriverIsPH);
            }}
            checked={primaryDriverIsPH}
          >
            {i18n.t('The primary driver is also the policy holder')}
          </CheckBox>
          <FlexWrapper>
            <TextInput
              name="FirstName"
              error={errors.FirstName}
              onChange={handleChange}
              disabled={primaryDriverIsPH}
              value={values.FirstName}
            >
              {i18n.t('First name')} *
            </TextInput>
            <TextInput
              name="LastName"
              error={errors.LastName}
              disabled={primaryDriverIsPH}
              onChange={handleChange}
              value={values.LastName}
            >
              {i18n.t('Last name')} *
            </TextInput>
          </FlexWrapper>
          <FlexWrapper>
            <SearchSelectInputV2
              error={errors.Title}
              key={`${handleSelectValue(titleOptions, 'Title') ||
                'empty'}_Title`}
              disabled={primaryDriverIsPH}
              name="Title"
              onChange={(val) => {
                handleSelectChange(val, 'Title');
              }}
              options={titleOptions}
              placeholder={SelectPlaceholder}
              value={handleSelectValue(titleOptions, 'Title') || undefined}
            >
              {i18n.t('Title')} *
            </SearchSelectInputV2>
            <DateInputV4
              iso
              name="DateOfBirth"
              disabled={primaryDriverIsPH}
              error={errors.DateOfBirth}
              onChange={handleChange}
              value={values.DateOfBirth}
            >
              {i18n.t('Date of birth')} *
            </DateInputV4>
          </FlexWrapper>

          <FlexWrapper>
            <InputWrapper>
              <StyledLabel>{t('Is driver still a learner')}</StyledLabel>
              <ToggleInput
                checked={values.LearnerDrivingLicense}
                name="LearnerDrivingLicense"
                onChange={(val) => {
                  handleChange({
                    name: 'LearnerDrivingLicense',
                    value: val.checked,
                  });
                }}
                falseLabel={t('no')}
                trueLabel={t('yes')}
              />
            </InputWrapper>
            <DateWrapper>
              <StyledLabel>
                {t('Driving on left side of road since')} *
              </StyledLabel>
              <StyledDateInput
                iso
                error={
                  errors.DrivingInLeftSiteOfRoadSince ||
                  errors?.DrivingLicenseIssueDate
                }
                name="DrivingInLeftSiteOfRoadSince"
                onChange={(val) => {
                  handleChange(val);
                  // We do this way because GAN wants DrivingLicenseIssueDate to equal DrivingInLeftSiteOfRoadSince
                  handleChange({
                    name: 'DrivingLicenseIssueDate',
                    value: val.value,
                  });
                }}
                value={values.DrivingInLeftSiteOfRoadSince}
              />
            </DateWrapper>
          </FlexWrapper>
          {!primaryDriverIsPH && (
            <>
              <FlexWrapper>
                <StyledLabel className="padding">
                  {t('Country of birth')} *
                </StyledLabel>
                <SearchSelectInputV2
                  key={`${handleSelectValue(countries, 'CustomerCountry') ||
                    'empty'}_CustomerCountry`}
                  error={errors.CustomerCountry}
                  name="CustomerCountry"
                  onChange={(val) => {
                    handleSelectChange(val, 'CustomerCountry');
                    // We do this way because GAN wants DrivingLicenseIssueCountry to equal CustomerCountry
                    handleSelectChange(val, 'DrivingLicenseIssueCountry');
                  }}
                  options={countries}
                  placeholder={SelectPlaceholder}
                  value={handleSelectValue(countries, 'CustomerCountry')}
                />
                {values?.CustomerCountry !== cyprusOid &&
                  values?.CustomerCountry && (
                    <StyledDateInput
                      iso
                      name="ResidentInCyprusSince"
                      error={errors.ResidentInCyprusSince}
                      onChange={handleChange}
                      value={values.ResidentInCyprusSince}
                    >
                      <StyledLabel>
                        {t('Resident in Cyprus since')} *
                      </StyledLabel>
                    </StyledDateInput>
                  )}
              </FlexWrapper>

              <FlexWrapper>
                <TextInput
                  name="IdNo"
                  error={errors.IdNo}
                  disabled={primaryDriverIsPH && values?.Oid}
                  onChange={(val) => handleChange(val)}
                  value={
                    defaultValues.idNo !== undefined
                      ? defaultValues.idNo
                      : values.IdNo
                  }
                >
                  <StyledLabel>{i18n.t('ID number')} *</StyledLabel>
                </TextInput>
                <SearchSelectInputV2
                  key={`${handleSelectValue(
                    occupations_lowerCase,
                    'Occupation'
                  ) || 'empty'}_Occupation`}
                  error={errors.Occupation}
                  name="Occupation"
                  onChange={(val) => {
                    handleSelectChange(val, 'Occupation');
                  }}
                  options={occupations_lowerCase}
                  placeholder={selectPlaceholder}
                  value={handleSelectValue(occupations_lowerCase, 'Occupation')}
                >
                  <StyledLabel className="padding">
                    {i18n.t('Occupation')} *
                  </StyledLabel>
                </SearchSelectInputV2>
              </FlexWrapper>

              <FlexWrapper>
                <PhoneInput
                  name="ContactTelephoneNumber"
                  country="cy"
                  error={errors.ContactTelephoneNumber}
                  handleChange={handleChange}
                  value={values.ContactTelephoneNumber}
                  specialLabel=""
                >
                  <StyledLabel> {t('Phonenumber')} *</StyledLabel>
                </PhoneInput>
                <TextInput
                  name="Email"
                  error={errors.Email}
                  onChange={(val) => handleChange(val)}
                  value={
                    defaultValues.Email !== undefined
                      ? defaultValues.Email
                      : values.Email
                  }
                >
                  <StyledLabel>{t('Email')} *</StyledLabel>
                </TextInput>
              </FlexWrapper>
            </>
          )}
          <FlexWrapper>
            <div>
              <StyledLabel>
                {i18n.t('Medical condition or disability')}
              </StyledLabel>
              <ToggleInput
                checked={values.MedicalConditionOrDisability}
                name="MedicalConditionOrDisability"
                onChange={(val) => {
                  const isValTrue = val.checked;
                  handleChange({
                    name: 'MedicalConditionOrDisability',
                    value: isValTrue ? 1 : 0,
                  });
                  !isValTrue &&
                    handleChange({
                      name: 'YearsOfDisability',
                      value: '',
                    });
                }}
                falseLabel={i18n.t('no')}
                trueLabel={i18n.t('yes')}
              />
            </div>
            {values.MedicalConditionOrDisability === 1 && (
              <StyledNumbersInputWithSymbol
                name="YearsOfDisability"
                onChange={(val) =>
                  handleChange({ ...val, value: parseInt(val.value) || null })
                }
                value={values.YearsOfDisability}
                error={errors.YearsOfDisability}
                disabled={!values.MedicalConditionOrDisability}
                icon={<IconDisabilityFilled />}
              >
                {i18n.t('Years of disability')}
              </StyledNumbersInputWithSymbol>
            )}
          </FlexWrapper>
        </Scroll>
        <ButtonContainer insurance={insuranceType}>
          <Required>* {i18n.t('Required fields')}</Required>
          <div>
            {insuranceType !== 'allMobile' && (
              <BackButton type="button" name="back" onClick={goBack}>
                <IconActionChevronLeft />
                {i18n.t('Back')}
              </BackButton>
            )}
            <ActionButton
              type="submit"
              value="Submit"
              data-test-id="drivers_information_submit"
            >
              {insuranceType === 'allMobile'
                ? i18n.t('Submit')
                : i18n.t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const InputWrapper = styled.div`
  width: -webkit-fill-available;
`;

const DateWrapper = styled(InputWrapper)`
  margin-bottom: 2.5rem;
`;

const StyledNumbersInputWithSymbol = styled(NumbersInputWithSymbol)`
  & span {
    padding: 0.5rem;
    top: 2.4rem;
  }

  & label {
    color: #8990a3;
    font-size: 1.5rem;
    line-height: 1rem;
  }
`;

const StyledLabel = styled(Label)`
  color: #5b5550;
`;

const Scroll = styled.div`
  margin-bottom: 1rem;
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
  }

  & > div + div {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1140px) {
    flex-direction: column;
    & > div + div {
      margin-left: unset;
    }
    & > * {
      margin-bottom: 2rem;
    }
  }
`;

const StyledDateInput = styled(DateInputV4)`
  margin-top: 1.4rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > div > label {
    color: #aeaeae;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
  }

  & button {
    font-weight: ${({ insurance }) =>
      insurance === 'allMobile' ? '500' : '550'};
    height: ${({ insurance }) => (insurance === 'allMobile' ? '4rem' : '5rem')};
    min-width: 12rem;
    width: ${({ insurance }) => (insurance === 'allMobile' ? '100%' : '12rem')};
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: ${({ insurance }) =>
        insurance === 'allMobile' ? 'center' : 'end'};
    }
  }
`;

export default FlowDriversInformationForm;
