import React, { useState, useEffect } from 'react';
import FlowPolicyHolderInfoFormPrivate from './FlowPolicyHolderInfoFormPrivate.jsx';
import FlowPolicyHolderInfoFormLegal from './FlowPolicyHolderInfoFormLegal.jsx';
import FlowPolicyHolderInfoFormExistingCustomer from './FlowPolicyHolderInfoFormExistingCustomer.jsx';
import FlowPolicyHolderPersonRadio from './FlowPolicyHolderPersonRadio.jsx';
import { generateUUID } from '../helpers/uuidService';
import { initializeStorage, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import styled from 'styled-components';
import i18n from '../i18n';

const FlowPolicyHolderInfoForm = ({
  retrieveSubmit,
  defaultValues,
  setId,
  id,
  loading,
  askEmailExistingCustomer,
}) => {
  const [personType, setPersonType] = useState();
  const { affinity, insuranceType } = useParams();
  const [allowedSwitchPersonType, setAllowedSwitchPersonType] = useState(true);
  const [customerType, setCustomerType] = useState('New');

  const formSubmitHandler = (val) => {
    retrieveSubmit(val, personType);
  };

  useEffect(() => {
    const tempId = generateUUID();
    setId(tempId);
    initializeStorage(tempId, affinity, insuranceType);
    if (insuranceType === 'teacher' || insuranceType === 'legal') {
      setPersonType('natural');
      setAllowedSwitchPersonType(false);
    } else {
      setPersonType();
      setAllowedSwitchPersonType(true);
    }
  }, [affinity, insuranceType]);

  useEffect(() => {
    if (personType) {
      patchStorage({ payload: { personType }, id, affinity, insuranceType });
    }
  }, [personType]);

  return (
    <>
      <TitleForm>{i18n.t('Personal Information')}</TitleForm>
      <CustomerInformationContainer>
        <StyledButton
          active={customerType === 'New'}
          onClick={(e) => {
            e.preventDefault();
            setCustomerType('New');
          }}
        >
          {i18n.t('New Customer')}
        </StyledButton>
        <StyledButton
          active={customerType === 'Existing'}
          onClick={(e) => {
            e.preventDefault();
            setCustomerType('Existing');
          }}
        >
          {i18n.t('Existing Customer')}
        </StyledButton>
      </CustomerInformationContainer>
      {customerType === 'New' ? (
        <>
          {allowedSwitchPersonType ? (
            <FlowPolicyHolderPersonRadio
              personType={(val) => setPersonType(val)}
              checked={personType}
            />
          ) : null}
          {personType === 'natural' ? (
            <FlowPolicyHolderInfoFormPrivate
              insuranceType={insuranceType}
              defaultValues={defaultValues}
              loading={loading}
              sendSubmit={formSubmitHandler}
            />
          ) : null}
          {personType === 'legal' ? (
            <FlowPolicyHolderInfoFormLegal
              defaultValues={defaultValues}
              loading={loading}
              sendSubmit={formSubmitHandler}
            />
          ) : null}
        </>
      ) : (
        <>
          {allowedSwitchPersonType ? (
            <FlowPolicyHolderPersonRadio
              personType={(val) => setPersonType(val)}
              checked={personType}
            />
          ) : null}
          <FlowPolicyHolderInfoFormExistingCustomer
            defaultValues={defaultValues}
            loading={loading}
            personType={personType}
            askEmailExistingCustomer={askEmailExistingCustomer}
            sendSubmit={formSubmitHandler}
          />
        </>
      )}
    </>
  );
};

const CustomerInformationContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 3rem 0;
  padding-top: 0;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  max-width: 21.3rem;
  width: 48%;
  height: 5.3rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
  }

  &:first-of-type  {
    margin-right: 2.4rem;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

FlowPolicyHolderInfoForm.defaultProps = {
  setId: () => {},
};

export default FlowPolicyHolderInfoForm;
