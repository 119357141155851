import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  SearchSelectInput,
  Label,
  TextInput,
  IconActionChevronLeft,
  DateInputV4,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import i18n from '../i18n';

import { string, number } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router';
import { diseases } from '../constants/diseasesListData';
import {
  primaryOptions as primaryOptionsConstants,
  DurationLiveAbroadEnum,
} from '../constants/FlowSearchSelectData';

const FlowHealthInformationForm = ({ handleFormValues, defaultValues }) => {
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [diseaseOptions, setDiseaseOptions] = useState([]);
  const [selected, setSelected] = useState({});
  const [durationLiveAbroadOptions, setDurationLiveAbroadOptions] = useState(
    []
  );
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    HeightInCM: number.required,
    WeightInKg: number.required,
    DoYouSmoke: string.required,
    NumberOfCigarettesPerDay: Yup.number().when('DoYouSmoke', {
      is: 'yes',
      then: number.required.min(1, i18n.t('Too low')),
    }),
    HowManyMonthsOfAYearDoYouLiveAbroad: string.required,
    AnyChronicOrLongTermMedicalCondition: string.required,
    EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth:
      string.required,
    CurrentlyDisabledPregnantOrUnableToPerformNormalActivities: string.required,
    AnyDiseasesOfTheCirculatorySystemHeart: string.required,
    MedicalDiseases: Yup.mixed().when(
      [
        'AnyChronicOrLongTermMedicalCondition',
        'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
      ],
      {
        is: (
          AnyChronicOrLongTermMedicalCondition,
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
        ) =>
          AnyChronicOrLongTermMedicalCondition === 'yes' ||
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes',
        then: Yup.array().required(i18n.t('Required')),
      }
    ),
    NutritionalAndMetabolicDiseases: Yup.mixed().when(
      [
        'AnyChronicOrLongTermMedicalCondition',
        'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
      ],
      {
        is: (
          AnyChronicOrLongTermMedicalCondition,
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
        ) =>
          AnyChronicOrLongTermMedicalCondition === 'yes' ||
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes',
        then: string.required,
      }
    ),
    DiabetesDiagnosed: Yup.mixed().when(
      [
        'AnyChronicOrLongTermMedicalCondition',
        'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
      ],
      {
        is: (
          AnyChronicOrLongTermMedicalCondition,
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
        ) =>
          AnyChronicOrLongTermMedicalCondition === 'yes' ||
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes',
        then: Yup.mixed()
          .nullable()
          .when('MedicalDiseases', {
            is: (MedicalDiseases) =>
              MedicalDiseases?.find((item) => item.value === '224'),
            then: Yup.date()
              .required('Required')
              .max(new Date(), 'Date must be in the past'),
          }),
      }
    ),
    ControlledByDiet: Yup.mixed().when(
      [
        'AnyChronicOrLongTermMedicalCondition',
        'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
      ],
      {
        is: (
          AnyChronicOrLongTermMedicalCondition,
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
        ) =>
          AnyChronicOrLongTermMedicalCondition === 'yes' ||
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes',
        then: Yup.mixed()
          .nullable()
          .when('MedicalDiseases', {
            is: (MedicalDiseases) =>
              MedicalDiseases?.find((item) => item.value === '224'),
            then: string.required,
          }),
      }
    ),
    DiabetesMedicationTaken: Yup.mixed().when(
      [
        'AnyChronicOrLongTermMedicalCondition',
        'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
      ],
      {
        is: (
          AnyChronicOrLongTermMedicalCondition,
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
        ) =>
          AnyChronicOrLongTermMedicalCondition === 'yes' ||
          EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes',
        then: Yup.mixed()
          .nullable()
          .when('MedicalDiseases', {
            is: (MedicalDiseases) =>
              MedicalDiseases?.find((item) => item.value === '224'),
            then: string.required,
          }),
      }
    ),
  });

  const SelectPlaceholder = i18n.t('Choose your option');

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstants.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setDiseaseOptions(
      diseases.map(({ value, label }) => {
        return { value, label: label[i18n.language] || label };
      })
    );
    setDurationLiveAbroadOptions(
      DurationLiveAbroadEnum.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (
        [
          'HowManyMonthsOfAYearDoYouLiveAbroad',
          'DoYouSmoke',
          'AnyChronicOrLongTermMedicalCondition',
          'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth',
          'CurrentlyDisabledPregnantOrUnableToPerformNormalActivities',
          'AnyDiseasesOfTheCirculatorySystemHeart',
          'MedicalDiseases',
          'NutritionalAndMetabolicDiseases',
          'ControlledByDiet',
        ].includes(name)
      ) {
        handleSelected({ name, value: defaultValues[name] });
      } else {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }

  const getEnumValue = (name, value) => {
    switch (name) {
      case 'HowManyMonthsOfAYearDoYouLiveAbroad':
        return durationLiveAbroadOptions.find((item) => item.value === value);
      case 'DoYouSmoke':
      case 'AnyChronicOrLongTermMedicalCondition':
      case 'EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth':
      case 'CurrentlyDisabledPregnantOrUnableToPerformNormalActivities':
      case 'AnyDiseasesOfTheCirculatorySystemHeart':
      case 'NutritionalAndMetabolicDiseases':
      case 'ControlledByDiet':
        return primaryOptions.find((item) => item.value === value);
      default:
        return;
    }
  };

  const handleSelected = ({ value, name }) => {
    const selectedVal = getEnumValue(name, value);
    setSelected((prevValue) => ({
      ...prevValue,
      [name]: selectedVal,
    }));
    handleChange({ value, name });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t('Health information')} </CatTitle>
        <FlexWrapper>
          <StyledTextInput
            name="HeightInCM"
            error={errors.HeightInCM}
            onChange={handleChange}
            value={values.HeightInCM}
          >
            <StyledLabel>{i18n.t('Height')} *</StyledLabel>
            <p className="valueType">{i18n.t('cm')}</p>
          </StyledTextInput>
          <StyledTextInput
            name="WeightInKg"
            error={errors.WeightInKg}
            onChange={handleChange}
            value={values.WeightInKg}
          >
            <StyledLabel>{i18n.t('Weight')} *</StyledLabel>
            <p className="valueType">{i18n.t('kg')}</p>
          </StyledTextInput>
        </FlexWrapper>
        <SelectInput
          error={errors.DoYouSmoke}
          name="DoYouSmoke"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.DoYouSmoke}
        >
          <StyledLabel>{i18n.t('Are you a smoker')} *</StyledLabel>
        </SelectInput>
        {values?.DoYouSmoke === 'yes' && (
          <StyledTextInput
            name="NumberOfCigarettesPerDay"
            type="number"
            error={errors.NumberOfCigarettesPerDay}
            onChange={handleChange}
            value={values.NumberOfCigarettesPerDay}
          >
            <StyledLabel>{i18n.t('How many cigarettes a day?')} *</StyledLabel>
          </StyledTextInput>
        )}
        <SelectInput
          error={errors.HowManyMonthsOfAYearDoYouLiveAbroad}
          name="HowManyMonthsOfAYearDoYouLiveAbroad"
          onSelected={handleSelected}
          value={selected?.HowManyMonthsOfAYearDoYouLiveAbroad}
          options={durationLiveAbroadOptions}
          placeholder={SelectPlaceholder}
          isClearable
        >
          <StyledLabel>
            {i18n.t('How many months a year do you live abroad')}? *
          </StyledLabel>
        </SelectInput>
        <SelectInput
          error={errors.AnyChronicOrLongTermMedicalCondition}
          name="AnyChronicOrLongTermMedicalCondition"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.AnyChronicOrLongTermMedicalCondition}
        >
          <StyledLabel>
            {i18n.t('Do you have any chronic or longterm medical conditions?')}{' '}
            *{' '}
          </StyledLabel>
        </SelectInput>
        <SelectInput
          error={
            errors.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
          }
          name="EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={
            selected?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth
          }
        >
          <StyledLabel>
            {i18n.t(
              'Have you even been diagnosed with a medical problem that may have impact on your health?'
            )}{' '}
            *{' '}
          </StyledLabel>
        </SelectInput>
        <SelectInput
          error={
            errors.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
          }
          name="CurrentlyDisabledPregnantOrUnableToPerformNormalActivities"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={
            selected?.CurrentlyDisabledPregnantOrUnableToPerformNormalActivities
          }
        >
          <StyledLabel>
            {i18n.t(
              'Are you currently disabled, pregnant or unable to perform normal activities?'
            )}{' '}
            *{' '}
          </StyledLabel>
        </SelectInput>
        <SelectInput
          error={errors.AnyDiseasesOfTheCirculatorySystemHeart}
          name="AnyDiseasesOfTheCirculatorySystemHeart"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.AnyDiseasesOfTheCirculatorySystemHeart}
        >
          <StyledLabel>
            {i18n.t(
              'Do you have any diseases of the circulatory system (heart)'
            )}{' '}
            *{' '}
          </StyledLabel>
        </SelectInput>
        {(values?.AnyChronicOrLongTermMedicalCondition === 'yes' ||
          values?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth ===
            'yes') && (
          <>
            <SelectInput
              isMulti
              error={errors.MedicalDiseases}
              name="MedicalDiseases"
              onSelected={handleSelected}
              options={diseaseOptions}
              value={values?.MedicalDiseases}
              placeholder={SelectPlaceholder}
              isClearable
            >
              <StyledLabel>
                {i18n.t('What medical diseases do you have')}? *
              </StyledLabel>
            </SelectInput>
            <SelectInput
              error={errors.NutritionalAndMetabolicDiseases}
              name="NutritionalAndMetabolicDiseases"
              onSelected={handleSelected}
              options={primaryOptions}
              placeholder={SelectPlaceholder}
              isClearable
              value={selected?.NutritionalAndMetabolicDiseases}
            >
              <StyledLabel>
                {i18n.t(
                  'Do you have any nutritional and or metabolic diseases'
                )}
                ? *
              </StyledLabel>
            </SelectInput>
            {values?.MedicalDiseases?.find((item) => item.value === '224') && (
              <>
                <StyledDateInputV4
                  name="DiabetesDiagnosed"
                  error={errors.DiabetesDiagnosed}
                  onChange={(val) => {
                    handleChange(val);
                  }}
                  value={values.DiabetesDiagnosed || undefined}
                >
                  <StyledLabel>
                    {i18n.t('When was your diabetes mellitus diagnosed')}? *
                  </StyledLabel>
                </StyledDateInputV4>
                <SelectInput
                  error={errors.ControlledByDiet}
                  name="ControlledByDiet"
                  onSelected={handleSelected}
                  options={primaryOptions}
                  placeholder={SelectPlaceholder}
                  isClearable
                  value={selected?.ControlledByDiet}
                >
                  <StyledLabel>
                    {i18n.t('Is it controlled by your diet')}? *
                  </StyledLabel>
                </SelectInput>
                <StyledTextInput
                  name="DiabetesMedicationTaken"
                  error={errors.DiabetesMedicationTaken}
                  onChange={(val) => {
                    handleChange(val);
                  }}
                  value={values.DiabetesMedicationTaken}
                >
                  <StyledLabel>
                    {i18n.t(
                      'What medication do you take to control your diabetes mellitus'
                    )}
                    ? *
                  </StyledLabel>
                </StyledTextInput>
              </>
            )}
          </>
        )}
        <Required>* {i18n.t('Required fields')}</Required>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <StyledActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledDateInputV4 = styled(DateInputV4)`
  margin-bottom: 2rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    margin-left: 1rem;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.text};
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  position: relative;
  p.valueType {
    padding: 1.4rem;
    border-left: 1px solid #cccccc;
    position: absolute;
    margin: 0;
    right: 0rem;
    bottom: 0rem;
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

export default FlowHealthInformationForm;
