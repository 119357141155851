import React, { useEffect } from 'react';
import { ganAuth, ganGetCustomerById } from '../helpers/apiRouterService';

const CorsTest = () => {
  useEffect(() => {
    console.log('running cors test');
    const corsTest = async () => {
      const [respA, statusA] = await ganAuth();
      console.log(respA, statusA);
      const [respB, statusB] = await ganGetCustomerById('dfqw234241341');
      console.log(respB, statusB);
    };
    corsTest();
  }, []);
  return <h1>Cors test page</h1>;
};

export default CorsTest;
