import React from 'react';

const CarCatGanIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 80 80"
    >
      <g strokeWidth="2.482" clipPath="url(#clip0)">
        <path
          stroke="url(#paint0_linear)"
          strokeMiterlimit="10"
          d="M39.995 78.754a38.759 38.759 0 1138.76-38.76 38.799 38.799 0 01-38.76 38.76v0z"
        ></path>
        <path
          stroke="#024284"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M56.27 36.17H44.38V24.28h-8.52v11.89H23.98v8.52h11.88v11.88h8.52V44.69h11.89v-8.52z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.366"
          x2="74.634"
          y1="20.007"
          y2="59.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#024284"></stop>
          <stop offset="0.81" stopColor="#004587"></stop>
          <stop offset="0.82" stopColor="#68A93E"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H80V80H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarCatGanIcon;
