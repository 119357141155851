import { GanDirect } from '../constants/themingData';
export let currentTheme = {};

const themes = {
  'gan-direct': GanDirect(),
};

export function getThemeObject(affinity) {
  return themes[
    Object.keys(themes).includes(affinity) ? affinity : 'gan-direct'
  ];
}
