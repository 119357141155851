import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import FlowAddressContainer from '../components/FlowAddressContainer';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import { useFlowStore } from '../contexts/FlowContext';
import useForm from '../hooks/useForm';
import {
  ActionButton,
  SearchSelectInput,
  IconActionChevronLeft,
  ToggleInput,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { retrieveAddressInfo } from '../helpers/apiRouterService';
import FlowChatBox from '../components/FlowChatBox';
import { financial_institutions } from '../constants/FinancialInstitutionsData';

const FlowSecondRiskAddress = () => {
  const [loading, setLoading] = useState(false);
  const [flowStore] = useFlowStore();
  const { addGanRiskAddress } = flowStore || {};
  const [defaultValues, setDefaultValues] = useState();
  const [propertyMortaged, setPropertyMortaged] = useState(false);
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
    buildingName: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    const question = {
      message: i18n.t(
        "Let's continue with the next building. Please fill in the address"
      ),
      slug: 'risk-address',
    };
    addQuestion(question);
    loadInitValues();
  }, []);

  const loadInitValues = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    if (data?.risk_addresses[currentProperty]?.FinancialInstitution) {
      handleChange({
        name: 'financial_institution',
        value: data?.risk_addresses[currentProperty]?.FinancialInstitution,
      });
    }
  };

  function receiveNewAddress(address) {
    setDefaultValues(address);
    defaultValuesHandler();
  }

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (values) => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
      buildingName,
    } = values;

    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: 'CY',
      boxnr: boxNumber,
      city: municipalityName,
    });

    const [respGan] = await addGanRiskAddress(
      {
        StreetName: streetName,
        HouseNumber: streetNumber.toLowerCase(),
        ZipCode: postalCode,
        BuildingName: buildingName,
      },
      data?.ganCustomer?.Oid
    );

    if (status / 100 === 2) {
      const element = {
        message: {
          address: resp.address,
          blankImage: resp.aerial_images_blank_satellite,
          image: resp.aerial_images_marked_satellite,
        },
        slug: 'address_response',
      };
      addAnswer(element);
      const { financial_institution } = values;
      respGan.property_mortaged = propertyMortaged;
      respGan.FinancialInstitution = propertyMortaged
        ? financial_institution
        : null;

      delete resp.parcel.main_building.surface;
      delete resp.parcel.main_building.house_type;

      patchStorage({
        payload: { ...resp, ...respGan },
        path: ['risk_addresses', currentProperty],
        id,
        affinity,
        insuranceType,
      });

      renderNextRoute(1, { property: currentProperty });
    }
    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <div>
          <TitleForm>
            {i18n.t('What other address would you like to insure?')}
          </TitleForm>
          <FlowAddressContainer
            title={i18n.t(`Fill in the address you would like to insure`)}
            errors={errors}
            values={values}
            defaultValues={defaultValues}
            sendAddress={receiveNewAddress}
            handleChange={handleChange}
          />
          <div>
            <Label>{i18n.t('Is the property mortgaged?')}</Label>
            <ToggleInput
              name="property_mortaged"
              falseLabel={i18n.t('No')}
              trueLabel={i18n.t('Yes')}
              checked={propertyMortaged}
              onChange={() => setPropertyMortaged(!propertyMortaged)}
            />
          </div>
          {propertyMortaged && (
            <div>
              <SelectInput
                name="financial_institution"
                error={errors.financial_institution}
                value={handleSelectValue(
                  financial_institutions,
                  'financial_institution'
                )}
                onChange={(val) => {
                  handleChange({
                    name: 'financial_institution',
                    value: val ? val.value : '',
                  });
                }}
                options={financial_institutions}
                placeholder="Choose an option"
              >
                {i18n.t('Select the financial institution')}
              </SelectInput>
            </div>
          )}
        </div>
        <form onSubmit={handleForm}>
          <ButtonContainer>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            {loading ? (
              <LoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Next')}
              </LoadingActionButton>
            ) : (
              <ActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t('Next')}
              </ActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const SelectInput = styled(SearchSelectInput)`
  min-height: 7rem;
  & label {
    color: #8990a3;
  }
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
    line-height: 125%;
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
  .Select__control {
    border-color: ${({ error }) => !error && '#e4e4e4'} !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const Label = styled.h2`
  color: #8990a3;
  font-size: 1.4rem;
  margin-top: 2rem;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export default FlowSecondRiskAddress;
