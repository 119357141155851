import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import FlowAddressContainer from '../components/FlowAddressContainer';
import LoadingActionButton from '../components/LoadingActionButton';
import { patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import { ActionButton, ToggleInput } from 'wg-fe-ui';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { retrieveAddressInfo } from '../helpers/apiRouterService';
import FlowChatBox from '../components/FlowChatBox';

const FlowSecondRiskAddressMobile = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const [propertyMortgaged, setPropertyMortgaged] = useState(false);
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        "Let's continue with the <span>next building.</span> Please fill in the address"
      ),
      slug: 'risk-address',
    };
    addQuestion(question);
  }, []);

  function receiveNewAddress(address) {
    setDefaultValues(address);
    defaultValuesHandler();
  }

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (address) => {
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
    } = address;

    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: 'CY',
      boxnr: boxNumber,
      city: municipalityName,
    });

    if (status / 100 === 2) {
      const element = {
        message: {
          address: resp.address,
          blankImage: resp.aerial_images_blank_satellite,
          image: resp.aerial_images_marked_satellite,
        },
        slug: 'address_response',
      };
      addAnswer(element);

      resp.property_mortgaged = propertyMortgaged;

      patchStorage({
        payload: resp,
        path: ['risk_addresses', [currentProperty]],
        id,
        affinity,
        insuranceType,
      });

      renderNextRoute(1, { property: currentProperty });
    }
    setLoading(false);
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <div>
          <FlowAddressContainer
            title={i18n.t(`Fill in the address you would like to insure`)}
            errors={errors}
            values={values}
            defaultValues={defaultValues}
            sendAddress={receiveNewAddress}
            handleChange={handleChange}
          />
          <div>
            <Label>{i18n.t('Is the property mortgaged?')}</Label>
            <ToggleInput
              name="property_mortgaged"
              falseLabel={i18n.t('No')}
              trueLabel={i18n.t('Yes')}
              checked={propertyMortgaged}
              onChange={() => setPropertyMortgaged(!propertyMortgaged)}
            />
          </div>
        </div>
        <ButtonContainer>
          {loading ? (
            <LoadingActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_loading"
            >
              {i18n.t('Loading')}
            </LoadingActionButton>
          ) : (
            <ActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_submit"
              onClick={handleForm}
            >
              {i18n.t('Submit')}
            </ActionButton>
          )}
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Label = styled.h2`
  color: #8990a3;
  font-size: 1.4rem;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: scroll;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 47% 53%;
  width: 100%;
`;
export default FlowSecondRiskAddressMobile;
