import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useParams, useHistory } from 'react-router-dom';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import useRouting from '../hooks/useRouting';
import _ from 'lodash';
import ListItemCard from '../components/ListItemCard';
import InventoryForm from '../components/InventoryForm';
import {
  propertyInventoryCategories,
  propertyInventoryItems,
} from '../constants/InventoryData';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeHighRiskSpecifics = () => {
  const { renderNextRoute } = useRouting();
  const [inventoryList, setInventoryList] = useState([]);
  const history = useHistory();
  const { id, affinity, insuranceType, currentProperty } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setInventoryList(
      data?.risk_addresses[currentProperty]?.PropertyInventory || []
    );
  }, []);

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const submitHandler = () => {
    patchStorage({
      payload: {
        PropertyInventory: inventoryList,
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  function handleDeleteItem(item) {
    let temp = inventoryList.filter((listItem) => !_.isEqual(listItem, item));
    setInventoryList(temp);
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <Title>
          {i18n.t('Inventory list of high risk or specified items')}
        </Title>
        <SpecificsContainer>
          <div>
            {inventoryList.length > 0 && (
              <>
                <List>
                  {inventoryList.map((item, i) => {
                    return (
                      <ListItemCard
                        title={
                          propertyInventoryItems.find(
                            (p) => p.value === item.InventoryItemName
                          )?.label
                        }
                        subTitle={
                          propertyInventoryCategories.find(
                            (p) => p.value === item.InventoryCategory
                          )?.label
                        }
                        price={item.PriceOfItem}
                        onRemove={() => handleDeleteItem(item)}
                        key={i}
                      />
                    );
                  })}
                </List>
                <Divider />
              </>
            )}
            <InventoryForm
              onAdd={(newItem) => setInventoryList([...inventoryList, newItem])}
            />
          </div>
        </SpecificsContainer>
        <ButtonContainer>
          <Required>* {i18n.t('Required fields')}</Required>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => submitHandler()}
              type="submit"
              value="Submit"
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e4e4e4;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const List = styled.ul`
  display: flex;
  margin-bottom: 1.6rem;
  justify-content: left;
  overflow-x: auto;
  padding: 1.6rem 0;
  min-height: 13rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  align-self: center;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const SpecificsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  > div > p {
    color: #30373d;
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 550;
    font-size: 1.6rem;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.brand.primary};
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
`;

export default FlowHomeHighRiskSpecifics;
