import React from 'react';
import styled from 'styled-components';
import { IconChevronDown, IconSearch } from 'wg-fe-ui';
import useBreakPoint from '../../hooks/useBreakPoint';
import { bp, mq } from '../../helpers/styledComponenstService';
import { isMobile as isMobileDevice } from 'react-device-detect';

interface Props {
  innerProps: Node | React.ReactNode;
  innerRef: React.RefObject<HTMLDivElement>;
  children: Node | React.ReactNode;
  selectProps: {
    menuIsOpen: boolean;
  };
  data: {
    label_secondary?: string;
    label: string;
    value: string | number;
  };
}

const SearchSelectCustomDropdownIndicator: React.FC<Props> = ({
  innerProps,
  innerRef,
  selectProps,
}) => {
  const { width } = useBreakPoint();
  const isMobile = isMobileDevice || width < bp.laptop;
  return (
    <div {...innerProps} ref={innerRef} className="Select__dropdown-indicator">
      <IconContainer isMobile={isMobile} open={selectProps?.menuIsOpen}>
        {isMobile ? (
          <IconSearch color="#C1C1C1" />
        ) : (
          <IconChevronDown color="#C1C1C1" />
        )}
      </IconContainer>
    </div>
  );
};

const IconContainer = styled.div<{ open: boolean; isMobile: boolean }>`
  padding-right: 0.4rem;
  display: flex;
  align-items: center;
  height: 100%;
  & svg {
    transition: 0.2s;
    opacity: 0.5;
    width: 3.5rem;

    ${mq.laptop} {
      transform: ${({ open, isMobile }) =>
        open && !isMobile ? 'rotate(180deg)' : null};
    }
  }
`;

export default SearchSelectCustomDropdownIndicator;