import * as Yup from 'yup';
import i18n from '../i18n';

export const string = {
  required: Yup.string().required(i18n.t(`required`)),
  notRequired: Yup.string().nullable(),
};

export const number = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const positiveNumber = number.required
  .positive(i18n.t('Please provide a positive number'))
  .notOneOf([0], i18n.t('Please provide a number above 0'));

export const date = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const average_km_per_year = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(0, i18n.t(`Value cannot be 0`))
  .max(100001, i18n.t(`Value too high`))
  .nullable()
  .required(i18n.t(`required`));

export const dateWithOutYear = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const firstName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const companyName = Yup.string()
  .required(i18n.t(`required`))
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_& ]{1,50}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const cbe = {
  required: Yup.string()
    .matches(/[0-9]{9}[A-Z]{1}/, i18n.t(`incorrect cbe format`))
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(/[0-9]{9}[A-Z]{1}/, i18n.t(`incorrect cbe format`)),
};

export const email = Yup.string()
  .email(i18n.t(`email`))
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const telephonenr = {
  required: Yup.string()
    .matches(/[0-9]{10}/, i18n.t(`Please enter a valid telephone number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const password = Yup.string()
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
};

export const options = Yup.number()
  .nullable()
  .typeError(i18n.t(`not a number`))
  .max(2500, i18n.t(`too long`));

export const licensePlate = Yup.string()
  .matches(/^[A-Za-z0-9 -]{1,20}$/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`));

export const vinNumber = Yup.string()
  .matches(/^[a-zA-Z0-9]{17,17}$/, i18n.t(`incorrect format`))
  .required(i18n.t(`required`));

export const captcha = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website = Yup.string(i18n.t(`required`))
  .matches()
  .nullable()
  .required(i18n.t(`required`));

export const numberplate = Yup.string().required(i18n.t(`required`));

export const nationalRegisterNr = Yup.string(i18n.t(`required`))
  .matches(
    /^[0-9][0-9].[0-9][0-9].[0-9][0-9]-[0-9][0-9][0-9].[0-9][0-9]$/,
    i18n.t(`incorrect format`)
  )
  .nullable();

export const street = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const zipcode = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`too short`))
  .max(9999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const construction_year = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1800, i18n.t(`Please enter a valid year`))
  .max(
    new Date().getFullYear(),
    i18n.t(`The construction year can not be in the future`)
  )
  .nullable()
  .required(i18n.t(`Required`));

export const housenr = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1, i18n.t(`too short`))
  .max(99999999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const boxNr = Yup.string()
  .matches(/[a-zA-Z0-9\- /]{1,20}/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`))
  .nullable();

export const city = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const bonusMalus = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(-2, i18n.t(`bm too low`))
  .max(22, i18n.t(`bm too high`))
  .nullable()
  .required(i18n.t(`required`));

export const vat_regime = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(0, i18n.t(`give percentage between 0 and 100`))
  .max(100, i18n.t(`give percentage between 0 and 100`))
  .nullable();

export const seats = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(2, i18n.t(`minSeats`))
  .max(10, i18n.t(`maxSeats`))
  .nullable()
  .required(i18n.t(`required`));

export const id_number = Yup.string()
  .required(i18n.t(`required`))
  .matches(/^[a-zA-Z0-9]+$/, i18n.t('invalid'));

export const oidObject = {
  string: Yup.object({
    Oid: string.required,
  }),
  number: Yup.object({
    Oid: number.required,
  }),
};

export const ganBool = Yup.number(/^[01]$/); // Either 1 or 0
