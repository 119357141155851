import React from 'react';

const UserGanIcon = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 79.61 79.61">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M39.8,78.37A38.57,38.57,0,1,1,78.37,39.8,38.61,38.61,0,0,1,39.8,78.37Z"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <line
            x1="35.67"
            y1="52.39"
            x2="35.67"
            y2="60.19"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <line
            x1="45.91"
            y1="51.54"
            x2="45.91"
            y2="59.85"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <polyline
            points="14.4 61.56 31.27 56.76 40.44 64.72 50.32 56.59 65.21 62.07"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
          <rect
            x="25.89"
            y="14.89"
            width="28.85"
            height="37.53"
            rx="14.43"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.48162492794007px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default UserGanIcon;