export default [
  {
    title: 'user-address',
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: 'risk-address',
      2: 'home-information',
    },
  },
  {
    title: 'risk-address',
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: 'home-information',
    },
  },
  {
    title: 'home-information',
    step: 1,
    subStep: 3,
    nextRoutes: {
      1: 'home-information-extra',
    },
  },
  {
    title: 'home-information-extra',
    step: 1,
    subStep: 4,
    nextRoutes: {
      1: 'building-information',
    },
  },
  {
    title: 'building-information',
    step: 1,
    subStep: 5,
    nextRoutes: {
      1: 'property-insurance-policy',
    },
  },
  {
    title: 'property-insurance-policy',
    step: 1,
    subStep: 6,
    nextRoutes: {
      1: 'property-insurance-policy-information',
      2: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-policy-information',
    step: 1,
    subStep: 7,
    nextRoutes: {
      1: 'property-insurance-extras',
    },
  },
  {
    title: 'property-insurance-extras',
    step: 1,
    subStep: 8,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-bike',
      3: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'property-insurance-extras-pets',
    step: 1,
    subStep: 10,
    nextRoutes: {
      1: 'home-specifics',
    },
  },
  {
    title: 'property-insurance-extras-bike',
    step: 1,
    subStep: 9,
    nextRoutes: {
      1: 'home-specifics',
      2: 'property-insurance-extras-pets',
    },
  },
  {
    title: 'home-specifics',
    step: 1,
    subStep: 9,
    nextRoutes: {
      1: 'home-high-risk',
    },
  },
  {
    title: 'home-high-risk',
    step: 1,
    subStep: 10,
    nextRoutes: {
      1: 'insurance-options-home',
    },
  },
  {
    title: 'insurance-options-home',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'home-premium-summary',
    },
  },
  {
    title: 'home-premium-summary',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'overview',
    },
  },
  {
    title: 'thankyou',
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];
