import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import {
  CheckBox_v2 as CheckBox,
  IconDriverFilled,
  IconParkingFilled,
  IconAttentionAssistanceFilled,
  IconECallFilled,
  IconEmergencyBrakeFilled,
  IconBlindSpotFilled,
  IconCruiseControlFilled,
  IconLaneControlFilled,
} from 'wg-fe-ui';

const SafetySystemItem = ({ value, onChange, selected }) => {
  const { insuranceType } = useParams();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    onChange({ name: value, value: isSelected });
  }, [isSelected]);

  function handleChange() {
    setIsSelected(!isSelected);
  }

  useEffect(() => {
    if (selected?.includes(value)) {
      setIsSelected(true);
    }
  }, [selected]);

  function handleSafetySystemItemIcon(item) {
    switch (item) {
      case 'Automatic Emergency Braking (AEB)':
        return <IconEmergencyBrakeFilled />;
      case 'Adaptive Cruise Control':
        return <IconCruiseControlFilled />;
      case 'Lane support':
        return <IconLaneControlFilled />;
      case 'Blind Spot Monitor':
        return <IconBlindSpotFilled />;
      case 'Attention Assist':
        return <IconAttentionAssistanceFilled />;
      case 'eCall':
        return <IconECallFilled />;
      case 'Automatic Parking':
        return <IconParkingFilled />;
      case 'Parking Aid':
        return <IconDriverFilled />;
      default:
        return <IconDriverFilled />;
    }
  }

  return (
    <Item
      insurance={insuranceType}
      checked={isSelected}
      onClick={() => handleChange()}
    >
      {handleSafetySystemItemIcon(value)}
      <p>{value}</p>
      {isSelected ? <CheckBox checked={true} /> : ''}
    </Item>
  );
};

const Item = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : '1px solid #e4e4e4'};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 5rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0 1rem;
  position: relative;

  > label {
    right: 0.5rem;
    position: absolute;
    display: ${({ insurance }) =>
      insurance === 'allMobile' ? 'none' : 'flex'};
  }

  > svg > path {
    fill: #c7c7c7;
  }

  p {
    font-size: ${({ insurance }) =>
      insurance === 'allMobile' ? '1.4rem' : '1.4rem'};
    margin-left: 1.5rem;
  }
`;

export default SafetySystemItem;
