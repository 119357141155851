import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { retrieveLicensePlateGan } from '../helpers/apiRouterService';
import useRouting from '../hooks/useRouting';
import LoadingActionButton from '../components/LoadingActionButton';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowCarLicensePlateSearchMobile = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const [text, setText] = useState('');
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { cars } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [defaultValues, setDefaultValues] = useState({});
  const [licensePlateError, setLicensePlateError] = useState();

  useEffect(() => {
    const question = {
      message: i18n.t("What's your <span>registration number</span>?"),
      slug: 'car_license_plate',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  function handleInputChange(e) {
    setLicensePlateError();
    const code = e.keyCode || e.which;
    if (code === 13) {
      handleSearch();
    }
    setText(e.currentTarget.value);
  }

  const loadDefaultValues = async () => {
    setDefaultValues(cars?.[currentVehicle]);
  };

  useEffect(() => {
    if (defaultValues?.license_plate != null) {
      setText(defaultValues?.license_plate);
    }
  }, [defaultValues]);

  async function handleSearch(e) {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const [resp, status] = await retrieveLicensePlateGan(text);
    if (status === 204) {
      setLicensePlateError(
        'Please enter a valid registered registration number'
      );
      setLoading(false);
      return;
    } else if (status !== 200) {
      setLoading(false);
      setLicensePlateError(
        'Something went wrong while looking up your registration number. Please try again.'
      );
      return;
    }

    let car_information = {
      license_plate: text,
      vehicle_info: resp,
    };

    patchStorage({
      payload: car_information,
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      msg: i18n.t(`The registration number is`, {
        licensePlate: text.toUpperCase(),
      }),
      slug: 'car_license_plate',
    };

    setLoading(false);
    addAnswer(answer);
    renderNextRoute(1, { vehicle: currentVehicle });
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <AddressToInsureQ>
          <LicenseLookup>
            <LicenseInputContainer>
              <LicenseInputLetter>CY</LicenseInputLetter>
              <LicenseInput
                onChange={handleInputChange}
                onKeyPress={handleInputChange}
                value={text}
                type="text"
              />
            </LicenseInputContainer>
            {licensePlateError && (
              <LicenseError>{licensePlateError}</LicenseError>
            )}
          </LicenseLookup>
        </AddressToInsureQ>
        <ButtonContainer>
          {loading ? (
            <LoadingActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_loading"
            >
              {i18n.t('Loading')}
            </LoadingActionButton>
          ) : (
            <ActionButton
              type="submit"
              value="Confirm"
              onClick={handleSearch}
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Search')}
            </ActionButton>
          )}
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const AddressToInsureQ = styled.div`
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 70% 30%;
  overflow: hidden;
  width: 100%;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const LicenseError = styled.div`
  color: #f74040;
  text-align: center;
  margin-bottom: 1rem;
`;

const LicenseLookup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const LicenseInputContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const LicenseInputLetter = styled.div`
  align-items: center;
  background-color: #fbfbfb;
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: 0;
  color: #5b5550;
  display: flex;
  flex: 0 0 5rem;
  font-size: 2.5rem;
  font-weight: 900;
  height: 5rem;
  justify-content: center;
  line-height: 4.6rem;
  width: 5rem;
`;

const LicenseInput = styled.input`
  border: 1px solid #e4e4e4;
  border-radius: 0 0.5rem 0.5rem 0;
  flex: 1 0 auto;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  width: 19rem;

  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.brand.primary};
    outline: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

export default FlowCarLicensePlateSearchMobile;
