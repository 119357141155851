import React from 'react';

const MobileGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#024284" />
          <stop offset="0.81" stopColor="#004587" />
          <stop offset="0.82" stopColor="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.89"
            cy="55.93"
            r="2.77"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeMiterlimit: '10',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <rect
            x="15.26"
            y="26.67"
            width="49.09"
            height="26.27"
            rx="3.7"
            transform="translate(79.61 0) rotate(90)"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeMiterlimit: '10',
              strokeWidth: '2.4816000000000003px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default MobileGanIcon;
