import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import { object, bool, func } from 'prop-types';
import { ganGetAddress } from '../helpers/apiRouterService';
import { TextInput } from 'wg-fe-ui';
import SearchSelectInputV2 from './SearchSelectInputV2';
import cogoToast from 'cogo-toast';

const FlowAddressContainer = ({
  errors,
  values,
  handleChange,
  defaultValues,
  mobile,
}) => {
  const [addressValues, setAddressValues] = useState(values);
  const [streetOptions, setStreetOptions] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setAddressValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    //called once when page loads
    //not called again until given in address AND number, and hitting enter => called when all values are filled in
    //called twice in total: when page loads, and when the address is given in and the API call is done to fill in the remaining inputs

    if (addressValues) {
      const selectedObj = {};
      Object.keys(addressValues).forEach((key) => {
        handleChange({ name: key, value: addressValues[key] });
        const selectedItem = {
          label: addressValues[key],
          value: addressValues[key],
        };
        if (key === 'postalCode' && addressValues[key]) {
          handleZipcodeValidation(addressValues[key]);
        }
        selectedObj[key] = selectedItem;
      });
      setSelected(selectedObj);
    }
  }, [addressValues]);

  const handleSelected = (value) => {
    const selectedVal = { label: value?.value, value: value?.value };
    const tmpSelected = { ...selected };
    tmpSelected[value.name] = selectedVal;
    setSelected(tmpSelected);
    handleChange(value);
  };

  const handleZipcodeValidation = async (_postalCode) => {
    let postalCode = values?.postalCode;
    if (_postalCode) {
      postalCode = _postalCode;
    }
    if (postalCode && postalCode.length === 4) {
      const [resp] = await ganGetAddress({
        ZipCode: parseInt(postalCode),
      });
      setStreetOptions([]);
      if (!_postalCode) {
        handleSelected({ name: 'streetName', value: undefined });
      }
      handleChange({
        name: 'municipalityName',
        value: resp?.Data?.[0]?.Area,
      });
      setStreetOptions(
        resp?.Data?.map((item) => ({
          label: item?.StreetName,
          value: item?.StreetName,
          Oid: item?.Oid,
          FullAddress: item?.FullAddress,
        })).sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      );
      if (!resp?.Data || !resp?.Data?.length || resp.Error) {
        cogoToast.error(i18n.t('Area code not found'));
      }
    }
  };

  return (
    <>
      <FlexWrapper mobile={mobile}>
        <TextInput
          error={errors.postalCode}
          onChange={handleChange}
          type="number"
          name="postalCode"
          dataTestId="underwriting_address_postalCode"
          value={values['postalCode']}
          onBlur={() => handleZipcodeValidation()}
          placeholder="zipcode"
        >
          {i18n.t('Postal code')} *
        </TextInput>
        <TextInput
          disabled
          id="municipalityName"
          name="municipalityName"
          onChange={handleChange}
          value={values?.municipalityName}
        >
          {i18n.t('Area')} *
        </TextInput>
      </FlexWrapper>
      <FlexWrapper mobile={mobile}>
        <SearchSelectInputV2
          isClearable
          error={errors.streetName}
          options={streetOptions}
          disabled={!values?.postalCode || !streetOptions.length}
          id="streetName"
          name="streetName"
          onSelected={(value) => handleSelected(value)}
          value={
            streetOptions?.find((item) => item.value === values?.streetName) ||
            undefined
          }
        >
          {i18n.t('Street')} *
        </SearchSelectInputV2>
        <FlexWrapper>
          <TextInput
            error={errors.streetNumber}
            onChange={handleChange}
            type="text"
            name="streetNumber"
            disabled={values['streetName'] === undefined}
            value={
              values['streetNumber'] && values['streetName']
                ? values['streetNumber']
                : ''
            }
          >
            {i18n.t('Housenr')} *
          </TextInput>
          <TextInput
            error={errors.boxNumber}
            onChange={handleChange}
            type="string"
            name="boxNumber"
            disabled={values['streetName'] === undefined}
            value={values['boxNumber'] ? values['boxNumber'] : ''}
          >
            {i18n.t('Boxnr')}
          </TextInput>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper mobile={mobile}>
        <TextInput
          error={errors.buildingName}
          onChange={handleChange}
          type="string"
          name="buildingName"
          value={values['buildingName'] ? values['buildingName'] : ''}
        >
          {i18n.t('Building name')}
        </TextInput>
      </FlexWrapper>
    </>
  );
};
const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ mobile }) => (mobile ? `column` : `row`)};

  ${({ mobile }) =>
    mobile
      ? null
      : `& > div + div {
    margin-left: 1rem;
  }`}
`;

FlowAddressContainer.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
  title: object,
  sendAddress: func,
  manual: bool,
};

export default FlowAddressContainer;
