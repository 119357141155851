import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import LogRocket from 'logrocket';
import '../assets/css/cogoToast.css';
import FlowChatBox from '../components/FlowChatBox';
import useRouting from '../hooks/useRouting.js';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router';
import {
  captchaAuth,
  ganAuth,
  ganGetCustomerById,
  ganGetLegalCustomerById,
} from '../helpers/apiRouterService';
import { patchStorage } from '../helpers/storeService';
import { ChatContext } from '../contexts/ChatContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ActionButton, TextInput } from 'wg-fe-ui';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';
import { formatISODate } from '../helpers/dateService';
import { id_number } from '../constants/validationSchemas.js';
import cogoToast from 'cogo-toast';
import FlowPolicyHolderPersonRadio from '../components/FlowPolicyHolderPersonRadio';
import { useFlowStore } from '../contexts/FlowContext';

const FlowPolicyHolderLogin = () => {
  const { renderNextRoute } = useRouting();
  const [, flowDispatch] = useFlowStore();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { id, affinity, insuranceType } = useParams();
  const [askEmailExistingCustomer, setAskEmailExistingCustomer] = useState(
    false
  );
  const { addAnswer, addQuestion } = useContext(ChatContext);

  const validationSchema = Yup.object().shape({
    idNumber: id_number,
  });

  const [personType, setPersonType] = useState('');
  const { errors, values, handleChange, handleSubmit } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'If you can give us your <span>ID number</span>, well look for your data'
      ),
      slug: 'existing-user',
    };

    addQuestion(question);
  }, []);

  function onFormSubmit() {
    handleSubmit(() => onSubmit(values, personType));
  }

  const onSubmit = async (values, personType) => {
    const { firstName, lastName, email, company_name, idNumber } = values || {};

    // Call ganAuth to get a JWT and put it in sessionStorage
    const [, ganstatus] = await ganAuth();
    if (ganstatus !== 200) return;

    let customresponse;
    if (idNumber) {
      // if idNumber is set, get customer info from Gan
      let getCustomer = async () => {};
      if (personType === 'legal') getCustomer = ganGetLegalCustomerById;
      else getCustomer = ganGetCustomerById;
      const [customresp, customstatus] = await getCustomer(idNumber);
      // if customer exists, use this customer data
      if (customstatus === 200) {
        customresponse = customresp;
        // if customer exists but no email from customer, ask for it
        if (!customresp?.Email && !email) {
          return setAskEmailExistingCustomer(true);
        } else if (askEmailExistingCustomer) {
          setAskEmailExistingCustomer(false);
        }
      } else {
        cogoToast.error(
          i18n.t(
            `A ${
              personType === 'natural' ? 'natural person' : 'legal entity'
            } with id number ${idNumber} wasn't found.`
          )
        );
      }
    } else {
      // // create customer
    }
    const tempValues = cloneDeep(values);

    // Fix so gan data is placed in storage
    if (customresponse?.FirstName) {
      tempValues.firstName = customresponse?.FirstName;
      tempValues.lastName = customresponse?.LastName;
      if (customresponse?.DateOfBirth) {
        tempValues.birth = formatISODate(customresponse?.DateOfBirth);
      }
    }

    authenticate(
      firstName,
      lastName,
      email,
      company_name,
      customresponse,
      tempValues,
      !!idNumber
    );
    LogRocket.identify('Email Login', {
      email,
      name: `${tempValues.firstName} ${tempValues.lastName}`,
    });
  };

  const authenticate = async (
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    company_name = undefined,
    customresp = undefined,
    values = undefined,
    isGan = true
  ) => {
    const captcha = await executeRecaptcha('captchaAuth');
    const [, status] = await captchaAuth({
      captcha,
      email: customresp?.Email ? customresp?.Email : email,
      firstName: customresp
        ? customresp?.FirstName
        : company_name
        ? affinity
        : firstName,
      lastName: customresp
        ? customresp?.LastName
        : company_name
        ? 'company_name'
        : lastName,
      askBrokerId: '52fc1647-afac-474b-92ee-36e51a87a1b7',
    });

    if (status !== 200) return;

    function upperCaseFirstLetter(string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
    }

    const answer = {
      message: i18n.t("Hi it's me", {
        firstName: upperCaseFirstLetter(values?.firstName || company_name),
      }),
      slug: 'verify-user',
    };

    addAnswer(answer);

    if (values?.primary === 'yes') {
      patchStorage({
        payload: { driver_information: values },
        id,
        affinity,
        insuranceType,
      });
    }

    let addressValues;
    if (customresp) {
      const filteredAddresses = customresp?.AddressesList?.filter(
        (item) => item.Mailing
      );
      if (filteredAddresses.length > 0) {
        addressValues = {
          municipalityName: filteredAddresses[0]?.Address?.City?.City,
          streetName: filteredAddresses[0]?.Address?.StreetName,
          postalCode: filteredAddresses[0]?.Address?.ZipCode,
          streetNumber:
            filteredAddresses[0]?.HouseNumber ||
            filteredAddresses[0]?.StreetNumber ||
            '',
          boxNumber: null,
        };
      }
    }

    if (addressValues?.municipalityName) {
      patchStorage({
        payload: addressValues,
        path: ['policy_holder_address'],
        id,
        affinity,
        insuranceType,
      });
    }

    if (isGan) {
      flowDispatch({
        type: 'ADD_GAN_CUSTOMER',
        payload: customresp,
      });
      patchStorage({
        payload: customresp,
        path: ['ganCustomer'],
        id,
        affinity,
        insuranceType,
      });
    } else {
      flowDispatch({
        type: 'ADD_NEW_CUSTOMER',
        payload: values,
      });
      patchStorage({
        payload: values,
        path: ['customer'],
        id,
        affinity,
        insuranceType,
      });
    }

    renderNextRoute();
  };

  return (
    <Container>
      <FlowChatBox />
      <CustomerInformationContainer>
        <FlowPolicyHolderPersonRadio
          personType={(val) => setPersonType(val)}
          checked={personType}
        />
        {personType && (
          <FlexWrapper>
            <TextInput
              name="idNumber"
              error={errors.idNumber}
              onChange={(val) => handleChange(val)}
              value={values.idNumber}
            >
              <InputWrapper>
                {personType === 'natural'
                  ? i18n.t('ID number')
                  : i18n.t('Company Registration Number')}{' '}
                *
              </InputWrapper>
            </TextInput>
          </FlexWrapper>
        )}
        <ButtonContainer>
          <StyledActionButton
            disabled={!personType}
            type="submit"
            value="Next"
            onClick={onFormSubmit}
            data-test-id="policy_holder_flow_submit"
          >
            {i18n.t('Submit')}
          </StyledActionButton>
        </ButtonContainer>
      </CustomerInformationContainer>
    </Container>
  );
};

const InputWrapper = styled.p`
  height: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & button {
    margin-left: auto;
    width: 100%;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;

const CustomerInformationContainer = styled.div`
  align-content: flex-end;
  align-self: end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
`;

export default FlowPolicyHolderLogin;
