import React, { useEffect, Suspense } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  Label,
  TextInput,
  DateInputV4,
  IconActionChevronLeft,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import i18n from '../i18n';

import { string } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router';

const FlowHealthInformationForm = ({ handleFormValues, defaultValues }) => {
  const history = useHistory();
  const SignupSchema = Yup.object().shape({
    DateOfRecentBloodPressureReading: Yup.date()
      .max(new Date(), i18n.t('Date cannot be in the future'))
      .required(i18n.t('Required')),
    BloodPresureResult: string.required,
    MedicationTaken: string.required,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t('Health information')} </CatTitle>
        <FlexWrapper>
          <DateInputV4
            name="DateOfRecentBloodPressureReading"
            error={errors.DateOfRecentBloodPressureReading}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.DateOfRecentBloodPressureReading}
          >
            <StyledLabel>
              {i18n.t('When was your latest blood reading')}? *
            </StyledLabel>
          </DateInputV4>
          <TextInput
            name="BloodPresureResult"
            error={errors.BloodPresureResult}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.BloodPresureResult}
          >
            <StyledLabel>{i18n.t('Blood pressure result')} *</StyledLabel>
          </TextInput>
        </FlexWrapper>
        <FlexWrapper>
          <TextInput
            name="MedicationTaken"
            error={errors.MedicationTaken}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.MedicationTaken}
          >
            <StyledLabel>
              {i18n.t('What medication have you taken')}? *
            </StyledLabel>
          </TextInput>
        </FlexWrapper>
        <Required>* {i18n.t('Required fields')}</Required>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <StyledActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  & > div + div {
    /* width: 48%; */
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > div + div {
      /* width: 48%; */
      margin-left: 0;
    }
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.text};
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

export default FlowHealthInformationForm;
