import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useParams } from 'react-router-dom';
import {
  ActionButton,
  Label,
  SearchSelectInput,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import useRouting from '../hooks/useRouting';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';

import { string } from '../constants/validationSchemas.js';
import {
  duration_time,
  column_material_options,
  // claim_options,
  roof_options,
  walls_options,
  floor_options,
  heating_method_options,
  heating_pipes_options,
} from '../constants/FlowSearchSelectData';

import { add, isFuture, isToday, parseISO, differenceInDays } from 'date-fns';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';

const FlowHomeInsuranceSpecificsMobile = () => {
  const { renderNextRoute } = useRouting();

  const columnMaterialOptions = useMemo(() =>
    column_material_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [column_material_options]
    )
  );
  const durationTime = useMemo(() =>
    duration_time.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [duration_time]
    )
  );
  const roofOptions = useMemo(() =>
    roof_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [roof_options]
    )
  );
  const wallsOptions = useMemo(() =>
    walls_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [walls_options]
    )
  );
  const floorOptions = useMemo(() =>
    floor_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [floor_options]
    )
  );
  const heatingMethodOptions = useMemo(() =>
    heating_method_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [heating_method_options]
    )
  );
  const heatingPipesOptions = useMemo(() =>
    heating_pipes_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [column_material_options]
    )
  );

  const { id, affinity, insuranceType, currentProperty } = useParams();

  function handleFuture(value) {
    return isToday(parseISO(value)) || isFuture(parseISO(value));
  }

  function handleFutureDays(value) {
    return differenceInDays(parseISO(value), new Date()) <= 35;
  }

  function handleOtherEndDate(value) {
    return (
      differenceInDays(parseISO(value), parseISO(this.parent.starting_date)) <=
      365
    );
  }

  const SignupSchema = Yup.object().shape({
    insurance_duration: Yup.mixed().required(i18n.t(`required`)),
    starting_date: Yup.mixed()
      .test(
        'valid-start-date',
        i18n.t('The start date must be today or in the future'),
        handleFuture
      )
      .test(
        'validate-start-date-days',
        i18n.t('The start date cannot be more than 35 days from today'),
        handleFutureDays
      ),
    ending_date: Yup.string().when('insurance_duration', {
      is: 0,
      then: string.required.test(
        'validate-end-date',
        i18n.t('The end date cannot be more than 1 year after the start date'),
        handleOtherEndDate
      ),
    }),
    // claims: string.required,
    WaterPipes: string.required,
    column_material: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    roof: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    walls: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    floor: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    heating_method: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
  });

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { risk_addresses } = data;
    defaultValuesHandler(risk_addresses);
  }, []);

  function defaultValuesHandler(data) {
    Object.keys(data || {}).forEach((name) => {
      handleChange({ name, value: data[name] });
    });
  }

  const submitHandler = () => {
    patchStorage({
      payload: {
        policy_details: {
          ending_date: values.ending_date
            ? values.ending_date
            : add(new Date(values?.starting_date), {
                months: values?.insurance_duration,
              }).toISOString(),
          starting_date: new Date(values?.starting_date)?.toISOString(),
          insurance_duration: values?.insurance_duration,
          // claims: values?.claims,
        },
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });

    patchStorage({
      payload: {
        construction_details: {
          column_material: values?.column_material,
          roof: values?.roof,
          walls: values?.walls,
          floor: values?.floor,
          heating_method: values?.heating_method,
        },
      },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <Title>{i18n.t('Specifics')}</Title>
      <SpecificsContainer>
        <p>{i18n.t('Policy details')}</p>
        <div>
          <div>
            <StyledLabel>{i18n.t('Duration')} *</StyledLabel>
            <SelectInput
              name="insurance_duration"
              error={errors.insurance_duration}
              value={handleSelectValue(durationTime, 'insurance_duration')}
              onChange={(val) => {
                handleSelectChange(val, 'insurance_duration');
              }}
              options={durationTime}
              placeholder=""
            />
          </div>
          <div>
            <StyledLabel>{i18n.t('Starting date')} *</StyledLabel>
            <DateInput
              name="starting_date"
              error={errors.starting_date}
              onChange={handleChange}
              value={values.starting_date}
            />
          </div>
        </div>
        <p>{i18n.t('Construction Materials')}</p>
        <div>
          <div>
            <SearchSelectInputV2
              isMulti
              withCheckmark
              name="column_material"
              error={errors.column_material}
              value={values?.column_material}
              onChange={(e) => {
                handleChange({ name: 'column_material', value: e });
              }}
              options={columnMaterialOptions}
              placeholder="Choose an option"
            >
              {i18n.t('Column')} *
            </SearchSelectInputV2>
          </div>
          <div>
            <SearchSelectInputV2
              isMulti
              withCheckmark
              name="roof"
              error={errors.roof}
              value={values?.roof}
              onChange={(e) => {
                handleChange({ name: 'roof', value: e });
              }}
              options={roofOptions}
              placeholder="Choose an option"
            >
              {i18n.t('Roof')} *
            </SearchSelectInputV2>
          </div>
          <div>
            <SearchSelectInputV2
              isMulti
              withCheckmark
              name="walls"
              error={errors.roof}
              value={values?.walls}
              onChange={(e) => {
                handleChange({ name: 'walls', value: e });
              }}
              options={wallsOptions}
              placeholder="Choose an option"
            >
              {i18n.t('Walls')} *
            </SearchSelectInputV2>
          </div>
          <div>
            <SearchSelectInputV2
              name="floor"
              isMulti
              error={errors.floor}
              options={floorOptions}
              value={values?.floor}
              onChange={(e) => {
                handleChange({ name: 'floor', value: e });
              }}
              placeholder="Choose an option"
            >
              {i18n.t('Floor')} *
            </SearchSelectInputV2>
          </div>
        </div>
        <p>{i18n.t('Heating')}</p>
        <div>
          <div>
            <SearchSelectInputV2
              isMulti
              withCheckmark
              name="heating_method"
              error={errors.heating_method}
              value={values?.heating_method}
              onChange={(e) => {
                handleChange({ name: 'heating_method', value: e });
              }}
              options={heatingMethodOptions}
              placeholder="Choose an option"
            >
              {i18n.t('Heating method')} *
            </SearchSelectInputV2>
          </div>
        </div>
        <p>{i18n.t('Water Pipes')}</p>
        <div>
          <div>
            <SearchSelectInputV2
              name="WaterPipes"
              error={errors.WaterPipes}
              value={values?.WaterPipes}
              onChange={(e) => {
                handleChange({ name: 'WaterPipes', value: e });
              }}
              options={heatingPipesOptions}
              placeholder="Choose an option"
            >
              {i18n.t('Water Pipes')} *
            </SearchSelectInputV2>
          </div>
        </div>
      </SpecificsContainer>
      <ButtonContainer>
        <ActionButton
          onClick={() => handleSubmit(submitHandler)}
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t('Next')}
        </ActionButton>
      </ButtonContainer>
    </Container>
  );
};

const SelectInput = styled(SearchSelectInput)`
  min-height: 7rem;
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-size: 1.5rem;
`;

const SpecificsContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  > div {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    > div {
      grid-column: span 6 / auto;
      height: 9rem;
    }
  }
  > p {
    color: #30373d;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 550;
    font-size: 1.6rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  width: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.brand.primary};
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
`;

export default FlowHomeInsuranceSpecificsMobile;
