import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { ActionButton } from 'wg-fe-ui';
import FlowChatBox from '../components/FlowChatBox';
import { financial_institutions } from '../constants/FinancialInstitutionsData';
import SearchSelectInputV3 from '../components/SearchSelectInputV3';

const FlowRiskAddressMortagedMobile = () => {
  const { addQuestion } = useContext(ChatContext);
  const { renderNextRoute } = useRouting();
  const [isNoClicked, setIsNoClicked] = useState(false);
  const { id, affinity, insuranceType, currentProperty } = useParams();

  const validationSchema = Yup.object().shape({
    property_mortaged: Yup.boolean().required(
      i18n.t('This question is required')
    ),
    financial_institution: Yup.string().when('property_mortaged', {
      is: true,
      then: Yup.string().required(),
    }),
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t('Is this property mortgaged?'),
      slug: 'risk-address-mortaged-mobile',
    };
    addQuestion(question);
    loadInitialValues();
  }, []);

  const loadInitialValues = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    if (data?.risk_addresses[currentProperty]?.FinancialInstitution) {
      handleChange({
        name: 'financial_institution',
        value: data?.risk_addresses[currentProperty]?.FinancialInstitution,
      });
    }
    if (
      data?.risk_addresses[currentProperty]?.property_mortaged !== undefined
    ) {
      handleChange({
        name: 'property_mortaged',
        value: data?.risk_addresses[currentProperty]?.property_mortaged,
      });
    }
  };

  useEffect(() => {
    if (values.property_mortaged === false && isNoClicked) {
      handleSubmit(submitHandler);
    }
  }, [values.property_mortaged, isNoClicked]);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  const submitHandler = async (values) => {
    const payload = {};
    const { financial_institution } = values;
    payload.property_mortaged = values.property_mortaged;
    payload.FinancialInstitution = values.property_mortaged
      ? financial_institution
      : null;
    patchStorage({
      payload: payload,
      path: ['risk_addresses', currentProperty],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { property: currentProperty });
  };

  console.log(errors);

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={handleForm}>
        <AddressToInsureQ>
          <StyledButton
            active={values.property_mortaged}
            onClick={(e) => {
              e.preventDefault();
              handleChange({ name: 'property_mortaged', value: true });
            }}
          >
            {i18n.t('Yes')}
          </StyledButton>
          <StyledButton
            active={values.property_mortaged === false}
            onClick={(e) => {
              e.preventDefault();
              handleChange({ name: 'property_mortaged', value: false });
              setIsNoClicked(true);
            }}
          >
            {i18n.t('No')}
          </StyledButton>
        </AddressToInsureQ>
        {values.property_mortaged && (
          <div>
            <SelectInput
              isClearable
              name="financial_institution"
              error={errors.financial_institution}
              value={handleSelectValue(
                financial_institutions,
                'financial_institution'
              )}
              onSelected={({ value }) => {
                handleChange({
                  name: 'financial_institution',
                  value: value ? value : '',
                });
              }}
              options={financial_institutions}
              placeholder="Choose an option"
            >
              {i18n.t('Select the financial institution')}
            </SelectInput>
          </div>
        )}
        {errors.property_mortaged && (
          <RequiredMessage>{errors.property_mortaged}</RequiredMessage>
        )}
        {!!values.property_mortaged && (
          <StyledActionButton
            type="submit"
            value="Confirm"
            data-test-id="risk_address_flow_submit"
            onClick={(e) => handleForm(e)}
          >
            {i18n.t('Next')}
          </StyledActionButton>
        )}
      </RightContainer>
    </Container>
  );
};

const RequiredMessage = styled.p`
  color: ${({ theme }) => theme.status.error};
  margin-top: 1.6rem;
`;

const StyledActionButton = styled(ActionButton)`
  margin-top: 2rem;
`;

const SelectInput = styled(SearchSelectInputV3)`
  margin-top: 2rem;
  ${'' /* & label {
    color: #8990a3;
  }
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
    line-height: 125%;
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
  .Select__control {
    border-color: ${({ error }) => !error && '#e4e4e4'} !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
  .Select__menu {
    margin-top: -20rem;
  } */}
`;

// const SelectInput = styled(SearchSelectInput)`
//   min-height: 7rem;
//   margin-top: 2rem;
//   & label {
//     color: #8990a3;
//   }
//   & .Select__option {
//     font-size: 1.6rem;
//     color: ${({ theme }) => theme.typo.text};
//     line-height: 125%;
//   }

//   & .Select__option--is-focused {
//     color: ${({ theme }) => theme.typo.text};
//     font-weight: 500;
//     background-color: ${({ theme }) => theme.brand.lighter};
//     :hover {
//       color: white;
//       background-color: ${({ theme }) => theme.brand.secondary};
//     }
//   }
//   & div {
//     margin-bottom: 0;
//   }
//   .Select__control {
//     border-color: ${({ error }) => !error && '#e4e4e4'} !important;
//   }
//   .Select__indicator-separator {
//     background-color: #e4e4e4 !important;
//   }
//   .Select__menu {
//     margin-top: -20rem;
//   }
// `;

const AddressToInsureQ = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 48% 48%;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  font-size: 1.4rem;
  height: 4rem;
  &:focus {
    outline: 0;
    background-color: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
  }
`;

const RightContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;
export default FlowRiskAddressMortagedMobile;
