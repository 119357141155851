import React from 'react';
import {
  Route,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom';
import { doesFlowExist } from '../helpers/tagRouterService';
import { retrieveStorageById } from '../helpers/storeService';

const FlowRouterRedirect = ({ from, to, id, loginPath }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { affinity, insuranceType } = useParams();
  if (!id && pathname !== loginPath) {
    if (doesFlowExist({ affinity, insuranceType })) {
      history.push(`/${affinity}/${insuranceType}/${loginPath}`);
    } else {
      history.push(`/${affinity}/${insuranceType}/not-possible`);
    }
    return null;
  }

  if (doesFlowExist({ affinity, insuranceType })) {
    return (
      <Route
        render={() =>
          retrieveStorageById(id, affinity, insuranceType) ? (
            <Redirect
              exact
              strict
              from={from}
              to={`/${affinity}/${insuranceType}/session/${to}/${id}`}
            />
          ) : (
            <Redirect
              to={{
                pathname: `/${affinity}/${insuranceType}/verify-user`,
              }}
            />
          )
        }
      />
    );
  } else {
    history.push(`/${affinity}/${insuranceType}/not-possible`);
    return null;
  }
};
export default FlowRouterRedirect;
