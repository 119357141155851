import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { retrieveCarInfo } from '../helpers/apiRouterService.js';
import { LoadingSpinner } from 'wg-fe-ui/dist';
import { getNestedObject } from '../helpers/objectService.js';
import GanDirectAvatar from '../assets/images/gan-direct-avatar.svg';

import FlowSearchForVehicleForm from '../components/FlowSearchForVehicleForm.jsx';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';

import i18n from '../i18n';

const FlowSearchForVehicle = () => {
  const [carBrandImage, setCarBrandImage] = useState(GanDirectAvatar);
  const [carInfo, setCarInfo] = useState({});
  const [versionValue, setVersionValue] = useState();

  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);

    if (Object.keys(carInfo).length > 0) {
      carInfo.version = versionValue;

      if (data.vehicleType !== undefined && data.vehicleType === '6WHEELS') {
        patchStorage({
          payload: { carInfo: carInfo },
          id,
          affinity,
          insuranceType,
        });
      } else {
        patchStorage({ payload: carInfo, id, affinity, insuranceType });
      }
      renderNextRoute();
    }
  }, [carInfo]);

  function handleBrandLogo(img) {
    if (!img) {
      setCarBrandImage(GanDirectAvatar);
    } else {
      setCarBrandImage(img);
    }
  }

  function handleManual() {
    renderNextRoute();
  }

  function handleSubmit(carInfo, brand, model) {
    if (!carInfo) return;
    const { version, model_year, kw } = carInfo;
    setVersionValue(version);

    carInfo = loadCarData(brand, model, version, model_year, kw);
  }

  const loadCarData = async (brand, model, version, model_year, kw) => {
    setLoading(true);

    if (brand && model && version && model_year && kw) {
      const [resp, status] = await retrieveCarInfo(
        brand,
        model,
        version,
        model_year,
        kw
      );
      if (status !== 200) {
        setLoading(false);
        setCarInfo({ brand, model, version, model_year, kw });
      }

      const { technical, carValue } = resp || {};

      const values = {
        car_img: resp.img !== undefined ? resp.img : carBrandImage,
        brand: getNestedObject(technical, ['labels', 'manufacturer'], ''),
        model: getNestedObject(technical, ['labels', 'model'], ''),
        type: getNestedObject(technical, ['labels', 'type'], ''),
        model_year: getNestedObject(technical, ['model_year'], ''),
        kw: getNestedObject(technical, ['kw'], ''),
        motorType: getNestedObject(technical, ['motorType'], ''),
        seats: getNestedObject(technical, ['seats'], ''),
        options: getNestedObject(carValue, ['options'], ''),
        catalogue_value: getNestedObject(carValue, ['exclVAT'], 0)
          ? getNestedObject(carValue, ['exclVAT'], 0).toFixed(2)
          : 0,
      };

      setLoading(false);
      setCarInfo(values);
    } else {
      setLoading(false);
      setCarInfo({ brand, model, version, model_year, kw });
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'Next I need some information about your car You can fill it out here on the right'
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowSearchForVehicleForm
          defaultValues={defaultValues}
          handleBrandLogo={handleBrandLogo}
          handleSubmit={handleSubmit}
          handleManual={handleManual}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 71% 25%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: '';
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  margin: auto;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FlowSearchForVehicle;
