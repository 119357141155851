export default [
  {
    title: 'user-address',
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: 'select-insurances',
    },
  },
  {
    title: 'select-insurances',
    step: 1,
    subStep: 2,
    nextRoutes: {
      2: 'car-already-registered',
    },
  },
  {
    title: 'health-information',
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: 'doctor-information',
      2: 'blood-information',
      3: 'hba1c-test-information',
    },
  },
  {
    title: 'blood-information',
    step: 2,
    subStep: 2,
    nextRoutes: {
      1: 'doctor-information',
      2: 'hba1c-test-information',
    },
  },
  {
    title: 'hba1c-test-information',
    step: 2,
    subStep: 4,
    nextRoutes: {
      1: 'doctor-information',
    },
  },
  {
    title: 'doctor-information',
    step: 2,
    subStep: 5,
    nextRoutes: {
      1: 'parental-information',
    },
  },
  {
    title: 'parental-information',
    step: 5,
    subStep: 6,
    nextRoutes: {
      1: 'health-insurance-policy',
    },
  },
  {
    title: 'health-insurance-policy',
    step: 5,
    subStep: 7,
    nextRoutes: {
      1: 'health-insurance-policy-information',
      2: 'general-information',
      3: 'health-specifics',
    },
  },
  {
    title: 'health-insurance-policy-information',
    step: 5,
    subStep: 8,
    nextRoutes: {
      1: 'general-information',
    },
  },
  {
    title: 'general-information',
    step: 2,
    subStep: 8,
    nextRoutes: {
      1: 'health-specifics',
    },
  },
  {
    title: 'health-specifics',
    step: 5,
    subStep: 9,
    nextRoutes: {
      1: 'insurance-options-health',
    },
  },
  {
    title: 'insurance-options-health',
    step: 6,
    subStep: 0,
    nextRoutes: {
      1: 'health-premium-summary',
    },
  },
  {
    title: 'health-premium-summary',
    step: 6,
    subStep: 1,
    nextRoutes: {
      1: 'health-information',
      2: 'overview',
    },
  },
  {
    title: 'overview',
    step: 7,
    subStep: 0,
    nextRoutes: {
      1: 'thankyou',
    },
  },
  {
    title: 'thankyou',
    step: 4,
    subStep: 1,
    nextRoutes: {
      1: 'user-address',
    },
  },
];