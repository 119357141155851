import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowCarExistingVehicleInsuranceQuestionMobile = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { renderNextRoute } = useRouting();
  const [, setDefaultValues] = useState({});
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { cars } = data;
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [, setError] = useState();
  const [currentCarInsuranceState, setCurrentCarInsuranceState] = useState();
  const [currentCarInsuranceKnown, setCurrentCarInsuranceKnown] = useState();
  const [selectedLabel, setSelectedLabel] = useState();

  const answers = [
    { label: i18n.t('Yes, and I know the insurer'), value: true },
    { label: i18n.t('Yes, but I dont know the insurer'), value: true },
    { label: i18n.t('No'), value: false },
  ];

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Do you currently have a <span>car insurance policy</span>?'
      ),
      slug: 'existing_vehicle_insurance',
    };
    addQuestion(question);
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    let defaultValuesTemp;

    defaultValuesTemp = {
      currently_have_property_insurance: getNestedObject(data, [
        'cars',
        [currentVehicle],
        'currently_have_property_insurance',
      ]),
      insurances: data.insurances,
    };
    setDefaultValues(defaultValuesTemp);
    if (defaultValuesTemp?.currently_have_property_insurance != null) {
      setCurrentCarInsuranceState(
        defaultValuesTemp?.currently_have_property_insurance
      );
    }
  };

  const handlePropertyInsurance = (e, answer) => {
    e.preventDefault();
    setCurrentCarInsuranceState(answer.value);
    setSelectedLabel(answer.label);

    if (
      answer.label === 'Yes, but I dont know the insurer' ||
      answer.label === 'No'
    ) {
      setCurrentCarInsuranceKnown(false);
    } else {
      setCurrentCarInsuranceKnown(true);
    }
  };

  useEffect(() => {
    if (currentCarInsuranceState == null) {
      setError('Please answer the question above');
      return;
    } else {
      setError();
    }

    if (
      !cars[currentVehicle].currently_have_property_insurance ===
        currentCarInsuranceState ||
      cars[currentVehicle].currently_have_property_insurance == null
    ) {
      patchStorage({
        payload: {
          currently_have_property_insurance: currentCarInsuranceState,
        },
        path: ['cars', [currentVehicle]],
        id,
        affinity,
        insuranceType,
      });
    }

    const answer = {
      message: i18n.t(selectedLabel),
      slug: 'existing_car_insurance',
    };

    //TODO FIX NO FLOW

    addAnswer(answer);
    if (
      currentCarInsuranceState === true &&
      currentCarInsuranceKnown === true
    ) {
      renderNextRoute(1, { property: currentVehicle });
    } else if (currentCarInsuranceKnown === false) {
      patchStorage({
        payload: {
          insurance_company_name: 'None',
        },
        path: ['cars', [currentVehicle]],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute(2, { property: currentVehicle });
    }
  }, [currentCarInsuranceKnown]);

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <form>
          {answers.map((answer) => {
            return (
              <StyledActionButton
                type="submit"
                checked={selectedLabel === answer.label}
                onClick={(e) => {
                  handlePropertyInsurance(e, answer);
                }}
              >
                {answer.label}
              </StyledActionButton>
            );
          })}
        </form>
      </RightContainer>
    </Container>
  );
};

const StyledActionButton = styled.button`
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.primary : 'white'};
  border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ checked, theme }) => (checked ? '#FFFFFF' : theme.brand.primary)};
  cursor: pointer;
  font-weight: 550;
  height: 3.5rem;
  width: 100%;
  &:focus {
    outline: 0;
  }

  margin-bottom: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  padding: 2rem;

  form {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default FlowCarExistingVehicleInsuranceQuestionMobile;
