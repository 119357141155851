/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';

import Checkmark from '../assets/icons/checkmark.js';

interface Props {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (arg: Record<string, string | boolean>) => void;
  name: string;
}

const CheckBox: React.FC<Props> = ({
  className,
  checked,
  disabled,
  onChange,
  children,
  name,
  ...otherProps
}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(
    checked ? true : false
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      e?.persist();
      setIsChecked(e.currentTarget.checked);
      onChange({ name: e.target.name, value: e.currentTarget.checked });
    }
  };

  useEffect(() => {
    if (isChecked !== checked) {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <StyledLabel
      htmlFor={name}
      disabled={!!disabled}
      className={className}
      {...otherProps}
    >
      <input
        name={name}
        id={name}
        disabled={disabled}
        checked={isChecked}
        type="checkbox"
        onChange={(e) => handleChange(e)}
      />
      <StyledBox disabled={disabled} checked={isChecked}>
        {isChecked && disabled ? (
          <Checkmark color={'#ccc'} />
        ) : isChecked && !disabled ? (
          <Checkmark color={'white'} />
        ) : null}
      </StyledBox>
      {children}
    </StyledLabel>
  );
};

const StyledBox = styled.div<{ checked?: boolean; disabled?: boolean }>`
  background-color: ${({ theme, checked, disabled }) =>
    checked && !disabled ? theme.brand.primary : '#F0F1F3'};
  width: 1.9rem;
  min-width: 1.9rem;
  height: 1.9rem;
  border: ${(props) =>
    props.checked && !props.disabled
      ? null
      : props.disabled
      ? '0.1rem solid #CCCCCC'
      : '0.1rem solid #A29C95'};
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-right: 1rem;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.checked ? null : '#F7F8F9')};
  }
`;

const StyledLabel = styled.label<{ disabled?: boolean }>`
  font-family: ${({ theme }) => theme.font};
  font-size: 1.4rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) =>
    disabled ? theme.ui.disabled : theme.typo.text};

  & input {
    display: none;
  }
`;

CheckBox.displayName = 'CheckBox';

CheckBox.defaultProps = {
  checked: false,
  disabled: false,
};

CheckBox.propTypes = {
  /** Beeing able to use it in Styled Components */
  className: string,
  /** If true box is disabled */
  disabled: bool,
  /** If true box is initialy checked */
  checked: bool,
  /** Triggered when button is clicked */
  onChange: func.isRequired,
  /** Adds extra props to the element */
  name: string.isRequired,
};

export default CheckBox;
