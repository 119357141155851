import React from 'react';

const attention_assist_sphere = ({ colors }) => (
  <svg viewBox="0 0 400 400" width="100%">
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors}
    />
    <g fill="#fff">
      <path d="m166.83 163.57a6.88 6.88 0 0 1 -6-3.45l-6.17-10.74a19.29 19.29 0 0 1 7.11-26.3 5.49 5.49 0 0 0 2-7.5l-6.17-10.74a6.89 6.89 0 0 1 12-6.84l6.16 10.74a19.25 19.25 0 0 1 -7.11 26.3 5.5 5.5 0 0 0 -2 7.5l6.16 10.73a6.88 6.88 0 0 1 -6 10.32z" />
      <path d="m200.59 163.57a6.88 6.88 0 0 1 -6-3.45l-6.16-10.74a19.27 19.27 0 0 1 7.11-26.3 5.5 5.5 0 0 0 2-7.5l-6.16-10.74a6.89 6.89 0 0 1 11.98-6.84l6.17 10.74a19.27 19.27 0 0 1 -7.11 26.3 5.5 5.5 0 0 0 -2 7.5l6.17 10.73a6.89 6.89 0 0 1 -6 10.32z" />
      <path d="m234.35 163.57a6.88 6.88 0 0 1 -6-3.45l-6.17-10.74a19.29 19.29 0 0 1 7.11-26.3 5.49 5.49 0 0 0 2-7.5l-6.17-10.74a6.89 6.89 0 0 1 12.01-6.84l6.16 10.74a19.27 19.27 0 0 1 -7.11 26.3 5.51 5.51 0 0 0 -2 7.5l6.17 10.73a6.88 6.88 0 0 1 -6 10.32z" />
      <path d="m274.22 206.09h-13.22v-24.59h-124v62a62 62 0 0 0 62 62 62 62 0 0 0 61.43-53.81h13.8a22.79 22.79 0 0 0 0-45.57zm0 31.79h-13.22v-18h13.25a9 9 0 1 1 0 18z" />
    </g>
  </svg>
);

export default attention_assist_sphere;
