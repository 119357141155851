import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import CarEcoGan from '../assets/icons/CarEcoGan';
import CarPremGanIcon from '../assets/icons/CarPremGanIcon';
import CarBasicGanIcon from '../assets/icons/CarBasicGanIcon';
import CarValueGanIcon from '../assets/icons/CarValueGanIcon';
import {
  ActionButton,
  IconActionChevronLeft,
  IconStatusCheck,
  LoadingSpinner,
} from 'wg-fe-ui';
import { useParams, useHistory } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { createCarPolicyGan } from '../helpers/apiRouterService';
import { useQuery } from 'react-query';
import { handlePolicyStartDate } from '../helpers/dateService';

const getPayload = (_data, selected, currentVehicle) => {
  let payload = {
    SavePolicy: _data?.SavePolicy || 'False',
    Customer: {
      Oid: _data.ganCustomer.Oid,
    },
    VehicleOrVessel: 0,
    Vehicle: {
      Oid: _data.cars[currentVehicle].vehicle_info.Oid,
    },
    MotorStatus: 1,
    ExistingInsuranceCompany: {
      InsuranceCompany: _data?.cars[currentVehicle]?.insurance_company_name,
    },
    ExpiringDateOfYourCurrentPolicy: _data?.cars[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars[currentVehicle]?.start_of_contract || null
      : null,
    ExistingInsuranceCompanyPrice: _data?.cars[currentVehicle]
      ?.insurance_company_name
      ? _data?.cars[currentVehicle]?.insurance_price || null
      : null,
    PolicyHolderAsDriver: _data.cars[currentVehicle].main_driver?.same_as_policy
      ? 1
      : 0,
    MotorDrivers: [
      {
        Oid: _data.cars[currentVehicle].main_driver?.same_as_policy
          ? _data?.ganCustomer?.Oid
          : _data?.cars?.[currentVehicle]?.main_driver?.Oid,
      },
      ..._data?.cars?.[currentVehicle]?.extra_drivers?.map((item) => ({
        Oid: item?.Oid,
      })),
    ],
    EuroCompPackageBenefit: _data?.EuroCompPackageBenefit?.value || '',
    LossOfUseBenefit: _data?.LossOfUseBenefit?.value || '',
    GuranteeAssetProtectionBenefit:
      _data?.GuranteeAssetProtectionBenefit?.value || '',
    ClaimProtectionBenefit: _data?.ClaimProtectionBenefit || '',
    RiotsStrikesBenefit: _data?.RiotsStrikesBenefit?.value || '',
    ConvulsionOfNatureBenefit: _data?.ConvulsionOfNatureBenefit?.value || '',
    PersonalEffectsBenefit: _data?.PersonalEffectsBenefit || '',
    MedicalExpensesBenefit: _data?.MedicalExpensesBenefit?.value || '',
    StayMobile: _data.cars[currentVehicle]?.replacement_vehicle ? 1 : 0,
    DrivingOtherCarsBenefit: _data?.DrivingOtherCarsBenefit || '',
    UnlimitedWindscreenBenefit: _data?.UnlimitedWindscreenBenefit || '',
    ExcessProtectionBenefit: _data?.ExcessProtectionBenefit?.value || '',
    Trailer: _data?.Trailer?.value || 0,
    TrailerChassisNumber: '',
    Relay: _data?.Relay || 0,
    PolicyStartDate: handlePolicyStartDate(
      _data.cars[currentVehicle]?.policy_details?.starting_date
    ),
    PolicyEndDate: new Date(
      _data.cars[currentVehicle]?.policy_details?.ending_date
    ).toISOString(),
    PolicyDuration:
      _data.cars[currentVehicle]?.policy_details?.insurance_duration,
    VehicleCoverType: 2,
    VehicleExcess: 250,
    InsuredAmount: parseInt(
      _data.cars[currentVehicle]?.vehicle_info?.invoice_value
    ),
  };

  switch (selected) {
    case 'Basic Plus':
      payload.VehicleCoverType = 0;
      break;
    case 'Economic':
      payload.VehicleCoverType = 1;
      break;
    case 'Value':
      payload.VehicleCoverType = 2;
      break;
    case 'Premium':
      payload.VehicleCoverType = 2;
      payload.EuroCompPackageBenefit = 1;
      break;
    default:
      payload.VehicleCoverType = 9;
      break;
  }
  return payload;
};

const BasicPlusDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: 'Relay' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: '24hr Glass replacement' },
];

const EconomicPackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Unlimited Windscreen' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Loss of use' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: '24hr Glass replacement' },
];

const ValuePackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Unlimited Windscreen' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Guarantee Asset Protection' },
  { value: 'Personal Effects' },
  { value: 'Excess Protection' },
  { value: 'Convulsion of nature' },
  { value: 'Riots & Strikes' },
  { value: 'Loss of use' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Claim Protection' },
  { value: 'Driving other cars' },
  { value: 'Hire purchase, Loan or long term rental agreement' },
  { value: 'Own damage for the Insured Vehicle' },
  { value: '24hr Glass replacement' },
];

const PremiumPackageDetails = [
  {
    value:
      'Bodily Injury or Death to Third Parties and Passengers up to €36,540,000',
  },
  { value: 'Third Party Liability for Property Damage up to €1,220,000' },
  { value: 'Medical Expenses for the Authorized Driver(s) up to €2,000' },
  { value: 'Small Accident Forgiveness up to €1,200' },
  { value: 'Passengers Liability to Third Parties' },
  { value: 'Beyond the “Road” use' },
  { value: 'Legal Protection' },
  { value: 'Penal Bail' },
  { value: '24hr Roadside & Business Assistance' },
  { value: '24hr Claims Assistance Free Courtesy Car (K2K)' },
  { value: 'Fire' },
  { value: 'Theft' },
  { value: 'Attempted theft' },
  { value: 'Guarantee Asset Protection' },
  { value: 'Excess Protection' },
  { value: 'Convulsion of nature' },
  { value: 'Trailer' },
  { value: 'Relay' },
  { value: 'Stay mobile' },
  { value: 'Hire purchase, Loan or long term rental agreement' },
  { value: 'Own damage for the Insured Vehicle' },
  { value: '24hr Glass replacement' },
  { value: 'Replacement of Motor Vehicle with New' },
  { value: 'Claim Protection' },
  { value: 'Natural hazards' },
  { value: 'Riots & Strikes' },
  { value: 'Personal Effects' },
  { value: 'Loss of use' },
  { value: 'Driving other cars' },
  { value: 'Unlimited Windscreen' },
];

const packages = [
  {
    value: 'Basic Plus',
    description:
      'Basic Plus Insurance Policy covers you against all sums including claimant’s costs and expenses which the Insured or any Authorized Driver shall become legally liable to pay in respect of death or bodily injury to any person, emergency treatment to any person or damage to property that may arise out of an accident caused by the use of the Insured Vehicle',
    benefits: BasicPlusDetails,
  },
  {
    value: 'Economic',
    description:
      'Economic Insurance Policy covers you against all sums including claimant’s costs and expenses which the Insured or any Authorized Driver shall become legally liable to pay in respect of death or bodily injury to any person, emergency treatment to any person or damage to property that may arise out of an accident caused by the use of the Insured Vehicle. It also covers you in case of fire, external explosion, self-ignition, lightning or burglary, theft or attempted theft to the Motor vehicle and its accessories and spare parts whilst thereon',
    benefits: EconomicPackageDetails,
  },
  {
    value: 'Value',
    description:
      'Value Insurance Policy covers you against the damages you may cause to a third party, against Fire & Theft and also against accidental damage to your own Motor Vehicle. It completely covers your own damage and the third party’s damage',
    benefits: ValuePackageDetails,
  },
  {
    value: 'Premium',
    description:
      'Premium Insurance Policy is a special deal package that consists of all the covers included in Value Policy plus many more additional benefits which are otherwise offered at an extra cost',
    benefits: PremiumPackageDetails,
  },
];

const FlowInsuranceOptions = () => {
  const [errorText, setErrorText] = useState();
  const history = useHistory();
  const { renderNextRoute } = useRouting();
  const [policyData, setPolicyData] = useState({});
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [selected, setSelected] = useState();

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createCarPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(storage?.data, selected?.value, currentVehicle)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };
  const { data, isLoading, failureCount, error, isFetching } = useQuery(
    ['policyData', selected?.value, storage?.data],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
    }
  );

  useEffect(() => {
    if (selected != null) {
      setErrorText();
    }
  }, [selected]);

  useEffect(() => {
    const packageIndex = packages.findIndex(
      (pack) =>
        pack.value === storage?.data?.cars[currentVehicle]?.insurance_pack
    );
    if (packageIndex > -1) setSelected(packages[packageIndex]);
  }, []);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected?.value]: data });
    }
  }, [data, selected, failureCount]);

  function iconType(icon) {
    switch (icon) {
      case 'Basic Plus': //terraced
        return <CarBasicGanIcon />;
      case 'Economic': //semi-detached
        return <CarEcoGan />;
      case 'Value':
        return <CarValueGanIcon />;
      case 'Premium':
        return <CarPremGanIcon />;
      default:
        return <CarValueGanIcon />;
    }
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const submitHandler = () => {
    if (selected == null) {
      setErrorText('Please choose an insurance package');
      return;
    } else {
      setErrorText();
    }
    patchStorage({
      payload: {
        insurance_pack: selected?.value,
        insurance_info: policyData[selected?.value]?.Data,
      },
      path: ['cars', currentVehicle],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute(1, { vehicle: currentVehicle });
  };

  // const handleNext = () => {
  //   if (selected == null) {
  //     setErrorText('Please choose an insurance package');
  //     return;
  //   } else {
  //     setErrorText();
  //   }
  //   patchStorage({
  //     payload: {
  //       insurance_pack: selected?.value,
  //       insurance_info: policyData[selected?.value]?.Data,
  //     },
  //     id,
  //     affinity,
  //     insuranceType,
  //   });
  //   renderNextRoute(2, { vehicle: currentVehicle });
  // };

  return (
    <Container>
      <LeftContainer>
        <div>
          <TitleForm>{i18n.t('Choose your package')}</TitleForm>
          <PackagesContainer>
            {packages?.map((insurance) => {
              return (
                <Extra
                  selected={selected?.value === insurance.value}
                  onClick={() => setSelected(insurance)}
                >
                  <span>{iconType(insurance.value)}</span>
                  <p>{insurance.value}</p>
                </Extra>
              );
            })}
          </PackagesContainer>
        </div>
        <Error>{errorText}</Error>
        {/* <CompareButton onClick={() => handleNext()}>
          See how Gan compares against other insurers
        </CompareButton> */}
      </LeftContainer>
      <RightContainer>
        {error?.toString()?.includes(selected?.value) &&
          !policyData[selected?.value] &&
          !isFetching && (
            <ErrorBox>
              <p>Something went wrong while fetching your quote</p>
            </ErrorBox>
          )}
        {data?.Error && (
          <ErrorBox>
            <p>Something went wrong while fetching your quote</p>
            <p>{data?.Description}</p>
          </ErrorBox>
        )}
        {isLoading || (isFetching && !policyData[selected?.value] && error) ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : policyData[selected?.value] ? (
          <>
            {selected != null && (
              <>
                <TopHalf>
                  <Title>{selected.value}</Title>
                  <Description>{selected.description}</Description>
                </TopHalf>

                <SubTitle>{selected?.value} package includes:</SubTitle>
                <EconomicPackageDetailsContainer>
                  {selected?.benefits?.map((detail) => {
                    return (
                      <DetailContainer>
                        <IconStatusCheck />
                        <p>{detail.value}</p>
                      </DetailContainer>
                    );
                  })}
                </EconomicPackageDetailsContainer>
              </>
            )}
            <ButtonContainer>
              <TotalPremium>
                <p>{i18n.t('Your total premium')}</p>
                <span>
                  {policyData[selected?.value]?.Data?.TotalwithFees} &euro;
                </span>
              </TotalPremium>
              <div>
                <BackButton type="button" name="back" onClick={goBack}>
                  <IconActionChevronLeft />
                  {i18n.t('Back')}
                </BackButton>
                <ActionButton
                  onClick={() => submitHandler()}
                  type="submit"
                  value="Submit"
                  data-test-id="drivers_information_submit"
                >
                  {i18n.t('Specify')}
                </ActionButton>
              </div>
            </ButtonContainer>
          </>
        ) : null}
      </RightContainer>
    </Container>
  );
};

// const CompareButton = styled.div`
//   color: ${({ theme }) => theme.brand.primary};
//   cursor: pointer;
//   font-size: 1.6rem;
//   font-weight: 550;
//   letter-spacing: 0.02em;
//   line-height: 2rem;
//   text-decoration-line: underline;
// `;

const Error = styled.div`
  font-size: 1.2rem;
  color: #f74040;
  margin-bottom: 1rem;
  text-align: left;
`;

const TotalPremium = styled.div`
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    color: #8990a3;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
  span {
    color: #0e0e0e;
    font-size: 2rem;
    font-weight: 550;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const EconomicPackageDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0;

  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const DetailContainer = styled.span`
  display: flex;
  margin-bottom: 0.3rem;
  width: 100%;
  p {
    align-self: center;
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.3rem;
    width: 90%;
  }
  svg {
    height: 3rem;
    margin-right: 1rem;
    width: 5rem;
    > path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    color: rgba(48, 55, 61, 0.66);
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.p`
  margin-top: 3rem;

  color: #30373d;
  font-size: 1.8rem;
  font-weight: 550;
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected, theme }) =>
    selected ? `3px solid ${theme.brand.primary}` : '1px solid #e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, theme }) =>
    selected ? theme.brand.primary : '#cfcece'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: space-evenly;
  > p {
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
  }
  span > svg {
    height: 7rem;
    width: 7rem;
  }
  @media (max-width: 1000px) {
    height: 12rem;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  border-right: 1px solid #f5f6f7;
  padding-right: 4rem;
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;

  overflow: auto;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 2.8rem;
  flex-shrink: 0;
  margin-bottom: 4rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ErrorBox = styled(LoadingBox)`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  line-height: 120%;
`;
export default FlowInsuranceOptions;
