import React from "react";
import { shape } from "prop-types";

const BrokerBackground = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1616 1120"
    style={{ width: `100%`, height: `100%` }}
  >
    <rect style={{ fill: `none` }} width="1440" height="900" />
    <rect
      style={{ fill: `${colors.brand.dark}` }}
      x="962.11"
      y="2.37"
      width="335.55"
      height="595.78"
      rx="36.45"
      ry="36.45"
      transform="translate(538.69 1308.87) rotate(-74.51)"
    />
    <rect
      style={{ fill: `${colors.brand.primary}` }}
      x="968.83"
      y="12.37"
      width="320.48"
      height="575.42"
      rx="24.3"
      ry="24.3"
      transform="translate(538.26 1307.94) rotate(-74.51)"
    />
    <rect
      style={{ fill: `${colors.brand.dark}` }}
      x="101.56"
      y="289.57"
      width="26.87"
      height="26.87"
      transform="translate(-180.57 170.06) rotate(-45)"
    />
    <path
      style={{ fill: `${colors.brand.secondary}` }}
      d="M1268,1120c192.2,0,348-155.8,348-348s-155.8-348-348-348S920,579.8,920,772,1075.8,1120,1268,1120Z"
      transform="translate(0)"
    />
    <path
      style={{ fill: `${colors.brand.secondary}` }}
      d="M112.53,782a44.82,44.82,0,1,0-44.82-44.82A44.82,44.82,0,0,0,112.53,782Z"
      transform="translate(0)"
    />
    <path
      style={{ fill: `${colors.brand.dark}`, fillRule: `evenodd` }}
      d="M374,861.51a22.5,22.5,0,1,0-22.5-22.5A22.5,22.5,0,0,0,374,861.51Z"
      transform="translate(0)"
    />
    <rect
      style={{ fill: `${colors.brand.dark}` }}
      x="1317.74"
      y="45.31"
      width="26.87"
      height="26.87"
      transform="translate(348.35 958.49) rotate(-45)"
    />
  </svg>
);

BrokerBackground.propTypes = {
  colors: shape().isRequired
};

export default BrokerBackground;
