import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import SafetySystemItem from '../components/SafetySystemItem';
import i18n from '../i18n';
import RiskObjectExtra from '../components/RiskObjectExtra';
import _ from 'lodash';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowManualVehicleExtra = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [chosenExtras, setChosenExtras] = useState([]);
  const [chosenSafeties, setChosenSafeties] = useState([]);
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const history = useHistory();

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    setDefaultValues(cars[currentVehicle].car_extras);
  };

  const CarTypes = [
    { value: 'SUV' },
    { value: 'Sportscar' },
    { value: 'Cabrio' },
  ];

  const SafetySystems = [
    { value: 'Automatic Emergency Braking (AEB)' },
    { value: 'Adaptive Cruise Control' },
    { value: 'Lane support' },
    { value: 'Blind Spot Monitor' },
    { value: 'Attention Assist' },
    { value: 'eCall' },
    { value: 'Automatic Parking' },
    { value: 'Parking Aid' },
  ];

  function handleExtras({ value, name }) {
    let arr = [];
    if (value) {
      arr = [...chosenExtras, name];
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    arr = _.uniq(arr);
    setChosenExtras(arr);
  }

  function handleSafetySystems({ value, name }) {
    let arr = [];
    if (value) {
      arr = [...chosenSafeties, name];
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    arr = _.uniq(arr);
    setChosenSafeties(arr);
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    const payload = {
      car_type: chosenExtras,
      safety_systems: chosenSafeties,
    };

    patchStorage({
      payload: { car_extras: payload },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { vehicle: currentVehicle });
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={formSubmitHandler}>
        <Scroll>
          <TitleForm>
            <h2>{i18n.t('Extras')}</h2>
            <p>{i18n.t('Select the right tags')}</p>
          </TitleForm>
          <ExtrasContainer>
            {CarTypes.map((car) => {
              return (
                <RiskObjectExtra
                  onChange={(val) => {
                    handleExtras(val);
                  }}
                  selected={defaultValues?.car_type}
                  value={car.value}
                />
              );
            })}
          </ExtrasContainer>
          <TitleForm>
            <h2>{i18n.t('Safety systems')}</h2>
          </TitleForm>
          <SafetySystemContainer>
            {SafetySystems.map((safety) => {
              return (
                <SafetySystemItem
                  onChange={(val) => {
                    handleSafetySystems(val);
                  }}
                  selected={defaultValues?.safety_systems}
                  value={safety.value}
                />
              );
            })}
          </SafetySystemContainer>
        </Scroll>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
            {i18n.t('Next')}
          </ActionButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const SafetySystemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;

  @media only screen and (max-width: 1250px) {
    grid-template-columns: 95%;
    row-gap: 0;
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const ExtrasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.2rem;

  > div {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const TitleForm = styled.header`
  margin-bottom: 2rem;

  h2 {
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 125%;
    color: ${({ theme }) => theme.brand.primary};
  }

  p {
    color: #aeaeae;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 125%;
  }
`;

const RightContainer = styled.form`
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

export default FlowManualVehicleExtra;
