import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import '../assets/css/cogoToast.css';
import FlowChatBox from '../components/FlowChatBox';
import { useHistory, useParams } from 'react-router';
import { patchStorage, initializeStorage } from '../helpers/storeService';
import { ChatContext } from '../contexts/ChatContext';
import { generateUUID } from '../helpers/uuidService';
import LanguageSelector from '../components/LanguageSelector';

const LoginMobile = () => {
  const history = useHistory();
  const { affinity, insuranceType } = useParams();
  const [id, setId] = useState(null);
  const { addAnswer, addQuestion, clearChat } = useContext(ChatContext);

  const [customerType, setCustomerType] = useState();

  useEffect(() => {
    const tempId = generateUUID();
    setId(tempId);
    initializeStorage(tempId, affinity, insuranceType);
    resetChat();
  }, []);

  useEffect(() => {
    if (customerType === 'New') {
      const answer = {
        message: i18n.t('Hi, im a <span>new customer</span>'),
        slug: 'customer_type',
      };
      addAnswer(answer);

      patchStorage({
        payload: { customer_type: customerType },
        id,
        affinity,
        insuranceType,
      });

      history.push(`/${affinity}/${insuranceType}/session/user-info/${id}`);
    } else if (customerType === 'Existing') {
      const answer = {
        message: i18n.t('Yes'),
        slug: 'customer_type',
      };
      addAnswer(answer);

      patchStorage({
        payload: { customer_type: customerType },
        id,
        affinity,
        insuranceType,
      });

      history.push(`/${affinity}/${insuranceType}/session/login/${id}`);
    }
  }, [customerType]);

  const resetChat = async () => {
    await clearChat();
    const question = {
      message: i18n.t(
        'Hello I am Gan In order to calculate your personalised premium please answer the following questions Did we meet before'
      ),
      slug: 'verify-user-mobile',
    };
    addQuestion(question);
  };

  return (
    <Container>
      <LanguageSelector onChange={resetChat} />
      <FlowChatBox />
      <CustomerInformationContainer>
        <StyledButton
          active={customerType === 'New'}
          onClick={(e) => {
            e.preventDefault();
            setCustomerType('New');
          }}
        >
          {i18n.t('New Customer')}
        </StyledButton>
        <StyledButton
          active={customerType === 'Existing'}
          onClick={(e) => {
            e.preventDefault();
            setCustomerType('Existing');
          }}
        >
          {i18n.t('Existing Customer')}
        </StyledButton>
      </CustomerInformationContainer>
    </Container>
  );
};

const CustomerInformationContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 1rem;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 100%;
  padding: 2rem;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  height: 4.5rem;
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  font-weight: bold;
  font-size: 1.4rem;
  height: 5rem;
  &:focus {
    outline: 0;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

export default LoginMobile;
