import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { getNestedObject } from '../helpers/objectService';
import { retrieveAddressInfo } from '../helpers/apiRouterService';
import { useFlowStore } from '../contexts/FlowContext';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';
import FlowChatBox from '../components/FlowChatBox';

const FlowRiskAddressMobile = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const [PHAddressIsToBeInsured, setPHAddressIsToBeInsured] = useState(true);
  const [policyHolderAddress, setPolicyHolderAddress] = useState();
  const [flowStore] = useFlowStore();
  const { addGanRiskAddress } = flowStore || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
    buildingName: string.notRequired,
  });

  const { handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const question = {
      message: i18n.t(
        'Lets start with <span>the first building</span> Is this the address you wish to insure?'
      ),
      slug: 'risk-address-mobile',
    };
    addQuestion(question);
  }, []);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };

    if (data?.risk_addresses?.[currentProperty] != null) {
      const riskAddress =
        getNestedObject(data, [
          'risk_addresses',
          [currentProperty],
          'address',
        ]) || {};
      const {
        street,
        city,
        zipcode,
        boxnr,
        housenr,
        buildingName,
      } = riskAddress;
      const tempData = {
        streetName: street,
        municipalityName: city,
        postalCode: zipcode,
        boxNumber: boxnr,
        streetNumber: housenr,
        buildingName: buildingName,
      };
      Object.keys(tempData).forEach((key) =>
        tempData[key] === undefined ? delete tempData[key] : {}
      );
      setPolicyHolderAddress(tempData);
    } else {
      const riskAddress =
        getNestedObject(data, ['policy_holder_address']) || {};
      const {
        streetName,
        municipalityName,
        postalCode,
        boxNumber,
        streetNumber,
        buildingName,
      } = riskAddress;
      const tempData = {
        streetName: streetName,
        municipalityName: municipalityName,
        postalCode: postalCode,
        boxNumber: boxNumber,
        streetNumber: streetNumber,
        buildingName: buildingName,
      };
      Object.keys(tempData).forEach((key) =>
        tempData[key] === undefined ? delete tempData[key] : {}
      );
      setPolicyHolderAddress(tempData);
    }
  }, []);

  useEffect(() => {
    defaultValuesHandler();
  }, [PHAddressIsToBeInsured]);

  useEffect(() => {
    if (PHAddressIsToBeInsured) {
      setDefaultValues(policyHolderAddress);
    } else {
      setDefaultValues({});
      defaultValuesHandler({});
    }
  }, [PHAddressIsToBeInsured]);

  useEffect(() => {
    defaultValuesHandler();
  }, [policyHolderAddress]);

  function defaultValuesHandler() {
    if (PHAddressIsToBeInsured && policyHolderAddress != null) {
      Object.keys(policyHolderAddress || {}).forEach((name) => {
        handleChange({ name, value: policyHolderAddress[name] });
      });
    } else {
      Object.keys(defaultValues || {}).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (values) => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    if (!PHAddressIsToBeInsured) {
      const element = {
        message: i18n.t(
          'No, I would like to insure <span>another address</span>'
        ),
        slug: 'ph_address_risk_address',
      };
      addAnswer(element);
      renderNextRoute(2, { property: currentProperty });
      return;
    }
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
      buildingName,
    } = policyHolderAddress;

    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: 'CY',
      boxnr: boxNumber,
      city: municipalityName,
    });

    const [respGan] = await addGanRiskAddress(
      {
        StreetName: streetName,
        HouseNumber: streetNumber.toLowerCase(),
        ZipCode: postalCode,
        BuildingName: buildingName,
      },
      data?.ganCustomer?.Oid
    );

    if (status / 100 === 2) {
      const element = {
        message: {
          address: resp.address,
          blankImage: resp.aerial_images_blank_satellite,
          image: resp.aerial_images_marked_satellite,
        },
        slug: 'address_response',
      };
      addAnswer(element);
      delete resp?.parcel?.main_building?.surface;
      delete resp?.parcel?.main_building?.house_type;

      patchStorage({
        payload: { ...resp, ...respGan },
        path: ['risk_addresses', currentProperty],
        id,
        affinity,
        insuranceType,
      });
    } else {
      const tempResp = {
        parcel: {
          main_building: {},
        },
      };
      patchStorage({
        payload: { ...tempResp, ...respGan },
        path: ['risk_addresses', currentProperty],
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute(1, { property: currentProperty });
    }
    setLoading(false);
    renderNextRoute(1, { property: currentProperty });
  };

  return (
    <Container>
      <FlowChatBox />
      <RightContainer onSubmit={handleForm}>
        <div>
          <AddressContainer>
            <HomeCatGanIcon />
            <div>
              <p>{`${policyHolderAddress?.streetName} ${policyHolderAddress?.streetNumber},`}</p>
              <p>{`${policyHolderAddress?.postalCode} ${policyHolderAddress?.municipalityName}`}</p>
            </div>
          </AddressContainer>
          <AddressToInsureQ>
            {loading ? (
              <StyledLoadingActionButton
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t('Loading')}
              </StyledLoadingActionButton>
            ) : (
              <StyledButton
                active={PHAddressIsToBeInsured}
                onClick={() => {
                  setPHAddressIsToBeInsured(true);
                }}
              >
                {i18n.t('Yes')}
              </StyledButton>
            )}
            <StyledButton
              active={!PHAddressIsToBeInsured}
              onClick={() => {
                setPHAddressIsToBeInsured(false);
              }}
            >
              {i18n.t('No')}
            </StyledButton>
          </AddressToInsureQ>
        </div>
      </RightContainer>
    </Container>
  );
};

const StyledLoadingActionButton = styled(LoadingActionButton)`
  @media (max-width: 300px) {
    p {
      margin-right: 1rem;
    }
    min-width: 12rem;
  }
`;

const AddressToInsureQ = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 48% 48%;
  width: 100%;
  justify-content: center;
  padding-top: 2rem;
`;

const StyledButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.brand.primary : '#ffffff'};
  border: ${({ active, theme }) =>
    active ? 'none' : `2px solid ${theme.brand.primary}`};
  border-radius: 5px;
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.brand.primary)};
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  height: 4rem;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
  }
`;

const AddressContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(4, 1fr);
  > svg {
    grid-column: span 1;
    align-self: center;
  }
  > div {
    align-self: center;
    grid-column: span 3;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 550;
    margin-left: 0.5rem;
    > p:last-child {
      color: #8990a3;
    }
  }
`;

const RightContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;
export default FlowRiskAddressMobile;
