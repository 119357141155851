import AllDesktopFlow from './routing-data/desktop/all';
import CarDesktopFlow from './routing-data/desktop/car';
import HomeDesktopFlow from './routing-data/desktop/home';
import HealthDesktopFlow from './routing-data/desktop/health';

import AllMobileFlow from './routing-data/mobile/all';
import CarMobileFlow from './routing-data/mobile/car';
import HomeMobileFlow from './routing-data/mobile/home';
import HealthMobileFlow from './routing-data/mobile/health';

const general = [];
const brandInfo = {
  name: 'Gan Direct',
  logo: 'https://files.wegroup.be/logos/insurances/gan-direct.png',
};

export const ganDirectCar = {
  brand: brandInfo,
  flow: [
    ...general,
    ...CarDesktopFlow,
  ],
};

export const ganDirectAll = {
  brand: brandInfo,
  flow: [
    ...general,
    ...AllDesktopFlow,
  ],
};

export const ganDirectHome = {
  brand: brandInfo,
  flow: [
    ...general,
    ...HomeDesktopFlow,
  ],
};

export const ganDirectHealth = {
  brand: brandInfo,
  flow: [
    ...general,
    ...HealthDesktopFlow,
  ],
};

export const ganDirectAllMobile = {
  brand: brandInfo,
  flow: [
    ...general,
    ...AllMobileFlow,
  ],
};

export const ganDirectCarMobile = {
  brand: brandInfo,
  flow: [
    ...general,
    ...CarMobileFlow,
  ],
};

export const ganDirectHomeMobile = {
  brand: brandInfo,
  flow: [
    ...general,
    ...HomeMobileFlow,
  ],
};

export const ganDirectHealthMobile = {
  brand: brandInfo,
  flow: [
    ...general,
    ...HealthMobileFlow,
  ],
};
