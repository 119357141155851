import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import ExtraInsuranceObject from '../components/ExtraInsuranceObject';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import _ from 'lodash';
import LoadingActionButton from '../components/LoadingActionButton';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';

const FlowInsureExtras = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [error, setError] = useState();
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const [chosenExtras, setChosenExtras] = useState([]);
  const history = useHistory();
  const insuranceExtras = [
    // { value: 'Digital devices' },
    { value: 'Bicycles' },
    // { value: 'Electric Scooter' },
    // { value: 'Drone' },
    // { value: 'Art or other valuables' },
    { value: 'Pets' },
    // { value: 'Jewelry' },
    { value: 'No' },
    // { value: 'Garden' },
  ];

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const question = {
      message: i18n.t(`Would you like to insure anything else?`),
      slug: 'insure_extras',
    };
    addQuestion(question);
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setChosenExtras(
      data?.risk_addresses?.[currentProperty]?.extra_items_to_insure || []
    );
    setDefaultValues(data);
  }, []);

  const submitHandler = () => {
    if (!chosenExtras.length) {
      return setError(i18n.t('Please select at least one option'));
    } else {
      setError();
    }
    setLoading(true);
    patchStorage({
      payload: {
        extra_items_to_insure: chosenExtras,
        ...(!chosenExtras?.includes('Bicycles') ? { Bikes: null } : {}),
        ...(!chosenExtras?.includes('Pets') ? { PetsInProperty: null } : {}),
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });

    if (chosenExtras?.includes('Bicycles'))
      renderNextRoute(2, { property: currentProperty });
    else if (chosenExtras?.includes('Pets'))
      renderNextRoute(3, { property: currentProperty });
    else renderNextRoute(1, { property: currentProperty });

    let answer;
    if (!chosenExtras?.includes('No')) {
      answer = {
        message: i18n.t(`Yes, please insure these extras`),
        slug: 'insure_extras_answer',
      };
    } else {
      answer = {
        message: i18n.t(`No`),
        slug: 'insure_extras_answer',
      };
    }

    addAnswer(answer);
    setLoading(false);
  };

  function handleExtras({ value, name }) {
    let arr = [];
    if (name === 'No' && value) {
      arr = ['No'];
    } else if (value) {
      arr = [...chosenExtras, name];
      arr = arr.filter((item) => item !== 'No');
    } else {
      arr = chosenExtras.filter((extra) => extra !== name);
    }
    if (arr.length) {
      setError();
    }
    arr = _.uniq(arr);
    setChosenExtras(arr);
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }
  return (
    <Container isMobile={isMobile()}>
      {!isMobile() && <FlowChatBox />}
      <RightContainer isMobile={isMobile()}>
        <Scroll>
          <TitleForm>
            {i18n.t('Would you like to insure anything else?')}
          </TitleForm>
          <HouseTypesContainer>
            {insuranceExtras?.map((type) => {
              return (
                <ExtraInsuranceObject
                  selected={defaultValues?.extra_items_to_insure}
                  value={type.value}
                  checked={chosenExtras?.includes(type.value)}
                  onChange={(val) => handleExtras(val)}
                />
              );
            })}
          </HouseTypesContainer>
        </Scroll>
        {error && <ValidationError>{error}</ValidationError>}
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          {loading ? (
            <LoadingActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_loading"
            >
              {i18n.t('Loading')}
            </LoadingActionButton>
          ) : (
            <ActionButton
              type="submit"
              value="Confirm"
              onClick={() => submitHandler()}
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          )}
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const ValidationError = styled.p`
  color: ${({ theme }) => theme.status.error};
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const HouseTypesContainer = styled.div`
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 3rem;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  ${(isMobile) => isMobile && 'padding: 2rem;'}
  @media (max-width: 768px) {
    padding-left: 0;
    ${(isMobile) => isMobile && 'padding: 2rem;'}
    width: 100%;
  }
`;

const Container = styled.div`
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? `auto auto` : `50% 50%`};
  display: grid;
  width: 100%;

  @media (max-width: 768px) {
    display: ${({ isMobile }) => (isMobile ? 'grid' : 'flex')};
  }
`;

export default FlowInsureExtras;
