import React, { Suspense, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  HashRouter as Router,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { verifyInit } from '../helpers/apiRouterService';
import { loggedIn, setLoggedIn } from '../helpers/authService';

import Flow from '../pages/Flow';
import CorsTest from './CorsTest';
import LoadingSpinner from '../components/LoadingSpinner.jsx';
import { doesFlowExist } from '../helpers/tagRouterService';
import { getNestedObject } from '../helpers/objectService';

function RouteFlowMustExist({ component: Component, ...rest }) {
  // Only render component if the flow for that company and insurancetype exists else redirect to not-possible page
  if (
    (rest?.computedMatch?.params?.insuranceType === 'allMobile' ||
      rest?.computedMatch?.params?.insuranceType === 'carMobile' ||
      rest?.computedMatch?.params?.insuranceType === 'homeMobile') &&
    rest.path.includes('verify-user')
  ) {
    setLoggedIn(true);
  }

  return (
    <Route
      {...rest}
      path="/:affinity/:insuranceType/verify-user"
      render={(props) =>
        isMobile &&
        getNestedObject(props, ['match', 'params', 'insuranceType']) ===
          'all' ? ( // If the browser is mobile, but is not on mobile flow, redirect to mobile flow
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/allMobile`,
            }}
          />
        ) : !isMobile && // If the browser is not mobile but it is on the mobile flow, redirect to desktop flow
          getNestedObject(props, ['match', 'params', 'insuranceType']) ===
            'allMobile' ? (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/all`,
            }}
          />
        ) : isMobile &&
          getNestedObject(props, ['match', 'params', 'insuranceType']) ===
            'car' ? ( // If the browser is mobile, but is not on mobile flow, redirect to mobile flow
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/carMobile`,
            }}
          />
        ) : !isMobile && // If the browser is not mobile but it is on the mobile flow, redirect to desktop flow
          getNestedObject(props, ['match', 'params', 'insuranceType']) ===
            'carMobile' ? (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/car`,
            }}
          />
        ) : isMobile &&
          getNestedObject(props, ['match', 'params', 'insuranceType']) ===
            'home' ? ( // If the browser is mobile, but is not on mobile flow, redirect to mobile flow
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/homeMobile`,
            }}
          />
        ) : !isMobile && // If the browser is not mobile but it is on the mobile flow, redirect to desktop flow
          getNestedObject(props, ['match', 'params', 'insuranceType']) ===
            'homeMobile' ? (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/home`,
            }}
          />
        ) : doesFlowExist({
            // Check if the flow exists, if it does, show the flow
            affinity: getNestedObject(props, ['match', 'params', 'affinity']),
            insuranceType: getNestedObject(props, [
              'match',
              'params',
              'insuranceType',
            ]),
          }) ? (
          <Component {...rest} />
        ) : (
          <Redirect // If the flow does not exist, redirect to impossible page
            to={{
              pathname: `/${getNestedObject(props, [
                'match',
                'params',
                'affinity',
              ])}/${getNestedObject(props, [
                'match',
                'params',
                'insuranceType',
              ])}/not-possible`,
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const [authorized, setAuthorized] = useState('initial'); // initial / authenticated / forbidden

  useEffect(() => {
    loggedIn ? setAuthorized('authenticated') : fetchAuthorization();
  }, []);

  async function fetchAuthorization() {
    const [, status] = await verifyInit();

    if (status === 200) setAuthorized('authenticated');
    else setAuthorized('forbidden');
  }

  switch (authorized) {
    case 'initial':
      return <LoadingSpinner />;
    case 'authenticated':
      setLoggedIn(true);
      return <RouteFlowMustExist {...rest} component={Component} />;
    case 'forbidden':
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${getNestedObject(props, [
                  'match',
                  'params',
                  'affinity',
                ])}/${getNestedObject(props, [
                  'match',
                  'params',
                  'insuranceType',
                ])}/verify-user`,
              }}
            />
          )}
        />
      );
    default:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${getNestedObject(props, [
                  'match',
                  'params',
                  'affinity',
                ])}/${getNestedObject(props, [
                  'match',
                  'params',
                  'insuranceType',
                ])}/verify-user`,
              }}
            />
          )}
        />
      );
  }
}

const Main = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router>
        <Switch>
          <Route path="/test-cors" component={CorsTest} exact />
          <RouteFlowMustExist
            path="/:affinity/:insuranceType/verify-user"
            component={Flow}
          />
          <Route
            path="/:affinity/:insuranceType/not-possible"
            component={Flow}
          />
          <PrivateRoute
            path="/:affinity/:insuranceType/session"
            component={Flow}
          />
          <Redirect exact from="/" to="/gan-direct/all" />
          <Route
            exact
            path="/:affinity/:insuranceType"
            render={(props) => (
              <Redirect
                to={`/${getNestedObject(props, [
                  'match',
                  'params',
                  'affinity',
                ])}/${getNestedObject(props, [
                  'match',
                  'params',
                  'insuranceType',
                ])}/session`}
              />
            )}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};
export default Main;
