import React from 'react';
import styled from 'styled-components';
import InfoPopup from './InfoPopup';
import { getBrandData } from '../helpers/tagRouterService';
import GanDirectAvatar from '../assets/images/gan-direct-avatar.svg';

const FlowLeftSideAvatar = ({ chatText, infoHelper, width = '50%' }) => {
  return (
    <LeftSplit width={width}>
      <WideLogo src={getBrandData().logo} alt="" />
      <RoundImage src={GanDirectAvatar} alt="Gan" />
      <TextBalloonWrapper>
        <TextBalloon>
          <p
            className="text"
            dangerouslySetInnerHTML={{ __html: chatText }}
          ></p>
          {infoHelper ? (
            <StyledInfoPopup
              inverted
              title={infoHelper.title}
              info={infoHelper.info}
            />
          ) : null}
        </TextBalloon>
      </TextBalloonWrapper>
    </LeftSplit>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  background-color: white;
  color: #188bee;
  float: right;
`;

const WideLogo = styled.img`
  width: 85%;
  max-width: 40rem;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
`;

const TextBalloonWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  align-self: center;
  flex-direction: column;
`;

const LeftSplit = styled.div`
  width: ${({ width }) => width};
  padding: 5rem 4rem;
  display: grid;
  grid-template-rows: 15rem 30rem auto;
  grid-gap: 30px 0;
  justify-items: center;

  :after {
    content: '';
    width: 1px;
    height: 68%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: ${({ width }) => width};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.img`
  display: block;
  max-height: 35rem;
  margin-bottom: -2rem;
  width: auto;
  align-self: center;
`;

export default FlowLeftSideAvatar;
