import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';
import FlowDriversInformationForm from '../components/FlowDriversInformationForm.jsx';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import {
  ganCreateCustomerIndvdl,
  ganUpdateCustomerIndvdl,
} from '../helpers/apiRouterService';
import cogoToast from 'cogo-toast';

const FlowDriversInformation = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};

  useEffect(() => {
    const question = {
      message: i18n.t(
        'We need some information about the <span>primary driver</span>'
      ),
      slug: 'drivers_information',
    };
    addQuestion(question);
  }, []);

  async function handleSubmit(values) {
    const payload = {
      DateOfBirth: values.DateOfBirth,
      FirstName: values.FirstName,
      LastName: values.LastName,
      DrivingLicenseIssueDate: values.DrivingLicenseIssueDate,
      DrivingLicenseIssueCountry: values.DrivingLicenseIssueCountry,
      DrivingInLeftSiteOfRoadSince: values.DrivingInLeftSiteOfRoadSince,
      CustomerCountry: values.CustomerCountry,
      ResidentInCyprusSince: values.ResidentInCyprusSince,
      Occupation: values.Occupation,
      Email: values.Email,
      IdNo: values?.IdNo,
      ContactTelephoneNumber: values.ContactTelephoneNumber,
      LearnerDrivingLicense: values.LearnerDrivingLicense,
      Title: values.Title,
      MedicalConditionOrDisability: values.MedicalConditionOrDisability,
      YearsOfDisability: values.YearsOfDisability,
    };

    if (!values?.same_as_policy) {
      const defaultPayload = {
        // Personal info
        IncompleteProfile: 1,
        Title: 0,
        MiddleName: '',

        // Driving info
        NumberOfDrivingPoints: 0,
        ReasonForDrivingPoints: {
          Oid: 7,
        },
        AnyLicenseRestrictions: {
          Oid: 8,
        },
        LearnerDrivingLicense: false,

        // Medical info
        MedicalConditionOrDisability: 0,
        YearsOfDisability: '',
      };
      const [resp, status] = await ganCreateCustomerIndvdl({
        ...defaultPayload,
        ...payload,
      });
      if ((status === 200 || status === 201) && !resp?.Error)
        payload.Oid = resp?.Data;
      else {
        return cogoToast.error(resp?.Description);
      }
    } else {
      const [resp, status] = await ganUpdateCustomerIndvdl(
        {
          ...data?.ganCustomer,
          ...payload,
        },
        data?.ganCustomer?.Oid
      );
      if (status !== 200 || resp?.Error) {
        cogoToast.error(resp?.Detail);
      } else {
        patchStorage({
          payload: payload,
          path: ['ganCustomer'],
          id,
          affinity,
          insuranceType,
        });
      }
    }

    patchStorage({
      payload: {
        main_driver: { ...payload, same_as_policy: values?.same_as_policy },
      },
      path: ['cars', [currentVehicle]],
      id,
      affinity,
      insuranceType,
    });

    const answer = {
      message: `<span>${values.FirstName}</span> will be the primary driver`,
      slug: 'drivers_information',
    };
    addAnswer(answer);

    renderNextRoute(1, { vehicle: currentVehicle });
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <FlowDriversInformationForm handleFormValues={handleSubmit} />
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowDriversInformation;
