import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import {
  ToggleInput,
  TextInput,
  SearchSelectInput,
  ActionButton,
  SmallLoader,
} from 'wg-fe-ui';
import _ from 'lodash';

const MobileCoverDetail = ({
  details,
  sendChanges,
  isLoading,
  policyData,
  setShowModal,
  selected,
}) => {
  const [toggleState, setToggleState] = useState(details.toggle);
  const [coverLimit, setCoverLimit] = useState();
  const [sum, setSum] = useState();

  useEffect(() => {
    const _details = { ...details };
    _details.toggle = toggleState;
    if (coverLimit != null) {
      _details.amount = coverLimit;
    }
    if (_details.search && sum != null) {
      _details.amount = sum;
    }
    if (!_.isEqual(details, _details)) sendChanges(_details);
  }, [toggleState, coverLimit, sum]);

  return (
    <BackDrop>
      <Container>
        <HeadTitle>{details?.value}</HeadTitle>
        <CoverLimitContent>
          {details?.toggle != null && (
            <>
              <Cover>{i18n.t('Include in your cover')}</Cover>
              <Title>
                <ToggleInput
                  checked={details.toggle}
                  onChange={() => {
                    setToggleState(!toggleState);
                  }}
                />
              </Title>
            </>
          )}
          {details?.amountOptions != null &&
          (toggleState === true || details.toggle === 'true') ? (
            <CoverContainer>
              <Cover>Cover limit</Cover>
              <SelectInput
                onChange={(val) => setCoverLimit(val)}
                placeholder=""
                value={details?.amount || coverLimit}
                options={details.amountOptions}
              />
            </CoverContainer>
          ) : details?.search && toggleState === true ? (
            <>
              <Cover>Cover limit</Cover>
              <StyledTextInput
                type="number"
                placeholder="Sum insured"
                value={details?.amount}
                onChange={(val) => {
                  setSum(val.value);
                }}
              />
            </>
          ) : details?.toggle != null &&
            toggleState === true &&
            details.toggleOnAmount != null &&
            details.toggleOnAmount !== 'Selected' ? (
            <>
              <Cover>Cover limit</Cover>
              <Title>{details.toggleOnAmount}</Title>
            </>
          ) : (
            details.amount != null &&
            details.amount !== '' &&
            details.amount !== 'Not selected' && (
              <>
                <Cover>Cover limit</Cover>
                <Title>{details.amount}</Title>
              </>
            )
          )}
        </CoverLimitContent>
        <ContinueContainer>
          <PremiumContainer>
            <p>Premium</p>
            <p>
              {isLoading ? (
                <SmallLoader color="" />
              ) : (
                <span>{policyData[selected]?.Data?.TotalwithFees} &euro;</span>
              )}
            </p>
          </PremiumContainer>
          <ContinueActionButton onClick={() => setShowModal(false)}>
            {i18n.t('Back')}
          </ContinueActionButton>
        </ContinueContainer>
      </Container>
    </BackDrop>
  );
};

const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const PremiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8990a3;
  width: 100%;
  margin: 0 auto;
  height: 3rem;
  margin-bottom: 2.4rem;
  > p:first-child {
    font-weight: 550;
  }
  > p {
    font-size: 1.6rem;
  }
`;

const Container = styled.div`
  padding: 2rem;
  background-color: white;
  width: 100%;
  border-radius: 1rem;
`;

const ContinueActionButton = styled(ActionButton)`
  width: 100%;
  margin: 0 auto;
`;

const ContinueContainer = styled.div`
  margin-top: auto;
`;

const Title = styled.p`
  align-items: left;
  color: #8990a3;
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  justify-content: left;
  margin-top: 1rem;
  > div {
    padding: 0;
    > input {
      margin-left: 0;
    }
  }
`;
const Cover = styled(Title)`
  color: #8990a3;
  margin-top: 1.6rem;
  font-size: 1.6rem;
  font-weight: 550;
`;

const StyledTextInput = styled(TextInput)`
  height: unset;
  margin-top: 2rem;
  input {
    font-size: 1.6rem;
    height: 3rem;
    margin: 0;
    width: 80%;
  }
  label {
    align-items: left;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  min-height: ;
  label > div {
    width: 100%;
  }
  .Select {
    &__control {
      height: 3rem;
      border-radius: 0.5rem;
      border: 1px solid #e4e4e4;
      z-index: 10;

      :hover {
        border-color: #e4e4e4;
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : ' #e4e4e4'};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : ' #e4e4e4'};
        }
      }
    }
    &__value-container {
      height: 3rem;
      font-size: 1.4rem;
      > div {
        margin: 0;
      }
    }
    &__input {
    }
    &__menu-list {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 8px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: ${({ theme }) => theme.brand.primary};
      }

      &::-webkit-scrollbar-track-piece {
        background: #f0f1f3;
        border-radius: 5px;
        width: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
      }
    }

    &__menu-notice--no-options,
    &__menu-notice--loading {
      min-height: 4.8rem;
      display: flex;
      align-items: center;
    }

    &__option {
      z-index: 60;
      line-height: 2.1rem;
      color: #8990a3;

      :hover {
        color: ${({ theme }) => theme.brand.primary};
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.grey};
      }

      &--is-focused {
        color: ${({ theme }) => theme.brand.primary};
        background-color: ${({ theme }) => theme.brand.grey};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

const BackDrop = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
`;

const CoverLimitContent = styled.div`
  align-self: left;
  color: #8990a3;
  font-size: 1.6rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 4rem;
`;

const HeadTitle = styled.h1`
  font-size: 2.1rem;
  font-weight: 700;
  padding-bottom: 3.2rem;
  width: 100%;
  margin: 0 auto;
  color: ${({ theme }) => theme.brand.primary};
`;

export default MobileCoverDetail;
