import React from 'react';
import styled from 'styled-components';
import GanDirectAvatar from '../assets/images/gan-direct-avatar.svg';
import AddressResponse from './AddressResponse';
import CarResponse from './CarResponse';
import LicensePlateResBalloon from './LicensePlateResBalloon';

const FlowChatBalloon = ({ left, children, question }) => {
  const timeStamp = new Date().toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <BalloonContainer left={left}>
      {left ? (
        <AvatarContainer>
          <Avatar src={GanDirectAvatar} />
        </AvatarContainer>
      ) : null}
      {!left && children?.address != null ? (
        <Text left={left}>
          <AddressResponse
            address={children?.address}
            blankImage={children?.blankImage}
            image={children?.image}
          />
        </Text>
      ) : !left && children?.car_image != null ? (
        <Text left={left}>
          <CarResponse car={children} />
        </Text>
      ) : !left && children?.car_information != null ? (
        <Text left={left}>
          <p>{children?.msg}</p>
          <LicensePlateResBalloon carInfo={children?.car_information} />
        </Text>
      ) : (
        <Text left={left} dangerouslySetInnerHTML={{ __html: children }} />
      )}
      <TimeStamp left={left}>{timeStamp}</TimeStamp>
    </BalloonContainer>
  );
};

const BalloonContainer = styled.div`
  align-self: ${(props) => (props.left ? 'flex-start' : 'flex-end')};
  color: ${({ left }) => (left ? 'white' : 'black')};
  display: inline-block;
  max-width: 70rem;
  position: relative;
  margin: 0;
  margin-top: 1.5rem;
  opacity: 1;
  text-align: ${({ left }) => (left ? 'left' : 'right')};

  @keyframes animation {
    from {
      margin-bottom: -7.6rem;
      opacity: 0;
    }

    to {
      margin-bottom: 0;
      opacity: 1;
    }
  }
`;

const AvatarContainer = styled.div`
  position: absolute;
  left: -1.5rem;
  bottom: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.brand.secondary};
`;

const Avatar = styled.img`
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  margin-top: -1rem;
`;

const Text = styled.p`
  padding: 1.2rem 1.8rem;
  font-size: 1.6rem;
  line-height: 125%;
  border-radius: ${(props) =>
    props.left ? '2rem 2rem 2rem 0' : '2rem 2rem 0'};
  opacity: 1;
  background-color: ${({ left, theme }) =>
    left ? theme.brand.primary : theme.brand.dark};

  & span {
    font-weight: 600;
  }
  sup {
    font-size: xx-small;
    vertical-align: top;
  }
`;

const TimeStamp = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.brand.primary};
  margin-top: 0.5rem;
  margin-left: ${(props) => (props.left ? '2rem' : '0')};
  font-size: 1.2rem;
  text-align: ${(props) => (props.left ? 'left' : 'right')};
`;

export default FlowChatBalloon;
