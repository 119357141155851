import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { useParams } from 'react-router';
import { retrieveStorageById } from '../helpers/storeService';

import { SearchSelectInput, ActionButton } from 'wg-fe-ui';
import {
  retrieveCarBrands,
  retrieveCarModels,
  retrieveCarVerions,
} from '../helpers/apiRouterService';
import SearchVersionList from './SearchVersionList';

const FlowSearchForVehicleForm = ({
  defaultValues,
  handleBrandLogo,
  handleSubmit,
  handleManual,
}) => {
  const [selectedModel, setSelectedModel] = useState();
  const [powerList, setPowerList] = useState([]);
  const [selectedPower, setSelectedPower] = useState();
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [versionList, setVersionList] = useState([]);
  const [filterdversionList, setFilterdVersionList] = useState([]);

  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [selectedBrand, setSelectedBrand] = useState();
  const [retrievedBrands, setRetrievedBrands] = useState([]);
  const [disableBrandInput, setDisableBrandInput] = useState(false);

  useEffect(() => {
    handleBrandOptions();
  }, [defaultValues]);

  useEffect(() => {
    if (affinity === 'honda') {
      setSelectedBrand('Honda');
      handleBrandLogo(retrievedBrands['Honda']);
      setDisableBrandInput(true);
    } else if (affinity === 'mazda') {
      setSelectedBrand('Mazda');
      handleBrandLogo(retrievedBrands['Mazda']);
      setDisableBrandInput(true);
    }
  }, [retrievedBrands]);

  useEffect(() => {
    setSelectedYear(undefined);
    setSelectedPower(undefined);
    if (selectedBrand in retrievedBrands) {
      handleBrandLogo(retrievedBrands[selectedBrand]);
    }
  }, [selectedModel, selectedBrand]);

  useEffect(() => {
    filterFilterList(filterdversionList);
  }, [filterdversionList]);

  useEffect(() => {
    setFilterdVersionList([]);
    handleVersions();
  }, [selectedModel]);

  useEffect(() => {
    handleVersionFiltering();
  }, [selectedYear, selectedPower]);

  async function handleBrandOptions(e) {
    if (!e) e = '';
    const [resp, status] = await retrieveCarBrands();

    if (status !== 200) return;
    const brandOptions = [];
    setRetrievedBrands(resp);

    Object.keys(resp)
      .filter((i) => i.toLowerCase().includes(e.toLowerCase()))
      .map((brand) => brandOptions.push({ label: brand, value: brand }));

    handleModelOptions();

    return brandOptions;
  }

  async function handleModelOptions(e) {
    setSelectedModel(undefined);

    if (!e) e = '';
    if (!selectedBrand) return;
    const [resp, status] = await retrieveCarModels(null, selectedBrand);

    if (status !== 200) return;
    const brandOptions = [];
    resp
      .filter((i) => i.toLowerCase().includes(e.toLowerCase()))
      .map((brand) => brandOptions.push({ label: brand, value: brand }));

    return brandOptions;
  }

  async function handleVersions() {
    setVersionList([]);
    setFilterdVersionList([]);
    const [resp, status] = await retrieveCarVerions(
      null,
      selectedBrand,
      selectedModel
    );

    if (status !== 200) return;
    filterFilterList(resp);
    setVersionList(resp);
    setFilterdVersionList(resp);
  }

  function filterFilterList(versionList) {
    const powerArr = [];

    versionList.forEach(({ kw }) => {
      powerArr.push(kw);
    });

    const filteredPowerArr = [...new Set(powerArr)].sort();

    setPowerList(
      filteredPowerArr.map((power) => {
        return { label: String(power), value: power };
      })
    );
    const yearArr = [];

    versionList.forEach(({ model_year }) => {
      yearArr.push(model_year);
    });

    const filteredYearArr = [...new Set(yearArr)].sort();

    setYearList(
      filteredYearArr.map((year) => {
        return { label: String(year), value: year };
      })
    );
  }

  function handleVersionFiltering() {
    setFilterdVersionList(
      versionList.filter(({ model_year, kw }) => {
        let _RETURN = false;
        if (selectedYear) {
          if (model_year === selectedYear) {
            _RETURN = true;
          } else {
            return false;
          }
        }
        if (selectedPower) {
          if (kw === selectedPower) {
            _RETURN = true;
          } else {
            return false;
          }
        }
        if (!selectedYear && !selectedPower) return true;
        return _RETURN;
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (inputName === 'power') {
      if (selectedPower) {
        const [selectValue] = object.filter((k) => k.value === selectedPower);
        if (!selectValue) return undefined;
        return selectValue;
      }
    } else if (inputName === 'model_year') {
      if (selectedYear) {
        const [selectValue] = object.filter((k) => k.value === selectedYear);
        if (!selectValue) return undefined;
        return selectValue;
      }
    } else if (inputName === 'model') {
      if (selectedModel) {
        const [selectValue] = Object.keys(object).filter(
          (k) => k.value === selectedModel
        );
        if (!selectValue) return undefined;
        return selectValue;
      }
    }
    if (!data[inputName]) return '';
    const key = data[inputName];
    const [selectValue] = Object.keys(object).filter((k) => k.value === key);
    if (!selectValue) return undefined;
    return { label: selectValue, value: selectValue };
  }
  const SelectPlaceholder = i18n.t('Choose your option');
  const noOptionMessage = i18n.t('No option');

  return (
    <GridContainer>
      <StyledPlaceHolder>
        <SelectInput
          className="brand"
          name="brand"
          disabled={disableBrandInput}
          placeholder={disableBrandInput ? selectedBrand : noOptionMessage}
          onSelected={({ value }) => {
            setSelectedBrand(value);
          }}
          value={disableBrandInput ? selectedBrand : ''}
          color={disableBrandInput ? 'black' : null}
          noOptionMessage={noOptionMessage}
          isClearable={!disableBrandInput}
          isSearchable={!disableBrandInput}
          options={Object.keys(retrievedBrands).map((b) => {
            return { label: b, value: b };
          })}
        >
          {i18n.t('Brand')}
        </SelectInput>
      </StyledPlaceHolder>
      <SelectInput
        key={selectedBrand}
        async
        name="model"
        onSelected={({ value }) => {
          setSelectedModel(value);
        }}
        disabled={!selectedBrand}
        loadOptions={handleModelOptions}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={data.model ? data.model : SelectPlaceholder}
      >
        {i18n.t('Model')}
      </SelectInput>
      <FilterContainer>
        <SelectInput
          name="model_year"
          disabled={filterdversionList.length === 0}
          onSelected={({ value }) => {
            setSelectedYear(value);
          }}
          options={yearList}
          isClearable
          isSearchable
          noOptionMessage={noOptionMessage}
          placeholder={SelectPlaceholder}
          value={selectedYear ? handleSelectValue(yearList, 'model_year') : ''}
        >
          {i18n.t('Model year')}
        </SelectInput>
        <SelectInput
          name="power"
          disabled={filterdversionList.length === 0}
          onSelected={({ value }) => {
            setSelectedPower(value);
          }}
          options={powerList}
          isClearable
          isSearchable
          noOptionMessage={noOptionMessage}
          value={selectedPower ? handleSelectValue(powerList, 'power') : ''}
          placeholder={SelectPlaceholder}
        >
          {i18n.t('Vermogen')} (KW)
        </SelectInput>
      </FilterContainer>
      <SearchVersionList
        filterdversionList={filterdversionList}
        disabled={!selectedModel}
        onSelected={(data) => setSelectedVersion(data)}
      />
      <ButtonContainer>
        <ManualLink
          level="secondary"
          onClick={(e) => {
            e.preventDefault();
            handleManual();
          }}
        >
          {i18n.t('I cannot find my car')}
        </ManualLink>
        <StyledActionButton
          onClick={() =>
            handleSubmit(selectedVersion, selectedBrand, selectedModel)
          }
        >
          {i18n.t('Next')}
        </StyledActionButton>
      </ButtonContainer>
    </GridContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`;

const StyledPlaceHolder = styled.div`
  & .Select__placeholder {
    color: black;
  }
`;

const ManualLink = styled.a`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: 0rem;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 8rem) 20rem auto;
  height: 100%;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

export default FlowSearchForVehicleForm;
