import React from 'react';
import styled from 'styled-components';
import { ActionButton } from 'wg-fe-ui';

import DownloadIcon from '../assets/icons/download-button.svg';
const DownloadButton = ({ children, href, className }) => {
  return (
    <StyledActionButton className={className} onClick={() => window.open(href)}>
      <Img src={DownloadIcon} />
      {children}
    </StyledActionButton>
  );
};

const StyledActionButton = styled(ActionButton)`
  background-color: default;
  position: relative;
  padding: 0.7rem 0 0.7rem 2.4rem;
  :hover {
    background-color: default;
  }
`;

const Img = styled.img`
  height: 1.4rem;
  position: absolute;
  left: 1.3rem;
`;

export default DownloadButton;
