import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  SENTRY_DSN,
  PROJECT_NAME,
  RELEASE,
  ENVIRONMENT
} from "./env.config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import packageJson from "../package.json";

Sentry.setTag("wg-fe-gan-direct", PROJECT_NAME);
Sentry.setTag("wg-fe-ui-version", packageJson.dependencies["wg-fe-ui"]);
﻿
Sentry.init({
  serverName: PROJECT_NAME,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
  ignoreErrors: [],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1 // Be sure to lower this in production
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
