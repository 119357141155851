import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting.js';
import { useParams } from 'react-router';
import FlowAddressContainer from '../components/FlowAddressContainer';
import LoadingActionButton from '../components/LoadingActionButton';
import { retrieveStorageById, patchStorage } from '../helpers/storeService.js';
import useForm from '../hooks/useForm';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import * as Yup from 'yup';
import { string } from '../constants/validationSchemas';
import { getNestedObject } from '../helpers/objectService';
import { ChatContext } from '../contexts/ChatContext';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import { useFlowStore } from '../contexts/FlowContext';
import { ganGetCustomerById } from '../helpers/apiRouterService';
import cogoToast from 'cogo-toast';

const FlowPolicyHolderInfo = () => {
  const [defaultValues, setDefaultValues] = useState();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [flowStore] = useFlowStore();
  const { addGanAddress, createGanCustomer } = flowStore || {};
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
    buildingName: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
    if (data) {
      setDefaultValues(getNestedObject(data, ['policy_holder_address']) || {});
    }

    function upperCaseFirstLetter(string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
    }

    const question = {
      message: i18n.t('Hello can you please fill in your address', {
        firstName: upperCaseFirstLetter(
          data?.ganCustomer?.FirstName ||
            data?.customer?.firstName ||
            data?.customer?.company_name
        ),
      }),
      slug: 'user-address',
    };

    addQuestion(question);
  }, []);

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (address) => {
    setLoading(true);
    const {
      streetName,
      streetNumber,
      postalCode,
      municipalityName,
      buidlingName,
    } = address;

    patchStorage({
      payload: { policy_holder_address: address },
      id,
      affinity,
      insuranceType,
    });

    const [resp] = await addGanAddress({
      StreetName: values?.streetName,
      ZipCode: values?.postalCode,
    });
    patchStorage({
      payload: { ganAddress: resp.Data?.[0] },
      id,
      affinity,
      insuranceType,
    });

    const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
    if (!data?.ganCustomer) {
      const { personType, customer } = data || {};
      const {
        firstName,
        lastName,
        occupation,
        country,
        idNo,
        password,
        resident_in_cyprus_since,
        telephonenr,
        birth,
        email,
        title,
        company_oid,
      } = customer || {};

      let payloadCreateCustomer = {
        FirstName: firstName,
        LastName: lastName,
        Title: title?.toString(),
        MiddleName: '',
        Email: email,
        WebPassword: password,
        ContactTelephoneNumber: telephonenr,
        Occupation: occupation.toString(),
        PreferredLanguageOfDocumentation: '0',
        Address: {
          Mailing: true,
          Address: resp?.Data[0]?.Oid,
          HouseNumber: '',
          BuidlingName: buidlingName || null,
          StreetNumber: streetNumber,
          Type: 0,
          OwnershipStatus: 3,
          SquareMeters: 100,
        },
      };

      if (personType === 'natural') {
        payloadCreateCustomer = {
          ...payloadCreateCustomer,
          IdNo: idNo,
          CustomerType: 0,
          HomeTelephoneNumber: telephonenr,
          DateOfBirth: birth,
          CustomerCountry: country,
          CustomerSubType: 'Private_Or_PersonalUse;LandLord',
          ResidentInCyprusSince: resident_in_cyprus_since || birth,
        };
      } else if (personType === 'legal') {
        payloadCreateCustomer = {
          ...payloadCreateCustomer,
          CustomerType: 1,
          VatNo: null,
          CompanyName: company_oid,
          OfficeBusinessTelephoneNumber: telephonenr,
          Occupation: occupation,
          DateOfBirth: birth,
          CustomerCountry: country,
          CustomerSubType: 'LandLord;Trades_Of_Business',
        };
      }

      // create customer
      const [customresp] = await createGanCustomer(payloadCreateCustomer);
      if (customresp?.Data) {
        const [customresp2] = await ganGetCustomerById(customresp?.Data);
        patchStorage({
          payload: customresp2,
          path: ['ganCustomer'],
          id,
          affinity,
          insuranceType,
        });
      } else {
        setLoading(false);
        if (customresp?.Error) return cogoToast.error(customresp.Description);
      }
    }

    const answer = {
      message: i18n.t(`My home address is`, {
        address: `${streetNumber} ${streetName}, ${municipalityName} ${postalCode}`,
      }),
      slug: 'home-address',
    };

    addAnswer(answer);

    renderNextRoute();
    setLoading(false);
  };

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container mobile={isMobile()}>
      {!isMobile() && <FlowChatBox />}
      <RightContainer>
        <form onSubmit={handleForm}>
          <div>
            <StyledTitle>{i18n.t('Policyholder address')}</StyledTitle>
            <FlowAddressContainer
              errors={errors}
              values={values}
              defaultValues={defaultValues}
              handleChange={handleChange}
              mobile={isMobile()}
            />
          </div>
          <ButtonContainer mobile={isMobile()}>
            <>
              <Required insurance={insuranceType}>
                * {i18n.t('Required fields')}
              </Required>
              <div>
                {!isMobile() && (
                  <BackButton type="button" name="back" onClick={goBack}>
                    <IconActionChevronLeft />
                    {i18n.t('Back')}
                  </BackButton>
                )}
                {loading ? (
                  <LoadingActionButton
                    type="submit"
                    value="Confirm"
                    data-test-id="policy_holder_address_flow_loading"
                  >
                    {i18n.t('Loading')}
                  </LoadingActionButton>
                ) : (
                  <ActionButton
                    type="submit"
                    value="Confirm"
                    data-test-id="policy_holder_address_flow_submit"
                  >
                    {!isMobile() ? i18n.t('Next') : i18n.t('Submit')}
                  </ActionButton>
                )}
              </div>
            </>
          </ButtonContainer>
        </form>
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const StyledTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: ${({ mobile }) => (mobile ? '100%' : '12rem')};
    &:focus {
      outline: 0;
    }
  }

  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: ${({ mobile }) => (mobile ? 'center' : 'end')};
    }
    & button {
      font-weight: 500;
      height: 4rem;
    }
  }
`;

const Container = styled.div`
  form {
    padding: ${({ mobile }) => (mobile ? '2rem' : '0')};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowPolicyHolderInfo;
