import React from 'react';

const GardenGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#024284" />
          <stop offset="0.81" stopColor="#004587" />
          <stop offset="0.82" stopColor="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <line
            x1="25.18"
            y1="57.82"
            x2="57.43"
            y2="57.82"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <line
            x1="33.6"
            y1="57.48"
            x2="33.6"
            y2="43.41"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <path
            d="M47.57,57.37V49.5"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <path
            d="M40.25,33.22c0,7.65-3.07,9.85-6.87,9.85s-6.87-2.2-6.87-9.85,6.87-17.86,6.87-17.86S40.25,25.57,40.25,33.22Z"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <path
            d="M52,42.67c0,5-2,6.39-4.45,6.39s-4.45-1.43-4.45-6.39S47.57,31.1,47.57,31.1,52,37.72,52,42.67Z"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default GardenGanIcon;
