import React, { useState, createContext, useEffect } from "react";
import { ThemeProvider as ExternalThemeProvider } from "wg-fe-ui";
import { ThemeProvider } from "styled-components";
import { getThemeObject } from "../helpers/themingService";

export const ThemeContext = createContext({
  theme: {},
  setTheme: () => {}
});

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState("gan-direct");
  const [themeObject, setThemeObject] = useState(getThemeObject());

  useEffect(() => {
    setThemeObject(getThemeObject(theme));
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ themeObject, setTheme }}>
      <ExternalThemeProvider theme={themeObject}>
        <ThemeProvider theme={themeObject}>{children}</ThemeProvider>
      </ExternalThemeProvider>
    </ThemeContext.Provider>
  );
};
