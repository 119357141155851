import React, { useState } from 'react';
import styled from 'styled-components';
import LogRocket from 'logrocket';
import '../assets/css/cogoToast.css';
import useRouting from '../hooks/useRouting.js';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  captchaAuth,
  ganAuth,
  ganGetCustomerById,
} from '../helpers/apiRouterService';
import { patchStorage } from '../helpers/storeService';
import { formatISODate } from '../helpers/dateService';
import FlowPolicyHolderInfoFormPrivate from '../components/FlowPolicyHolderInfoFormPrivate.jsx';
import FlowPolicyHolderInfoFormLegal from '../components/FlowPolicyHolderInfoFormLegal.jsx';
import FlowPolicyHolderPersonRadio from '../components/FlowPolicyHolderPersonRadio.jsx';
import cogoToast from 'cogo-toast';
import i18n from '../i18n';

const FlowPolicyHolderUserInfoForm = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [askEmailExistingCustomer, setAskEmailExistingCustomer] = useState(
    false
  );
  const [personType, setPersonType] = useState();

  function handleSubmit(client) {
    onSubmit(client);
  }

  const onSubmit = async (values) => {
    const { firstName, lastName, email, company_name, idNumber } = values || {};
    // Call ganAuth to get a JWT and put it in sessionStorage
    const [, ganstatus] = await ganAuth();
    if (ganstatus !== 200) return;
    let customresponse;
    if (idNumber) {
      // if idNumber is set, get customer info from Gan
      const [customresp, customstatus] = await ganGetCustomerById(idNumber);
      // if customer exists, use this customer data
      if (customstatus === 200) {
        customresponse = customresp;
        // if customer exists but no email from customer, ask for it
        if (!customresp?.Email && !email) {
          return setAskEmailExistingCustomer(true);
        } else if (askEmailExistingCustomer) {
          setAskEmailExistingCustomer(false);
        }
      } else {
        cogoToast.error(i18n.t('Customer not found'));
      }
    }
    const tempValues = cloneDeep(values);
    // Fix so gan data is placed in storage
    if (customresponse?.FirstName) {
      tempValues.firstName = customresponse?.FirstName;
      tempValues.lastName = customresponse?.LastName;
      if (customresponse?.DateOfBirth) {
        tempValues.birth = formatISODate(customresponse?.DateOfBirth);
      }
    }

    authenticate(
      firstName,
      lastName,
      email,
      company_name,
      customresponse,
      tempValues
    );
    LogRocket.identify('Email Login', {
      email,
      name: `${tempValues.firstName} ${tempValues.lastName}`,
    });
  };

  const authenticate = async (
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    company_name = undefined,
    customresp = undefined,
    values = undefined
  ) => {
    const captcha = executeRecaptcha('captchaAuth');
    const [, status] = await captchaAuth({
      captcha,
      email: customresp?.Email ? customresp?.Email : email,
      firstName: customresp
        ? customresp?.FirstName
        : company_name
        ? affinity
        : firstName,
      lastName: customresp
        ? customresp?.LastName
        : company_name
        ? company_name
        : lastName,
      askBrokerId: '52fc1647-afac-474b-92ee-36e51a87a1b7',
    });

    if (status !== 200) return;
    if (captcha) delete values.captcha;

    if (values?.primary === 'yes') {
      patchStorage({
        payload: { driver_information: values },
        id,
        affinity,
        insuranceType,
      });
    }

    let addressValues;
    if (customresp) {
      const filteredAddresses = customresp?.AddressesList?.filter(
        (item) => item.Mailing
      );
      if (filteredAddresses.length > 0) {
        addressValues = {
          municipalityName: filteredAddresses[0]?.Address?.City?.City,
          streetName: filteredAddresses[0]?.Address?.StreetName,
          postalCode: filteredAddresses[0]?.Address?.ZipCode,
          streetNumber:
            filteredAddresses[0]?.HouseNumber ||
            filteredAddresses[0]?.StreetNumber ||
            '',
          boxNumber: null,
        };
      }
    }

    patchStorage({ payload: values, id, affinity, insuranceType });
    if (addressValues?.municipalityName) {
      patchStorage({
        payload: addressValues,
        path: ['policy_holder_address'],
        id,
        affinity,
        insuranceType,
      });
    }
    if (customresp) {
      patchStorage({
        payload: customresp,
        path: ['ganCustomer'],
        id,
        affinity,
        insuranceType,
      });
    }
    renderNextRoute();
  };

  const formSubmitHandler = (val) => {
    handleSubmit(val);
  };

  return (
    <Container>
      <FlowPolicyHolderPersonRadio
        personType={(val) => setPersonType(val)}
        checked={personType}
      />
      {personType === 'natural' ? (
        <FlowPolicyHolderInfoFormPrivate
          insuranceType={insuranceType}
          defaultValues={{}}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
      {personType === 'legal' ? (
        <FlowPolicyHolderInfoFormLegal
          defaultValues={{}}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  width: 100%;
`;

export default FlowPolicyHolderUserInfoForm;
