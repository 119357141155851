import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowDown from '../assets/icons/ArrowDown.svg';
import FlowOverviewPolicyDetailHeaderIcon from './FlowOverviewPolicyDetailHeaderIcon';

interface Props {
  policy: any;
  type: string;
  open: boolean;
  toggleOpen: () => void;
}

const FlowOverviewPolicyDetail: React.FC<Props> = ({
  policy,
  toggleOpen,
  type,
  open,
}) => {
  const { t } = useTranslation();

  return (
    <ProductInfo onClick={toggleOpen}>
      <FlowOverviewPolicyDetailHeaderIcon
        type={type}
        insurance_pack={policy?.allData?.insurance_pack}
      />
      <ProductTitle>{t(policy?.allData?.insurance_pack)}</ProductTitle>
      <ProductPrice>
        <Price>&euro; {policy?.response?.TotalwithFees}</Price>
      </ProductPrice>
      <ToggleBackground>
        <ArrowIcon open={open} src={ArrowDown} />
      </ToggleBackground>
      {policy?.error && (
        <Error>
          <strong>{t('Error! ')}</strong>
          {policy?.response?.Description}
        </Error>
      )}
    </ProductInfo>
  );
};

const Error = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  grid-column: 1 / -1;

  strong {
    font-weight: bold;
  }
`;

export default FlowOverviewPolicyDetail;

const ArrowIcon = styled.img<{ open?: boolean }>`
  transform: rotate(${({ open }) => (open ? '0deg' : '-90deg')});
  transition: transform 0.1s ease-in-out;
`;

const ToggleBackground = styled.div`
  width: 48px;
  height: 48px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f1f3;
  border-radius: 6px;
`;

const ProductInfo = styled.div`
  display: grid;
  grid-gap: 2rem;
  align-items: center;
  grid-template-columns: 5.6rem 3fr 1fr auto;
  padding: 2rem;
  cursor: pointer;
`;

const ProductTitle = styled.h3`
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 120%;
  color: ${({ color }) => (color ? color : '#224074')};
`;
const ProductPrice = styled.div``;

const Price = styled.p`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 115%;
  text-align: right;
  color: #050505;
`;
