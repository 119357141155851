import React from 'react';
import AddaptiveCruiseControlSphere from '../assets/images/drive_assist/adaptive_cruise_control_sphere.jsx';
import AttentionAssistSphere from '../assets/images/drive_assist/attention_assist_sphere';
import AutomaticEmergencyBreakingSphere from '../assets/images/drive_assist/automatic_emergency_braking_sphere';
import AutomaticParkingSphere from '../assets/images/drive_assist/automatic_parking_sphere';
import BlindSpotCheckSphere from '../assets/images/drive_assist/blind_spot_check_sphere';
import EcallSphere from '../assets/images/drive_assist/ecall_sphere';
import LaneSupportSphere from '../assets/images/drive_assist/lane_support_sphere';
import ParkingAid from '../assets/images/drive_assist/parking_aid';

const LoadingDriveAssistIcons = ({ question, checked, colors }) => {
  const type = question.type;

  switch (type) {
    case 'adaptive_cruise_control':
      return <AddaptiveCruiseControlSphere checked={checked} colors={colors} />;
    case 'attention_assist':
      return <AttentionAssistSphere checked={checked} colors={colors} />;
    case 'automatic_emergency_braking':
      return (
        <AutomaticEmergencyBreakingSphere checked={checked} colors={colors} />
      );
    case 'automatic_parking':
      return <AutomaticParkingSphere checked={checked} colors={colors} />;
    case 'blind_spot_check':
      return <BlindSpotCheckSphere checked={checked} colors={colors} />;
    case 'ecall':
      return <EcallSphere checked={checked} colors={colors} />;
    case 'lane_support':
      return <LaneSupportSphere checked={checked} colors={colors} />;
    case 'parking_aid':
      return <ParkingAid checked={checked} colors={colors} />;
    default:
      return <p>[No Icon found]</p>;
  }
};

export default LoadingDriveAssistIcons;
