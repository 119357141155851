import React, { useReducer, useEffect } from 'react';
import FlowPriceCalculatorOption from './FlowPriceCalculatorOption';
import pricingOptions from '../constants/PricingOptions';
import { getNestedObject } from '../helpers/objectService';
import i18n from '../i18n';

const FlowPriceCalculatorOptions = ({
  subPrices,
  onChange,
  selectedType,
  carAge,
}) => {
  function handleOptionChange(name, status) {
    if (status) {
      dispatch({ type: 'add', value: name });
    } else {
      dispatch({ type: 'remove', value: name });
    }
  }

  const [selectedOptions, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case 'add':
        return [...arr, value];
      case 'remove':
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  useEffect(() => {
    processOptionsqueryString(
      selectedOptions.filter(
        (item) =>
          item !== 'civil_liability' &&
          item !== 'omnium' &&
          item !== 'mini_omnium'
      )
    );
  }, [selectedOptions, selectedType]);

  function handleSubPrices(name) {
    if (name === 'civil_liability') {
      const value = getNestedObject(subPrices, [
        'quote',
        'base',
        'total_premium',
      ]);
      return value + 3.62;
    }

    const options = getNestedObject(subPrices, ['quote', 'options']) || [];
    const currentOption = options.filter((item) => item.name === name)[0] || {};
    return currentOption.total_premium || 0;
  }

  function processOptionsqueryString(_selectedOptions) {
    if (_selectedOptions === undefined) return;
    if (_selectedOptions.length === 0) return onChange('');
    const queryString = 'option=' + _selectedOptions.join('&option=');

    onChange(queryString);
  }

  return (
    <>
      {pricingOptions.map(
        ({ id, title, ipidLink, termsLink, mandatory, helper }) => {
          if (selectedType === 'omnium' && id === 'mini_omnium') return null;
          if (selectedType === 'mini_omnium' && id === 'omnium') return null;
          if (selectedType === 'civil_liability' && id === 'omnium')
            return null;
          if (selectedType === 'civil_liability' && id === 'mini_omnium')
            return null;
          return (
            <FlowPriceCalculatorOption
              id={id}
              key={id}
              title={i18n.t(title)}
              ipidLink={ipidLink}
              termsLink={termsLink}
              disabled={id === 'assistance_vehicle' && carAge >= 10}
              onChange={handleOptionChange}
              mandatory={mandatory}
              price={handleSubPrices(id)}
              helperText={i18n.t(helper)}
              defaultValue={id === 'legal'}
            />
          );
        }
      )}
    </>
  );
};
export default FlowPriceCalculatorOptions;
