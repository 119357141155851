export const building_types = [
  {
    value: 24,
    label: "Block of Flats"
  },
  {
      value: 34,
      label: "Bungalow"
  },
  {
      value: 56,
      label: "Communal Areas"
  },
  {
      value: 27,
      label: "Condominium"
  },
  {
      value: 29,
      label: "Detached House"
  },
  {
      value: 28,
      label: "Dormitory"
  },
  {
      value: 25,
      label: "Flat"
  },
  {
      value: 26,
      label: "Flat Basement"
  },
  {
      value: 35,
      label: "Maisonette"
  },
  {
      value: 37,
      label: "Mobile Home"
  },
  {
      value: 31,
      label: "Nursing home"
  },
  {
      value: 36,
      label: "Prefabricated Home"
  },
  {
      value: 30,
      label: "Semi-detached House"
  },
  {
      value: 33,
      label: "Villa"
  }
];