import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ChatContext } from '../contexts/ChatContext';
import FlowHealthInformationForm from '../components/FlowHealthInformationForm.jsx';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import FlowChatBox from '../components/FlowChatBox';
import cloneDeep from 'lodash.clonedeep';

const FlowHealthInformation = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { addQuestion, addAnswer } = useContext(ChatContext);
  const { id, affinity, insuranceType, currentHealth } = useParams();

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { healths } = data;
    if (healths[currentHealth] !== null) {
      setDefaultValues(healths[currentHealth]);
    }
    const question = {
      message: i18n.t(
        'Please fill in some general health information about yourself'
      ),
      slug: 'health-information',
    };
    addQuestion(question);
  }, []);

  const fixData = (values) => {
    const tempValues = cloneDeep(values);
    if (
      tempValues?.AnyChronicOrLongTermMedicalCondition !== 'yes' &&
      tempValues?.EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth !==
        'yes'
    ) {
      tempValues.MedicalDiseases = null;
      tempValues.NutritionalAndMetabolicDiseases = null;
      tempValues.DiabetesDiagnosed = null;
      tempValues.ControlledByDiet = null;
      tempValues.DiabetesMedicationTaken = null;
    }

    if (!tempValues?.MedicalDiseases?.find((item) => item?.value === '224')) {
      tempValues.DiabetesDiagnosed = null;
      tempValues.ControlledByDiet = null;
      tempValues.DiabetesMedicationTaken = null;
      tempValues.RecentHba1cTest = null;
      tempValues.ResultHba1c = null;
    }

    if (!tempValues?.MedicalDiseases?.find((item) => item?.value === '362')) {
      tempValues.DateOfRecentBloodPressureReading = null;
      tempValues.BloodPresureResult = null;
      tempValues.MedicationTaken = null;
    }
    return tempValues;
  };

  function handleSubmit(values) {
    const {
      HeightInCM,
      WeightInKg,
      DoYouSmoke,
      NumberOfCigarettesPerDay,
      HowManyMonthsOfAYearDoYouLiveAbroad,
      MedicalDiseases,
      AnyChronicOrLongTermMedicalCondition,
      EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth,
      CurrentlyDisabledPregnantOrUnableToPerformNormalActivities,
      AnyDiseasesOfTheCirculatorySystemHeart,
      NutritionalAndMetabolicDiseases,
      DiabetesDiagnosed,
      ControlledByDiet,
      DiabetesMedicationTaken,
    } = values || {};

    const payload = fixData({
      HeightInCM,
      WeightInKg,
      DoYouSmoke,
      NumberOfCigarettesPerDay,
      HowManyMonthsOfAYearDoYouLiveAbroad,
      AnyChronicOrLongTermMedicalCondition,
      EverDiagnosedWithMedicalProblemThatMayHaveImpactOnYourHealth,
      CurrentlyDisabledPregnantOrUnableToPerformNormalActivities,
      AnyDiseasesOfTheCirculatorySystemHeart,
      NutritionalAndMetabolicDiseases,
      DiabetesDiagnosed,
      ControlledByDiet,
      DiabetesMedicationTaken,
      MedicalDiseases,
    });

    const answer = {
      message: i18n.t(`I am cm tall and weigh kg`, {
        length: HeightInCM,
        weight: WeightInKg,
      }),
      slug: 'health-information',
    };

    addAnswer(answer);

    patchStorage({
      payload,
      path: ['healths', [currentHealth]],
      id,
      affinity,
      insuranceType,
    });
    if (MedicalDiseases?.find((item) => item.value === '362')) {
      renderNextRoute(2, { health: currentHealth });
    } else if (MedicalDiseases?.find((item) => item.value === '224')) {
      renderNextRoute(3, { health: currentHealth });
    } else {
      renderNextRoute(1, { health: currentHealth });
    }
  }

  return (
    <SplitContainer>
      {!isMobile() && <FlowChatBox />}
      <RightSplit>
        <FlowHealthInformationForm
          handleFormValues={handleSubmit}
          defaultValues={
            defaultValues.health ? defaultValues.health : defaultValues
          }
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightSplit = styled.div`
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;

export default FlowHealthInformation;
