import React from 'react';
import styled from 'styled-components';
import CheckBox from '../components/CheckBox';
import InfoPopUp from '../components/InfoPopUpLink';
import i18n from '../i18n';

// eslint-disable-next-line react/prop-types
const OfferedPolicy = ({
  checked = false,
  name,
  children,
  onChange,
  popUp = false,
  disabled = false,
}) => {
  return (
    <StyledDiv className="unsetHeight" checked={checked} disabled={disabled}>
      <StyledCheckBox
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div>
        <StyledSpan dangerouslySetInnerHTML={{ __html: children }} />
        {popUp ? (
          <InfoPopUp
            title={`${i18n.t(children)}${i18n.t(popUp.button)}`}
            buttonText={i18n.t(popUp.button)}
            info={i18n.t(popUp.text)}
            onClick={() => {
              onChange({ name: name, value: true });
            }}
          />
        ) : null}
      </div>
    </StyledDiv>
  );
};

const StyledSpan = styled.span`
  font-weight: normal !important;
`;

const StyledDiv = styled.label`
  font-size: 1.4rem;
  align-items: center;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : '1px solid #CCCCCC'};
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2%;
  padding-left: 2rem;
  padding-right: 2rem;
  color: ${({ disabled, theme }) =>
    disabled ? theme.ui.disabled : theme.typo.text};
  display: flex;
  line-height: 1.3;
  transition: all 0.2s ease;
  background-color: ${({ disabled }) => (disabled ? '#efefef' : 'white')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  & > div {
    margin: 1rem;
    word-break: break-word;
    display: inline;
  }

  span {
    font-weight: bold;
  }
`;

const StyledCheckBox = styled(CheckBox)``;
export default OfferedPolicy;
