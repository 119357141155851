export const getCoverDetails = (type, data) => {
  switch (type) {
    case 'Economic': {
      return [
        {
          //need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Guarantee Asset Protection',
          toggle: !!data['Economic']?.GuranteeAssetProtectionBenefit,
          key: 'GuranteeAssetProtectionBenefit',
          responseKey: 'BenGAPLoading',
          amountOptions: [
            {
              value: `1`,
              label: `Motor Guarantee Asset Protection Up to -3420`,
            },
            {
              value: `2`,
              label: `Motor Guarantee Asset Protection Up to -5130`,
            },
            {
              value: `3`,
              label: `Motor Guarantee Asset Protection Up to -8550`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        // {
        //   value: "Injury / Death to Third parties",
        //   key: "",
        //   amount: "36.350.000",
        //   premium: "-",
        //   detail:
        //     "Covers injury or death you may cause to someone else, including passengers. Such death or injury arises out of an accident caused by or arising out of the use of the Insured Vehicle on the road, in the geographical area defined in the Schedule",
        // },
        // {
        //   value: "Liability for Property Damages",
        //   amount: "1,220,000",
        //   premium: "-",
        //   detail:
        //     "Covers damages you may cause to someone else Property. Such damage arises out of an accident caused by or arising out of the use of the Insured Vehicle on the road, in the geographical area defined in the Schedule",
        // },
        {
          value: 'Medical Expenses for Authorized Drivers',
          toggle: !!data['Economic']?.MedicalExpensesBenefit,
          responseKey: 'BenMedicalExpensesLoading',
          key: 'MedicalExpensesBenefit',
          amountOptions: [
            { value: 1, label: '2150' },
            { value: 3, label: '2350' },
            { value: 5, label: '2500' },
            { value: 7, label: '2700' },
          ],
          amount: '',
          premium: '-',
          detail:
            'Covers Medical Expenses for the authorized drivers occurring following an accident with the Insured Vehicle. In the unfortunate event of an accident which requires Medical Treatment we will pay for Medical Expenses',
        },
        {
          value: 'Beyond the Road Use',
          amount: '-',
          premium: '-',
          responseKey: 'BenMedicalExpensesLoading',
          detail:
            'Cover is extended for use of the vehicle anywhere within the Republic of Cyprus excluding the restricted areas of ports and airports',
        },
        {
          value: 'Passengers Liability to Third Parties',
          premium: '-',
          responseKey: 'VesselMedicalPassengersLoading',
          detail:
            'This benefit covers property damages and bodily injuries to other Third Parties caused by the passengers of the insured vehicle',
        },
        {
          value: 'Penal Bail',
          amount: '3.000',
          premium: '-',
          detail:
            'In case of a road accident we will pay an amount to bail out the driver. The insured has to reimburse Gan Direct for any amount so paid within three months',
        },
        {
          value: 'Legal Protection',
          amount: '1.000',
          premium: '-',
          detail:
            'We will pay for the expenses to defend the driver involved in an accident in order to avoid his imprisonment',
        },
        {
          value: 'Unlimited Windscreen Cover',
          toggle: !!data['Economic']?.UnlimitedWindscreenBenefit,
          amount: '250',
          key: 'UnlimitedWindscreenBenefit',
          responseKey: 'BenUnlimitedWindscreenLoading',
          toggleOnAmount: 'Unlimited',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'The benefit provides cover for the front windscreen in addition to the side and rear windows. In summary, this benefit provides cover due to accidental breakage. It does not have to be due to a physical accident. A stone can cause breakage of a windscreen for example',
        },
        {
          value: 'Third Party Cover',
          premium: '-',
          detail: 'Here you can see the premium for the Third Party Cover',
        },
        {
          value: 'Fire and Theft, Attempted Theft',
          search: true,
          amount: '',
          premium: '-',
          detail:
            'We will cover the vehicle and its accessories and spare parts in the event of Fire, external explosion, self-ignition or lighting as well as Theft or Attempted Theft',
        },
        {
          value: 'Excess',
          amount: '100',
          premium: '100',
          detail:
            'Please choose the excess for your policy. This the amount that you will need to pay first in case of a Claim for your own damage. By choosing a higher excess amount you can reduce your premium',
        },
        {
          value: 'Driving other cars',
          key: 'DrivingOtherCarsBenefit',
          toggle: !!data['Economic']?.DrivingOtherCarsBenefit,
          responseKey: 'BenDrivingOtherCarsLoading',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'This benefit provides Third Party Liability cover whilst driving another private motor vehicle up to the same engine capacity and type as the vehicle insured with Gan Direct. The vehicle must not belong to the customer or hired to the customer under a hire purchase agreement or otherwise or belong to spouse or employer or partner. This cover only applies to one registered owner being the policyholder, provided that the vehicle driven has valid road tax, MOT and Insurance',
        },
        {
          value: 'Drawing a trailer',
          key: 'Trailer',
          toggle: !!data['Economic']?.Trailer,
          responseKey: 'TrailerLoading',
          amountOptions: [
            { value: 1, label: 'Specified' },
            { value: 2, label: 'Unspecified' },
          ],
          amount: '',
          premium: '-',
          detail:
            'Provides Third Party Legal Liability cover for a trailer whilst attached to the insured vehicle. Please choose from the list between Specified or Unspecified Trailer',
        },
        {
          // theres differences based on saloon or own goods
          value: 'Claim protection',
          key: 'ClaimProtectionBenefit',
          responseKey: 'BenClaimProtectionLoading',
          toggle: !!data['Economic']?.ClaimProtectionBenefit,
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: '2',
          premium: '-',
          detail:
            'When a claim is submitted, this benefit protects the customer from paying an additional premium that would apply at the following renewal date due to the claim. This applies to one and only claim for a year of insurance irrespective of the cost of the claim',
        },
        {
          value: 'Personal Effects',
          toggle: !!data['Economic']?.PersonalEffectsBenefit,
          key: 'PersonalEffectsBenefit',
          amount: 'Not selected',
          responseKey: 'BenPersonalEffectLoading',
          toggleOnAmount: `€ 1000`,
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Cover is provided for loss or damage to personal effects whilst in or on the insured vehicle and directly caused by fire, lightning, explosion, theft or attempted theft or accident. We do not provide cover for any loss or damage whatsoever to money, credit cards, cheque books, jewellery, portable electronic equipment, stamps, tickets, documents, securities, goods, tools or samples carried in connection with any trade or business or any mobile electronic equipment',
        },
        {
          // need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Loss of Use',
          responseKey: 'BenLossOfUseLoading',
          toggle: !!data['Economic']?.LossOfUseBenefit,
          key: 'LossOfUseBenefit',
          amountOptions: [
            {
              value: 1,
              label: `Loss of Use -5 Days -	€ 20`,
            },
            {
              value: 5,
              label: `Loss of Use -5 Days -	€ 30`,
            },
            {
              value: 9,
              label: `Loss of Use -7 Days -	€ 30`,
            },
            {
              value: 13,
              label: `Loss of Use -10 Days -	€ 35`,
            },
            {
              value: 17,
              label: `Loss of Use -7 Days -	€ 45`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        {
          value: 'Legal Protection Assistance',
          amount: 'Free',
          premium: '-',
          detail:
            'With this free service, our company will protect your legal rights every step of the way, with representation from experienced lawyers introduced by our company, committed to defending your interests and seeking compensation for bodily injuries, damages to the vehicle and other losses suffered by the driver and/or any passengers, if such damage claims have not been settled amicably. More specifically we will ensure that you will not be required to pay any legal fees, court fees and witness and expert witness fees',
        },
        {
          value: 'Claim Support Service',
          amount: 'Free',
          premium: '-',
          detail:
            'With this service on your behalf, we will ensure a fair and prompt service of your claim’s handling process by the other insurance companies and/or uninsured driver(s), till your case is settled',
        },
        {
          value: 'Roadside Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of a breakdown of the Insured Vehicle. We provide assistance at the roadside if you are broken. If unable to fix your vehicle at the roadside, tow your vehicle and up to 5 passengers to a local repairer or a local destination of your choice within 16 KM from the breakdown point',
        },
        {
          value: 'Claim Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of an accident/ incident that might lead to a claim. Notifying our 24Hrs Claim Assistant is a contractual obligation of all our customers. With our immediate visit at the scene within 24 minutes if you are within town limits, we are there to provide support and safeguard our customer’s interests by taking them through the correct procedure of a claim, completing all the necessary documents, taking photographs of the damages and towing away the vehicle if is needed',
        },
        {
          value: 'Business Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of a breakdown of the Insured Vehicle. We provide assistance at the roadside if you are broken. If unable to fix your vehicle at the roadside, tow your vehicle and up to 5 passengers to a local repairer or a local destination of your choice within 16 KM from the breakdown point',
        },
        {
          value: '24 Hours Glass Replacement',
          amount: 'Included',
          premium: '-',
          detail:
            'The 24 Hours Glass Replacement Service provides replacement of your damage glass in 24hrs',
        },
        {
          value: 'Relay',
          toggle: !!data['Economic']?.Relay,
          responseKey: 'RelayLoading',
          key: 'Relay',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Relay Assistance covers towing of a vehicle to any destination of the customer’s choice and offers lift to up to 5 people',
        },
        {
          value: 'Stay Mobile',
          key: 'StayMobile',
          toggle: !!data['Economic']?.StayMobile,
          responseKey: 'StayMobileLoading',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Stay Mobile provides to the insured an alternative vehicle whilst his/her vehicle is being repaired. A customer with a Stay Mobile Service may decide not to take an alternative vehicle but stay in a hotel in the area they had the breakdown for 1 night. In such a case we provide accommodation with breakfast for up to 5 people. Stay Mobile also has a third choice for the customer which is free of use of public transportation',
        },
      ];
    }
    case 'Premium': {
      return [
        {
          // need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Loss of Use',
          toggle: !!data['Premium']?.LossOfUseBenefit,
          responseKey: 'BenLossOfUseLoading',
          key: 'LossOfUseBenefit',
          amountOptions: [
            {
              value: 1,
              label: `Loss of Use -5 Days -	€ 20`,
            },
            {
              value: 5,
              label: `Loss of Use -5 Days -	€ 30`,
            },
            {
              value: 9,
              label: `Loss of Use -7 Days -	€ 30`,
            },
            {
              value: 13,
              label: `Loss of Use -10 Days -	€ 35`,
            },
            {
              value: 17,
              label: `Loss of Use -7 Days -	€ 45`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        {
          //need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Guarantee Asset Protection',
          responseKey: 'BenGAPLoading',
          toggle: !!data['Premium']?.GuranteeAssetProtectionBenefit,
          key: 'GuranteeAssetProtectionBenefit',
          amountOptions: [
            {
              value: `1`,
              label: `Motor Guarantee Asset Protection Up to -3420`,
            },
            {
              value: `2`,
              label: `Motor Guarantee Asset Protection Up to -5130`,
            },
            {
              value: `3`,
              label: `Motor Guarantee Asset Protection Up to -8550`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        {
          // theres differences based on saloon or own goods
          value: 'Medical Expenses for Authorized Drivers',
          toggle: !!data['Premium']?.MedicalExpensesBenefit,
          responseKey: 'BenMedicalExpensesLoading',
          key: 'MedicalExpensesBenefit',
          amountOptions: [
            { value: 1, label: '2150' },
            { value: 3, label: '2350' },
            { value: 5, label: '2500' },
            { value: 7, label: '2700' },
          ],
          amount: '',
          premium: '-',
          detail:
            'Covers Medical Expenses for the authorized drivers occurring following an accident with the Insured Vehicle. In the unfortunate event of an accident which requires Medical Treatment we will pay for Medical Expenses',
        },
        {
          value: 'Excess',
          amount: '100',
          premium: '100',
          detail:
            'Please choose the excess for your policy. This the amount that you will need to pay first in case of a Claim for your own damage. By choosing a higher excess amount you can reduce your premium',
        },
        {
          // need more info on this
          value: 'Convulsion of Nature',
          amount: '',
          premium: '-',
          detail:
            'Covers damages to the vehicle caused by natural hazard events such as rain, or tornado',
        },
        {
          value: 'Drawing a trailer',
          key: 'Trailer',
          toggle: !!data['Premium']?.Trailer,
          responseKey: 'TrailerLoading',
          amountOptions: [
            { value: 1, label: 'Specified' },
            { value: 2, label: 'Unspecified' },
          ],
          unspecifiedAmount: '10,25 €',
          specifiedAmount: '5,13 €',
          amount: '',
          premium: '-',
          detail:
            'Provides Third Party Legal Liability cover for a trailer whilst attached to the insured vehicle. Please choose from the list between Specified or Unspecified Trailer',
        },
        {
          value: 'Relay',
          toggle: !!data['Premium']?.Relay,
          key: 'Relay',
          amount: 'Not selected',
          responseKey: 'RelayLoading',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          selectedPremium: '10,00 €',
          premium: '-',
          detail:
            'Relay Assistance covers towing of a vehicle to any destination of the customer’s choice and offers lift to up to 5 people',
        },
        {
          value: 'Stay Mobile',
          key: 'StayMobile',
          responseKey: 'StayMobileLoading',
          toggle: !!data['Premium']?.StayMobile,
          amount: 'Not selected',
          selectedPremium: '15,00 €',
          toggleOnAmount: 'Selected',
          premium: '-',
          detail:
            'Stay Mobile provides to the insured an alternative vehicle whilst his/her vehicle is being repaired. A customer with a Stay Mobile Service may decide not to take an alternative vehicle but stay in a hotel in the area they had the breakdown for 1 night. In such a case we provide accommodation with breakfast for up to 5 people. Stay Mobile also has a third choice for the customer which is free of use of public transportation',
        },
      ];
    }
    case 'Value': {
      return [
        {
          // need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Loss of Use',
          toggle: !!data['Value']?.LossOfUseBenefit,
          key: 'LossOfUseBenefit',
          responseKey: 'BenLossOfUseLoading',
          amountOptions: [
            {
              value: 1,
              label: `Loss of Use -5 Days -	€ 20`,
            },
            {
              value: 5,
              label: `Loss of Use -5 Days -	€ 30`,
            },
            {
              value: 9,
              label: `Loss of Use -7 Days -	€ 30`,
            },
            {
              value: 13,
              label: `Loss of Use -10 Days -	€ 35`,
            },
            {
              value: 17,
              label: `Loss of Use -7 Days -	€ 45`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        {
          //need to include vehicle type: saloon or own goods -- dont know what own goods means
          value: 'Guarantee Asset Protection',
          responseKey: 'BenGAPLoading',
          toggle: !!data['Value']?.GuranteeAssetProtectionBenefit,
          key: 'GuranteeAssetProtectionBenefit',
          amountOptions: [
            {
              value: `1`,
              label: `Motor Guarantee Asset Protection Up to -3420`,
            },
            {
              value: `2`,
              label: `Motor Guarantee Asset Protection Up to -5130`,
            },
            {
              value: `3`,
              label: `Motor Guarantee Asset Protection Up to -8550`,
            },
          ],
          amount: '',
          premium: '-',
          detail:
            'In the Event of a claim this benefit provides a daily amount for loss of use of the vehicle. This is limited to the actual number of working days required to complete the repairs (as per assessment) but in no case shall the number of days exceed the period or amount selected',
        },
        {
          // theres differences based on saloon or own goods
          value: 'Claim protection',
          key: 'ClaimProtectionBenefit',
          responseKey: 'BenClaimProtectionLoading',
          toggle: !!data['Value']?.ClaimProtectionBenefit,
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: '2',
          premium: '-',
          detail:
            'When a claim is submitted, this benefit protects the customer from paying an additional premium that would apply at the following renewal date due to the claim. This applies to one and only claim for a year of insurance irrespective of the cost of the claim',
        },
        {
          // is excess the same as excess protection?
          value: 'Excess',
          amount: '100',
          premium: '100',
          detail:
            'Please choose the excess for your policy. This the amount that you will need to pay first in case of a Claim for your own damage. By choosing a higher excess amount you can reduce your premium',
        },
        {
          // need more info on this
          value: 'Riots Strikes Protection',
          amount: '',
          premium: '-',
          detail:
            'Covers damages to vehicle caused by events such as riots, strikes, etc.',
        },
        {
          value: 'Personal Effects',
          toggle: !!data['Value']?.PersonalEffectsBenefit,
          key: 'PersonalEffectsBenefit',
          responseKey: 'BenPersonalEffectLoading',
          amount: 'Not selected',
          toggleOnAmount: `€ 1000`,
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Cover is provided for loss or damage to personal effects whilst in or on the insured vehicle and directly caused by fire, lightning, explosion, theft or attempted theft or accident. We do not provide cover for any loss or damage whatsoever to money, credit cards, cheque books, jewellery, portable electronic equipment, stamps, tickets, documents, securities, goods, tools or samples carried in connection with any trade or business or any mobile electronic equipment',
        },
        {
          // need more info on this
          value: 'Convulsion of Nature',
          amount: '',
          premium: '-',
          detail:
            'Covers damages to the vehicle caused by natural hazard events such as rain, or tornado',
        },
        {
          // theres differences based on saloon or own goods
          value: 'Medical Expenses for Authorized Drivers',
          toggle: !!data['Value']?.MedicalExpensesBenefit,
          responseKey: 'BenMedicalExpensesLoading',
          key: 'MedicalExpensesBenefit',
          amountOptions: [
            { value: 1, label: '2150' },
            { value: 3, label: '2350' },
            { value: 5, label: '2500' },
            { value: 7, label: '2700' },
          ],
          amount: '',
          premium: '-',
          detail:
            'Covers Medical Expenses for the authorized drivers occurring following an accident with the Insured Vehicle. In the unfortunate event of an accident which requires Medical Treatment we will pay for Medical Expenses',
        },
        {
          value: 'Driving other cars',
          key: 'DrivingOtherCarsBenefit',
          toggle: !!data['Value']?.DrivingOtherCarsBenefit,
          amount: 'Not selected',
          responseKey: 'BenDrivingOtherCarsLoading',
          selectedPremium: '2,50 €',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'This benefit provides Third Party Liability cover whilst driving another private motor vehicle up to the same engine capacity and type as the vehicle insured with Gan Direct. The vehicle must not belong to the customer or hired to the customer under a hire purchase agreement or otherwise or belong to spouse or employer or partner. This cover only applies to one registered owner being the policyholder, provided that the vehicle driven has valid road tax, MOT and Insurance',
        },
        {
          value: 'Windscreen Cover',
          amount: '250',
          premium: '-',
          detail:
            'The benefit provides cover for the front windscreen in addition to the side and rear windows. In summary, this benefit provides cover due to accidental breakage. It does not have to be due to a physical accident. A stone can cause breakage of a windscreen for example',
        },
        {
          value: 'Drawing a trailer',
          key: 'Trailer',
          toggle: !!data['Value']?.Trailer,
          responseKey: 'TrailerLoading',
          amountOptions: [
            { value: 1, label: 'Specified' },
            { value: 2, label: 'Unspecified' },
          ],
          unspecifiedAmount: '10,25 €',
          specifiedAmount: '5,13 €',
          amount: '',
          premium: '-',
          detail:
            'Provides Third Party Legal Liability cover for a trailer whilst attached to the insured vehicle. Please choose from the list between Specified or Unspecified Trailer',
        },
        {
          value: 'Relay',
          toggle: !!data['Value']?.Relay,
          key: 'Relay',
          responseKey: 'RelayLoading',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          selectedPremium: '10,00 €',
          premium: '-',
          detail:
            'Relay Assistance covers towing of a vehicle to any destination of the customer’s choice and offers lift to up to 5 people',
        },
        {
          value: 'Stay Mobile',
          key: 'StayMobile',
          toggle: !!data['Value']?.StayMobile,
          amount: 'Not selected',
          responseKey: 'StayMobileLoading',
          selectedPremium: '15,00 €',
          toggleOnAmount: 'Selected',
          premium: '-',
          detail:
            'Stay Mobile provides to the insured an alternative vehicle whilst his/her vehicle is being repaired. A customer with a Stay Mobile Service may decide not to take an alternative vehicle but stay in a hotel in the area they had the breakdown for 1 night. In such a case we provide accommodation with breakfast for up to 5 people. Stay Mobile also has a third choice for the customer which is free of use of public transportation',
        },
      ];
    }
    case 'Basic Plus': {
      return [
        {
          value: 'Injury / Death to Third parties',
          amount: '36.350.000',
          premium: '-',
          detail:
            'Covers injury or death you may cause to someone else, including passengers. Such death or injury arises out of an accident caused by or arising out of the use of the Insured Vehicle on the road, in the geographical area defined in the Schedule',
        },
        {
          value: 'Liability for Property Damages',
          amount: '1,220,000',
          premium: '-',
          detail:
            'Covers damages you may cause to someone else Property. Such damage arises out of an accident caused by or arising out of the use of the Insured Vehicle on the road, in the geographical area defined in the Schedule',
        },
        {
          value: 'Medical Expenses for Authorized Drivers',
          toggle: !!data['Basic Plus']?.MedicalExpensesBenefit,
          responseKey: 'BenMedicalExpensesLoading',
          key: 'MedicalExpensesBenefit',
          amountOptions: [
            { value: 1, label: '2150' },
            { value: 3, label: '2350' },
            { value: 5, label: '2500' },
            { value: 7, label: '2700' },
          ],
          amount: '',
          premium: '-',
          detail:
            'Covers Medical Expenses for the authorized drivers occurring following an accident with the Insured Vehicle. In the unfortunate event of an accident which requires Medical Treatment we will pay for Medical Expenses',
        },
        {
          value: 'Beyond the Road Use',
          amount: '-',
          premium: '-',
          detail:
            'Cover is extended for use of the vehicle anywhere within the Republic of Cyprus excluding the restricted areas of ports and airports',
        },
        {
          value: 'Passengers Liability to Third Parties',
          premium: '-',
          detail:
            'This benefit covers property damages and bodily injuries to other Third Parties caused by the passengers of the insured vehicle',
        },
        {
          value: 'Penal Bail',
          amount: '3.000',
          premium: '-',
          detail:
            'In case of a road accident we will pay an amount to bail out the driver. The insured has to reimburse Gan Direct for any amount so paid within three months',
        },
        {
          value: 'Legal Protection',
          amount: '1.000',
          premium: '-',
          detail:
            'We will pay for the expenses to defend the driver involved in an accident in order to avoid his imprisonment',
        },
        {
          value: 'Windscreen Cover',
          amount: '250',
          premium: '-',
          detail:
            'The benefit provides cover for the front windscreen in addition to the side and rear windows. In summary, this benefit provides cover due to accidental breakage. It does not have to be due to a physical accident. A stone can cause breakage of a windscreen for example',
        },
        {
          value: 'Third Party Cover',
          premium: '101,02 €',
          detail: 'Here you can see the premium for the Third Party Cover',
        },
        {
          value: 'Driving other cars',
          key: 'DrivingOtherCarsBenefit',
          responseKey: 'BenDrivingOtherCarsLoading',
          toggle: !!data['Basic Plus']?.DrivingOtherCarsBenefit,
          selectedPremium: '2,50 €',
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'This benefit provides Third Party Liability cover whilst driving another private motor vehicle up to the same engine capacity and type as the vehicle insured with Gan Direct. The vehicle must not belong to the customer or hired to the customer under a hire purchase agreement or otherwise or belong to spouse or employer or partner. This cover only applies to one registered owner being the policyholder, provided that the vehicle driven has valid road tax, MOT and Insurance',
        },
        {
          value: 'Drawing a trailer',
          key: 'Trailer',
          responseKey: 'TrailerLoading',
          toggle: !!data['Basic Plus']?.Trailer,
          amountOptions: [
            { value: 1, label: 'Specified' },
            { value: 2, label: 'Unspecified' },
          ],
          unspecifiedAmount: '10,25 €',
          specifiedAmount: '5,13 €',
          amount: '',
          premium: '-',
          detail:
            'Provides Third Party Legal Liability cover for a trailer whilst attached to the insured vehicle. Please choose from the list between Specified or Unspecified Trailer',
        },
        {
          value: 'Claim protection',
          key: 'ClaimProtectionBenefit',
          toggle: !!data['Basic Plus']?.ClaimProtectionBenefit,
          responseKey: 'BenClaimProtectionLoading',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          toggleOnValue: '2',
          selectedPremium: '5,00 €',
          premium: '-',
          detail:
            'When a claim is submitted, this benefit protects the customer from paying an additional premium that would apply at the following renewal date due to the claim. This applies to one and only claim for a year of insurance irrespective of the cost of the claim',
        },
        {
          value: 'Legal Protection Assistance',
          amount: 'Free',
          premium: '-',
          detail:
            'With this free service, our company will protect your legal rights every step of the way, with representation from experienced lawyers introduced by our company, committed to defending your interests and seeking compensation for bodily injuries, damages to the vehicle and other losses suffered by the driver and/or any passengers, if such damage claims have not been settled amicably. More specifically we will ensure that you will not be required to pay any legal fees, court fees and witness and expert witness fees',
        },
        {
          value: 'Claim Support Service',
          amount: 'Free',
          premium: '-',
          detail:
            'With this service on your behalf, we will ensure a fair and prompt service of your claim’s handling process by the other insurance companies and/or uninsured driver(s), till your case is settled',
        },
        {
          value: 'Roadside Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of a breakdown of the Insured Vehicle. We provide assistance at the roadside if you are broken. If unable to fix your vehicle at the roadside, tow your vehicle and up to 5 passengers to a local repairer or a local destination of your choice within 16 KM from the breakdown point',
        },
        {
          value: 'Claim Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of an accident/ incident that might lead to a claim. Notifying our 24Hrs Claim Assistant is a contractual obligation of all our customers. With our immediate visit at the scene within 24 minutes if you are within town limits, we are there to provide support and safeguard our customer’s interests by taking them through the correct procedure of a claim, completing all the necessary documents, taking photographs of the damages and towing away the vehicle if is needed',
        },
        {
          value: 'Business Assistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Available 24 hours a day, 7 days a week, 365 days a year for all authorized drivers in the unfortunate event of a breakdown of the Insured Vehicle. We provide assistance at the roadside if you are broken. If unable to fix your vehicle at the roadside, tow your vehicle and up to 5 passengers to a local repairer or a local destination of your choice within 16 KM from the breakdown point',
        },
        {
          value: '24 Hours Glass Replacement',
          amount: 'Included',
          premium: '-',
          detail:
            'The 24 Hours Glass Replacement Service provides replacement of your damage glass in 24hrs',
        },
        {
          value: 'Relay',
          toggle: !!data['Basic Plus']?.Relay,
          key: 'Relay',
          amount: 'Not selected',
          toggleOnAmount: 'Selected',
          responseKey: 'RelayLoading',
          toggleOnValue: 1,
          selectedPremium: '10,00 €',
          premium: '-',
          detail:
            'Relay Assistance covers towing of a vehicle to any destination of the customer’s choice and offers lift to up to 5 people',
        },
        {
          value: 'Stay Mobile',
          key: 'StayMobile',
          toggle: !!data['Basic Plus']?.StayMobile,
          amount: 'Not selected',
          selectedPremium: '15,00 €',
          responseKey: 'StayMobileLoading',
          toggleOnAmount: 'Selected',
          premium: '-',
          detail:
            'Stay Mobile provides to the insured an alternative vehicle whilst his/her vehicle is being repaired. A customer with a Stay Mobile Service may decide not to take an alternative vehicle but stay in a hotel in the area they had the breakdown for 1 night. In such a case we provide accommodation with breakfast for up to 5 people. Stay Mobile also has a third choice for the customer which is free of use of public transportation',
        },
      ];
    }
    default:
      return;
  }
};

export const getCoverDetailsHome = (type, data, storageData) => {
  switch (type) {
    case 'Value': {
      return [
        {
          value: 'Building Cover',
          toggle: !!data['Value']?.BuildingSumInsured,
          responseKey: 'BuildingSum',
          amount:
            data['Value']?.BuildingSumInsured ||
            storageData?.parcel?.main_building?.surface * 1200,
          key: 'BuildingSumInsured',
          toggleOnAmount: '',
          search: true,
          premium: '-',
          detail:
            'Please choose if you wish to insure the Buildings of your property and if so enter the amount you wish to insure your buildings for. Please note that the amount should reflect the re-building value of your buildings in today’s prices using the same material that it is built of. Also note that this amount must be revised and adjusted at every renewal. The sum insured is the amount of money for which your property is covered. It is the most we will pay under any circumstances. In addition to the physical structure of the Buildings you should include outbuildings such as garages, garden sheds and swimming pools. The market value of your home has no direct relationship to the rebuilding cost of your home',
        },
        {
          value: 'Contents Cover',
          toggle: !!data['Value']?.ContentsSumInsured,
          responseKey: 'ContentsSum',
          amount:
            data['Value']?.ContentsSumInsured ||
            storageData?.parcel?.content_sum ||
            '0',
          key: 'ContentsSumInsured',
          toggleOnAmount: '',
          search: true,
          premium: '-',
          detail:
            'Please choose if you wish to insure the Contents of your property and if so enter here the amount you wish to insure your contents for. Please note that the amount should reflect the replacement value of your contents in today’s prices. Also note that this amount must be revised and adjusted at every renewal. As contents you should include all household goods and all other personal property, fixtures & fittings including radio and television aerials, satellite dishes, their fittings and mast, that are fixed to the building and are owned by you or any permanent member of your household',
        },
        {
          value: 'High Risk Items Sum Insured',
          search: true,
          amount: 0,
          responseKey: 'SpecifiedItemsSum',
          premium: '-',
          detail:
            'Enter here the total value of your High Risk Items. Please note that this amount must be included in the total amount insert above. High Risk Items are gold, silver, gold and silver plated articles, jewellery, furs, antiques, paintings and works of art. Please note that if the total value of your high risk items is more than €2000 then must be specified in the inventory list and any items with individual cost more than €500 must be followed by evaluations and photos. Any High Risk Items must be kept in wall-mounted safe for cover to apply',
        },
        {
          value: 'Earthquake Excess',
          search: true,
          key: 'EarthquakeExcess',
          responseKey: 'BuildingEarthquake',
          amount: data['Value']?.EarthquakeExcess || {
            value: 1148,
            label: '2.5%',
          },
          amountOptionsNoToggle: [
            {
              value: 1148,
              label: '2.5%',
            },
          ],
          premium: '-',
          detail:
            'Please choose the Excess for the Earthquake Cover. By choose a higher excess amount you will benefit with lower premium',
        },

        {
          value: 'Consecutive Days Unoccupied',
          key: 'ConsecutiveDaysUnoccupiedFor',
          responseKey: 'UnlimitedUnoccupancy',
          amount: data['Value']?.ConsecutiveDaysUnoccupiedFor || {
            value: '1',
            label: 'Up to 30 Consecutive Days',
          },
          amountOptionsNoToggle: [
            {
              value: '1',
              label: 'Up to 30 Consecutive Days',
            }, //prem: 0
            {
              value: '2',
              label: 'Up to 60 Consecutive Days',
            }, //prem: 35,10
            {
              value: '3',
              label: 'More than 60 Consecutive Days',
            }, //prem 35,10
          ],
          //depending on the amount of days unattended -> the premium will be different
          premium: '-',
          detail:
            'Specify from the list, the number of consecutive days that the property is left unoccupied. Unoccupied means that the property is not occupied by the insured',
        },
        {
          value: 'Employers Liability',
          amount: 'Not selected',
          key: 'EmployersLiablity',
          responseKey: 'EmployersLiability',
          toggle: !!data['Value']?.EmployersLiablity,
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          //add employees
          premium: '-',
          detail:
            'Employers Liability is an optional benefit of our policy at an additional premium and is needed if you employ domestic staff. The Employers Liability is compulsory by law for anyone that employ staff including the Domestic Staff. Employers Liability covers the Insured’s legal liability for bodily injury to any employee that works for the Insured Person',
        },
        {
          value: 'Legal Protection Assistance',
          amount: 'Free',
          responseKey: 'LegalProtectionAssistance',
          premium: '-',
          detail:
            'With this free service, our company will protect your legal rights every step of the way, with representation from experienced lawyers introduced by our company, committed to defending your interests and seeking compensation for bodily injuries, damages to the vehicle and other losses suffered by the driver and/or any passengers, if such damage claims have not been settled amicably. More specifically we will ensure that you will not be required to pay any legal fees, court fees and witness and expert witness fees',
        },
        {
          value: 'Claim Support Service',
          amount: 'Free',
          premium: '-',
          detail:
            'With this service on your behalf, we will ensure a fair and prompt service of your claim’s handling process by the other insurance companies and/or uninsured driver(s), till your case is settled',
        },
        {
          value: 'Response',
          amount: '€ 60 per Call 3 Calls',
          premium: '-',
          responseKey: 'Response',
          detail:
            'Response is a Free benefit of our 24Hrs Property Assistance membership. Response covers the call-out fees as well as the first hour’s labor on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Total Response',
          key: 'TotalResponse',
          toggle: !!data['Value']?.TotalResponse,
          // amount: data["Value"]?.TotalResponse || "",
          amount: '€500 - 3 Calls',
          premium: '-',
          detail:
            'Total Response is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. Response covers the call-out fees as well as parts and labor up to the selected amount on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Total Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Home Secure',
          key: 'HomeBusinessSecure',
          toggle: !!data['Value']?.HomeBusinessSecure,
          toggleOnAmount: 'Home basic',
          toggleOnValue: 1,
          responseKey: 'HomeBusinessSecure',
          premium: '-',
          detail:
            'Home/Business Secure is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. The Home/Business Secure Service is offering services for monitor and respond to signals from an alarm monitoring system in cooperation with a licensed center.',
        },
      ];
    }
    case 'Tenant': {
      return [
        {
          value: 'Contents Cover',
          toggle: true,
          amount:
            data['Value']?.ContentsSumInsured ||
            storageData?.parcel?.content_sum ||
            '20000',
          key: 'ContentsSumInsured',
          responseKey: 'ContentsSum',
          toggleOnAmount: '',
          search: true,
          premium: '-',
          detail:
            'Please choose if you wish to insure the Contents of your property and if so enter here the amount you wish to insure your contents for. Please note that the amount should reflect the replacement value of your contents in today’s prices. Also note that this amount must be revised and adjusted at every renewal. As contents you should include all household goods and all other personal property, fixtures & fittings including radio and television aerials, satellite dishes, their fittings and mast, that are fixed to the building and are owned by you or any permanent member of your household',
        },
        {
          value: 'High Risk Items Sum Insured',
          search: true,
          responseKey: 'SpecifiedItemsSum',
          amount: 0,
          premium: '-',
          detail:
            'Enter here the total value of your High Risk Items. Please note that this amount must be included in the total amount insert above. High Risk Items are gold, silver, gold and silver plated articles, jewellery, furs, antiques, paintings and works of art. Please note that if the total value of your high risk items is more than €2000 then must be specified in the inventory list and any items with individual cost more than €500 must be followed by evaluations and photos. Any High Risk Items must be kept in wall-mounted safe for cover to apply',
        },
        {
          value: 'Earthquake Excess',
          search: true,
          key: 'EarthquakeExcess',
          responseKey: 'BuildingEarthquake',
          amount: data['Value']?.EarthquakeExcess || {
            value: 1148,
            label: '2.5%',
          },
          amountOptionsNoToggle: [
            {
              value: 1148,
              label: '2.5%',
            },
          ],
          premium: '-',
          detail:
            'Please choose the Excess for the Earthquake Cover. By choose a higher excess amount you will benefit with lower premium',
        },

        {
          value: 'Consecutive Days Unoccupied',
          key: 'ConsecutiveDaysUnoccupiedFor',
          responseKey: 'UnlimitedUnoccupancy',
          amount: data['Value']?.ConsecutiveDaysUnoccupiedFor || {
            value: '1',
            label: 'Up to 30 Consecutive Days',
          },
          amountOptionsNoToggle: [
            {
              value: '1',
              label: 'Up to 30 Consecutive Days',
            }, //prem: 0
            {
              value: '2',
              label: 'Up to 60 Consecutive Days',
            }, //prem: 35,10
            {
              value: '3',
              label: 'More than 60 Consecutive Days',
            }, //prem 35,10
          ],
          //depending on the amount of days unattended -> the premium will be different
          premium: '-',
          detail:
            'Specify from the list, the number of consecutive days that the property is left unoccupied. Unoccupied means that the property is not occupied by the insured',
        },
        {
          value: 'Employers Liability',
          amount: 'Not selected',
          responseKey: 'EmployersLiability',
          key: 'EmployersLiablity',
          toggle: !!data['Value']?.EmployersLiablity,
          toggleOnAmount: 'Selected',
          toggleOnValue: 1,
          //add employees
          premium: '-',
          detail:
            'Employers Liability is an optional benefit of our policy at an additional premium and is needed if you employ domestic staff. The Employers Liability is compulsory by law for anyone that employ staff including the Domestic Staff. Employers Liability covers the Insured’s legal liability for bodily injury to any employee that works for the Insured Person',
        },
        {
          value: 'Legal Protection Assistance',
          amount: 'Free',
          responseKey: 'LegalProtectionAssistance',
          premium: '-',
          detail:
            'With this free service, our company will protect your legal rights every step of the way, with representation from experienced lawyers introduced by our company, committed to defending your interests and seeking compensation for bodily injuries, damages to the vehicle and other losses suffered by the driver and/or any passengers, if such damage claims have not been settled amicably. More specifically we will ensure that you will not be required to pay any legal fees, court fees and witness and expert witness fees',
        },
        {
          value: 'Claim Support Service',
          amount: 'Free',
          premium: '-',
          detail:
            'With this service on your behalf, we will ensure a fair and prompt service of your claim’s handling process by the other insurance companies and/or uninsured driver(s), till your case is settled',
        },
        {
          value: 'Response',
          amount: '€ 60 per Call 3 Calls',
          premium: '-',
          responseKey: 'Response',
          detail:
            'Response is a Free benefit of our 24Hrs Property Assistance membership. Response covers the call-out fees as well as the first hour’s labor on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Total Response',
          toggle: !!data['Value']?.TotalResponse,
          key: 'TotalResponse',
          // amount: data["Value"]?.TotalResponse || "",
          amount: '€500 - 3 Calls',
          premium: '-',
          detail:
            'Total Response is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. Response covers the call-out fees as well as parts and labor up to the selected amount on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Total Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Home Secure',
          key: 'HomeBusinessSecure',
          responseKey: 'HomeBusinessSecure',
          toggle: !!data['Value']?.HomeBusinessSecure,
          toggleOnAmount: 'Home basic',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Home/Business Secure is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. The Home/Business Secure Service is offering services for monitor and respond to signals from an alarm monitoring system in cooperation with a licensed center.',
        },
      ];
    }
    case 'Premium': {
      return [
        {
          value: 'Building Cover',
          toggle: !!data['Premium']?.BuildingSumInsured,
          responseKey: 'BuildingSum',
          amount:
            data['Value']?.BuildingSumInsured ||
            storageData?.parcel?.main_building?.surface * 1200,
          key: 'BuildingSumInsured',
          toggleOnAmount: '',
          search: true,
          premium: '-',
          detail:
            'Please choose if you wish to insure the Buildings of your property and if so enter the amount you wish to insure your buildings for. Please note that the amount should reflect the re-building value of your buildings in today’s prices using the same material that it is built of. Also note that this amount must be revised and adjusted at every renewal. The sum insured is the amount of money for which your property is covered. It is the most we will pay under any circumstances. In addition to the physical structure of the Buildings you should include outbuildings such as garages, garden sheds and swimming pools. The market value of your home has no direct relationship to the rebuilding cost of your home',
        },
        {
          value: 'Contents Cover',
          responseKey: 'ContentsSum',
          toggle: !!data['Premium']?.ContentsSumInsured,
          amount:
            data['Premium']?.ContentsSumInsured ||
            storageData?.parcel?.content_sum ||
            '0',
          key: 'ContentsSumInsured',
          toggleOnAmount: 13000,
          search: true,
          premium: '-',
          detail:
            'Please choose if you wish to insure the Contents of your property and if so enter here the amount you wish to insure your contents for. Please note that the amount should reflect the replacement value of your contents in today’s prices. Also note that this amount must be revised and adjusted at every renewal. As contents you should include all household goods and all other personal property, fixtures & fittings including radio and television aerials, satellite dishes, their fittings and mast, that are fixed to the building and are owned by you or any permanent member of your household',
        },
        {
          value: 'High Risk Items Sum Insured',
          search: true,
          amount: '',
          responseKey: 'SpecifiedItemsSum',
          premium: '-',
          detail:
            'Enter here the total value of your High Risk Items. Please note that this amount must be included in the total amount insert above. High Risk Items are gold, silver, gold and silver plated articles, jewellery, furs, antiques, paintings and works of art. Please note that if the total value of your high risk items is more than €2000 then must be specified in the inventory list and any items with individual cost more than €500 must be followed by evaluations and photos. Any High Risk Items must be kept in wall-mounted safe for cover to apply',
        },
        {
          value: 'Earthquake Excess',
          search: true,
          responseKey: 'BuildingEarthquake',
          key: 'EarthquakeExcess',
          amount: data['Premium']?.EarthquakeExcess || {
            value: 1148,
            label: '2.5%',
          },
          amountOptionsNoToggle: [
            {
              value: 1148,
              label: '2.5%',
            },
          ],
          premium: '-',
          detail:
            'Please choose the Excess for the Earthquake Cover. By choose a higher excess amount you will benefit with lower premium',
        },
        {
          value: 'Accidental Damage to Building',
          toggle: false,
          responseKey: 'AccidentalDamageToTheBuilding',
          amount: 'Not selected',
          toggleOnAmount: '180.000,00 €',
          toggleOnValue: 1,
          key: 'AccidentalDamageToBuilding',
          premium: '-',
          detail:
            'Accidental Damage to the Building is an optional benefit with additional premium. Accidental Damage Cover for your buildings extends your standard cover to include accidental damage to the rest of your buildings. Our standard cover includes accidental damage to underground pipes and cables, fixed glass, and sanitary fixtures and fittings like baths, shower trays, sinks etc. By choosing our extra accidental damage cover for buildings you extend this cover to include accidental damage to the rest of your buildings e.g. putting your foot through the ceiling, hitting a nail into a water pipe. In addition by choosing this benefit you will cover your Solar Panels from Frost Damage or Escape of water from them',
        },
        {
          value: 'Accidental Damage to Contents',
          toggle: false,
          amount: 'Not selected',
          responseKey: 'AccidentalDamageToContetns',
          toggleOnAmount: '0,00 €',
          toggleOnValue: 1,
          key: 'AccidentalDamageToContents',
          premium: '-',
          detail:
            'Accidental Damage to the Contents is an optional benefit with additional premium. Accidental Damage Cover for your home contents insurance, extends the standard accidental damage cover to the rest of your home contents. Our standard cover includes accidental damage to Audio and Audio Visual Units including Television Sets, Video Recorders and Home Computers. By choosing our extra accidental damage cover for Contents you extend this cover to include accidental damage to the most contents and soft furnishings',
        },
        {
          value: 'Valuable & Personal Effects',
          toggle: false,
          amount: 'Not selected',
          key: 'ValuableAndPersonalEffects',
          toggleOnValue: 1,
          responseKey: 'TotalValueOfPersonalItems',
          toggleOnAmount: '2,000 €',
          premium: '-',
          detail:
            "Valuables and Personal effects is an optional benefit with additional premium. The benefit provides cover for your Valuables and Personal Effects while you're away from home, within the European Union and whilst temporarily outside the European Union for a period not exceeding 30 days in any one period of Insurance. Valuables and Personal Effects are items that are carried about the person in everyday life; e.g. the clothes we wear, our jewellery and for example a handbag/carrying case. We specifically exclude mobile phones, palm held computers, money and credit cards",
        },
        {
          value: 'Specified Items',
          toggle: !!data['Premium']?.SpecifiedItemsTotalValue,
          amount: data['Premium']?.SpecifiedItemsTotalValue || '0',
          key: 'SpecifiedItemsTotalValue',
          responseKey: 'TotalValueOfSpecifiedItems',
          toggleOnAmount: '0',
          premium: '-',
          search: true,
          detail:
            "Specified items, is an optional benefit with additional premium. The benefit provides cover for items you specify while you're away from home, within the European Union and whilst temporarily outside the European Union for a period not exceeding 30 days in any one period of Insurance. Specified Items will usually be for jewellery; laptop, camera or any other item you usually use outside the house",
        },
        {
          value: 'Contents of Frozen Food Cabinet',
          toggle: !!data['Premium']?.DomesticFreezeContents,
          amount: data['Premium']?.DomesticFreezeContents || '0',
          key: 'DomesticFreezeContents',
          toggleOnAmount: '300 €',
          responseKey: 'DomesticFreezerSum',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Cover to the Contents of a Domestic Deep Freeze is an optional cover with additional premium. We provide cover for the contents of a frozen food cabinet or domestic refrigerator in case of Deterioration or Putrefaction due to a change in temperature or contamination from refrigeration fumes. We will not cover electricity failure due to deliberate act to cut power by the electricity company; like bill not paid or the Electricity Supplier goes on strike',
        },
        {
          value: 'Personal Money & Credit Cards',
          toggle: !!data['Premium']?.PersonalMoneyAndCreditCards,
          key: 'PersonalMoneyAndCreditCards',
          amount: 'Not selected',
          responseKey: 'PersonalMoneyAndCreditCards',
          toggleOnAmount: '500 €',
          toggleOnValue: 1,
          premium: '-',
          detail:
            'Cover for Personal Money & Credit cards, is an optional cover with additional premium. By Personal Money and Credit Cards we mean money belonging to the Insured which is kept and used solely for private, social and domestic purposes. Cover is provided for loss or damage to personal money within the European Union. In addition, cover is provided whilst outside the European Union provided the period does not exceed 30 days in any one period of insurance',
        },
        {
          value: 'Tracing the Leak',
          responseKey: 'TracingTheLeakSum',
          amount: data['Premium']?.TracingTheLeakCoverAmount || {
            value: '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a',
            label: '0',
          },
          key: 'TracingTheLeakCoverAmount',
          // toggleOnAmount: "500 €",
          premium: '-',
          amountOptionsNoToggle: [
            { value: '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a', label: '0' },
            { value: '346029e9-6645-4b3f-a1ab-80b314e86c84', label: '1000' },
            { value: 'f2fd3ff2-b9e9-49f6-b046-e83142990e07', label: '2000' },
            { value: '842eb0b1-8b95-40dc-8ac1-3b461dac7900', label: '3000' },
          ],
          detail:
            'Cover for Tracing the Leak is an optional cover with additional premium. This cover removes the exclusion of the Escape of Water regarding the cost of locating the point of escape of water or repair any such fixed water tanks, apparatus or pipes',
        },
        {
          value: 'Consecutive Days Unoccupied',
          search: true,
          key: 'ConsecutiveDaysUnoccupiedFor',
          responseKey: 'UnlimitedUnoccupancy',
          amount: data['Premium']?.ConsecutiveDaysUnoccupiedFor || {
            value: '1',
            label: 'Up to 30 Consecutive Days',
          },
          amountOptionsNoToggle: [
            {
              value: '1',
              label: 'Up to 30 Consecutive Days',
            }, //prem: 0
            {
              value: '2',
              label: 'Up to 60 Consecutive Days',
            }, //prem: 35,10
            {
              value: '3',
              label: 'More than 60 Consecutive Days',
            }, //prem 35,10
          ],
          //depending on the amount of days unattended -> the premium will be different
          premium: '-',
          detail:
            'Specify from the list, the number of consecutive days that the property is left unoccupied. Unoccupied means that the property is not occupied by the insured',
        },

        {
          value: 'Pedal Cycles',
          toggle: !!data['Premium']?.UsePedalCycles,
          key: 'UsePedalCycles',
          toggleOnValue: 1,
          toggleOnAmount: 'Included',
          responseKey: 'BicycleSum',
          // toggleOnAmount: "500 €",
          premium: '-',
          detail:
            'Pedal Cycles is optional benefit at an additional premium. This benefit provides cover for accidental damage and loss or damage by theft to Pedal Cycles within European Union. You can specify up to six cycles with a sum insured for each cycle. The main exclusions are that we do not cover theft or attempted theft of accessories of the cycle unless the cycle is stolen at the same time or theft of the cycle unless it was locked to an immovable object or kept in a locked building when left unattended',
        },
        {
          value: 'Pet Insurance',
          amount: 'Not selected',
          search: true, //number and amount
          responseKey: 'PetsSum',
          premium: '-',
          detail:
            'Pet Insurance is available as an additional optional benefit at an additional premium. It is available for Pets not less than 8 weeks old at commencement of the Insurance and you can insure up to 5 pets. Cover includes: • Veterinary Fees • Accidental Death of the Pet • Theft or Straying • Hospitalization of the Owner • Third Party Liability (Dogs Only)',
        },
        {
          value: 'Classic Vehicle Cover',
          amount: 'Not selected',
          responseKey: 'ClassicCarSum',
          search: true, //number and amount
          premium: '-',
          detail:
            'Classic Vehicle Cover is optional with additional premium. Cover protects the classic car from loss or damage whilst it is parked in a closed area within the home. Loss or damage must be directly as a result of an Insured event. An Insured Event is Fire, Lightning, Explosion, Earthquake, Aircraft, Storm, Flood, Tornado, Escape of Water or Oil, Theft or Attempted Theft, Impact, Riot, Civil Commotion, Malicious Damage, Subsidence, Landslip or Heave, Falling Trees, Telegraph Poles or Lamp Posts. In addition, cover also includes loss or damage to the Classic Vehicle whilst being towed or whilst being loaded or unloaded to a traile',
        },
        {
          value: 'Speed Boats Third Party Liability',
          amount: 'Not selected',
          toggleOnAmount: '85,000* €',
          premium: '-',
          responseKey: 'SpeedBoat',
          detail:
            'Cover for Third Party Speed Boat Liability is an optional cover with additional premium. A speed boat can be a pleasure craft, speedboat or Jet Ski used for private and pleasure purposes only. This cover also extends to any person who is navigating or in charge of the speed boat with the consent of the Insured. The cover we provide is for legal liability for any claims against the Insured person by reason of interest in the boat. As an example, the Insured causes damage to a third party person or third party property then we may become legally liable to compensate the third party. There is NO cover available for the Boat itself',
        },
        {
          value: 'Jet-Skiers Liability',
          amount: 'Not selected',
          toggleOnAmount: '85,000* €',
          responseKey: 'SkiersLiablity',
          premium: '-',
          detail:
            'Third party Jet Skiers Liability in respect of all claims which the insured shall by reason of interest in the insured speed boat become legally liable to pay and shall pay. The indemnity payable in respect of any accident or series of accidents arising out of the same event, shall be limited to the sum specified in the Schedule',
        },
        {
          value: 'Passengers Liability',
          amount: 'Not selected',
          responseKey: 'PassengersLiabiltiy',
          toggleOnAmount: '85,000* €',
          premium: '-',
          detail:
            'Cover for Passengers Liability is optional with additional premium. This cover provides Third Party Liability for up to 6 passengers excluding the driver in respect of all claims which the Insured become legally liable to pay and shall pay. As an example; a passenger throws a bottle out of the boat and hits a third party on the head who was swimming near the boat',
        },
        {
          value: 'Hunters Liability',
          amount: 'Not selected',
          toggleOnAmount: '45,000 €',
          premium: '-',
          responseKey: 'HuntersLiability',
          detail:
            'Hunters Liability is available as an optional benefit at an additional premium. This cover provides legal liability to the insured in respect of bodily injury or damage to property for sums the insured shall and become liable to pay whilst Hunting. Hunter Liability excludes the Hunting Dog. Example of cover: The Insured accidentally shoots a Third Party person and/or damages Third Party property whilst hunting',
        },
        {
          value: 'Medical to Domestic Staff',
          amount: 'Not selected',
          search: true, //date of birth
          // toggleOnAmount: "45,000 €",
          premium: '-',
          responseKey: 'MedicalAlienSum',
          detail:
            'Medical to Domestic Staff is an optional benefit with an additional premium. This benefit provides Medical Insurance for the Domestic Staff of your household. Covers 1. In Patient Treatment 2. Childbirth Cover 3. Repatriation of Corpse 4. Out Patient Treatment',
        },
        {
          value: 'Accidents to Domestic Staff',
          amount: 'Applicable',
          premium: '-',
          detail:
            'The benefit for Accidents to Domestic Staff covers the legal liability in respect of Bodily Injury by accident or disease to any domestic staff of the insured employed when the contents cover has been selected',
        },
        {
          value: 'Employers Liability',
          amount: 'Not selected',
          toggle: false,
          responseKey: 'EmployersLiabilitySum',
          toggleOnAmount: 'Selected',
          //add employees
          premium: '-',
          detail:
            'Employers Liability is an optional benefit of our policy at an additional premium and is needed if you employ domestic staff. The Employers Liability is compulsory by law for anyone that employ staff including the Domestic Staff. Employers Liability covers the Insured’s legal liability for bodily injury to any employee that works for the Insured Person',
        },
        {
          value: 'Legal Protection Assistance',
          amount: 'Free',
          premium: '-',
          detail:
            'With this free service, our company will protect your legal rights every step of the way, with representation from experienced lawyers introduced by our company, committed to defending your interests and seeking compensation for bodily injuries, damages to the vehicle and other losses suffered by the driver and/or any passengers, if such damage claims have not been settled amicably. More specifically we will ensure that you will not be required to pay any legal fees, court fees and witness and expert witness fees',
        },
        {
          value: 'Claim Support Service',
          amount: 'Free',
          premium: '-',
          detail:
            'With this service on your behalf, we will ensure a fair and prompt service of your claim’s handling process by the other insurance companies and/or uninsured driver(s), till your case is settled',
        },
        {
          value: 'Response',
          amount: '€ 60 per Call 3 Calls',
          premium: '-',
          detail:
            'Response is a Free benefit of our 24Hrs Property Assistance membership. Response covers the call-out fees as well as the first hour’s labor on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Total Response',
          key: 'TotalResponse',
          toggle: !!data['Premium']?.TotalResponse,
          responseKey: 'TotalResponse',
          // amount: data["Premium"]?.TotalResponse || "",
          amountOptions: [
            {
              value: '1',
              label: 'Plan A - Annual Limit 500',
            },
            {
              value: '2',
              label: 'Plan B - Annual Limit 750',
            },
            {
              value: '3',
              label: 'Plan C - Annual Limit 1000',
            },
          ],
          premium: '-',
          detail:
            'Total Response is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. Response covers the call-out fees as well as parts and labor up to the selected amount on emergency events related with the Electrical Wiring, Plumbing and Drainage, Security and Heating. In addition Total Response provides 12 month guarantee for all permanent repairs performed',
        },
        {
          value: 'Home Secure',
          key: 'HomeBusinessSecure',
          toggle: !!data['Value']?.HomeBusinessSecure,
          toggleOnAmount: 'Home Premium',
          toggleOnValue: 2,
          responseKey: 'HomeSecure',
          premium: '-',
          detail:
            'Home/Business Secure is an optional benefit of our 24Hrs Property Assistance membership with an additional premium. The Home/Business Secure Service is offering services for monitor and respond to signals from an alarm monitoring system in cooperation with a licensed center.',
        },
      ];
    }
    default:
      break;
  }
};

export const getCoverDetailsHealth = (type, data) => {
  switch (type) {
    case 'Value': {
      return [
        {
          value: 'In Patient & Day Patient Treatment',
          toggle: !!data?.MedicalInPatient,
          amount: data?.MedicalInPatient,
          responseKey: 'InPatient',
          key: 'MedicalInPatient',
          type: 'BOOL',
          toggleOnAmount: 1,
          coverLimit: '€ 200.000,00',
          search: false,
          premium: '-',
          detail:
            'In Patient treatment is the covered provided to any insured person who remains overnight or longer in Hospital, and is admitted solely to receive Medically Necessary Treatment for an Insured Medical Condition under the policy. Similarly, Day Patient Treatment is provided to an insured person who is admitted to a hospital solely to receive medically necessary treatment that is covered by this policy and for a period of clinically- supervised recovery or treatment but does not remain in hospital overnight. This section is divided into sub sections. • Room and Board in a Hospital, Clinic or Surgical room • Room and Board in an Intensive Care Unit • Doctor’s Fees for treatment not operational in general ward • Doctor’s Fees for treatment in an Intensive Care Unit • Accidents, Emergencies, Intensive Care • Surgeons, Assistant Surgeons, Anesthesiologists • Medical Practitioners • Prescribed Medicine, Dressings • Restorative Surgery • Diagnostic Tests and Procedures, X-rays, & MRI/CT Scans • Treatment for Cancer • Physiotherapy • Parental Hospital Accommodation • Prosthetic Devices and Implants',
        },
        {
          value: 'Reimbursement Method',
          type: 'ENUM',
          amount: data?.ReimbursementMethod || 3,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'According to Schedule of Fees',
            }, //prem: 0
            {
              value: 3,
              label: 'Schedule of Fees Plus 10%',
            }, //prem: 35,10
            {
              value: 4,
              label: 'Schedule of Fees Plus 15%',
            }, //prem 35,10
          ],
          key: 'ReimbursementMethod',
          responseKey: 'ReimbursementMethod',
          premium: '-',
          detail:
            'Choose from the List the Reimbursement Method. Reimbursement is a Term that we meet in Health Insurance Policies that refers to the compensation for Health Care Services. In simple words it means the method that the Insurance Company will use in order to decide the amount that will pay out following a Medical Incident.',
        },
        {
          value: 'Geographical Area',
          type: 'ENUM',
          responseKey: 'GeoAreaCover',
          amount: data?.GeographicalArea || 3,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'Cyprus',
            }, //prem: -21,61 €
            {
              value: 2,
              label: 'Cyprus and Greece',
            }, //prem: -13,72 €
            {
              value: 3,
              label: 'Europe',
            }, //prem -13,72 €
            {
              value: 4,
              label: 'Worldwide',
            }, //prem 2,06 €
            {
              value: 5,
              label: 'Worldwide Excluding USA/Canada',
            }, //prem 2,06 €
          ],
          key: 'GeographicalArea',
          premium: '-',
          detail:
            'Choose from the List the Geographical areas you wish cover for.',
        },
        {
          value: 'Deductible',
          key: 'InPatientDeductible',
          responseKey: 'Deductible',
          type: 'ENUM',
          amount: data?.InPatientDeductible || 3,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: '€ 0',
            }, //premium 0
            {
              value: 2,
              label: '€ 250',
            }, //premium -41,16 €
            {
              value: 3,
              label: '€ 450',
            }, //premium -72,03 €
            {
              value: 4,
              label: '€ 600',
            }, // premium -89,18 €
            {
              value: 5,
              label: '€ 850',
            }, //premium -120,05 €
            {
              value: 6,
              label: '€ 1700',
            }, // premium -185,22 €
            {
              value: 7,
              label: '€ 5000',
            }, // premium -240,10 €
            {
              value: 8,
              label: '€ 7500',
            }, // premium -257,25 €
            {
              value: 9,
              label: '€ 10000',
            }, // premium -274,40 €
          ],
          premium: '-',
          detail:
            'Choose from the List the first amount paid by you (or on your behalf) for every insured Medical Condition, for every Period of Insurance in respect to the eligible expenses/ charges and covers, before any benefits are paid under your policy and excluding the Co- Insurance. If treatment has gone on for more than the Period of Insurance, we will treat it as a new claim for any further treatment after the date and we will reapply any Exemption. It is an optional amount to be deducted from a claimed amount on reimbursement. By choosing to have a Deductible Amount you reduce the premium paid.',
        },
        {
          value: 'Co-Insurance',
          key: 'InPatientCoInsurance',
          responseKey: 'CoInsurance',
          toggle: data?.InPatientCoInsurance > 1,
          amount: data?.InPatientCoInsurance || 1,
          type: 'ENUM',
          toggleOnValue: 2,
          toggleOffValue: 1,
          amountOptions: [
            {
              value: 1,
              label: '0%',
            },
            {
              value: 2,
              label: '10%',
            },
            {
              value: 3,
              label: '20%',
            },
            {
              value: 4,
              label: '30%',
            }, //premium remains 0 but dependin on what you choose, the premium for  deductible and geographical area changes
          ],
          premium: '-',
          detail:
            'Choose from the List the Co- Insurance percentage. With Co-Insurance, the insured undertakes the liability to pay for a percentage of the reimbursement. By choosing to have Co-Insurance you reduce the premium paid.',
        },
        {
          value: 'New Born Baby Benefit',
          key: 'NewBornBaby',
          type: 'BOOL',
          responseKey: 'NewBornBabyBenefit',
          toggle: !!data?.NewBornBaby,
          // toggleOnAmount: '€1.000',
          toggleOnValue: 1,
          //if selected,premium is 20
          premium: '-',
          detail:
            'If you choose this benefit, Gan Direct will pay the amount of benefit to assist with the initial expenses you incurred during the Period of Insurance whilst preparing the family home for your Newborn provided that you have been continuously insured under your policy for at least 12 consecutive months immediately preceding the birth. This is not a cover of charges and it has nothing to do with the type of delivery (caesarean or other).',
        },
        {
          value: 'Out Patient Treatment',
          key: 'MedicalOutPatient',
          responseKey: 'OutPatient',
          // amount: 'Not selected',
          toggle: !!data?.MedicalOutPatient || false,
          // toggleOnAmount: '€2.000',
          type: 'BOOL',
          toggleOnValue: 1,
          //if selected,premium is 245
          premium: '-',
          detail:
            'If you choose this benefit and subject to the terms of this Policy we will pay for the treatment and surgery of an Out Patient. Out Patient Treatment covers: • Visit to General Practitioner or to a Specialist after Reference • Diagnostic Laboratory Examinations, Tests, X-Rays, MRI, CT Scans • Prescribed Medicine • Post-Surgical Physiotherapy',
        },
        {
          value: 'Travel Transportation and Out of Area Benefit',
          // amount: 'Not selected',
          responseKey: 'TravelTransportation',
          key: 'TravelTransportationAndOutOfGeorgaphycalArea',
          type: 'BOOL',
          // toggleOnAmount: 'Included',
          toggle: !!data?.TravelTransportationAndOutOfGeorgaphycalArea || true,
          toggleOnValue: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'With this benefit, Gan Direct will pay the expenses needed In the event of a serious physical injury resulting from an accident or a sudden illness of the insured while being out of Geographical Area and requires International Assistance and travel transportation. International Assistance/Travel Transportation refers to the cover of expenses, as below, during traveling abroad for not more than 60 days. We cover the following: • Medical Transportation and Treatment of an Emergency, until the Patient’s condition is stable and he/she is allowed to travel) • Repatriation of the Patient and his/her accompanying relatives. • Fare expenses of a Family Member if the treatment lasts for more than 10 days. • Accommodation expenses for the Family Member (room only for up to 7 days and up to € 650) • Repatriation of Corpse',
        },
        {
          value: 'Death Benefit',
          search: true,
          key: 'DeathBenefit',
          responseKey: 'DeathCover',
          targetKey: 'DeathAmountInsured',
          type: 'ENUM',
          toggle: !!data?.DeathBenefit || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          // amount: data["Value"]?.TotalResponse || "",
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 7,10
            {
              value: 3,
              label: '20000',
            }, //premium 14,20
            {
              value: 4,
              label: '30000',
            }, // premium 21,30
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 7,10 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '100000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'We will cover in case of Insured Death if it occurs due to an Occupational Hazard (accident/ disease). Occupational Hazard is the risk that comes from work activity or occupational activity. Work or occupation is somebody’s activity for economic or ethic reward (i.e. housewife). The benefit aims to relief the beneficiaries, in case of the insured’s death, from all relevant financial problems arising after such an event. Such financial problems are Taxes, Loans, Last expenses, Funeral expenses ect.',
        },
        {
          value: 'Dread Benefit',
          search: true,
          key: 'DreadDisease',
          responseKey: 'DreadDiseaseAmount',
          targetKey: 'DreadSumInsured',
          type: 'ENUM',
          toggle: !!data?.DreadDisease || false,
          toggleOnValue: 5,
          toggleOffValue: 0,
          // amount: data["Value"]?.TotalResponse || "",
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 10,80
            {
              value: 3,
              label: '20000',
            }, //premium 21,60
            {
              value: 4,
              label: '30000',
            }, // premium 32,40 €
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 10,80 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '10000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'This benefit aims not only to cover the medical costs but also to relief the customer from relative expenses. We consider as Dread Disease the following: Heart Attack, Stroke, Cancer, Kidney failure, Transplant of vital organs',
        },
        {
          value: 'Permanent Total Disability Benefit',
          search: true,
          key: 'PermanentTotalOrPartialDisability',
          responseKey: 'DisabilityCover',
          targetKey: 'DisabilityAmount',
          type: 'ENUM',
          toggle: !!data?.PermanentTotalOrPartialDisability || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          amountOptions: [
            {
              value: 1,
              label: 0,
            }, //premium 0
            {
              value: 2,
              label: 10000,
            }, //premium 8
            {
              value: 3,
              label: 20000,
            },
            {
              value: 4,
              label: 30000,
            },
            {
              value: 5,
              label: 40000,
            }, //premium goes up by 8 every 1000
            {
              value: 6,
              label: 50000,
            },
            {
              value: 7,
              label: 60000,
            },
            {
              value: 8,
              label: 70000,
            },
            {
              value: 9,
              label: 80000,
            },
            {
              value: 10,
              label: 90000,
            },
            {
              value: 11,
              label: 10000,
            },
            {
              value: 12,
              label: 110000,
            },
            {
              value: 13,
              label: 120000,
            },
            {
              value: 14,
              label: 140000,
            },
            {
              value: 15,
              label: 150000,
            },
            {
              value: 16,
              label: 160000,
            },
            {
              value: 17,
              label: 180000,
            },
            {
              value: 18,
              label: 200000,
            },
            {
              value: 19,
              label: 250000,
            },
            {
              value: 20,
              label: 300000,
            },
            {
              value: 21,
              label: 350000,
            },
            {
              value: 22,
              label: 400000,
            },
            {
              value: 23,
              label: 450000,
            },
            {
              value: 24,
              label: 500000,
            },
          ],
          premium: '-',
          detail:
            'This benefit covers Permanent Total Disability as a result of an Accident or a Disease. Permanently and Totally Disable is a person who permanently is not able to exercise any job and his/her ailment is permanently incurable. A medical board (of doctors) decides whether the total disability is Permanent 12 months after the accident or the occurrence of a disease.',
        },
        {
          value: 'Medical Call Center Service',
          key: 'MedicalCallCenteService',
          coverLimit: 'Included',
          responseKey: 'MedicalCallCenterService',
          toggle: !!data?.MedicalCallCenteService || false,
          type: 'BOOL',
          toggleOnAmount: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'You will have medical advice from qualified and specialized medical teams which includes supply of contact details for all medical institutions, in the area where the patient needs hospitalization.',
        },
        {
          value: '24Hrs Health Helpline Assistance',
          key: '24HrsHealthHelplineAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Health helpline assistance is there for you whenever you need it, and just a phone call away. If you are feeling unwell or would like some general healthcare information, then you can be put through to specialized medical teams. Additionally, our 24hr Medical Assistance Service provided with this policy will handle your claim as soon as you notify us and will provide you an unforgettable customer service.',
        },
        {
          value: '24Hrs Casualty Care Assistance',
          key: '24HrsCasualtyCareAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Our Casualty Care will be next to you to make sure everything is on track, assist you with any question you may have and make sure you have been provided with the appropriate level of care and attention.',
        },
        {
          value: 'Second Opinion',
          key: 'SecondOpinion',
          amount: 'Included',
          premium: '-',
          detail:
            'The Second Medical Opinion cover provides a Second Medical Opinion from Experienced Medical Centers around the world following the diagnosis of a Qualifying Medical Condition. The benefit can be provided to Health Insurance customers irrespective of their type of Policy or Membership plan nu adding this additional benefit to their membership.',
        },
        {
          value: 'Legal Protection Assistance',
          key: 'LegalProtectionAssistance',
          amount: 'Included',
          premium: '-',
        },
      ];
    }
    case 'Premium': {
      return [
        {
          value: 'In Patient & Day Patient Treatment',
          toggle: !!data?.MedicalInPatient,
          amount: data?.MedicalInPatient,
          responseKey: 'InPatient',
          key: 'MedicalInPatient',
          type: 'BOOL',
          toggleOnAmount: 1,
          coverLimit: '€ 2.000.000,00',
          search: false,
          premium: '-',
          detail:
            'In Patient treatment is the covered provided to any insured person who remains overnight or longer in Hospital, and is admitted solely to receive Medically Necessary Treatment for an Insured Medical Condition under the policy. Similarly, Day Patient Treatment is provided to an insured person who is admitted to a hospital solely to receive medically necessary treatment that is covered by this policy and for a period of clinically- supervised recovery or treatment but does not remain in hospital overnight. This section is divided into sub sections. • Room and Board in a Hospital, Clinic or Surgical room • Room and Board in an Intensive Care Unit • Doctor’s Fees for treatment not operational in general ward • Doctor’s Fees for treatment in an Intensive Care Unit • Accidents, Emergencies, Intensive Care • Surgeons, Assistant Surgeons, Anesthesiologists • Medical Practitioners • Prescribed Medicine, Dressings • Restorative Surgery • Diagnostic Tests and Procedures, X-rays, & MRI/CT Scans • Treatment for Cancer • Physiotherapy • Parental Hospital Accommodation • Prosthetic Devices and Implants',
        },
        {
          value: 'Reimbursement Method',
          type: 'ENUM',
          amount: data?.ReimbursementMethod || 1,
          responseKey: 'ReimbursementMethod',
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'According to Schedule of Fees',
            }, //prem: 0
            {
              value: 3,
              label: 'Schedule of Fees Plus 10%',
            }, //prem: 35,10
            {
              value: 4,
              label: 'Schedule of Fees Plus 15%',
            }, //prem 35,10
          ],
          key: 'ReimbursementMethod',
          premium: '-',
          detail:
            'Choose from the List the Reimbursement Method. Reimbursement is a Term that we meet in Health Insurance Policies that refers to the compensation for Health Care Services. In simple words it means the method that the Insurance Company will use in order to decide the amount that will pay out following a Medical Incident.',
        },
        {
          value: 'Geographical Area',
          type: 'ENUM',
          responseKey: 'GeoAreaCover',
          amount: data?.GeographicalArea || 4,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'Cyprus',
            }, //prem: -21,61 €
            {
              value: 2,
              label: 'Cyprus and Greece',
            }, //prem: -13,72 €
            {
              value: 3,
              label: 'Europe',
            }, //prem -13,72 €
            {
              value: 4,
              label: 'Worldwide',
            }, //prem 2,06 €
            {
              value: 5,
              label: 'Worldwide Excluding USA/Canada',
            }, //prem 2,06 €
          ],
          key: 'GeographicalArea',
          premium: '-',
          detail:
            'Choose from the List the Geographical areas you wish cover for.',
        },
        {
          value: 'Deductible',
          key: 'InPatientDeductible',
          responseKey: 'Deductible',
          type: 'ENUM',
          amount: data?.InPatientDeductible || 8,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: '€ 0',
            }, //premium 0
            {
              value: 2,
              label: '€ 250',
            }, //premium -41,16 €
            {
              value: 3,
              label: '€ 450',
            }, //premium -72,03 €
            {
              value: 4,
              label: '€ 600',
            }, // premium -89,18 €
            {
              value: 5,
              label: '€ 850',
            }, //premium -120,05 €
            {
              value: 6,
              label: '€ 1700',
            }, // premium -185,22 €
            {
              value: 7,
              label: '€ 5000',
            }, // premium -240,10 €
            {
              value: 8,
              label: '€ 7500',
            }, // premium -257,25 €
            {
              value: 9,
              label: '€ 10000',
            }, // premium -274,40 €
          ],
          premium: '-',
          detail:
            'Choose from the List the first amount paid by you (or on your behalf) for every insured Medical Condition, for every Period of Insurance in respect to the eligible expenses/ charges and covers, before any benefits are paid under your policy and excluding the Co- Insurance. If treatment has gone on for more than the Period of Insurance, we will treat it as a new claim for any further treatment after the date and we will reapply any Exemption. It is an optional amount to be deducted from a claimed amount on reimbursement. By choosing to have a Deductible Amount you reduce the premium paid.',
        },
        {
          value: 'Co-Insurance',
          key: 'InPatientCoInsurance',
          responseKey: 'CoInsurance',
          toggle: data?.InPatientCoInsurance > 1,
          amount: data?.InPatientCoInsurance || 1,
          type: 'ENUM',
          toggleOnValue: 2,
          toggleOffValue: 1,
          amountOptions: [
            {
              value: 1,
              label: '0%',
            },
            {
              value: 2,
              label: '10%',
            },
            {
              value: 3,
              label: '20%',
            },
            {
              value: 4,
              label: '30%',
            }, //premium remains 0 but dependin on what you choose, the premium for  deductible and geographical area changes
          ],
          premium: '-',
          detail:
            'Choose from the List the Co- Insurance percentage. With Co-Insurance, the insured undertakes the liability to pay for a percentage of the reimbursement. By choosing to have Co-Insurance you reduce the premium paid.',
        },
        {
          value: 'New Born Baby Benefit',
          key: 'NewBornBaby',
          type: 'BOOL',
          responseKey: 'NewBornBabyBenefit',
          toggle: !!data?.NewBornBaby,
          // toggleOnAmount: '€1.000',
          toggleOnValue: 1,
          //if selected,premium is 20
          premium: '-',
          detail:
            'If you choose this benefit, Gan Direct will pay the amount of benefit to assist with the initial expenses you incurred during the Period of Insurance whilst preparing the family home for your Newborn provided that you have been continuously insured under your policy for at least 12 consecutive months immediately preceding the birth. This is not a cover of charges and it has nothing to do with the type of delivery (caesarean or other).',
        },
        {
          value: 'Out Patient Treatment',
          key: 'MedicalOutPatient',
          // amount: 'Not selected',
          responseKey: 'OutPatient',
          toggle: !!data?.MedicalOutPatient || true,
          // toggleOnAmount: '€2.000',
          type: 'BOOL',
          toggleOnValue: 1,
          //if selected,premium is 245
          premium: '-',
          detail:
            'If you choose this benefit and subject to the terms of this Policy we will pay for the treatment and surgery of an Out Patient. Out Patient Treatment covers: • Visit to General Practitioner or to a Specialist after Reference • Diagnostic Laboratory Examinations, Tests, X-Rays, MRI, CT Scans • Prescribed Medicine • Post-Surgical Physiotherapy',
        },
        {
          value: 'Travel Transportation and Out of Area Benefit',
          // amount: 'Not selected',
          key: 'TravelTransportationAndOutOfGeorgaphycalArea',
          type: 'BOOL',
          responseKey: 'TravelTransportation',
          // toggleOnAmount: 'Included',
          toggle: !!data?.TravelTransportationAndOutOfGeorgaphycalArea || true,
          toggleOnValue: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'With this benefit, Gan Direct will pay the expenses needed In the event of a serious physical injury resulting from an accident or a sudden illness of the insured while being out of Geographical Area and requires International Assistance and travel transportation. International Assistance/Travel Transportation refers to the cover of expenses, as below, during traveling abroad for not more than 60 days. We cover the following: • Medical Transportation and Treatment of an Emergency, until the Patient’s condition is stable and he/she is allowed to travel) • Repatriation of the Patient and his/her accompanying relatives. • Fare expenses of a Family Member if the treatment lasts for more than 10 days. • Accommodation expenses for the Family Member (room only for up to 7 days and up to € 650) • Repatriation of Corpse',
        },
        {
          value: 'Death Benefit',
          search: true,
          key: 'DeathBenefit',
          responseKey: 'DeathCover',
          targetKey: 'DeathAmountInsured',
          type: 'ENUM',
          toggle: !!data?.DeathBenefit || true,
          toggleOnValue: 1,
          toggleOffValue: 0,
          // amount: data["Value"]?.TotalResponse || "",
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 7,10
            {
              value: 3,
              label: '20000',
            }, //premium 14,20
            {
              value: 4,
              label: '30000',
            }, // premium 21,30
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 7,10 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '100000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'We will cover in case of Insured Death if it occurs due to an Occupational Hazard (accident/ disease). Occupational Hazard is the risk that comes from work activity or occupational activity. Work or occupation is somebody’s activity for economic or ethic reward (i.e. housewife). The benefit aims to relief the beneficiaries, in case of the insured’s death, from all relevant financial problems arising after such an event. Such financial problems are Taxes, Loans, Last expenses, Funeral expenses ect.',
        },
        {
          value: 'Dread Benefit',
          search: true,
          key: 'DreadDisease',
          targetKey: 'DreadSumInsured',
          type: 'ENUM',
          responseKey: 'DreadDiseaseAmount',
          toggle: !!data?.DreadDisease || true,
          toggleOnValue: 5,
          toggleOffValue: 0,
          // amount: data["Value"]?.TotalResponse || "",
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 10,80
            {
              value: 3,
              label: '20000',
            }, //premium 21,60
            {
              value: 4,
              label: '30000',
            }, // premium 32,40 €
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 10,80 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '10000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'This benefit aims not only to cover the medical costs but also to relief the customer from relative expenses. We consider as Dread Disease the following: Heart Attack, Stroke, Cancer, Kidney failure, Transplant of vital organs',
        },
        {
          value: 'Permanent Total Disability Benefit',
          search: true,
          key: 'PermanentTotalOrPartialDisability',
          responseKey: 'DisabilityCover',
          targetKey: 'DisabilityAmount',
          type: 'ENUM',
          toggle: !!data?.PermanentTotalOrPartialDisability || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          amountOptions: [
            {
              value: 1,
              label: 0,
            }, //premium 0
            {
              value: 2,
              label: 10000,
            }, //premium 8
            {
              value: 3,
              label: 20000,
            },
            {
              value: 4,
              label: 30000,
            },
            {
              value: 5,
              label: 40000,
            }, //premium goes up by 8 every 1000
            {
              value: 6,
              label: 50000,
            },
            {
              value: 7,
              label: 60000,
            },
            {
              value: 8,
              label: 70000,
            },
            {
              value: 9,
              label: 80000,
            },
            {
              value: 10,
              label: 90000,
            },
            {
              value: 11,
              label: 10000,
            },
            {
              value: 12,
              label: 110000,
            },
            {
              value: 13,
              label: 120000,
            },
            {
              value: 14,
              label: 140000,
            },
            {
              value: 15,
              label: 150000,
            },
            {
              value: 16,
              label: 160000,
            },
            {
              value: 17,
              label: 180000,
            },
            {
              value: 18,
              label: 200000,
            },
            {
              value: 19,
              label: 250000,
            },
            {
              value: 20,
              label: 300000,
            },
            {
              value: 21,
              label: 350000,
            },
            {
              value: 22,
              label: 400000,
            },
            {
              value: 23,
              label: 450000,
            },
            {
              value: 24,
              label: 500000,
            },
          ],
          premium: '-',
          detail:
            'This benefit covers Permanent Total Disability as a result of an Accident or a Disease. Permanently and Totally Disable is a person who permanently is not able to exercise any job and his/her ailment is permanently incurable. A medical board (of doctors) decides whether the total disability is Permanent 12 months after the accident or the occurrence of a disease.',
        },
        {
          value: 'Medical Call Center Service',
          key: 'MedicalCallCenteService',
          coverLimit: 'Included',
          responseKey: 'MedicalCallCenterService',
          toggle: !!data?.MedicalCallCenteService || false,
          type: 'BOOL',
          toggleOnAmount: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'You will have medical advice from qualified and specialized medical teams which includes supply of contact details for all medical institutions, in the area where the patient needs hospitalization.',
        },
        {
          value: '24Hrs Health Helpline Assistance',
          key: '24HrsHealthHelplineAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Health helpline assistance is there for you whenever you need it, and just a phone call away. If you are feeling unwell or would like some general healthcare information, then you can be put through to specialized medical teams. Additionally, our 24hr Medical Assistance Service provided with this policy will handle your claim as soon as you notify us and will provide you an unforgettable customer service.',
        },
        {
          value: '24Hrs Casualty Care Assistance',
          key: '24HrsCasualtyCareAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Our Casualty Care will be next to you to make sure everything is on track, assist you with any question you may have and make sure you have been provided with the appropriate level of care and attention.',
        },
        {
          value: 'Second Opinion',
          key: 'SecondOpinion',
          amount: 'Included',
          premium: '-',
          detail:
            'The Second Medical Opinion cover provides a Second Medical Opinion from Experienced Medical Centers around the world following the diagnosis of a Qualifying Medical Condition. The benefit can be provided to Health Insurance customers irrespective of their type of Policy or Membership plan nu adding this additional benefit to their membership.',
        },
        {
          value: 'Legal Protection Assistance',
          key: 'LegalProtectionAssistance',
          amount: 'Included',
          premium: '-',
        },
      ];
    }
    case 'Economy': {
      return [
        {
          value: 'In Patient & Day Patient Treatment',
          toggle: !!data?.MedicalInPatient,
          amount: data?.MedicalInPatient,
          responseKey: 'InPatient',
          key: 'MedicalInPatient',
          type: 'BOOL',
          toggleOnAmount: 1,
          coverLimit: '€ 100.000,00',
          search: false,
          premium: '-',
          detail:
            'In Patient treatment is the covered provided to any insured person who remains overnight or longer in Hospital, and is admitted solely to receive Medically Necessary Treatment for an Insured Medical Condition under the policy. Similarly, Day Patient Treatment is provided to an insured person who is admitted to a hospital solely to receive medically necessary treatment that is covered by this policy and for a period of clinically- supervised recovery or treatment but does not remain in hospital overnight. This section is divided into sub sections. • Room and Board in a Hospital, Clinic or Surgical room • Room and Board in an Intensive Care Unit • Doctor’s Fees for treatment not operational in general ward • Doctor’s Fees for treatment in an Intensive Care Unit • Accidents, Emergencies, Intensive Care • Surgeons, Assistant Surgeons, Anesthesiologists • Medical Practitioners • Prescribed Medicine, Dressings • Restorative Surgery • Diagnostic Tests and Procedures, X-rays, & MRI/CT Scans • Treatment for Cancer • Physiotherapy • Parental Hospital Accommodation • Prosthetic Devices and Implants',
        },
        {
          value: 'Reimbursement Method',
          type: 'ENUM',
          responseKey: 'ReimbursementMethod',
          amount: data?.ReimbursementMethod || 1,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'According to Schedule of Fees',
            }, //prem: 0
            {
              value: 3,
              label: 'Schedule of Fees Plus 10%',
            }, //prem: 35,10
            {
              value: 4,
              label: 'Schedule of Fees Plus 15%',
            }, //prem 35,10
          ],
          key: 'ReimbursementMethod',
          premium: '-',
          detail:
            'Choose from the List the Reimbursement Method. Reimbursement is a Term that we meet in Health Insurance Policies that refers to the compensation for Health Care Services. In simple words it means the method that the Insurance Company will use in order to decide the amount that will pay out following a Medical Incident.',
        },
        {
          value: 'Geographical Area',
          type: 'ENUM',
          responseKey: 'GeoAreaCover',
          amount: data?.GeographicalArea || 1,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: 'Cyprus',
            }, //prem: -21,61 €
            {
              value: 2,
              label: 'Cyprus and Greece',
            }, //prem: -13,72 €
            {
              value: 3,
              label: 'Europe',
            }, //prem -13,72 €
            {
              value: 4,
              label: 'Worldwide',
            }, //prem 2,06 €
            {
              value: 5,
              label: 'Worldwide Excluding USA/Canada',
            }, //prem 2,06 €
          ],
          key: 'GeographicalArea',
          premium: '-',
          detail:
            'Choose from the List the Geographical areas you wish cover for.',
        },
        {
          value: 'Deductible',
          key: 'InPatientDeductible',
          type: 'ENUM',
          responseKey: 'Deductible',
          amount: data?.InPatientDeductible || 5,
          amountOptionsNoToggle: [
            {
              value: 1,
              label: '€ 0',
            }, //premium 0
            {
              value: 2,
              label: '€ 250',
            }, //premium -41,16 €
            {
              value: 3,
              label: '€ 450',
            }, //premium -72,03 €
            {
              value: 4,
              label: '€ 600',
            }, // premium -89,18 €
            {
              value: 5,
              label: '€ 850',
            }, //premium -120,05 €
            {
              value: 6,
              label: '€ 1700',
            }, // premium -185,22 €
            {
              value: 7,
              label: '€ 5000',
            }, // premium -240,10 €
            {
              value: 8,
              label: '€ 7500',
            }, // premium -257,25 €
            {
              value: 9,
              label: '€ 10000',
            }, // premium -274,40 €
          ],
          premium: '-',
          detail:
            'Choose from the List the first amount paid by you (or on your behalf) for every insured Medical Condition, for every Period of Insurance in respect to the eligible expenses/ charges and covers, before any benefits are paid under your policy and excluding the Co- Insurance. If treatment has gone on for more than the Period of Insurance, we will treat it as a new claim for any further treatment after the date and we will reapply any Exemption. It is an optional amount to be deducted from a claimed amount on reimbursement. By choosing to have a Deductible Amount you reduce the premium paid.',
        },
        {
          value: 'Co-Insurance',
          key: 'InPatientCoInsurance',
          responseKey: 'CoInsurance',
          toggle: data?.InPatientCoInsurance > 1,
          amount: data?.InPatientCoInsurance || 1,
          type: 'ENUM',
          toggleOnValue: 2,
          toggleOffValue: 1,
          amountOptions: [
            {
              value: 1,
              label: '0%',
            },
            {
              value: 2,
              label: '10%',
            },
            {
              value: 3,
              label: '20%',
            },
            {
              value: 4,
              label: '30%',
            }, //premium remains 0 but dependin on what you choose, the premium for  deductible and geographical area changes
          ],
          premium: '-',
          detail:
            'Choose from the List the Co- Insurance percentage. With Co-Insurance, the insured undertakes the liability to pay for a percentage of the reimbursement. By choosing to have Co-Insurance you reduce the premium paid.',
        },
        {
          value: 'New Born Baby Benefit',
          key: 'NewBornBaby',
          type: 'BOOL',
          responseKey: 'NewBornBabyBenefit',
          toggle: !!data?.NewBornBaby,
          // toggleOnAmount: '€1.000',
          toggleOnValue: 1,
          //if selected,premium is 20
          premium: '-',
          detail:
            'If you choose this benefit, Gan Direct will pay the amount of benefit to assist with the initial expenses you incurred during the Period of Insurance whilst preparing the family home for your Newborn provided that you have been continuously insured under your policy for at least 12 consecutive months immediately preceding the birth. This is not a cover of charges and it has nothing to do with the type of delivery (caesarean or other).',
        },
        {
          value: 'Out Patient Treatment',
          key: 'MedicalOutPatient',
          responseKey: 'OutPatient',
          toggle: !!data?.MedicalOutPatient,
          // toggleOnAmount: '€2.000',
          type: 'BOOL',
          toggleOnValue: 1,
          //if selected,premium is 245
          premium: '-',
          detail:
            'If you choose this benefit and subject to the terms of this Policy we will pay for the treatment and surgery of an Out Patient. Out Patient Treatment covers: • Visit to General Practitioner or to a Specialist after Reference • Diagnostic Laboratory Examinations, Tests, X-Rays, MRI, CT Scans • Prescribed Medicine • Post-Surgical Physiotherapy',
        },
        {
          value: 'Travel Transportation and Out of Area Benefit',
          responseKey: 'TravelTransportation',
          key: 'TravelTransportationAndOutOfGeorgaphycalArea',
          type: 'BOOL',
          // toggleOnAmount: 'Included',
          toggle: !!data?.TravelTransportationAndOutOfGeorgaphycalArea,
          toggleOnValue: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'With this benefit, Gan Direct will pay the expenses needed In the event of a serious physical injury resulting from an accident or a sudden illness of the insured while being out of Geographical Area and requires International Assistance and travel transportation. International Assistance/Travel Transportation refers to the cover of expenses, as below, during traveling abroad for not more than 60 days. We cover the following: • Medical Transportation and Treatment of an Emergency, until the Patient’s condition is stable and he/she is allowed to travel) • Repatriation of the Patient and his/her accompanying relatives. • Fare expenses of a Family Member if the treatment lasts for more than 10 days. • Accommodation expenses for the Family Member (room only for up to 7 days and up to € 650) • Repatriation of Corpse',
        },
        {
          value: 'Death Benefit',
          search: true,
          key: 'DeathBenefit',
          responseKey: 'DeathCover',
          targetKey: 'DeathAmountInsured',
          type: 'ENUM',
          toggle: !!data?.DeathBenefit || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 7,10
            {
              value: 3,
              label: '20000',
            }, //premium 14,20
            {
              value: 4,
              label: '30000',
            }, // premium 21,30
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 7,10 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '100000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'We will cover in case of Insured Death if it occurs due to an Occupational Hazard (accident/ disease). Occupational Hazard is the risk that comes from work activity or occupational activity. Work or occupation is somebody’s activity for economic or ethic reward (i.e. housewife). The benefit aims to relief the beneficiaries, in case of the insured’s death, from all relevant financial problems arising after such an event. Such financial problems are Taxes, Loans, Last expenses, Funeral expenses ect.',
        },
        {
          value: 'Dread Benefit',
          search: true,
          key: 'DreadDisease',
          responseKey: 'DreadDiseaseAmount',
          targetKey: 'DreadSumInsured',
          type: 'ENUM',
          toggle: !!data?.DreadDisease || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          // amount: data["Value"]?.TotalResponse || "",
          amountOptions: [
            {
              value: 1,
              label: '0',
            }, //premium 0
            {
              value: 2,
              label: '10000',
            }, //premium 10,80
            {
              value: 3,
              label: '20000',
            }, //premium 21,60
            {
              value: 4,
              label: '30000',
            }, // premium 32,40 €
            {
              value: 5,
              label: '40000',
            }, //premium goes up by 10,80 for every 1000
            {
              value: 6,
              label: '50000',
            },
            {
              value: 7,
              label: '60000',
            },
            {
              value: 8,
              label: '70000',
            },
            {
              value: 9,
              label: '80000',
            },
            {
              value: 10,
              label: '90000',
            },
            {
              value: 11,
              label: '10000',
            },
            {
              value: 12,
              label: '110000',
            },
            {
              value: 13,
              label: '120000',
            },
            {
              value: 14,
              label: '140000',
            },
            {
              value: 15,
              label: '150000',
            },
            {
              value: 16,
              label: '160000',
            },
            {
              value: 17,
              label: '180000',
            },
            {
              value: 18,
              label: '200000',
            },
            {
              value: 19,
              label: '250000',
            },
            {
              value: 20,
              label: '300000',
            },
            {
              value: 21,
              label: '350000',
            },
            {
              value: 22,
              label: '400000',
            },
            {
              value: 23,
              label: '450000',
            },
            {
              value: 24,
              label: '500000',
            },
          ],
          premium: '-',
          detail:
            'This benefit aims not only to cover the medical costs but also to relief the customer from relative expenses. We consider as Dread Disease the following: Heart Attack, Stroke, Cancer, Kidney failure, Transplant of vital organs',
        },
        {
          value: 'Permanent Total Disability Benefit',
          search: true,
          responseKey: 'DisabilityCover',
          key: 'PermanentTotalOrPartialDisability',
          targetKey: 'DisabilityAmount',
          type: 'ENUM',
          toggle: !!data?.PermanentTotalOrPartialDisability || false,
          toggleOnValue: 1,
          toggleOffValue: 0,
          amountOptions: [
            {
              value: 1,
              label: 0,
            }, //premium 0
            {
              value: 2,
              label: 10000,
            }, //premium 8
            {
              value: 3,
              label: 20000,
            },
            {
              value: 4,
              label: 30000,
            },
            {
              value: 5,
              label: 40000,
            }, //premium goes up by 8 every 1000
            {
              value: 6,
              label: 50000,
            },
            {
              value: 7,
              label: 60000,
            },
            {
              value: 8,
              label: 70000,
            },
            {
              value: 9,
              label: 80000,
            },
            {
              value: 10,
              label: 90000,
            },
            {
              value: 11,
              label: 10000,
            },
            {
              value: 12,
              label: 110000,
            },
            {
              value: 13,
              label: 120000,
            },
            {
              value: 14,
              label: 140000,
            },
            {
              value: 15,
              label: 150000,
            },
            {
              value: 16,
              label: 160000,
            },
            {
              value: 17,
              label: 180000,
            },
            {
              value: 18,
              label: 200000,
            },
            {
              value: 19,
              label: 250000,
            },
            {
              value: 20,
              label: 300000,
            },
            {
              value: 21,
              label: 350000,
            },
            {
              value: 22,
              label: 400000,
            },
            {
              value: 23,
              label: 450000,
            },
            {
              value: 24,
              label: 500000,
            },
          ],
          premium: '-',
          detail:
            'This benefit covers Permanent Total Disability as a result of an Accident or a Disease. Permanently and Totally Disable is a person who permanently is not able to exercise any job and his/her ailment is permanently incurable. A medical board (of doctors) decides whether the total disability is Permanent 12 months after the accident or the occurrence of a disease.',
        },
        {
          value: 'Medical Call Center Service',
          key: 'MedicalCallCenteService',
          responseKey: 'MedicalCallCenterService',
          coverLimit: 'Included',
          toggle: !!data?.MedicalCallCenteService || false,
          type: 'BOOL',
          toggleOnAmount: 1,
          //if selected,premium is 5
          premium: '-',
          detail:
            'You will have medical advice from qualified and specialized medical teams which includes supply of contact details for all medical institutions, in the area where the patient needs hospitalization.',
        },
        {
          value: '24Hrs Health Helpline Assistance',
          key: '24HrsHealthHelplineAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Health helpline assistance is there for you whenever you need it, and just a phone call away. If you are feeling unwell or would like some general healthcare information, then you can be put through to specialized medical teams. Additionally, our 24hr Medical Assistance Service provided with this policy will handle your claim as soon as you notify us and will provide you an unforgettable customer service.',
        },
        {
          value: '24Hrs Casualty Care Assistance',
          key: '24HrsCasualtyCareAssistance',
          amount: 'Included',
          premium: '-',
          detail:
            'Our Casualty Care will be next to you to make sure everything is on track, assist you with any question you may have and make sure you have been provided with the appropriate level of care and attention.',
        },
        {
          value: 'Second Opinion',
          key: 'SecondOpinion',
          amount: 'Included',
          premium: '-',
          detail:
            'The Second Medical Opinion cover provides a Second Medical Opinion from Experienced Medical Centers around the world following the diagnosis of a Qualifying Medical Condition. The benefit can be provided to Health Insurance customers irrespective of their type of Policy or Membership plan nu adding this additional benefit to their membership.',
        },
        {
          value: 'Legal Protection Assistance',
          key: 'LegalProtectionAssistance',
          amount: 'Included',
          premium: '-',
        },
      ];
    }
    default:
      break;
  }
};
