import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import FlowDriversInformationExtraForm from '../components/FlowDriversInformationExtraForm.jsx';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import { retrieveStorageById } from '../helpers/storeService';
import FlowChatBox from '../components/FlowChatBox';
import _ from 'lodash';

const FlowDriversInformationExtra = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const [, setAccidentsArr] = useState();
  const { addQuestion } = useContext(ChatContext);

  useEffect(() => {
    const question = {
      message: i18n.t(
        `Did ${cars[currentVehicle]?.main_driver.firstName} have <span>any accidents</span> in the last 5 years?`
      ),
      slug: 'accident_information',
    };
    addQuestion(question);
  }, []);

  function handleSubmit() {
    if (
      _.isEmpty(data?.extra_items_to_insure) ||
      data?.extra_items_to_insure == null
    ) {
      renderNextRoute(1, { vehicle: currentVehicle });
    } else {
      renderNextRoute(2, { vehicle: currentVehicle });
    }
  }

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <FlowDriversInformationExtraForm
          sendAccidentArr={(accidents) => setAccidentsArr(accidents)}
          handleFormValues={handleSubmit}
        />
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowDriversInformationExtra;
