import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import FlowLeftSideAvatar from '../components/FlowLeftSideAvatar.jsx';

const ImpossibleFlow = () => {
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `The route you requested could not be found. Please try again.`
        )}
      />
      <RightSplit>
        <h1>{i18n.t('Impossible')}</h1>
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default ImpossibleFlow;
