import React, { useState } from 'react';
import styled from 'styled-components';
import { IconWitnessFilled, IconPropertyFilled } from 'wg-fe-ui';
import LineIcon from '../assets/icons/LineIcon';
import { useParams } from 'react-router';

const AddressResponse = ({ address, blankImage, image }) => {
  const { insuranceType } = useParams();
  const [showMarkedImage, setShowMarkedImage] = useState(false);

  function handleSatelliteImage() {
    if (image != null) {
      setShowMarkedImage(!showMarkedImage);
    }
  }
  if (image != null) {
    return (
      <AddressImageContainer insurance={insuranceType}>
        <div>
          {(image || blankImage) && (
            <img src={showMarkedImage ? image : blankImage} alt="address" />
          )}
          <AddressContainer insurance={insuranceType}>
            <IconPropertyFilled className="property" />
            <div>
              <p className="first">
                {address.street} {address.housenr},
              </p>
              <p>
                {address.zipcode} {address.city}
              </p>
            </div>
            {showMarkedImage && (
              <Eye onClick={() => handleSatelliteImage()}>
                <span>
                  <LineIcon className="line" />
                </span>
                <IconWitnessFilled className="eye" />
              </Eye>
            )}
          </AddressContainer>
        </div>
      </AddressImageContainer>
    );
  } else {
    return (
      <div>
        <p className="first">
          {address.street} {address.housenr},
        </p>
        <p>
          {address.zipcode} {address.city}
        </p>
      </div>
    );
  }
};

const AddressContainer = styled.div`
  background: #ffffff;
  border-radius: 5px;
  bottom: 2rem;
  box-shadow: 0px 0px 34.29px rgba(0, 0, 0, 0.08);
  color: black;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 6rem;
  left: 0;
  margin: 0 5rem;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: left;
  width: ${({ insurance }) => (insurance === 'allMobile' ? '90%' : '75%')};

  > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 550;
    grid-column: span 4 / auto;
    justify-content: center;
    margin-left: 0.5rem;
  }

  svg.property {
    align-self: center;
    grid-column: span 1 / auto;
    margin-left: 1rem;
    path {
      fill: #e4e4e4;
    }
  }
`;

const Eye = styled.span`
  align-self: center;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  grid-column: span 1 / auto;
  justify-self: center;
  margin-right: 1rem;
  position: relative;

  > svg.eye {
    margin-left: 0;
    padding: 0.3rem;
    vertical-align: middle;
    path {
      fill: #e4e4e4;
    }
  }
  span {
    position: absolute;
    padding: 0.5rem;
    svg {
      padding-bottom: 0.2rem;
      padding-right: 0.2rem;
    }
  }
`;

const AddressImageContainer = styled.div`
  width: 100%;
  > div {
    border-radius: 10px;
    height: ${({ insurance }) =>
      insurance === 'allMobile' ? '28rem' : '34rem'};
    width: 35rem;
    overflow: hidden;
  }
  img {
    border-radius: 10px;
    width: 100%;
  }
  @media (max-width: 1020px) {
    > div {
      height: ${({ insurance }) => insurance !== 'allMobile' && '29rem'};
      width: 30rem;
    }
  }
  @media (max-width: 900px) {
    > div {
      height: 22rem;
      width: 24.5rem;
    }
  }
  @media (max-width: 325px) {
    > div {
      height: 20rem;
      width: 20.5rem;
    }
  }
`;

export default AddressResponse;
