import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { useParams, useHistory } from 'react-router-dom';
import {
  ActionButton,
  IconActionChevronLeft,
  DateInputV4 as DateInput,
} from 'wg-fe-ui';
import SearchSelectInputV2 from '../components/SearchSelectInputV2';
import useRouting from '../hooks/useRouting';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';

import { string } from '../constants/validationSchemas.js';
import {
  duration_time,
  column_material_options,
  claim_options,
  roof_options,
  walls_options,
  heating_pipes_options,
  floor_options,
  heating_method_options,
} from '../constants/FlowSearchSelectData';
import {
  add,
  isFuture,
  isToday,
  parseISO,
  differenceInDays,
  isAfter,
} from 'date-fns';
import FlowChatBox from '../components/FlowChatBox';

const FlowHomeInsuranceSpecifics = () => {
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const { id, affinity, insuranceType, currentProperty } = useParams();

  const columnMaterialOptions = useMemo(() =>
    column_material_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [column_material_options]
    )
  );
  const heatingPipesOptions = useMemo(() =>
    heating_pipes_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [heating_pipes_options]
    )
  );

  const durationTime = useMemo(() =>
    duration_time.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [duration_time]
    )
  );
  const claimOptions = useMemo(() =>
    claim_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [claim_options]
    )
  );
  const roofOptions = useMemo(() =>
    roof_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [roof_options]
    )
  );
  const wallsOptions = useMemo(() =>
    walls_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [walls_options]
    )
  );
  const floorOptions = useMemo(() =>
    floor_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [floor_options]
    )
  );
  const heatingMethodOptions = useMemo(() =>
    heating_method_options.map(
      (item) => ({ value: item.value, label: i18n.t(item.label) }),
      [heating_method_options]
    )
  );

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { risk_addresses } = data;
    if (risk_addresses?.[currentProperty]?.policy_details) {
      defaultValuesHandler(risk_addresses?.[currentProperty]?.policy_details);
      defaultValuesHandler(
        risk_addresses?.[currentProperty]?.parcel?.main_building
          ?.construction_details
      );
    }
  }, []);

  function handleEndAfterStart(value) {
    return isAfter(parseISO(value), parseISO(this.parent.starting_date));
  }

  const SignupSchema = Yup.object().shape({
    insurance_duration: Yup.mixed().required(i18n.t(`required`)),
    starting_date: Yup.mixed()
      .test(
        'valid-start-date',
        i18n.t('The start date must be today or in the future'),
        handleFuture
      )
      .test(
        'validate-start-date-days',
        i18n.t('The start date cannot be more than 35 days from today'),
        handleFutureDays
      ),
    ending_date: Yup.string().when('insurance_duration', {
      is: 0,
      then: string.required
        .test(
          'validate-end-date',
          i18n.t(
            'The end date cannot be more than 1 year after the start date'
          ),
          handleOtherEndDate
        )
        .test(
          'validate-end-date',
          i18n.t('The end date cannot be before the start date'),
          handleEndAfterStart
        ),
    }),
    claims: string.required,
    WaterPipes: string.required,
    column_material: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    roof: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    walls: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    floor: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    heating_method: Yup.array()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
  });

  function defaultValuesHandler(data) {
    Object.keys(data || {}).forEach((name) => {
      handleChange({ name, value: data[name] });
    });
  }

  function handleFuture(value) {
    return isToday(parseISO(value)) || isFuture(parseISO(value));
  }

  function handleFutureDays(value) {
    return differenceInDays(parseISO(value), new Date()) <= 35;
  }

  function handleOtherEndDate(value) {
    return (
      differenceInDays(parseISO(value), parseISO(this.parent.starting_date)) <=
      365
    );
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const submitHandler = () => {
    patchStorage({
      payload: {
        policy_details: {
          ending_date: values.ending_date
            ? values.ending_date
            : add(new Date(values?.starting_date), {
                months: values?.insurance_duration,
              }).toISOString(),
          starting_date: new Date(values?.starting_date)?.toISOString(),
          insurance_duration: values?.insurance_duration,
          claims: values?.claims,
        },
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });

    patchStorage({
      payload: {
        construction_details: {
          column_material: values?.column_material,
          roof: values?.roof,
          walls: values?.walls,
          floor: values?.floor,
          WaterPipes: values?.WaterPipes,
          heating_method: values?.heating_method,
        },
      },
      path: ['risk_addresses', [currentProperty], 'parcel', 'main_building'],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { property: currentProperty });
  };

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      {!isMobile() && <FlowChatBox />}
      <RightContainer>
        <Title>{i18n.t('Specifics')}</Title>
        <SpecificsContainer>
          <p>{i18n.t('Policy details')}</p>
          <div>
            <div>
              <SearchSelectInputV2
                name="insurance_duration"
                error={errors.insurance_duration}
                value={handleSelectValue(durationTime, 'insurance_duration')}
                onChange={(val) => {
                  handleSelectChange(val, 'insurance_duration');
                }}
                options={durationTime}
                placeholder="Choose an option"
              >
                {i18n.t('Duration')} *
              </SearchSelectInputV2>
            </div>
            <div>
              <DateInput
                name="starting_date"
                error={errors.starting_date}
                onChange={handleChange}
                value={values.starting_date}
              >
                {i18n.t('Starting date')} *
              </DateInput>
            </div>
            {values.insurance_duration === 0 && (
              <div>
                <DateInput
                  name="ending_date"
                  error={errors.ending_date}
                  onChange={handleChange}
                  value={values.ending_date}
                >
                  {i18n.t('Ending date')} *
                </DateInput>
              </div>
            )}
            <div>
              <SearchSelectInputV2
                name="claims"
                error={errors.claims}
                value={handleSelectValue(claimOptions, 'claims')}
                options={claimOptions}
                onChange={(val) => {
                  handleSelectChange(val, 'claims');
                }}
                placeholder="Choose an option"
              >
                {i18n.t('Claims')} *
              </SearchSelectInputV2>
            </div>
          </div>
          <p>{i18n.t('Construction Materials')}</p>
          <div>
            <div>
              <SearchSelectInputV2
                isMulti
                withCheckmark
                name="column_material"
                error={errors.column_material}
                value={values?.column_material}
                onChange={(e) => {
                  handleChange({ name: 'column_material', value: e });
                }}
                options={columnMaterialOptions}
                placeholder="Choose an option"
              >
                {i18n.t('Column')} *
              </SearchSelectInputV2>
            </div>
            <div>
              <SearchSelectInputV2
                isMulti
                withCheckmark
                name="roof"
                error={errors.roof}
                value={values?.roof}
                onChange={(e) => {
                  handleChange({ name: 'roof', value: e });
                }}
                options={roofOptions}
                placeholder="Choose an option"
              >
                {i18n.t('Roof')} *
              </SearchSelectInputV2>
            </div>
            <div>
              <SearchSelectInputV2
                isMulti
                withCheckmark
                name="walls"
                error={errors.roof}
                value={values?.walls}
                onChange={(e) => {
                  handleChange({ name: 'walls', value: e });
                }}
                options={wallsOptions}
                placeholder="Choose an option"
              >
                {i18n.t('Walls')} *
              </SearchSelectInputV2>
            </div>
            <div>
              <SearchSelectInputV2
                name="floor"
                withCheckmark
                isMulti
                error={errors.floor}
                options={floorOptions}
                value={values?.floor}
                onChange={(e) => {
                  handleChange({ name: 'floor', value: e });
                }}
                placeholder="Choose an option"
              >
                {i18n.t('Floor')} *
              </SearchSelectInputV2>
            </div>
          </div>
          <p>{i18n.t('Heating')}</p>
          <div>
            <div>
              <SearchSelectInputV2
                isMulti
                withCheckmark
                name="heating_method"
                error={errors.heating_method}
                value={values?.heating_method}
                onChange={(e) => {
                  handleChange({ name: 'heating_method', value: e });
                }}
                options={heatingMethodOptions}
                placeholder="Choose an option"
              >
                {i18n.t('Heating method')} *
              </SearchSelectInputV2>
            </div>
          </div>
          <p>{i18n.t('Water Pipes')}</p>
          <div>
            <div>
              <SearchSelectInputV2
                name="WaterPipes"
                error={errors.WaterPipes}
                value={values?.WaterPipes}
                onChange={(e) => {
                  handleChange({ name: 'WaterPipes', value: e });
                }}
                options={heatingPipesOptions}
                placeholder="Choose an option"
              >
                {i18n.t('Water Pipes')} *
              </SearchSelectInputV2>
            </div>
          </div>
        </SpecificsContainer>
        <ButtonContainer>
          <Required>* {i18n.t('Required fields')}</Required>
          <div>
            <BackButton type="button" name="back" onClick={goBack}>
              <IconActionChevronLeft />
              {i18n.t('Back')}
            </BackButton>
            <ActionButton
              onClick={() => handleSubmit(submitHandler)}
              type="submit"
              value="Submit"
              data-test-id="drivers_information_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          </div>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  align-self: center;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const SpecificsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  > div {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    > div {
      grid-column: span 3 / auto;
    }
  }
  > p {
    color: #30373d;
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 550;
    font-size: 1.6rem;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
  @media (max-width: 850px) {
    > div > div {
      grid-column: span 6 / auto;
      height: 9rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
  > div {
    display: flex;
  }

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 910px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
    }
    p {
      margin-bottom: 2rem;
      width: 100%;
      text-align: end;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowHomeInsuranceSpecifics;
