import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ActionButton } from 'wg-fe-ui';
import i18n from '../i18n';
import InfoPopup from './InfoPopup';
import CurrencyInput from './CurrencyInput';

const OptionsPriceInput = ({
  name,
  error,
  value,
  onChange,
  defaultValue,
  children,
  popUpTitle,
  popUpInfo,
}) => {
  const [isVATInclusive, setIsVATInclusive] = useState(false);
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    setStateValue(
      isVATInclusive ? (defaultValue * 1.19).toFixed(2) : defaultValue
    );
  }, [defaultValue]);

  const handleVATChange = () => {
    if (isVATInclusive) {
      setStateValue((stateValue / 1.19).toFixed(2));
    } else {
      setStateValue((stateValue * 1.19).toFixed(2));
    }
    setIsVATInclusive(!isVATInclusive);
  };

  return (
    <BTWContainer>
      <InputContainer>
        <CurrencyInput
          value={stateValue ? stateValue : 0}
          error={error}
          name={name}
          onChange={({ value }) => {
            setStateValue(value);
            onChange({ value, isVATInclusive });
          }}
        >
          <LabelName>{children}</LabelName>
        </CurrencyInput>
        <EuroSign>&euro;</EuroSign>
      </InputContainer>
      <InvoiceContainer>
        {popUpTitle || popUpInfo ? (
          <StyledInfoPopup title={popUpTitle} info={popUpInfo} />
        ) : (
          ''
        )}
        <BTWButton
          included={isVATInclusive}
          type="button"
          onClick={handleVATChange}
        >
          {isVATInclusive ? i18n.t('Incl VAT') : i18n.t('Excl VAT')}
        </BTWButton>
      </InvoiceContainer>
    </BTWContainer>
  );
};

const LabelName = styled.p`
  color: #8990a3;
`;

const BTWContainer = styled.div`
  display: flex;
  align-items: center;

  display: grid;
  grid-gap: 4%;
  grid-template-columns: 48% auto;
  margin-right: 2rem;
`;

const BTWButton = styled(ActionButton)`
  justify-content: center;
  background-color: ${({ theme, included }) =>
    included ? '#FFFFFF' : theme.brand.primary};
  border: ${({ theme, included }) =>
    included ? `2px solid ${theme.brand.primary}` : 'none'};
  color: ${({ theme, included }) =>
    included ? theme.brand.primary : '#FFFFFF'};
  font-size: 1.2rem;
  font-weight: 550;
  margin-top: 2.4rem;
  height: 4rem;
  margin-right: 0;
`;

const InvoiceContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 0rem auto;
  width: 100%;
`;

const EuroSign = styled.span`
  color: #8990a3;
  padding: 0 1.4rem;
  display: flex;
  align-items: center;
  border-left: 1px solid #e4e4e4;
  position: absolute;
  margin: 0;
  right: 0rem;
  top: 2.5rem;
  height: 3.8rem;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: 1rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  justify-self: end;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
`;

export default OptionsPriceInput;
