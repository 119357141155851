import React from 'react';
import styled from 'styled-components';
import { customScrollBar } from '../../helpers/styledComponenstService';

interface Props {
  children: Node | React.ReactNode;
  selectProps: {
    innerProps: { fallbackOptionMessage: string; onFallback: () => void };
    innerRef: React.RefObject<HTMLDivElement>;
    onMenuClose: () => void;
    inputValue?: string;
  };
}

const SearchSelectCustomMenuList: React.FC<Props> = ({
  children,
  selectProps,
}) => {
  const { innerProps, innerRef } = selectProps;
  const { fallbackOptionMessage, onFallback } = innerProps;

  return (
    <StyledMenu className="Select__Custom-menu" ref={innerRef}>
      {children}
      {fallbackOptionMessage && (
        <StyledLink
          onClick={() => onFallback()}
          dangerouslySetInnerHTML={{ __html: fallbackOptionMessage }}
        />
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled.div`
  position: relative;
  background-color: white;
  overflow-y: auto;
  max-height: 16rem;

  ${customScrollBar}
`;
const StyledLink = styled.a`
  background-color: white;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f1f3;
  padding: 0.4rem 1.8rem;
  cursor: pointer;

  & span {
    color: ${({ theme }) => theme.brand.primary};
    margin-left: 0.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default SearchSelectCustomMenuList;