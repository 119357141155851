import React, { useEffect, useState } from 'react';
import {
  Label,
  TextInput,
  IconActionChevronLeft,
  DateInputV4,
  ActionButton,
  SearchSelectInput,
  PhoneInput,
  ToggleInput,
  NumbersInputWithSymbol,
  IconDisabilityFilled,
} from 'wg-fe-ui';
import styled from 'styled-components';
import { date, object, bool, number as yupNumb, mixed } from 'yup';
import i18n from '../i18n';

import useForm from '../hooks/useForm';
import {
  string,
  firstName,
  lastName,
  email,
  telephonenr,
  id_number,
  number,
} from '../constants/validationSchemas.js';
import {
  ganCreateCustomerIndvdl,
  ganGetCustomerById,
  ganUpdateCustomerIndvdl,
} from '../helpers/apiRouterService';

import { differenceInYears, isWithinInterval } from 'date-fns';
import { useHistory } from 'react-router';
import { titleOptions as titleOptionsConstants } from '../constants/FlowSearchSelectData';
import { countries, occupations } from '../constants/customerData';
import { useTranslation } from 'react-i18next';
import Error from './Error';
import { getObjectDifferences, isObjectEmpty } from '../helpers/objectService';
import LoadingSpinner from './LoadingSpinner';
import ListItemCard from './ListItemCard';
import parseISO from 'date-fns/parseISO';

//** CONSTANTS **//
const defaultPayload = {
  // Personal info
  IncompleteProfile: 1,
  Title: 0,
  MiddleName: '',

  // Driving info
  NumberOfDrivingPoints: 0,
  ReasonForDrivingPoints: {
    Oid: 7,
  },
  AnyLicenseRestrictions: {
    Oid: 8,
  },
  LearnerDrivingLicense: false,

  // Medical info
  MedicalConditionOrDisability: 0,
  YearsOfDisability: '',
};

const titleOptions = titleOptionsConstants.map(({ value, label }) => {
  return { value, label: i18n.t(label) };
});
const occupations_lowerCase = occupations.map((o) => {
  o.label = o.label.toLowerCase();
  return o;
});
// Payload properties that are nested with Oid like {property: {Oid: value}}
const oidObjects = [
  'DrivingLicenseIssueCountry',
  'ReasonForDrivingPoints',
  'AnyLicenseRestrictions',
];

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const selectPlaceholder = i18n.t('Choose your option');
const cyprusOid = '6e0e5110-ebbf-4a71-abfc-491a2778e24f';

//** COMPONENT **//
const FlowAddAdditionalDriver = ({
  goNextRoute,
  storedValues,
  handleFormValues,
  defaultValues,
}) => {
  //** STATES AND HOOKS **//
  const history = useHistory();
  const { t } = useTranslation();

  const [existingCustomer, setExistingCustomer] = useState(null);
  const [showForm, toggleShowForm] = useState(false);
  const [additionalDrivers, setAdditionalDrivers] = useState(
    storedValues || []
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  //** NAVIGATION LOGIC **//
  const confirmLeaveForm = (e, callback) => {
    e.preventDefault();

    if (!isObjectEmpty(values)) {
      if (
        window.confirm(
          'Are you sure? Filled in information in the form will be lost'
        )
      )
        callback();
      return;
    }

    callback();
  };

  function goBack() {
    history.goBack();
  }

  const SignupSchemaFirst = object().shape({
    IdNo: id_number,
  });

  //** VALIDATOIN SCHEME AND FUNCTIONS **//
  const SignupSchema = object().shape({
    /* Personal info */
    IdNo: id_number,
    Title: string.required,
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: date()
      .test(
        'valid-birth',
        t('The driver must be at least 17 years of age'),
        validateDateOfBirth
      )
      .required(t('required')),
    CustomerCountry: string.required,
    ResidentInCyprusSince: mixed().when('CustomerCountry', {
      is: cyprusOid,
      then: undefined,
      otherwise: date().test(
        'valid-resident-cyprus',
        t('Provide a valid date, starting from your birth date until today'),
        validateResidentInCyprusSince
      ),
    }),
    Occupation: string.required,
    Email: email,
    ContactTelephoneNumber: telephonenr.required.test(
      'Phone number',
      t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),

    /* Driving info */
    DrivingLicenseIssueDate: date()
      .test(
        'valid-license-date',
        t('The driver is too young to obtain a driving licence'),
        validateDriveLicenseAge
      )
      .required(t('required')),
    DrivingLicenseIssueCountry: object({
      Oid: string.required,
    }),
    DrivingInLeftSiteOfRoadSince: date()
      .test(
        'valid-leftside-road-date',
        t(
          'This date can only range from the start of your license issue date until today'
        ),
        validateLeftSiteOfRoadDate
      )
      .required(t('required')),
    LearnerDrivingLicense: bool(),

    /* Medical info */
    MedicalConditionOrDisability: yupNumb(/^[01]$/), // either 0 or 1
    YearsOfDisability: mixed().when('MedicalConditionOrDisability', {
      is: 1,
      then: number.required
        .positive(t('Please provide a positive number'))
        .notOneOf([0], t('Please provide a number other than 0')),
    }),
  });

  function validateDateOfBirth() {
    const { DateOfBirth } = this.parent;

    return (
      17 <= differenceInYears(new Date(), new Date(DateOfBirth)) &&
      differenceInYears(new Date(), new Date(DateOfBirth)) <= 100
    );
  }

  function validateDriveLicenseAge() {
    const { DrivingLicenseIssueDate, DateOfBirth } = this.parent;

    let minimumDrivingAge = 18;
    return (
      differenceInYears(
        parseISO(DrivingLicenseIssueDate),
        parseISO(DateOfBirth)
      ) >= minimumDrivingAge
    );
  }

  function validateResidentInCyprusSince() {
    const { DateOfBirth, ResidentInCyprusSince } = this.parent;

    if (!DateOfBirth || !ResidentInCyprusSince) return false;

    return isWithinInterval(new Date(ResidentInCyprusSince), {
      start: new Date(DateOfBirth),
      end: new Date(),
    });
  }

  function validateLeftSiteOfRoadDate() {
    const {
      DrivingLicenseIssueDate,
      DrivingInLeftSiteOfRoadSince,
    } = this.parent;

    if (!DrivingLicenseIssueDate || !DrivingInLeftSiteOfRoadSince) return false;

    return isWithinInterval(new Date(DrivingInLeftSiteOfRoadSince), {
      start: new Date(DrivingLicenseIssueDate),
      end: new Date(),
    });
  }

  //** FORM AND ERROR HANDLING **//
  const {
    handleChange,
    errors,
    values,
    resetForm,
    handleSubmit,
    setValues,
  } = useForm({
    change: existingCustomer,
    validationSchema:
      existingCustomer === false ? SignupSchema : SignupSchemaFirst,
  });

  useEffect(() => {
    if (!isObjectEmpty(errors))
      setErrorMessage(t('There were errors in the form, please resolve them'));

    return () => setErrorMessage('');
  }, [errors]);

  useEffect(() => {
    resetForm();
    // Handles sessionStorage
    handleFormValues(additionalDrivers || []);
  }, [additionalDrivers]);

  function handleSelectValue(object, inputName) {
    if (values[inputName] === undefined || values[inputName] === null)
      return undefined;

    let key = null;
    if (oidObjects.includes(inputName)) {
      key = values[inputName]['Oid'];
    } else {
      key = values[inputName];
    }

    return object.find(({ value }) => value === key);
  }

  function handleSelectChange(val, name) {
    if (oidObjects.includes(name)) {
      return handleChange({
        name,
        // Nesting in Oid for some properties, see comment above declaration of oidObjects[]
        value: { Oid: val ? val.value : undefined },
      });
    }
    handleChange({ name, value: val ? val.value : undefined });
  }

  //** ACTION FUNCTIONS **//
  const updateDriver = async (driverWithUpdates) => {
    setIsFetching(true);

    const previousValues = additionalDrivers.find(
      ({ Oid }) => Oid === driverWithUpdates.Oid
    );
    const updatedValues = getObjectDifferences(
      driverWithUpdates,
      previousValues
    );
    // If updated data was empty, close form and do nothing
    if (isObjectEmpty(updatedValues)) {
      setIsFetching(false);
      toggleShowForm(false);
      return;
    }

    const [resp, status] = await ganUpdateCustomerIndvdl(
      updatedValues,
      driverWithUpdates.Oid
    );

    if (status !== 200) {
      setErrorMessage('Something went wrong at GanDirect');
      return setIsFetching(false);
    }

    if (resp.Error) {
      setErrorMessage(resp.Description);
      return setIsFetching(false);
    }

    setAdditionalDrivers((prevDrivers) => {
      const newDrivers = [...prevDrivers];
      const driverIndex = newDrivers.findIndex(
        ({ Oid }) => Oid === driverWithUpdates.Oid
      );

      if (driverIndex !== -1) newDrivers[driverIndex] = driverWithUpdates;
      return newDrivers;
    });

    setIsFetching(false);
    toggleShowForm(false);
  };

  const addNewDriver = async (_values) => {
    setIsFetching(true);
    if (
      Object.values(additionalDrivers).find(
        (item) => item.IdNo === _values?.IdNo
      )
    ) {
      toggleShowForm(false);
      setIsFetching(false);
      resetForm();
      return setExistingCustomer(null);
    }
    if (existingCustomer === null) {
      const [resp, status] = await ganGetCustomerById(_values?.IdNo);
      setIsFetching(false);
      if (status !== 200) {
        return setExistingCustomer(false);
      } else {
        toggleShowForm(false);
        resetForm();
        setExistingCustomer(null);
        return setAdditionalDrivers([
          ...additionalDrivers,
          { ...resp, existingCustomer: true },
        ]);
      }
    }

    if (existingCustomer === false) {
      // If driver already exists in DB (Oid already generated), update instead
      if (_values?.Oid) return updateDriver(_values);

      const newDriver = { ...defaultPayload, ..._values };
      const [resp, status] = await ganCreateCustomerIndvdl(newDriver);

      if (status !== 200) {
        setErrorMessage('Something went wrong at GanDirect');
        return setIsFetching(false);
      }

      if (resp.Error) {
        setErrorMessage(resp.Description);
        return setIsFetching(false);
      }

      setAdditionalDrivers([
        ...additionalDrivers,
        { ...newDriver, Oid: resp.Data },
      ]);
      setExistingCustomer(null);
      resetForm();
      setIsFetching(false);
      toggleShowForm(false);
    }
  };

  const deleteDriver = (index) => {
    // No API logic here, since there is no API endpoint for deleting drivers
    setAdditionalDrivers((prevDrivers) => {
      const newDrivers = [...prevDrivers];
      newDrivers.splice(index, 1);

      return newDrivers;
    });
  };

  const handleAddDriver = (e) => {
    e.preventDefault();
    handleSubmit(addNewDriver);
  };

  const onEdit = (driver) => {
    setValues(driver);
    toggleShowForm(true);
  };

  return (
    <>
      <TitleForm>{i18n.t('Additional drivers')}</TitleForm>
      <Form>
        <AddDriverButton
          onClick={(e) =>
            confirmLeaveForm(e, () => {
              toggleShowForm(!showForm);
              setExistingCustomer(null);
              resetForm();
            })
          }
        >
          {showForm ? t('Close add driver') : t('Add driver')}
        </AddDriverButton>
        {showForm && (
          //** FORM INPUTS **//
          <>
            <SubTitle>{t('Personal info')}</SubTitle>
            <TextInput
              name="IdNo"
              error={errors.IdNo}
              disabled={values?.Oid}
              onChange={(val) => handleChange(val)}
              value={
                defaultValues.idNo !== undefined
                  ? defaultValues.idNo
                  : values.IdNo
              }
            >
              <StyledLabel>{i18n.t('ID number')} *</StyledLabel>
            </TextInput>
            {existingCustomer === false && (
              <>
                <FlexWrapper>
                  <TextInput
                    name="FirstName"
                    onChange={handleChange}
                    error={errors.FirstName}
                    value={values.FirstName}
                  >
                    <StyledLabel>{t('First name')} *</StyledLabel>
                  </TextInput>
                  <TextInput
                    name="LastName"
                    error={errors.LastName}
                    onChange={handleChange}
                    value={values.LastName}
                  >
                    <StyledLabel>{t('Last name')} *</StyledLabel>
                  </TextInput>
                </FlexWrapper>

                <FlexWrapper>
                  <InputWrapper>
                    <StyledLabel className="padding">
                      {t('Title')} *
                    </StyledLabel>
                    <SelectInput
                      error={errors.Title}
                      name="Title"
                      onChange={(val) => {
                        handleSelectChange(val, 'Title');
                      }}
                      options={titleOptions}
                      placeholder={selectPlaceholder}
                      value={
                        handleSelectValue(titleOptions, 'Title') || undefined
                      }
                    />
                  </InputWrapper>
                  <DateWrapper>
                    <StyledLabel>{t('Date of birth')} *</StyledLabel>
                    <StyledDateInput
                      name="DateOfBirth"
                      error={errors.DateOfBirth}
                      onChange={handleChange}
                      value={values.DateOfBirth}
                    />
                  </DateWrapper>
                </FlexWrapper>

                <FlexWrapper>
                  <InputWrapper>
                    <StyledLabel className="padding">
                      {t('Country of birth')} *
                    </StyledLabel>
                    <SelectInput
                      error={errors.CustomerCountry}
                      name="CustomerCountry"
                      onChange={(val) => {
                        handleSelectChange(val, 'CustomerCountry');
                        // We do this way because GAN wants DrivingLicenseIssueCountry to equal CustomerCountry
                        handleSelectChange(val, 'DrivingLicenseIssueCountry');
                      }}
                      options={countries}
                      placeholder={selectPlaceholder}
                      value={handleSelectValue(countries, 'CustomerCountry')}
                    />
                  </InputWrapper>
                  {values?.CustomerCountry !== cyprusOid &&
                    values?.CustomerCountry && (
                      <DateWrapper>
                        <StyledLabel>
                          {t('Resident in Cyprus since')} *
                        </StyledLabel>
                        <StyledDateInput
                          name="ResidentInCyprusSince"
                          error={errors.ResidentInCyprusSince}
                          onChange={handleChange}
                          value={values.ResidentInCyprusSince}
                        />
                      </DateWrapper>
                    )}
                </FlexWrapper>

                <FlexWrapper>
                  <InputWrapper>
                    <StyledLabel className="padding">
                      {i18n.t('Occupation')} *
                    </StyledLabel>
                    <SelectInput
                      error={errors.Occupation}
                      name="Occupation"
                      onChange={(val) => {
                        handleSelectChange(val, 'Occupation');
                      }}
                      options={occupations_lowerCase}
                      placeholder={selectPlaceholder}
                      value={handleSelectValue(
                        occupations_lowerCase,
                        'Occupation'
                      )}
                    />
                  </InputWrapper>
                </FlexWrapper>

                <FlexWrapper>
                  <PhoneInput
                    name="ContactTelephoneNumber"
                    country="cy"
                    error={errors.ContactTelephoneNumber}
                    handleChange={handleChange}
                    value={values.ContactTelephoneNumber}
                    specialLabel=""
                  >
                    <StyledLabel> {t('Phonenumber')} *</StyledLabel>
                  </PhoneInput>
                  <TextInput
                    name="Email"
                    error={errors.Email}
                    onChange={(val) => handleChange(val)}
                    value={
                      defaultValues.Email !== undefined
                        ? defaultValues.Email
                        : values.Email
                    }
                  >
                    <StyledLabel>{t('Email')} *</StyledLabel>
                  </TextInput>
                </FlexWrapper>

                <SubTitle>{t('Info about driving')}</SubTitle>

                <FlexWrapper>
                  <InputWrapper>
                    <StyledLabel>{t('Is driver still a learner')}</StyledLabel>
                    <ToggleInput
                      checked={values.LearnerDrivingLicense}
                      name="LearnerDrivingLicense"
                      onChange={(val) => {
                        handleChange({
                          name: 'LearnerDrivingLicense',
                          value: val.checked,
                        });
                      }}
                      falseLabel={t('no')}
                      trueLabel={t('yes')}
                    />
                  </InputWrapper>
                  <DateWrapper>
                    <StyledLabel>
                      {t('Driving on left side of road since')} *
                    </StyledLabel>
                    <StyledDateInput
                      error={errors.DrivingInLeftSiteOfRoadSince}
                      name="DrivingInLeftSiteOfRoadSince"
                      onChange={(val) => {
                        handleChange(val);
                        // We do this way because GAN wants DrivingLicenseIssueDate to equal DrivingInLeftSiteOfRoadSince
                        handleChange({
                          name: 'DrivingLicenseIssueDate',
                          value: val.value,
                        });
                      }}
                      value={values.DrivingInLeftSiteOfRoadSince}
                    />
                  </DateWrapper>
                </FlexWrapper>

                <SubTitle>{t('Medical info')}</SubTitle>
                <FlexWrapper>
                  <InputWrapper>
                    <StyledLabel>
                      {t('Medical condition or disability')}
                    </StyledLabel>
                    <ToggleInput
                      checked={values.MedicalConditionOrDisability}
                      name="MedicalConditionOrDisability"
                      onChange={(val) => {
                        const isValTrue = val.checked;
                        handleChange({
                          name: 'MedicalConditionOrDisability',
                          value: isValTrue ? 1 : 0,
                        });
                        !isValTrue &&
                          handleChange({
                            name: 'YearsOfDisability',
                            value: '',
                          });
                      }}
                      falseLabel={t('no')}
                      trueLabel={t('yes')}
                    />
                  </InputWrapper>
                  {values.MedicalConditionOrDisability === 1 && (
                    <StyledNumbersInputWithSymbol
                      name="YearsOfDisability"
                      onChange={(val) =>
                        handleChange({
                          ...val,
                          value: parseInt(val.value) || null,
                        })
                      }
                      value={values.YearsOfDisability}
                      error={errors.YearsOfDisability}
                      disabled={!values.MedicalConditionOrDisability}
                      icon={<IconDisabilityFilled />}
                    >
                      {t('Years of disability')}
                    </StyledNumbersInputWithSymbol>
                  )}
                </FlexWrapper>
              </>
            )}

            <StyledError error={errorMessage} />

            {isFetching ? (
              <StyledLoadingSpinner />
            ) : (
              <AddDriverButton
                onClick={(e) => handleAddDriver(e)}
                className="addDriver"
                type="submit"
              >
                {values?.Oid ? t('Update driver') : t('Add additional driver')}
              </AddDriverButton>
            )}
          </>
        )}
      </Form>

      {additionalDrivers.length > 0 && !showForm && (
        <List>
          {additionalDrivers.map((driver, i) => (
            <ListItemCard
              title={`${driver.FirstName} ${driver.LastName}`}
              subTitle={driver.Email}
              key={i}
              onEdit={!driver?.existingCustomer ? () => onEdit(driver) : null}
              onRemove={() => deleteDriver(i)}
            />
          ))}
        </List>
      )}
      <ButtonContainer>
        <BackButton
          type="button"
          name="back"
          onClick={(e) => confirmLeaveForm(e, goBack)}
        >
          <IconActionChevronLeft />
          {t('Back')}
        </BackButton>
        <ActionButton
          onClick={(e) => confirmLeaveForm(e, goNextRoute)}
          type="submit"
          value="Confirm"
          data-test-id="risk_address_flow_submit"
        >
          {t('Next')}
        </ActionButton>
      </ButtonContainer>
    </>
  );
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 3rem auto;
  width: 60px;
  height: 60px;
`;

const StyledError = styled(Error)`
  margin-bottom: -1rem;
  justify-content: center;
`;

const StyledNumbersInputWithSymbol = styled(NumbersInputWithSymbol)`
  & span {
    padding: 0.5rem;
    top: 2.4rem;
  }

  & label {
    color: #8990a3;
    font-size: 1.5rem;
    line-height: 1rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.4rem;
    text-transform: capitalize;
    line-height: 125%;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }

  & div {
    margin-bottom: 0;
  }
  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4;
  }
`;

const InputWrapper = styled.div`
  width: -webkit-fill-available;
`;

const DateWrapper = styled(InputWrapper)`
  margin-bottom: 2.5rem;
`;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-size: 1.5rem;
  line-height: 1rem;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
  justify-content: left;
  overflow-y: auto;
  padding: 1.6rem 0;
  height: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 2rem;
  }

  @media screen and (max-width: 1140px) {
    flex-direction: column;
    & > div + div {
      margin-left: unset;
    }
  }
`;

const AddDriverButton = styled.button`
  background-color: silver;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  height: 4rem;

  margin-bottom: 2rem;
  padding: 1rem;

  &.addDriver {
    margin-top: 3rem;
  }
`;

const StyledDateInput = styled(DateInputV4)`
  margin-top: 1.4rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const SubTitle = styled.h3`
  color: black;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 1.65rem;
`;

export default FlowAddAdditionalDriver;
