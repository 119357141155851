import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  SearchSelectInput,
  Label,
  IconActionChevronLeft,
  DateInputV4,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import i18n from '../i18n';

import { string } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router';
import { ReasonForDoctorVisitEnum } from '../constants/FlowSearchSelectData';

const FlowParentalInformationForm = ({ handleFormValues, defaultValues }) => {
  const history = useHistory();
  const [lastVisitOptions, setLastVisitOptions] = useState([]);
  const [selected, setSelected] = useState({});

  const SignupSchema = Yup.object().shape({
    ReasonOfLastVisit: string.required,
    LastDateSeenByTheDoctor: Yup.date()
      .required('Required')
      .max(new Date(), 'Date must be in the past'),
  });

  const SelectPlaceholder = i18n.t('Choose your option');

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setLastVisitOptions(
      ReasonForDoctorVisitEnum.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (['ReasonOfLastVisit'].includes(name)) {
        handleSelected({ name, value: defaultValues[name] });
      } else {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }

  const getEnumValue = (name, value) => {
    switch (name) {
      case 'ReasonOfLastVisit':
        return ReasonForDoctorVisitEnum.find((item) => item.value === value);
      default:
        return;
    }
  };

  const handleSelected = ({ value, name }) => {
    const selectedVal = getEnumValue(name, value);
    setSelected((prevValue) => ({
      ...prevValue,
      [name]: selectedVal,
    }));
    handleChange({ value, name });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t('Health information')} </CatTitle>
        <DateInputV4
          name="LastDateSeenByTheDoctor"
          error={errors.LastDateSeenByTheDoctor}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.LastDateSeenByTheDoctor}
        >
          <StyledLabel>
            {i18n.t("When was your last doctor's appointment")}? *
          </StyledLabel>
        </DateInputV4>

        <SelectInput
          error={errors.ReasonOfLastVisit}
          name="ReasonOfLastVisit"
          onSelected={handleSelected}
          options={lastVisitOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.ReasonOfLastVisit}
        >
          <StyledLabel>
            {i18n.t('What was the reason of your last visit')}? *
          </StyledLabel>
        </SelectInput>

        <Required>* {i18n.t('Required fields')}</Required>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <StyledActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.text};
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

export default FlowParentalInformationForm;
