import React from 'react';

const PetsGanIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 79.61 79.61">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.34"
          y1="19.91"
          x2="74.27"
          y2="59.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#024284" />
          <stop offset="0.81" stopColor="#004587" />
          <stop offset="0.82" stopColor="#68a93e" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            cx="39.8"
            cy="39.8"
            r="38.56"
            style={{
              fill: 'none',
              strokeMiterlimit: '10',
              strokeWidth: '2.48162492794007px',
              stroke: 'url(#linear-gradient)',
            }}
          />
          <path
            d="M32.62,25H46.86l4.51,8a8,8,0,0,1,.84,5.67L48.79,54.06a4.74,4.74,0,0,1-4.63,3.72H36.07a4.76,4.76,0,0,1-4.56-3.43l-4.05-14A8,8,0,0,1,28,34.64Z"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <polyline
            points="46.86 25.01 57.17 25.01 61.59 37.77 52.26 41.7"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <polyline
            points="32.38 25.01 22.07 25.01 18.02 38.14 26.98 41.7"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <line
            x1="39.74"
            y1="47.58"
            x2="39.74"
            y2="53.12"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <line
            x1="36.18"
            y1="53.61"
            x2="43.06"
            y2="53.61"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <line
            x1="45.39"
            y1="36.55"
            x2="46.8"
            y2="36.55"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
          <line
            x1="33.42"
            y1="36.55"
            x2="34.83"
            y2="36.55"
            style={{
              fill: 'none',
              stroke: '#024284',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.4816000000000003px',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default PetsGanIcon;
