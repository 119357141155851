import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useForm from '../hooks/useForm.js';
import { TextInput, SearchSelectInput, ActionButton } from 'wg-fe-ui';
import * as Yup from 'yup';
import {
  string,
  number,
  vat_regime,
  seats,
} from '../constants/validationSchemas.js';
import useRouting from '../hooks/useRouting';
import {
  fuelTypeGan,
  annualMileageOptions,
} from '../constants/FlowSearchSelectData.js';
import { numberWithCommas } from '../helpers/stringService';
import OptionsPriceInput from '../components/VATInputSwitcherV2';
import i18n from '../i18n';
import { formatISODate } from '../helpers/dateService.js';
import DateInput from '../components/DateInput';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';
import { useParams } from 'react-router';
import { ChatContext } from '../contexts/ChatContext';
import FlowChatBox from '../components/FlowChatBox';

const FlowLicenseLookupResponseMobile = () => {
  const { id, affinity, insuranceType, currentVehicle } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const { cars } = data;
  const [defaultValues, setDefaultValues] = useState({});
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [annualMileage, setAnnualMileage] = useState(0);
  const [, setTransmissionType] = useState();
  const SelectPlaceholder = i18n.t('Choose your option');
  const { renderNextRoute } = useRouting();
  const { addQuestion } = useContext(ChatContext);
  const [invoiceError, setInvoiceError] = useState();

  const validationSchema = Yup.object().shape({
    brand: string.required,
    model: string.required,
    type: string.notRequired,
    manufacturedDate: string.required,
    kw: number.required,
    vat_regime: vat_regime,
    seats: seats,
    options: number.notRequired,
    catalogue_value: number.required,
  });

  const { errors, values, handleChange } = useForm({
    validationSchema,
  });

  const loadDefaultValues = async () => {
    if (cars[currentVehicle] != null) {
      setDefaultValues(cars[currentVehicle].vehicle_info);
    }
  };

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      switch (name) {
        case 'Make':
          handleChange({
            name: 'brand',
            value: defaultValues[name].VehicleMakeDesc,
          });
          break;
        case 'Model':
          handleChange({
            name: 'series',
            value: defaultValues[name].VehicleModelDesc,
          });
          break;
        case 'EngineHP':
          handleChange({
            name: 'power',
            value: defaultValues[name],
          });
          break;
        case 'Supply':
          handleChange({
            name: 'motor_type',
            value: defaultValues[name].PowerSupply,
          });
          break;
        case 'ManufacturedDate':
          handleChange({
            name: 'registration_first',
            value: formatISODate(defaultValues[name]),
          });
          break;
        case 'annualMileage':
          // handleChange({
          //   name: 'annualMileage',
          //   value: formatISODate(defaultValues[name]),
          // });
          setAnnualMileage(defaultValues[name]);
          break;
        case 'AnnualMileage':
          if (!defaultValues?.annualMileage) {
            setAnnualMileage(
              annualMileageOptions?.find(
                (item) => item.value === defaultValues[name]?.Oid
              )?.to
            );
          }
          break;
        case 'EngineCapacity':
          handleChange({
            name: 'engine_capacity',
            value: defaultValues[name],
          });
          break;

        default:
          handleChange({ name, value: defaultValues[name] });
          break;
      }
    });
  }, [defaultValues]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleFormValues();
  };

  const handleFormValues = () => {
    if (values.invoice_value === 0 || values.invoice_value == null) {
      setInvoiceError('Please enter a valid value');
      return;
    } else {
      setInvoiceError('');
    }
    patchStorage({
      payload: values,
      path: ['cars', [currentVehicle], 'vehicle_info'],
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute(1, { vehicle: currentVehicle });
  };

  const existingVehicle = data?.cars?.[currentVehicle]?.car_already_registered;

  useEffect(() => {
    const question = {
      message: i18n.t(
        'We gathered some data, please check out the details to make sure everything is correct'
      ),
      slug: 'license_response_data',
    };
    addQuestion(question);
    loadDefaultValues();
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setFuelTypeOptions(
      fuelTypeGan.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return '';
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  const transmissionTypes = [
    { value: 'auto', label: i18n.t('Automatic') },
    { value: 'man', label: i18n.t('Manual') },
  ];

  return (
    <Container>
      <FlowChatBox />
      <RightContainer>
        <Form onSubmit={formSubmitHandler}>
          <Scroll>
            <FlexWrapper>
              <StyledTextInput
                name="brand"
                error={errors.brand}
                onChange={(val) => {
                  handleChange(val);
                }}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.Make
                }
                value={values.brand}
              >
                <p>{i18n.t('Brand')} *</p>
              </StyledTextInput>
              <StyledTextInput
                name="series"
                error={errors.series}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.Model
                }
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.series}
              >
                <p>{i18n.t('Model (+year)')} *</p>
              </StyledTextInput>
            </FlexWrapper>
            <FlexWrapper>
              <SearchSelectInput
                onChange={(val) => {
                  handleSelectChange(val, 'motor_type');
                }}
                name="motor_type"
                error={errors.motor_type}
                options={fuelTypeOptions}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.Supply
                }
                value={handleSelectValue(fuelTypeOptions, 'motor_type')}
                placeholder={SelectPlaceholder}
              >
                <LabelName>{i18n.t('Fuel type')}</LabelName>
              </SearchSelectInput>
              <SearchSelectInput
                name="transmission_type"
                isClearable
                value={handleSelectValue(
                  transmissionTypes,
                  'transmission_type'
                )}
                disabled={!values.motor_type}
                placeholder={i18n.t('Choose your option')}
                options={transmissionTypes}
                onSelected={({ value }) => {
                  setTransmissionType(value);
                }}
              >
                <LabelName>{i18n.t('Transmission type')}</LabelName>
              </SearchSelectInput>
            </FlexWrapper>
            <FlexWrapper>
              <StyledDateInput
                name="registration_first"
                error={errors.registration_first}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.ManufacturedDate
                }
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.registration_first}
              >
                <p>{i18n.t('First registration date')} *</p>
              </StyledDateInput>
              <StyledTextInput
                name="power"
                error={errors.power}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.EngineHP
                }
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.power}
              >
                <StyledLabel>{i18n.t('HP')} *</StyledLabel>
                <p className="valueType">{i18n.t('HP')}</p>
              </StyledTextInput>
            </FlexWrapper>
            <FlexWrapper>
              <StyledTextInput
                name="engine_capacity"
                error={errors.engine_capacity}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.EngineCapacity
                }
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.engine_capacity}
              >
                <StyledLabel>{i18n.t('Engine capacity')} *</StyledLabel>
                <p className="valueType">{i18n.t('CC')}</p>
              </StyledTextInput>
              <StyledTextInput
                name="UnladenWeight"
                error={errors.UnladenWeight}
                disabled={
                  existingVehicle &&
                  data?.cars?.[currentVehicle]?.vehicle_info?.UnladenWeight
                }
                onChange={(val) => {
                  handleChange(val);
                }}
                value={values.UnladenWeight}
              >
                <StyledLabel>{i18n.t('Unladen weight')} *</StyledLabel>
                <p className="valueType">{i18n.t('KG')}</p>
              </StyledTextInput>
            </FlexWrapper>
            <RangeWrapper>
              <LabelName>
                {i18n.t(
                  'How many km/year will the vehicle travel approximately?'
                )}
              </LabelName>
              <div>
                <RangeContainer>
                  <p className="min">0 km</p>
                  <p className="max">{numberWithCommas(200000) + ' km'}</p>
                </RangeContainer>
                <MaxConsecutiveDays>
                  <input
                    type="range"
                    min="0"
                    max="200000"
                    value={annualMileage}
                    onChange={(e) => setAnnualMileage(e.target.value)}
                    step="2500"
                  />
                  <p>
                    <span> {annualMileage} </span>
                    km
                  </p>
                </MaxConsecutiveDays>
              </div>
            </RangeWrapper>
            <OptionsPriceInput
              name="price"
              id="invoice_value"
              error={errors.price}
              onChange={({ value, isVATInclusive }) =>
                handleChange({
                  name: 'invoice_value',
                  value: isVATInclusive ? (value / 1.19).toFixed(2) : value,
                })
              }
              value={values?.price}
              defaultValue={defaultValues?.invoice_value}
            >
              {i18n.t('Insured value')}
            </OptionsPriceInput>
            {invoiceError && <Error>{invoiceError}</Error>}
          </Scroll>
          <ButtonContainer>
            <Required>* {i18n.t('Required fields')}</Required>
            <ActionButton
              type="submit"
              data-test-id="manual_vehicle_flow_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          </ButtonContainer>
        </Form>
      </RightContainer>
    </Container>
  );
};

const MaxConsecutiveDays = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    margin-right: 1rem;
    width: 100%;
    height: 0.5rem;
    background: #f0f0f0; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    background: ${({ theme }) => theme.brand.primary};
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: #f0f0f0;
    border-color: transparent;
    color: transparent;
  }

  p {
    bottom: -3rem;
    color: #000000;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const RangeWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 6rem;
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  color: #cfcece;
  font-size: 1.4rem;
  font-weight: bold;
`;

const Error = styled.div`
  color: #f74040;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-top: -2rem;
  text-align: left;
`;

const LabelName = styled.p`
  color: #8990a3;
`;

const Scroll = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled.div`
  color: #8990a3;
  height: 1rem;
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p {
    color: #8990a3;
  }
  p.valueType {
    padding: 1.4rem;
    border-left: 1px solid #e4e4e4;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.5rem;
  }
  input {
    border-color: #e4e4e4 !important;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .Select__control {
    border-color: #e4e4e4 !important;
  }
  .Select__indicator-separator {
    background-color: #e4e4e4 !important;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;

  & button {
    height: 4rem;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
`;

const StyledDateInput = styled(DateInput)`
  margin-bottom: 3rem;
  p {
    color: #8990a3;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 25% 75%;
  overflow: hidden;
  width: 100%;
`;

const RightContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

export default FlowLicenseLookupResponseMobile;
