/**
 * Return the sting length
 * @param {string} number
 */

export const capitalize = s => {
  if (!s) return '';
  const l = s.toLowerCase();
  return l && l[0].toUpperCase() + l.slice(1);
};

export const stringLength = string => {
  if (typeof string === 'string') {
    return string.length;
  }

  return 0;
};

/**
 * Generates a comma-seperated string representing a number
 * @param {number} x Number input
 * @returns {string} Comma-seperated string
 */
export const numberWithCommas = x => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};
