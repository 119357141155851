import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import useRouting from '../hooks/useRouting';
import { ChatContext } from '../contexts/ChatContext';
import { patchStorage, retrieveStorageById } from '../helpers/storeService';
import { ActionButton, IconActionChevronLeft } from 'wg-fe-ui';
import LoadingActionButton from '../components/LoadingActionButton';
import { useHistory } from 'react-router-dom';
import FlowChatBox from '../components/FlowChatBox';
import { isEqual } from 'lodash';
import ListItemCard from '../components/ListItemCard';
import BikeSelector from '../components/BikeSelector';

const FlowInsureExtrasPets = () => {
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const { addQuestion } = useContext(ChatContext);
  const [bikesArray, setBikesArray] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const question = {
      message: i18n.t(`Which <span>bikes</span> do you have?`),
      slug: 'insure_extras_bike',
    };
    addQuestion(question);
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setBikesArray(data?.risk_addresses[currentProperty]?.Bikes || []);
  }, []);

  const submitHandler = () => {
    setLoading(true);
    patchStorage({
      payload: {
        Bikes: bikesArray,
      },
      path: ['risk_addresses', [currentProperty]],
      id,
      affinity,
      insuranceType,
    });
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    if (
      data?.risk_addresses?.[currentProperty]?.extra_items_to_insure?.includes(
        'Pets'
      )
    )
      renderNextRoute(2, { property: currentProperty });
    else renderNextRoute(1, { property: currentProperty });
    setLoading(false);
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  const isMobile = () => {
    if (
      insuranceType === 'allMobile' ||
      insuranceType === 'carMobile' ||
      insuranceType === 'healthMobile' ||
      insuranceType === 'homeMobile'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRemoveBike = (bike) => {
    const _bikes = [...bikesArray];
    const filteredArray = _bikes.filter((_bike) => !isEqual(_bike, bike));
    setBikesArray(filteredArray);
  };

  return (
    <Container>
      {!isMobile() && <FlowChatBox />}
      <RightContainer isMobile={isMobile()}>
        <TitleForm>{i18n.t('Which bikes do you have?')}</TitleForm>
        {bikesArray.length > 0 && (
          <PetList>
            {bikesArray.map((bike, i) => (
              <ListItemCard
                title={`${bike.Make} ${bike.Model}`}
                price={bike.Value}
                onRemove={() => handleRemoveBike(bike)}
                key={i}
              />
            ))}
          </PetList>
        )}
        <BikeSelector
          onChange={(newBike) => setBikesArray([...bikesArray, newBike])}
        />
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          {loading ? (
            <LoadingActionButton
              type="submit"
              value="Confirm"
              data-test-id="risk_address_flow_loading"
            >
              {i18n.t('Loading')}
            </LoadingActionButton>
          ) : (
            <ActionButton
              type="submit"
              value="Confirm"
              onClick={() => submitHandler()}
              data-test-id="risk_address_flow_submit"
            >
              {i18n.t('Next')}
            </ActionButton>
          )}
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

const PetList = styled.ul`
  display: flex;
  margin-bottom: 1.6rem;
  justify-content: left;
  overflow-x: auto;
  padding: 1.6rem 0;
  min-height: 13rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const RightContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
    ${(isMobile) => isMobile && 'padding: 2rem;'}
  }
  ${(isMobile) => isMobile && 'padding: 2rem;'}
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default FlowInsureExtrasPets;
