import { getAffinityFlow } from "../helpers/tagRouterService";
import { useLocation, useHistory, useParams } from "react-router-dom";

const useRouting = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    affinity,
    insuranceType,
    id,
    currentVehicle,
    currentProperty,
  } = useParams();
  const routingData = getAffinityFlow({ affinity, insuranceType });

  function currentRouteKey() {
    let foundCurrentKey;
    routingData.forEach(({ title }) => {
      if (pathname.includes(title)) foundCurrentKey = title;
    });
    return foundCurrentKey;
  }

  function getFirstRoute() {
    return routingData[0] ? routingData[0].title : null;
  }

  function currentRouteObject() {
    const [currentRoute] = routingData.filter(
      ({ title }) => title === currentRouteKey()
    );
    return currentRoute;
  }

  function renderNextRoute(routeIndex = 1, flowData) {
    const { nextRoutes } = currentRouteObject();

    if (flowData) {
      if (flowData.vehicle >= 0) {
        if (
          (flowData.vehicle || flowData.vehicle === 0) &&
          currentVehicle === undefined
        ) {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/0/${id}`
          );
        } else {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${flowData.vehicle}/${id}`
          );
        }
      } else if (flowData.property >= 0) {
        if (
          (flowData.property || flowData.property === 0) &&
          currentProperty === undefined
        ) {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/0/${id}`
          );
        } else {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${flowData.property}/${id}`
          );
        }
      } else if (flowData.health >= 0) {
        if (
          (flowData.health || flowData.health === 0) &&
          currentProperty === undefined
        ) {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/0/${id}`
          );
        } else {
          history.push(
            `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${flowData.property}/${id}`
          );
        }
      }
    } else {
      history.push(
        `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${id}`
      );
    }
  }

  function getNextRoute(routeIndex = 1) {
    const { nextRoutes } = currentRouteObject();

    return nextRoutes[routeIndex];
  }

  return { renderNextRoute, currentRouteObject, getNextRoute, getFirstRoute };
};

export default useRouting;
