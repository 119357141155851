/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { string, func } from 'prop-types';
import { IconActionPlus, TextInput } from 'wg-fe-ui';
import styled from 'styled-components';
import PriceInput_V2 from './PriceInput_V2';
import useForm from '../hooks/useForm';
import { string as validationString } from '../constants/validationSchemas';
import { isObjectEmpty } from '../helpers/objectService';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const BikeSelector = ({ name, onChange }) => {
  const [type, setType] = useState();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    Make: validationString.required,
    Model: validationString.required,
    Value: Yup.number()
      .min(200, t('Purchase value has to be at least 200 EUR'))
      .max(900, t('Purchase value can only be 900 EUR'))
      .required(t(`required`)),
  });

  const { values, errors, resetForm, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  const handleAdd = (vals) => {
    const { Make, Model, Value } = vals;
    const payload = {
      Value: Value || 0,
      Make,
      Model,
    };
    onChange(payload);
    setType();
    resetForm();
  };

  useEffect(() => {
    resetForm();
  }, [type]);

  return (
    <div style={{ width: '100%' }}>
      <Wrapper>
        <TextInput
          name="Make"
          onChange={handleChange}
          error={errors?.Make}
          value={values?.Make}
        >
          {i18n.t('Make')}
        </TextInput>
        <TextInput
          name="Model"
          onChange={handleChange}
          error={errors?.Model}
          value={values?.Model}
        >
          {i18n.t('Model')}
        </TextInput>
      </Wrapper>
      <PriceInput_V2
        onChange={(value) => handleChange({ name: 'Value', value })}
        value={values?.Value}
        name="Value"
        style={{ width: '50%' }}
        error={errors?.Value}
      >
        {i18n.t(`Purchase value`)}
      </PriceInput_V2>
      <AddItemsToInventory
        disabled={!isObjectEmpty(errors) || isObjectEmpty(values)}
        onClick={() => handleSubmit(handleAdd)}
      >
        <IconActionPlus />
        {i18n.t('Add')} {name}
      </AddItemsToInventory>
    </div>
  );
};

const AddItemsToInventory = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme, disabled }) =>
    disabled ? theme.ui.disabled : theme.brand.primary};
  border-radius: 5px;
  font-size: 1.6rem;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border: none;
  margin-bottom: 3rem;
  grid-column: span 6 / auto !important;
  margin-top: 1rem;
  > svg {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.ui.disabled : theme.brand.primary};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    width: 48%;
  }
`;

BikeSelector.propTypes = {
  name: string,
  onChange: func,
};

export default BikeSelector;
