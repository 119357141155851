import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import useRouting from '../hooks/useRouting.js';
import { useHistory, useParams } from 'react-router';
import { patchStorage, retrieveStorageById } from '../helpers/storeService.js';
import {
  AccordionBox,
  ActionButton,
  PieProgressIndicator,
  IconCertificateFilled,
  LoadingSpinner,
  SmallLoader,
  IconActionChevronLeft,
} from 'wg-fe-ui';
import CoverDetail from '../components/CoverDetail';

import HomeBasicGanIcon from '../assets/icons/HomeBasicGanIcon';
import HomeEcoGanIcon from '../assets/icons/HomeEcoGanIcon';
import HomePremGanIcon from '../assets/icons/HomePremGanIcon';
import HomeCatGanIcon from '../assets/icons/HomeCatGanIcon';
import { getCoverDetailsHome } from '../helpers/CoverDetailsService.js';
import { useQuery } from 'react-query';
import { createPropertyPolicyGan } from '../helpers/apiRouterService.js';
import { useTranslation } from 'react-i18next';

const myObj = {
  style: 'currency',
  currency: 'EUR',
};

const FlowHomePremiumSummary = () => {
  const { t } = useTranslation();
  const { renderNextRoute } = useRouting();
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const { id, affinity, insuranceType, currentProperty } = useParams();
  const storage = retrieveStorageById(id, affinity, insuranceType);
  const [coverData, setCoverData] = useState(storage?.data);
  const [areDiscountsOpen, setAreDiscountsOpen] = useState(false);
  const [policyData, setPolicyData] = useState({});
  const [cachedData, setCachedData] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [selected, setSelected] = useState('Economic');

  const getConstructionMaterialString = (package_info) => {
    return `${package_info?.column_material
      ?.map((value) => `Columns / ${value?.value}`)
      .join(';')};${package_info?.floor
      ?.map((value) => `Floor / ${value?.value}`)
      .join(';')};${package_info?.roof
      ?.map((value) => `Roof / ${value?.value}`)
      .join(';')};${package_info?.walls
      ?.map((value) => `Walls / ${value?.value}`)
      .join(';')}`;
  };

  const getPropertyType = (type) => {
    switch (type) {
      case 'open':
        return 35;
      case 'halfopen':
        return 30;
      case 'Detached':
        return 29;
      case 'Appartment':
        return 25;
      default:
        return 30;
    }
  };

  const insurance_packages = storage?.data?.risk_addresses[currentProperty]
    ?.is_landlord
    ? [{ value: 'Value' }, { value: 'Premium' }]
    : [{ value: 'Tenant' }];

  const getPayload = (_data, selected, currentProperty) => {
    const isPremium = selected === 'Premium';
    const payload = {
      Customer: {
        Oid: _data?.ganCustomer?.Oid,
      },
      PropertyStatus: 1,
      AvailiableProductsForCustomer: _data?.risk_addresses[currentProperty]
        ?.is_landlord
        ? 'FTP_and_PL_and_EL'
        : 'HHC_and_EL',
      SelectedProduct:
        selected === 'Value'
          ? _data?.risk_addresses[currentProperty]?.is_landlord
            ? 1
            : 3
          : 0,
      DoYouWishToHaveCargoInsurance: 0,
      DoesYourBusinessEmployeeAnyAdditionalPeople: 0,
      DoPeopleOtherThanEmployeesEnterYourPremisses: 0,
      DoesYourBusinessProvideProfessionalAdviseOrConstultationToCustomers: 0,
      DoesYourBusinessSupplieProductsOrComponentsToCustomers: 0,
      CouldTheftOrDishonestyByEmployeesBeAProblem: 0,
      PremisesHasMachineryStockOrOtherContents: 0,
      WillYourBusinessBeAffectedByAnyInterruptionLikeFireFloodDeniedAccess: 0,
      BusinessIsInvolvedInTransportinGoodsOrProducts: 0,
      BusinessOperateMotorVechiclesOrUsesStaffOwnVechicles: 0,
      BusinessProvidesHealthBenefitsToStaff: 0,
      ExistingInsuranceCompany: {
        InsuranceCompany:
          _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.insurance_company_name || 'None',
      },
      ExpiringDateOfYourCurrentPolicy: _data?.risk_addresses[currentProperty]
        ?.parcel?.main_building?.insurance_company_name
        ? _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.start_of_contract || null
        : null,
      ExistingInsuranceCompanyPrice: _data?.risk_addresses[currentProperty]
        ?.parcel?.main_building?.insurance_company_name
        ? _data?.risk_addresses[currentProperty]?.parcel?.main_building
            ?.insurance_price || null
        : null,
      BuildingAddress: _data?.risk_addresses[currentProperty]?.Oid,
      FinancialInstitution:
        _data.risk_addresses[currentProperty]?.FinancialInstitution || null,
      JointOwnership: 0,
      PropertyType: getPropertyType(
        _data.risk_addresses[currentProperty]?.parcel?.main_building?.house_type
      ),
      ManufactureYear:
        _data?.risk_addresses[currentProperty]?.parcel?.main_building
          ?.construction_year,
      DateOfLastRenovation:
        _data?.risk_addresses[currentProperty]?.parcel?.main_building
          ?.renovation_year ||
        new Date(
          _data?.risk_addresses[
            currentProperty
          ]?.parcel?.main_building?.construction_year
        ).toISOString(),
      ConsecutiveDaysUnoccupiedFor:
        _data?.ConsecutiveDaysUnoccupiedFor?.value || 1,
      ConstructionMaterial: getConstructionMaterialString(
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.construction_details
      ),
      MethodsOfHeating:
        _data.risk_addresses[
          currentProperty
        ].parcel?.main_building?.construction_details?.heating_method
          ?.map((value) => value?.value)
          ?.join(';') || 'None',
      WaterPipes:
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.WaterPipes || 1,
      UseOfProperty: 1,
      DoesYourPropertyHaveAntisesmicSurvey:
        _data.risk_addresses[currentProperty]?.parcel?.main_building
          ?.DoesYourPropertyHaveAntisesmicSurvey,
      TypeOfPremises: null,
      DoYouHaveAnyLargeQuantitiesOfWaterWithinPremises:
        'No Large Water Quantities',
      SafetyFeatures: '',
      IsThePropertyNeighbouringToAnOpenAreaWithBushesOrShrubsOrTreesWithinThirtyMetersFromProperty: 0,
      IsThereAManMadeOrNaturalFirePortectionZone: 0,
      IsThePropertyAtARemoteDestination: 0,
      PolicyDurationMonths:
        _data.risk_addresses[currentProperty]?.policy_details
          ?.insurance_duration,
      StartDate: new Date(
        _data.risk_addresses[currentProperty]?.policy_details?.starting_date
      ).toISOString(),
      EndDate: new Date(
        _data.risk_addresses[currentProperty]?.policy_details?.ending_date
      ).toISOString(),
      BuildingSumInsured:
        selected === 'Tenant'
          ? 0
          : _data.BuildingSumInsured ||
            _data.risk_addresses[currentProperty]?.parcel?.main_building
              ?.surface * 1200,
      AccidentalDamageToBuilding: _data?.AccidentalDamageToBuilding || 0,
      ContentsSumInsured:
        _data.ContentsSumInsured ||
        _data.risk_addresses[currentProperty]?.parcel?.content_sum
          ?.insurance_price ||
        0,
      AccidentalDamageToContents: _data?.AccidentalDamageToContents || 0,
      TotalValueOfHighRiskItems: 0,

      SpecifiedItemsTotalValue: _data?.SpecifiedItemsTotalValue || 0,
      DomesticFreezeContents: _data?.DomesticFreezeContents || 0,
      TracingTheLeakCoverAmount:
        _data?.TracingTheLeakCoverAmount?.value ||
        '40b2dd18-cb9c-4ddd-9ebc-fdc8e49bc39a',
      ValuableAndPersonalEffects: _data?.ValuableAndPersonalEffects || 0,
      PersonalMoneyAndCreditCards: _data?.PersonalMoneyAndCreditCards || 0,
      UsePedalCycles: !!_data?.UsePedalCycles ? 1 : 0,
      PetInsurance: _data?.PetInsurance && isPremium ? 1 : 0,
      PetsInProperty:
        _data.risk_addresses[currentProperty]?.PetsInProperty || null,
      PropertyInventory:
        _data.risk_addresses[currentProperty]?.PropertyInventory?.length > 0
          ? _data.risk_addresses[currentProperty]?.PropertyInventory
          : null,
      ClassicVehicleInsurance:
        _data.risk_addresses[currentProperty]?.ClassicVehicleInsurance || 0,
      ClassicVehicleMarketValue: null,
      AccidentsToDomesticStaff: isPremium ? 1 : 0,
      VesselLiability: 0,
      SkiersLiability: 0,
      PassengerLiability: 0,
      HuntersLiability: 0,
      MedicalForDomesticStaff: 0,
      VesselInProperty: null,
      PedalCycles: _data.risk_addresses[currentProperty]?.Bikes
        ? _data.risk_addresses[currentProperty]?.Bikes
        : null,
      DomesticStaff: null,
      ContentsSumInsuredExcludingSAndC: null,
      TheftForContents: 0,
      StockSumInsured: null,
      TheftForStock: 0,
      OwnComputerEquipmentSumInsured: null,
      TotalElectronicEquipmentSumInsured: null,
      MoneyCoverSumInsured: null,
      BusinessInterruptionSumInsured: null,
      BusinessInterruptionForRegisteredCoreEmployeesSalaries: null,
      NetAnnualSalaryForAllCoreRegisteredEmployees: null,
      LimitOfIdemnityRequiredForAnyOneClaimSumInsured: null,
      NoOfAllEmployees: null,
      AnnualWagesAndSalariesForAllEmployees: null,
      SumInsuredByAnyOneConsignment: null,
      PublicLiability: 0,
      DoYouHaveAWrittenQualityProceduresManual: 0,
      ProductLiability: 0,
      EmployersLiablity: _data.EmployersLiablity || 0,
      EmployersSocialSecurityNo: null,
      EmployersLiabilityList: null,
      PersonalAccident: 0,
      NoOfPersonsForPersonalAccidentCover: null,
      NoOfUnitsForEachEmployee: null,
      PersonalAccidentPersons: null,
      GlassSumInsured: null,
      SignSumInsured: null,
      PanesOfGlassWhereLengthAndWidthExceed5mOrTotalAreaExceeds10cm: null,
      HomeBusinessSecure: _data.risk_addresses[currentProperty]
        ?.HomeBusinessSecure || { Oid: isPremium ? 2 : 5 },
      TotalCoverAreaOfGlassInSquareCm: null,
      SpecifiedItemsSumInsured: null,
      UnspecifiedItemsSumInsured: null,
      ProfessionalIdemnity: 0,
      NoOfEmployeesThatYouWishToInsureForProfessionalIdemity: null,
      CountryYourBusinessIsDomicited: null,
      TotalTurnoverOfTheLastYearIncludingFeeIncome: null,
      HaveYouBeenChargedWithACriminalOffence: 0,
      TotalResponse: _data?.TotalResponse?.value || 4,
      AutomaticRenewal: _data?.AutomaticRenewal || false,
      EarthquakeExcess:
        _data.risk_addresses[currentProperty]?.EarthquakeExcess || 1148,
    };
    return payload;
  };

  const handleApiCall = async (payloadArg) => {
    const [res, status] = await createPropertyPolicyGan(
      Object.keys(payloadArg)?.[0] !== 'queryKey'
        ? payloadArg
        : getPayload(coverData, selected, currentProperty)
    );
    if (status === 200) return res;
    else throw new Error(selected);
  };

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['propertyData', selected, coverData],
    handleApiCall,
    {
      enabled: selected !== undefined,
      retry: 2,
      staleTime: 120000,
    }
  );

  useEffect(() => {
    if (!isEqual(coverData, storage?.data) && policyData[selected]) {
      setCachedData({ ...cachedData, [selected]: { ...coverData } });
      refetch();
    }
  }, [coverData]);

  function iconType(icon) {
    switch (icon) {
      case 'Basic Plus': //terraced
        return <HomeBasicGanIcon />;
      case 'Economic': //semi-detached
        return <HomeEcoGanIcon />;
      case 'Value':
        return <HomeCatGanIcon />;
      case 'Premium':
        return <HomePremGanIcon />;
      default:
        return <HomeCatGanIcon />;
    }
  }

  const getPackageColor = (insuranceType) => {
    switch (insuranceType) {
      case 'Basic Plus': //terraced
        return '#98989B';
      case 'Economic': //semi-detached
        return '#00A3E4';
      case 'Value':
        return '#024284';
      case 'Premium':
        return '#C29B67';
      default:
        return '#98989B';
    }
  };

  useEffect(() => {
    if (storage.data?.risk_addresses[currentProperty].home_insurance_pack) {
      setSelected(
        storage.data?.risk_addresses[currentProperty].home_insurance_pack?.value
      );
    }
  }, []);

  useEffect(() => {
    if (data) {
      setPolicyData({ ...policyData, [selected]: data });
    }
  }, [data, selected]);

  useEffect(() => {
    if (cachedData[selected]) {
      setCoverData(cachedData[selected]);
    } else {
      // set default data values
      const _data = { ...storage?.data };
      _data['BuildingSumInsured'] =
        _data.risk_addresses[currentProperty]?.parcel?.main_building?.surface *
        1200;
      setCoverData(_data);
    }
  }, [selected]);

  useEffect(() => {
    let arr = [];
    if (policyData[selected]?.Data) {
      Object.entries(policyData[selected]?.Data).forEach((item) => {
        if (item[0].includes('Discount') && item[0] !== 'Discount') {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policyData[selected]]);

  const premium_summary_items = [
    {
      value: 'Premium',
      amount: (
        parseFloat(policyData[selected]?.Data?.Total?.replace(/,/g, '.') || 0) +
        -1 *
          parseFloat(
            policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
          )
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Discounts',
      amount: parseFloat(
        policyData[selected]?.Data?.Discount?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Stamp & fees',
      amount: parseFloat(
        policyData[selected]?.Data?.Fees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
    {
      value: 'Total',
      amount: parseFloat(
        policyData[selected]?.Data?.TotalwithFees?.replace(/,/g, '.') || 0
      ).toLocaleString('nl-BE', myObj),
    },
  ];

  const handleNextRoute = async () => {
    setIsAdding(true);
    const payload = getPayload(coverData, selected, currentProperty);
    payload.SavePolicy = 'true';
    const resp = await handleApiCall(payload);
    if (resp?.Error) return;
    patchStorage({
      payload: {
        home_package_cover_details: payload,
        insurance_pack: selected,
        insurance_info: resp?.Data,
      },
      path: ['risk_addresses', currentProperty],
      id,
      affinity,
      insuranceType,
    });

    if (storage?.data?.insurances != null) {
      if (storage?.data?.insurances?.Home > +currentProperty + 1) {
        let nextProperty = 1 + +currentProperty;
        renderNextRoute(1, { property: nextProperty });
      } else if (storage?.data?.insurances?.Car > 0) {
        renderNextRoute(2, { vehicle: 0 });
      } else if (storage?.data?.insurances?.Health > 0) {
        renderNextRoute(3, { health: 0 });
      } else {
        renderNextRoute(4);
      }
    }
    setIsAdding(false);
  };

  function receiveChanges(val) {
    const _coverData = { ...coverData };
    if (!val?.toggle && !val?.amountOptionsNoToggle) {
      delete _coverData[val?.key];
      return setCoverData(_coverData);
    }
    if (val?.toggleOnValue) {
      _coverData[val?.key] = val?.toggleOnValue || '';
      setCoverData(_coverData);
    } else if (val?.toggleOnAmount) {
      _coverData[val?.key] = val?.toggleOnAmount || '';
      setCoverData(_coverData);
    } else if (val.amount) {
      _coverData[val?.key] = val?.amount || '';
      setCoverData(_coverData);
    } else {
      _coverData[val?.key] = val?.amount || '';
    }
  }

  const getDiscountPrice = (value) => {
    const discountPercentages = {
      FirstHomeDiscount: 10,
      HouseProtectiveDevicesDiscount: 10,
      NewMoveDiscount: 10,
      PGThirtyThreeDiscount: 33,
      FiftyContentsDiscount: 50,
      BundlingDiscount: 5,
    };
    if (discountPercentages[value])
      return (
        (parseInt(policyData[selected]?.Data?.Total || '0') / 100) *
        discountPercentages[value]
      ).toFixed(2);
    else return 0;
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container>
      <TopContainer>
        <BackButton type="button" name="back" onClick={goBack}>
          <IconActionChevronLeft />
          {t('Back')}
        </BackButton>
        <PackagesContainer>
          {insurance_packages?.map((insurance) => {
            return (
              <Extra
                selected={selected === insurance.value}
                borderColor={getPackageColor(insurance.value)}
                onClick={() => setSelected(insurance.value)}
              >
                <span>{iconType(insurance.value)}</span>
                <p>{insurance.value}</p>
              </Extra>
            );
          })}
        </PackagesContainer>
      </TopContainer>
      <BottomContainer>
        <LeftContainer>
          <CoverDetailsTable>
            <Headers>
              <p>{t('Cover details')}</p>
              <p>{t('Cover limit')}</p>
              <p>{t('Premium')}</p>
            </Headers>
            {isLoading && !policyData[selected] ? (
              <LoadingBox>
                <LoadingSpinner className="spinner" />
              </LoadingBox>
            ) : (
              policyData[selected] && (
                <Content>
                  {data?.Error && (
                    <ErrorBoxLine>
                      <strong>{t('Error! ')}</strong>
                      {data?.Description}
                    </ErrorBoxLine>
                  )}
                  {getCoverDetailsHome(
                    selected,
                    cachedData,
                    storage?.data.risk_addresses[currentProperty]
                  ).map((details) => {
                    return (
                      <CoverDetail
                        details={details}
                        prices={data?.Data}
                        sendChanges={(changes) => {
                          receiveChanges(changes);
                        }}
                      />
                    );
                  })}
                </Content>
              )
            )}
          </CoverDetailsTable>
        </LeftContainer>
        <RightContainer>
          {!areDiscountsOpen && (
            <div>
              <Title>{t('Premium summary')}</Title>
              <PremiumItems>
                {premium_summary_items.map((item) => {
                  return (
                    <div>
                      <span>{item.value}</span>
                      <p>{item.amount}</p>
                    </div>
                  );
                })}
              </PremiumItems>
            </div>
          )}

          <StyledAccordionBox
            isOpen={areDiscountsOpen}
            onOpen={(open) => setAreDiscountsOpen(open)}
          >
            <AccordionBox.Header>
              <AccordionHeader>
                <p>{t('Enabled discounts')}</p>
                <DiscountCount>
                  <PieProgressIndicator
                    percentage={
                      discounts.length === 0
                        ? 0
                        : (discounts?.filter(
                            (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                          )?.length /
                            discounts?.length) *
                          100
                    }
                  />
                  <p>
                    {
                      discounts?.filter(
                        (d) => d[1] !== '0,00' && d[0] !== 'Discount'
                      )?.length
                    }
                    /{discounts?.length}
                  </p>
                </DiscountCount>
              </AccordionHeader>
            </AccordionBox.Header>
            <AccordionBox.Content>
              <AccordionInfo>
                {discounts?.map((discount) => {
                  if (discount[1] !== '0,00' && discount[0] !== 'Discount') {
                    return (
                      <div className="discount-row">
                        <IconCertificateFilled color="#8990A3" />
                        <p className="discount-label">{t(discount[0])}</p>
                        <p className="discount-price">{discount[1]} &euro;</p>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
                <Header>Possible discounts</Header>
                {discounts?.map((discount) => {
                  if (discount[1] === '0,00' && discount[0] !== 'Discount') {
                    return (
                      <div className="discount-row">
                        <IconCertificateFilled color="#8990A3" />
                        <p className="discount-label">{t(discount[0])}</p>
                        <p className="discount-price">
                          -{getDiscountPrice(discount[0])} &euro;
                        </p>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
              </AccordionInfo>
            </AccordionBox.Content>
          </StyledAccordionBox>
          {isFetching && policyData[selected] ? (
            <UpdatingContainer>
              <p>{t('Updating price')}</p>
              <SmallLoader color="" />
            </UpdatingContainer>
          ) : null}
          <ButtonContainer>
            <Total>
              <p>{t('Your total premium')}</p>
              <span>&euro; {policyData[selected]?.Data?.TotalwithFees}</span>
            </Total>
            <ActionButton
              disabled={data?.Error || isLoading || isAdding}
              onClick={() => handleNextRoute()}
            >
              {t('Add to basket')}{' '}
              {isAdding && (
                <SmallLoader style={{ marginLeft: '20px' }} color="" />
              )}
            </ActionButton>
          </ButtonContainer>
        </RightContainer>
      </BottomContainer>
    </Container>
  );
};

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1.8rem;
  border-top: 0.1rem solid #e4e4e4;

  & button {
    font-weight: bold;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6.4rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 8rem;
    height: 2rem;
    justify-content: center;
    > div {
      margin-right: -3rem;
    }
  }
  p {
    font-weight: 550;
  }
`;

const StyledAccordionBox = styled(AccordionBox)`
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;
  margin-top: ${({ isOpen }) => (!isOpen ? 'auto' : '0')};
`;

const Total = styled.div`
  align-self: center;
  margin-bottom: 1rem;
  width: 100%;
  margin-top: 1rem;
  > p {
    font-size: 1.4rem;
    color: #8990a3;
    margin-bottom: 0.5rem;
  }
  > span {
    font-weight: 550;
    font-size: 2.1rem;
  }
`;

const PremiumItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    padding: 0.4rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h2`
  color: #30373d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
`;

const Headers = styled.div`
  border-bottom: 1px solid #e5e5e5;
  color: #8990a3;
  display: grid;
  font-weight: 550;
  grid-template-columns: 55% 25% 20%;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  p:first-child {
    padding-left: 6rem;
  }
`;

const Content = styled.div`
  padding-right: 1.5rem;
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
  > div {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    padding: 0.8rem 0;
  }
`;

const CoverDetailsTable = styled.div`
  height: 100%;
`;

const LeftContainer = styled.div`
  height: 100%;
  margin: 0;
  overflow: hidden;
`;

const RightContainer = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
  padding: 1.5rem;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Extra = styled.div`
  align-items: center;
  background: #ffffff;
  border: ${({ selected }) => (selected ? `0.2rem solid` : '1px solid')};
  border-color: ${({ selected, borderColor }) =>
    selected ? borderColor : '#e4e4e4'};
  box-shadow: 0px 2px 4px rgba(32, 32, 35, 0.1);
  border-radius: 6px;
  color: ${({ selected, borderColor }) => (selected ? borderColor : '#cfcece')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  > p {
    font-size: 1.75rem;
    text-align: center;
  }
  span > svg {
    height: 4rem;
    width: 3rem;
  }
  @media (max-width: 1000px) {
    padding: 0 1rem;
    p {
      font-size: 1.5rem;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 15% 85%;
  width: 100%;
  height: 100%;
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  min-width: 8rem;
  height: 4rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const TopContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 2rem;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-rows: 43% 57%;
    grid-template-columns: unset;
  }
`;

const DiscountCount = styled.div`
  margin-left: auto;
  display: flex;
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: ${({ theme }) => theme.brand.primary};
  }

  ::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }
`;

const AccordionInfo = styled(Scroll)`
  margin-top: -5.5rem;
  overflow-y: auto;
  max-height: 33vh;
  width: 100%;
  padding-right: 1.6rem;
  & .discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #e4e4e4;
    font-size: 1.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    & svg {
      width: 4rem;
      margin-right: 0.8rem;
    }

    & .discount-label {
      color: #11141c;
      width: 100%;
    }

    & .discount-price {
      margin-left: auto;
      width: 15rem;
      text-align: right;
    }
  }
`;

const Header = styled.p`
  font-weight: 550;
  margin-bottom: 1.5rem;
  margin-top: 2.4rem;
`;

const UpdatingContainer = styled.div`
  width: 100%;
  display: flex;
  & > p {
    margin-right: 0.8rem;
  }
`;

const ErrorBoxLine = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 5px;

  strong {
    font-weight: bold;
  }
`;

export default FlowHomePremiumSummary;
