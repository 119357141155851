import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import cogoToast from 'cogo-toast';
import LogRocket from 'logrocket';
import '../assets/css/cogoToast.css';
import FlowChatBox from '../components/FlowChatBox';
import LoginMobile from './LoginMobile';
import { cloneDeep } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router';
import FlowPolicyHolderInfoForm from '../components/FlowPolicyHolderInfoForm';
import {
  captchaAuth,
  ganAuth,
  ganGetCustomerById,
  ganGetLegalCustomerById,
} from '../helpers/apiRouterService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { patchStorage } from '../helpers/storeService';
import { ChatContext } from '../contexts/ChatContext';
import { formatISODate } from '../helpers/dateService';
import { useFlowStore } from '../contexts/FlowContext';
import LanguageSelector from '../components/LanguageSelector';

const Login = () => {
  const { affinity, insuranceType } = useParams();
  const [, flowDispatch] = useFlowStore();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(useLocation().search);
  const useLocationConst = useLocation().search === '?logoutStatus=expired';
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [id, setId] = useState(null);
  const [askEmailExistingCustomer, setAskEmailExistingCustomer] = useState(
    false
  );
  const [latestSessionData] = useState();
  const { addAnswer, addQuestion, clearChat } = useContext(ChatContext);

  useEffect(() => {
    if (useLocationConst) {
      cogoToast.error(i18n.t('Your session has expired  Please log in again'), {
        position: 'top-right',
      });
    }

    if (
      insuranceType !== 'allMobile' &&
      insuranceType !== 'carMobile' &&
      insuranceType !== 'homeMobile'
    ) {
      resetChat();
    }
  }, []);

  if (urlParams.has('lang')) {
    const toSetLanguage = urlParams.get('lang');
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
  }

  const history = useHistory();

  function handleSubmit(values, personType) {
    onSubmit(values, personType);
  }

  const onSubmit = async (values, personType) => {
    const { firstName, lastName, email, company_name, idNumber, cbe, idNo } =
      values || {};

    setLoading(true);
    // Call ganAuth to get a JWT and put it in sessionStorage
    const [, ganstatus] = await ganAuth();
    if (ganstatus !== 200) return;

    let customresponse;
    if (idNumber) {
      // if idNumber is set, get customer info from Gan
      let getCustomer = async () => {};
      if (personType === 'legal') getCustomer = ganGetLegalCustomerById;
      else getCustomer = ganGetCustomerById;
      const [customresp, customstatus] = await getCustomer(idNumber);
      // if customer exists, use this customer data
      if (customstatus === 200) {
        customresponse = customresp;
        // if customer exists but no email from customer, ask for it
        if (!customresp?.Email && !email) {
          setLoading(false);
          return setAskEmailExistingCustomer(true);
        } else if (askEmailExistingCustomer) {
          setAskEmailExistingCustomer(false);
        }
      } else {
        setLoading(false);
        cogoToast.error(
          i18n.t(`A customer with id number ${idNumber} wasn't found.`)
        );
      }
    } else {
      let getCustomer = async () => {};
      let customerId;
      if (personType === 'legal') {
        getCustomer = ganGetLegalCustomerById;
        customerId = cbe;
      } else {
        getCustomer = ganGetCustomerById;
        customerId = idNo;
      }
      const [, customstatus] = await getCustomer(customerId);
      // if customer exists, use this customer data
      if (customstatus === 200) {
        setLoading(false);
        return cogoToast.error(
          i18n.t(
            `A customer with id number ${customerId} already exists, please use the existing customer option.`
          )
        );
      }
    }
    const tempValues = cloneDeep(values);

    // Fix so gan data is placed in storage
    if (customresponse?.FirstName) {
      tempValues.firstName = customresponse?.FirstName;
      tempValues.lastName = customresponse?.LastName;
      if (customresponse?.DateOfBirth) {
        tempValues.birth = formatISODate(customresponse?.DateOfBirth);
      }
    }

    authenticate(
      firstName,
      lastName,
      email,
      company_name,
      customresponse,
      tempValues,
      !!idNumber
    );
    LogRocket.identify('Email Login', {
      email,
      name: `${tempValues.firstName} ${tempValues.lastName}`,
    });
  };

  const authenticate = async (
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    company_name = undefined,
    customresp = undefined,
    values = undefined,
    isGan = true
  ) => {
    const captcha = await executeRecaptcha('captchaAuth');
    const [, status] = await captchaAuth({
      captcha,
      email: customresp?.Email ? customresp?.Email : email,
      firstName: customresp
        ? customresp?.FirstName
        : company_name
        ? affinity
        : firstName,
      lastName: customresp
        ? customresp?.LastName
        : company_name
        ? 'company_name'
        : lastName,
      askBrokerId: '52fc1647-afac-474b-92ee-36e51a87a1b7',
    });

    if (status !== 200) return;

    function upperCaseFirstLetter(string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
    }

    const answer = {
      message: i18n.t("Hi it's me", {
        firstName: upperCaseFirstLetter(values?.firstName || company_name),
      }),
      slug: 'verify-user',
    };

    addAnswer(answer);

    if (values?.primary === 'yes') {
      patchStorage({
        payload: { driver_information: values },
        id,
        affinity,
        insuranceType,
      });
    }

    let addressValues;
    if (customresp) {
      const filteredAddresses = customresp?.AddressesList?.filter(
        (item) => item.Mailing
      );
      if (filteredAddresses.length > 0) {
        addressValues = {
          municipalityName: filteredAddresses[0]?.Address?.City?.City,
          streetName: filteredAddresses[0]?.Address?.StreetName,
          postalCode: filteredAddresses[0]?.Address?.ZipCode,
          streetNumber:
            filteredAddresses[0]?.HouseNumber ||
            filteredAddresses[0]?.StreetNumber ||
            '',
          boxNumber: null,
        };
      }
    }

    if (addressValues?.municipalityName) {
      patchStorage({
        payload: addressValues,
        path: ['policy_holder_address'],
        id,
        affinity,
        insuranceType,
      });
    }

    if (isGan) {
      flowDispatch({
        type: 'ADD_GAN_CUSTOMER',
        payload: customresp,
      });
      patchStorage({
        payload: customresp,
        path: ['ganCustomer'],
        id,
        affinity,
        insuranceType,
      });
    } else {
      flowDispatch({
        type: 'ADD_NEW_CUSTOMER',
        payload: values,
      });
      patchStorage({
        payload: values,
        path: ['customer'],
        id,
        affinity,
        insuranceType,
      });
    }
    setLoading(false);

    history.push(`/${affinity}/${insuranceType}/session`);
  };

  const resetChat = async () => {
    await clearChat();
    const question = {
      message: i18n.t(
        'Hello I am Gan In order to calculate your personalised premium please answer the following questions on the right'
      ),
      slug: 'verify-user',
    };
    addQuestion(question);
  };

  if (
    insuranceType === 'allMobile' ||
    insuranceType === 'carMobile' ||
    insuranceType === 'homeMobile'
  ) {
    return <LoginMobile />;
  } else {
    return (
      <Container>
        <LanguageSelector onChange={resetChat} />
        <FlowChatBox />
        <RightContainer>
          <FlowPolicyHolderInfoForm
            id={id}
            setId={setId}
            loading={loading}
            retrieveSubmit={handleSubmit}
            askEmailExistingCustomer={askEmailExistingCustomer}
            defaultValues={
              latestSessionData !== undefined ? latestSessionData.data : {}
            }
          />
        </RightContainer>
      </Container>
    );
  }
};

const RightContainer = styled.div`
  padding-left: 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default Login;
