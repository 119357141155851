import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { ActionButton } from 'wg-fe-ui';
import OfferedPolicy from '../components/OfferedPolicy';
import FlowLeftSideMap from '../components/FlowLeftSideMap';
import { retrieveStorageById, patchStorage } from '../helpers/storeService';
import { useParams } from 'react-router';
import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import { getNestedObject } from '../helpers/objectService';
import useRouting from '../hooks/useRouting';

const FlowHomeInformationExtra = () => {
  const { id, affinity, insuranceType } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_address } = data;

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const nearest_neighbour = getNestedObject(data, [
      'risk_address',
      'parcel',
      'distances',
      'nearest_neighbor',
    ]);
    let far_away_neighbour = getNestedObject(data, [
      'risk_address',
      'parcel',
      'main_building',
      'enquiries',
      'far_away_neighbour',
    ]);

    if (typeof far_away_neighbour === undefined) {
      far_away_neighbour = nearest_neighbour > 50;
    }

    const defaultValuesTemp = {
      has_basement: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'has_basement',
      ]),
      has_attic: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'has_attic',
      ]),
      inhabitable_attic: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'inhabitable_attic',
      ]),
      inhabitable_basement: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'inhabitable_basement',
      ]),
      professional_kitchen: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'professional_kitchen',
      ]),
      professional_bathroom: getNestedObject(data, [
        'risk_address',
        'parcel',
        'main_building',
        'enquiries',
        'professional_bathroom',
      ]),
      has_solar_panels: getNestedObject(data, [
        'risk_address',
        'parcel',
        'properties',
        'has_solar_panels',
      ]),
      far_away_neighbour,
    };
    setDefaultValues(defaultValuesTemp);
  };

  const enquiries = [
    {
      label: 'Does the building have a basement?',
      key: 'has_basement',
      locking: 'inhabitable_basement',
    },
    {
      label: 'Is the basement used as a living area?',
      key: 'inhabitable_basement',
      lockedBy: 'has_basement',
    },
    {
      label: 'Does the building have an attic?',
      key: 'has_attic',
      locking: 'inhabitable_attic',
    },
    {
      label: 'Is the attic used as a living area?',
      key: 'inhabitable_attic',
      lockedBy: 'has_attic',
    },
    {
      label: 'There are solar panels attached to the building',
      key: 'has_solar_panels',
    },
    {
      label: 'Closest neighbor is more than 50m away from the building',
      key: 'far_away_neighbour',
    },
    {
      label:
        'Kitchen was professionally installed and has a high quality finish',
      key: 'professional_kitchen',
    },
    {
      label:
        'Bathroom was professionally installed and has a high quality finish',
      key: 'professional_bathroom',
    },
  ];

  useEffect(() => {
    enquiries.forEach((item) => {
      handleChange({ name: item.key, value: false });
    });
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  const validationSchema = Yup.object().shape({});

  const { values, handleSubmit, handleChange } = useForm({ validationSchema });

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    const {
      has_attic,
      has_basement,
      inhabitable_attic,
      inhabitable_basement,
      professional_bathroom,
      professional_kitchen,
      has_solar_panels,
      far_away_neighbour,
    } = values;
    const properties = {
      has_solar_panels,
    };

    const enquiries = {
      has_basement,
      has_attic,
      inhabitable_basement,
      inhabitable_attic,
      professional_kitchen,
      professional_bathroom,
      far_away_neighbour,
    };
    patchStorage({
      payload: properties,
      path: ['risk_address', 'parcel', 'properties'],
      id,
      affinity,
      insuranceType,
    });
    patchStorage({
      payload: enquiries,
      path: ['risk_address', 'parcel', 'main_building', 'enquiries'],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  };

  return (
    <SplitContainer>
      <FlowLeftSideMap
        chatText={
          <>
            <Label>{i18n.t('Address')}</Label>
            <Address>
              {risk_address.address.street} {risk_address.address.housenr},{' '}
              {risk_address.address.zipcode} {risk_address.address.city}
            </Address>
          </>
        }
        addressInfo={risk_address}
      />
      <RightSplit>
        <h1>{i18n.t('Risk address')}</h1>
        <form onSubmit={formSubmitHandler}>
          <RisicoAdresVragen>
            {enquiries.map((item, key) => {
              return (
                <OfferedPolicy
                  key={key}
                  name={item.key}
                  checked={values[item.key]}
                  disabled={item.lockedBy ? !values[item.lockedBy] : false}
                  onChange={(e) => {
                    handleChange(e);
                    if (!e.value) {
                      if (item.locking) {
                        handleChange({ name: item.locking, value: false });
                      }
                    }
                  }}
                >
                  {i18n.t(item.label)}
                </OfferedPolicy>
              );
            })}
          </RisicoAdresVragen>
          <ButtonContainer>
            <StyledActionButton>{i18n.t('Next')}</StyledActionButton>
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const Label = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.typo.title};
`;

const Address = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-top: 2rem;
    margin-left: auto;
    width: 20rem;
  }
`;

const RisicoAdresVragen = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 49% 49%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowHomeInformationExtra;
