import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mq } from '../../helpers/styledComponenstService';
import { isMobile } from 'react-device-detect';

interface CustomOptionProps {
  label_secondary?: string;
  label: string;
  value: string | number;
  isSelected: boolean;
}

const SearchSelectCustomOption: React.FC<CustomOptionProps> = ({
  label,
  label_secondary,
  isSelected,
}) => {
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (isMobile && isSelected && ref.current?.scrollIntoView)
      ref.current?.scrollIntoView();
  }, [isSelected]);
  
  return (
    <div
      onClick={(e) => e.preventDefault()}
      className="custom-option_container"
      ref={ref}
    >
      <StyledOption isMobileDevice={isMobile} hasSecondary={!!label_secondary}>
        <p className="custom-option_primary-label">{label}</p>
        <p className="custom-option_secondary-label">{label_secondary}</p>
        <MobileRadioIcon
          isMobileDevice={isMobile}
          className="custom-option_radioIcon"
        >
          <div className="custom-option_radioIcon_fill" />
        </MobileRadioIcon>
      </StyledOption>
    </div>
  );
};

const MobileRadioIcon = styled.div<{ isMobileDevice?: boolean }>`
width: 1.6rem;
height: 1.6rem;
position: absolute;
right: 1.2rem;
top: 50%;
transform: translate3d(0, 0, 200px) translateY(-50%);
border: 1px solid #E2E3E6;
border-radius: 50px;

& > .custom-option_radioIcon_fill {
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.brand.primary};
  border-radius: 50px;
  display: none; /* this will be enabled in main selectInput component */
}

${mq.laptop} {
  display: ${({ isMobileDevice }) => !isMobileDevice && 'none'};
}
`;

const StyledOption = styled.div<{
hasSecondary?: boolean;
isMobileDevice: boolean;
}>`
display: flex;
width: 100%;
flex-direction: column;
justify-content: center;
position: relative;

& .custom-option_primary-label {
  width: 90%;
}

& .custom-option_secondary-label {
  margin-top: 0.8rem;
  display: ${({ hasSecondary }) => !hasSecondary && 'none'};
}

${mq.laptop} {
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      & .custom-option_secondary-label {
        margin-top: 0;
      }
    `}
}
`;

export default SearchSelectCustomOption;