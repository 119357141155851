import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AccordionBox } from 'wg-fe-ui';
import FlowOverviewDiscountsSectionContent from './FlowOverviewDiscountsSectionAccordionContent';
import FlowOverviewDiscountsSectionHeader from './FlowOverviewDiscountsSectionAccordionHeader';

interface Props {
  policy: any;
  areDiscountsOpen: boolean;
  setAreDiscountsOpen: (areDiscountsOpen: boolean) => void;
}
const FlowOverviewDiscountsSection: React.FC<Props> = ({
  policy,
  areDiscountsOpen,
  setAreDiscountsOpen,
}) => {
  const [discounts, setDiscounts] = useState<any[]>([]);

  useEffect(() => {
    const arr: Array<any> = [];
    if (policy?.response) {
      Object.entries(policy?.response).forEach((item) => {
        if (
          (item[0].includes('Discount') ||
            item[0].includes('AutomaticRenewal')) &&
          item[0] !== 'Discount'
        ) {
          arr.push(item);
        }
      });
    }
    setDiscounts(arr);
  }, [policy, policy.response]);

  return (
    <StyledAccordionBox
      isOpen={areDiscountsOpen}
      onOpen={(open: boolean) => setAreDiscountsOpen(open)}
    >
      <AccordionBox.Header>
        <FlowOverviewDiscountsSectionHeader discounts={discounts} />
      </AccordionBox.Header>
      <AccordionBox.Content>
        <FlowOverviewDiscountsSectionContent
          policy={policy}
          discounts={discounts}
        />
      </AccordionBox.Content>
    </StyledAccordionBox>
  );
};

export default FlowOverviewDiscountsSection;

const StyledAccordionBox = styled(AccordionBox)`
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;
  width: auto;
  margin-top: ${({ isOpen }) => (!isOpen ? '20px' : '0')};

  & > div:first-child {
    padding-right: 0;
    width: auto;
    & > div:first-child {
      & > div {
        margin-right: 4rem;
        width: auto;
      }
    }
  }

  & > div:nth-child(2) {
    padding-top: 0 !important;
  }
`;
