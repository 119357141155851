import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  Label,
  TextInput,
  IconActionChevronLeft,
} from 'wg-fe-ui';
import * as Yup from 'yup';
import i18n from '../i18n';

import { string, number } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router';
import { primaryOptions as primaryOptionsConstants } from '../constants/FlowSearchSelectData';
import SearchSelectInputV2 from './SearchSelectInputV2';

const FlowParentalInformationForm = ({ handleFormValues, defaultValues }) => {
  const history = useHistory();
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const SignupSchema = Yup.object().shape({
    AreBothOYourParentsSmokers: string.required,
    NoOfCigarettesParentsSmoke: Yup.number()
      .nullable()
      .when('AreBothOYourParentsSmokers', {
        is: 'yes',
        then: number.required.min(1, 'Too low'),
      }),
    ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus:
      string.required,
    YoungestParentAge: Yup.mixed().when(
      'ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus',
      {
        is: 'yes',
        then: number.required
          .min(12, i18n.t('Too low'))
          .max(120, i18n.t('Too high')),
      }
    ),
  });

  const SelectPlaceholder = i18n.t('Choose your option');

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstants.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (
        [
          'AreBothOYourParentsSmokers',
          'ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus',
        ].includes(name)
      ) {
        handleSelected({ name, value: defaultValues[name] });
      } else {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }

  const getEnumValue = (name, value) => {
    switch (name) {
      case 'AreBothOYourParentsSmokers':
      case 'ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus':
        return primaryOptions.find((item) => item.value === value);
      default:
        return;
    }
  };

  const handleSelected = ({ value, name }) => {
    const selectedVal = getEnumValue(name, value);
    setSelected((prevValue) => ({
      ...prevValue,
      [name]: selectedVal,
    }));
    handleChange({ value, name });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t('Health information')} </CatTitle>
        <SearchSelectInputV2
          error={errors.AreBothOYourParentsSmokers}
          name="AreBothOYourParentsSmokers"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={selected?.AreBothOYourParentsSmokers}
        >
          <StyledLabel>{i18n.t('Do both your parents smoke?')} *</StyledLabel>
        </SearchSelectInputV2>
        {values?.AreBothOYourParentsSmokers === 'yes' && (
          <TextInput
            error={errors.NoOfCigarettesParentsSmoke}
            name="NoOfCigarettesParentsSmoke"
            onChange={handleChange}
            type="number"
            value={values?.NoOfCigarettesParentsSmoke}
          >
            <StyledLabel>
              {i18n.t('How many cigarettes do your parents smoke')}? *
            </StyledLabel>
          </TextInput>
        )}
        <SearchSelectInputV2
          error={
            errors.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
          }
          name="ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus"
          onSelected={handleSelected}
          options={primaryOptions}
          placeholder={SelectPlaceholder}
          value={
            selected?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus
          }
        >
          <StyledLabel>
            {i18n.t(
              'Does at least one of your parents suffer from Atherosclerotic ciculatory disease, peripheral cardiovascular, cerebrovascular disorder or diabetes mellitus'
            )}
            ? *
          </StyledLabel>
        </SearchSelectInputV2>
        {values?.ParentsSufferFromAtheroscleroticCirculatoryDiseaseOrPeripheralCardiovascularOrCerebrovascularDisorderOrDiabetesMellitus ===
          'yes' && (
          <TextInput
            error={errors.YoungestParentAge}
            name="YoungestParentAge"
            type="number"
            onChange={handleChange}
            value={values?.YoungestParentAge}
          >
            <StyledLabel>
              {i18n.t("What is your youngest parent's age")}? *
            </StyledLabel>
          </TextInput>
        )}
        <Required>* {i18n.t('Required fields')}</Required>
        <ButtonContainer>
          <BackButton type="button" name="back" onClick={goBack}>
            <IconActionChevronLeft />
            {i18n.t('Back')}
          </BackButton>
          <StyledActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t('Next')}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: white;
  background-color: default;
  font-weight: normal;
`;

const StyledLabel = styled(Label)`
  text-transform: initial;
  color: ${({ theme }) => theme.typo.text};
  a {
    margin-top: -0.55rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    font-weight: 550;
    height: 5rem;
    min-width: 12rem;
    width: 12rem;
    &:focus {
      outline: 0;
    }
  }
`;

const BackButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  margin-right: 1rem;
  padding-right: 1.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.brand.light};
    color: white;
    svg > path {
      stroke: white;
    }
  }
  > svg {
    height: 2.5rem;
    margin-bottom: 0.35rem;
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.8rem;
  flex-shrink: 0;
  line-height: 2.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.brand.primary};
`;

export default FlowParentalInformationForm;
